import React from "react";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import StockList from "../Components/LandingComponent/StockList";

const StockListPage = () => {
  return (
    <>
      <Header />
      <StockList />
      <Footer />
    </>
  );
};

export default StockListPage;
