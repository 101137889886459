import { Disclosure } from "@headlessui/react";
import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { getSelectedDiamondState } from "../../store/Slices/diamondSelectSlice";

const TableRow = React.forwardRef(
  ({ data, isOpen, toggleAccordion, handleSelectDiamond }, ref) => {
    const { data: selectedDiamond } = useSelector(getSelectedDiamondState);
    return (
      <React.Fragment>
        <tr
          ref={ref}
          className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
          onClick={toggleAccordion}
        >
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px]">
            {data.SHAPE}
          </td>
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            {data.CARAT}
          </td>
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            {data.COLOUR}
          </td>
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            {data.CLARITY}
          </td>
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            {data.CUTPOLISHSYM}
          </td>
          {/* <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            ${data.Price || 0.0}
          </td> */}
          <td className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-center">
            ${data.Amount || 0.0}
          </td>
          <td className="md:py-3 md:px-4 py-2 px-1 text-[18px] text-center">
            <BiChevronRight />
          </td>
        </tr>
        {isOpen && (
          <tr>
            <td colSpan="8">
              <div className="accordion-content bg-white border border-gray-200 rounded-b md:p-4 p-2">
                <div className="flex items-start justify-between flex-wrap">
                  <div className="md:w-[50%] w-full">
                    <video
                      className="md:w-[350px] w-[250px] md:h-[350px] h-[250px] mx-auto bg-gray-200"
                      autoPlay
                      loop
                      muted
                    >
                      <source src={data.VideoUrl} />
                    </video>
                  </div>
                  <div className="md:w-[50%] w-full">
                    <dl className="space-y-3 divide-y divide-gray-900/10">
                      <Disclosure as="div" className="pt-3">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="md:text-base text-[14px] font-[800] font-Josefin leading-7">
                                  Cut:{" "}{data.CUTPOLISHSYM}{" "}
                                  <span className="font-[400] mr-[5px]">
                                    {data.cut === "Excellent"
                                      ? "Perfect premium cut."
                                      : "High quality cut."}
                                  </span>
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-gray-600">
                                The quality of the cut determines how brilliant
                                a diamond is. Life is too short for a diamond
                                that is not brilliant. All of our diamonds are
                                cut to perfection.
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="pt-3">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="md:text-base text-[14px] font-[800] font-Josefin leading-7">
                                  Color:{" "}{data.COLOUR}{" "}
                                  <span className="font-[400] mr-[5px]">
                                    Near colorless.
                                  </span>
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-gray-600">
                                Color ratings are difficult to discern unless
                                two diamonds are compared directly side-by-side.
                                Colorless diamonds are more rare but
                                near-colorless ones offer greater value.
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="pt-3">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="md:text-base text-[14px] font-[800] font-Josefin leading-7">
                                  Clarity:{" "}
                                  <span className="font-[400] mr-[5px]">
                                    {data.clarity === "SI1"
                                      ? "Slightly included."
                                      : "Very Slightly included."}
                                  </span>
                                  {data.CLARITY}
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-gray-600">
                                Clarity rates how large inclusions are in a
                                diamond. We offer a wide selection from our own
                                foundry.
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as="div" className="pt-3">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="md:text-base text-[14px] font-[800] font-Josefin leading-7">
                                  <font>Certificate: {" "}</font> 
                                  <font className="font-normal">{data?.LAB}{" "}{data.CERTID}</font>
                                </span>
                                {/* {data?.LAB}{" "}{data.CERTID} */}
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmallIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-gray-600">
                                
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </dl>
                    <div className="mt-10">
                      {selectedDiamond?.length > 0 && selectedDiamond.find(
                        (item) => item.StoneNo === data.StoneNo
                      ) && (
                        <div className="text-[#ff0000] text-[16px] font-Poppins mb-4">
                          Sold Out !
                        </div>
                      )}
                      <button
                        onClick={handleSelectDiamond}
                        disabled={
                          selectedDiamond?.length > 0 && selectedDiamond.find(
                            (item) => item.StoneNo === data.StoneNo
                          )
                            ? true
                            : false
                        }
                        className="font-Josefin w-full bg-[#000] text-white py-2 disabled:cursor-not-allowed disabled:opacity-70"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
);

export default TableRow;
