import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTags } from "../../Services/tagService";

export const fetchTagsData = createAsyncThunk("tags/fetchData", async () => {
  const response = await getTags()
  return response.data.data.tags
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTagsData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchTagsData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchTagsData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getTagsState = state => state.tagsData

export default tagSlice.reducer