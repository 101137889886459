import api from "./api";

const placeOrder = (data) => {
  return api("/api/Order", "post", data);
};

const getOrderList = () => api("/api/Order", "get");

const getOrderwithId = (id) => api(`/api/Order/${id}`, "get");

const cancelOrderwithId = (id, data) => api(`/api/OrderCancel/${id}`, "post", data);

export { placeOrder, getOrderList, getOrderwithId, cancelOrderwithId };
