import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductCardSkeleton = () => {
  return (
    <>
      {/* <div
        className={`group relative w-full overflow-hidden m-auto h-[380px] max-w-[296px] sm:max-w-full`}
      >
        <Skeleton height={"280px"} width={"100%"} />
        <div className="mt-2 text-center">
          <h3 className="font-semibold text-gray-900 truncate">
            <span className="absolute inset-0 cursor-pointer" />
            <Skeleton height={20} width={"80%"} />
          </h3>
          <p className="mt-1 text-gray-900">
            <Skeleton height={20} width={70} />
          </p>
        </div>
        <ul className="flex items-center gap-3 justify-center pt-[12px]">
          <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 ">
            <Skeleton circle height={"100%"} />
          </span>
          <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 ">
            <Skeleton circle height={"100%"} />
          </span>
          <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 ">
            <Skeleton circle height={"100%"} />
          </span>
          <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 ">
            <Skeleton circle height={"100%"} />
          </span>
        </ul>
      </div> */}
      <div
        className={`group relative w-full overflow-hidden m-auto h-[380px] max-w-[296px] sm:max-w-full`}
      >
        <Skeleton height={"280px"} width={"100%"} />
        <div className="mt-2">
          <div className="font-semibold text-gray-900 truncate">
            <Skeleton height={20} width={"100%"} />
          </div>
        </div>
        <div className="mt-2 flex justify-between">
          <div className="font-semibold text-gray-900 truncate w-[40%]">
            <Skeleton height={20} width={"100%"} />
          </div>

          <div className="font-semibold text-gray-900 truncate w-[30%]">
            <Skeleton height={20} width={"100%"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCardSkeleton;

// import React from "react";
// import Skeleton from "react-loading-skeleton";

// const ProductCardSkeleton = () => {
//   return (
//     <div
//       className={`flex flex-col relative shadow-md w-full overflow-hidden m-auto sm:h-[400px] h-[225px] max-w-[296px] sm:max-w-full rounded-[10px]`}
//     >
//       <div className="h-full ">
//         <Skeleton height={"100%"} width={"100%"} />
//       </div>
//       <div className="px-[10px] pb-2 pt-1.5 block border-t border-[#e3e3e3]">
//         <h3 className="sm:text-[16px] max-w-[90%] truncate text-[14px] leading-[19px] font-[400] font-Josefin text-[#000] text-left mb-1">
//           <Skeleton />
//         </h3>
//         <div className="flex justify-between">
//           <div className="flex gap-2">
//             <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5">
//               <Skeleton circle height={"100%"} />
//             </span>
//             <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5">
//               <Skeleton circle height={"100%"} />
//             </span>
//             <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5">
//               <Skeleton circle height={"100%"} />
//             </span>
//             <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5">
//               <Skeleton circle height={"100%"} />
//             </span>
//           </div>
//           <Skeleton width={70} height={15} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductCardSkeleton;
