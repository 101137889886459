import React from "react";
import Skeleton from "react-loading-skeleton";

const DiamondListMobileSkeleton = () => {
  return (
    <div className="bg-white flex items-center gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden py-4 sm:px-6 px-4">
      <div className="w-full">
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>

          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1">
            <Skeleton height={"100%"} />
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center flex-row">
        <div className="w-10 h-10">
          <Skeleton height={"100%"} />
        </div>
        <div className="w-10 h-10">
          <Skeleton height={"100%"} />
        </div>
        <div className="w-10 h-10">
          <Skeleton height={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default DiamondListMobileSkeleton;
