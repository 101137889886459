import React from 'react'
import loader from "../../../assets/images/loader.gif"

const Loader = () => {
  return (
    <div className='flex justify-center items-center p-2'>
      <img src={loader} className='w-[40px] h-[40px]' alt="loader" />
    </div>
  )
}

export default Loader