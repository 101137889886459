import classNames from "classnames";
import React from "react";
import { Fragment } from "react";

const Breadcrumb = ({ tabs = [] }) => {
  const data = tabs?.length > 0 && tabs.filter((item) => item.title)
  return (
    <div>
      <p className="text-[16px] font-[400] font-Primary leading-[23px] truncate">
        {data.map((item, index) => {
          return (
            <Fragment key={index}>
              <span
                className={classNames("px-2 first:pl-0", {
                  "cursor-pointer hover:underline text-[#333]": !(
                    index ===
                    tabs.length - 1
                  ),
                })}
                onClick={() => {
                  item.onClick && item.onClick();
                }}
              >
                {item.title}
              </span>
              {!(index === data.length - 1) && <span>&#62;</span>}
            </Fragment>
          );
        })}
      </p>
    </div>
  );
};

export default Breadcrumb;
