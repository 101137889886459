import React from 'react'
import banner2 from '../../assets/images/banner2.png'
import { useNavigate } from 'react-router-dom'

const NewBanner = () => {
    const navigate = useNavigate()
    return (

        <>

        {/* <div className='bg-[#F6F7F9] lg:h-[420px] h-auto flex items-center justify-between lg:flex-row flex-col xl:mb-[100px] lg:mb-[80px] md:mb-[60] mb-[40px] lg:p-1 p-4 lg:gap-0 gap-[20px] 2xl:max-w-[1224px] max-w-full 2xl:px-0 px-4 mx-auto'>
            <div className='lg:ml-auto ml-0 mb-auto lg:mt-auto lg:w-[640px] md:w-[65%] w-[90%]  -mt-[60px] lg:bg-transparent bg-white lg:order-0 order-2 p-4 rounded'>
                <h1 className='xl:text-[40px] lg:text-[32px] md:text-[30px] text-[20px] lg:leading-[52px] md:leading-[40px] leading-[30px] font-[600] text-[#000] lg:mb-4 mb-2 font-Josefin text-center lg:text-left'>Know Lab-Grown Diamonds</h1>
                <p className='lg:text-[16px] text-[14px] lg:leading-[28px] leading-[22px] font-[400] text-[#000] mb-4 font-Poppins sm:text-start'>Grown diamonds are chemically, optically, and physically identical to earth-mined diamonds. They are more ethical, beautiful, and affordable than any diamond we will ever get out of the earth. We're replicating the earth's natural process by crystallizing carbon in a modern-day lab environment into brilliant diamonds. They are priced up to 40% less and are free of any environmental or humanitarian concerns.</p>
                <button onClick={() => navigate("/diamonds-new")} className='lg:text-[16px] text-[14px] leading-[32px] lg:py-[6px] py-[4px] lg:px-[23px] px-[16px] border border-[#1F2937] font-[400] text-[#FFF] sm:w-auto w-full bg-[#1F2937] hover:bg-white hover:text-[#1F2937] font-Josefin'>Select Your Diamond</button>
            </div>
            <div className='lg:pt-0 pt-0 lg:order-2 order-1 lg:flex 2xl:justify-end 2xl:pr-5'>
                <img src={banner2} alt="banner2" className='lg:w-[96%] xl:w-[80%] 2xl:w-[95%]' />
            </div>
        </div> */}

        <div className='bg-[#F6F7F9] lg:h-[420px] h-auto flex items-center justify-between lg:flex-row flex-col xl:mb-[100px] lg:mb-[80px] md:mb-[60] mb-[40px] lg:p-0 p-4 lg:gap-0 gap-[20px] 2xl:max-w-[1224px] max-w-full 2xl:px-0 px-4 mx-auto'>
            <div className='lg:ml-auto ml-0 mb-auto lg:mt-auto lg:w-[640px] md:w-[65%] w-[90%]  -mt-[60px] lg:bg-transparent bg-white lg:order-0 order-2 p-4 rounded'>
                <h1 className='lg:text-[40px] md:text-[30px] text-[26px] lg:leading-[52px] md:leading-[40px] leading-[30px] font-[600] text-[#000] lg:mb-4 mb-1 font-Josefin'>Know Lab-Grown Diamonds</h1>
                <p className='lg:text-[16px] text-[14px] lg:leading-[28px] leading-[22px] font-[400] text-[#000] mb-4 font-Poppins text-justify sm:text-start'>Grown diamonds are chemically, optically, and physically identical to earth-mined diamonds. They are more ethical, beautiful, and affordable than any diamond we will ever get out of the earth. We're replicating the earth's natural process by crystallizing carbon in a modern-day lab environment into brilliant diamonds. They are priced up to 40% less and are free of any environmental or humanitarian concerns.</p>
                <button onClick={() => navigate("/diamonds-new")} className='lg:text-[16px] text-[14px] leading-[32px] lg:py-[6px] py-[4px] lg:px-[23px] px-[16px] border border-[#1F2937] font-[400] text-[#FFF] sm:w-auto w-full bg-[#1F2937] hover:bg-white hover:text-[#1F2937] font-Josefin'>Select Your Diamond</button>
            </div>
            <div className='lg:pt-[100px] pt-0  lg:order-2 order-1'>
                <img src={banner2} alt="banner2" />
            </div>
        </div>


        </>
    )
}

export default NewBanner