import React from "react";
import MenuCard from "./../common/MenuCard";
import SnapPost from '../../../assets/images/Snap & post.png'
import OrderList from '../../../assets/images/order list.png'
import Diamondlist from '../../../assets/images/diamond.png'
import Diamond from '../../../assets/images/Diamond report.png'
import OrderReport from '../../../assets/images/order repoet.png'
import PlaceOrderList from '../../../assets/images/Report.png'
import SideStone from '../../../assets/images/side stone prize.png'
import Design from '../../../assets/images/Design.png'

const TransactionMenuList = () => {
  const commonMasterList = [
    {
      title: "Snap & Post",
      icon: SnapPost,
      link: '/admin/snap&postlist'
    },
    {
      title: "Order List",
      icon: OrderList,
      link: "/admin/order"
    },
    {
      title: "Diamond List",
      icon: Diamondlist,
      link: "/admin/diamondStockList"
    },
    {
      title: "Diamond Request",
      icon: OrderReport,
      link: "/admin/diamondsrequestlist"
    },
    {
      title: "Place Order List",
      icon: PlaceOrderList,
      link: "/admin/placeorderlist"
    },
    {
      title: "Customize Jewelry",
      icon: Design,
      link: "/admin/product"
    },
    {
      title: "Ready To Ship",
      icon: Design,
      link: "/admin/readytoship"
    },
    {
      title: "Calibrated Stock",
      icon: SideStone,
      link: "/admin/calibrated-stock",
    },
  ];
  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard title={item.title} key={index} link={item.link} icon={item.icon} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TransactionMenuList;
