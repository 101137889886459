import api from "./api"

const getDiamondReqData = () => {
  return api("/api/StockRequest", "get")
}

const getAttributeById = (id) => {
  return api(`/api/StockRequest/${id}`, "get")
}

const createDiamonndServiceData = (data) => {
  return api("/api/StockRequest", "post", data)
}

export {
  getDiamondReqData,
  createDiamonndServiceData,
  getAttributeById
}