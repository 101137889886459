import React, { useState, useEffect } from "react";
import {
  getCities,
  getCountries,
  getStates,
} from "../../Services/countryStateCityService";
import {
  updateUserProfile,
  updateUserPassword,
} from "../../Services/userService";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteWithoutTags from "./common/AutoCompleteWithoutTag";

const UserProfile = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [contryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [profileLoading, setProfileLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const { user: userData } = useSelector((state) => state.authData);

  const [formData, setFormData] = useState({
    unUserIdentifier: "",
    stUserName: "",
    stPassword: "",
    c_stPassword: "",
    stFirstName: "",
    stLastName: "",
    stPhoneNumber: "",
    stEmail: "",
    stCompanyName: "",
    stAddress: "",
    stPinCode: "",
    dcStoneDisc: 0,
    dcJewelleryDisc: 0,
    stOldPassword: "",
    stPassword: "",
    stNewPassword: "",
  });

  const [formError, setFormError] = useState({
    stUserName: "",
    stPassword: "",
    c_stPassword: "",
    stFirstName: "",
    stEmail: "",
    country: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      unUserIdentifier: userData?.unUserIdentifier,
      stUserName: userData?.stUserName,
      stFirstName: userData?.stFirstName,
      stLastName: userData?.stLastName,
      stPhoneNumber: userData?.stPhoneNumber,
      stEmail: userData?.stEmail,
      stCompanyName: userData?.stCompanyName,
      stAddress: userData?.stAddress,
      stPinCode: userData?.stPinCode,
    });

    setSelectedCountry(userData?.unCountryId);
    setSelectedState(userData?.unStateId);
    setSelectedCity(userData?.unCityId);
  }, [userData]);

  const handleChangeCountry = (val) => {
    setFormError((prev) => ({ ...prev, country: "" }));
    setSelectedCountry(val);
    fetchStateData(val.id);
  };

  const handleChangeState = (val) => {
    setFormError((prev) => ({ ...prev, state: "" }));
    setSelectedState(val);
    fetchCityData(selectedCountry?.id, val?.id);
  };

  const handleChangeCity = (val) => {
    setFormError((prev) => ({ ...prev, city: "" }));
    setSelectedCity(val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchCountryData = async () => {
    try {
      const response = await getCountries();
      if (response.data.success) {
        setCountryData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };
  const fetchStateData = async (id) => {
    try {
      const response = await getStates(id);
      if (response.data.success) {
        setStateData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };
  const fetchCityData = async (countryId, stateId) => {
    try {
      const response = await getCities(countryId, stateId);
      if (response.data.success) {
        setCityData(response.data.data);
      }
    } catch (err) {
      console.log("Error: ", err.response);
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const checkSubmitValidation = () => {
    let isValidated = false;

    if (!formData.stUserName) {
      isValidated = false;
      setFormError((prev) => ({
        ...prev,
        stUserName: "This field is required.",
      }));
    } else {
      isValidated = true;
    }
    if (!formData.stFirstName) {
      isValidated = false;
      setFormError((prev) => ({
        ...prev,
        stFirstName: "This field is required.",
      }));
    } else {
      isValidated = true;
    }
    if (!formData.stEmail) {
      isValidated = false;
      setFormError((prev) => ({ ...prev, stEmail: "This field is required." }));
    } else {
      isValidated = true;
    }
    // if (!selectedCity) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, city: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }
    // if (!selectedCountry) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, country: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }
    // if (!selectedState) {
    //   isValidated = false;
    //   setFormError((prev) => ({ ...prev, state: "This field is required." }));
    // } else {
    //   isValidated = true;
    // }

    return isValidated;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setProfileLoading(true);

    if (Object.values(formError).join("")) {
      return;
    }

    if (!checkSubmitValidation()) {
      return;
    }

    const payload = new FormData();
    payload.append("unUserIdentifier", formData.unUserIdentifier);
    payload.append("stFirstName", formData.stFirstName);
    payload.append("stLastName", formData.stLastName);
    // payload.append("stPhoneNumber", formData.stPhoneNumber);
    // payload.append("stEmail", formData.stEmail);
    // payload.append("stCompanyName", formData.stCompanyName);
    // payload.append("stAddress", formData.stAddress ? formData.stAddress : "---");
    // payload.append("stPinCode", formData.stPinCode ? formData.stPinCode : 0);
    // payload.append("unCityId", selectedCity?.id ? selectedCity?.id : 0);
    // payload.append("unStateId", selectedState?.id || 0);
    // payload.append("unCountryId", selectedCountry?.id || 0);

    // const res = await updateUserProfile(payload)
    updateUserProfile(payload);

    setProfileLoading(false);
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();

    setPasswordLoading(true);
    const payload = new FormData();
    payload.append("unUserIdentifier", formData.unUserIdentifier);
    payload.append("stOldPassword", formData.stOldPassword);
    payload.append("stPassword", formData.stPassword);
    // payload.append("stCnfmPassword", formData.stConfirmPassword);

    updateUserPassword(payload);
    setPasswordLoading(false);
  };

  return (
    <main>
      <div className="grid px-6 pb-6">
        <h1 className="my-6 mt-0 text-lg font-bold text-gray-700 dark:text-gray-300">
          Profile
        </h1>
        <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-lg">
          <form
            onSubmit={handleSubmit}
            method="post"
            className="border rounded-lg pb-1"
          >
            <div className="p-6 flex-grow scrollbar-hide w-full max-h-full">
              {/* <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
                <label className="block text-sm text-gray-700 dark:text-gray-400 col-span-4 sm:col-span-2 font-medium">
                  Profile Picture
                </label>
                <div className="col-span-8 sm:col-span-4">
                  <div className="w-full text-center">
                    <div
                      className="px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-md cursor-pointer"
                      role="button"
                      tabIndex={0}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        autoComplete="off"
                        tabIndex={-1}
                        style={{ display: "none" }}
                      />
                      <span className="mx-auto flex justify-center">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="text-3xl text-green-500"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="16 16 12 12 8 16" />
                          <line x1={12} y1={12} x2={12} y2={21} />
                          <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" />
                          <polyline points="16 16 12 12 8 16" />
                        </svg>
                      </span>
                      <p className="text-sm mt-2">Drag your image here</p>
                      <em className="text-xs text-gray-400">
                        (Only *.jpeg and *.png images will be accepted)
                      </em>
                    </div>
                    <aside className="flex flex-row flex-wrap mt-4">
                      <img
                        className="inline-flex border rounded-md border-gray-100 dark:border-gray-600 w-24 max-h-24 p-2"
                        src="https://i.ibb.co/WpM5yZZ/9.png"
                        alt="product"
                      />
                    </aside>
                  </div>
                </div>
              </div> */}

              <div class="grid md:grid-cols-2 md:gap-x-4">
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="firstname"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="stFirstName"
                    id="stFirstName"
                    value={formData.stFirstName}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="lastname"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="stLastName"
                    id="stLastName"
                    value={formData.stLastName}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>

                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    id="stEmail"
                    name="stEmail"
                    type="email"
                    value={formData.stEmail}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="phoneNumber"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="stPhoneNumber"
                    id="stPhoneNumber"
                    value={formData.stPhoneNumber}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
              </div>

              <div class="grid md:grid-cols-2  md:gap-x-4">
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Company Name
                  </label>
                  <input
                    id="stCompanyName"
                    name="stCompanyName"
                    type="text"
                    value={formData.stCompanyName}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
                <div class="relative w-full mb-2 group">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Country
                  </label>
                  <AutoCompleteWithoutTags
                    options={contryData}
                    setData={handleChangeCountry}
                    data={selectedCountry || {}}
                  />
                </div>

                <div class="relative w-full mb-2 group">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    State
                  </label>
                  <AutoCompleteWithoutTags
                    options={stateData}
                    setData={handleChangeState}
                    data={selectedState || {}}
                  />
                </div>
                <div class="relative w-full mb-2 group">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    City
                  </label>
                  <AutoCompleteWithoutTags
                    options={cityData}
                    setData={handleChangeCity}
                    data={selectedCity || {}}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse pr-6 pb-2">
              <button
                className={`align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none py-2 rounded-lg text-sm text-white bg-[#1F2937] border border-transparent active:bg-[#1F2937] hover:bg-bg-[#1F2937] focus:ring focus:ring-purple-300 h-10 px-5 ${profileLoading && 'opacity-50'}`}
                type="submit"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>

        <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-lg mt-6">
          <form
            onSubmit={handleUpdatePassword}
            method="post"
            className="border rounded-lg pb-1"
          >
            <div className="p-6 flex-grow scrollbar-hide w-full max-h-full">
              <div class="grid md:grid-cols-3 md:gap-x-4">
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="oldpassword"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Old Password
                  </label>
                  <input
                    type="password"
                    name="stOldPassword"
                    id="stOldPassword"
                    value={formData.stOldPassword}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="newpassword"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    name="stNewPassword"
                    id="stNewPassword"
                    value={formData.stNewPassword}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    for="confirmpassword"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="stConfirmPassword"
                    id="stConfirmPassword"
                    value={formData.stConfirmPassword}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 dark:placeholder-gray-400 dark:text-white outline-none"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse pr-6 pb-2">
              <button
                className={`align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none py-2 rounded-lg text-sm text-white bg-[#1F2937] border border-transparent active:bg-[#1F2937] hover:bg-bg-[#1F2937] focus:ring focus:ring-purple-300 h-10 px-5 ${passwordLoading && 'opacity-50'}`}
                type="submit"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default UserProfile;
