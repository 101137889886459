import { createSlice } from "@reduxjs/toolkit"
import { clearCartAction } from "../actions"

const initialState = {
  cart: [],
  cartDrawer: false
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.cart.push(action.payload)
    },
    removeFromCart(state, action) {
      state.cart = state.cart.filter((_, index) => index !== action.payload)
    },
    openCart(state, action) {
      state.cartDrawer = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(clearCartAction, (state) => {
      state.cart = []
    })
  }
})

export const { addToCart, removeFromCart, openCart } = cartSlice.actions

export const selectCartState = state => state.cartData

export default cartSlice.reducer