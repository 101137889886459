import React from 'react'
import { useState } from 'react'
import AdminModal from '../common/AdminModal'
import { SiMicrosoftexcel } from "react-icons/si"
import { acceptedFileExtensions } from '../../shared/constants'
import { toast } from 'react-hot-toast'
import SelectMenu from '../common/SelectMenu'
import { getAttributeValueByAttribute } from '../../../Services/attributeValueService'
import { useEffect } from 'react'
import { uploadSideStonePrice } from '../../../Services/sideStonePriceService'
import { fetchSideStonePriceList } from '../../../store/Slices/sideStonePriceSlice'
import { useDispatch } from 'react-redux'

const UploadSideStonePrice = ({ isOpen, setIsOpen }) => {
  const [uploadedFile, setUploadedFile] = useState(null)
  const [selectedShape, setSelectedShape] = useState(null)
  const [shapeOptions, setShapeOptions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      fetchShapeData()
    }
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
    setSelectedShape(null)
    setUploadedFile(null)
  }

  const handleChange = e => {
    const { files } = e.target
    if (files[0] && acceptedFileExtensions.includes(files[0].name.split(".").pop())) {
      setUploadedFile(files[0])
    } else {
      toast.error("Invalid File Extension.")
    }
  }

  const fetchShapeData = async () => {
    const response = await getAttributeValueByAttribute(2)
    if (response.data.success) {
      const options = response.data.data.attribute_value.map(item => ({
        title: item.stAttributeValueName,
        id: item.unAttributeValueIdentifier
      }))
      setShapeOptions(options)
    }
  }

  const handleSubmit = async () => {
    const payload = new FormData()
    payload.append("file", uploadedFile)
    payload.append("unAttributeValueShapeId", selectedShape?.id)
    const response = await uploadSideStonePrice(payload)
    if (response.data.success) {
      toast.success("Uploaded Successfully.")
      dispatch(fetchSideStonePriceList())
      handleClose()
    } else {
      toast.error("Something went wrong.")
    }
  }

  return (
    <AdminModal
      title={"Upload Side Stone Price"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
      disableSubmit={!!!uploadedFile || !!!selectedShape}
      submitButtonText="Upload"
    >
      <div className="mt-4">
        <SelectMenu
          options={shapeOptions}
          data={selectedShape}
          setData={setSelectedShape}
          dropDownHeightClass="max-h-[100px]"
        />
        <div
          className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 mt-5">
          <div className="space-y-1 text-center">
            <div className="flex items-center justify-center gap-4 text-sm text-gray-600">
              <SiMicrosoftexcel size={48} color="#295268" />
              <label htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-medium">
                <span>{uploadedFile ? uploadedFile?.name : "Upload a file"}</span>
                <input id="file-upload" name="file-upload" type="file" onChange={handleChange} className="sr-only" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </AdminModal>
  )
}

export default UploadSideStonePrice