import React from 'react'
import MenuCard from '../common/MenuCard';
import Select from '../../../assets/images/Select design.png'
import User from '../../../assets/images/user.png'
import Customize from '../../../assets/images/Customize design.png'


const AgentMenu = () => {
  const commonMasterList = [
      {
        title: "Customize Design",
        icon: Customize,
        link: "/admin/agent"
      },
      {
        title: "Select Design",
        icon: Select,
        link: "/admin/agent-selection"
      },
      {
        title: "User",
        icon: User,
        link: "/admin/user-role"
      },
    ];
  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard title={item.title} key={index} link={item.link} icon={item.icon} />
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default AgentMenu