import React, { useState } from "react";
import login from "../../assets/images/login.svg";
import cartIcon from "../../assets/images/cart.svg";
import { Link, useNavigate } from "react-router-dom";
import { IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
import { ClickAwayListener, Drawer } from "@mui/material";
import { FiShoppingCart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCart,
  openCart,
  removeFromCart,
  selectCartState,
} from "../../store/Slices/cartSlice";
import { Fragment } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import { clearAuth, getAuthState } from "../../store/Slices/AuthSlice";
import { CiLogout } from "react-icons/ci";
import { useThunk } from "../../Hooks/use-thunk";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";
import useApi from "../../Hooks/use-Api";
import { getCategoryStylebyCategoryId } from "../../Services/categoryStyleService";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";
import Cart from "../../assets/images/cart.png";
import IsLoginHeader from "./IsLoginHeader";
import { AiOutlineMenu } from "react-icons/ai";
import { GrHomeRounded } from "react-icons/gr";

const shapeData = ["Round", "Oval", "Emerald", "Marquise", "Cushion", "Pear"];

const Header = (props) => {

  const { headerText } = props;

  const [open, setOpen] = useState(false);
  const [smOpen, setSmOpen] = useState(false);
  const [selectedCategory, setSelectedcategory] = useState(null);
  const [selectedSubCategory, setSelectedSubcategory] = useState(null);
  const { cart, cartDrawer } = useSelector(selectCartState);
  const User = JSON.parse(localStorage.getItem("name"));
  const totalPrice = useMemo(
    () => cart.reduce((acc, cur) => acc + Number(cur.total), 0),
    [cart]
  );
  // const [fetchCategoryData] = useThunk(fetchCategory);
  const [fetchCategoryStyleData, categoryStyleData, styleLoading] = useApi(
    getCategoryStylebyCategoryId
  );

  const { isLoggedIn } = useSelector(getAuthState);
  const { data: categoryData } = useSelector(getCategoryDataState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchCategoryData();
  // }, [fetchCategoryData]);

  useEffect(() => {
    if (categoryData.length) {
      fetchCategoryStyleData(categoryData[0].unCategoryIdentifier);
      setSelectedcategory(categoryData[0]);
    }
  }, [categoryData, fetchCategoryStyleData]);

  useEffect(() => {
    if (categoryStyleData && categoryStyleData.categories_style.length) {
      setSelectedSubcategory(categoryStyleData.categories_style[0]);
    }
  }, [categoryStyleData]);

  const menus = [
    { name: "Home", link: "/" },
    { name: "Customize Jewelry", link: "/customized-jewelry" },
    { name: "Ready To Ship", link: "/readytoship" },
    { name: "Snap & Post", link: "/snap-own-design" },
    { name: "Know Lab Diamond", link: "/about" },
    { name: "About Us", link: "/about" },
    // { name: "Snap And Post", link: "/snap-own-design" },
  ];

  const handleOpenMenu = () => {
    const ele = document.querySelector("#smMenu");
    ele.classList.add("left-0");
    ele.classList.remove("-left-[100%]");
  };

  const handleCloseMenu = () => {
    const ele = document.querySelector("#smMenu");
    ele.classList.remove("left-0");
    ele.classList.add("-left-[100%]");
  };

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleCheckout = () => {
    handleCloseCart();
    if (isLoggedIn) {
      navigate("/checkout");
    } else {
      navigate("/login", { state: { path: "/checkout" } });
    }
  };

  const handleLogout = () => {
    dispatch(clearAuth());
    localStorage.clear();
    navigate("/login");
  };

  const handleSelectcategory = (data) => {
    setSelectedcategory(data);
    fetchCategoryStyleData(data.unCategoryIdentifier);
  };

  const subCategoryImage = useMemo(() => {
    if (selectedSubCategory && categoryStyleData) {
      const subcategory = categoryStyleData.categories_style.find(
        (item) => item.unCategoryStyleIdentifier === selectedSubCategory
      );
      return subcategory?.stImageUrl || "";
    }
    return "";
  }, [selectedSubCategory, categoryStyleData]);

  const handleClickSubCategory = (style) => {
    navigate("/product", {
      state: {
        subCategory: {
          id: style.unCategoryStyleIdentifier,
          name: style.stCategoryStyleName,
          category: {
            id: selectedCategory.unCategoryIdentifier,
            name: selectedCategory.stCategoryName,
          },
        },
      },
    });
    setSelectedSubcategory(style);
  };

  const handleOpenCart = () => {
    dispatch(openCart(true));
  };

  const handleCloseCart = () => {
    dispatch(openCart(false));
  };

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  return (
    <>
      {/* TEMPORARY MARQUEEE STOP */}
      {/* 
      <div className="md:hidden block text-[16px] text-slate-900 font-normal text-center py-2 tracking-[2px]">
        <marquee>
          <a href="js:">Click to find out our latest promotion!</a>
        </marquee>
      </div> 
      */}

      <div className="bg-white sticky top-0 z-40">
        <div className="headerBg">
          {/* bg-[#e9e9ff] */}
          <div className="xl:max-w-[1224px] max-w-none mx-auto w-full xl:px-0 py-2 px-[20px] relative">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={handleOpenMenu}
                  className="p-1 -ml-1 rounded-md lg:hidden focus:outline-none"
                >
                  <AiOutlineMenu />
                </button>
                <div className="flex justify-center items-center">
                  {/* <Bars3Icon className="h-8 w-8 mt-0.5 hidden lg:block pr-1 text-[#1F2937] cursor-pointer" onClick={(e)=>navigate('/')} /> */}
                  <span
                    // onClick={(e) => navigate("/jewelry")}
                    className="flex items-center font-playfair font-[600] uppercase lg:text-[32px] sm:text-[26px] text-[24px] text-[#1F2937]"
                  >
                    DTN jewelry
                  </span>
                  {/* <header className="sm:text-center text-left md:text-3xl sm:text-xl text-[14px] md:my-2 mt-6 mb-3">
                    DTN
                    <span className="font-dancingScript font-bold ">
                      {" "}
                      {headerText}{" "}
                    </span>
                    LAB-GROWN JEWELRY
                  </header> */}
                </div>
              </div>
              {/* TEMPORARY MARQUEEE STOP */}
              {/* <div className="md:block hidden text-[16px] text-slate-900 font-normal tracking-[2px]">
              <marquee>
                <a href="js:">Click to find out our latest promotion!</a>
              </marquee>
            </div> */}

              {/* <div className="absolute top-[50%] -translate-y-1/2 xl:right-[15%] right-[12%] lg:flex hidden gap-5 xl:text-[16px] text-[14px]">
              <button
                type="button"
                onClick={() => navigate("/about")}
                className="p-1 -ml-1 rounded-md focus:outline-none"
              >
                Know Lab Diamond
              </button>
              <button
                type="button"
                onClick={() => navigate("/about")}
                className="p-1 -ml-1 rounded-md focus:outline-none"
              >
                About Us
              </button>
            </div> */}

              {/* <div className='lg:block hidden'> */}
              <div className="flex items-center justify-end sm:gap-[18px] gap-[14px] sm:w-[203px]">
                {/* <a href="js:">
              <img src={search} alt="search" />{" "}
            </a> */}

                {/* <GrHomeRounded
                  className="h-5 w-5 cursor-pointer text-[#1F2937]"
                  onClick={(e) => navigate("/home")}
                /> */}

                <span
                  onClick={() => handleOpenCart()}
                  className="cursor-pointer relative"
                >
                  <img src={cartIcon} alt="cart" className="h-5 w-5" />{" "}
                  {/* <CiShoppingCart className="h-6 w-6 cursor-pointer text-[#1F2937]" /> */}
                  <span className="flex justify-center items-center absolute -top-[45%] -right-[40%] w-4 h-4 rounded-full bg-[#1F2937] text-white text-xs">
                    {cart?.length}
                  </span>
                </span>

                {User && isLoggedIn ? (
                  <IsLoginHeader />
                ) : (
                  <Link to={"/admin"} className="">
                    <img src={login} alt="login" className="h-5 w-5" />{" "}
                    {/* <CiUser className="h-6 w-6 cursor-pointer text-[#1F2937]" /> */}
                  </Link>
                )}
                {/* <a href="js:">
              <img src={wichlist} alt="wichlist" />{" "}
            </a> */}
              </div>
              <Drawer
                anchor={"right"}
                open={cartDrawer}
                onClose={() => handleCloseCart()}
              >
                <div className="sm:w-[470px] w-[310px] pt-16 max-h-full h-full relative">
                  <div className="absolute top-0 left-0 z-50 flex justify-between w-full h-[54px] bg-[#F7F7F7] px-[15px] items-center">
                    <div className="flex gap-2 items-center">
                      <FiShoppingCart className="sm:text-[26px] text-[22px] font-[600]" />
                      <span className="ml-2 sm:text-[24px] text-[20px] leading-[52px] font-[600] ">
                        Cart
                      </span>
                    </div>
                    <span
                      onClick={() => handleCloseCart()}
                      className="cursor-pointer"
                    >
                      <IoMdCloseCircleOutline className="sm:text-[24px] text-[20px]" />
                    </span>
                  </div>
                  {!!cart?.length && (
                    <div className="absolute left-0 bottom-0 z-50 bg-[#334155] w-full text-[#FFFFFF] flex justify-between items-center px-[15px] ">
                      <button
                        onClick={handleCheckout}
                        className="sm:text-[18px] text-[16px] leading-[40px] font-[400] py-1 font-Josefin"
                      >
                        Checkout
                      </button>
                      {!!totalPrice && (
                        <span className="sm:text-[18px] text-[16px] leading-[33px] font-[400] font-Josefin">
                          ${totalPrice}
                        </span>
                      )}
                    </div>
                  )}
                  <div
                    className="max-h-full h-[85vh] overflow-y-auto"
                    id="cartScroll"
                  >
                    {cart.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="border-b-[1px] border-[#000000] flex justify-between items-center px-[15px] ">
                            <div className="flex items-center gap-1">
                              <button
                                onClick={handleRemoveItem.bind(null, index)}
                              >
                                <MdOutlineClose />
                              </button>
                              <h4 className="md:text-base sm:text-sm truncate max-w-[90%] text-xs py-1 font-[500] font-Josefin">
                                {item?.product?.title}
                              </h4>
                            </div>
                            {!!item?.total && (
                              <span className="sm:text-[20px] text-[18px] leading-[33px] font-[400] font-Josefin">
                                ${item?.total}
                              </span>
                            )}
                          </div>
                          <div className="sm:pl-[10px] px-0 sideCart">
                            <div className="relative flex justify-center gap-[15px] p-[10px] ">
                              {item?.product?.image && (
                                <img
                                  src={item?.product?.image}
                                  className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                                  alt="ring"
                                />
                              )}
                              {!item?.product?.image && (
                                <div className="h-[130px] w-[130px] bg-gray-200">
                                  <img
                                    src={item?.product?.image}
                                    className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                                    alt="ring"
                                  />
                                </div>
                              )}
                              <div className="w-full">
                                {item.product.DesignVariationId && (
                                  <div className="flex justify-between">
                                    <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[600] mb-[4px]  font-Josefin">
                                      {item?.product?.title}
                                    </h2>
                                    {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                  ${item?.product?.price}
                                </span> */}
                                  </div>
                                )}
                                <p className="sm:text-[15px] text-[13px] leading-[20px] font-[400] flex flex-col text-[#626262] font-Josefin">
                                  {item?.product?.attributes &&
                                    Object.entries(
                                      item?.product?.attributes
                                    ).map(([key, value], index) => {
                                      return (
                                        <span
                                          key={index}
                                          title={value?.name}
                                          className="max-w-[270px] truncate"
                                        >
                                          {key} : {value?.name}
                                        </span>
                                      );
                                    })}
                                  {item.product.DesignVariationId && (
                                    <span>Size : {item?.product?.size}</span>
                                  )}
                                  {!item.product.DesignVariationId && (
                                    <>
                                      <div className="flex justify-between">
                                        <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[500] text-black font-Josefin">
                                          {item?.product?.title}{" "}
                                          {item?.product?.source ===
                                          "calibrated"
                                            ? `- ${item?.product?.name}`
                                            : ``}
                                        </h2>
                                        {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                        ${item?.product?.price}
                                      </span> */}
                                      </div>
                                      <span className="text-[14px]">
                                        {item?.product?.stockNo}
                                      </span>
                                      {item?.product?.attribute?.length &&
                                        item?.product?.attribute.map(
                                          (items, i) => {
                                            return (
                                              <ul className="list-none" key={i}>
                                                <li className="text-[14px] font-[400] ">
                                                  <span className="font-[500]">
                                                    {
                                                      items.Attribute
                                                        .stAttributeStock
                                                    }
                                                  </span>{" "}
                                                  :- {items.AttributeValue}
                                                  {/* <span className="font-[300]">
                                                {data?.[item.stAttributeName]?.name}
                                              </span> */}
                                                </li>
                                              </ul>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                  {item?.product?.engraving && (
                                    <span
                                      title={item?.product?.engraving}
                                      className="max-w-[180px] truncate"
                                    >
                                      Engraving :{" "}
                                      <span className="font-Allura">
                                        {item?.product?.engraving}
                                      </span>
                                    </span>
                                  )}

                                  {item?.product?.source === "calibrated" && (
                                    <div className="flex flex-col">
                                      <span>
                                        Quantity : {`${item?.product?.pcs} Pcs`}{" "}
                                        / {`${item?.product?.carat} cts`}
                                      </span>
                                      <span>
                                        Color : {item?.product?.color}
                                      </span>
                                      <span>
                                        Clarity : {item?.product?.clarity}
                                      </span>
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
                            {!!item.diamond?.length &&
                              item.diamond.map((diamond, i) => {
                                return (
                                  <Fragment key={i}>
                                    <div className="relative flex justify-center gap-[15px] p-[20px] ">
                                      {diamond?.image && (
                                        <img
                                          src={diamond?.image}
                                          className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                                          alt="ring"
                                        />
                                      )}
                                      {!diamond?.image && (
                                        <div className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]">
                                          <img
                                            src={diamond?.ImageUrl}
                                            className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px] object-contain max-w-fit"
                                            alt="ring"
                                          />
                                        </div>
                                      )}
                                      <div className="w-full">
                                        <div className="flex justify-between">
                                          <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[600] mb-[4px]  font-Josefin">
                                            {diamond?.title}
                                          </h2>
                                          {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                          ${diamond?.price}
                                        </span> */}
                                        </div>
                                        <p className="sm:text-[15px] text-[13px] leading-[20px] font-[400] flex flex-col text-[#626262] font-Josefin">
                                          <span>Carat : {diamond?.carat}</span>
                                          <span>
                                            Colour : {diamond?.COLOUR || "N/A"}
                                          </span>
                                          <span>
                                            Clarity :{" "}
                                            {diamond?.CLARITY || "N/A"}
                                          </span>
                                          <span>
                                            Cut :{" "}
                                            {diamond?.CUTPOLISHSYM || "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
                                  </Fragment>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                    {!!!cart?.length && (
                      <div className="absolute top-1/2 -translate-y-1/2 text-md font-[600] text-slate-800 w-full flex justify-center items-center">
                        <div className="relative">
                          <div className="relative w-[90px] h-[90px] mx-auto mb-2">
                            <div className="w-[90px] h-[90px] bg-slate-200 rounded-full"></div>
                            <img
                              src={Cart}
                              alt="Cart"
                              className="w-[90px] rounded-full absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 h-[90px]"
                            />
                          </div>
                          <span>Your Cart is Empty</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Drawer>
              {/* </div> */}
            </div>
            <div
              id="smMenu"
              className={`bg-[#1F2937] absolute top-0 -left-[100%] min-h-screen z-50 w-[300px] duration-500 text-gray-100 px-4`}
            >
              <div className="py-3 flex justify-end">
                <IoMdClose
                  size={26}
                  className="cursor-pointer"
                  onClick={handleCloseMenu}
                />
              </div>
              <div className="mt-4 flex flex-col gap-4 relative">
                {menus?.map((menu, i) => (
                  <Link
                    to={menu?.link}
                    onClick={handleCloseMenu}
                    key={i}
                    className={` ${
                      menu?.margin && "mt-5"
                    } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
                  >
                    <h2
                      style={{
                        transitionDelay: `${i + 3}00ms`,
                      }}
                      className={`whitespace-pre duration-500`}
                    >
                      {menu?.name}
                    </h2>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
