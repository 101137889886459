import { Listbox, Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { useRef } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { BsCardChecklist, BsPlusLg } from "react-icons/bs";
import UploadCalibratedStock from "./Modals/UploadCalibratedStock";
import {
  DeleteStock,
  getDiamondStock,
  getDiamondStockFilterParameter,
} from "../../Services/diamondService";
import { fetchCalibratedData } from "../../store/Slices/calibratedSlice";
import Loader from "./common/Loader";
import { TbCertificate } from "react-icons/tb";
import ModalImage from "react-modal-image";
import Gai from "./../../assets/images/GIA.png";
import Igi from "./../../assets/images/IGI.png";
import {
  defaultCaratRange,
  numberRegexWithDecimals,
} from "../shared/constants";
import { useMemo } from "react";
import { useCallback } from "react";
import classNames from "classnames";
import { GrFormClose } from "react-icons/gr";
import { CgPlayButtonO } from "react-icons/cg";
import { TiArrowBack } from "react-icons/ti";
import { sortByID } from "../../Utils";
import useApi from "../../Hooks/use-Api";
import DiamondFilterDrawer from "../LandingComponent/DiamondFilterDrawer";
import VideoModal from "./Modals/VideoModal";
import Tooltip from "@mui/material/Tooltip";
import { GoDiffAdded, GoKebabVertical } from "react-icons/go";
import EditDiamond from "./Modals/EditDiamond";
import UploadStockImage from "./UploadStockImage";
import { Link, useNavigate } from "react-router-dom";
import AddDiamondStock from "./AddDiamondStock";
import { debounce } from "@mui/material";
import * as XLSX from "xlsx";
import Pagination from "./Pagination";
import usePagination from "../../Hooks/use-Pagination";

const actionOptions = [
  // { id: 1, title: "Download Stock Excel" },
  { id: 2, title: "Upload Stock Excel" },
  // { id: 3, title: "Mistake Stock Excel" },
];

const filterList = ["Shape", "Carat", "Color", "Clarity", "Location"];

const headerList = [
  "LOTNO",
  "SHAPE",
  "SHAPETYPE",
  "SIEVE",
  "MM",
  "COLOR",
  "CLARITY",
  "PCS",
  "CARAT",
  "RATE",
  "AMOUNT",
  "ORIGIN",
  "LOCATION",
];

const PremiumDtnDiamonds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [uploadStock, setUploadStock] = useState(false);
  const [stockData, setStockData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValue, setFilterValue] = useState(null);
  const [locationFilter, setLocationFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [value, setValue] = useState(defaultCaratRange);
  const [videoData, setVideoData] = useState({
    videoUrl: "",
    title: "",
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [filter, setFilter] = useState({
    shapeType: "all",
    selectedShapes: [],
    searchText: "",
  });

  const { shapeType, selectedShapes, searchText } = filter;

  const handleFilter = (key, value) => {
    if (key === "selectedShapes") {
      if (selectedShapes?.includes(value)) {
        setFilter((prevState) => ({
          ...prevState,
          selectedShapes: selectedShapes?.filter((i) => i !== value),
        }));
      } else {
        setFilter((prevState) => ({
          ...prevState,
          selectedShapes: [...selectedShapes, value],
        }));
      }
    } else
      setFilter((prevState) => ({
        ...prevState,
        [key]: value,
        selectedShapes: [],
      }));
  };

  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  const { Shapes, ShapeSieveMM } = useSelector(
    (state) => state?.calibrated?.data
  );

  const [state, setState] = useState({
    openModal: false,
    dataLoading: false,
  });

  let { openModal, dataLoading } = state;

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastPost = activePage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  // Check filter
  const filteredList = (list) => {
    let shapeCodes = [];

    if (shapeType === "sidepairs") {
      shapeCodes = Shapes?.filter((i) => i.ShapeType !== "Standard")?.map(
        (i) => i?.ShapeCode
      );
    } else if (shapeType === "standard") {
      shapeCodes = Shapes?.filter((i) => i.ShapeType === "Standard")?.map(
        (i) => i?.ShapeCode
      );
    } else {
      shapeCodes = Shapes?.map((i) => i?.ShapeCode);
    }

    let finalList =
      list?.filter(
        (i) =>
          shapeCodes
            ?.filter((item) =>
              selectedShapes?.length ? selectedShapes?.includes(item) : true
            )
            ?.includes(i?.ShapeCode) &&
          (i?.ShapeSize?.includes(searchText) ||
            i?.Lot?.toString()?.includes(searchText) ||
            i?.Pcs?.includes(searchText) ||
            i?.Carat?.includes(searchText))
      ) || [];

    return { list: finalList, shapeCodes };
  };

  const { currentData, currentDataCount, shapeCodes } = useMemo(() => {
    let { list, shapeCodes } = filteredList(ShapeSieveMM);

    return {
      currentData: list?.slice(indexOfFirstPost, indexOfLastPost),
      currentDataCount: list?.length,
      shapeCodes,
    };
  }, [Shapes, ShapeSieveMM, activePage, filter]);

  useEffect(() => {
    setActivePage(1);
  }, [filter]);

  useEffect(() => {
    if (filterParameters) {
      setValue([
        +filterParameters.carat[0].ID,
        +filterParameters.carat[0].Name,
      ]);
    }
  }, [filterParameters]);

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      openModal: !openModal,
    }));
  };

  const tabContent = useMemo(() => {
    return {
      shape: sortByID(filterParameters?.shape || [], "asc", "SrNo") || [],
      color: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      clarity: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
    };
  }, [filterParameters]);

  const DiamondStock = useCallback(
    async (page, limit) => {
      if (page === 1) {
        setStockData([]);
      }
      const Data = new FormData();
      if (filterValue && JSON.stringify(filterValue) !== "{}") {
        Data.append(
          "ShapeId",
          filterValue?.shape ? filterValue?.shape?.join(",") : ""
        );
        Data.append(
          "ColorId",
          filterValue?.color ? filterValue?.color.join(",") : ""
        );
        Data.append(
          "ClarityId",
          filterValue?.clarity ? filterValue?.clarity.join(",") : ""
        );
        Data.append("Cut", filterValue.cut || "");
        Data.append("FromCarat", filterValue?.fromCarat ?? "");
        Data.append("ToCarat", filterValue?.toCarat ?? "");
        Data.append("fromPrice", filterValue?.fromPrice ?? "");
        Data.append("toPrice", filterValue?.toPrice ?? "");
        Data.append("symmetry", filterValue?.symmetry || "");
        Data.append("polish", filterValue?.polish || "");
        Data.append("type", filterValue?.type || "");
        Data.append("LAB", filterValue.lab || "");
        Data.append("CERTID", filterValue.certi || "");
        Data.append("StockID", filterValue?.StockID || "");
      }
      Data.append("Location", locationFilter ?? "");
      Data.append("inPageNo", page);
      Data.append("inPageSize", limit || 10);
      setLoading(true);
      const response = await getDiamondStock(Data);
      setLoading(false);
      if (response.data.success) {
        setHasMore(!(response.data.data.stock?.length < 10));
        setStockData((prev) => [...prev, ...response.data.data.stock]);
      }
    },
    [filterValue, locationFilter]
  );

  // useEffect(() => {
  //   DiamondStock(pageNumber);
  // }, [pageNumber, DiamondStock]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleFilterChange = async (data, name) => {
    // setStockData([]);
    setPageNumber(1);
    // setSelectedFilter((prev) => {
    //   return {
    //     ...prev,
    //     [name]:
    //       name === "carat" ? (
    //         <span className="lowercase text-sm">
    //           {Number(data[0]).toFixed(2)} ct - {Number(data[1]).toFixed(2)} ct
    //         </span>
    //       ) : (
    //         data.Name
    //       ),
    //   };
    // });
    // setSelectedTab("");

    if (name === "carat") {
      setValue(data);
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: Number(data[0]),
        toCarat: Number(data[1]),
      }));
    } else {
      setFilterValue((prev) => {
        if (prev) {
          if (prev[name]) {
            return {
              ...prev,
              [name]:
                prev[name] && prev[name].includes(data.ID)
                  ? prev[name].filter((item) => item !== data.ID)
                  : prev[name].concat(data.ID),
            };
          } else {
            return {
              ...prev,
              [name]: [data.ID],
            };
          }
        }
        return { [name]: [data.ID] };
      });
    }
  };

  const handleRemoveFilter = (type) => {
    // setStockData([]);
    setPageNumber(1);
    const filterData = { ...filterValue };
    const selectedFilterData = { ...selectedFilter };
    delete selectedFilterData[type];
    setSelectedFilter(selectedFilterData);
    if (type === "carat") {
      setValue([filterParameters.carat[0].ID, filterParameters.carat[0].Name]);
      delete filterData.fromCarat;
      delete filterData.toCarat;
      setFilterValue(filterData);
    } else {
      delete filterData[type];
      setFilterValue(filterData);
    }
  };

  const handleVideoModalOpen = (data) => {
    setVideoData({
      videoUrl: data.VideoUrl,
      title: `Stock ID: ${data.StoneNo}`,
    });
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
    setVideoData({
      videoUrl: "",
      title: "",
    });
  };

  const downloadExcel = (dataList = [], fileName = "CalibratedStock") => {
    // Convert JSON to worksheet
    const ws = XLSX.utils.json_to_sheet([headerList, ...dataList], {
      skipHeader: true,
    });

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All");

    // Save the workbook as a blob
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const downloadStockSampleFile = () => {
    const dataList = [
      [
        1,
        "Triangle",
        "",
        "",
        "3.50*3.40",
        "DEF",
        "VVS",
        2,
        0.23,
        135,
        31.05,
        "",
        "BKK",
      ],
    ];

    downloadExcel(dataList, "CalibratedStock");
  };

  const downloadStockExcel = async () => {
    const Data = new FormData();
    Data.append("inPageNo", 1);
    Data.append("inPageSize", 10000);

    const response = await getDiamondStock(Data);
    if (response.data.success) {
      const dataList = response.data.data.stock?.map((item) => [
        item?.StoneNo,
        item?.LAB,
        item?.CERTID,
        item?.SHAPE,
        item?.CARAT,
        item?.COLOUR,
        item?.CLARITY,
        item?.CUTPOLISHSYM?.split(" ")?.[0],
        item?.CUTPOLISHSYM?.split(" ")?.[1],
        item?.CUTPOLISHSYM?.split(" ")?.[2],
        "",
        item?.MEASUREMENT?.split("x")?.[0],
        item?.MEASUREMENT?.split("x")?.[1],
        item?.MEASUREMENT?.split("x")?.[2],
        "",
        "",
        "",
        "",
        item?.RAP,
        item?.Back,
        item?.Price,
        item?.Amount,
        "",
        "",
        item?.Location,
        "",
        item?.ImageUrl,
        item?.VideoUrl,
        "",
      ]);

      downloadExcel(dataList, "StockData");
    }
  };

  useEffect(() => {
    if (selectedAction?.title === "Upload Stock Excel") {
      setUploadStock(true);
    }

    if (selectedAction?.title === "Download Stock Excel") {
      downloadStockExcel();
    }
  }, [selectedAction]);

  useEffect(() => {
    if (!uploadStock) {
      setSelectedAction(null);
    }
  }, [uploadStock]);

  const handleEdit = (id) => {
    setEditData(id);
    // setEditOpen(true);
    toggleModal();
  };

  const handleDelete = async (id) => {
    try {
      const res = await DeleteStock(id);
      if (res.status === 200) {
        toast.success("Stock Deleted.");
        DiamondStock(pageNumber);
      }
    } catch (error) {
      console.log("Error:", error.response);
    }
  };

  const handleStockId = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      StockID: e.target.value,
    }));
    setPageNumber(1);
  };

  const stockIdDebouncedOnChange = debounce(handleStockId, 700);

  const getData = async () => {
    await dispatch(fetchCalibratedData());
  };

  useEffect(() => {
    if (!Shapes) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      getData();
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [Shapes]);

  return (
    <>
      <AddDiamondStock
        open={openModal}
        handleClose={toggleModal}
        DiamondStock={DiamondStock}
        pageNumber={pageNumber}
        formMode={editData ? "edit" : "add"}
        item={editData}
      />

      <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100 pt-5">
        <UploadCalibratedStock
          isOpen={uploadStock}
          setIsOpen={setUploadStock}
          downloadStockSampleFile={downloadStockSampleFile}
        />
        <DiamondFilterDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setFilterValue={setFilterValue}
          filterParameters={filterParameters}
          setPageNumber={setPageNumber}
        />
        <EditDiamond
          isOpen={editOpen}
          setIsOpen={setEditOpen}
          editData={editData}
          setEditData={setEditData}
          fetchUpdate={DiamondStock.bind(null, 1)}
          setStockData={setStockData}
        />
        <VideoModal
          data={videoData}
          isOpen={videoModalOpen}
          onClose={handleVideoModalClose}
        />
        <UploadStockImage
          isOpen={uploadOpen}
          setIsOpen={setUploadOpen}
          stockData={stockData}
          DiamondStock={DiamondStock}
          dummyRef={dummyRef}
          lastDiamondElementRef={lastDiamondElementRef}
          loading={loading}
          Igi={Igi}
          Gai={Gai}
          fetchUpdate={DiamondStock.bind(null, 1)}
          handleVideoModalOpen={handleVideoModalOpen}
        />
        <main className="h-full overflow-y-auto">
          <div className="mx-auto px-6 pb-6">
            <div className="flex flex-col-reverse md:flex-row items-start md:items-center justify-between gap-[10px]">
              <div className="flex gap-2 flex-col md:flex-row mt-[-46px] md:mt-0">
                <Link
                  to={"/admin/transactionMenu"}
                  className="w-[80px] md:w-auto rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
                >
                  <TiArrowBack className="text-[18px]" /> Back
                </Link>

                <div className="flex flex-row">
                  <div class="inline-flex shadow-sm" role="group">
                    <button
                      type="button"
                      class={`inline-flex items-center px-4 py-2 text-[10px] md:text-sm font-medium text-gray-900 bg-transparent border border-[#cbd5e1] hover:bg-gray-200 focus:z-10 focus:ring-gray-500 focus:bg-gray-200 dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 ${
                        shapeType === "all" && "bg-[#e2e2e2]"
                      }`}
                      onClick={() => handleFilter("shapeType", "all")}
                    >
                      All
                    </button>
                    <button
                      type="button"
                      class={`inline-flex items-center px-4 py-2 text-[10px] md:text-sm font-medium text-gray-900 bg-transparent border-t border-b border-[#cbd5e1] hover:bg-gray-200 focus:z-10 focus:ring-gray-500 focus:bg-gray-200 dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 ${
                        shapeType === "standard" && "bg-[#e2e2e2]"
                      }`}
                      onClick={() => handleFilter("shapeType", "standard")}
                    >
                      Standard
                    </button>
                    <button
                      type="button"
                      class={`inline-flex items-center px-4 py-2 text-[10px] md:text-sm font-medium text-gray-900 bg-transparent border border-[#cbd5e1]  hover:bg-gray-200 focus:z-10 focus:ring-gray-500 focus:bg-gray-200 dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 ${
                        shapeType === "sidepairs" && "bg-[#e2e2e2]"
                      }`}
                      onClick={() => handleFilter("shapeType", "sidepairs")}
                    >
                      Side Pairs
                    </button>
                  </div>

                  <div className="ml-2 w-[85px] md:w-[150px] xl:w-[200px]">
                    <input
                      type="text"
                      className="px-2 py-0 h-full text-sm outline-none border border-gray-300 w-full bg-transparent cursor-pointer rounded-none"
                      onChange={(e) =>
                        handleFilter("searchText", e.target.value)
                      }
                      placeholder="Search.."
                      name="searchtext"
                    />
                  </div>
                </div>
              </div>

              {/* <button
                onClick={() => {
                  setEditData("");
                  toggleModal();
                }}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </button> */}

              <div className="flex flex-wrap items-end md:items-center gap-4 order-1 self-end">
                {/* <button
                onClick={() => setUploadOpen(!uploadOpen)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[10px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] "
              >
                Upload Image/Video
              </button> */}
                <Listbox value={selectedAction} onChange={setSelectedAction}>
                  <div className="relative">
                    <Listbox.Button className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] ">
                      <span className="block truncate">Action Dropdown</span>
                      <span className="pointer-events-none  flex items-center">
                        <ChevronUpDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {actionOptions?.length &&
                          actionOptions?.map((item, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                                  active
                                    ? "bg-[#edf4f8] text-gray-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={item}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {item?.title}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <div className="flex items-center justify-between gap-[10px]">
              <div className="flex items-center gap-2 mt-3 flex-wrap justify-center md:justify-start">
                {(shapeCodes || Shapes?.map((i) => i?.ShapeCode))?.map(
                  (item, index) => {
                    return (
                      <button
                        key={index}
                        disabled={loading}
                        onClick={() => {
                          handleFilter("selectedShapes", item);
                        }}
                        className={classNames(
                          "min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent hover:border-b-gray-400",
                          {
                            "border-b-gray-400 font-medium":
                              selectedShapes && selectedShapes.includes(item),
                          }
                        )}
                      >
                        {Shapes?.find((i) => i?.ShapeCode === item)?.Shape ||
                          "-"}
                      </button>
                    );
                  }
                )}
              </div>
            </div>

            <div className="hidden items-center justify-between lg:flex-row flex-col lg:gap-0 gap-4 mt-4">
              <div className="flex flex-wrap items-center  gap-4 order-1">
                {/* <button
                onClick={() => setUploadOpen(!uploadOpen)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[10px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] "
              >
                Upload Image/Video
              </button> */}
                <Listbox value={selectedAction} onChange={setSelectedAction}>
                  <div className="relative">
                    <Listbox.Button className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-between items-center gap-1 hover:bg-[#f8fafc] ">
                      <span className="block truncate">Action Dropdown</span>
                      <span className="pointer-events-none  flex items-center">
                        <ChevronUpDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {actionOptions?.length &&
                          actionOptions?.map((item, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                                  active
                                    ? "bg-[#edf4f8] text-gray-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={item}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {item?.title}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <div>
                <div className="flex justify-around sm:justify-start items-center gap-2 flex-wrap">
                  {filterList.map((filter, index) => {
                    const count = filterValue?.[filter.toLowerCase()]
                      ? filterValue?.[filter.toLowerCase()]?.length
                      : 0;
                    return (
                      <button
                        key={index}
                        className={classNames(
                          "min-w-[100px] relative font-openSans text-sm outline-none border border-gray-300 sm:hover:border-gray-700 uppercase",
                          {
                            "border-gray-700":
                              selectedTab === filter.toLowerCase(),
                          }
                        )}
                        onClick={() =>
                          setSelectedTab((prev) =>
                            prev === filter.toLowerCase()
                              ? ""
                              : filter.toLowerCase()
                          )
                        }
                      >
                        <span className="flex items-center mx-3 justify-around">
                          <span className="w-full">
                            {filter} {count ? `(${count})` : ""}{" "}
                            {filter === "Carat" && filterValue?.fromCarat
                              ? `(${filterValue?.fromCarat} - ${filterValue?.toCarat})`
                              : ""}
                          </span>
                          {filter === "Carat" ? (
                            filterValue?.fromCarat && (
                              <GrFormClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFilter(filter.toLowerCase());
                                }}
                                className="text-[20px] "
                              />
                            )
                          ) : filter === "Location" && locationFilter ? (
                            <GrFormClose
                              onClick={(e) => {
                                e.stopPropagation();
                                setLocationFilter("");
                              }}
                              className="text-[20px] "
                            />
                          ) : (
                            !!count && (
                              <GrFormClose
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFilter(filter.toLowerCase());
                                }}
                                className="text-[20px] "
                              />
                            )
                          )}
                        </span>
                      </button>
                    );
                  })}

                  <input
                    type="text"
                    className="px-2 py-0.5 max-w-[100px] text-xs outline-none border border-gray-300 w-3/4 bg-transparent cursor-pointer rounded-none"
                    onChange={stockIdDebouncedOnChange}
                    placeholder="STOCK ID"
                    name="StockID"
                  />

                  <button
                    className={
                      "flex gap-1 items-center min-w-[100px] px-3 font-openSans text-sm outline-none border border-gray-300 hover:border-gray-700 uppercase"
                    }
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <FiFilter /> Advanced Filter
                  </button>
                </div>
                {/* <div className="flex items-center gap-2 flex-wrap">
                  </div> */}
                {!!tabContent?.[selectedTab]?.length && (
                  <div className="flex items-center gap-2 mt-3 flex-wrap">
                    {tabContent?.[selectedTab].map((item, index) => {
                      return (
                        <button
                          key={index}
                          disabled={loading}
                          onClick={handleFilterChange.bind(
                            null,
                            item,
                            selectedTab
                          )}
                          className={classNames(
                            "min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent hover:border-b-gray-400",
                            {
                              "border-b-gray-400 font-medium":
                                filterValue?.[selectedTab] &&
                                filterValue?.[selectedTab].includes(item.ID),
                            }
                          )}
                        >
                          {item.Name}
                        </button>
                      );
                    })}
                  </div>
                )}
                {selectedTab === "carat" && (
                  <div className=" mt-3">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleFilterChange(value, "carat");
                      }}
                    >
                      <div className="flex gap-2 items-center ">
                        <div className="">
                          <label htmlFor="caratFrom">From : </label>
                          <input
                            type="text"
                            className="outline-none px-2 py-0.5 w-16 border rounded"
                            id="caratFrom"
                            value={value[0]}
                            name="caratFrom"
                            onChange={(e) => {
                              const { value: val } = e.target;
                              if (
                                numberRegexWithDecimals.test(val) &&
                                val < value[1]
                              ) {
                                setValue([val, value[1]]);
                              }
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="toFrom">To : </label>
                          <input
                            type="text"
                            className="outline-none px-2 py-0.5 w-16 border rounded"
                            id="toFrom"
                            name="toFrom"
                            value={value[1]}
                            onChange={(e) => {
                              const { value: val } = e.target;
                              if (numberRegexWithDecimals.test(val)) {
                                setValue([value[0], val]);
                              }
                            }}
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={loading}
                          className="disabled:opacity-80 disabled:cursor-not-allowed border border-gray-400 px-2 rounded text-sm bg-gray-500 text-white py-1"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {selectedTab === "location" && (
                  <div className="flex items-center gap-2 mt-3 flex-wrap">
                    {[...new Set(stockData?.map((i) => i?.Location))]?.map(
                      (item, index) => (
                        <button
                          key={index}
                          disabled={loading}
                          onClick={() => {
                            setLocationFilter(item);
                            setPageNumber(1);
                          }}
                          className={classNames(
                            `min-w-[50px] text-xs px-2 outline-none border-b border-b-transparent sm:hover:border-b-gray-400 ${
                              locationFilter === item &&
                              "border-b-gray-400 font-medium"
                            }`
                          )}
                        >
                          {item}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="shadow-md sm:rounded-lg overflow-hidden relative mt-4 rounded">
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-4 w-[20px]">
                        Sr.
                      </th>
                      <th scope="col" className="px-4 py-4 w-[200px]">
                        Shape
                      </th>
                      <th scope="col" className="px-4 py-4 w-[200px]">
                        Shape Size
                      </th>
                      <th scope="col" className="px-4 py-4 w-[200px]">
                        Lot
                      </th>
                      <th scope="col" className="px-4 py-4 w-[200px]">
                        Pcs
                      </th>
                      <th scope="col" className="px-4 py-4 w-[200px]">
                        Carat
                      </th>
                      {/* <th scope="col" className="px-4 py-4 w-[100px] text-center">
                      Action
                    </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      currentData?.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {i + 1}
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {
                                Shapes?.find(
                                  (i) => i?.ShapeCode === item?.ShapeCode
                                )?.Shape
                              }
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item?.ShapeSize}
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item?.Lot}
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item?.Pcs}
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item?.Carat}
                            </th>
                            {/* <td className="px-4 py-3">
                            <div className="flex items-center gap-[10px]">
                              <button
                                onClick={handleEdit.bind(
                                  null,
                                  item.unAttributeIdentifier
                                )}
                                type="button"
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              <button
                                type="button"
                                onClick={handleDelete.bind(
                                  null,
                                  item.unAttributeIdentifier
                                )}
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              <div className="flex items-center justify-end">
                                <Menu
                                  as="div"
                                  className="h-[20px] text-left relative"
                                >
                                  <Menu.Button>
                                    <GoKebabVertical className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[3px] rounded" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute top-3 z-50 right-0 mt-2 w-56  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="px-1 py-1 ">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-gray-900"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                              // onClick={() =>
                                              //   handleOpenAttribute(
                                              //     item.unAttributeIdentifier,
                                              //     item.stAttributeName,
                                              //     item.flgIsSelectDiamond
                                              //   )
                                              // }
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Attribute Value
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-[#1e293b]"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                              onClick={() =>
                                                navigate(
                                                  `/admin/attributevaluelist/${item.unAttributeIdentifier}`,
                                                  {
                                                    state: {
                                                      name: item.stAttributeName,
                                                      selectDiamond:
                                                        item.flgIsSelectDiamond,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              <BsCardChecklist className="text-[16px]" />
                                              Attribute Value List
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </td> */}
                          </tr>
                        );
                      })}
                    {loading && (
                      <tr className="bg-white">
                        <td colSpan={6} className="h-60">
                          <Loader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                totalItems={currentDataCount}
                activePage={activePage}
                itemsPerPage={itemsPerPage}
                setActivePage={setActivePage}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default PremiumDtnDiamonds;
