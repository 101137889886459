import api from "./api";

export const getDiamondStockFilterParameter = () => {
  return api("/api/StockFilterParameter", "get");
};
export const getDiamondStock = (Data) => {
  return api("/api/StockFilter", "post", Data);
};

export const diamondStockUpdate = (Data) => {
  return api("/api/StockUpdate", "post", Data);
};

export const getDiamondStockWithoutRate = (Data) => {
  return api("/api/StockFilterWithoutRate", "post", Data);
};

export const getDiamondStockWithoutRateMargin = (Data) => {
  return api("/api/StockFilterWithoutRateMargin", "post", Data);
};

export const getDiamondStockWithoutRateMarginJewelry = (Data) => {
  return api("/api/StockFilterWithoutRateMarginJewelry", "post", Data);
};

export const getDiamondStockwithId = (id) => {
  return api(`/api/DiamondStock/${id}`, "get");
};

export const getDiamondStockById = (id) => {
  return api(`/api/StockUpdateById/${id}`, "get");
};

export const AddDiamondStockData = (data) => {
  return api(`/api/StockUpload`, "post", data);
};

export const filterStockDiamonds = (data) => {
  return api(`/api/StockFilter`, "post", data);
};

export const UpdateDiamondStock = (data) => {
  return api(`/api/DiamondStock`, "put", data);
};

export const DeleteDiamondStock = (id) => {
  return api(`/api/DiamondStock/${id}`, "delete");
};

export const DeleteStock = (id) => {
  return api(`/api/StockDelete/${id}`, "delete");
};
