import React from "react";

const NoDataText = ({ text, height = "200px" }) => {
  return (
    <div
      className={`w-full h-[${height}] flex justify-center items-center text-gray-400 text-sm italic font-Poppins`}
    >
      {text}
    </div>
  );
};

export default NoDataText;
