import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "../store/Slices/AuthSlice";
import MainHeader from "../Components/LandingComponent/MainHeader";
import { clearSelectedShape } from "../store/Slices/calibratedSlice";
import { clearSelectedShape as clearDiamondSelectedShape } from "../store/Slices/diamondSelectSlice";
import { clearSelectedCategory } from "../store/Slices/categorySlice";
import GIAImage from "./../assets/images/home_gia_igi.png";
import CalibratedImage from "./../assets/images/home_calibrated.png";
import SidePairImage from "./../assets/images/home_side_pairs.png";
import InStoreImage from "./../assets/images/home_in_store.png";
import StyleWeMadeImage from "./../assets/images/home_style_we_made.png";
import MakeOwnDesign from "./../assets/images/home_make_your_design.png";
import RightArrow from "./../assets/images/icons/right_arrow_white.png";
import DiamondGray from "./../assets/images/icons/diamond_gray.png";
import DiamondWhite from "./../assets/images/icons/diamond_white.png";
import JewelryGray from "./../assets/images/icons/jewelry_gray.png";
import JewelryWhite from "./../assets/images/icons/jewelry_white.png";
import { FaArrowRight } from "react-icons/fa";

const diamondsList = [
  {
    title: "Certified",
    url: "/diamonds-shapes",
    imageURL: GIAImage,
  },
  {
    title: "Calibrated",
    url: "/calibrated-shapes?type=Standard",
    imageURL: CalibratedImage,
  },
  {
    title: "Side Pairs",
    url: "/calibrated-shapes?type=Pairs",
    imageURL: SidePairImage,
  },
];

const jewelryList = [
  {
    title: "In Store",
    url: "/category-selection?type=readyToShip",
    imageURL: InStoreImage,
  },
  {
    title: "Styles We Made",
    url: "/category-selection?type=customizedJewelry",
    imageURL: StyleWeMadeImage,
  },
  {
    title: "Make Your Design",
    url: "/category-selection?type=makeOwnDesign",
    imageURL: MakeOwnDesign,
  },
];

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector(getAuthState);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");

  const [state, setState] = useState({
    selectedTab: tab || "diamonds",
  });

  const { selectedTab } = state;

  const handleState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    dispatch(clearSelectedShape());
    dispatch(clearDiamondSelectedShape());
    dispatch(clearSelectedCategory());
  }, []);

  useEffect(() => {
    if (user?.stAccessType === "Diamonds Only") {
      handleState("selectedTab", "diamonds");
    } else if (user?.stAccessType === "Jewelry Only") {
      handleState("selectedTab", "jewelry");
    }
  }, [user?.stAccessType]);

  return (
    <div className="pb-4">
      <MainHeader hideSearch showHomeIcon />

      <div className="flex justify-center w-full p-3 pb-1">
        <div
          className={`w-full ${
            user?.stAccessType !== "All" ? "md:w-48" : "md:w-96"
          } flex items-center bg-gray-100 p-2 rounded-md`}
        >
          {(user?.stAccessType === "All" ||
            user?.stAccessType === "Diamonds Only") && (
            <button
              onClick={() => handleState("selectedTab", "diamonds")}
              className={`flex items-center justify-center text-[#999999] px-4 py-2 rounded-md focus:outline-none ${
                user?.stAccessType !== "All" ? "w-full" : "w-1/2"
              } font-[500] ${
                selectedTab === "diamonds" && "bg-[#295268] text-white"
              }`}
            >
              <img
                src={selectedTab === "diamonds" ? DiamondWhite : DiamondGray}
                className="pr-1"
              />
              Diamond
            </button>
          )}
          {(user?.stAccessType === "All" ||
            user?.stAccessType === "Jewelry Only") && (
            <button
              onClick={() => handleState("selectedTab", "jewelry")}
              className={`flex items-center justify-center text-[#999999] px-4 py-2 rounded-md focus:outline-none ${
                user?.stAccessType !== "All" ? "w-full" : "w-1/2"
              } font-[500] ${
                selectedTab === "jewelry" && "bg-[#295268] text-white"
              }`}
            >
              <img
                src={selectedTab === "jewelry" ? JewelryWhite : JewelryGray}
                className="pr-1"
              />
              Jewelry
            </button>
          )}
        </div>
      </div>

      {(user?.stAccessType === "All" ||
        user?.stAccessType === "Diamonds Only") &&
        selectedTab === "diamonds" && (
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="hidden md:block">
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img src={GIAImage} className="w-full h-full object-cover" />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] md:top-[40px] lg:top-[60px] xl:top-[75px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      Certified Diamond
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() => navigate("/diamonds-shapes")}
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
                <td
                  rowspan="2"
                  className="relative p-2 hover:scale-95 duration-200 hover:opacity-90"
                >
                  <img
                    src={CalibratedImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] md:top-[205px] lg:top-[280px] xl:top-[350px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      Calibrated Diamonds
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/calibrated-shapes?type=Standard")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={SidePairImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] md:top-[40px] lg:top-[60px] xl:top-[75px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      Side Pairs Diamond
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() => navigate("/calibrated-shapes?type=Pairs")}
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
              </tr>
            </table>

            <table className="md:hidden">
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img src={GIAImage} className="w-full h-full object-cover" />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] top-[30px] sm:top-[85px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      Certified Diamond
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[125px] cursor-pointer"
                      onClick={() => navigate("/diamonds-shapes")}
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={SidePairImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] top-[30px] sm:top-[85px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      Side Pairs Diamond
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[125px] cursor-pointer"
                      onClick={() => navigate("/calibrated-shapes?type=Pairs")}
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={CalibratedImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] top-[180px] sm:top-[370px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      Calibrated Diamonds
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[135px] cursor-pointer"
                      onClick={() =>
                        navigate("/calibrated-shapes?type=Standard")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        )}

      {(user?.stAccessType === "All" ||
        user?.stAccessType === "Jewelry Only") &&
        selectedTab === "jewelry" && (
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <table className="hidden md:block">
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={InStoreImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] md:top-[40px] lg:top-[60px] xl:top-[75px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      In Store Jewelry
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=readyToShip")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
                <td
                  rowspan="2"
                  className="relative p-2 hover:scale-95 duration-200 hover:opacity-90"
                >
                  <img
                    src={MakeOwnDesign}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[150px] left-[35px] md:top-[205px] lg:top-[280px] xl:top-[350px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      Make Your Design
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=makeOwnDesign")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={StyleWeMadeImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] md:top-[40px] lg:top-[60px] xl:top-[75px]">
                    <h3 className="font-semibold text-white md:text-xl lg:text-3xl">
                      Styles We Made
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-base text-white border border-white p-2 md:py-1 lg:py-3 rounded md:w-[110px] lg:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=customizedJewelry")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px] lg:w-[20px]" />
                    </p>
                  </div>
                </td>
              </tr>
            </table>

            <table className="md:hidden">
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={InStoreImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] left-[35px] top-[30px] sm:top-[85px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      In Store Jewelry
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=readyToShip")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={StyleWeMadeImage}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[135px] left-[35px] top-[30px] sm:top-[85px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      Styles We Made
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[125px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=customizedJewelry")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="relative p-2 hover:scale-95 duration-200 hover:opacity-90">
                  <img
                    src={MakeOwnDesign}
                    className="w-full h-full object-cover"
                  />

                  <div className="absolute inset-0 flex flex-col w-[140px] sm:w-[180px] left-[35px] top-[180px] sm:top-[370px]">
                    <h3 className="font-semibold text-white text-[20px] sm:text-[30px]">
                      Make Your Design
                    </h3>
                    <p
                      aria-hidden="true"
                      className="mt-2 flex items-center justify-around text-sm text-white border border-white p-2 py-1 sm:text-base sm:py-2 rounded w-[110px] sm:w-[135px] cursor-pointer"
                      onClick={() =>
                        navigate("/category-selection?type=makeOwnDesign")
                      }
                    >
                      View More
                      <img src={RightArrow} className="w-[14px]" />
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        )}
    </div>
  );
};

export default HomePage;
