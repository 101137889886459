import React from "react";
import { useEffect } from "react";
import { getCategoryStylebyCategoryId } from "../../Services/categoryStyleService";
import {
  fetchDesignByCategoryStyleId,
} from "../../store/Slices/designSlice";
import { useThunk } from "../../Hooks/use-thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CategoryStyle = ({ category, ctId }) => {
  const navigate = useNavigate();

  const [fetchDesignByCategoryStyle] = useThunk(fetchDesignByCategoryStyleId);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchCategoryData = async (id) => {
      const res = await getCategoryStylebyCategoryId(id);
      if (res.status === 200) {
        setCategoryData(res.data.data.categories_style);
      }
    };
    if (ctId) {
      fetchCategoryData(ctId);
    }
  }, [ctId]);

  return (
    <div className="w-full mx-auto sm:p-2 lg:p-4 pt-0 xl:p-0 my-[40px] mt-0">
      <h3 className="md:text-[30px] text-[26px] lg:leading-[42px] md:leading-[40px] leading-[30px] font-[600] font-playfair text-[#000] text-center lg:mb-[24px] md:mb-[16px] mb-[14px]">
        {category.toUpperCase()}
      </h3>
      <Slider {...settings}>
        {categoryData.length > 0
          ? categoryData.map((item, i) => {
              return (
                <div
                  key={i}
                  className="relative h-[46px] mr-[40px] rounded-[6px] bg-transparent overflow-hidden cursor-pointer"
                  onClick={()=>{fetchDesignByCategoryStyle(item?.unCategoryStyleIdentifier)}}
                  
                >
                  {/* <div className="sm:h-[232px] h-[160px] flex justify-center">
                    <img
                      src={item.stImageUrl}
                      alt="Product1"
                      className="w-[100%] h-[50%] object-cover"
                    />
                  </div> */}
                  <div>
                  {!!item?.stImageUrl && <img
                      src={item.stImageUrl}
                      alt="Product1"
                      className="w-[100%] h-[160.2px] object-cover"
                    />}
                  </div>
                  {/* <h3 className="text-[14px] backdrop-blur-sm border-[3px] bg-none m-0.5 leading-[24px]  font-Josefin text-gray-800 text-center py-[6px] z-40 bg-[#FFF]" style={{borderImageSlice: 1, borderImageSource: 'linear-gradient(to left, #4a98e9, #e94aab, #31919b)'}}> */}
                  <h3 className="text-[14px] bottomBg backdrop-blur-sm border-[#e3e3e3] leading-[28px] font-[700] font-Josefin text-[#000] text-center py-[6px] z-40">
                    {item.stCategoryStyleName}
                  </h3>
                </div>
              );
            })
          : ""}
      </Slider>
    </div>
  );
};

export default CategoryStyle;
