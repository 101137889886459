import React from "react";
import search from "../../assets/images/search.svg";
import login from "../../assets/images/login.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchMenu } from "../../store/Slices/menuSlice";
import { clearAuth, getAuthState } from "../../store/Slices/AuthSlice";
import { MdPowerSettingsNew } from "react-icons/md";
import { GrHomeRounded } from "react-icons/gr";
import IsLoginHeader from "./IsLoginHeader";
import { IoSearch } from "react-icons/io5";
import { IoPersonAdd } from "react-icons/io5";
import { GoSearch } from "react-icons/go";

const roles = ["agent", "user"];

const MainHeader = (props) => {
  const dispatch = useDispatch();
  const User = JSON.parse(localStorage.getItem("name"));
  const { isLoggedIn } = useSelector(getAuthState);
  const navigate = useNavigate();

  const { hideSearch, showHomeIcon } = props;

  useEffect(() => {
    dispatch(fetchMenu());
  }, [dispatch]);

  return (
    <div className="landing__footer sticky top-0 z-50 bg-white">
      <div className="max-w-[1232px] w-full xl:mx-auto mx-0 xl:px-0 px-4">
        <div className="grid grid-cols-6 relative">
          <div className="col-span-6 sm:py-4 py-6">
            {/* <Link to={"/jewelry"} className=""> */}
              <div>
                <span className="font-montserrat font-[700] lg:text-[30px] sm:text-[24px] uppercase block text-[12.5px] text-[#000] sm:text-center text-left">
                  Diamond Trading Network
                </span>
              </div>
            {/* </Link> */}
          </div>

          <div className="absolute top-[50%] z-50 sm:right-0 right-2 transform -translate-y-[50%] flex items-center justify-end sm:gap-[20px] gap-[12px]">
            {!hideSearch && (
              <a href="js:" className="pointer-events-none">
                {/* <img src={search} alt="search" />{" "} */}
                <GoSearch className="h-5 w-5 cursor-pointer text-[#1F2937]" />
              </a>
            )}

            {showHomeIcon && (
              <GrHomeRounded
                className="h-5 w-5 cursor-pointer text-[#1F2937]"
                onClick={(e) => navigate("/")}
              />
            )}

            {User && isLoggedIn ? (
              // <div className="relative group">
              //   <button className="rounded-full blur w-[45px] h-[45px] border flex items-center justify-center bg-gray-300 duration-300 text-[#334155] font-bold outline-none"></button>
              //   <div className="absolute top-[50%] -translate-y-[50%] text-[#334155] font-bold left-[50%] -translate-x-[50%]">
              //     <span className="cursor-pointer">
              //       {(User?.stFirstName?.[0] || "") +
              //         (User?.stLastName?.[0] || "")}
              //     </span>
              //     <ul
              //       className="absolute z-[60] h-auto top-full invisible opacity-0 transform -translate-y-[10px] group-hover:visible group-hover:opacity-100 group-hover:translate-y-2 left-[50%] -translate-x-[50%] duration-200 bg-white rounded-lg text-[#000000DE] font-medium text-base"
              //       aria-hidden="true"
              //     >
              //       {User.Role.toLowerCase() === "user" && (
              //         <li className="py-2 px-4 hover:bg-[#0000000a]">
              //           <Link
              //             to={"/admin"}
              //             className=""
              //           >
              //             Dashboard
              //           </Link>
              //         </li>
              //       )}
              //       <li className="py-2 px-4 hover:bg-[#0000000a] ">
              //         {" "}
              //         <button
              //           onClick={handleLogout}
              //           className="flex justify-center items-center gap-1 outline-none bg-transparent "
              //         >
              //           <MdPowerSettingsNew /> Logout
              //         </button>
              //       </li>
              //     </ul>
              //   </div>
              // </div>
              <IsLoginHeader />
            ) : (
              <Link to={"/admin"} className="">
                <img src={login} alt="login" className="h-5 w-5" />{" "}
                {/* <IoPersonAdd className="h-[22px] w-[22px] cursor-pointer text-[#1F2937]" /> */}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
