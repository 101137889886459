import React, { useState } from "react";
import { MdManageAccounts, MdMenu } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { BsBookmarkDash } from "react-icons/bs";
import { TbTools } from "react-icons/tb";
import { FiShoppingCart, FiUser } from "react-icons/fi";
import { CgLogOut } from "react-icons/cg";
import { AiOutlineHome } from "react-icons/ai";
import { TbLayoutDashboard } from "react-icons/tb";
import { Menu, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "../../store/Slices/AuthSlice";
import useRolePermission from "../../Hooks/use-RolePermission";
import { useMemo } from "react";

const Header = () => {
  const [headerName, setHeaderName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { hasPermission, accessableRoutes } = useSelector(s => s.rolePermissions.rolePermissions)
  // const [hasPermission, accessableRoutes] = useRolePermission(
  //   location.pathname
  // );
  const UserData = localStorage.getItem("name")
    ? JSON?.parse(localStorage.getItem("name"))
    : null;

  useEffect(() => {
    const path = location.pathname.split("/")?.pop();
    switch (path) {
      case "dashboard":
        setHeaderName("Dashboard");
        break;
      case "masterMenu":
        setHeaderName("Master");
        break;
      case "transactionMenu":
        setHeaderName("Transaction");
        break;
      case "reportMenu":
        setHeaderName("Report");
        break;
      case "utilityMenu":
        setHeaderName("Utility");
        break;
      case "attributelist":
        setHeaderName("Attribute List");
        break;
      case "attributevaluelist":
        setHeaderName("Attribute Value List");
        break;
      case "product":
        setHeaderName("Customize Jewelry List");
        break;
      case "readytoship":
        setHeaderName("Ready To Ship List");
        break;
      case "mounting":
        setHeaderName("Mounting");
        break;
      case "snap&postlist":
        setHeaderName("Snap & Post List");
        break;
      case "order":
        setHeaderName("Order List");
        break;
      case "orderlist":
        setHeaderName("Order List");
        break;
      case "placeorderlist":
        setHeaderName("Place Order List");
        break;
      case "diamondStockList":
        setHeaderName("Diamond List");
        break;
      case "sideStonePriceList":
        setHeaderName("Side Stone Price List");
        break;
      case "metalPrice":
        setHeaderName("Metal Price Update");
        break;
      case "supplierlist":
        setHeaderName("Supplier List");
        break;
      case "designerlist":
        setHeaderName("Designer List");
        break;
      case "taglist":
        setHeaderName("Tag List");
        break;
      case "StockClarityList":
        setHeaderName("Clarity List");
        break;
      case "StockColorList":
        setHeaderName("Color List");
        break;
      case "StockSizeList":
        setHeaderName("Size List");
        break;
      case "user-role":
        setHeaderName("User Role List");
        break;
      case "categorylist":
        setHeaderName("Category List");
        break;
      case "categoryStylelist":
        setHeaderName("Category Style List");
        break;
      case "agent-selection":
        setHeaderName("Select Design");
        break;
      case "agent":
        setHeaderName("Customize Design");
        break;
      case "agentMenu":
        setHeaderName("Agent");
        break;
      case "tool":
        setHeaderName("Tools");
        break;
      default:
        setHeaderName("");
    }
  }, [location.pathname]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearAuth());
    navigate("/login");
  };

  const menus = useMemo(
    () => [
      {
        name: "Dashboard",
        // show: accessableRoutes.includes("/admin/dashboard"),
        link: "/admin/dashboard",
        icon: <MdOutlineDashboard size={20} />,
      },
      {
        name: "Master",
        // show: accessableRoutes.includes("/admin/masterMenu"),
        link: "/admin/masterMenu",
        icon: <SlBadge size={20} />,
      },
      {
        name: "Transaction",
        // show: accessableRoutes.includes("/admin/transactionMenu"),
        link: "/admin/transactionMenu",
        icon: <BsBookmarkDash size={20} />,
      },
      {
        name: "Order",
        // show: accessableRoutes.includes("/admin/orderlist"),
        link: "/admin/orderlist",
        icon: <FiShoppingCart size={20} />,
      },
      {
        name: "Report",
        // show: accessableRoutes.includes("/admin/reportMenu"),
        link: "/admin/reportMenu",
        icon: <HiOutlineClipboardDocumentList size={20} />,
      },
      {
        name: "Utility",
        // show: accessableRoutes.includes("/admin/utilityMenu"),
        link: "/admin/utilityMenu",
        icon: <RiSettings4Line size={20} />,
      },
      {
        name: "Agent",
        // show: accessableRoutes.includes("/admin/agent"),
        link: "/admin/agent",
        icon: <MdManageAccounts size={20} />,
      },
      {
        name: "Tools",
        // show: accessableRoutes.includes("/admin/tool"),
        link: "/admin/tool",
        icon: <TbTools size={20} />,
      },
    ],
    []
  );

  const menuList = useMemo(() => {
    if (accessableRoutes) {
      return menus.filter((item) => accessableRoutes.includes(item.link));
    }
    return [];
  }, [accessableRoutes, menus]);

  const handleOpenMenu = () => {
    const ele = document.querySelector("#smMenu");
    ele.classList.add("left-0");
    ele.classList.remove("-left-[100%]");
  };

  const handleCloseMenu = () => {
    const ele = document.querySelector("#smMenu");
    ele.classList.remove("left-0");
    ele.classList.add("-left-[100%]");
  };

  return (
    <div className="sticky top-0 z-10 ">
      <header className=" relative py-3 bg-[#f3f4f6] ">
        <div className=" flex items-center justify-between h-full px-6 mx-auto">
          <button
            onClick={handleOpenMenu}
            type="button"
            className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none"
          >
            <MdMenu />
          </button>
          <div
            id="smMenu"
            className={`bg-[#0e0e0e] absolute top-0 -left-[100%] min-h-screen z-50 w-[300px] duration-500 text-gray-100 px-4`}
          >
            <div className="py-3 flex justify-end">
              <HiMenuAlt3
                size={26}
                className="cursor-pointer"
                onClick={handleCloseMenu}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1 relative">
              {menuList?.map((menu, i) => (
                <Link
                  to={menu?.link}
                  onClick={handleCloseMenu}
                  key={i}
                  className={` ${
                    menu?.margin && "mt-5"
                  } group flex items-center text-sm gap-3.5 font-medium px-3 py-4 hover:bg-gray-800 duration-300`}
                >
                  <div>{menu?.icon}</div>
                  <h2
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`whitespace-pre duration-500`}
                  >
                    {menu?.name}
                  </h2>
                </Link>
              ))}
            </div>
          </div>
          <span className="sm:text-2xl text-base text-black font-[600] font-Josefin">
            {headerName}
          </span>
          <ul className="flex justify-end items-center flex-shrink-0 space-x-6">
            <li className="relative inline-block text-left">
              <button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="rounded-full hover:bg-transparent text-white font-medium mx-auto outline-none"
              >
                <div
                  className="relative rounded-full inline-block align-middle"
                  aria-hidden="true"
                >
                  <div className="w-[45px] h-[45px] rounded-full border flex items-center justify-center bg-[#cbd5e1] text-[#334155] font-bold capitalize">
                    {UserData?.socialProvider
                      ? UserData?.stUserName?.[0]
                      : (UserData?.stFirstName?.[0] || "") +
                        (UserData?.stLastName?.[0] || "")}
                  </div>
                </div>
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem className="textFont" onClick={() => navigate("/home")}>
                  <AiOutlineHome
                    className="mr-2 h-[17.5px] w-[17.5px] text-gray-700"
                    aria-hidden="true"
                  />
                  Go to Home
                </MenuItem>
                <MenuItem
                  className="textFont"
                  onClick={() => {
                    navigate("/admin/profile");
                    handleClose();
                  }}
                >
                  <FiUser
                    className="mr-2 h-[17.5px] w-[17.5px] text-gray-700"
                    aria-hidden="true"
                  />
                  Profile
                </MenuItem>
                <MenuItem className="textFont" onClick={handleClose}>
                  <TbLayoutDashboard
                    className="mr-2 h-[17.5px] w-[17.5px] text-gray-700"
                    aria-hidden="true"
                  />
                  My account
                </MenuItem>
                <MenuItem className="textFont" onClick={handleLogout}>
                  <CgLogOut
                    className="mr-2 h-[17.5px] w-[17.5px] text-gray-700"
                    aria-hidden="true"
                  />
                  Logout
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
