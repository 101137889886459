import api from "./api";

const getDesignersData = () => api("/api/Designers", "get")
const getDesignersDataById = (id) => api(`/api/Designers/${id}`, "get")
const createDesignersData = (data) => api("/api/Designers", "post", data)
const updateDesignersData = (data) => api("/api/Designers", "put", data)
const deleteDesignersData = (id) => api(`/api/Designers/${id}`, "delete")

export {
  getDesignersData,
  getDesignersDataById,
  createDesignersData,
  updateDesignersData,
  deleteDesignersData,
}