import React from "react";
import { useNavigate } from "react-router";
import { HiListBullet, HiMiniPaintBrush } from "react-icons/hi2";
import {
  MdOutlineColorLens,
  MdOutlineSelectAll,
  MdRebaseEdit,
  MdCallReceived,
  MdCardMembership,
} from "react-icons/md";
import { BsArrowsExpand } from "react-icons/bs";
import { AiOutlineExpand, AiOutlineAntDesign } from "react-icons/ai";
import { IoDiamondOutline } from "react-icons/io5";
import {
  FaUserFriends,
  FaRegListAlt,
  FaUsers,
  FaTag,
  FaBoxes,
} from "react-icons/fa";
import { GiDiamondHard } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { BiMoney } from "react-icons/bi";

const renderIcon = (title) => {
  const iconClass = "h-5 w-5 text-gray-700";

  switch (title?.toLowerCase()) {
    case "attribute":
    case "menu":
      return <HiListBullet className={iconClass} />;
    case "color":
      return <MdOutlineColorLens className={iconClass} />;
    case "size":
      return <BsArrowsExpand className={iconClass} />;
    case "clarity":
      return <AiOutlineExpand className={iconClass} />;
    case "diamond list":
      return <IoDiamondOutline className={iconClass} />;
    case "user":
      return <FaUserFriends className={iconClass} />;
    case "customize design":
      return <AiOutlineAntDesign className={iconClass} />;
    case "mounting":
    case "select design":
      return <MdOutlineSelectAll className={iconClass} />;
    case "calibrated stock":
      return <AiOutlineAntDesign className={iconClass} />;
    case "category":
    case "place order list":
      return <FaRegListAlt className={iconClass} />;
    case "supplier":
      return <FaUsers className={iconClass} />;
    case "designer":
      return <AiOutlineAntDesign className={iconClass} />;
    case "tag manager":
      return <FaTag className={iconClass} />;
    case "snap & post":
      return <FaTag className={iconClass} />;
    case "order list":
      return <FaBoxes className={iconClass} />;
    case "diamond request":
      return <MdCallReceived className={iconClass} />;
    case "customize jewelry":
      return <GiDiamondHard className={iconClass} />;
    case "ready to ship":
      return <MdCardMembership className={iconClass} />;
    case "calibrated stock":
      return <FaTag className={iconClass} />;
    case "snap own post":
      return <TbReportAnalytics className={iconClass} />;
    case "report":
      return <TbReportAnalytics className={iconClass} />;
    case "order report":
      return <TbReportAnalytics className={iconClass} />;
    case "side stone price":
    case "metal price":
      return <BiMoney className={iconClass} />;
    default:
  }
};

const MenuCard = ({ children, title, className = "", link, icon }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate(link)}
      className={`min-w-[260px] block p-4 bg-white border shadow shadow-gray-100 hover:shadow-2xl duration-300 ${className}`}
    >
      <div>
        <div className="flex gap-[10px] items-center">
          <div>
            <span className="w-[40px] h-[40px] rounded-[5px] bg-[#fff] flex items-center justify-center ">
              {renderIcon(title)}
            </span>
          </div>
          <div>
            <h3 className="font-[500] text-[15px] text-gray-600 ">{title}</h3>
          </div>
        </div>
        {children}
      </div>
    </button>
  );
};

export default MenuCard;
