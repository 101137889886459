import api from "./api"

const getTags = () => {
  return api("/api/Tags", "get")
}

const getTagsById = (id) => {
  return api(`/api/Tags/${id}`, "get")
}

const createTags = (data) => {
  return api("/api/Tags", "post", data)
}

const updateTags = (data) => {
  return api("/api/Tags", "put", data)
}

const DeleteTags = (data) => {
  return api(`/api/Tags/${data}`, "delete")
}

export {
  getTags,
  getTagsById,
  createTags,
  updateTags,
  DeleteTags
}