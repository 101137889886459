import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSelectedShape,
  clearSelectedShape,
} from "../../store/Slices/diamondSelectSlice";
import { getDiamondStockFilterParameter } from "../../Services/diamondService";
import useApi from "../../Hooks/use-Api";
import { AiOutlineClose } from "react-icons/ai";
import MainHeader from "../../Components/LandingComponent/MainHeader";
import CloseIcon from "../../assets/images/icons/close_blue.png";
import "./DiamondShapSelection.css";

function DiamondShapSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: false,
  });

  const { loading } = state;

  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  const shapes = useMemo(() => {
    return filterParameters?.shape;
  }, [filterParameters]);

  const selectedShapes = useSelector(
    (state) => state.selectedDiamond.selectedShape
  );

  const getData = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    await fetchFilterParameters();
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  useEffect(() => {
    getData();
    dispatch(clearSelectedShape());
  }, [fetchFilterParameters]);

  useEffect(() => {
    if (selectedShapes?.length) {
      navigate("/diamonds");
    }
  }, [selectedShapes]);

  useEffect(() => {
    dispatch(clearSelectedShape());
  }, []);

  return (
    <>
      <MainHeader hideSearch showHomeIcon />

      <div className="flex flex-col bg-[#F5F7FA] items-center pt-6 2xl:h-[calc(100vh-78px)] lg:min-h-[calc(100vh-78px)] h-full overflow-y-scroll">
        <div className="w-full flex justify-center">
          {/* <p className="mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
            Select Shape{" "}
            {!!selectedShapes?.length &&
              `( ${selectedShapes?.length || 0} / ${shapes?.length} )`}
          </p> */}

          <div className="w-full px-4 xl:px-0 xl:w-[864px] flex justify-between items-center">
            <p className="mb-2 text-sm font-montserrat font-[700] text-[#023254] dark:text-gray-400 min-w-fit">
              SELECT CERTIFIED SHAPE
            </p>

            <div className="hidden md:block border-t border-[#D2D3D6] h-[1px] w-full -mt-[6px] mx-4"></div>

            {/* <p className="mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
              {`( ${selectedShapes?.length || 0} / ${shapes?.length || 0} )`}
            </p> */}

            {/* <p className="mb-2 mr-[70px] text-sm font-montserrat font-[500] text-[#023254] underline underline-offset-[5px]">
              Certified
            </p> */}

            <img
              src={CloseIcon}
              className="h-[18px] w-[18px] md:h-[18px] md:w-[19px] cursor-pointer mb-1 md:mb-2"
              onClick={(e) => navigate("/home")}
            />
          </div>
        </div>

        {/* <div className="pb-2 md:pb-3">
          <p className="text-base md:text-lg text-gray-500 underline underline-offset-[5px]">
            Certified
          </p>
        </div> */}

        {loading ? (
          <div role="status" className="py-3">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-800"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
              {shapes?.map((item) => {
                let isSelected = selectedShapes?.find(
                  (i) => i.shapeCode == item?.ID
                );

                return (
                  <div
                    className={`zoom-in-out-box flex flex-col items-center gap-x-4 bg-white hover:bg-[#295268] text-[#295268] hover:text-white w-40 h-40 py-6 px-6 justify-evenly cursor-pointer border shadow shadow-gray-100 hover:shadow-xl duration-300 rounded-xl ${
                      isSelected && "border-gray-800"
                    }`}
                    onClick={(e) => {
                      dispatch(
                        setSelectedShape({
                          key: -1,
                          shapeCode: item?.ID,
                          name: item?.Name,
                          href: "#",
                          icon: item?.ImageURL,
                          current: false,
                          shapeSieveMM: "",
                        })
                      );
                    }}
                  >
                    <div className="h-[80px] w-[80px] p-0 flex-none rounded-full grid place-content-center">
                      <img
                        src={`${item.ImageURL || "/images/icons/Asscher.png"}`}
                        alt={item?.Shape}
                        className="h-[80px] w-[80px] p-1 object-contain"
                      />
                    </div>
                    <div className="text-[12px] md:text-sm font-medium leading-6 mt-2 uppercase truncate">
                      {item?.Name?.toLowerCase() || "N/A"}
                    </div>
                  </div>
                );
              })}

              {/* <div
                className={`zoom-in-out-box flex flex-col items-center gap-x-4 bg-white hover:bg-gray-50 w-40 h-40 py-6 px-6 justify-evenly cursor-pointer border shadow shadow-gray-100 hover:shadow-xl duration-300`}
                onClick={(e) => navigate("/diamonds")}
              >
                <div className="h-14 w-14 p-0 flex-none rounded-full ring-1 ring-gray-900/10 grid place-content-center">
                  <img
                    src={AllIcon}
                    alt="all"
                    className="h-12 p-2 opacity-60"
                  />
                </div>
                <div className="text-[12px] md:text-sm font-medium leading-6 text-gray-700 mt-2 uppercase truncate">
                  ALL
                </div>
              </div> */}
            </div>
          </>
        )}

        {!!selectedShapes?.length && false && (
          <div className="w-full flex justify-center fixed bottom-5">
            <button
              type="button"
              class="zoom-in-out-box text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-16 py-3 mb-4 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              onClick={() => navigate("/diamonds")}
            >
              <p className="mb-1">
                Next <span aria-hidden="true">→</span>
              </p>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default DiamondShapSelection;
