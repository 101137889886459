import React from "react";
import Skeleton from "react-loading-skeleton";

const DiamondListDesktopSkeleton = () => {
  return (
    <div className="bg-white flex items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
      <div className="w-full">
        <div className="w-full grid grid-cols-8 gap-2">
          <div className="mb-2 col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1 mb-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="mb-2 col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="mb-2 col-span-1">
            <Skeleton height={"100%"} />
          </div>
          <div className="mb-2 col-span-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="mb-2 col-span-2">
            <Skeleton height={"100%"} />
          </div>
        </div>
        <div className="w-full grid grid-cols-8 gap-2">
          <div className="mt-2 sm:col-span-1 col-span-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-2 mt-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-1 mt-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="col-span-2 mt-2">
            <Skeleton height={"100%"} />
          </div>
          <div className="mt-2 col-span-2">
            <Skeleton height={"100%"} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 lg:gap-4 gap-2 items-center">
        <div className="sm:w-10 sm:h-10 w-14 h-14">
          <Skeleton height={"100%"} />
        </div>
        <div className="sm:w-10 sm:h-10 w-14 h-14">
          <Skeleton height={"100%"} />
        </div>
        <div className="sm:w-10 sm:h-10 w-14 h-14">
          <Skeleton height={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default DiamondListDesktopSkeleton;
