import React from "react";
import useApi from "../../../Hooks/use-Api";
import { getDiamondStockFilterParameter } from "../../../Services/diamondService";
import { useEffect } from "react";
import {
  defaultCaratRange,
  defaultPriceRange,
  numberRegex,
  numberRegexWithDecimals,
} from "../../shared/constants";
import { useState } from "react";
import { sortByID } from "../../../Utils";
import { useMemo } from "react";
import shap1 from "./../../../assets/images/shap1.png";
import { Drawer, Slider } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { BsPlusCircleDotted } from "react-icons/bs";
import { BsDashCircleDotted } from "react-icons/bs";
import DropdownMenu from "../Common/DropdownMenu";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { IoCloseSharp } from "react-icons/io5";

function valuetext(value) {
  return `${value}°C`;
}

const DiamondFilters = ({
  setFilterValue,
  filterValue,
  loading,
  setPageNumber,
  hideChat = () => {},
  stockData,
}) => {
  const [value, setValue] = useState(defaultCaratRange);
  const [priceValue, setPriceValue] = useState(defaultPriceRange);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [debounceFields, setDebounceFields] = useState({
    StockID: "",
    certi: "",
  });
  const [formData, setFormData] = useState({
    cut: "",
    lab: "",
    type: "",
    polish: "",
    symmetry: "",
    shape: [],
    color: [],
    clarity: [],
    certi: "",
    StockID: "",
  });

  useEffect(() => {
    hideChat(selectedFilter);
  }, [selectedFilter, hideChat]);

  const [fetchFilterParameters, filterParameters, filterLoading] = useApi(
    getDiamondStockFilterParameter
  );

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  useEffect(() => {
    if (filterParameters) {
      setValue([
        parseFloat(filterParameters.carat[0].ID),
        parseFloat(filterParameters.carat[0].Name),
      ]);
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
    }
  }, [filterParameters]);

  const tabContent = useMemo(() => {
    return {
      shape: sortByID(filterParameters?.shape || [], "asc", "SrNo") || [],
      color: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      clarity: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
      cut: sortByID(filterParameters?.cut || [], "asc", "SrNo") || [],
      polish: sortByID(filterParameters?.polish || [], "asc", "SrNo") || [],
      symmetry: sortByID(filterParameters?.sym || [], "asc", "SrNo") || [],
    };
  }, [filterParameters]);

  const handleFilterChange = async (data, name) => {
    setPageNumber(1);

    const multiFilters = [
      "shape",
      "color",
      "clarity",
      "cut",
      "polish",
      "symmetry",
    ];

    if (multiFilters.includes(name)) {
      const prevData =
        filterValue && filterValue[name] && filterValue[name].split(",");

      setFilterValue((prev) => {
        if (prevData) {
          return {
            ...prev,
            [name]: prevData.includes(data)
              ? prevData.filter((item) => item !== data).join(",")
              : prevData.concat(data).join(","),
          };
        } else {
          if (prev) {
            return { ...prev, [name]: data };
          } else {
            return { [name]: data };
          }
        }
      });
    } else if (name === "carat") {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, fromCarat: data[0], toCarat: data[1] };
        }
        return { fromCarat: data[0], toCarat: data[1] };
      });
    } else if (name === "price") {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, fromPrice: data[0], toPrice: data[1] };
        }
        return { fromPrice: data[0], toPrice: data[1] };
      });
    } else {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, [name]: data };
        }
        return { [name]: data };
      });
    }
  };

  const handleClick = async () => {
    const data = {
      fromPrice:
        priceValue[0] === +filterParameters.amount[0].ID &&
        priceValue[1] === +filterParameters.amount[0].Name
          ? ""
          : priceValue[0],
      toPrice:
        priceValue[0] === +filterParameters.amount[0].ID &&
        priceValue[1] === +filterParameters.amount[0].Name
          ? ""
          : priceValue[1],
      fromCarat:
        value[1] === +filterParameters.carat[0].Name &&
        value[0] === +filterParameters.carat[0].ID
          ? ""
          : value[0],
      toCarat:
        value[1] === +filterParameters.carat[0].Name &&
        value[0] === +filterParameters.carat[0].ID
          ? ""
          : value[1],
      cut: formData.cut,
      lab: formData.lab,
      certi: formData.certi,
      type: formData.type,
      polish: formData.polish,
      symmetry: formData.symmetry,
      StockID: formData.StockID,
      shape: formData.shape.join(","),
      color: formData.color.join(","),
      clarity: formData.clarity.join(","),
    };
    setPageNumber(1);
    setFilterValue((prev) => ({ ...prev, ...data }));
    setSelectedFilter("");
  };

  const handleChangeForm = (val, name) => {
    const exception = ["certi", "StockID"];
    if (exception.includes(name)) {
      setFormData((prev) => ({ ...prev, [name]: val }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: prev[name] === val ? "" : val,
      }));
    }
  };

  const handleChangeMultiData = (val, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: prev[name].includes(val)
        ? prev[name].filter((item) => item !== val)
        : prev[name].concat(val),
    }));
  };

  const handleChangeSlider = (data, name) => {
    if (name === "carat") {
      setFormData((prev) => ({
        ...prev,
        fromCarat: data[0],
        toCarat: data[1],
      }));
    } else if (name === "price") {
      setFormData((prev) => ({
        ...prev,
        fromPrice: data[0],
        toPrice: data[1],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: data,
      }));
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (debounceFields.StockID) {
        handleFilterChange(debounceFields.StockID, "StockID");
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [debounceFields.StockID]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (debounceFields.certi) {
        handleFilterChange(debounceFields.certi, "certi");
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [debounceFields.certi]);

  const handleClearFilter = (type) => {
    setPageNumber(1);
    setFilterValue(null);
    setValue([
      parseFloat(filterParameters.carat[0].ID),
      parseFloat(filterParameters.carat[0].Name),
    ]);
    setPriceValue([
      parseFloat(filterParameters.amount[0].ID),
      parseFloat(filterParameters.amount[0].Name),
    ]);
    setFormData({
      cut: "",
      lab: "",
      type: "",
      polish: "",
      symmetry: "",
      shape: [],
      color: [],
      clarity: [],
      certi: "",
      StockID: "",
    });
    setDebounceFields({
      StockID: "",
      certi: "",
    });
  };

  const handleRemoveFilter = (name) => {
    setPageNumber(1);
    if (name === "price") {
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
    } else if (["StockID", "certi"].includes(name)) {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
      setDebounceFields((prev) => ({ ...prev, [name]: "" }));
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleRemoveFilterMobile = (name) => {
    setPageNumber(1);
    if (name === "price") {
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
      setFormData((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
    } else if (name === "carat") {
      setValue([
        parseFloat(filterParameters.carat[0].ID),
        parseFloat(filterParameters.carat[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: "",
        toCarat: "",
      }));
      setFormData((prev) => ({
        ...prev,
        fromCarat: "",
        toCarat: "",
      }));
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
      setFormData((prev) => ({
        ...prev,
        [name]: ["shape", "color", "clarity"].includes(name) ? [] : "",
      }));
    }
  };

  const handleSelectAll = (data, event) => {
    const { name, checked } = event.target;
    setPageNumber(1);
    const value = data.map((item) => item.ID).join(",");
    if (checked) {
      setFilterValue((prev) =>
        prev ? { ...prev, [name]: value } : { [name]: value }
      );
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const advancedFilterOptionsDesktop = useMemo(() => {
    return {
      // cut: {
      //   title: "Cut",
      //   value: sortByID(filterParameters?.cut || [], "asc", "SrNo") || [],
      // },
      // polish: {
      //   title: "Polish",
      //   value: sortByID(filterParameters?.polish || [], "asc", "SrNo") || [],
      // },
      // symmetry: {
      //   title: "Symmetry",
      //   value: sortByID(filterParameters?.sym || [], "asc", "SrNo") || [],
      // },
      lab: {
        title: "Lab",
        value: sortByID(filterParameters?.lab || [], "asc", "SrNo") || [],
      },
      price: { title: "Price", value: filterParameters?.amount?.[0] || {} },
      type: {
        title: "Type",
        value: sortByID(filterParameters?.type || [], "asc", "SrNo") || [],
      },
      StockID: { title: "Stock Id" },
      certi: { title: "Certi Id" },
    };
  }, [filterParameters]);

  const advancedFilterOptionsMobile = useMemo(() => {
    return {
      shape: {
        title: "Shape",
        value: sortByID(filterParameters?.shape || [], "asc", "SrNo") || [],
      },
      carat: { title: "Carat", value: filterParameters?.carat?.[0] || {} },
      color: {
        title: "Color",
        value: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      },
      clarity: {
        title: "Clarity",
        value: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
      },
      cut: {
        title: "Cut",
        value: sortByID(filterParameters?.cut || [], "asc", "SrNo") || [],
      },
      polish: {
        title: "Polish",
        value: sortByID(filterParameters?.polish || [], "asc", "SrNo") || [],
      },
      symmetry: {
        title: "Symmetry",
        value: sortByID(filterParameters?.sym || [], "asc", "SrNo") || [],
      },
      lab: {
        title: "Lab",
        value: sortByID(filterParameters?.lab || [], "asc", "SrNo") || [],
      },
      price: { title: "Price", value: filterParameters?.amount?.[0] || {} },
      type: {
        title: "Type",
        value: sortByID(filterParameters?.type || [], "asc", "SrNo") || [],
      },
      StockID: { title: "Stock Id" },
      certi: { title: "Certi Id" },
    };
  }, [filterParameters]);

  const drawerData = useMemo(() => {
    if (selectedFilter) {
      return advancedFilterOptionsMobile[selectedFilter];
    }
    return null;
  }, [selectedFilter, advancedFilterOptionsMobile]);
  return (
    <>
      <div className="hidden md:block">
        <div className="flex justify-between sm:flex-nowrap flex-wrap items-center gap-5 mb-4">
          {/* shape and color */}
          <div className="flex-grow min-w-[50%] flex flex-col gap-2 self-stretch items-start">
            {/* shape */}
            <div className="flex gap-2 self-start">
              <h4 className="font-semibold text-base mb-1 w-16">Shape:</h4>
              <div className="flex justify-start items-center gap-2 w-full flex-wrap">
                {filterLoading && (
                  <>
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                    <Skeleton width={70} height={60} />
                  </>
                )}
                {tabContent.shape.map((shape, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      name="shape"
                      disabled={loading}
                      onClick={handleFilterChange.bind(null, shape.ID, "shape")}
                      className={`
                                w-[70px] border-2 group rounded duration-200 sm:hover:border-gray-500
                                ${
                                  filterValue?.shape &&
                                  filterValue?.shape?.includes(shape.ID)
                                    ? "border-gray-500"
                                    : "border-gray-300"
                                }
                              `}
                    >
                      <div>
                        <div className="py-2 px-2 ">
                          <img
                            src={shape.ImageURL || shap1}
                            alt={`shape-${index + 1}`}
                            className="m-auto h-[25px]"
                          />
                        </div>
                        <div
                          className={`text-[11px] group-hover:bg-[#64748b] group-hover:text-white duration-200 ${
                            filterValue?.shape &&
                            filterValue?.shape?.includes(shape.ID)
                              ? "bg-[#64748b] text-white"
                              : "bg-white text-[#64748b]"
                          }`}
                        >
                          {shape.Name}
                        </div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>

            {/* color */}
            <div className="flex gap-2 self-start">
              <h4 className="font-semibold text-base mb-1 w-16">Color:</h4>
              <div className="flex justify-start items-center w-full gap-2 flex-wrap">
                {filterLoading && (
                  <>
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                  </>
                )}
                {!filterLoading && (
                  <label
                    id="color"
                    className={`
                        font-openSans cursor-pointer text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          filterValue?.color &&
                          filterValue.color ===
                            tabContent.color.map((item) => item.ID).join(",")
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                    `}
                  >
                    All
                    <input
                      type="checkbox"
                      checked={
                        filterValue?.color &&
                        filterValue.color ===
                          tabContent.color.map((item) => item.ID).join(",")
                      }
                      onChange={handleSelectAll.bind(null, tabContent.color)}
                      name="color"
                      id="color"
                      className="hidden"
                    />
                  </label>
                )}
                {tabContent.color.map((color, index) => {
                  return (
                    <button
                      key={index}
                      disabled={loading}
                      name="color"
                      onClick={handleFilterChange.bind(null, color.ID, "color")}
                      className={`
                            font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                            ${
                              filterValue?.color &&
                              filterValue?.color?.includes(color.ID)
                                ? "border-gray-500"
                                : "border-gray-300"
                            }
                        `}
                    >
                      {color.Name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {/* carat, cut, polish, symmetry, clarity */}
          <div className="flex-grow min-w-[50%] flex flex-col gap-4 items-start">
            <div className="flex gap-2 self-start w-full">
              <h4 className="font-semibold text-base mb-1 w-24">Carat:</h4>
              {filterLoading && (
                <div className="w-full">
                  <Skeleton height={15} />
                  <div className=" mt-1 px-2 flex justify-between items-center">
                    <Skeleton width={48} height={25} />
                    <Skeleton width={48} height={25} />
                  </div>
                </div>
              )}
              {!filterLoading && (
                <div className="w-full mx-4">
                  <Slider
                    value={value}
                    onChange={(e, val) => {
                      setValue(val);
                    }}
                    onChangeCommitted={(e, val) => {
                      handleFilterChange(val, "carat");
                    }}
                    getAriaValueText={valuetext}
                    min={+filterParameters?.carat?.[0]?.ID}
                    max={+filterParameters?.carat?.[0]?.Name}
                    step={0.01}
                    sx={{
                      "& .MuiSlider-thumb": {
                        color: "#475569",
                      },
                      "& .MuiSlider-track": {
                        color: "#475569",
                      },
                      "& .MuiSlider-rail": {
                        color: "#acc4e4",
                      },
                      "& .MuiSlider-active": {
                        color: "green",
                      },
                    }}
                  />
                  <div className="flex justify-between items-center">
                    <input
                      type="text"
                      name="fromCarat"
                      id="fromCarat"
                      value={value[0]}
                      onBlur={(e) => {
                        const { value: val } = e.target;
                        if (!parseInt(val.split(".").join(""))) {
                          handleFilterChange(
                            filterParameters?.carat?.[0]?.ID,
                            "fromCarat"
                          );
                          setValue((prev) => [
                            filterParameters?.carat?.[0]?.ID,
                            prev[1],
                          ]);
                        }
                      }}
                      onChange={(e) => {
                        const { value: val } = e.target;
                        if (numberRegexWithDecimals.test(val)) {
                          if (parseInt(val.split(".").join(""))) {
                            handleFilterChange(val, "fromCarat");
                            handleFilterChange(value[1], "toCarat");
                          }
                          setValue((prev) => [val, prev[1]]);
                        }
                      }}
                      className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                    />
                    <input
                      type="text"
                      name="toCarat"
                      id="toCarat"
                      value={value[1]}
                      onBlur={(e) => {
                        const { value: val } = e.target;
                        if (!parseInt(val.split(".").join(""))) {
                          handleFilterChange(
                            filterParameters?.carat?.[0]?.Name,
                            "toCarat"
                          );
                          setValue((prev) => [
                            prev[0],
                            filterParameters?.carat?.[0]?.Name,
                          ]);
                        }
                      }}
                      onChange={(e) => {
                        const { value: val } = e.target;
                        if (numberRegexWithDecimals.test(val)) {
                          if (parseInt(val.split(".").join(""))) {
                            handleFilterChange(val, "toCarat");
                            handleFilterChange(value[0], "fromCarat");
                          }
                          setValue((prev) => [prev[0], val]);
                        }
                      }}
                      className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="flex gap-2 self-start w-full">
              <h4 className="font-semibold text-base mb-1 w-24">Cut:</h4>
              <div className="flex justify-start gap-2 flex-wrap w-full">
                {filterLoading && (
                  <>
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                  </>
                )}
                {!filterLoading && (
                  <label
                    id="cut_desk"
                    className={`font-openSans cursor-pointer self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500 ${
                      filterValue?.cut &&
                      filterValue.cut ===
                        tabContent.cut.map((item) => item.ID).join(",")
                        ? "border-gray-500"
                        : "border-gray-300"
                    }`}
                  >
                    All
                    <input
                      type="checkbox"
                      checked={
                        filterValue?.cut &&
                        filterValue.cut ===
                          tabContent.cut.map((item) => item.ID).join(",")
                      }
                      disabled={loading}
                      onChange={handleSelectAll.bind(null, tabContent.cut)}
                      name="cut"
                      id="cut_desk"
                      className="hidden"
                    />
                  </label>
                )}
                {tabContent.cut.map((cut, index) => {
                  return (
                    <button
                      key={index}
                      name="cut"
                      disabled={loading}
                      onClick={handleFilterChange.bind(null, cut.ID, "cut")}
                      className={`
                                font-openSans self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                                ${
                                  filterValue?.cut &&
                                  filterValue?.cut.split(",")?.includes(cut.ID)
                                    ? "border-gray-500"
                                    : "border-gray-300"
                                }
                              `}
                    >
                      {cut.Name}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex gap-2 self-start w-full">
              <h4 className="font-semibold text-base mb-1 w-24">Polish:</h4>
              <div className="flex justify-start gap-2 flex-wrap w-full">
                {filterLoading && (
                  <>
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                  </>
                )}
                {!filterLoading && (
                  <label
                    id="polish"
                    className={`font-openSans cursor-pointer self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500 ${
                      filterValue?.polish &&
                      filterValue.polish ===
                        tabContent.polish.map((item) => item.ID).join(",")
                        ? "border-gray-500"
                        : "border-gray-300"
                    }`}
                  >
                    All
                    <input
                      type="checkbox"
                      checked={
                        filterValue?.polish &&
                        filterValue.polish ===
                          tabContent.polish.map((item) => item.ID).join(",")
                      }
                      disabled={loading}
                      onChange={handleSelectAll.bind(null, tabContent.polish)}
                      name="polish"
                      id="polish"
                      className="hidden"
                    />
                  </label>
                )}
                {tabContent.polish.map((polish, index) => {
                  return (
                    <button
                      key={index}
                      name="polish"
                      disabled={loading}
                      onClick={handleFilterChange.bind(
                        null,
                        polish.ID,
                        "polish"
                      )}
                      className={`
                                font-openSans self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                                ${
                                  filterValue?.polish &&
                                  filterValue?.polish
                                    ?.split(",")
                                    ?.includes(polish.ID)
                                    ? "border-gray-500"
                                    : "border-gray-300"
                                }
                              `}
                    >
                      {polish.Name}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex gap-2 self-start w-full">
              <h4 className="font-semibold text-base mb-1 w-24">Symmetry:</h4>
              <div className="flex justify-start gap-2 flex-wrap w-full">
                {filterLoading && (
                  <>
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                  </>
                )}
                {!filterLoading && (
                  <label
                    id="symmetry"
                    className={`font-openSans cursor-pointer self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500 ${
                      filterValue?.symmetry &&
                      filterValue.symmetry ===
                        tabContent.symmetry.map((item) => item.ID).join(",")
                        ? "border-gray-500"
                        : "border-gray-300"
                    }`}
                  >
                    All
                    <input
                      type="checkbox"
                      checked={
                        filterValue?.symmetry &&
                        filterValue.symmetry ===
                          tabContent.symmetry.map((item) => item.ID).join(",")
                      }
                      disabled={loading}
                      onChange={handleSelectAll.bind(null, tabContent.symmetry)}
                      name="symmetry"
                      id="symmetry"
                      className="hidden"
                    />
                  </label>
                )}
                {tabContent.symmetry.map((symmetry, index) => {
                  return (
                    <button
                      key={index}
                      name="symmetry"
                      disabled={loading}
                      onClick={handleFilterChange.bind(
                        null,
                        symmetry.ID,
                        "symmetry"
                      )}
                      className={`
                                font-openSans self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                                ${
                                  filterValue?.symmetry &&
                                  filterValue?.symmetry?.includes(symmetry.ID)
                                    ? "border-gray-500"
                                    : "border-gray-300"
                                }
                              `}
                    >
                      {symmetry.Name}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex gap-2 self-start w-full">
              <h4 className="font-semibold text-base mb-1 w-24">Clarity:</h4>
              <div className="flex justify-start gap-2 flex-wrap w-full">
                {filterLoading && (
                  <>
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                    <Skeleton width={40} height={25} />
                  </>
                )}
                {!filterLoading && (
                  <label
                    id="clarity"
                    className={`font-openSans cursor-pointer self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500 ${
                      filterValue?.clarity &&
                      filterValue.clarity ===
                        tabContent.clarity.map((item) => item.ID).join(",")
                        ? "border-gray-500"
                        : "border-gray-300"
                    }`}
                  >
                    All
                    <input
                      type="checkbox"
                      checked={
                        filterValue?.clarity &&
                        filterValue.clarity ===
                          tabContent.clarity.map((item) => item.ID).join(",")
                      }
                      disabled={loading}
                      onChange={handleSelectAll.bind(null, tabContent.clarity)}
                      name="clarity"
                      id="clarity"
                      className="hidden"
                    />
                  </label>
                )}
                {tabContent.clarity.map((clarity, index) => {
                  return (
                    <button
                      key={index}
                      name="clarity"
                      disabled={loading}
                      onClick={handleFilterChange.bind(
                        null,
                        clarity.ID,
                        "clarity"
                      )}
                      className={`
                                font-openSans self-start text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                                ${
                                  filterValue?.clarity &&
                                  filterValue?.clarity?.includes(clarity.ID)
                                    ? "border-gray-500"
                                    : "border-gray-300"
                                }
                              `}
                    >
                      {clarity.Name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-3">
          <div className="flex items-start gap-2 min-h-[30px]">
            <h4 className="font-semibold h-full text-base mb-1 flex justify-start items-center gap-2">
              <span className="">Advanced options:</span>
              <button onClick={() => setShowAdvanced((prev) => !prev)}>
                {showAdvanced ? (
                  <BsDashCircleDotted fontSize={18} />
                ) : (
                  <BsPlusCircleDotted fontSize={18} />
                )}
              </button>
            </h4>
            {showAdvanced && (
              <div className="flex max-w-full justify-start items-center gap-2 flex-wrap">
                {Object.entries(advancedFilterOptionsDesktop).map(
                  ([key, value], index) => {
                    const title =
                      key !== "price" ? (
                        <div className="flex justify-start items-center gap-2">
                          {filterValue?.[key] && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFilter(key);
                              }}
                              className="rounded-full bg-gray-200 flex justify-center items-center w-4 h-4"
                            >
                              <IoCloseSharp fontSize={12} />
                            </button>
                          )}
                          {value.title} {filterValue?.[key] && "(1)"}
                        </div>
                      ) : (
                        <div className="flex justify-start items-center gap-2">
                          {filterValue?.fromPrice && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFilter(key);
                              }}
                              className="rounded-full bg-gray-200 flex justify-center items-center w-4 h-4"
                            >
                              <IoCloseSharp fontSize={12} />
                            </button>
                          )}
                          {value.title}{" "}
                          {filterValue?.fromPrice &&
                            `(${filterValue?.fromPrice} - ${filterValue?.toPrice})`}
                        </div>
                      );
                    return (
                      <DropdownMenu key={index} title={title}>
                        <div className="flex flex-col gap-2">
                          <h2 className="text-base font-bold">{value.title}</h2>
                          {["price", "StockID", "certi"].includes(key) ? (
                            <>
                              {key === "price" ? (
                                <div className="w-80 px-6 ">
                                  <Slider
                                    value={priceValue}
                                    onChange={(e, val) => {
                                      setPriceValue(val);
                                    }}
                                    onChangeCommitted={(e, val) => {
                                      handleFilterChange(val, "price");
                                    }}
                                    getAriaValueText={valuetext}
                                    min={+filterParameters?.amount?.[0]?.ID}
                                    max={+filterParameters?.amount?.[0]?.Name}
                                    step={0.01}
                                    sx={{
                                      "& .MuiSlider-thumb": {
                                        color: "#475569",
                                      },
                                      "& .MuiSlider-track": {
                                        color: "#475569",
                                      },
                                      "& .MuiSlider-rail": {
                                        color: "#acc4e4",
                                      },
                                      "& .MuiSlider-active": {
                                        color: "green",
                                      },
                                    }}
                                  />
                                  <div className="flex justify-between items-center">
                                    <input
                                      type="text"
                                      name="fromPrice"
                                      id="fromPrice"
                                      value={priceValue[0]}
                                      onBlur={(e) => {
                                        const { value: val } = e.target;
                                        if (
                                          !parseInt(val.split(".").join(""))
                                        ) {
                                          handleFilterChange(
                                            filterParameters?.amount?.[0]?.ID,
                                            "fromPrice"
                                          );
                                          setPriceValue((prev) => [
                                            filterParameters?.amount?.[0]?.ID,
                                            prev[1],
                                          ]);
                                        }
                                      }}
                                      onChange={(e) => {
                                        const { value: val } = e.target;
                                        if (numberRegexWithDecimals.test(val)) {
                                          if (
                                            parseInt(val.split(".").join(""))
                                          ) {
                                            handleFilterChange(
                                              val,
                                              "fromPrice"
                                            );
                                            handleFilterChange(
                                              priceValue[1],
                                              "toPrice"
                                            );
                                          }
                                          setPriceValue((prev) => [
                                            val,
                                            prev[1],
                                          ]);
                                        }
                                      }}
                                      className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                                    />
                                    <input
                                      type="text"
                                      name="toPrice"
                                      id="toPrice"
                                      value={priceValue[1]}
                                      onBlur={(e) => {
                                        const { value: val } = e.target;
                                        if (
                                          !parseInt(val.split(".").join(""))
                                        ) {
                                          handleFilterChange(
                                            filterParameters?.amount?.[0]?.Name,
                                            "toPrice"
                                          );
                                          setPriceValue((prev) => [
                                            prev[0],
                                            filterParameters?.amount?.[0]?.Name,
                                          ]);
                                        }
                                      }}
                                      onChange={(e) => {
                                        const { value: val } = e.target;
                                        if (numberRegexWithDecimals.test(val)) {
                                          if (
                                            parseInt(val.split(".").join(""))
                                          ) {
                                            handleFilterChange(val, "toPrice");
                                            handleFilterChange(
                                              priceValue[0],
                                              "fromPrice"
                                            );
                                          }
                                          setPriceValue((prev) => [
                                            prev[0],
                                            val,
                                          ]);
                                        }
                                      }}
                                      className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="px-6">
                                  <input
                                    type="text"
                                    id={key}
                                    name={key}
                                    value={debounceFields[key]}
                                    // value={filterValue?.[key]}
                                    onChange={(e) => {
                                      const { value: val } = e.target;
                                      if (numberRegex.test(val)) {
                                        setDebounceFields((prev) => ({
                                          ...prev,
                                          [key]: val,
                                        }));
                                      }
                                    }}
                                    className="rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5  "
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            value.value.map((val, ind) => {
                              return (
                                <button
                                  key={ind}
                                  onClick={() => {
                                    handleFilterChange(val.Name, key);
                                  }}
                                  className={`hover:bg-gray-200 ${
                                    val.Name === filterValue?.[key]
                                      ? "bg-gray-200"
                                      : ""
                                  } min-w-full w-28 px-2 py-1 text-xs rounded flex justify-between items-center gap-2`}
                                >
                                  {val.Name}
                                  {val.Name === filterValue?.[key] && (
                                    <CheckIcon
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              );
                            })
                          )}
                        </div>
                      </DropdownMenu>
                    );
                  }
                )}
              </div>
            )}
          </div>
          <button
            onClick={handleClearFilter}
            className="flex justify-center items-center gap-2"
          >
            <span className="w-full">Clear filter </span>
            <span className="rounded-full bg-gray-200 flex justify-center items-center p-0.5">
              <IoCloseSharp fontSize={12} />
            </span>
          </button>
        </div>
      </div>
      <div className="md:hidden">
        <div className="">
          <div className="flex max-w-full justify-start items-center gap-2 flex-wrap mb-4">
            {Object.keys(advancedFilterOptionsMobile)
              .slice(
                0,
                showMore ? Object.keys(advancedFilterOptionsMobile).length : 7
              )
              .map((filter, index) => {
                const count = filterValue?.[filter]
                  ? filterValue?.[filter]?.split(",").filter((item) => item)
                      ?.length
                  : 0;
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedFilter(filter);
                    }}
                    className="inline-flex bg-transparent text-gray-600 border border-gray-300 rounded-3xl justify-center items-center px-2 py-0.5 lg:px-4 lg:py-1 lg:text-sm text-xs font-medium hover:bg-opacity-30 outline-none "
                  >
                    {(!!count ||
                      (filter === "price" && filterValue?.fromPrice) ||
                      (filter === "carat" && filterValue?.fromCarat)) && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFilterMobile(filter);
                        }}
                        className="rounded-full bg-gray-200 flex justify-center items-center w-4 h-4 mr-1"
                      >
                        <IoCloseSharp fontSize={12} />
                      </span>
                    )}
                    {advancedFilterOptionsMobile[filter].title}
                    {count ? `(${count})` : ""}
                    {filter === "price" && filterValue?.fromPrice
                      ? `(${filterValue?.fromPrice} - ${filterValue?.toPrice})`
                      : ""}
                    {filter === "carat" && filterValue?.fromCarat
                      ? `(${filterValue?.fromCarat} - ${filterValue?.toCarat})`
                      : ""}
                    <ChevronDownIcon
                      className="ml-2 -mr-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                );
              })}
            <button onClick={() => setShowMore((prev) => !prev)}>
              {showMore ? (
                <BsDashCircleDotted fontSize={18} />
              ) : (
                <BsPlusCircleDotted fontSize={18} />
              )}
            </button>
            <Drawer
              anchor="bottom"
              open={!!selectedFilter}
              onClose={() => {
                setSelectedFilter("");
              }}
            >
              <div className="flex flex-col justify-between items-center gap-2">
                <div className="px-2 py-1 border-b flex justify-between w-full items-center">
                  <h3 className="text-lg font-semibold text-gray-600">
                    {drawerData?.title}
                  </h3>
                  <button onClick={() => setSelectedFilter("")}>
                    <IoCloseSharp fontSize={18} />
                  </button>
                </div>
                <div className="px-3 w-full h-full py-6">
                  {selectedFilter === "shape" ? (
                    <div className="flex justify-start items-center gap-2 flex-wrap">
                      {drawerData.value.map((shape, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            name="shape"
                            disabled={loading}
                            onClick={handleChangeMultiData.bind(
                              null,
                              shape.ID,
                              "shape"
                            )}
                            className={`
                              min-w-[60px] border-2 group rounded duration-200 sm:hover:border-gray-500
                              ${
                                formData?.shape?.includes(shape.ID)
                                  ? "border-gray-500"
                                  : "border-gray-300"
                              }
                            `}
                          >
                            <div>
                              <div className="py-2 px-2 ">
                                <img
                                  src={shape.ImageURL || shap1}
                                  alt={`shape-${index + 1}`}
                                  className="m-auto h-[25px]"
                                />
                              </div>
                              <div
                                className={`text-xs sm:group-hover:bg-[#64748b] sm:group-hover:text-white duration-200 ${
                                  formData?.shape?.includes(shape.ID)
                                    ? "bg-[#64748b] text-white"
                                    : "bg-white text-[#64748b]"
                                }`}
                              >
                                {shape.Name}
                              </div>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  ) : selectedFilter === "carat" ? (
                    <div className="px-3 w-full">
                      <Slider
                        value={value}
                        onChange={(e, val) => {
                          setValue(val);
                        }}
                        onChangeCommitted={(e, val) => {
                          handleChangeSlider(val, "carat");
                        }}
                        getAriaValueText={valuetext}
                        min={+drawerData.value.ID}
                        max={+drawerData.value?.Name}
                        step={0.01}
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#475569",
                          },
                          "& .MuiSlider-track": {
                            color: "#475569",
                          },
                          "& .MuiSlider-rail": {
                            color: "#acc4e4",
                          },
                          "& .MuiSlider-active": {
                            color: "green",
                          },
                        }}
                      />
                      <div className="flex justify-between items-center">
                        <input
                          type="text"
                          name="fromCarat"
                          id="fromCarat"
                          value={value[0]}
                          onBlur={(e) => {
                            const { value: val } = e.target;
                            if (!parseInt(val.split(".").join(""))) {
                              handleChangeSlider(
                                filterParameters?.carat?.[0]?.ID,
                                "fromCarat"
                              );
                              setValue((prev) => [
                                filterParameters?.carat?.[0]?.ID,
                                prev[1],
                              ]);
                            }
                          }}
                          onChange={(e) => {
                            const { value: val } = e.target;
                            if (numberRegexWithDecimals.test(val)) {
                              if (parseInt(val.split(".").join(""))) {
                                handleChangeSlider(val, "fromCarat");
                                handleChangeSlider(value[1], "toCarat");
                              }
                              setValue((prev) => [val, prev[1]]);
                            }
                          }}
                          className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                        />
                        <input
                          type="text"
                          name="toCarat"
                          id="toCarat"
                          value={value[1]}
                          onBlur={(e) => {
                            const { value: val } = e.target;
                            if (!parseInt(val.split(".").join(""))) {
                              handleChangeSlider(
                                filterParameters?.carat?.[0]?.Name,
                                "toCarat"
                              );
                              setValue((prev) => [
                                prev[0],
                                filterParameters?.carat?.[0]?.Name,
                              ]);
                            }
                          }}
                          onChange={(e) => {
                            const { value: val } = e.target;
                            if (numberRegexWithDecimals.test(val)) {
                              if (parseInt(val.split(".").join(""))) {
                                handleChangeSlider(val, "toCarat");
                                handleChangeSlider(value[0], "fromCarat");
                              }
                              setValue((prev) => [prev[0], val]);
                            }
                          }}
                          className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                        />
                      </div>
                    </div>
                  ) : selectedFilter === "price" ? (
                    <div className="px-3 w-full">
                      <Slider
                        value={priceValue}
                        onChange={(e, val) => {
                          setPriceValue(val);
                        }}
                        onChangeCommitted={(e, val) => {
                          handleChangeSlider(val, "price");
                        }}
                        getAriaValueText={valuetext}
                        min={+filterParameters?.amount?.[0]?.ID}
                        max={+filterParameters?.amount?.[0]?.Name}
                        step={0.01}
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#475569",
                          },
                          "& .MuiSlider-track": {
                            color: "#475569",
                          },
                          "& .MuiSlider-rail": {
                            color: "#acc4e4",
                          },
                          "& .MuiSlider-active": {
                            color: "green",
                          },
                        }}
                      />
                      <div className="flex justify-between items-center">
                        <input
                          type="text"
                          name="fromPrice"
                          id="fromPrice"
                          value={priceValue[0]}
                          onBlur={(e) => {
                            const { value: val } = e.target;
                            if (!parseInt(val.split(".").join(""))) {
                              handleChangeSlider(
                                filterParameters?.amount?.[0]?.ID,
                                "fromPrice"
                              );
                              setPriceValue((prev) => [
                                filterParameters?.amount?.[0]?.ID,
                                prev[1],
                              ]);
                            }
                          }}
                          onChange={(e) => {
                            const { value: val } = e.target;
                            if (numberRegexWithDecimals.test(val)) {
                              if (parseInt(val.split(".").join(""))) {
                                handleChangeSlider(val, "fromPrice");
                                handleChangeSlider(priceValue[1], "toPrice");
                              }
                              setPriceValue((prev) => [val, prev[1]]);
                            }
                          }}
                          className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                        />
                        <input
                          type="text"
                          name="toPrice"
                          id="toPrice"
                          value={priceValue[1]}
                          onBlur={(e) => {
                            const { value: val } = e.target;
                            if (!parseInt(val.split(".").join(""))) {
                              handleChangeSlider(
                                filterParameters?.amount?.[0]?.Name,
                                "toPrice"
                              );
                              setPriceValue((prev) => [
                                prev[0],
                                filterParameters?.amount?.[0]?.Name,
                              ]);
                            }
                          }}
                          onChange={(e) => {
                            const { value: val } = e.target;
                            if (numberRegexWithDecimals.test(val)) {
                              if (parseInt(val.split(".").join(""))) {
                                handleChangeSlider(val, "toPrice");
                                handleChangeSlider(priceValue[0], "fromPrice");
                              }
                              setPriceValue((prev) => [prev[0], val]);
                            }
                          }}
                          className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                        />
                      </div>
                    </div>
                  ) : ["color", "clarity"].includes(selectedFilter) ? (
                    <div className="px-2 w-full flex justify-start items-center gap-2 flex-wrap">
                      {drawerData.value.map((filter, index) => {
                        return (
                          <button
                            key={index}
                            disabled={loading}
                            name="color"
                            onClick={handleChangeMultiData.bind(
                              null,
                              filter.ID,
                              selectedFilter
                            )}
                            className={`
                            font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                            ${
                              formData?.[selectedFilter]?.includes(filter.ID)
                                ? "border-gray-500"
                                : "border-gray-300"
                            }
                        `}
                          >
                            {filter.Name}
                          </button>
                        );
                      })}
                    </div>
                  ) : ["StockID", "certi"].includes(selectedFilter) ? (
                    <div className="w-full px-3">
                      <input
                        type="text"
                        value={formData[selectedFilter]}
                        name={selectedFilter}
                        id={selectedFilter}
                        onChange={(e) => {
                          handleChangeForm(e.target.value, selectedFilter);
                        }}
                        className="w-full outline-none border-2 border-gray-400 rounded text-sm px-2 py-0.5"
                      />
                    </div>
                  ) : (
                    <div className="px-2 w-full flex justify-start items-center gap-2 flex-wrap">
                      {drawerData &&
                        drawerData.value.map((filter, index) => {
                          return (
                            <button
                              key={index}
                              disabled={loading}
                              name="color"
                              onClick={handleChangeForm.bind(
                                null,
                                filter.Name,
                                selectedFilter
                              )}
                              className={`
                            font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                            ${
                              formData?.[selectedFilter] === filter.Name
                                ? "border-gray-500"
                                : "border-gray-300"
                            }
                        `}
                            >
                              {filter.Name}
                            </button>
                          );
                        })}
                    </div>
                  )}
                </div>
                <div className="px-3 mb-2 w-full">
                  <button
                    onClick={handleClick}
                    className="bg-primaryColor text-sm text-white w-full py-1 rounded"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Drawer>
          </div>
          <div className="flex items-center justify-between">
            <span>Total {stockData?.length} Stones Found</span>
            {filterValue && !!Object.values(filterValue).join("") && (
              <button
                onClick={handleClearFilter}
                className="flex justify-center items-center gap-2 text-sm text-gray-500"
              >
                <span className="w-full">Clear filter </span>
                <span className="rounded-full bg-gray-200 flex justify-center items-center p-0.5 ">
                  <IoCloseSharp fontSize={12} />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DiamondFilters;
