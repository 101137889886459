import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  DesignMetal,
  getDesignById,
  getMetalById,
} from "../../Services/designService";
import { useState } from "react";
import { useMemo } from "react";
import { toast } from "react-hot-toast";
import { TiArrowBack } from "react-icons/ti";
import { numberRegexWithDecimals } from "../shared/constants";

const AddAgentModule = () => {
  const [designIdData, setDesingIDData] = useState();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const DefData = async () => {
    const res = await getMetalById(id);
    if (!!res.data.data.design_metal?.length) {
      setMetalRows(res.data.data.design_metal);
    } else {
      designById();
    }
  };

  const designById = async () => {
    const res = await getDesignById(id);
    if (res.status === 200) {
      setDesingIDData(res.data.data);
    }
  };

  useEffect(() => {
    DefData();
  }, [id]);

  const metalData = useMemo(() => {
    if (designIdData) {
      return (
        designIdData?.designAttributeValue?.length &&
        designIdData.designAttributeValue.filter(
          (it) => it.stAttributeName === "Metal"
        )
      );
    }
  }, [designIdData]);
  const [metalRows, setMetalRows] = useState([
    {
      unAttributeValueId: "",
      dcGrWt: 0.0,
      dcNtWt: 0.0,
      dcFine: 0.0,
    },
  ]);

  useEffect(() => {
    if (metalData) {
      const data = metalData.map((it, index) => {
        return {
          unAttributeValueId: it.unAttributeValueId,
          stAttributeValueName: it.stAttributeValueName,
          dcGrWt: 0.0,
          dcNtWt: 0.0,
          dcFine: 0.0,
        };
      });
      setMetalRows(data);
    }
  }, [metalData]);

  const handleChange = (e, metal) => {
    const { name, value } = e.target;
    setMetalRows((prev) =>
      prev.map((item, i) =>
        metal === item.unAttributeValueId ? { ...item, [name]: value } : item
      )
    );
  };

  useEffect(() => {}, []);

  const numberRegex = /^(\s*|\d+)$/;

  const handleSave = async () => {
    const payload = new FormData();
    payload.append("unDesignIdentifier", id);
    payload.append("DesignMetalDetails", JSON.stringify(metalRows));
    const res = await DesignMetal(payload);
    if (res.status === 200) {
      toast.success(res.data.message);
    }
  };

  return (
    <div className="grid px-6 py-4">
      <div className="flex items-center justify-between">
        <h1 className="font-medium font-Josefin text-[20px]">
          {state?.stDesignAlias + " - " + "Design" + "[" + state?.name + "]"}
        </h1>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <table className="border">
            <tr className="border-b">
              <td className="w-[50px] px-3 py-2"></td>
              <td className="border-l px-3 py-2 font-bold">Gross Wt.</td>
              <td className="border-l px-3 py-2">{state?.dcGrWt}</td>
            </tr>
            <tr>
              <td className="w-[50px] px-3 py-2"></td>
              <td className="border-l px-3 py-2 font-bold">Net Wt.</td>
              <td className="border-l px-3 py-2">{state?.dcNtWt}</td>
            </tr>
          </table>
          <div className="flex items-center justify-end gap-3">
            <Link
              to={"/admin/product"}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <TiArrowBack className="text-[20px]" /> Back
            </Link>
            <button
              onClick={handleSave}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              Save
            </button>
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500 rounded dark:text-gray-400">
          <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3 w-[20px]">
                Sr.
              </th>
              <th scope="col" className="px-4 py-3">
                Name
              </th>
              <th scope="col" className="px-4 py-3 w-[150px]">
                Gross Wt.
              </th>
              <th scope="col" className="px-4 py-3 w-[150px]">
                Net Wt.
              </th>
              <th scope="col" className="px-4 py-3 w-[150px]">
                Fine(999)
              </th>
            </tr>
          </thead>
          <tbody>
            {metalRows?.length &&
              metalRows.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="bg-white text-xs border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                  >
                    <th
                      scope="row"
                      className="px-4 py-3 max-w-[20px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {i + 1}
                    </th>
                    <td className="px-4 py-3 max-w-[100px] whitespace-nowrap text-ellipsis overflow-hidden mx-w-[80px]">
                      {item.stAttributeValueName}
                    </td>
                    <td className="px-4 py-1 w-[150px] whitespace-nowrap text-ellipsis overflow-hidden mx-w-[80px]">
                      <input
                        type="text"
                        className="outline-none w-[150px] border rounded py-2 px-1"
                        name="dcGrWt"
                        value={item.dcGrWt}
                        placeholder="Enter Gross Wt."
                        onChange={(e) => {
                          if (numberRegexWithDecimals.test(e.target.value)) {
                            handleChange(e, item?.unAttributeValueId);
                          }
                        }}
                      />
                    </td>
                    <td className="px-4 py-1 w-[150px] whitespace-nowrap text-ellipsis overflow-hidden mx-w-[80px]">
                      <input
                        type="text"
                        className="outline-none w-[150px] border rounded py-2 px-1"
                        name="dcNtWt"
                        value={item.dcNtWt}
                        placeholder="Enter Net Wt."
                        onChange={(e) => {
                          if (numberRegexWithDecimals.test(e.target.value)) {
                            handleChange(e, item.unAttributeValueId);
                          }
                        }}
                      />
                    </td>
                    <td className="px-4 py-1 w-[150px] whitespace-nowrap text-ellipsis overflow-hidden mx-w-[80px]">
                      <input
                        type="text"
                        className="outline-none w-[150px] border rounded py-2 px-1"
                        name="dcFine"
                        value={item.dcFine}
                        placeholder="Enter Fine"
                        onChange={(e) => {
                          if (numberRegexWithDecimals.test(e.target.value)) {
                            handleChange(e, item.unAttributeValueId);
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            <tr className="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700 odd:bg-gray-900"></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddAgentModule;
