import api from "./api"

const getAttributeData = () => {
  return api("/api/Attributes", "get")
}

const getAttributeById = (id) => {
  return api(`/api/Attributes/${id}`, "get")
}

const createAttributeData = (data) => {
  return api("/api/Attributes", "post", data)
}

const updateAttributeData = (data) => {
  return api("/api/Attributes", "put", data)
}

const DeleteAttributeData = (data) => {
  return api(`/api/Attributes/${data}`, "delete")
}

export {
  getAttributeData,
  createAttributeData,
  updateAttributeData,
  DeleteAttributeData,
  getAttributeById
}