import React, { useEffect, useState } from "react";
import { addElementsInArray } from "../../../Utils";
import ProductCard from "../Common/ProductCard";
import ProductBanner from "../../../assets/images/ProductBanner.png";
import { useMemo } from "react";
import upArrow from "../../../assets/images/upArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDesignByCategoryId,
  fetchDesignByCategoryStyleId,
  fetchDesigns,
  getDesignDataState,
} from "../../../store/Slices/designSlice";
import { useLocation } from "react-router-dom";
import { useThunk } from "../../../Hooks/use-thunk";
import ProductCardSkeleton from "../Common/skeleton/ProductCardSkeleton";
import NoDataText from "../../shared/NoDataText";
import { ReactComponent as Emoji } from "../../../assets/images/sadEmoji.svg";

const ProductList = () => {
  const [displayProduct, setDisplayProduct] = useState(18);
  const [gridType, setGridType] = useState(0);
  const dispatch = useDispatch();
  const { data } = useSelector(getDesignDataState);
  const location = useLocation();
  const { state } = location;
  const [fetchCategoryWiseDesign, loading, error] = useThunk(
    fetchDesignByCategoryId
  );
  const [fetchDesignByCategoryStyle] = useThunk(fetchDesignByCategoryStyleId);

  useEffect(() => {
    if (state?.category) {
      fetchCategoryWiseDesign(state?.category?.id);
    } else if (state?.subCategory) {
      fetchDesignByCategoryStyle(state?.subCategory?.id);
    } else {
      dispatch(fetchDesigns());
    }
  }, [
    dispatch,
    state?.category,
    state?.subCategory,
    fetchCategoryWiseDesign,
    fetchDesignByCategoryStyle,
  ]);

  const handleResizeScreen = () => {
    if (window.screen.availWidth >= 1024) {
      setGridType(0);
    }
    if (window.screen.availWidth < 1024 && window.screen.availWidth >= 768) {
      setGridType(1);
    }
    if (window.screen.availWidth < 768 && window.screen.availWidth >= 640) {
      setGridType(2);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeScreen);
    handleResizeScreen();

    return () => {
      window.removeEventListener("resize", handleResizeScreen);
    };
  }, []);

  const productData = useMemo(() => {
    switch (gridType) {
      case 0:
        return addElementsInArray(data, [{ type: 1 }], [4, 13]);
      case 1:
        return addElementsInArray(data, [{ type: 1 }], [3, 9]);
      default:
        return data;
    }
  }, [gridType, data]);

  const handleViewMore = () => {
    if (displayProduct < productData.length) {
      setDisplayProduct((prev) => prev + 18);
    } else {
      setDisplayProduct(18);
    }
  };

  const handleBackToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {loading ? (
        <div className=" grid lg:grid-cols-4 md:grid-cols-3	sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2">
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center text-slate-400 text-base italic ">
          {/* {error.message} */}
        </div>
      ) : (
        <div className="product_list font-Josefin grid xl:grid-cols-8 md:grid-cols-6 grid-cols-4 gap-4">
          {productData
            .slice(0, displayProduct)
            .map(
              (
                {
                  stDefImageURL: mainImage,
                  unDesignIdentifier: id,
                  stDesignName: name,
                  stFocusImageURL: hoverImage,
                  dcSalePrice: price,
                  ...rest
                },
                index
              ) => {
                return rest.type ? (
                  // <div
                  //   className="product_banner w-full self-stretch sm:col-span-2 col-auto "
                  //   key={index}
                  // >
                  <div
                    className={`group relative w-full overflow-hidden m-auto sm:h-[380px] h-[220px] max-w-[296px] sm:max-w-auto boxShadow rounded-none col-span-2`}
                  >
                    <div className={`relative h-full w-full`}>
                      <img
                        src={ProductBanner}
                        className="w-full h-full object-cover"
                        alt={`ProductBanner-${index}`}
                      />
                      <div className="flex flex-col justify-center absolute bottom-[25px] left-[61%] -translate-x-[50%] w-[80%] bg-white lg:h-[50%] sm:h-[60%] px-[18px] md:p-4 sm:p-0 py-[10px] ">
                        <h4 className="font-[700] xl:text-[20px] sm:text-[18px] text-[14px] xl:leading-[28px] sm:leading-[24px] leading-[20px] mb-1 line-clamp-2">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting
                        </h4>
                        <p className="font-[400] sm:text-[14px] text-[12px] xl:leading-[24px] sm:leading-[20px] leading-[16px] mb-0.5 line-clamp-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting
                        </p>
                        <span className="font-[700] text-[13px] sm:text-[16px] cursor-pointer">
                          Book an Appointment
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  // </div>
                  <ProductCard
                    key={index}
                    id={id}
                    title={name}
                    mainImage={mainImage}
                    hoverImage={hoverImage}
                    price={price}
                    data={rest}
                  />
                );
              }
            )}
        </div>
      )}
      {!productData?.length && (
        <NoDataText
          text={
            <div className="flex flex-col justify-center items-center">
              {/* <img src={emoji} alt="sad emoji" /> */}
              <Emoji opacity={0.3} width={60} />
              <span>Sorry, we couldn't found any product.</span>
            </div>
          }
        />
      )}
      {productData.length > 18 && (
        <div className="flex justify-center xl:mt-[80px] lg:mt-[60px] md:mt-[40px] mt-[30px]">
          <button
            onClick={handleViewMore}
            className={`font-Josefin border-[2px] border-black text-[16px] px-8 py-3`}
          >
            {displayProduct < productData.length ? "View more" : "View Less"}
          </button>
        </div>
      )}
      <div className="flex justify-center lg:mt-[40px] md:mt-[30px] mt-[20px]">
        <button
          onClick={handleBackToTop}
          className={`font-Josefin text-[16px] px-8 py-3`}
        >
          Back to top
          <img src={upArrow} className="ml-2 inline-block" alt="upArrow" />
        </button>
      </div>
    </>
  );
};

export default ProductList;
