import React from "react";
import AdminModal from "../common/AdminModal";
import { getDesign, getMetalById } from "../../../Services/designService";
import { useState } from "react";
import { useEffect } from "react";
import TextInputAdmin from "../common/TextInputAdmin";

const BrowseMetalData = ({
  isOpen,
  setIsOpen,
  setMetalRows,
  setSelectedMetalRow,
}) => {
  const [designData, setDesignData] = useState([]);
  const [design, setDesign] = useState([]);
  const userId = localStorage.name ? JSON.parse(localStorage.name) : {};

  useEffect(() => {
    if (isOpen) {
      fetchDesignData();
    }
  }, [isOpen]);

  const fetchDesignData = async () => {
    try {
      const params = {
        inPageNo: 1,
        inPageSize: 10,
        unUserId: userId?.unUserIdentifier,
      };
      const response = await getDesign(params);
      if (response.status === 200) {
        setDesignData(response?.data?.data?.designs);
        setDesign(response?.data?.data?.designs);
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  };

  const handleChange = (search) => {
    if (search) {
      const filterData = design.filter((item) =>
        item.category.toLowerCase().includes(search.toLowerCase())
      );
      setDesignData(filterData);
    } else {
      setDesignData(design);
    }
  };

  const handleSelectRow = async (row) => {
    setSelectedMetalRow(row);
    const res = await getMetalById(row?.unDesignIdentifier);
    if (res.status === 200) {
      const data = res.data.data.design_metal;
      setMetalRows(data);
    }
    setIsOpen(false);
  };
  return (
    <AdminModal
      title={"Browse Metal"}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      showButton={false}
      className={
        "xl:max-w-6xl lg:max-w-[991px] md:max-w-3xl sm:max-w-lg max-w-lg"
      }
    >
      <div className="text-right">
        <TextInputAdmin
          id="color"
          type="text"
          name="COLOUR"
          onChange={(e) => handleChange(e.target.value)}
          placeholder="Search.."
          className="mb-3 w-[200px] ml-auto"
        />
      </div>
      <table className="w-full relative">
        <thead className="sticky top-0">
          <tr className="bg-gray-200">
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Sr
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Category
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Style
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Code
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Name
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              Metal
            </th>
            <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
              NW
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-600">
          {!!designData?.length &&
            designData.map((item, index) => {
              return (
                <tr
                  key={index}
                  onClick={handleSelectRow.bind(null, item)}
                  className=" cursor-pointer hover:bg-slate-300  "
                >
                  <td className="md:py-3 md:px-4 py-2 px-1 ">{index + 1}</td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">
                    {item.category}
                  </td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">
                    {item.categoryStyle}
                  </td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">
                    {item.stDesignAlias}
                  </td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">
                    {item.stDesignName}
                  </td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">{item.metal}</td>
                  <td className="md:py-3 md:px-4 py-2 px-1 ">{item.dcNtWt}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </AdminModal>
  );
};

export default BrowseMetalData;
