import React from "react";
import Header from "../Components/LandingComponent/Header";
import ProductDetails from "../Components/LandingComponent/ProductDetails";
import Footer from "../Components/LandingComponent/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDesignById,
  getDesignDataState,
  resetError,
} from "../store/Slices/designSlice";
import { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import ProductDetailsSkeleton from "../Components/LandingComponent/Common/skeleton/ProductDetailsSkeleton";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector(getDesignDataState);
  const { design, designLoading, designError } = data;

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchDesignById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (designError) {
      toast.error(designError || "No Product found.");
      dispatch(resetError());
      navigate(-1);
    }
  }, [designError, navigate, dispatch]);

  const breadCrumbData = [
    {
      title: "Home",
      onClick: () => {
        navigate("/jewelry");
      },
    },
    {
      title: design?.stDesignName || "",
    },
  ];

  const breadCrumbDataWithCategory = [
    {
      title: "Home",
      onClick: () => {
        navigate("/jewelry");
      },
    },
    // location.state?.prevPath !== "/jewelry" && {
    //   title: location.state?.prevPath
    //     ? location.state.prevPath
    //       .split("/")[1]
    //       .replace(
    //         location.state.prevPath.split("/")[1][0],
    //         location.state.prevPath.split("/")[1][0].toUpperCase()
    //       ).replace(
    //         location.state.prevPath.split("-")[1][0],
    //         location.state.prevPath.split("-")[1][0].toUpperCase()
    //       )
    //     : "",
    //   onClick: () => {
    //     navigate(location.state.prevPath, {
    //       state: { category: location.state?.category },
    //     });
    //   },
    // },
    location.state?.prevPath !== "/jewelry" && {
      title: location.state?.prevPath
        ? location.state?.prevPath
            .split("/")
            .slice(0)
            .map((segment) => {
              const words = segment.split("-");
              return words
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
            })
            .join(" ")
        : "",
      onClick: () => {
        navigate(location.state?.prevPath, {
          state: { category: location.state?.category },
        });
      },
    },

    {
      title: design?.stDesignName || "",
    },
  ];

  return (
    <>
      <Header />
      {/* <div className="headerBg"> */}
      <div className="xl:max-w-[1224px] max-w-auto xl:mx-auto mx-[20px] xl:w-full w-auto ">
        {/* <h3 className="lg:text-[22px] text-center font-semibold md:text-[26px] text-[20px] lg:leading-[52px] md:leading-[40px] leading-[30px] md:my-0 my-3">
          Customize your ring
        </h3> */}
        <div className="my-6">
          {/* <Breadcrumb tabs={location.pathname === "/product" ? breadCrumbDataWithCategory : breadCrumbDataWithCategory} /> */}

          <button
            type="button"
            onClick={() => {
              navigate(location.state?.prevPath || "/customized-jewelry", {
                state: { category: location.state?.category },
              });
            }}
            className="flex items-center outline-none gap-2 font-Primary"
          >
            <IoIosArrowBack /> Back
          </button>
        
        </div>
      </div>
      {/* </div> */}

      {designLoading ? (
        <ProductDetailsSkeleton />
      ) : (
        <div>
          <ProductDetails product={design} />
          {/* <ViewedProduct title={ProductlistTitle} /> */}
          {/* <Reviews /> */}
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default ProductDetail;
