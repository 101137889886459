import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import Pagination from "../Pagination";
import { useDispatch, useSelector } from "react-redux";
import AddVariation from "../Modals/AddVariation";
import {
  fetchDesignById,
  getDesignDataState,
} from "../../../store/Slices/designSlice";
import { GoKebabHorizontal } from "react-icons/go";
import { useThunk } from "../../../Hooks/use-thunk";
import usePagination from "../../../Hooks/use-Pagination";
import NoDataText from "../../shared/NoDataText";
import Loader from "../common/Loader";

const StepFour = () => {
  const [editId, setEditId] = useState("");
  const [index, setIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const { design: designIdData } = useSelector(getDesignDataState);
  const location = useLocation();

  const [getDesignDataById, loading, error] = useThunk(fetchDesignById);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: designIdData ? designIdData?.designVariation : [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    getDesignDataById(id);
  }, [getDesignDataById, id]);

  const handleEdit = (item, i) => {
    setEditId(item);
    setIndex(i + 1);
    setIsOpen(true);
  };

  return (
    <div className="px-6 pt-6">
      <AddVariation
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        item={editId}
        setEditId={setEditId}
        index={index}
        designIdData={designIdData}
        Description={location?.state?.name}
      />
      <div className="flex items-center justify-between gap-3">
        <h2 className="text-[22px] font-Josefin font-semibold">
          {location?.state?.name}
        </h2>
        <div className="flex items-center justify-end gap-3">
          <Link
            to={"/admin/product"}
            className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
          >
            <TiArrowBack className="text-[18px]" /> Back
          </Link>
        </div>
      </div>
      <div className="shadow-md sm:rounded-lg mt-4 overflow-hidden">
        <div
          className={`relative overflow-x-auto bg-white ${
            []?.length ? "min-h-[15rem]" : ""
          } `}
        >
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {/* <th scope="col" className="px-4 py-3 max-w-[20px]">
                  Sr.
                </th>
                <th scope="col" className="px-4 py-3">
                  Veriation Key
                </th> */}
                <th scope="col" className="px-4 py-3">
                  Veriation
                </th>
                <th scope="col" className="px-4 py-3">
                  Images
                </th>
                <th scope="col" className="px-4 py-3">
                  Setting Sale
                </th>
                <th scope="col" className="px-4 py-3">
                  Variation
                </th>
                <th scope="col" className="px-4 py-3">
                  Total USD
                </th>
                <th scope="col" className="px-4 py-3">
                  Done
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading && designIdData?.designVariation?.length
                ? designIdData?.designVariation.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-white text-xs border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                      >
                        {/* <th
                          scope="row"
                          className="px-4 py-3 max-w-[20px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {i + 1}
                        </th> */}
                        <td
                          title={item.stVariation}
                          className="px-4 py-3 whitespace-nowrap text-ellipsis overflow-hidden flex items-center gap-[20px]"
                        >
                          <td className="bg-slate-200 flex items-center justify-center w-[40px] h-[40px] rounded-full">{i + 1}</td>
                          <div>
                            <tr>
                              {item.stVariation}
                            </tr>
                            <tr>
                              {item.stDescription}
                            </tr>
                          </div>
                        </td>
                        {/* <td className="px-4 py-3 max-w-[100px] truncate whitespace-nowrap text-ellipsis overflow-hidden"></td> */}
                        <td className="px-4 py-1 w-[100px] whitespace-nowrap text-ellipsis overflow-hidden">
                          <div class="flex items-center">
                            {item.design_variation_images?.length
                              ? item.design_variation_images.map(
                                  (imge, ind) => {
                                    return (
                                      <img
                                        class="w-8 h-8 rounded-full border-gray-200 border transform hover:scale-125"
                                        src={imge}
                                        alt="data"
                                      />
                                    );
                                  }
                                )
                              : ""}
                          </div>
                        </td>
                        <td className="px-4 py-3 w-[130px] whitespace-nowrap text-ellipsis overflow-hidden">
                          {
                            (item?.dcSalePrice !== "0.00" ? 
                            item?.dcSalePrice: 
                            designIdData?.dcSalePrice)
                          }
                        </td>
                        <td className="px-4 py-3 w-[100px] whitespace-nowrap text-ellipsis overflow-hidden">
                          {item?.dcVariationPrice}
                        </td>
                        <td className="px-4 py-3 w-[100px] whitespace-nowrap text-ellipsis overflow-hidden">
                          {(
                            +(item?.dcSalePrice !== "0.00" ? 
                             item?.dcSalePrice : 
                             designIdData?.dcSalePrice) +    
                            +item?.dcVariationPrice
                            ).toFixed(2)
                          }
                        </td>
                        <td className="px-4 py-3 w-[40px] whitespace-nowrap text-ellipsis overflow-hidden">
                          {(item.flgIsActive === "1" || item.flgIsActive === 1) ? (
                            <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                              Y
                            </span>
                          ) : (
                            <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                              N
                            </span>
                          )}
                        </td>
                        <td className="px-4 py-3 w-[100px] text-center">
                          <button
                            type="button"
                            onClick={() => handleEdit(item, i)}
                          >
                            <GoKebabHorizontal className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                : !loading && (
                    <tr>
                      <td colSpan={9}>
                        <NoDataText text={"No Variation found!"} />
                      </td>
                    </tr>
                  )}
              {loading && (
                <tr>
                  <td colSpan={9} className="h-60">
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <Pagination
          totalItems={
            designIdData?.designVariation
              ? designIdData?.designVariation?.length
              : 0
          }
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          setActivePage={setActivePage}
        /> */}
      </div>
    </div>
  );
};

export default StepFour;
