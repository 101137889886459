import React, { useState, useEffect, useMemo } from "react";
import AdminModal from "../../Admin/common/AdminModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../../store/Slices/cartSlice";
import {
  fetchStoneSizeData,
  getStoneSizeState,
} from "../../../store/Slices/stoneSizeSlice";
import {
  fetchStoneColorData,
  getStoneColorState,
} from "../../../store/Slices/stoneColorSlice";
import {
  fetchStoneClarityData,
  getStoneClarityState,
} from "../../../store/Slices/stoneClaritySlice";
import TextInputAdmin from "../../Admin/common/TextInputAdmin";
import SelectMenuTableView from "../../Admin/common/SelectMenuTableView";
import { calibratedPlaceOrder } from "../../../Services/calibratedService";
import { MdOutlineDeleteOutline } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pb: 2,
  border: "0px solid #000",
  outline: "none",
};

export default function BasicModal(props) {
  const dispatch = useDispatch();

  const shapes = useSelector((state) => state?.calibrated?.data?.Shapes);
  const { data: size, error } = useSelector(getStoneSizeState);
  const { data: color } = useSelector(getStoneColorState);
  const { data: clarity } = useSelector(getStoneClarityState);

  const { item, formMode, open, handleClose, parentState } = props;

  const [state, setState] = useState({
    shapeOption: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      shapeOption: shapes?.map((data) => ({
        id: data.ShapeCode,
        title: data.Shape,
      })),
    }));
  }, [shapes]);

  useEffect(() => {
    dispatch(fetchStoneSizeData());
    dispatch(fetchStoneColorData());
    dispatch(fetchStoneClarityData());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    orderDate: "",
    remark: "",
    items: [],
  });

  const { shapeOption } = state;
  const { name, email, phone, orderDate, remark, items } = formData;

  useEffect(() => {
    setState((prevState) => ({ ...prevState, clarity: item?.Clarity || "" }));

    if (formMode === "add") {
      setFormData({
        name: "",
        email: "",
        phone: "",
        orderDate: "",
        items: [...Array(1).keys()]?.map((i) => ({
          POId: "",
          SrNo: "",
          unAttributeShapeId: "",
          Sieve: "",
          Colour: "",
          Clarity: "",
          Pcs: "",
          Carat: "",
          Remark: "",
        })),
      });
      setLoading(false);
    }
  }, [item]);

  const handleChange = (key, event) => {
    setFormData((prevState) => ({ ...prevState, [key]: event.target.value }));
  };

  const handleChangeRowData = (value, name, index, type) => {

    const updatedItems = items?.map((data, i) =>
      i === index ? { ...data, [name]: value } : data
    );

    setFormData((prev) => ({ ...prev, items: updatedItems }));
  };

  const handleRows = (opCode, index) => {
    if (opCode === "add") {
      setFormData((prev) => ({
        ...prev,
        items: [
          ...items,
          {
            POId: "",
            SrNo: "",
            unAttributeShapeId: "",
            Sieve: "",
            Colour: "",
            Clarity: "",
            Pcs: "",
            Carat: "",
            Remark: "",
          },
        ],
      }));
    }

    if (opCode === "delete") {
      setFormData({...formData, items: items?.filter((_,i)=>i!==index)})
    }
  };

  const sizeOption = useMemo(() => {
    const arr = [];
    size.length > 0 &&
      size.map((item, i) => {
        const data = {
          id: item.unStoneSizeIdentifier,
          shapeId: item.unAttributeValueShapeId,
          title: item.stStoneSizeName,
        };
        arr.push(data);
      });
    return arr;
  }, [size]);

  const sizeSelect = (shape) => {
    const filterData =
      sizeOption.length > 0 &&
      sizeOption.filter(
        (item) =>
          item.shapeId === shapeOption?.find((i) => i?.id === shape)?.title
      );
    return filterData || [];
  };

  const colorOption = useMemo(() => {
    const arr = [];
    color.length > 0 &&
      color.map((item, i) => {
        const data = {
          id: item.unStoneColorIdentifier,
          title: item.stStoneColorName,
        };
        arr.push(data);
      });
    return arr;
  }, [color]);

  const clarityOption = useMemo(() => {
    const arr = [];
    clarity.length > 0 &&
      clarity.map((item, i) => {
        const data = {
          id: item.unStoneClarityIdentifier,
          title: item.stStoneClarityName,
        };
        arr.push(data);
      });
    return arr;
  }, [clarity]);

  const handleSubmit = async () => {
    const itemList = items?.map((data, i) => ({
      POId: 0,
      SrNo: i + 1,
      unAttributeShapeId: data?.unAttributeShapeId || "",
      Sieve: data?.Sieve || "",
      Colour: data?.Colour || "",
      Clarity: data?.Clarity || "",
      Pcs: 5,
      Carat: 2.5,
      Remark: data?.Remark || "-",
      Status: "-",
      StatusRemark: "-",
    }));

    const payload = {
      POId: 0,
      PODate: orderDate || new Date().toISOString().slice(0, 10),
      POName: name,
      POEmail: email,
      POPhone: phone,
      PORemark1: "-",
      PORemark2: "-",
      PORemark3: "-",
      POStatus: "-",
      POStatusRemark: "-",
      details: itemList,
    };
    setLoading(true);
    const resp = await calibratedPlaceOrder(payload);

    if (resp.data) {
      toast.success("Order Placed Successfully.");
      handleClose();
    }

    setLoading(false);
  };

  const checkValidation = useMemo(() => {

    let isSubmit = true;

    // Items validation
    items?.forEach(item=>{
      if (
        !item?.unAttributeShapeId ||
        !item?.Sieve ||
        !item?.Colour ||
        !item?.Clarity ||
        !item?.Pcs ||
        !item?.Carat
      ) {
        isSubmit = false;
      }
    })

    // Name validation
    if(!name || !email || !phone || !items?.length)
    {
      isSubmit = false;
    }

    return isSubmit

  },[formData])

  return (
    <div>
      <AdminModal
        title={"Place Order"}
        onSubmit={handleSubmit}
        disableSubmit={!checkValidation}
        isOpen={open}
        onClose={() => handleClose()}
        submitButtonText={"Submit Order"}
        className="min-w-[50%]"
      >
        <div className="w-full">
          <div className="flex justify-between items-start">
            <div className="w-1/2 pr-4">
              <div className="py-0 flex items-center">
                <TextInputAdmin
                  type="text"
                  onChange={(e) => handleChange("name", e)}
                  className="rounded-t-lg rounded-b-none ring-1 border"
                  name="name"
                  value={name}
                  placeholder="Name"
                />
                <span className="text-red-500 ml-1">*</span>
              </div>
              <div className="py-0 flex items-center">
                <TextInputAdmin
                  type="text"
                  onChange={(e) => handleChange("email", e)}
                  className="rounded-none shadow-none ring-transparent border-2 border-t-0 border-b-0"
                  name="email"
                  value={email}
                  placeholder="Email"
                />
                <span className="text-red-500 ml-1">*</span>
              </div>
              <div className="py-0 flex items-center">
                <TextInputAdmin
                  type="text"
                  onChange={(e) => handleChange("phone", e)}
                  className="rounded-b-lg rounded-t-none ring-1 border"
                  name="phone"
                  value={phone}
                  placeholder="Phone"
                />
                <span className="text-red-500 ml-1">*</span>
              </div>
            </div>
            <div className="w-1/2 justify-end pl-4 flex flex-col">
              {/* <label class="block text-sm font-medium leading-6 text-gray-900">
                Order Date
              </label>
              <TextInputAdmin
                type="date"
                onChange={(e) => handleChange("orderDate", e)}
                className="h-9"
                name={orderDate}
                placeholder="Order Date"
              /> */}
              <TextInputAdmin
                type="text"
                onChange={(e) => handleChange("remark", e)}
                className="ring-1 border h-[108px]"
                name="remark"
                value={remark}
                placeholder="Remark"
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="flex items-center justify-between col-span-full">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Order Details
              </h2>
              <button
                type="button"
                onClick={() => handleRows("add")}
                className="bg-white px-[12px] lg:pb-1 text-[18px] shadow border border-gray-300 rounded text-slate-800"
              >
                +
              </button>
            </div>

            <div className="w-full overflow-x-auto">
              <table className="w-full border mt-2">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="min-w-[100px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                      Shape
                      <span className="text-red-500 ml-[2px]">*</span>
                    </th>
                    <th className="min-w-[100px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                      Size/Sieve
                      <span className="text-red-500 ml-[2px]">*</span>
                    </th>
                    <th className="min-w-[100px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                      Color
                      <span className="text-red-500 ml-[2px]">*</span>
                    </th>
                    <th className="min-w-[100px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                      Clarity
                      <span className="text-red-500 ml-[2px]">*</span>
                    </th>
                    <th className="w-[100px] border-r border-b text-center py-1.5 px-2 text-[13px] font-semibold">
                      <span className="pr-[6px]">
                        Pcs <span className="text-red-500">*</span>
                      </span>
                    </th>
                    <th className="w-[100px] border-r border-b text-right py-1.5 px-2 text-[13px] font-semibold">
                      <span className="pr-[6px]">
                        Carat <span className="text-red-500">*</span>
                      </span>
                    </th>
                    <th className="w-[100px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                      Remark
                    </th>
                    <th className="border-b w-[39px]"></th>
                  </tr>
                </thead>
                <tbody>
                  {items.length > 0 &&
                    items.map((item, i) => {
                      return (
                        <tr className="text-gray-700">
                          <td className="p-0">
                            <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white outline-none"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "unAttributeShapeId",
                                  i,
                                  ""
                                )
                              }
                              value={item?.unAttributeShapeId}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {shapeOption?.map((i) => {
                                return (
                                  <option value={i?.id}>{i?.title}</option>
                                );
                              })}
                            </select>
                          </td>
                          <td className="p-0">
                            {/* <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Sieve",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Sieve}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {sizeSelect(item.Shape)?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select> */}

                            {/* <input
                              type="text"
                              name="sieve"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Sieve",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Sieve}
                              className="w-full py-1.5 border px-2 text-[13px] outline-none"
                              placeholder="Size/Sieve"
                            /> */}

                            <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white outline-none"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Sieve",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Sieve}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {sizeSelect(item?.unAttributeShapeId)?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select>
                          </td>
                          <td className="p-0">
                            {/* <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Colour",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Colour}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {colorOption?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select> */}

                            <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white outline-none"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Colour",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Colour}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {colorOption?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select>
                          </td>
                          <td className="p-0">
                            {/* <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Clarity",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Clarity}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {clarityOption?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select> */}

                            <select
                              id="small"
                              class="w-full h-[34px] text-sm border bg-white outline-none"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Clarity",
                                  i,
                                  ""
                                )
                              }
                              value={item?.Clarity}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {clarityOption?.map((i) => {
                                return (
                                  <option value={i?.title}>{i?.title}</option>
                                );
                              })}
                            </select>
                          </td>
                          <td className="p-0">
                            <input
                              type="number"
                              name="Pcs"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Pcs",
                                  i,
                                  ""
                                )
                              }
                              value={item.Pcs}
                              className="w-full py-1.5 border px-2 text-[13px] text-center outline-none"
                              placeholder="Pcs"
                            />
                          </td>
                          <td className="p-0">
                            <input
                              type="number"
                              name="Carat"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Carat",
                                  i,
                                  ""
                                )
                              }
                              value={item.Carat}
                              className="w-full py-1.5 border px-2 text-[13px] text-right outline-none"
                              placeholder="Carat"
                            />
                          </td>

                          <td className="p-0">
                            <input
                              type="text"
                              name="Remark"
                              onChange={(e) =>
                                handleChangeRowData(
                                  e.target.value,
                                  "Remark",
                                  i,
                                  ""
                                )
                              }
                              value={item.Remark}
                              className="w-full py-1.5 border px-2 text-[13px] outline-none"
                              placeholder="Remark"
                            />
                          </td>
                          <td className="p-0 grid place-content-center">
                            <button
                              type="button"
                              onClick={() => handleRows("delete", i)}
                              className="bg-red-200 text-red-700 p-2 text-[16px] rounded"
                            >
                              <MdOutlineDeleteOutline />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                {!!items?.length && (
                  <thead>
                    <tr>
                      <td className="w-[150px] border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                        Total
                      </td>
                      <td className="border-b w-[39px]"></td>
                      <td className="border-b w-[39px]"></td>
                      <td className="border-b w-[39px]"></td>
                      <td className="w-[40px] border-r border-l border-b text-center py-1.5 px-2 text-[13px] font-semibold">
                        <span className="pr-[14px]">
                          {items?.reduce(
                            (total, item) => total + parseFloat(item?.Pcs || 0),
                            0
                          )}
                        </span>
                      </td>
                      <td className="w-[40px] border-r border-b text-right py-1.5 px-2 text-[13px] font-semibold">
                        <span className="pr-4">
                          {items
                            ?.reduce(
                              (total, item) =>
                                total + parseFloat(item?.Carat || 0),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </td>

                      <td className="border-b w-[39px]"></td>
                    </tr>
                  </thead>
                )}
              </table>
            </div>
          </div>
        </div>
      </AdminModal>
    </div>
  );
}
