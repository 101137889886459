import React, { useEffect } from "react";
import { sortByID } from "../../../Utils";
import { useMemo } from "react";
import { useState } from "react";
import {
  defaultCaratRange,
  defaultPriceRange,
  numberRegexWithDecimals,
} from "../../shared/constants";
import useApi from "../../../Hooks/use-Api";
import { getDiamondStockFilterParameter } from "../../../Services/diamondService";
import { IoCloseSharp } from "react-icons/io5";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BsDashCircleDotted, BsPlusCircleDotted } from "react-icons/bs";
import { Drawer, Slider } from "@mui/material";
import shap1 from "../../../assets/images/shap1.png";

function valuetext(value) {
  return `${value}°C`;
}

const DiamondFilter = ({
  setFilterValue,
  filterValue,
  loading,
  setPageNumber,
  hideChat = () => {},
  carat,
  shape,
  filterParameters,
}) => {
  const [value, setValue] = useState(defaultCaratRange);
  const [priceValue, setPriceValue] = useState(defaultPriceRange);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [debounceFields, setDebounceFields] = useState({
    StockID: "",
    certi: "",
  });
  const [formData, setFormData] = useState({
    cut: "",
    lab: "",
    type: "",
    polish: "",
    sym: "",
    shape: {},
    color: "",
    clarity: "",
    certi: "",
    StockID: "",
  });

  useEffect(() => {
    hideChat(selectedFilter);
  }, [selectedFilter, hideChat]);

  useEffect(() => {
    if (filterParameters) {
      // setValue([parseFloat(carat[0]), parseFloat(carat[1])]);
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
    }
  }, [filterParameters]);

  useEffect(() => {
    if (filterValue?.fromCarat && filterValue?.toCarat) {
      setValue([
        parseFloat(filterValue.fromCarat),
        parseFloat(filterValue.toCarat),
      ]);
    } else if (filterParameters) {
      setValue([
        parseFloat(filterParameters.carat[0].ID),
        parseFloat(filterParameters.carat[0].Name),
      ]);
    }
  }, [filterValue, filterParameters]);

  useEffect(() => {
    if (filterValue?.shape) {
      setFormData((prev) => ({
        ...prev,
        shape: { ID: filterValue?.shape?.id, name: filterValue?.shape?.name },
      }));
    }
  }, [filterValue]);

  const handleFilterChange = async (data, name) => {
    setPageNumber(1);

    const multiFilters = ["shape", "color", "clarity"];

    if (multiFilters.includes(name)) {
      const prevData =
        filterValue && filterValue[name] && filterValue[name].split(",");

      setFilterValue((prev) => {
        if (prevData) {
          return {
            ...prev,
            [name]: prevData.includes(data)
              ? prevData.filter((item) => item !== data).join(",")
              : prevData.concat(data).join(","),
          };
        } else {
          if (prev) {
            return { ...prev, [name]: data };
          } else {
            return { [name]: data };
          }
        }
      });
    } else if (name === "carat") {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, fromCarat: data[0], toCarat: data[1] };
        }
        return { fromCarat: data[0], toCarat: data[1] };
      });
    } else if (name === "price") {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, fromPrice: data[0], toPrice: data[1] };
        }
        return { fromPrice: data[0], toPrice: data[1] };
      });
    } else {
      setFilterValue((prev) => {
        if (prev) {
          return { ...prev, [name]: data };
        }
        return { [name]: data };
      });
    }
  };

  const handleClick = async () => {
    const data = {
      fromPrice:
        priceValue[0] === +filterParameters.amount[0].ID &&
        priceValue[1] === +filterParameters.amount[0].Name
          ? ""
          : priceValue[0],
      toPrice:
        priceValue[0] === +filterParameters.amount[0].ID &&
        priceValue[1] === +filterParameters.amount[0].Name
          ? ""
          : priceValue[1],
      cut: formData.cut,
      polish: formData.polish,
      sym: formData.sym,
      color: formData.color,
      clarity: formData.clarity,
      shape: { id: formData?.shape?.ID, name: formData?.shape?.name },
    };
    setPageNumber(1);
    setFilterValue((prev) => ({ ...prev, ...data }));
    setSelectedFilter("");
  };

  const handleChangeForm = (id, val, name) => {
    const exception = ["certi", "StockID"];
    if (exception.includes(name)) {
      setFormData((prev) => ({ ...prev, [name]: val }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: prev[name].ID === id ? "" : { ID: id, name: val },
      }));
    }
  };

  const handleChangeMultiData = (val, nam, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]:
        name !== "shape" && prev[name].ID === val ? "" : { ID: val, name: nam },
    }));
  };

  const handleChangeSlider = (data, name) => {
    if (name === "carat") {
      setFormData((prev) => ({
        ...prev,
        fromCarat: data[0],
        toCarat: data[1],
      }));
    } else if (name === "price") {
      setFormData((prev) => ({
        ...prev,
        fromPrice: data[0],
        toPrice: data[1],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: data,
      }));
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (debounceFields.StockID) {
        handleFilterChange(debounceFields.StockID, "StockID");
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [debounceFields.StockID]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (debounceFields.certi) {
        handleFilterChange(debounceFields.certi, "certi");
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [debounceFields.certi]);

  const handleClearFilter = (type) => {
    setPageNumber(1);
    setFilterValue((prev) => ({
      ...prev,
      ColorId: "",
      ClarityId: "",
      cut: null,
      clarity: null,
      color: null,
      toPrice: "",
      fromPrice: "",
      fromCarat: "",
      toCarat: "",
      polish: null,
      sym: null,
    }));
    setPriceValue([
      parseFloat(filterParameters.amount[0].ID),
      parseFloat(filterParameters.amount[0].Name),
    ]);
    setFormData((prev) => ({
      ...prev,
      cut: null,
      polish: null,
      sym: null,
      color: null,
      clarity: null,
    }));
    setDebounceFields({
      StockID: "",
      certi: "",
    });
  };

  const handleRemoveFilter = (name) => {
    setPageNumber(1);
    if (name === "price") {
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
    } else if (["StockID", "certi"].includes(name)) {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
      setDebounceFields((prev) => ({ ...prev, [name]: "" }));
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleRemoveFilterMobile = (name) => {
    setPageNumber(1);
    if (name === "price") {
      setPriceValue([
        parseFloat(filterParameters.amount[0].ID),
        parseFloat(filterParameters.amount[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
      setFormData((prev) => ({
        ...prev,
        fromPrice: "",
        toPrice: "",
      }));
    } else if (name === "carat") {
      setValue([
        parseFloat(filterParameters.carat[0].ID),
        parseFloat(filterParameters.carat[0].Name),
      ]);
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: "",
        toCarat: "",
      }));
      setFormData((prev) => ({
        ...prev,
        fromCarat: "",
        toCarat: "",
      }));
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
      setFormData((prev) => ({
        ...prev,
        [name]: ["shape", "color", "clarity"].includes(name) ? [] : "",
      }));
    }
  };

  const handleSelectAll = (data, event) => {
    const { name, checked } = event.target;
    setPageNumber(1);
    const value = data.map((item) => item.ID).join(",");
    if (checked) {
      setFilterValue((prev) =>
        prev ? { ...prev, [name]: value } : { [name]: value }
      );
    } else {
      setFilterValue((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const advancedFilterOptionsMobile = useMemo(() => {
    return {
      shape: {
        title: "Shape",
        value: sortByID(shape || [], "asc", "SrNo") || [],
      },
      carat: { title: "Carat", value: filterParameters?.carat?.[0] || {} },
      color: {
        title: "Color",
        value: sortByID(filterParameters?.color || [], "asc", "SrNo") || [],
      },
      clarity: {
        title: "Clarity",
        value: sortByID(filterParameters?.clarity || [], "asc", "SrNo") || [],
      },
      cut: {
        title: "Cut",
        value: sortByID(filterParameters?.cut || [], "asc", "SrNo") || [],
      },
      polish: {
        title: "Polish",
        value: sortByID(filterParameters?.polish || [], "asc", "SrNo") || [],
      },
      sym: {
        title: "Sym",
        value: sortByID(filterParameters?.sym || [], "asc", "SrNo") || [],
      },
      price: { title: "Price", value: filterParameters?.amount?.[0] || {} },
    };
  }, [filterParameters, shape]);

  const drawerData = useMemo(() => {
    if (selectedFilter) {
      return advancedFilterOptionsMobile[selectedFilter];
    }
    return null;
  }, [selectedFilter, advancedFilterOptionsMobile]);

  return (
    <div className="md:hidden">
      <div className="">
        <div className="flex max-w-full justify-start items-center gap-2 flex-wrap mb-4">
          {Object.keys(advancedFilterOptionsMobile)
            .slice(
              0,
              showMore ? Object.keys(advancedFilterOptionsMobile).length : 8
            )
            .map((filter, index) => {
              const count = filterValue?.[filter] ? filterValue?.[filter] : 0;
              return (
                <button
                  key={index}
                  onClick={() => {
                    setSelectedFilter(filter);
                  }}
                  className="inline-flex bg-transparent text-gray-600 border border-gray-300 rounded-3xl justify-center items-center px-2 py-0.5 lg:px-4 lg:py-1 lg:text-sm text-xs font-medium hover:bg-opacity-30 outline-none "
                >
                  {(!!count ||
                    (filter === "price" && filterValue?.fromPrice) ||
                    (filter === "carat" && filterValue?.fromCarat)) &&
                    filter !== "shape" && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFilterMobile(filter);
                        }}
                        className="rounded-full bg-gray-200 flex justify-center items-center w-4 h-4 mr-1"
                      >
                        <IoCloseSharp fontSize={12} />
                      </span>
                    )}
                  {advancedFilterOptionsMobile[filter].title}
                  {count ? `(${count.name})` : ""}
                  {filter === "price" &&
                    (filterValue?.fromPrice || filterValue?.toPrice) &&
                    `(${filterValue?.fromPrice} - ${filterValue?.toPrice})`}
                  {filter === "carat" && filterValue?.fromCarat
                    ? `(${filterValue?.fromCarat} - ${filterValue?.toCarat})`
                    : ""}
                  <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              );
            })}
          {/* <button onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? (
              <BsDashCircleDotted fontSize={18} />
            ) : (
              <BsPlusCircleDotted fontSize={18} />
            )}
          </button> */}
          <Drawer
            anchor="bottom"
            open={!!selectedFilter}
            onClose={() => setSelectedFilter("")}
          >
            <div className="flex flex-col justify-between items-center gap-2">
              <div className="px-2 py-1 border-b flex justify-between w-full items-center">
                <h3 className="text-lg font-semibold text-gray-600">
                  {drawerData?.title}
                </h3>
                <button onClick={() => setSelectedFilter("")}>
                  <IoCloseSharp fontSize={18} />
                </button>
              </div>
              <div className="px-3 w-full h-full py-6">
                {selectedFilter === "shape" ? (
                  <div className="flex justify-start items-center gap-2 flex-wrap">
                    {drawerData.value.map((shape, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          name="shape"
                          disabled={loading}
                          onClick={handleChangeMultiData.bind(
                            null,
                            shape.unAttributeValueId,
                            shape.stAttributeValueName,
                            "shape"
                          )}
                          className={`
                              min-w-[60px] border-2 group rounded duration-200 sm:hover:border-gray-500
                              ${
                                formData?.shape?.ID === shape.unAttributeValueId
                                  ? "border-gray-500"
                                  : "border-gray-300"
                              }
                            `}
                        >
                          <div>
                            <div className="py-2 px-2 ">
                              <img
                                src={shape.ImageURL || shap1}
                                alt={`shape-${index + 1}`}
                                className="m-auto h-[25px]"
                              />
                            </div>
                            <div
                              className={`text-xs sm:group-hover:bg-[#64748b] sm:group-hover:text-white duration-200 ${
                                formData?.shape?.ID === shape.unAttributeValueId
                                  ? "bg-[#64748b] text-white"
                                  : "bg-white text-[#64748b]"
                              }`}
                            >
                              {shape.stAttributeValueName}
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                ) : selectedFilter === "carat" ? (
                  <div className="px-3 w-full">
                    <Slider
                      value={value}
                      disabled
                      onChange={(e, val) => {
                        setValue(val);
                      }}
                      onChangeCommitted={(e, val) => {
                        handleChangeSlider(val, "carat");
                      }}
                      getAriaValueText={valuetext}
                      min={filterParameters?.carat?.[0]?.ID}
                      max={filterParameters?.carat?.[0]?.Name}
                      step={0.01}
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#475569",
                        },
                        "& .MuiSlider-track": {
                          color: "#475569",
                        },
                        "& .MuiSlider-rail": {
                          color: "#acc4e4",
                        },
                        "& .MuiSlider-active": {
                          color: "green",
                        },
                      }}
                    />
                    <div className="flex justify-between items-center">
                      <input
                        type="text"
                        disabled
                        name="fromCarat"
                        id="fromCarat"
                        value={value[0]}
                        onBlur={(e) => {
                          const { value: val } = e.target;
                          if (!parseInt(val.split(".").join(""))) {
                            handleChangeSlider(
                              filterParameters?.carat?.[0]?.ID,
                              "fromCarat"
                            );
                            setValue((prev) => [
                              filterParameters?.carat?.[0]?.ID,
                              prev[1],
                            ]);
                          }
                        }}
                        onChange={(e) => {
                          const { value: val } = e.target;
                          if (numberRegexWithDecimals.test(val)) {
                            if (parseInt(val.split(".").join(""))) {
                              handleChangeSlider(val, "fromCarat");
                              handleChangeSlider(value[1], "toCarat");
                            }
                            setValue((prev) => [val, prev[1]]);
                          }
                        }}
                        className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                      />
                      <input
                        type="text"
                        name="toCarat"
                        id="toCarat"
                        disabled
                        value={value[1]}
                        onBlur={(e) => {
                          const { value: val } = e.target;
                          if (!parseInt(val.split(".").join(""))) {
                            handleChangeSlider(
                              filterParameters?.carat?.[0]?.Name,
                              "toCarat"
                            );
                            setValue((prev) => [
                              prev[0],
                              filterParameters?.carat?.[0]?.Name,
                            ]);
                          }
                        }}
                        onChange={(e) => {
                          const { value: val } = e.target;
                          if (numberRegexWithDecimals.test(val)) {
                            if (parseInt(val.split(".").join(""))) {
                              handleChangeSlider(val, "toCarat");
                              handleChangeSlider(value[0], "fromCarat");
                            }
                            setValue((prev) => [prev[0], val]);
                          }
                        }}
                        className="w-12 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                      />
                    </div>
                  </div>
                ) : selectedFilter === "price" ? (
                  <div className="px-3 w-full">
                    <Slider
                      value={priceValue}
                      onChange={(e, val) => {
                        setPriceValue(val);
                      }}
                      onChangeCommitted={(e, val) => {
                        handleChangeSlider(val, "price");
                      }}
                      getAriaValueText={valuetext}
                      min={+filterParameters?.amount?.[0]?.ID}
                      max={+filterParameters?.amount?.[0]?.Name}
                      step={0.01}
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#475569",
                        },
                        "& .MuiSlider-track": {
                          color: "#475569",
                        },
                        "& .MuiSlider-rail": {
                          color: "#acc4e4",
                        },
                        "& .MuiSlider-active": {
                          color: "green",
                        },
                      }}
                    />
                    <div className="flex justify-between items-center">
                      <input
                        type="text"
                        name="fromPrice"
                        id="fromPrice"
                        value={priceValue[0]}
                        onBlur={(e) => {
                          const { value: val } = e.target;
                          if (!parseInt(val.split(".").join(""))) {
                            handleChangeSlider(
                              filterParameters?.amount?.[0]?.ID,
                              "fromPrice"
                            );
                            setPriceValue((prev) => [
                              filterParameters?.amount?.[0]?.ID,
                              prev[1],
                            ]);
                          }
                        }}
                        onChange={(e) => {
                          const { value: val } = e.target;
                          if (numberRegexWithDecimals.test(val)) {
                            if (parseInt(val.split(".").join(""))) {
                              handleChangeSlider(val, "fromPrice");
                              handleChangeSlider(priceValue[1], "toPrice");
                            }
                            setPriceValue((prev) => [val, prev[1]]);
                          }
                        }}
                        className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                      />
                      <input
                        type="text"
                        name="toPrice"
                        id="toPrice"
                        value={priceValue[1]}
                        onBlur={(e) => {
                          const { value: val } = e.target;
                          if (!parseInt(val.split(".").join(""))) {
                            handleChangeSlider(
                              filterParameters?.amount?.[0]?.Name,
                              "toPrice"
                            );
                            setPriceValue((prev) => [
                              prev[0],
                              filterParameters?.amount?.[0]?.Name,
                            ]);
                          }
                        }}
                        onChange={(e) => {
                          const { value: val } = e.target;
                          if (numberRegexWithDecimals.test(val)) {
                            if (parseInt(val.split(".").join(""))) {
                              handleChangeSlider(val, "toPrice");
                              handleChangeSlider(priceValue[0], "fromPrice");
                            }
                            setPriceValue((prev) => [prev[0], val]);
                          }
                        }}
                        className="w-20 rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
                      />
                    </div>
                  </div>
                ) : ["color", "clarity"].includes(selectedFilter) ? (
                  <div className="px-2 w-full flex justify-start items-center gap-2 flex-wrap">
                    {drawerData.value.map((filter, index) => {
                      return (
                        <button
                          key={index}
                          disabled={loading}
                          name="color"
                          onClick={handleChangeMultiData.bind(
                            null,
                            filter.ID,
                            filter.Name,
                            selectedFilter
                          )}
                          className={`
                            font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                            ${
                              formData?.[selectedFilter].ID === filter.ID
                                ? "border-gray-500"
                                : "border-gray-300"
                            }
                        `}
                        >
                          {filter.Name}
                        </button>
                      );
                    })}
                  </div>
                ) : ["StockID", "certi"].includes(selectedFilter) ? (
                  <div className="w-full px-3">
                    <input
                      type="text"
                      value={formData[selectedFilter]}
                      name={selectedFilter}
                      id={selectedFilter}
                      onChange={(e) => {
                        handleChangeForm(e.target.value, selectedFilter);
                      }}
                      className="w-full outline-none border-2 border-gray-400 rounded text-sm px-2 py-0.5"
                    />
                  </div>
                ) : (
                  <div className="px-2 w-full flex justify-start items-center gap-2 flex-wrap">
                    {drawerData &&
                      drawerData.value.map((filter, index) => {
                        return (
                          <button
                            key={index}
                            disabled={loading}
                            name="color"
                            onClick={handleChangeForm.bind(
                              null,
                              filter.ID,
                              filter.Name,
                              selectedFilter
                            )}
                            className={`
                            font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                            ${
                              formData?.[selectedFilter]?.ID === filter.ID
                                ? "border-gray-500"
                                : "border-gray-300"
                            }
                        `}
                          >
                            {filter.Name}
                          </button>
                        );
                      })}
                  </div>
                )}
              </div>
              {selectedFilter === "carat" ? (
                ""
              ) : (
                <div className="px-3 mb-2 w-full">
                  <button
                    onClick={handleClick}
                    className="bg-primaryColor text-sm text-white w-full py-1 rounded"
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
          </Drawer>
        </div>
        {filterValue && !!Object.values(filterValue).join("") && (
          <button
            onClick={handleClearFilter}
            className="flex justify-center items-center gap-2 text-sm text-gray-500"
          >
            <span className="w-full">Clear filter </span>
            <span className="rounded-full bg-gray-200 flex justify-center items-center p-0.5 ">
              <IoCloseSharp fontSize={12} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DiamondFilter;
