import React from "react";
import Header from "../Components/LandingComponent/Header";
import DiamondLists from "../Components/LandingComponent/DiamondLists";
import { useEffect } from "react";

const DiamondList = () => {
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <DiamondLists />
      {/* <Footer /> */}
    </div>
  );
};

export default DiamondList;
