import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Sidebar from "../Components/Admin/Sidebar";
import Header from "../Components/Admin/Header";
import { useSelector } from "react-redux";
import { getAuthState } from "../store/Slices/AuthSlice";
import useRolePermission from "../Hooks/use-RolePermission";

const AdminPenal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector(getAuthState);
  const { hasPermission, accessableRoutes } = useSelector(s => s.rolePermissions.rolePermissions)
  // const [hasPermission, accessableRoutes] = useRolePermission(
  //   location?.pathname
  // );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (location.pathname === "/admin" && user?.Role === "User" && location?.state?.prevPath && isLoggedIn) {
      navigate("/home");
    } else if (location.pathname === "/admin" && isLoggedIn) {
      navigate("/admin/dashboard");
    }

  }, [navigate, location.pathname, isLoggedIn]);

  let content;

  if (hasPermission) {
    content = <Outlet />;
  } else if (accessableRoutes?.length) {
    content = <Navigate to={accessableRoutes[0]} />;
  }

  return (
    <div className="flex h-full">
      <aside className="h-screen sticky top-0">
        <Sidebar />
      </aside>
      <div className="w-full flex flex-col bg-gradient-to-tr from-gray-50">
        <Header />
        {content}
      </div>
    </div>
  );
};

export default AdminPenal;
