import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import {
  MdOutlineImageNotSupported,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddDesigner from "./Modals/AddDesigner";
import Pagination from "./Pagination";
import ModalImage from "react-modal-image";
import {
  fetchSnapPost,
  getSnapPostDataState,
} from "../../store/Slices/snapPostSlice";
import { TiArrowBack } from "react-icons/ti";
import { DeleteSnap } from "../../Services/snapPostService";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";
import { FiFilter } from "react-icons/fi";
import SearchIcon from "@mui/icons-material/Search";
import { BsPlusLg } from "react-icons/bs";
import { debounce } from "@mui/material";
import Chip from '@mui/material/Chip';
import SnapPostDetailModal from "./SnapPostDetailModal";
import { downloadPDF } from "../../Pages/MakeOwnDesign";

const SnapPostList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(getSnapPostDataState);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchSnapPost());
  }, [dispatch]);

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDelete) {
      const res = await DeleteSnap(id);
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(fetchSnapPost());
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const handleSearchBox = (e) => {
    let params = {};
    if (e.target.value.length > 0) {
      params["Name"] = e.target.value;
    }
    dispatch(fetchSnapPost(params));
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const debouncedOnChange = debounce(handleSearchBox, 700);

  return (
    <>
      <SnapPostDetailModal
        open={openModal}
        handleClose={toggleModal}
        data={modalData}
      />

      <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
        <main className="h-full overflow-y-auto">
          <div className="px-6 pb-6 mx-auto">
            {/* <div className="flex items-center justify-end">
            <Link
              to={"/admin/transactionMenu"}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <TiArrowBack className="text-[20px]" /> Back
            </Link>
            <AddDesigner
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              editId={editId}
              setIsEdit={setEditId}
            />
          </div> */}
            <div className="w-auto mr-1 lg:mr-0">
              <div className="mt-2 flex flex-wrap rounded-md shadow-sm">
                <Link
                  to={"/admin/transactionMenu"}
                  className="rounded-l-md  text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50 "
                >
                  <TiArrowBack className="text-[18px]" /> Back
                </Link>
                <div className="relative flex flex-grow items-stretch focus-within:z-10 w-20 md:w-auto">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={debouncedOnChange}
                    className="block w-full rounded-none border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search Text here..."
                  />
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-r-md"
                >
                  <FiFilter
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Filter
                </button>
                <Link className="hidden bg-white text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 justify-center items-center gap-1 hover:bg-[#f8fafc] ">
                  <BsPlusLg className="text-[10px]" /> Add
                </Link>
              </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-t mt-4">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 w-[150px]">
                      Image
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Phone
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Shape
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Metal
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-4 py-3 w-[100px]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800">
                            <th
                              scope="row"
                              className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white w-[150px]"
                            >
                              {item?.jsonImages ? (
                                <ModalImage
                                  className="w-[40px] h-[40px] rounded"
                                  small={
                                    JSON.parse(item?.jsonImages)?.[0]?.url || ""
                                  }
                                  large={
                                    JSON.parse(item?.jsonImages)?.[0]?.url || ""
                                  }
                                  alt=""
                                />
                              ) : (
                                <MdOutlineImageNotSupported className="h-8 w-8 text-gray-500 ml-1" />
                              )}
                            </th>
                            <td className="px-4 py-1">{item.stName}</td>
                            <td className="px-4 py-1">{item.stEmailId}</td>
                            <td className="px-4 py-1">{item.stPhoneNo}</td>
                            <td className="px-4 py-1">{item.stCenterStone}</td>
                            <td className="px-4 py-1">{item.stMetal || "-"}</td>
                            <td className="px-4 py-1">
                              <Chip
                                label={item.stRemark2 ? "Quote" : "Snap"}
                                color={item.stRemark2 ? "success" : "primary"}
                                variant="outlined"
                                size="small"
                              />
                            </td>
                            <td className="px-4 py-1 w-[100px]">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setModalData(item);
                                    toggleModal();
                                  }}
                                >
                                  <MdOutlineRemoveRedEye className="text-[20px] text-gray-600 bg-gray-100 p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    downloadPDF(
                                      item?.jsonImages
                                        ? JSON.parse(item?.jsonImages)?.map(
                                            (i) => i?.url
                                          )
                                        : [],
                                      item?.stRefrenceURL,
                                      item?.stOrderNo,
                                      item?.dtOrderDate,
                                      item?.stCustomerCode,
                                      item?.stStyle,
                                      item?.stBandWidth,
                                      item?.stCenterStone,
                                      item?.stLength,
                                      item?.stWidth,
                                      item?.stDepth,
                                      item?.stCategorySize,
                                      item?.stMetal,
                                      item?.stStamping,
                                      item?.stEngraving,
                                      item?.dtDeliveryDate
                                    )
                                  }
                                >
                                  <IoMdDownload className="text-[20px] text-gray-600 bg-gray-100 p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDelete(item.unSnapId)}
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={7}>
                            <NoDataText text={"No data found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default SnapPostList;
