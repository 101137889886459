import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

const BackDrop = (props) => {
  return (
    <div
      className={`fixed inset-0 z-[20] bg-[#00000082] ${props.className}`}
      onClick={props.onClose}
    ></div>
  );
};

const ModalOverlay = ({
  title = "Test",
  children,
  onClose,
  className,
  size = "lg",
}) => {
  const classes = classNames(
    `w-full max-h-[100vh] overflow-auto fixed top-[50%] sm:px-[25px] px-[10px] py-[17px] left-[50%] z-[30] -translate-x-[50%] -translate-y-[50%] bg-[#FFFFFF] ${className}`,
    {
      "max-w-[645px]": size === "md",
      "max-w-[845px]": size === "lg",
      "max-w-full": size === "screen",
    }
  );
  return (
    // <div className="w-full max-h-[80vh] lg:max-w-[845px] md:max-w-[645px] sm:max-w-[445px] max-w-[300px] fixed top-[50%] px-[25px] py-[17px] left-[50%] z-[30] -translate-x-[50%] -translate-y-[50%] bg-[#FFFFFF] ">
    <div id="modelScroll" className={classes} onClick={onClose}>
      <div className="relative ">
        <h2 className="lg:text-xl md:text-lg text-base font-[400] ">{title}</h2>
        <button
          className="absolute top-[50%] right-[15px] -translate-y-[50%]"
          onClick={onClose}
        >
          <CloseIcon color={'#FFFFFF'} />
        </button>
      </div>
      <div className="overflow-y-auto h-full">{children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlay-root");

const Modal = ({ open, onClose, children, backdropClass, ...rest }) => {
  useEffect(() => {
    if(open){
      document.body.classList.add("overflow-hidden")
    }
    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [open])
  if (!open) return;
  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop onClose={onClose} className={backdropClass} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={onClose} {...rest}>
          {children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

const CloseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color}
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export default Modal;
