export const numberRegex = /^(\s*|\d+)$/;
export const numberRegexWithDecimals = /^[0-9]*(\.[0-9]{0,2})?$/;
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const everyNumber = /^[-]?[0-9]*[.,]?[0-9]+$/;
export const defaultCaratRange = [0, 8];
export const defaultPriceRange = [0, 10000];

export const imageLimitation = ["jpeg", "jpg", "png"];
export const stockFileUploadExt = ["csv", "xlsx"];

export const city = [
    { name: "Select" },
    { id: 1, name: "Surat" },
    { id: 2, name: "Baroda" },
    { id: 3, name: "Ahmedabad" },
    { id: 4, name: "Junagadh" },
]
export const states = [
    { name: "Select" },
    { id: 1, name: "Gujarat" },
    { id: 2, name: "Maharastra" },
    { id: 3, name: "Delhi" },
    { id: 4, name: "MP" },
    { id: 5, name: "UP" },
]
export const countries = [
    { name: "Select" },
    { id: 1, name: "India" },
    { id: 2, name: "USA" },
    { id: 3, name: "Canada" },
    { id: 4, name: "UK" },
    { id: 5, name: "Germany" },
]

export const acceptedFileExtensions = ["csv", "xlsx"]
