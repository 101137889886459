import React from "react";
import AdminModal from "../common/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeState } from "../../../store/Slices/attributeSlice";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { fetchMenu } from "../../../store/Slices/menuSlice";
import { createMenuData, updateMenuData } from "../../../Services/menuService";

const AddMenu = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [inputValue, setInputValue] = useState({ stMenuName: "", flgIsActive: true });
  const dispatch = useDispatch()
  const { data } = useSelector(getAttributeState);

  useEffect(() => {
    const editData = data.find((item) => item.unAttributeIdentifier === editId);
    if (editData) {
      setInputValue({
        stMenuName: editData.stMenuName,
        flgIsActive: editData.flgIsActive === "1" ? true : false,
      });
    }
  }, [editId, data]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "isActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setEditId("");
    setInputValue({ stMenuName: "", flgIsActive: true })
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stMenuName: inputValue.stMenuName,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unMenuIdentifier: editId
      }
      const res = await updateMenuData(payload);
      if (res.status === 200) {
        toast.success(res.data.message)
        dispatch(fetchMenu())
        handleClose();
      }
    } else {
      const payload = new FormData();
      payload.append("stMenuName", inputValue.stMenuName);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createMenuData(payload);
      if (res.status === 200) {
        toast.success(res.data.message)
        dispatch(fetchMenu())
        handleClose();
      }
    }
  };

  return (
    <AdminModal
      title={editId ? "Update Main Menu" : "Add Main Menu"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="stMenuName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Menu Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stMenuName"
            value={inputValue.stMenuName}
            id="stMenuName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Attribute"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="flgIsActive"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="flgIsActive"
            className=" text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddMenu;
