import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { TiArrowBack } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { DeleteCategoryStyle } from "../../Services/categoryStyleService";
import AddCategoryStyle from "./Modals/AddCategoryStyle";
import Pagination from "./Pagination";
import swal from "sweetalert";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";
import usePagination from "../../Hooks/use-Pagination";
import { useThunk } from "../../Hooks/use-thunk";
import {
  fetchCategoryStyleByCategoryId,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";

const CategoryStyleList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState();
  const { categoryStyles } = useSelector(getCategoryDataState);

  const [getCategoryStylebyCategoryId, loading] = useThunk(
    fetchCategoryStyleByCategoryId
  );

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: categoryStyles,
      itemsPerPage: 10,
    });

  useEffect(() => {
    getCategoryStylebyCategoryId(id);
  }, [getCategoryStylebyCategoryId, id]);

  const handleEdit = (id) => {
    setIsEdit(id);
    setIsOpen(true);
  };

  const handleDelete = async (styleId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await DeleteCategoryStyle(styleId);
      if (res.status === 200) {
        toast.success(res.data.message);
        getCategoryStylebyCategoryId(id);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <main className="h-full overflow-y-auto">
        <div className=" grid px-6 mx-auto">
          <div className="flex items-center justify-end gap-[10px]">
            <Link
              to={"/admin/categorylist"}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
            <AddCategoryStyle
              isAtOpen={isOpen}
              setIsAtOpen={setIsOpen}
              categoryId={id}
              editId={isEdit}
              setIsEdit={setIsEdit}
            />
          </div>
          <div className="sm:rounded-lg relative mt-4 rounded shadow-md">
            <div className="relative">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 w-[100px]">
                      Sort No.
                    </th>
                    <th scope="col" className="px-4 py-3 text-center w-[100px]">
                      Image Url
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Category Style Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Category Style Alias
                    </th>
                    <th scope="col" className="px-6 py-3 w-[100px]">
                      Y | N
                    </th>
                    <th scope="col" className="px-6 py-3 w-[100px]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData?.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white w-[100px]"
                            >
                              {item.inSortOrder}
                            </th>
                            <td className="px-6 py-1 text-center">
                              <img
                                src={item.stImageUrl}
                                alt="categoryStyle"
                                className="w-[40px] h-[40px] rounded m-auto"
                              />
                            </td>
                            <td className="px-6 py-1">
                              {item.stCategoryStyleName}
                            </td>
                            <td className="px-6 py-1">
                              {item.stCategoryStyleAlias}
                            </td>
                            <td className="px-6 py-1 w-[100px]">
                              {item.flgIsActive === "1" ? (
                                <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  Y
                                </span>
                              ) : (
                                <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  N
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-1 w-[100px]">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleEdit(item.unCategoryStyleIdentifier)
                                  }
                                >
                                  <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDelete(item.unCategoryStyleIdentifier)
                                  }
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td
                            colSpan={6}
                            className="text-[14px] text-center py-2"
                          >
                            <NoDataText text={"No any Category found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={6} className="h-60 py-2">
                        <Loader />
                      </td>
                    </tr>
                  )}
                  <tr className="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700 odd:bg-gray-900"></tr>
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={categoryStyles.length ?? 0}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CategoryStyleList;
