import api from "./api";

export const getCategoryStyle = () => {
    return api("/api/CategoryStyles", "get")
}

export const getCategoryStylewithId = (id) => {
    return api(`/api/CategoryStyles/${id}`, "get")
}

export const getCategoryStylebyCategoryId = (id) => {
    return api(`/api/CategoryStylesByCategoryId/${id}`, "get")
}

export const getCategoryStylesOfStockById = (id) => {
    return api(`/api/CategoryStylesOfStock/?CategoryId=${id}`, "get")
}

export const getCategoryStylesOfDesignById = (id) => {
    return api(`/api/CategoryStylesOfDesign?CategoryId=${id}`, "get")
}

export const AddCategoryStyleData = (data) => {
    return api(`/api/CategoryStyles`, "post", data)
}

export const UpdateCategoryStyle = (data) => {
    return api(`/api/CategoryStyles`, "put", data)
}

export const DeleteCategoryStyle = (id) => {
    return api(`/api/CategoryStyles/${id}`, "delete")
}