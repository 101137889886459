import React from "react";
import MenuCard from "./../common/MenuCard";
import User from "../../../assets/images/user.png";
import SideStone from "../../../assets/images/side stone prize.png";
import OrderReport from '../../../assets/images/order repoet.png'

const commonMasterList = [
  // {
  //   title: "User",
  //   icon: User,
  //   link: "/admin/user-role"
  // },
  {
    title: "Side Stone Price",
    icon: SideStone,
    link: "/admin/sideStonePriceList",
  },
  {
    title: "Metal Price",
    icon: OrderReport,
    link: "/admin/metalPrice",
  },
];

const UtilityMenuList = () => {
  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard
                title={item.title}
                key={index}
                link={item.link}
                icon={item.icon}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UtilityMenuList;
