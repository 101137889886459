import React from "react";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import ProductList from "../Components/LandingComponent/ProductList";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchCategory,
  getCategoryDataState,
} from "../store/Slices/categorySlice";
import { useThunk } from "../Hooks/use-thunk";
import classNames from "classnames";
import { transformCapitalize } from "../Utils";
import { useState } from "react";
import { getCategoryStylebyCategoryId } from "../Services/categoryStyleService";
import useApi from "../Hooks/use-Api";
import RingMenu from "../assets/images/RingMenu.png";
import Loader from "../Components/Admin/common/Loader";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import { FaHome } from "react-icons/fa";

const ECatelog = () => {
  const navigate = useNavigate();
  const [fetchCategoryData, loading, error] = useThunk(fetchCategory);
  const [fetchCategoryStyleData, categoryStyleData, styleLoading, styleError] =
    useApi(getCategoryStylebyCategoryId);
  const [selectedCategory, setSelectedcategory] = useState("");
  const [selectedSubCategory, setSelectedSubcategory] = useState("");
  const { data: categoryData } = useSelector(getCategoryDataState);

  useEffect(() => {
    fetchCategoryData();
  }, [fetchCategoryData]);

  useEffect(() => {
    if (categoryData.length) {
      fetchCategoryStyleData(categoryData[0].unCategoryIdentifier);
      setSelectedcategory(categoryData[0].unCategoryIdentifier);
    }
  }, [categoryData, fetchCategoryStyleData]);

  useEffect(() => {
    if (categoryStyleData && categoryStyleData.categories_style.length) {
      setSelectedSubcategory(
        categoryStyleData.categories_style[0].unCategoryStyleIdentifier
      );
    }
  }, [categoryStyleData]);

  const handleSelectcategory = async (id) => {
    setSelectedcategory(id);
    fetchCategoryStyleData(id);
  };

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  const linkTab = [
    {
      title: (
        <span className="hover:underline inline-flex justify-start items-center gap-1">
          {" "} Home{" "}
        </span>
      ),
      onClick: () => {
        navigate("/jewelry");
      },
    },
    {
      title: "E-Catelog",
    },
  ];
  return (
    <div>
      <Header />
      <div className="products max-w-[1224px]  mx-auto pt-4 mb-10">
        <Breadcrumb tabs={linkTab} />
      </div>
      {/* <ProductBanner className="xl:mb-[28px] lg:mb-[20px] mb-[15px]" /> */}
      <div className="products max-w-[1224px]  mx-auto p-4 xl:p-0 xl:mb-[100px] lg:mb-[80px] md:mb-[60] mb-[40px]">
        <div className="filters flex items-center justify-start gap-[9px] mb-[36px]">
          {categoryData.map((category, index) => {
            return (
              <div
                key={index}
                className="px-[43px] py-[20px] rounded-[12px] bg-[#F6F7F9] w-[230px]"
              >
                <button
                  className={
                    "flex justify-start items-center gap-[10px] cursor-pointer "
                  }
                  onClick={handleSelectcategory.bind(
                    null,
                    category.unCategoryIdentifier
                  )}
                >
                  {category.stImageUrl ? (
                    <img
                      src={category.stImageUrl}
                      alt="Ring"
                      className="h-5 w-5 rounded-full"
                    />
                  ) : (
                    <span className="w-5 h-5 rounded-full bg-slate-400 "></span>
                  )}
                  <span
                    className={classNames(
                      "border-b-[1px] border-transparent hover:border-gray-500 text-[18px] text-[#262626]",
                      {
                        "border-gray-500":
                          selectedCategory === category.unCategoryIdentifier,
                      }
                    )}
                  >
                    {transformCapitalize(category.stCategoryName)}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
        <div className="filters flex items-center justify-start gap-[9px] mb-[36px]">
          {!!categoryStyleData
            ? categoryStyleData.categories_style.map((style, index) => {
              return (
                <div
                  key={index}
                  className={
                    "flex justify-start items-center gap-[10px] cursor-pointer px-[43px] py-[20px] rounded-[12px] bg-[#F6F7F9] w-[230px]"
                  }
                  onClick={() =>
                    setSelectedSubcategory(style.unCategoryStyleIdentifier)
                  }
                >
                  <img src={RingMenu} alt="Ring" />
                  <span
                    className={classNames(
                      "border-b-[1px] border-transparent hover:border-gray-500 text-[18px] text-[#262626]",
                      {
                        "border-gray-500":
                          selectedSubCategory ===
                          style.unCategoryStyleIdentifier,
                      }
                    )}
                  >
                    {transformCapitalize(style.stCategoryStyleName)}
                  </span>
                </div>
              );
            })
            : null}
          {styleLoading && (
            <div className="flex justify-center items-center ">
              <Loader />
            </div>
          )}
        </div>
        <ProductList />
      </div>
      {/* <NewBanner /> */}
      <Footer />
    </div>
  );
};

export default ECatelog;
