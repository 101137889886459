import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStoneSize } from "../../Services/stoneSizeService";

export const fetchStoneSizeData = createAsyncThunk("size/fetchData", async () => {
  const response = await getStoneSize()
  return response.data.data.size
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const stoneSizeSlice = createSlice({
  name: "size",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoneSizeData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchStoneSizeData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchStoneSizeData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getStoneSizeState = state => state.stoneSizeData

export default stoneSizeSlice.reducer