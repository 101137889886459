import React, { useEffect, useState } from "react";
import wichlist from "../../../assets/images/wichlist.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shap1 from "../../../assets/images/shap1.png";
import shap2 from "../../../assets/images/shap2.png";
import shap3 from "../../../assets/images/shap3.png";
import shap4 from "../../../assets/images/shap4.png";
import shap5 from "../../../assets/images/shap5.png";
import shap6 from "../../../assets/images/shap6.png";
import shap7 from "../../../assets/images/shap7.png";
import shap8 from "../../../assets/images/shap8.jpg";
import diamond from "../../../assets/images/diamond.png";
import Calendar from "../../../assets/images/Calendar.png";
import truck from "../../../assets/images/truck.png";
import warranty from "../../../assets/images/warranty.png";
import noImage from "../../../assets/images/no-image.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../../store/Slices/cartSlice";
import { toast } from "react-hot-toast";
import {
  fetchAttributeValueData,
  getAttributeValueState,
} from "../../../store/Slices/attributeValueSlice";
import { designsVariationSearch } from "../../../Services/designService";
import {
  getSelectedDiamondState,
  removeDiamond,
  selectDiamond,
} from "../../../store/Slices/diamondSelectSlice";
import { useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { RiVideoLine } from "react-icons/ri";
import { CgPlayButtonO } from "react-icons/cg";
import VideoIcon from "../Common/VideoIcon";

const sizeArray = [4, 4.5, 5, 6, 6.5, 7, 7.5, 8];

const ProductDetails = ({ product }) => {
  const [data, setData] = useState(null);
  const [metalPurity, setMetalPurity] = useState(null);
  const [centerStoneRange, setCenterStoneRange] = useState(null);
  const [mainImage, setMainImage] = useState(product?.stDefImageURL);
  const [size, setSize] = useState(sizeArray[0]);
  const [showMore, setShowMore] = useState(false);
  const [showEngraving, setShowEngraving] = useState(false);
  const [addEngraving, setAddEngraving] = useState("");
  const [engraving, setEngraving] = useState("");
  const [selectedVariation, setSelectedVariation] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const { data: selectedDiamond } = useSelector(getSelectedDiamondState);

  useEffect(() => {
    setSelectedVariation(null);
  }, [location]);
  // 98c2301f-8dad-4b0a-8eac-d15cedf75097

  useEffect(() => {
    if (product?.designAttributeValue) {
      dispatch(fetchAttributeValueData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (state) {
      setData(state.attrData);
    }
  }, [state]);

  const images = useMemo(() => {
    if (product) {
      const productImage =
        product?.images?.length > 0
          ? product.images?.map((d) => {
              return {
                id: d.SortOrder,
                img: d.ImageURL,
              };
            })
          : [];
      const sortData = productImage.sort((a, b) => a.id - b.id);
      if (selectedVariation) {
        return [...selectedVariation.design_variation_images, ...sortData];
      } else {
        return sortData;
      }
    }
    return [];
  }, [selectedVariation, product]);

  const getData = useCallback(() => {
    if (product?.designAttribute?.length) {
      product?.designAttribute?.forEach((attr) => {
        const attrData = product.designAttributeValue.filter(
          (attrVal) => attrVal.unAttributeId === attr.unAttributeId
        );
        if (attrData?.length && !state?.attrData) {
          setData((prev) => ({
            ...prev,
            [attr?.stAttributeName]: {
              name: attrData?.[0]?.stAttributeValueName,
              id: attrData?.[0]?.unAttributeValueId,
            },
          }));
        }
      });
    }

    if (product?.designMetalTone?.length) {
      setMetalPurity({
        name: product?.designMetalTone?.[0]?.stAttributeValueName,
        id: product?.designMetalTone?.[0]?.unAttributeValueIdentifier,
        dcPrice: product?.designMetalTone?.[0]?.dcPrice,
      });
    }

    if (product?.designMainStone?.length) {
      setCenterStoneRange({
        name: product?.designMainStone?.[0]?.stAttributeValueName,
        id: product?.designMainStone?.[0]?.unAttributeValueIdentifier,
        dcFromCarat: product?.designMainStone?.[0]?.dcFromCarat,
        dcToCarat: product?.designMainStone?.[0]?.dcToCarat,
      });
    }

    if (Object.keys(state?.metalPurity || {})?.length)
      setMetalPurity(state?.metalPurity);
  }, [product, state]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (images?.length) {
      setMainImage(images?.[0]);
    }
  }, [images]);

  useEffect(() => {
    if (data) {
      variationSearch();
    }
  }, [data]);

  const variationSearch = async () => {
    try {
      const payload = new FormData();
      const idsArr = Object.values(data).map((item) => item?.id);
      payload.append("unDesignId", id);
      payload.append("stVariationKey", idsArr.join());
      const response = await designsVariationSearch(payload);
      if (response.data.success) {
        setSelectedVariation(response.data.data);
      }
    } catch (e) {}
  };

  const isSelectedDiamond = useMemo(() => {
    if (selectedDiamond?.length) {
      return (
        !!selectedDiamond.find((item) => item?.productId === id) &&
        selectedDiamond?.length === +product?.inMainStonesPcs
      );
    }
    return false;
  }, [selectedDiamond, id, product]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const productImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
    // variableWidth: true,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productMobileImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
  };

  const handleChangeShape = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetal = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetalPurity = (value, valId, dcPrice) => {
    setMetalPurity({ name: value, id: valId, dcPrice });
  };

  const handleCenterStoneRange = (item) => {
    setCenterStoneRange({
      name: item?.stAttributeValueName,
      id: item?.unAttributeValueIdentifier,
      dcFromCarat: item?.dcFromCarat,
      dcToCarat: item.dcToCarat,
    });
  };

  const handleAddToCart = () => {
    if (isSelectedDiamond || !product?.inMainStonesPcs) {
      const cartData = {
        product: {
          id: id,
          title: product?.stDesignName,
          price: productPrice,
          image: product?.stFocusImageURL,
          engraving: addEngraving,
          size,
          attributes: data,
          details: product?.details,
          metal: product?.metal,
          DesignVariationId:
            selectedVariation?.unDesignVariationIdentifier || "",
          centerStoneRange,
        },
        diamond: selectedDiamond.map((dmnd) => {
          return {
            ...dmnd,
            title: "Diamond",
            id: dmnd?.STOCKNO,
            carat: dmnd?.CARAT,
            price: dmnd?.Amount || 0,
            shape: dmnd?.SHAPE,
            image: dmnd?.stImageUrl,
          };
        }),
        total:
          Number(productPrice) +
          Number(
            selectedDiamond.reduce(
              (acc, cur) => acc + parseInt(cur.Amount),
              0
            ) || 0
          ) +
          Number(metalPurity?.dcPrice || 0) * Number(product?.dcNtWt || 1),
      };
      dispatch(openCart(true));
      dispatch(addToCart(cartData));
      toast.success("Successfully Added to Cart.");
    } else {
      navigate("/diamondlist", {
        state: {
          product: id,
          attrData: data,
          productData: product,
          centerStoneRange,
          metalPurity,
          prevPath: state?.prevPath,
        },
      });
    }
  };

  const handleRemoveDiamond = (stockId) => {
    const filterData =
      selectedDiamond.length > 0 &&
      selectedDiamond.filter((item) => item.StoneNo !== stockId);
    dispatch(removeDiamond(filterData));
  };

  const productPrice = useMemo(() => {
    if (selectedVariation) {
      return (
        parseInt(selectedVariation?.dcSalePrice) +
          parseInt(selectedVariation?.dcVariationPrice) ||
        parseInt(product?.dcSalePrice)
      );
    }
    return parseInt(product?.dcSalePrice) || 0;
  }, [selectedVariation, product]);

  const productDescription = useMemo(() => {
    return product?.Description1 || "";
  }, [product]);

  const variationDescription = useMemo(() => {
    if (selectedVariation?.stDescription) {
      return selectedVariation?.stDescription;
    }
  }, [selectedVariation]);

  const stonePrice = useMemo(() => {
    if (selectedDiamond?.length && isSelectedDiamond) {
      return selectedDiamond.reduce(
        (acc, cur) => acc + parseInt(cur.Amount),
        0
      );
    }
    return 0;
  }, [selectedDiamond, isSelectedDiamond]);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  return (
    <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-[20px] mx-auto">
      <div className="items-start lg:flex-row flex-col grid sm:grid-cols-12 gap-[42px]">
        <div className="lg:flex xl:col-span-6 lg:col-span-6 col-span-full w-auto lg:w-full sideImages">
          <div className="my-4 w-1/4 bottomImg p-3 pt-0 mt-2 mb-0 pb-0">
            {!!images?.length && (
              <Slider
                {...productImageSettings}
                className="details_page max-w-[100%] mounting-detail-slider"
              >
                {images.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                    >
                      {(
                        typeof item === "object"
                          ? item.img.endsWith("mp4") || item.img.endsWith("mov")
                          : item.endsWith("mp4") || item.endsWith("mov")
                      ) ? (
                        // <CgPlayButtonO className="text-[20px]  w-[30px] h-[30px] object-cover border-1 border-[#e2e8f0]" onClick={() => setMainImage(item)} />
                        // <VideoIcon onClick={() => setMainImage(item)} />
                        <div className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50">
                          <CgPlayButtonO
                            className="text-[20px] w-[30px] h-[30px] lg:w-[100px] lg:h-[100px] object-scale-down border-1 border-[#e2e8f0]"
                            onClick={() => setMainImage(item)}
                          />
                        </div>
                      ) : (
                        <img
                          onClick={() => setMainImage(item)}
                          src={typeof item === "object" ? item.img : item}
                          alt="Product"
                          className="w-full h-full lg:w-[100px] lg:h-[100px] object-scale-down border-1 border-[#e2e8f0]"
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
          <div className="lg:hidden block col-span-full shadow-lg">
            <Slider
              {...productMobileImageSettings}
              className="details_page max-w-[100%] w-[200px] md:w-[160px] h-[338px] mx-auto flex items-center"
            >
              {images.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="details__image max-h-[330px] overflow-hidden"
                  >
                    {(
                      typeof item === "object"
                        ? item?.img?.endsWith("mp4") ||
                          item?.img?.endsWith("mov")
                        : item?.endsWith("mp4") || item?.endsWith("mov")
                    ) ? (
                      <video
                        src={typeof item === "object" ? item.img : item}
                        alt="Product"
                        className="m-auto w-full h-full object-scale-down bannerImg border-0 border-[#e2e8f0]"
                        autoPlay
                        muted
                        loop
                      ></video>
                    ) : (
                      <img
                        src={typeof item === "object" ? item.img : item}
                        alt="Product"
                        className="h-[285px] md:h-full w-full lg:w-[185px] object-scale-down object-center border-0"
                      />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="w-full lg:w-3/4">
            {(
              typeof mainImage === "object"
                ? mainImage?.img?.endsWith("mp4") ||
                  mainImage?.img?.endsWith("mov")
                : mainImage?.endsWith("mp4") || mainImage?.endsWith("mov")
            ) ? (
              <video
                src={typeof mainImage === "object" ? mainImage.img : mainImage}
                alt="Product"
                className="m-auto w-[495px] h-[495px] shadow-lg lg:block hidden border-0 border-[#e2e8f0] object-scale-down"
                autoPlay
                muted
                loop
              ></video>
            ) : (
              <img
                src={typeof mainImage === "object" ? mainImage.img : mainImage}
                alt="Product"
                className="m-auto lg:w-[400px] lg:h-[400px] shadow-lg lg:block hidden border-0 border-[#e2e8f0] object-scale-down"
              />
            )}
          </div>
        </div>
        <div className="xl:col-span-6 lg:col-span-6 col-span-full w-full">
          <div className="flex items-start justify-between mb-[6px]">
            <h2 className="md:text-[30px] text-[24px] lg:leading-[43px] md:leading-[38px] leading-[32px] font-[400] text-[#000] font-playfair">
              {product?.stDesignName}
            </h2>
            {/* <button type="button" className="p-3 sm:block hidden">
              <img src={wichlist} alt="wichlist" />
            </button> */}
          </div>
          <p className="lg:text-[18px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] lg:mb-4 mb-3 block">
            {product?.mounting?.MouShortDesc}
          </p>
          <p className="lg:text-[18px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] lg:mb-4 mb-3 block">
            {product?.mounting?.MainStoneShape}
          </p>
          {/* <p
            dangerouslySetInnerHTML={{ __html: productDescription }}
            className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] py-[5px] px-[12px] shadow-xl  text-[#000] font-[300] lg:mb-4 mb-3"
          ></p> */}
          <div className="sm:mb-6 mb-4">
            {product?.designAttribute?.length &&
              product?.designAttribute?.map((item, i) => {
                if (item.stAttributeName === "Size") {
                  const SizeData = product?.designAttributeValue.filter(
                    (itm) => itm.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="mb-4">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">{data?.Shape?.name}</span> */}
                      </h3>
                      <div className="flex items-center gap-2">
                        {SizeData.length > 0 &&
                          SizeData.map((itm, i) => {
                            return (
                              <button
                                key={i}
                                type="button"
                                onClick={handleChangeShape.bind(
                                  null,
                                  item.stAttributeName,
                                  itm.stAttributeValueName,
                                  itm?.unAttributeValueId
                                )}
                                className={`flex items-center justify-center w-[40px] h-[40px] border-2 rounded-full shadow-lg border-[#cbd5e1] ${
                                  data?.Size?.name === itm.stAttributeValueName
                                    ? "bg-[#f1f5f9]"
                                    : ""
                                }`}
                              >
                                {itm.stAttributeValueAlias}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                }
                if (
                  item.stAttributeName === "Center Stone Shape" ||
                  item.stAttributeName === "Side Stone Shape"
                ) {
                  const ShapeData = product?.designAttributeValue.filter(
                    (itm) => itm.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="mb-4">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">{data?.Shape?.name}</span> */}
                      </h3>
                      {ShapeData.length >= 7 ? (
                        <Slider {...settings}>
                          {ShapeData.length > 0 &&
                            ShapeData.map((itm, i) => {
                              return (
                                <button
                                  key={i}
                                  type="button"
                                  onClick={handleChangeShape.bind(
                                    null,
                                    item.stAttributeName,
                                    itm.stAttributeValueName,
                                    itm?.unAttributeValueId
                                  )}
                                  className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${
                                    data?.[item.stAttributeName]?.name ===
                                    itm.stAttributeValueName
                                      ? "bg-[#f1f5f9]"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={shapeImages(itm.stAttributeValueName)}
                                    alt={`shape-${i + 1}`}
                                    className="m-auto h-[30px]"
                                  />
                                </button>
                              );
                            })}
                        </Slider>
                      ) : (
                        <div className="flex items-center gap-2">
                          {ShapeData.length > 0 &&
                            ShapeData.map((itm, i) => {
                              return (
                                <button
                                  key={i}
                                  type="button"
                                  onClick={handleChangeShape.bind(
                                    null,
                                    item.stAttributeName,
                                    itm.stAttributeValueName,
                                    itm?.unAttributeValueId
                                  )}
                                  className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${
                                    data?.[item.stAttributeName]?.name ===
                                    itm.stAttributeValueName
                                      ? "bg-[#f1f5f9]"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={itm.stImageURL}
                                    alt={`shape-${i + 1}`}
                                    className="m-auto h-[30px]"
                                  />
                                </button>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  );
                }
                if (item.stAttributeName === "Select Precious Metal") {
                  const MetalData = product?.designAttributeValue.filter(
                    (it) => it.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="my-5">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">
                          {data?.[item.stAttributeName]?.name}
                        </span> */}
                      </h3>
                      <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
                        {MetalData?.length > 0 &&
                          MetalData.map((it) => {
                            return (
                              <button
                                type="button"
                                onClick={handleChangeMetal.bind(
                                  null,
                                  item.stAttributeName,
                                  it.stAttributeValueName,
                                  it.unAttributeValueId
                                )}
                                className={`border-4 ${
                                  it.stAttributeValueAlias === "PL"
                                    ? "px-2 py-1 w-[37px] h-[37px]"
                                    : "px-1 py-1"
                                } ${
                                  data?.[item.stAttributeName]?.name ===
                                    it.stAttributeValueName &&
                                  it.stAttributeValueName?.includes(
                                    "White Gold"
                                  )
                                    ? "bg-[#ececec]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Rose Gold"
                                      )
                                    ? "bg-[#e6be8a]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Yellow Gold"
                                      )
                                    ? "bg-[#d4af37]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Yellow Gold"
                                      )
                                    ? "bg-[#e5e4e2]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Platinum"
                                      )
                                    ? "bg-[#d6d5d5]"
                                    : "bg-[#fff]"
                                }
                                    rounded-full ${
                                      it.stAttributeValueName?.includes(
                                        "White Gold"
                                      )
                                        ? "border-[#e4e5e2]"
                                        : it.stAttributeValueName?.includes(
                                            "Rose Gold"
                                          )
                                        ? "border-[#e6be8a]"
                                        : it.stAttributeValueName?.includes(
                                            "Yellow Gold"
                                          )
                                        ? "border-[#d4af37]"
                                        : "border-[#e5e4e2]"
                                    } font-semibold text-[14px]`}
                              >
                                {it.stAttributeValueAlias}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                }
                const attrValData = product.designAttributeValue.filter(
                  (attrVal) => attrVal.unAttributeId === item.unAttributeId
                );
                return (
                  <div className="my-4 ">
                    <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                      {item.stAttributeName}{" "}
                      {/* <span className="font-[300]">
                        {data?.[item.stAttributeName]?.name}
                      </span> */}
                    </h3>
                    <div className="flex items-center gap-2">
                      {attrValData?.length > 0 &&
                        attrValData?.map((band) => {
                          return (
                            <button
                              key={band.unAttributeValueId}
                              type="button"
                              onClick={handleChangeMetal.bind(
                                null,
                                item.stAttributeName,
                                band.stAttributeValueName,
                                band.unAttributeValueId
                              )}
                              className={`border px-3 ${
                                data?.[item.stAttributeName]?.name ===
                                band.stAttributeValueName
                                  ? "bg-[#f1f5f9]"
                                  : ""
                              } rounded p-1`}
                            >
                              {band.stAttributeValueName}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                );
              })}

            {product?.designMetalTone?.length > 0 && (
              <div className="my-4 ">
                <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                  Select Metal Purity
                </h3>
                <div className="flex items-center gap-2">
                  {product?.designMetalTone?.map((item) => {
                    return (
                      <button
                        key={item.unAttributeValueIdentifier}
                        type="button"
                        onClick={handleChangeMetalPurity.bind(
                          null,
                          item.stAttributeValueName,
                          item.unAttributeValueIdentifier,
                          item.dcPrice
                        )}
                        className={`border px-3 ${
                          metalPurity?.name === item?.stAttributeValueName
                            ? "bg-[#f1f5f9]"
                            : ""
                        } rounded p-1`}
                      >
                        {item.stAttributeValueName}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}

            {product?.designMainStone?.length > 0 &&
              !product?.designMainStone?.every(
                (i) => !i.stAttributeValueName
              ) && (
                <div className="my-4 ">
                  <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                    Center Stone Range
                  </h3>
                  <div className="flex items-center gap-2">
                    {product?.designMainStone?.map((item) => {
                      if (!item.stAttributeValueName) return;

                      return (
                        <button
                          key={item.unAttributeValueIdentifier}
                          type="button"
                          onClick={handleCenterStoneRange.bind(null, item)}
                          className={`border px-3 ${
                            centerStoneRange?.name ===
                            item?.stAttributeValueName
                              ? "bg-[#f1f5f9]"
                              : ""
                          } rounded p-1`}
                        >
                          {item.stAttributeValueName}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}

            {(isSelectedDiamond || !product?.inMainStonesPcs) &&
              product?.isSize === 1 && (
                <>
                  {product?.isSize !== "0" && (
                    <div className="mt-4">
                      <h3 className="text-[16px] leading-[36px] font-[500] text-[#000] mb-[4px] font-playfair">
                        Choose your ring size
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {sizeArray
                          .slice(0, showMore ? sizeArray.length : 5)
                          .map((item, index) => {
                            return (
                              <button
                                key={index}
                                type="button"
                                onClick={() => {
                                  setSize(item);
                                }}
                                className={`flex justify-center items-center w-10 h-10 border rounded ${
                                  size === item ? "bg-[#f1f5f9]" : ""
                                }`}
                              >
                                {item}
                              </button>
                            );
                          })}
                      </div>
                      {!showMore && (
                        <button
                          onClick={() => setShowMore((prev) => !prev)}
                          className="text-xs underline text-slate-400 "
                        >
                          Show More size
                        </button>
                      )}
                    </div>
                  )}
                  {/* {product?.isEngraving !== "0" && (
                  <div className="mt-4">
                    {!!!addEngraving && (
                      <button
                        onClick={() => setShowEngraving(true)}
                        className="text-lg mb-3 font-Josefin font-extrabold text-slate-600"
                      >
                        Add Engraving
                        <span className="text-base font-[500] text-[#000000]">
                          (optional)
                        </span>
                      </button>
                    )}
                    {!!addEngraving && (
                      <div className="w-full flex justify-between items-center mb-3">
                        <div className="text-lg font-bold">
                          Your engraving :{" "}
                          <span className="text-[24px] font-Allura font-[600] tracking-wider text-[#000000]">
                            {" "}
                            {addEngraving}
                          </span>
                        </div>
                        {!showEngraving && (
                          <button
                            onClick={() => setShowEngraving(true)}
                            className="text-xs underline text-slate-400 "
                          >
                            Modify
                          </button>
                        )}
                      </div>
                    )}
                    {showEngraving && (
                      <>
                        <div>
                          <input
                            type="text"
                            value={engraving}
                            onChange={(e) => {
                              e.target.value?.length <= 20 &&
                                setEngraving(e.target.value);
                            }}
                            className="font-Allura px-3 h-10 w-full outline-none text-[#626262] text-base border-[1px] border-slate-400 "
                          />
                          <span className="text-xs text-slate-600">
                            Character limit: {engraving?.length}/20
                          </span>
                        </div>
                        <button
                          onClick={() => {
                            setAddEngraving(engraving);
                            setShowEngraving(false);
                          }}
                          className="outline-none border-2 border-slate-700 w-full py-2 text-base font-Josefin mt-3"
                        >
                          Add engraving
                        </button>
                      </>
                    )}
                  </div>
                )} */}
                </>
              )}
            <h3 className="hidden lg:text-[25px] text-[18px] lg:leading-[36px] leading-[30px] text-[#000] font-[400] my-3 flex items-center gap-2">
              US${" "}
              {(
                productPrice +
                stonePrice +
                Number(metalPurity?.dcPrice || 0) * Number(product?.dcNtWt || 1)
              ).toFixed(2)}
              {selectedDiamond.length > 0 ? (
                <span className="text-[14px] font-[400]">
                  (Including Center Stone)
                </span>
              ) : (
                <span className="text-[14px] font-[400]">(Setting Price)</span>
              )}
            </h3>
            {!!selectedDiamond?.length && (
              <div className="hidden my-4">
                {/* <h3 className="text-lg font-[500] font-Josefin text-[#000] m-0 mb-1">
                Selected Diamonds : &nbsp;
              </h3> */}
                <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
                  {selectedDiamond.map((dia, index) => {
                    return (
                      <div
                        key={index}
                        className="text-sm text-gray-700 col-span-2"
                      >
                        <div className="flex items-center gap-4 border p-2 pr-4 rounded relative">
                          <div className="overflow-hidden">
                            {dia.ImageUrl !== null ? (
                              <img
                                src={dia.ImageUrl}
                                alt="ImageUrl"
                                className="w-[60px] h-[60px] object-scale-down rounded"
                              />
                            ) : (
                              <div className="w-[60px] h-[60px] bg-slate-200 rounded"></div>
                            )}
                          </div>
                          <div>
                            <div className="text-[13px]">
                              <span className="font-[600]">Shape:</span>{" "}
                              {dia.SHAPE}
                            </div>
                            <div className="flex items-center">
                              <div className="pr-2 text-[13px]">
                                <span className="font-[600]">Carat:</span>{" "}
                                {dia.CARAT}
                              </div>
                              <div className="text-[13px]">
                                <span className="font-[600]">Colour:</span>{" "}
                                {dia.COLOUR}
                              </div>
                            </div>
                            <div className="text-[13px]">
                              <span className="font-[600]">Clarity:</span>{" "}
                              {dia.CLARITY}
                            </div>
                          </div>
                          <div className="absolute top-[5px] right-[10px]">
                            <button
                              onClick={() => handleRemoveDiamond(dia.StoneNo)}
                            >
                              <IoMdClose />
                            </button>
                          </div>
                        </div>
                        {/* <span className="font-[500]"></span> {dia.SHAPE}, {dia.CARAT}, {dia.COLOUR}, {dia.CLARITY} */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <p className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] mb-9 sm:hidden block">
            {product?.stRemark1}
          </p>
          <button
            onClick={handleAddToCart}
            className="hidden w-full block text-center font-playfair tracking-[1px] bg-[#334155] text-[#fff] text-[16px] leading-[32px] py-[6px]"
          >
            {isSelectedDiamond || !product?.inMainStonesPcs
              ? "Add To Cart"
              : "Select Your Diamond"}
          </button>
          <div className="flex items-start justify-end mb-[40px] md:flex-row flex-col gap-14 mt-8">
            <div className="w-full lg:text-left sm:text-center text-left">
              {/* <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={diamond} alt="diamond" /> Jewellers created diamond{" "}
          </p>  
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={Calendar} alt="diamond" />
            Made to order. Order now, ships by Thu, Mar 2
          </p>
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={truck} alt="diamond" /> Free shipping{" "}
          </p>
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={warranty} alt="diamond" /> Lifetime warranty
          </p> */}
              {/* <p
                dangerouslySetInnerHTML={{ __html: variationDescription }}
                className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] lg:mb-4 mb-3"
              ></p> */}
              <p
                dangerouslySetInnerHTML={{ __html: productDescription }}
                className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] py-[5px] px-[12px] shadow-xl  text-[#000] font-[300] lg:mb-4 mb-3"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
