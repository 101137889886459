import React from "react";
import MenuCard from "./../common/MenuCard";
import Attribute from '../../../assets/images/attibute.png'
import Categoryicon from '../../../assets/images/categoryicon.png'
import Supplier from '../../../assets/images/Supplier.png'
import Designer from '../../../assets/images/Designer.png'
import Design from '../../../assets/images/Design.png'
import Tag from '../../../assets/images/Tag manager.png'
import Color from '../../../assets/images/Color.png'
import Clearify from '../../../assets/images/Clearify.png'
import Size from '../../../assets/images/Size.png'
import Diamondlist from '../../../assets/images/diamond.png'

const MasterMenuList = () => {
  const commonMasterList = [
    {
      title: "Attribute",
      icon: Attribute,
      link: "/admin/attributelist"
    },
    {
      title: "Category",
      icon: Categoryicon,
      link: "/admin/categorylist"
    },
    {
      title: "Supplier",
      icon: Supplier,
      link: "/admin/supplierlist"
    },
    {
      title: "Designer",
      icon: Designer,
      link: "/admin/designerlist"
    },
    {
      title: "Tag Manager",
      icon: Tag,
      link: "/admin/taglist"
    },
    // {
    //   title: "Customize Jewelry",
    //   icon: Design,
    //   link: "/admin/product"
    // },
    // {
    //   title: "Ready To Ship",
    //   icon: Design,
    //   link: "/admin/readytoship"
    // },
    {
      title: "Mounting",
      icon: Diamondlist,
      link: "/admin/mounting"
    },
  ];
  const diamondMasterList = [
    {
      title: "Clarity",
      icon: Clearify,
      link: "/admin/StockClarityList"
    },
    {
      title: "Color",
      icon: Color,
      link: "/admin/StockColorList"
    },
    {
      title: "Size",
      icon: Size,
      link: "/admin/StockSizeList"
    },
  ];
  return (
    <div className="p-6">
      <div className="mb-6">
        <h2 className="font-[600] font-Josefin md:text-[18px] tex-base text-gray-700 mb-4">
          List of common Master
        </h2>
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard
                title={item.title}
                key={index}
                link={item.link}
                icon={item.icon}
              />
            );
          })}
        </div>
      </div>
      <div className="border-t pt-4">
        <h2 className="font-[600] font-Josefin md:text-[18px] tex-base text-gray-700 mb-4 ">
          List of Diamond Master
        </h2>
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {diamondMasterList.map((item, index) => {
            return (
              <MenuCard
                title={item.title}
                key={index}
                link={item.link}
                icon={item.icon}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MasterMenuList;
