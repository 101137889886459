import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  createStoneSize,
  updateStoneSize,
} from "../../../Services/stoneSizeService";
import AdminModal from "../common/AdminModal";
import { fetchStoneSizeData, getStoneSizeState } from "../../../store/Slices/stoneSizeSlice";
import { getAttributeValueByAttribute } from "../../../Services/attributeValueService";

const SizeModal = ({ isOpen, setIsOpen, editId, setEditId, shapes }) => {
  const [inputValue, setInputValue] = useState({
    stStoneSizeName: "",
    unAttributeValueShapeId: "",
    stFromSieve: "",
    stToSieve: "",
    dcFromMM: "",
    dcToMM: "",
    dcAvgPcs: "",
    dcAvgCts: "",
    flgIsActive: true,
  });
  const { data } = useSelector(getStoneSizeState);
  const [shapeData, setShapeData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const metalData = async () => {
      const resShape = await getAttributeValueByAttribute(2);
      setShapeData(resShape.data.data.attribute_value);
    };
    metalData();
  }, []);

  useEffect(() => {
    const editData = data.length && data?.find((item) => item.unStoneSizeIdentifier === editId);
    if (editData) {
      setInputValue({
        stStoneSizeName: editData.stStoneSizeName,
        stFromSieve: editData.stFromSieve,
        stToSieve: editData.stToSieve,
        dcFromMM: editData.dcFromMM,
        dcToMM: editData.dcToMM,
        dcAvgPcs: editData.dcAvgPcs,
        dcAvgCts: editData.dcAvgCts,
        flgIsActive: editData.flgIsActive === "1" ? true : false,
      });
      // setSelected(shape)
    }
  }, [editId, data, shapeData]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setInputValue({
      stStoneSizeName: "",
      unAttributeValueShapeId: "",
      stFromSieve: "",
      stToSieve: "",
      dcFromMM: "",
      dcToMM: "",
      dcAvgPcs: "",
      dcAvgCts: "",
      flgIsActive: true,
    });
    // setSelected('')
    setEditId("");
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stStoneSizeName: inputValue.stStoneSizeName,
        unAttributeValueShapeId: shapes,
        stFromSieve: inputValue.stFromSieve,
        stToSieve: inputValue.stToSieve,
        dcFromMM: inputValue.dcFromMM,
        dcToMM: inputValue.dcToMM,
        dcAvgPcs: inputValue.dcAvgPcs,
        dcAvgCts: inputValue.dcAvgCts,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unStoneSizeIdentifier: editId,
      };
      const res = await updateStoneSize(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneSizeData());
      }
    } else {
      const payload = new FormData();
      payload.append("stStoneSizeName", inputValue.stStoneSizeName);
      payload.append("unAttributeValueShapeId", shapes);
      payload.append("stFromSieve", inputValue.stFromSieve);
      payload.append("stToSieve", inputValue.stToSieve);
      payload.append("dcFromMM", inputValue.dcFromMM);
      payload.append("dcToMM", inputValue.dcToMM);
      payload.append("dcAvgPcs", inputValue.dcAvgPcs);
      payload.append("dcAvgCts", inputValue.dcAvgCts);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createStoneSize(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneSizeData());
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={editId ? "Update Stone Size" : "Add Stone Size"}
      onSubmit={handleSubmit}
      onClose={() => {
        setIsOpen(false);
        handleClose()
      }}
      isOpen={isOpen}
    >
      <div className="grid max-w-2xl gap-y-2 gap-x-6 sm:grid-cols-6 grid-cols-1">
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="stStoneSizeName"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Stone Size Name
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="stStoneSizeName"
              id="stStoneSizeName"
              value={inputValue.stStoneSizeName}
              onChange={handleChange}
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter Stone Color Name"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="stFromSieve"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            From Sieve
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="stFromSieve"
              id="stFromSieve"
              value={inputValue.stFromSieve}
              onChange={handleChange}
              placeholder="Enter Intencity"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="stToSieve"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            To Sieve
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="stToSieve"
              id="stToSieve"
              value={inputValue.stToSieve}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="dcFromMM"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            From MM
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="dcFromMM"
              id="dcFromMM"
              value={inputValue.dcFromMM}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="dcToMM"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            To MM
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="dcToMM"
              id="dcToMM"
              value={inputValue.dcToMM}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="dcAvgPcs"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Avg.Pcs
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="dcAvgPcs"
              id="dcAvgPcs"
              value={inputValue.dcAvgPcs}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
        <div className="sm:col-span-3 col-span-6">
          <label
            htmlFor="dcAvgCts"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Avg.Cts
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="dcAvgCts"
              id="dcAvgCts"
              value={inputValue.dcAvgCts}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
        <div className="col-span-6">
          <div className="flex items-center gap-[10px]">
            <input
              type="checkbox"
              name="flgIsActive"
              checked={inputValue.flgIsActive}
              onChange={handleChange}
              id="flgIsActive"
              className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
            />
            <label
              htmlFor="flgIsActive"
              className=" block text-sm font-medium leading-6 text-gray-900"
            >
              is Active
            </label>
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default SizeModal;
