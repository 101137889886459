import api from "./api";

export const getCategory = () => {
    return api("/api/Categories", "get")
}

// In store
export const getStockCategory = () => {
    return api("api/StockCategory", "get")
}

// Style we made
export const getDesignCategory = () => {
    return api("api/DesignCategory", "get")
}

export const getCategorywithId = (id) => {
    return api(`/api/Categories/${id}`, "get")
}

export const getCategoryFilterList = () => {
    return api("api/MenuCustomised", "get")
}

export const getReadyToShipFilterList = () => {
    return api("api/MenuReadyToShip", "get")
}

export const AddCategoryData = (data) => {
    return api(`/api/Categories`, "post", data)
}

export const UpdateCategory = (data) => {
    return api(`/api/Categories`, "put", data)
}

export const DeleteCategory = (id) => {
    return api(`/api/Categories/${id}`, "delete")
}