import api from "./api"

const getMetals = () => {
  return api("/api/Metals", "get")
}

const updateMetals = (data) => {
  return api("/api/Metals", "post", data)
}

export {
  getMetals,
  updateMetals,
}