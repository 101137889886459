import React, { useState, useEffect, useMemo } from "react";
import AdminModal from "../../Admin/common/AdminModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../../store/Slices/cartSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: "0px solid #000",
  outline: 'none',
};

export default function BasicModal(props) {

  const dispatch = useDispatch();

  const { item, source, open, handleClose, parentState } = props;

  const [state, setState] = useState({
    clarity: '',
    pcs: '',
    carat: ''
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, clarity: item?.Clarity || '', color: "DEF", pcs: '', carat: '' }));
  }, [item]);

  const { clarity, color, pcs, carat } = state;

  const handleChange = (key,event) => {


    if(key==='pcs')
    {
      let caratAmount = (event.target.value * (item?.carat || item?.Carat || 0)  /  (item?.pcs || item?.Pcs || 0)).toFixed(3)
      setState((prevState) => ({...prevState, [key]: event.target.value, 'carat': caratAmount}));
    }
    else if(key === 'carat')
    {
      let pcsAmount = Math.floor(
        (event.target.value * (item?.pcs || item?.Pcs || 0)) /
          (item?.carat || item?.Carat || 0)
      );
      setState((prevState) => ({
        ...prevState,
        [key]: event.target.value,
        pcs: pcsAmount,
      }));
    }
    else
    {
      setState((prevState) => ({...prevState, [key]: event.target.value}));
    }

  };

  const handleAddToCart = () => {
    const cartData = {
      product: {
        ...item,
        id: parentState?.currentShape?.shapeCode,
        title: parentState?.currentShape?.name,
        price: 0,
        image: parentState?.currentShape?.icon,
        carat: carat,
        pcs: pcs,
        clarity: clarity,
        color,
        source: 'calibrated'
      },
      diamond: [],
      total: 0,
    };

    dispatch(openCart(true));
    dispatch(addToCart(cartData));
    toast.success("Successfully Added to Cart.");
    handleClose();
  };

  const checkValidation = useMemo(() => {

    let isSubmit = true;

    // Name validation
    if(!clarity || !pcs || !carat)
    {
      isSubmit = false;
    }

    return isSubmit

  },[state])

  return (
    <div>
      <AdminModal
        title={"Add To Cart"}
        onSubmit={handleAddToCart}
        disableSubmit={!checkValidation}
        isOpen={open}
        onClose={() => handleClose()}
        submitButtonText={"Add To Cart"}
      >
        <div>
          <div className="flex justify-between text-sm text-gray-800">
            <span>Available Pcs</span>
            <span className="font-[500] text-gray-700">
              {item?.pcs || item?.Pcs || 0}
            </span>
          </div>

          <div className="mt-2 flex justify-between text-sm text-gray-800">
            <span>Carat</span>
            <span className="font-[500] text-gray-700">
              {item?.carat || item?.Carat || 0}
            </span>
          </div>

          <div className="mt-2 flex justify-between text-sm text-gray-800">
            <span>Colour</span>
            <span className="font-[500] text-gray-700">
              {item?.Color || "DEF"}
            </span>
          </div>

          {item?.Clarity && (
            <div className="mt-2 flex justify-between text-sm text-gray-800">
              <span>Clarity</span>
              <span className="font-[500] text-gray-700">{item?.Clarity}</span>
            </div>
          )}

          {!item?.Clarity && (
            <div className="flex mt-3">
              <select
                id="small"
                class="w-full h-[34px] border px-2 text-[14px] text-gray-700 outline-none pl-[0.25rem]"
                onChange={(e) => handleChange("clarity", e)}
                value={clarity}
              >
                <option selected value="">
                  Clarity
                </option>
                <option value="VVS">VVS</option>
                <option value="VS">VS</option>
                <option value="SI">SI</option>
              </select>
            </div>
          )}

          <div className="flex gap-2 mt-2">
            <input
              type="number"
              name="pcs"
              onChange={(e) => handleChange("pcs", e)}
              value={pcs}
              className="w-full py-1.5 border px-2 text-[14px] outline-none"
              placeholder="Pcs"
            />

            <input
              type="number"
              name="carat"
              onChange={(e) => handleChange("carat", e)}
              value={carat}
              className="w-full py-1.5 border px-2 text-[14px] outline-none"
              placeholder="Carat"
            />
          </div>
        </div>
      </AdminModal>
    </div>
  );
}