import { Tab } from "@headlessui/react";
import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductCatalogSkeleton = () => {
  const images = new Array(5)

  return (
    <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-3 mx-auto mt-4s">
      <div className="bg-white shadow-md p-4 rounded flex items-center justify-center">
        <div className="mx-auto max-w-2xl lg:max-w-7xl">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            {/* Image gallery */}
            <Tab.Group as="div" className="sm:flex flex-col-reverse hidden">
              {/* Image selector */}
              <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none">
                <Tab.List className={`grid sliderGrid gap-6`}>
                  {images.slice(0, 5).map((image) => (
                    <Tab
                      key={image}
                      className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                    >
                      <Skeleton height={"100%"} width={"100%"} />
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="aspect-h-1 aspect-w-1 w-full ring-1 ring-[#e2e8f0] rounded">
                  <Tab.Panel>
                    <Skeleton className="sm:h-[470px] h-full w-full sm:w-[580px] object-cover object-center sm:rounded-lg" />
                  </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="sm:hidden block ">
              <div className="details_page max-w-[100%] w-[325px] h-[338px] mx-auto flex items-center shadow-lg">
                <div
                  className="details__image max-h-[330px] overflow-hidden"
                >
                  <Skeleton height={"100%"} width={"100%"} className="object-cover object-center sm:rounded-lg" />
                </div>
              </div>
            </div>

            {/* Product info */}
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                <Skeleton height={"100%"} />
              </h1>
              <p className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] my-3">
                <Skeleton height={"100%"} />
              </p>
              <div className="mb-3">
                <h2 className="sr-only">Product information</h2>
                <p className="text-xl tracking-tight text-gray-900">
                  <Skeleton height={"100%"} />
                </p>
              </div>

              <div className="mb-9">
                <Skeleton height={"100%"} />
                <Skeleton height={"100%"} />
                <Skeleton height={"100%"} />
                <Skeleton height={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogSkeleton;
