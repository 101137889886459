import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMetals } from "../../Services/metalPriceService";

export const fetchMetalData = createAsyncThunk("metal/fetchData", async () => {
  const response = await getMetals();
  return response.data.data;
});

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const metalPriceSlice = createSlice({
  name: "metalPriceData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetalData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMetalData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchMetalData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default metalPriceSlice.reducer;
