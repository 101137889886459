import React, { useEffect, useState, useMemo } from "react";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDesignAndStockByStockCode,
  getDesignAndStockByStockName,
} from "../Services/designService";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import { transformCapitalize } from "../Utils";
import ProductCard from "../Components/LandingComponent/Common/ProductCard/index";
import ProductCardSkeleton from "../Components/LandingComponent/Common/skeleton/ProductCardSkeleton";

const SearchedResult = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    // const response = await getDesignAndStockByStockCode(state?.searchedText);
    const response = await getDesignAndStockByStockName(state?.searchedText);

    if (response.status === 200) {
      if (!response?.data?.data?.length) {
        setResult([]);
      }
      setResult(response?.data?.data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (state.searchedText) {
      getData();
    }
  }, [state.searchedText]);

  const linkTab = useMemo(() => {
    return [
      {
        title: (
          <span className="hover:underline inline-flex justify-start items-center gap-1">
            {" "}
            Home{" "}
          </span>
        ),
        onClick: () => {
          navigate("/jewelry");
        },
      },
      {
        title: (
          <span className="inline-flex justify-start items-center gap-1">
            Searched Result
          </span>
        ),
      },
    ];
  }, [state, navigate]);

  return (
    <>
      <Header />

      <div className="products max-w-[1224px] mx-auto sm:p-2 p-4 pt-0 xl:p-0 xl:mb-[15px] lg:mb-[15px] md:mb-[15px] sm:mb-[15px]">
        <div className="my-4">
          <Breadcrumb tabs={linkTab} />
        </div>

        <h3 className="md:text-[30px] text-[26px] lg:leading-[42px] md:leading-[40px] leading-[30px] font-[600] font-playfair text-[#000] text-center lg:mb-[24px] md:mb-[16px] mb-[14px]">
          '{state?.searchedText || "SEARCHED"}' RESULTS
        </h3>

        <div className="md:text-[30px] text-[26px] lg:leading-[42px] md:leading-[40px] leading-[30px] font-[600] font-playfair text-[#000] text-center lg:mb-[24px] md:mb-[16px] mb-[14px]"></div>

        {isLoading ? (
          <div className=" grid lg:grid-cols-4 md:grid-cols-3	sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        ) : (
          <>
            {result?.length ? (
              <div className="grid xl:grid-cols-8 md:grid-cols-6 grid-cols-4 gap-4">
                {!!result?.length &&
                  result?.map(
                    (
                      {
                        DefImageURL: mainImage,
                        Id: id,
                        DesignStockName: name,
                        FocusImageURL: hoverImage,
                        SaleRate: price,
                        DesignStockCode: StockNo,
                        ...rest
                      },
                      index
                    ) => {
                      return (
                        <ProductCard
                          key={index}
                          id={id}
                          title={name}
                          mainImage={mainImage}
                          hoverImage={hoverImage}
                          price={price}
                          data={rest}
                          StockNo={StockNo}
                          needToRedirectReadyToShip={true}
                        />
                      );
                    }
                  )}
              </div>
            ) : (
              <div className="flex justify-center items-center text-slate-400 text-base italic mt-5">
                No Result Found
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SearchedResult;
