import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import {
  DeleteAttributeValueData,
  getAttributeValueByAttribute,
} from "../../Services/attributeValueService";
import Pagination from "./Pagination";
import AddAttributeValue from "./Modals/AddAttributeValue";
import { Link, useLocation, useParams } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import usePagination from "../../Hooks/use-Pagination";
import useApi from "../../Hooks/use-Api";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const AttributeValueList = () => {
  const { id } = useParams();
  const [isAtOpen, setIsAtOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const location = useLocation();

  const [fetchAttributeData, data, loading, error] = useApi(
    getAttributeValueByAttribute
  );

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: data?.attribute_value || [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    fetchAttributeData(id);
  }, [id, fetchAttributeData]);

  const handleDelete = async (attId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await DeleteAttributeValueData(attId);
      if (res.status === 200) {
        if (res.status === 200) {
          toast.success(res.data.message);
          fetchAttributeData(id);
        }
      }
    }
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsAtOpen(true);
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <AddAttributeValue
        isAtOpen={isAtOpen}
        setIsAtOpen={setIsAtOpen}
        editId={editId}
        setEditId={setEditId}
        attributeId={id}
        attributeName={location?.state?.name}
        fetchData={fetchAttributeData.bind(null, id)}
        selectDiamond={location?.state?.selectDiamond}
        setSelectDiamond={()=>{}}
      />
      <main className="h-full overflow-y-auto">
        <div className="grid px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-3 pb-3">
            <span class="sm:text-2xl text-base text-black font-[600] font-Josefin">{location?.state?.name}</span>
          </div>
          <div className="flex items-center justify-between gap-[10px]">
            <Link
              to={"/admin/attributelist"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsAtOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc]"
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
          </div>

          <div className="shadow-md sm:rounded-lg overflow-hidden relative mt-4 rounded">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 w-[20px]">
                      Sr.
                    </th>
                    <th scope="col" className="px-4 py-3 text-center w-[50px]">
                      Image
                    </th>
                    <th scope="col" className="px-4 py-3 w-[150px]">
                      Short
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Name
                    </th>
                    {location?.state?.name === "Metal" && (
                      <th scope="col" className="px-4 py-3  w-[80px]">
                        Touch
                      </th>
                    )}
                    <th scope="col" className="px-4 py-3  w-[80px]">
                      Color
                    </th>
                    <th scope="col" className="px-4 py-3  w-[100px]">
                      HTML Tag
                    </th>
                    <th scope="col" className="px-4 py-3 w-[80px]">
                      Y | N
                    </th>
                    <th scope="col" className="px-4 py-3 w-[80px]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData?.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-4 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {i + 1}
                            </th>
                            <td className="px-4 py-1 flex justify-center items-center">
                              <img
                                src={item.stImageURL}
                                alt={`${item.stAttributeValueName}`}
                                className=" h-[40px] rounded"
                              />
                            </td>
                            <td className="px-4 py-1">
                              {item.stAttributeValueAlias}
                            </td>
                            <td className="px-4 py-1">
                              {item.stAttributeValueName}
                            </td>
                            {location?.state?.name === "Metal" && (
                              <td className="px-4 py-1">{item.dcTouch}</td>
                            )}
                            <td className="px-4 py-1">{item.stColorCode}</td>
                            <td className="px-4 py-1">{item.stHTMLTag}</td>
                            <td className="px-4 py-1">
                              {item.flgIsActive === "1" ||
                              item.flgIsActive === 1 ? (
                                <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  Y
                                </span>
                              ) : (
                                <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  N
                                </span>
                              )}
                            </td>
                            <td className="px-4 py-1">
                              <div className="flex items-center gap-[10px]">
                                <button type="button">
                                  <BiEdit
                                    onClick={() =>
                                      handleEdit(
                                        item.unAttributeValueIdentifier
                                      )
                                    }
                                    className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded"
                                  />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDelete(
                                      item.unAttributeValueIdentifier
                                    );
                                  }}
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={8} className="text-[14px] text-center">
                            <NoDataText text={"No data found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr className="bg-white ">
                      <td colSpan={9} className="text-[14px] text-center h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={data ? data?.attribute_value?.length : 0}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AttributeValueList;
