import React from "react";
import StepThree from "./addProductsSteps/StepThree";
import { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { getSidebarStatus } from "../../store/Slices/sidebarStatusSlice";
import { useSelector } from "react-redux";
import StepOne from "./addProductsSteps/StepOne";
import { useNavigate } from "react-router-dom";

const AddProductDetails = () => {
  const [stepCount, setStepCount] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);
  const { isOpen } = useSelector(getSidebarStatus);
  const [basicInfo, setBasicInfo] = useState(false);

  const navigate = useNavigate();

  const prevStepHandler = () => {
    setStepCount((prev) => (prev === 1 ? prev : prev - 1));
  };
  const nextStepHandler = () => {
    setBasicInfo(true);
  };

  const nextStepHandlerAdd = () => {
    setBasicInfo(true);
    setIsSubmit(true);
  };

  const renderSteps =
    stepCount === 1 ? (
      <StepOne
        basicInfo={basicInfo}
        setStepCount={setStepCount}
        setBasicInfo={setBasicInfo}
      />
    ) : (
      <StepThree
        isSubmit={basicInfo}
        setBasicInfo={setBasicInfo}
        basicInfo={isSubmit}
        setStepCount={setStepCount}
      />
    );

  return (
    <div className={`w-full`}>
      <div
        className={`p-8 mx-auto min-h-[80vh] ${
          isOpen ? "lg:w-[calc(100vw-310px)]" : "lg:w-[calc(100vw-100px)]"
        } `}
      >
        {renderSteps}
      </div>
      <div className="flex justify-between shadow items-center bg-white p-[20px] sticky bottom-0 ">
        <button
          onClick={prevStepHandler}
          disabled={stepCount === 1}
          className="shadow disabled:text-slate-400 bg-slate-100 py-1 rounded-[15px] px-4 text-base text-slate-800 flex items-center "
        >
          {" "}
          <BsArrowLeft className=" mr-2 " /> Previous
        </button>
        <span>{stepCount}/2</span>
        {stepCount === 2 ? (
          <div className="flex items-center justify-end gap-[10px]">
            {/* <button
              onClick={() => {
                nextStepHandlerAdd();
              }}
              className="shadow disabled:text-slate-400 bg-slate-100 py-1 rounded-[15px] px-4 text-base text-slate-800 flex items-center "
            >
              {" "}
              Save And Another Design Add
            </button> */}
            <button
              onClick={() => {
                nextStepHandler();
              }}
              className="shadow disabled:text-slate-400 bg-slate-100 py-1 rounded-[15px] px-4 text-base text-slate-800 flex items-center "
            >
              {" "}
              Finish
            </button>
          </div>
        ) : (
          <div className="flex">
            <button
              onClick={() => navigate('/admin/product')}
              className="shadow disabled:text-slate-400 bg-slate-100 py-1 rounded-[15px] px-4 text-base text-slate-800 flex items-center mr-2"
            >
              {" "}
             Cancel
            </button>

            <button
              onClick={() => {
                nextStepHandler();
              }}
              className="shadow disabled:text-slate-400 bg-slate-100 py-1 rounded-[15px] px-4 text-base text-slate-800 flex items-center "
            >
              {" "}
              <BsArrowRight className=" mr-2 " /> Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProductDetails;
