import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowUpShort } from "react-icons/bs";
import { tawkWidgetToggle } from "../../../../Utils";
import { useDispatch, useSelector } from "react-redux";

const products = [
  {
    id: 1,
    name: "Machined Pen",
    color: "Black",
    price: "$35",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-02-product-01.jpg",
    imageAlt:
      "Black machined steel pen with hexagonal grip and small white logo at top.",
    availableColors: [
      { name: "Black", colorBg: "#111827" },
      { name: "Brass", colorBg: "#FDE68A" },
      { name: "Chrome", colorBg: "#E5E7EB" },
    ],
  },
];

const StyleProductCard = ({
  title,
  mainImage,
  hoverImage,
  price,
  id,
  needToRedirectReadyToShip,
  alias,
  StockNo,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tawkTo = require("tawkto-react");
  const tawkToPropertyId = "64644842ad80445890ed5f0b";
  const tawkToKey = "1h0jrkhog";

  const { user: userData } = useSelector((state) => state.authData);

  const handleClick = () => {
    navigate(
      needToRedirectReadyToShip
        ? `/readytoshipdetail/${id}`
        : `/styleproductdetail/${id}`,
      {
        state: {
          prevPath: needToRedirectReadyToShip
            ? "/readytoship"
            : location.pathname,
          category: location.state?.category,
        },
      }
    );
  };

  // const handleClick = () => {
  //   navigate(`/readytoshipdetail/${id}`, {
  //     state: {
  //       prevPath: location.pathname,
  //       category: location.state?.category,
  //     },
  //   });
  // };

  return (
    <div
      onClick={handleClick}
      className={`group relative w-full overflow-hidden m-auto h-[240px] sm:h-[350px] md:h-[350px] xl:h-[350px] max-w-[296px] sm:max-w-auto boxShadow col-span-2 cursor-pointer`}
    >
      <div className="sm:h-[280px] h-[145px]">
        <img
          src={mainImage}
          alt="mainImage"
          className="absolute top-0 left-0 m-auto w-full sm:h-[280px] h-[190px] object-contain object-center group-hover:opacity-0 group-hover:invisible duration-500 "
        />
        <img
          src={hoverImage || mainImage}
          alt="hoverimage"
          className="m-auto absolute top-0 left-0 w-full sm:h-[280px] h-[190px] opacity-0 object-contain object-center invisible group-hover:opacity-100 group-hover:visible duration-500"
        />
      </div>
      {!!Number(rest?.data?.flgIsHotDesign) && (
        <span className="absolute z-10 top-2 right-2 px-1.5 py-0.5 font-[500] text-xs text-slate-700 italic font-Poppins">
          Best Seller
        </span>
      )}
      {/* <div className="mt-4 text-center">
        <h3 className="font-semibold sm:text-[16px] text-[14px] text-gray-900 truncate sm:px-0 px-2">
          <span
            className="absolute inset-0 cursor-pointer"
            onClick={handleClick}
          />
          {title}
        </h3>
        {(alias || StockNo) && <p className="text-gray-900 sm:text-[16px] text-[12px]">{alias || StockNo}</p>}
        <p className="mt-0 text-gray-900 sm:text-[16px] text-[14px]">$ {Number(price)}</p>
      </div> */}

      <div class="mt-[50px] sm:mt-3 xl:mt-3 flex flex-col justify-between items-center p-2 pt-0">
        <div className="w-full">
          <h3 class="text-xs sm:text-sm font-semibold text-gray-700 truncate text-ellipsis">
            {title}
          </h3>
        </div>
        <div
          className={`flex ${
            userData?.Role === "User" || !userData?.Role
              ? "justify-between"
              : "justify-start"
          } items-center w-full mt-1 sm:mt-1.5 lg:mt-1`}
        >
          {/* <p class="text-[10px] sm:text-xs lg:text-[13px] xl:text-sm font-bold text-[#2F9E44] text-left w-[40%]">
            ${Number(price)}
          </p> */}
          {(userData?.Role === "User" || !userData?.Role) && (
            <p
              class="text-[10px] sm:text-xs lg:text-[13px] xl:text-sm text-left w-[40%] font-[400] underline text-gray-700"
              onClick={(e) => {
                e.stopPropagation();
                new tawkTo(tawkToPropertyId, tawkToKey);
                tawkWidgetToggle(true);
              }}
            >
              On request
            </p>
          )}
          <p
            class={`text-[10px] sm:text-xs lg:text-[13px] xl:text-sm font-medium text-[#999999] ${
              (userData?.Role === "User" || !userData?.Role)
                ? "text-right"
                : "text-left"
            }  w-[40%]`}
          >
            {alias || StockNo}
          </p>
        </div>
      </div>

      {/* <ul className="flex items-center gap-3 justify-center pt-[12px]">
        <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 rounded-full bg-[#C8C8C8]"></span>
        <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 rounded-full bg-[#C8AB6E]"></span>
        <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 rounded-full bg-[#E0E0E0]"></span>
        <span className="block sm:w-5 w-3.5 sm:h-5 h-3.5 rounded-full bg-[#CEAC8B]"></span>
      </ul> */}
    </div>
    // <div>
    //   {products.map((product) => {
    //     return (
    //       <li
    //         key={product.id}
    //         className="inline-flex w-64 flex-col text-center lg:w-auto"
    //       >
    //         <div className="group relative">
    //           <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200">
    //             <img
    //               src={mainImage}
    //               alt={product.imageAlt}
    //               className="h-full w-full object-cover object-center group-hover:opacity-0 group-hover:invisible"
    //             />
    //             <img
    //               src={mainImage}
    //               alt={product.imageAlt}
    //               className="h-full w-full object-cover object-center invisible opacity-0 group-hover:opacity-100 group-hover:visible"
    //             />
    //           </div>
    //           <div className="mt-6">
    //             <h3 className="mt-1 font-semibold text-gray-900">
    //               <a href={product.href}>
    //                 <span className="absolute inset-0" />
    //                 {title}
    //               </a>
    //             </h3>
    //             <p className="mt-1 text-gray-900">${Number(price)}</p>
    //           </div>
    //         </div>

    //         <h4 className="sr-only">Available colors</h4>
    //         <ul
    //           role="list"
    //           className="mt-auto flex items-center justify-center space-x-3 pt-6"
    //         >
    //           {product.availableColors.map((color) => (
    //             <li
    //               key={color.name}
    //               className="h-4 w-4 rounded-full border border-black border-opacity-10"
    //               style={{ backgroundColor: color.colorBg }}
    //             >
    //               <span className="sr-only">{color.name}</span>
    //             </li>
    //           ))}
    //         </ul>
    //       </li>
    //     );
    //   })}
    // </div>
  );
};

export default StyleProductCard;
