import React from "react";
import MainHeader from "../Components/LandingComponent/MainHeaderNew";
import HomeSlider from "../Components/LandingComponent/HomeSliderNew";
import Aboutus from "../Components/LandingComponent/AboutusNew";
import MainFooter from "../Components/LandingComponent/MainFooterNew";

const Home = () => {
  return (
    <>
      <MainHeader />
      <HomeSlider />
      <Aboutus />
      <MainFooter />
    </>
  );
};

export default Home;