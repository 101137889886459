import React from "react";
import MenuCard from "./../common/MenuCard";
import SnapOwn from '../../../assets/images/Snap own post.png'
import Report from '../../../assets/images/Report.png'
import OrderReport from '../../../assets/images/order repoet.png'

const commonMasterList = [
  {
    title: "Snap Own Post",
    icon: SnapOwn,
    link: ""
  },
  {
    title: "Report",
    icon: Report,
    link: ''
  },
  {
    title: "Order Report",
    icon: OrderReport,
    link: ""
  },
];

const ReportMenuList = () => {
  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard title={item.title} key={index} link={item.link} icon={item.icon} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReportMenuList;
