import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategory, getStockCategory, getDesignCategory } from "../../Services/categoryService";
import { getCategoryStylebyCategoryId, getCategoryStylesOfDesignById } from "../../Services/categoryStyleService";

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const response = await getCategory();
    return response.data.data.categories;
  }
);

export const fetchStockCategory = createAsyncThunk(
  "category/fetchStockCategory",
  async () => {
    const response = await getStockCategory();
    return response.data.data;
  }
);

export const fetchDesignCategory = createAsyncThunk(
  "category/fetchDesignCategory",
  async () => {
    const response = await getDesignCategory();
    return response.data.data;
  }
);

export const fetchCategoryStyleByCategoryId = createAsyncThunk(
  "categoryStyle/fetchCategoryStyleByCategoryId",
  async (id) => { 
    const response = await getCategoryStylebyCategoryId(id);
    return response.data.data.categories_style;
    // return response.data.data.categories_style;
  }
);

const initialState = {
  data: [],
  categoryStyles: [],
  categoryStylesDesign: [],
  loading: false,
  error: null,
  selectedCategory: [],
  stockCategoryLoading: false,
  stockCategories: [],
  stockCategoryError: null,
  stockDesignCateoryLoading: false,
  stockDesignCategories: [],
  stockDesignCategoryError: null
};

const designSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.selectedCategory = [action.payload];
    },
    clearSelectedCategory(state, action) {
      state.selectedCategory = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchStockCategory.pending, (state, action) => {
      state.stockCategoryLoading = true;
    });
    builder.addCase(fetchStockCategory.fulfilled, (state, action) => {
      state.stockCategoryLoading = false;
      state.stockCategories = action.payload;
      state.stockCategoryError = null;
    });
    builder.addCase(fetchStockCategory.rejected, (state, action) => {
      state.stockCategoryLoading = false;
      state.stockCategoryError = action.error.message;
    });
    builder.addCase(fetchDesignCategory.pending, (state, action) => {
      state.stockDesignCateoryLoading = true;
    });
    builder.addCase(fetchDesignCategory.fulfilled, (state, action) => {
      state.stockDesignCateoryLoading = false;
      state.stockDesignCategories = action.payload;
      state.stockDesignCategoryError = null;
    });
    builder.addCase(fetchDesignCategory.rejected, (state, action) => {
      state.stockDesignCateoryLoading = false;
      state.stockDesignCategoryError = action.error.message;
    });
    builder.addCase(
      fetchCategoryStyleByCategoryId.fulfilled,
      (state, action) => {
        state.categoryStyles = action.payload;
      }
    );
  },
});

export const { setSelectedCategory, clearSelectedCategory } = designSlice.actions

export const getCategoryDataState = (state) => state.categoryData;
export const getCategoryStyleDesignDataState = (state) => state.categoryData;

export default designSlice.reducer;
