import React from "react";
import { getDesignDataState } from "../../../store/Slices/designSlice";
import { useSelector } from "react-redux";
import ProductCard from "../Common/ReadyToShipProductCard";
import ProductCardSkeleton from "../Common/skeleton/ProductCardSkeleton";
import { useMemo } from "react";
import { getReadyToShipDataState } from "../../../store/Slices/readyToShipSlice";

const ReadyToShipList = () => {
  const { data, loading, error } = useSelector(getReadyToShipDataState);
  const { searchedText } = useSelector((state) => state.readyToShipData);

  const filterData = useMemo(() => {
    return data?.length > 0 ? data.filter((item, i) => item.IsSale === 1) : [];
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2 mt-4">
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center text-slate-400 text-base italic ">
          No Data
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="product_list font-Josefin grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2 mt-4 items-start">
              {filterData.map(
                (
                  {
                    DefImageURL: mainImage,
                    StockID: id,
                    StockName: name,
                    FocusImageURL: hoverImage,
                    SalePrice: price,
                    StockNo,
                    ...rest
                  },
                  index
                ) => {

                  if (
                    searchedText &&
                    !(StockNo?.toLowerCase()?.includes(searchedText?.toLowerCase()))
                  )
                    return;

                  return (
                    <ProductCard
                      key={index}
                      id={id}
                      title={name}
                      mainImage={mainImage}
                      hoverImage={hoverImage}
                      price={price}
                      StockNo={StockNo}
                      data={rest}
                    />
                  );
                }
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center text-slate-400 text-base italic ">
              No Data
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReadyToShipList;
