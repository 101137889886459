import React from "react";
import AdminModal from './common/AdminModal';

export default function AddUserRole({ isOpen, setIsOpen }) {
  return (
    <AdminModal
      title={"Add User Role"}
      onSubmit={() => { setIsOpen(false) }}
      onClose={() => { setIsOpen(false) }}
      isOpen={isOpen}
    >
      <div className="mb-4">
        <label
          htmlFor="userRole"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Role
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="userRole"
            id="userRole"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="User Role"
          />
        </div>
      </div>
    </AdminModal>
  );
}
