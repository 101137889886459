import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Drawer } from "@mui/material";
import {
  defaultCaratRange,
  defaultPriceRange,
  numberRegexWithDecimals,
} from "../shared/constants";
import { GrFormClose } from "react-icons/gr";
import { sortByID } from "../../Utils";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";

const DiamondFilterDrawer = ({
  isOpen,
  setIsOpen,
  setFilterValue,
  filterParameters,
  setPageNumber,
}) => {
  const [value, setValue] = useState(defaultPriceRange);
  const [caratValue, setCaratValue] = useState(defaultCaratRange);
  const [formData, setFormData] = useState({
    cut: [],
    lab: {},
    type: {},
    polish: [],
    symmetry: [],
    shape: [],
    color: [],
    clarity: [],
    certi: "",
    stockid: "",
  });

  useEffect(() => {
    if (filterParameters) {
      setValue([
        +filterParameters.amount[0].ID,
        +filterParameters.amount[0].Name,
      ]);
      setCaratValue([
        +filterParameters.carat[0].ID,
        +filterParameters.carat[0].Name,
      ]);
    }
  }, [filterParameters]);

  const handleChangeForm = (val, e) => {
    const { name } = e.target;
    const exception = ["certi", "stockid"];
    if (exception.includes(name)) {
      setFormData((prev) => ({ ...prev, [name]: val }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: prev[name]?.ID === val.ID ? {} : val,
      }));
    }
  };

  const handleChangeMultiData = (val, e) => {
    const { name } = e.target.closest("button");
    setFormData((prev) => ({
      ...prev,
      [name]: prev[name].includes(val)
        ? prev[name].filter((item) => item !== val)
        : prev[name].concat(val),
    }));
  };

  const handleClick = async () => {
    // setStockData([]);
    const data = {
      fromPrice:
        value[0] === +filterParameters.amount[0].ID &&
        value[1] === +filterParameters.amount[0].Name
          ? ""
          : value[0],
      toPrice:
        value[0] === +filterParameters.amount[0].ID &&
        value[1] === +filterParameters.amount[0].Name
          ? ""
          : value[1],
      fromCarat:
        caratValue[1] === +filterParameters.carat[0].Name &&
        caratValue[0] === +filterParameters.carat[0].ID
          ? ""
          : caratValue[0],
      toCarat:
        caratValue[1] === +filterParameters.carat[0].Name &&
        caratValue[0] === +filterParameters.carat[0].ID
          ? ""
          : caratValue[1],
      cut: formData.cut,
      lab: formData.lab.Name,
      certi: formData.certi,
      type: formData.type.Name,
      polish: formData.polish,
      symmetry: formData.symmetry,
      StockID: formData.stockid,
      shape: formData.shape,
      color: formData.color,
      clarity: formData.clarity,
    };
    setPageNumber(1);
    setFilterValue((prev) => ({ ...prev, ...data }));
    setIsOpen(false);
  };

  const handleClickClear = async () => {
    setValue([
      +filterParameters.amount[0].ID,
      +filterParameters.amount[0].Name,
    ]);
    setCaratValue([
      +filterParameters.carat[0].ID,
      +filterParameters.carat[0].Name,
    ]);
    setFormData({
      cut: [],
      lab: "",
      certi: "",
      type: "",
      polish: [],
      symmetry: [],
      stockid: "",
      shape: [],
      color: [],
      clarity: [],
    });
    setPageNumber(1);
    setFilterValue(null);
    setIsOpen(false);
  };

  const labData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.lab, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const typeData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.type, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const cutData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.cut, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const polishData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.polish, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const symData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.sym, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const shapeData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.shape, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const colorData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.color, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const clarityData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.clarity, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  return (
    <Drawer
      anchor={"right"}
      className="sideDrawer"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="sm:min-w-[400px] sm:max-w-[600px] w-full flex flex-col justify-between h-full ">
        <div className="w-full">
          <div className=" bg-white px-4 py-2 flex justify-between items-center border-b">
            <header className=" font-bold text-lg ">Filter</header>
            <GrFormClose
              onClick={() => setIsOpen(false)}
              className="text-[26px] cursor-pointer"
            />
          </div>
        </div>
        <div className="px-4 py-2 overflow-y-auto max-h-full h-full">
          <div className="mb-4 flex items-start gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Shape:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {shapeData?.map((shape, index) => {
                const image = shapeImages(shape.Name);
                return (
                  <button
                    key={index}
                    type="button"
                    name="shape"
                    onClick={handleChangeMultiData.bind(null, shape.ID)}
                    className={`
                      w-[70px] border-2 group rounded duration-200 sm:hover:border-gray-500
                      ${
                        formData.shape.includes(shape.ID)
                          ? "border-gray-500"
                          : "border-gray-300"
                      }
                    `}
                  >
                    <div>
                      {/* <div className="py-2 px-2 ">
                          <img
                            src={image}
                            alt={`shape-${index + 1}`}
                            className="m-auto h-[25px]"
                          />
                        </div> */}
                      <div
                        className={`text-[11px] duration-200`}
                        // className={`text-[11px] group-hover:bg-[#64748b] group-hover:text-white duration-200 ${formData.shape.includes(shape.ID)
                        //   ? "bg-[#64748b] text-white"
                        //   : "bg-white text-[#64748b]"
                        //   }`}
                      >
                        {shape.Name}
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-3 flex gap-3 items-center ">
            <h1 className="uppercase text-xs font-[600] w-1/4">Carat:</h1>
            <div className="max-w-[300px]  w-3/4">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <label className="text-sm" htmlFor="caratFrom">
                    From :{" "}
                  </label>
                  <input
                    type="text"
                    className="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                    id="caratFrom"
                    value={caratValue[0]}
                    name="caratFrom"
                    onChange={(e) => {
                      const { value: val } = e.target;
                      if (
                        numberRegexWithDecimals.test(val) &&
                        val < caratValue[1]
                      ) {
                        setCaratValue([val, caratValue[1]]);
                      }
                    }}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <label className="text-sm" htmlFor="toFrom">
                    To :{" "}
                  </label>
                  <input
                    type="text"
                    className="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                    id="toFrom"
                    name="toFrom"
                    value={caratValue[1]}
                    onChange={(e) => {
                      const { value: val } = e.target;
                      if (numberRegexWithDecimals.test(val)) {
                        setCaratValue([caratValue[0], val]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4 flex items-start gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Color:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {colorData.map((color, index) => {
                return (
                  <button
                    key={index}
                    name="color"
                    onClick={handleChangeMultiData.bind(null, color.ID)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.color.includes(color.ID)
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {color.Name}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="mb-4 flex items-start gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Clarity:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {clarityData.map((clarity, index) => {
                return (
                  <button
                    key={index}
                    name="clarity"
                    onClick={handleChangeMultiData.bind(null, clarity.ID)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.clarity.includes(clarity.ID)
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {clarity.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-4 flex items-start gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Cut:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {cutData.map((cut, index) => {
                return (
                  <button
                    key={index}
                    name="cut"
                    onClick={handleChangeMultiData.bind(null, cut.ID)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.cut.includes(cut.ID)
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {cut.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">polish:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {polishData.map((polish, index) => {
                return (
                  <button
                    key={index}
                    name="polish"
                    onClick={handleChangeMultiData.bind(null, polish.ID)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.polish.includes(polish.ID)
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {polish.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Symmetry:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {symData.map((sym, index) => {
                return (
                  <button
                    key={index}
                    name="symmetry"
                    onClick={handleChangeMultiData.bind(null, sym.ID)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.symmetry.includes(sym.ID)
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {sym.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4 ">Lab:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {labData.map((lab, index) => {
                return (
                  <button
                    key={index}
                    name="lab"
                    onClick={handleChangeForm.bind(null, lab)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.lab.Name === lab.Name
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {lab.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-3 flex gap-3 items-center ">
            <h1 className="uppercase text-xs font-[600] w-1/4">Price Range:</h1>
            <div className="max-w-[300px]  w-3/4">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <label className="text-sm" htmlFor="caratFrom">
                    From :{" "}
                  </label>
                  <input
                    type="text"
                    className="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                    id="caratFrom"
                    value={value[0]}
                    name="caratFrom"
                    onChange={(e) => {
                      const { value: val } = e.target;
                      if (numberRegexWithDecimals.test(val) && val < value[1]) {
                        setValue([val, value[1]]);
                      }
                    }}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <label className="text-sm" htmlFor="toFrom">
                    To :{" "}
                  </label>
                  <input
                    type="text"
                    className="outline-none px-2 text-xs py-0.5 w-16 border rounded"
                    id="toFrom"
                    name="toFrom"
                    value={value[1]}
                    onChange={(e) => {
                      const { value: val } = e.target;
                      if (numberRegexWithDecimals.test(val)) {
                        setValue([value[0], val]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Type:</h1>
            <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
              {typeData.map((type, index) => {
                return (
                  <button
                    key={index}
                    name="type"
                    onClick={handleChangeForm.bind(null, type)}
                    className={`
                         font-openSans text-xs border-2 outline-none px-2 py-0.5 rounded sm:hover:border-gray-500
                        ${
                          formData.type.Name === type.Name
                            ? "border-gray-500"
                            : "border-gray-300"
                        }
                      `}
                  >
                    {type.Name}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Stock ID:</h1>
            <input
              type="text"
              className=" px-2 py-1 max-w-[200px] text-xs outline-none border border-gray-400 rounded w-3/4"
              onChange={(e) => handleChangeForm(e.target.value, e)}
              value={formData.stockid}
              name="stockid"
            />
          </div>

          <div className="mb-4 flex items-center gap-3">
            <h1 className="uppercase text-xs font-[600] w-1/4">Certi id:</h1>
            <input
              type="text"
              className=" px-2 py-1 max-w-[200px] text-xs outline-none border border-gray-400 rounded w-3/4"
              onChange={(e) => handleChangeForm(e.target.value, e)}
              value={formData.certi}
              name="certi"
            />
          </div>
        </div>
        <div className="flex justify-end items-center border-t p-2 gap-3">
          <button
            onClick={handleClick}
            className="w-full text-sm max-w-[100px] py-2 text-white text-center bg-[#475569] rounded"
          >
            Apply
          </button>
          <button
            onClick={handleClickClear}
            className="w-full text-sm py-2 max-w-[100px] text-center border bg-[#FFFFFF] rounded"
          >
            Clear filter
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default DiamondFilterDrawer;
