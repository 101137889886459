import api from "./api";

export const getSnap = (params) => {
    return api("/api/Snap", "get", null, params)
}

export const getSnapwithId = (id) => {
    return api(`/api/Snap/${id}`, "get")
}

export const AddSnapData = (data) => {
    return api(`/api/Snap`, "post", data)
}

export const UpdateSnap = (data) => {
    return api(`/api/Snap`, "put", data)
}

export const DeleteSnap = (id) => {
    return api(`/api/Snap/${id}`, "delete")
}