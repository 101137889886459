import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Fragment } from "react";
import { BsPlusLg } from "react-icons/bs";

const Card = ({
  children,
  title,
  isHidden,
  className,
  add,
  onclick,
  centerStone,
  metalRows,
}) => {
  return (
    <div
      className={`shadow-sm max-w-full overflow-x-auto rounded-[4px] sm:px-[25px] md:px-[24px] px-[15px] pb-[20px] font-Poppins bg-[#FFFFFF] ${className}`}
    >
      <div className="mb-[1rem]">
        <div className="flex items-center justify-between">
          <h3 className="text-[22px] leading-[20px] font-[600] pt-[1rem] pb-[0.5rem] ">
            {title}
          </h3>
          {metalRows?.length > 0 ? (
            ""
          ) : (
            <>
              {isHidden === false && (
                <>
                  {add === "side" ? (
                    <button
                      type="button"
                      className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
                      onClick={onclick}
                    >
                      <BsPlusLg className="text-[10px]" /> Add
                    </button>
                  ) : (
                    <>
                      {add && (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center border border-[cbd5e1] rounded-md bg-white px-4 py-2 text-sm font-medium text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                              Select Action
                              <ChevronDownIcon
                                className="ml-2 -mr-1 h-5 w-5 text-black hover:text-violet-100"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={onclick.bind(
                                        null,
                                        "browseStock"
                                      )}
                                      className={`${
                                        active
                                          ? "bg-[#475569] text-white"
                                          : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Browser Stock
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={onclick.bind(null, "demand")}
                                      className={`${
                                        active
                                          ? "bg-[#475569] text-white"
                                          : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Give Demand
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="h-[1px] w-full bg-[#D1D5DB] "></div>
      </div>
      {children}
    </div>
  );
};

export default Card;
