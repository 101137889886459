import React, { useEffect, useMemo, useState } from "react";
import AdminModal from "../common/AdminModal";
import TextInputAdmin from "../common/TextInputAdmin";
import AutoCompleteWithoutTags from "../common/AutoCompleteWithoutTag";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeValueByAttribute } from "../../../Services/attributeValueService";
import {
  createSideStone,
  updateSideStone,
} from "../../../Services/sideStoneService";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  fetchSideStone,
  getSideStoneDataState,
} from "../../../store/Slices/sideStoneSlice";
import {
  fetchSideStonePriceList,
  getSideStonePriceState,
} from "../../../store/Slices/sideStonePriceSlice";

const AddSideStoneDetails = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [shapeData, setShapeData] = useState();
  const [shapeNData, setShapeNData] = useState();
  const [sizeNData, setSizeNData] = useState();
  const [colorNData, setColorNData] = useState();
  const [shape, setShape] = useState();
  const [size, setSize] = useState();
  const [color, setColor] = useState();
  const [inputValue, setInputValue] = useState({
    inPcs: "",
    dcPcsPerWt: "",
    dcWt: "",
    dcRate: "",
    dcAmount: "",
    dcSettingCostPerPcs: "",
    dcSettingCostAmount: "",
    flgRateOn: false,
    flgIsSelect: true,
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSelector(getSideStoneDataState);
  const { data: sideStone } = useSelector(getSideStonePriceState);

  useEffect(() => {
    dispatch(fetchSideStonePriceList());
  }, [dispatch]);

  const SizeOption = useMemo(() => {
    if (shape && sideStone?.length) {
      return sideStone.filter(
        (item) => item.unAttributeValueShapeId === shape.name
      );
    }
  }, [shape, sideStone]);

  useEffect(() => {
    if (shape && sideStone?.length && size && color) {
      const data = sideStone.find(
        (item) =>
          item.unAttributeValueShapeId === shape.name &&
          item.stSizeName === size.name &&
          item.stColourClarity === color.name
      );
      if (data) {
        setInputValue((prev) => ({ ...prev, dcRate: data.dcPrice }));
      }
    }
  }, [shape, size, color, sideStone]);

  const WtTotal = useMemo(() => {
    if (inputValue.dcPcsPerWt && inputValue.inPcs) {
      const total = +inputValue.inPcs * +inputValue.dcPcsPerWt;
      return total;
    } else {
      return 0;
    }
  }, [inputValue]);

  const AmountTotal = useMemo(() => {
    if (WtTotal && inputValue.dcRate) {
      const total = +WtTotal * +inputValue.dcRate;
      return total;
    } else {
      return 0;
    }
  }, [inputValue, WtTotal]);

  const coseTotal = useMemo(() => {
    if (inputValue.inPcs && inputValue.dcSettingCostPerPcs) {
      const total = +inputValue.inPcs * +inputValue.dcSettingCostPerPcs;
      return total;
    } else {
      return 0;
    }
  }, [inputValue]);

  useEffect(() => {
    const metalData = async () => {
      const resShape = await getAttributeValueByAttribute(2);
      setShapeData(resShape.data.data.attribute_value);
    };
    metalData();
  }, []);

  useEffect(() => {
    let arr = [];
    let atval = [];
    let colval = [];
    if (shapeData?.length) {
      shapeData.forEach((item) => {
        const data = {
          id: item.unAttributeValueIdentifier,
          name: item.stAttributeValueName,
          atId: item.unAttributeId,
        };
        arr.push(data);
      });
    }
    if (SizeOption?.length) {
      SizeOption.forEach((item) => {
        const data = {
          id: item.inSrNumber,
          name: item.stSizeName,
        };
        atval.push(data);
      });
    }
    if (SizeOption?.length) {
      SizeOption.forEach((item) => {
        const data = {
          id: item.inSrNumber,
          name: item.stColourClarity,
        };
        colval.push(data);
      });
    }
    if (colval.length) {
      const uniqueColor = [...new Map(colval.map((m) => [m.name, m])).values()];
      setColorNData(uniqueColor);
    }
    if (atval.length) {
      const unique = [...new Map(atval.map((m) => [m.name, m])).values()];
      setSizeNData(unique);
    }
    setShapeNData(arr);
  }, [shapeData, SizeOption]);

  useEffect(() => {
    const editData = data.find(
      (item) => item.unDesignStoneDetailsIdentifier === editId
    );
    if (editData) {
      setInputValue({
        inPcs: editData.inPcs,
        dcPcsPerWt: editData.dcPcsPerWt,
        dcWt: editData.dcWt,
        dcRate: editData.dcRate,
        dcAmount: editData.dcAmount,
        dcSettingCostPerPcs: editData.dcSettingCostPerPcs,
        dcSettingCostAmount: editData.dcSettingCostAmount,
        flgRateOn: editData.flgRateOn === "1" ? true : false,
        flgIsSelect: editData.flgIsSelect === "1" ? true : false,
      });
      setShape({ name: editData.stShapeName });
      setColor({ name: editData.stColorClarity });
      setSize({ name: editData.stSizeName });
    }
  }, [editId, data]);

  const handleClose = () => {
    setInputValue({
      inPcs: "",
      dcPcsPerWt: "",
      dcWt: "",
      dcRate: "",
      dcAmount: "",
      dcSettingCostPerPcs: "",
      dcSettingCostAmount: "",
      flgRateOn: false,
      flgIsSelect: true,
    });
    setShape();
    setSize();
    setColor();
    setEditId("");
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const checkname = ["flgRateOn", "flgIsSelect"];
    setInputValue((prev) => ({
      ...prev,
      [name]: checkname.includes(name) ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        unDesignStoneDetailsIdentifier: editId,
        unDesignId: id,
        stShapeName: shape.name,
        stSideName: size.name,
        stColorClarity: color.name,
        inPcs: inputValue.inPcs,
        dcPcsPerWt: inputValue.dcPcsPerWt,
        dcRate: inputValue.dcRate,
        dcAmount: AmountTotal,
        dcSettingCostPerPcs: inputValue.dcSettingCostPerPcs,
        dcSettingCostAmount: coseTotal,
        flgRateOn: inputValue.flgRateOn ? "1" : "0",
        flgIsSelect: inputValue.flgIsSelect ? "1" : "0",
      };
      const res = await updateSideStone(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchSideStone());
        setIsOpen(false);
      }
    } else {
      const payload = new FormData();
      payload.append("unDesignId", id);
      payload.append("stShapeName", shape?.name);
      payload.append("stSizeName", size.name);
      payload.append("stColorClarity", color.name);
      payload.append("inPcs", inputValue.inPcs);
      payload.append("dcPcsPerWt", inputValue.dcPcsPerWt);
      payload.append("dcWt", WtTotal);
      payload.append("dcRate", inputValue.dcRate);
      payload.append("dcAmount", AmountTotal);
      payload.append("dcSettingCostPerPcs", inputValue.dcSettingCostPerPcs);
      payload.append("dcSettingCostAmount", coseTotal);
      payload.append("flgRateOn", inputValue.flgRateOn ? "1" : "0");
      payload.append("flgIsSelect", inputValue.flgIsSelect ? "1" : "0");
      const res = await createSideStone(payload);
      if (res.status === 200) {
        dispatch(fetchSideStone());
        toast.success(res.data.message);
      }
    }
    handleClose();
  };
  return (
    <AdminModal
      title={"Add Side Stone Details"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        handleClose();
      }}
      submitButtonText={"Save"}
    >
      <div className="grid sm:grid-cols-6 text-[12px] gap-y-1 gap-x-4 sm:text-[14px] leading-[20px] font-[500]">
        <div className="sm:col-span-3 relative">
          <label
            htmlFor="sideShape"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Shape
          </label>
          <div className="">
            <AutoCompleteWithoutTags
              options={shapeNData}
              setData={setShape}
              data={shape}
            />
          </div>
        </div>
        <div className="sm:col-span-3 relative">
          <label
            htmlFor="sideShape"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Size
          </label>
          <div className="">
            <AutoCompleteWithoutTags
              options={sizeNData}
              setData={setSize}
              data={size}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="sideColor"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Color/Clarity
          </label>
          <div>
            <AutoCompleteWithoutTags
              options={colorNData}
              setData={setColor}
              data={color}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="inPcs"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            PCS
          </label>
          <div>
            <TextInputAdmin
              name="inPcs"
              type="text"
              id="inPcs"
              onChange={handleChange}
              value={inputValue.inPcs}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcPcsPerWt"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            PCS/PERWT
          </label>
          <div>
            <TextInputAdmin
              name="dcPcsPerWt"
              type="text"
              id="dcPcsPerWt"
              onChange={handleChange}
              value={inputValue.dcPcsPerWt}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcWt"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            WT
          </label>
          <div>
            <TextInputAdmin
              name="dcWt"
              disable={true}
              type="text"
              id="dcWt"
              onChange={handleChange}
              value={WtTotal && WtTotal.toFixed(2)}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcRate"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Rate
          </label>
          <div>
            <TextInputAdmin
              disable={true}
              name="dcRate"
              type="text"
              id="dcRate"
              onChange={handleChange}
              value={inputValue.dcRate}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcAmount"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Amount
          </label>
          <div>
            <TextInputAdmin
              name="dcAmount"
              disable={true}
              type="text"
              id="dcAmount"
              onChange={handleChange}
              value={Math.round(AmountTotal)}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcSettingCostPerPcs"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Setting Cost PER/PES
          </label>
          <div>
            <TextInputAdmin
              name="dcSettingCostPerPcs"
              type="text"
              id="dcSettingCostPerPcs"
              onChange={handleChange}
              value={inputValue.dcSettingCostPerPcs}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcSettingCostAmount"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Setting Cost Amount
          </label>
          <div>
            <TextInputAdmin
              name="dcSettingCostAmount"
              disable={true}
              type="text"
              id="dcSettingCostAmount"
              onChange={handleChange}
              value={coseTotal}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className="flex items-center">
            <input
              id="link-checkbox"
              type="checkbox"
              name="flgRateOn"
              onChange={handleChange}
              checked={inputValue.flgRateOn}
              className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
            />
            <label
              htmlFor="link-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Rate On.
            </label>
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className="flex items-center">
            <input
              id="flgIsSelect"
              type="checkbox"
              onChange={handleChange}
              checked={inputValue.flgIsSelect}
              name="flgIsSelect"
              className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
            />
            <label
              htmlFor="flgIsSelect"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Is Selected.
            </label>
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddSideStoneDetails;
