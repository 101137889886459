import React from "react";
import { useState } from "react";
import FormError from "./FormError";

const ImageUpload = ({ onChange = () => {}, video = false, ...rest }) => {
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setError("");
    let err = "";
    const { files } = e.target;
    if (!!files.length) {
      const arr = ["jpeg", "jpg", "png", "svg+xml", "mp4"];

      if (video) {
        arr.push("mp4");
      }

      const allFiles = Object.values(files);
      allFiles.forEach((item) => {
        if (!arr.includes(item.type.split("/")[1])) {
          err = "invalid file!";
          setError("invalid file!");
        }
      });
      if (!err) {
        onChange(e);
      }
    }
  };
  return (
    <>
      <input type="file" onChange={handleChange} {...rest} />
      {error && <FormError className=" text-sm italic">{error}</FormError>}
    </>
  );
};

export default ImageUpload;
