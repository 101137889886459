import React, { useEffect, useMemo } from "react";
import Pagination from "./Pagination";
import usePagination from "../../Hooks/use-Pagination";
import Loader from "./common/Loader";
import NoDataText from "../shared/NoDataText";
import { getAuthState } from "../../store/Slices/AuthSlice";
import { useSelector } from "react-redux";
import useApi from "../../Hooks/use-Api";
import { getOrderList } from "../../Services/orderService";
import moment from "moment";

const Dashboard = () => {
  const { user } = useSelector(getAuthState);

  const [fetchOrderData, orders, isLoading] = useApi(getOrderList);

  const data = useMemo(() => {
    return orders && user
      ? orders.order.filter((item) => item.unUserId === user.unUserIdentifier)
      : [];
  }, [orders, user]);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: orders ? orders.order : [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  const calculateTime = (time) => {
    const toDay = moment();
    const diff = toDay.diff(time, "day");
    if (diff === 0) {
      return "Today";
    }
    return `${diff}d`;
  };

  const pendingOrder = useMemo(() => {
    const pending =
      currentData.length > 0 &&
      currentData.filter(
        (item) =>
          item.unFollowUpStatusId === null && item.flgIsCancelOrder === 0
      );
    return pending.length;
  }, [currentData]);

  return (
    <div className="flex flex-col flex-1 w-full">
      <main className="h-full overflow-y-auto">
        <div className=" grid px-6 py-4 mx-auto from-gray-50">
          <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
            <div className="min-w-0 rounded-lg overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg">
                <div className="flex items-center justify-center p-3 rounded-[4px] h-12 w-12 text-center mr-4 text-lg text-gray-600 dark:text-#2A5067 bg-gray-100">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9" cy="21" r="1"></circle>
                    <circle cx="20" cy="21" r="1"></circle>
                    <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Total Order</span>
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {currentData?.length}
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg">
                <div className="flex items-center justify-center p-3 rounded-[4px] h-12 w-12 text-center mr-4 text-lg text-gray-600 dark:text-#2A5067 bg-gray-100 dark:bg-[#DAEAFE]">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="23 4 23 10 17 10"></polyline>
                    <polyline points="1 20 1 14 7 14"></polyline>
                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Order Pending</span>
                    {/* <span className="text-red-400 text-sm font-semibold">
                      (5891.50)
                    </span> */}
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    {pendingOrder || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg">
                <div className="flex items-center justify-center p-3 rounded-[4px] h-12 w-12 text-center mr-4 text-lg text-gray-600 dark:text-#2A5067 bg-gray-100 dark:bg-[#DAEAFE]">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="1" y="3" width="15" height="13"></rect>
                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Order Processing</span>
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    0
                  </p>
                </div>
              </div>
            </div>
            <div className="min-w-0 rounded-lg overflow-hidden bg-white dark:bg-gray-800 flex h-full">
              <div className="p-4 flex items-center border border-gray-200 dark:border-gray-800 w-full rounded-lg">
                <div className="flex items-center justify-center p-3 rounded-[4px] h-12 w-12 text-center mr-4 text-lg text-gray-600 dark:text-#2A5067 bg-gray-100 dark:bg-[#DAEAFE]">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div>
                  <p className="mb-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                    <span>Order Delivered</span>
                  </p>
                  <p className="text-2xl font-bold leading-none text-gray-600 dark:text-gray-200">
                    0
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" rounded-lg shadow overflow-hidden mt-4">
            <div className="overflow-auto border  bg-white ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#e2e8f0]">
                  <tr>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Age
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Order No
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Remark
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-left text-gray-500 uppercase  "
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-right text-gray-500 uppercase "
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-xs font-bold text-center text-gray-500 uppercase"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {!isLoading && !!currentData?.length
                    ? currentData.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-3 text-sm text-gray-800 ">
                              {calculateTime(order.dtOrderDate)}
                            </td>
                            <td className="p-3 text-sm text-gray-800 ">
                              {order.dtOrderDate.split(" ")[0]}
                            </td>
                            <td className="p-3 text-sm text-gray-800 ">
                              {order.stOrderNo}
                            </td>
                            <td className="p-3 text-sm text-gray-800 ">
                              {order.stOrderRemark || "-"}
                            </td>
                            <td className="p-3 text-sm text-gray-800 w-[100px]">
                              {order.setting.length}
                            </td>
                            <td className="p-3 text-sm text-gray-800 text-right w-[100px]">
                              ${Number(order.dcOrderAmount)}
                            </td>
                            <td className="p-3 text-sm text-gray-800 text-center">
                              {order.flgIsCancelOrder === 1 ? (
                                <span className="text-red-800 bg-red-200 text-[13px] py-0.5 px-2 rounded font-Poppins">
                                  Cancel
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : !isLoading && (
                        <tr>
                          <td colSpan={10}>
                            <NoDataText text={"No Order found!"} />
                          </td>
                        </tr>
                      )}
                  {isLoading && (
                    <tr>
                      <td colSpan={10} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
