import api from "./api"

const getStoneSize = () => {
  return api("/api/StoneSize", "get")
}

const getStoneSizeById = (id) => {
  return api(`/api/StoneSize/${id}`, "get")
}

const createStoneSize = (data) => {
  return api("/api/StoneSize", "post", data)
}

const updateStoneSize = (data) => {
  return api("/api/StoneSize", "put", data)
}

const DeleteStoneSize = (data) => {
  return api(`/api/StoneSize/${data}`, "delete")
}

export {
  getStoneSize,
  getStoneSizeById,
  createStoneSize,
  updateStoneSize,
  DeleteStoneSize
}