import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDesign,
  getDesignByCategoryId,
  getDesignByCategoryStyleId,
  getDesignById,
  getDesignsListV2,
  getDesignsJStockListV2
} from "../../Services/designService";

const userId = localStorage.name ? JSON.parse(localStorage.name) : {};

export const fetchDesigns = createAsyncThunk(
  "design/fetchDesign",
  async (
    params = {
      inPageNo: 1,
      inPageSize: 10,
      unUserId: userId?.unUserIdentifier,
    }
  ) => {
    const response = await getDesign(params);
    return response.data.data;
  }
);

export const fetchDesignsInProductPage = createAsyncThunk(
  "design/fetchDesign",
  async (params) => {
    const paramsData = {
      inPageNo: params || 1,
      inPageSize: params.inPageSize || 10,
      unUserId: userId?.unUserIdentifier,
    };
    const response = await getDesign(paramsData);
    return response.data.data;
  }
);

export const fetchDesignById = createAsyncThunk(
  "design/fetchSingleDesign",
  async (id) => {
    const response = await getDesignById(id);
    return response.data;
  }
);

export const fetchDesignByCategoryId = createAsyncThunk(
  "design/fetchDesignByCategoryId",
  async (id) => {
    const response = await getDesignByCategoryId(id);
    return response.data;
  }
);
export const fetchDesignByCategoryStyleId = createAsyncThunk(
  "design/fetchDesignByCategoryStyleId",
  async (id) => {
    const response = await getDesignByCategoryStyleId(id);
    return response.data;
  }
);

export const fetchDesignsListV2 = createAsyncThunk(
  "design/fetchDesignsListV2",
  async (data) => {
    const response = await getDesignsListV2(data);
    return response.data;
  }
);

export const fetchDesignsJStockListV2 = createAsyncThunk(
  "design/fetchDesignsJStockListV2",
  async (data) => {
    const response = await getDesignsJStockListV2(data);
    return response.data;
  }
);

const initialState = {
  data: [],
  design: null,
  loading: false,
  error: null,
  designLoading: false,
  designError: null,
  totalCount: 0,
  searchedText: ''
};

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    resetError(state) {
      state.designError = null;
      state.error = null;
    },
    setSearchedText(state, action) {
      state.searchedText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesigns.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesigns.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.designs;
        state.totalCount = action.payload.total;
        state.error = null;
      })
      .addCase(fetchDesigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignByCategoryId.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignByCategoryId.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.data = action.payload.data;
          state.error = null;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(fetchDesignByCategoryId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignByCategoryStyleId.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignByCategoryStyleId.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.data = action.payload.data.designs;
          state.error = null;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(fetchDesignByCategoryStyleId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignsListV2.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignsListV2.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.data = action.payload.data;
          state.error = null;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(fetchDesignsListV2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignsJStockListV2.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignsJStockListV2.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.data = action.payload.data;
          state.error = null;
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(fetchDesignsJStockListV2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignById.pending, (state, action) => {
        state.designLoading = true;
        state.designError = null;
      })
      .addCase(fetchDesignById.fulfilled, (state, action) => {
        state.designLoading = false;
        state.design = action.payload?.success ? action.payload.data : null;
        state.designError = !action.payload?.success
          ? action.payload.message
          : null;
      })
      .addCase(fetchDesignById.rejected, (state, action) => {
        state.designLoading = false;
        state.designError = action.error.message;
      });
  },
});

export const { resetError, setSearchedText } = designSlice.actions;

export const getDesignDataState = (state) => state.designData;

export default designSlice.reducer;
