import api from "./api"

const uploadSideStonePrice = (data) => {
  return api("/api/StonePriceUpload", "post", data)
}

const getSideStonePriceList = () => {
  return api("/api/StonePrice", "get")
}

const deleteSideStonePriceList = (id) => {
  return api(`/api/StonePrice/${id}`, "delete")
}

export {
  uploadSideStonePrice,
  getSideStonePriceList,
  deleteSideStonePriceList
}
