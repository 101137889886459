import api from "./api";

const getMenuData = () => api("/api/Menu", "get")
const getMenuDataById = (id) => api(`/api/Menu/${id}`, "get")
const createMenuData = (data) => api("/api/Menu", "post", data)
const updateMenuData = (data) => api("/api/Menu", "put", data)
const deleteMenuData = (id) => api(`/api/Menu/${id}`, "delete")

export {
  getMenuData,
  getMenuDataById,
  createMenuData,
  updateMenuData,
  deleteMenuData,
}