import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: {
    category: null,
    categoryStyle: null,
  },
};

const customizeJewelrySlice = createSlice({
  name: "cusomizeJewelrySlice",
  initialState,
  reducers: {
    setCustomizeJewelryAns(state, action) {
      state.answers = {
        ...state.answers,
        ...action.payload,
      };
    },
  },
});

export const { setCustomizeJewelryAns } = customizeJewelrySlice.actions;

export const getCustomizeJewelryData = (state) => state.customizeJewelry;

export default customizeJewelrySlice.reducer;
