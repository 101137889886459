import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAttributeData } from "../../Services/attributeService";

export const fetchAttributeData = createAsyncThunk("attribute/fetchData", async () => {
  const response = await getAttributeData()
  return response.data.data.attribute
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAttributeData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchAttributeData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchAttributeData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getAttributeState = state => state.attributeData

export default attributeSlice.reducer