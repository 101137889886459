import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdManageAccounts } from "react-icons/md";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { BsBookmarkDash } from "react-icons/bs";
import { IoLogOutOutline } from "react-icons/io5";
import { TbTools } from "react-icons/tb";
import { FiShoppingCart } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../store/Slices/sidebarStatusSlice";
import { clearAuth } from "../../store/Slices/AuthSlice";
import { Tooltip } from "@material-tailwind/react";
import useRolePermission from "../../Hooks/use-RolePermission";
import { setRolePermissions } from "../../store/Slices/rolePermissions";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserData = localStorage.getItem("name")
    ? JSON?.parse(localStorage.getItem("name"))
    : null;
  const [hasPermission, accessableRoutes] = useRolePermission(
    location.pathname
  );

  useEffect(() => {
    if (hasPermission)
      dispatch(setRolePermissions({ accessableRoutes, hasPermission }));
  }, [accessableRoutes, hasPermission]);

  const menus = useMemo(
    () => [
      {
        name: "Dashboard",
        link: "/admin/dashboard",
        icon: <MdOutlineDashboard size={20} />,
      },
      {
        name: "Master",
        link: "/admin/masterMenu",
        icon: <SlBadge size={20} />,
      },
      {
        name: "Transaction",
        link: "/admin/transactionMenu",
        icon: <BsBookmarkDash size={20} />,
      },
      {
        name: "Order",
        link: "/admin/orderlist",
        icon: <FiShoppingCart size={20} />,
      },
      {
        name: "Report",
        link: "/admin/reportMenu",
        icon: <HiOutlineClipboardDocumentList size={20} />,
      },
      {
        name: "Utility",
        link: "/admin/utilityMenu",
        icon: <RiSettings4Line size={20} />,
      },
      {
        name: "Agent",
        link: "/admin/agentMenu",
        icon: <MdManageAccounts size={20} />,
      },
      {
        name: "Tools",
        link: "/admin/tool",
        icon: <TbTools size={20} />,
      },
    ],
    []
  );


  const menuList = useMemo(() => {
    if (accessableRoutes) {
      return menus.filter((item) => accessableRoutes.includes(item.link));
    }
    return [];
  }, [accessableRoutes, menus]);
  const [open, setOpen] = useState(true);
  const sidebarOpenCloseHandler = () => {
    setOpen((prev) => {
      return !prev;
    });
    dispatch(toggleSidebar(!open));
  };

  const handleLogout = () => {
    dispatch(clearAuth());
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      className={`bg-[#1F2937] overflow-hidden relative lg:flex flex-col justify-between hidden min-h-screen h-[100vh] z-50 ${
        open ? "w-72" : "w-16"
      } duration-500 text-gray-100 `}
    >
      <div className="px-4">
        <div className="py-3 flex justify-end">
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={sidebarOpenCloseHandler}
          />
        </div>
        <div className="mt-4 flex flex-col gap-1 relative">
          {menuList?.map((menu, i) => (
            <Link
              to={menu?.link}
              key={i}
              className={` ${menu?.margin && "mt-5"} ${
                location.pathname === menu?.link ? "bg-[#111827]" : ""
              } group flex items-center text-sm gap-3.5 font-medium px-3 py-4 hover:bg-[#111827] duration-300`}
            >
              <div>{menu?.icon}</div>
              <h2
                style={{
                  transitionDelay: `${(i + 3) * 100}ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit z-200`}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}
        </div>
      </div>
      <div
        className={`w-72 pl-[10px] pr-[20px] border-t-[1px] border-[#6B7280] `}
      >
        <div className={`py-[17px] w-full flex items-center justify-between `}>
          <div className={`flex gap-[10px] items-center`}>
            <div>
              <div className="w-[45px] h-[45px] rounded-full border flex items-center justify-center bg-[#e2e8f0] text-[#334155] font-bold capitalize">
                {UserData?.socialProvider
                  ? UserData?.stUserName?.[0]
                  : UserData?.stFirstName?.[0] + UserData?.stLastName?.[0]}
              </div>
            </div>
            <div className={`${!open ? "hidden" : "block"} overflow-hidden `}>
              <h3 className="font-[600] text-[16px] leading-6 text-[#FFFFFF] ">
                {UserData?.socialProvider
                  ? UserData?.stUserName
                  : UserData?.stFirstName + " " + UserData?.stLastName}
              </h3>
              <p className="font-[500] text-[12px] leading-[16px] text-[#6B7280]">
                {UserData?.stRoleName}
              </p>
            </div>
          </div>
          <Tooltip content="Logout">
            <button
              data-tooltip-target="tooltip-default"
              type="button"
              className={`${!open ? "hidden" : "block"} cursor-pointer`}
              onClick={handleLogout}
            >
              <IoLogOutOutline size={20} color="#6B7280" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
