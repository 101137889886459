import React from "react";
import { TiArrowBack } from "react-icons/ti";

const StockList = () => {
  return (
    <div className="xl:max-w-[1224px] max-w-none mx-auto w-full xl:px-0 px-[20px] py-6">
      <button className="bg-primaryColor text-white rounded text-[14px] border-[1px] py-[8px] px-4 font-[600] leading-4 flex justify-center items-center gap-1">
        <TiArrowBack className="text-lg mr-1" /> Back
      </button>

      <div className="my-5">
        <div className="flex justify-start items-center gap-1">
          <h3 className="font-bold text-gray-800">Product Name</h3>
          <span>:-</span>
          <p className="text-gray-800">Demo</p>
        </div>
        <div className="flex justify-start items-center gap-1">
          <h3 className="font-bold text-gray-800">SKU Code</h3>
          <span>:-</span>
          <p className="text-gray-800">A2B5645</p>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default StockList;
