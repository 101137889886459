import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMountingList, getMountingDetails, getMountingMenu } from "../../Services/mountingService";

export const fetchMountingMenu = createAsyncThunk("mounting/fetchMountingMenu", async () => {
  const response = await getMountingMenu()
  return response.data.data
})

export const fetchMountingList = createAsyncThunk("mounting/fetchMountingList", async () => {
  const response = await getMountingList()
  return response.data.data
})

export const fetchMountingDetailById = createAsyncThunk(
  "mounting/fetchMountingDetailById",
  async (params) => {
    const response = await getMountingDetails(params);
    return response.data.data;
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
  mountingDetailLoading: null,
  mountingDetailError: null,
  mountingDetail: null,
  menuData: [],
  menuLoading: false,
  menuError: null,
}

const mountingSlice = createSlice({
  name: "mounting",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(fetchMountingList.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchMountingList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(fetchMountingList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(fetchMountingDetailById.pending, (state, action) => {
      state.mountingDetailLoading = true;
      state.mountingDetailError = null;
    })
    .addCase(fetchMountingDetailById.fulfilled, (state, action) => {
      state.mountingDetailLoading = false;
      state.mountingDetail = action.payload;
      state.mountingDetailError = null;
    })
    .addCase(fetchMountingDetailById.rejected, (state, action) => {
      state.mountingDetailLoading = false;
      state.mountingDetailError = action.error.message;
    })
    .addCase(fetchMountingMenu.pending, (state) => {
      state.menuLoading = true
    })
    .addCase(fetchMountingMenu.fulfilled, (state, action) => {
        state.menuLoading = false
        state.menuData = action.payload
        state.menuError = null
    })
    .addCase(fetchMountingMenu.rejected, (state, action) => {
        state.menuLoading = false
        state.menuError = action.error.message
    })
  }
})

export const getMountingState = (state) => state.mounting;

export default mountingSlice.reducer