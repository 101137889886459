import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  createStoneColor,
  updateStoneColor,
} from "../../../Services/stoneColorService";
import {
  fetchStoneColorData,
  getStoneColorState,
} from "../../../store/Slices/stoneColorSlice";
import AdminModal from "../common/AdminModal";

const ColorModal = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [inputValue, setInputValue] = useState({
    stStoneColorName: "",
    stStoneColorAlias: "",
    stIntencity: "",
    stOverTone: "",
    flgIsActive: true,
  });
  const { data } = useSelector(getStoneColorState);
  const dispatch = useDispatch();

  useEffect(() => {
    const editData = data.find(
      (item) => item.unStoneColorIdentifier === editId
    );
    if (editData) {
      setInputValue({
        stStoneColorName: editData.stStoneColorName,
        stStoneColorAlias: editData.stStoneColorAlias,
        stIntencity: editData.stIntencity,
        stOverTone: editData.stOverTone,
        flgIsActive: editData.flgIsActive === "1" ? true : false,
      });
    }
  }, [editId, data]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setInputValue({
      stStoneColorName: "",
      stStoneColorAlias: "",
      stIntencity: "",
      stOverTone: "",
      flgIsActive: true,
    });
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stStoneColorName: inputValue.stStoneColorName,
        stStoneColorAlias: inputValue.stStoneColorAlias,
        stIntencity: inputValue.stIntencity,
        stOverTone: inputValue.stOverTone,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unStoneColorIdentifier: editId,
      };
      const res = await updateStoneColor(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneColorData());
        setIsOpen(false);
        setEditId("")
      }
    } else {
      const payload = new FormData();
      payload.append("stStoneColorName", inputValue.stStoneColorName);
      payload.append("stStoneColorAlias", inputValue.stStoneColorAlias);
      payload.append("stIntencity", inputValue.stIntencity);
      payload.append("stOverTone", inputValue.stOverTone);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createStoneColor(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneColorData());
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={"Add Stone Color"}
      onSubmit={handleSubmit}
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
    >
      <div className="mb-4">
        <label
          htmlFor="stStoneColorName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Stone Color Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stStoneColorName"
            id="stStoneColorName"
            value={inputValue.stStoneColorName}
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Stone Color Name"
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="stStoneColorAlias"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Stone Color Alias
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stStoneColorAlias"
            id="stStoneColorAlias"
            value={inputValue.stStoneColorAlias}
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Stone Color Alias"
          />
        </div>
      </div>
      <div className="flex items-center gap-[10px]">
        <div className="mb-6 w-[400px]">
          <label
            htmlFor="stIntencity"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Intencity
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="stIntencity"
              id="stIntencity"
              value={inputValue.stIntencity}
              onChange={handleChange}
              placeholder="Enter Intencity"
            />
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="stOverTone"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            OverTone
          </label>
          <div className="col-span-8 sm:col-span-4">
            <input
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              name="stOverTone"
              id="stOverTone"
              value={inputValue.stOverTone}
              onChange={handleChange}
              placeholder="Enter OverTone"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="flgIsActive"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="flgIsActive"
            className=" block text-sm font-medium leading-6 text-gray-900"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default ColorModal;
