import React from "react";
import Header from "../Components/LandingComponent/Header";
import Footer from "../Components/LandingComponent/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  resetError,
} from "../store/Slices/designSlice";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import { IoIosArrowBack } from "react-icons/io";
import ProductDetailsSkeleton from "../Components/LandingComponent/Common/skeleton/ProductDetailsSkeleton";
import ReadyToShipDetails from "../Components/LandingComponent/ReadyToShipDetails";
import { fetchReadyToShipById, getReadyToShipDataState } from "../store/Slices/readyToShipSlice";

const ReadyToShipDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector(getReadyToShipDataState);
  const { design, designLoading, designError } = data;

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchReadyToShipById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (designError) {
      toast.error(designError || "No Product found.");
      dispatch(resetError());
      navigate(-1);
    }
  }, [designError, navigate, dispatch]);

  const breadCrumbData = [
    {
      title: "Home",
      onClick: () => {
        navigate("/jewelry");
      },
    },
    {
      title: "Ready To Ship",
      onClick: () => {
        navigate(location.state?.prevPath || '/readytoship', {
          state: { category: location.state?.category },
        });
      },
    },
    {
      title: design?.StockName || "",
    },
  ];

  return (
    <>
      <Header />
      {/* <div className="headerBg"> */}
      <div className="xl:max-w-[1224px] max-w-auto xl:mx-auto mx-[20px] xl:w-full w-auto ">
        {/* <h3 className="lg:text-[22px] text-center font-semibold md:text-[26px] text-[20px] lg:leading-[52px] md:leading-[40px] leading-[30px] md:my-0 my-3">
          Customize your ring
        </h3> */}
        <div className="my-6">
          {/* <Breadcrumb tabs={breadCrumbData} /> */}

          <button
            type="button"
            onClick={() => {
              navigate(location.state?.prevPath || '/readytoship', {
                state: { category: location.state?.category },
              });
            }}
            className="flex items-center outline-none gap-2 font-Primary"
          >
            <IoIosArrowBack /> Back
          </button>

        </div>
      </div>
      {/* </div> */}

      {designLoading ? (
        <ProductDetailsSkeleton />
      ) : (
        <div>
          <ReadyToShipDetails product={design} />
          {/* <ViewedProduct title={ProductlistTitle} /> */}
          {/* <Reviews /> */}
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default ReadyToShipDetail;
