import React from "react";
import ProductList from "../Components/LandingComponent/ProductList";
import { useEffect } from "react";
import ProductBanner from "../Components/LandingComponent/ProductBanner";

const ProductListByCategoryStyle = () => {
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <ProductBanner className="xl:mb-[28px] lg:mb-[20px] mb-[15px]" />
      <div className="products max-w-[1224px]  mx-auto p-4 xl:p-0 xl:mb-[100px] lg:mb-[80px] md:mb-[60] mb-[40px]">
        <ProductList />
      </div>
    </>
  );
};

export default ProductListByCategoryStyle;
