import { Menu } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "react-transition-group";
import { clearAuth } from "../../store/Slices/AuthSlice";
import { useDispatch } from "react-redux";
import { RxDashboard } from "react-icons/rx";
import { CgLogOut } from "react-icons/cg";

const IsLoginHeader = () => {
  const User = JSON.parse(localStorage.getItem("name"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearAuth());
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <div className="rounded-full blur w-[43px] h-[40px] border flex items-center justify-center bg-gray-300 duration-300 text-[#334155] font-bold outline-none absolute z-20 top-0"></div>
          <Menu.Button className="relative inline-flex w-full justify-center rounded-md bg-transparent px-[13px] py-2 text-sm font-medium text-gray-800 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-40">
            <span className="cursor-pointer capitalize">
              {(User?.socialProvider ? User?.stUserName?.[0] : ((User?.stFirstName?.[0] || "") + (User?.stLastName?.[0] || "")))}
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-slate-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => navigate("/admin")}
                  >
                    {active ? (
                      <RxDashboard
                        className="mr-2 mt-1 h-4 w-5 text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <RxDashboard
                        className="mr-2 mt-1 h-4 w-5"
                        aria-hidden="true"
                      />
                    )}
                    Dashboard
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-slate-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={handleLogout}
                  >
                    {active ? (
                      <CgLogOut
                        className="mr-2 mt-1 h-4 w-5 text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CgLogOut
                        className="mr-2 mt-1 h-4 w-5"
                        aria-hidden="true"
                      />
                    )}
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default IsLoginHeader;
