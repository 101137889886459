import api from "./api";

const getUsers = (params) => api("/api/Users", "get", null, params);

const updateUserRole = (data) => api("/api/UserRoleUpdate", "put", data);

const updateUserProfile = (data) => api("/api/UserProfileUpdate", "put", data);

const updateUserPassword = (data) => api("/api/UserPasswordUpdate", "put", data);

const deleteUserRole = (data) => api(`/api/Users/${data}`, "delete");

const updateUseAccess = (data) => api(`api/UseAccessUpdate`, "put", data);

export { getUsers, updateUserRole, updateUserProfile, updateUserPassword, deleteUserRole, updateUseAccess };
