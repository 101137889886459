import { RadioGroup } from "@headlessui/react";
import React from "react";
import { useState } from "react";
import AdminModal from "../common/AdminModal";
import { SiMicrosoftexcel } from "react-icons/si";
import { AddDiamondStockData } from "../../../Services/diamondService";
import { toast } from "react-hot-toast";
import { HiInformationCircle } from "react-icons/hi";
import { stockFileUploadExt } from "../../shared/constants";
import FormError from "../../shared/FormError";

const checkBoxList = [
  { name: "Upload Full List", value: "delete" },
  // { name: "Update", value: "update" },
  { name: "Add New Stock", value: "apend" },
];

const infoData = {
  // delete: (
  //   <div>
  //     <p>First delete current stock</p>
  //     <p>And add stock from excel stock</p>
  //   </div>
  // ),
  update: (
    <div>
      <p>Exit CertiID Stock Modify / Update.</p>
      <p>And not exits certiID stock add from excel stock</p>
    </div>
  ),
  apend: (
    <div>
      <p>Add Stock from excel not check certiId condition</p>
    </div>
  ),
};

const UploadDiamondStock = ({ isOpen, setIsOpen, downloadStockSampleFile }) => {
  const [selected, setSelected] = useState(checkBoxList[0]);
  const [error, setError] = useState("");
  const [inputValue, setInputValue] = useState({
    file: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const file = files[0];
    if (file.name && stockFileUploadExt.includes(file.name.split(".").pop())) {
      setInputValue((prev) => ({
        ...prev,
        [name]: files[0],
      }));
      setError("");
    } else {
      setError("Invalid file.");
    }
  };

  const handleClose = () => {
    setInputValue({
      file: "",
    });
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    const payload = new FormData();

    if (error || !inputValue.file) return;

    payload.append("file", inputValue.file);
    payload.append("type", selected.value.toUpperCase());
    const res = await AddDiamondStockData(payload);
    if (res.status === 200) {
      toast.success(res.data.message);
    }
    handleClose();
  };

  return (
    <AdminModal
      title={"Upload Diamond Stock"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
      submitButtonText="Upload"
      disableSubmit={error || !inputValue.file}
    >
      <div className="mb-2">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="flex items-center gap-[10px] sm:gap-[20px] justify-around">
            {checkBoxList.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ active, checked }) =>
                  `${active ? "ring-2 ring-white ring-opacity-60 " : ""}
                  ${
                    checked
                      ? "border-2 border-[#295268] bg-[#edf4f8] bg-opacity-75 text-white"
                      : "border border-slate-400 bg-white"
                  }
                    relative sm:w-full w-[150px] flex cursor-pointer outline-none rounded-lg px-5 py-3 shadow-md focus:outline-none mt-0`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-center">
                      <div className="flex items-center">
                        <div className="text-[11px] sm:text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-[#295268]" : "text-gray-900"
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-[#73aac8] checkBoxData mt-2">
                          <CheckIcon className="h-4 w-4" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {!!infoData?.[selected?.value] && (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <HiInformationCircle />
          </div>
          <div className="text-xs text-gray-400 font-montserrat">
            {infoData?.[selected?.value]}
          </div>
        </div>
      )}
      <div className="mt-4">
        <label
          htmlFor="file-upload"
          className="flex max-w-lg cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
        >
          <div className="space-y-1 text-center">
            <div className="flex items-center justify-center gap-4 text-sm text-gray-600">
              <SiMicrosoftexcel size={48} color="#295268" />
              <div className="relative rounded-md bg-white font-medium">
                <div>
                  <span className="block">
                    {inputValue.file ? inputValue.file.name : "Upload a file"}
                  </span>
                  {error && <FormError>{error}</FormError>}
                </div>
                <input
                  id="file-upload"
                  name="file"
                  onChange={handleChange}
                  type="file"
                  className="sr-only"
                />
              </div>
            </div>
          </div>
        </label>
      </div>
      <div>
        <span
          className="text-xs text-blue-900 cursor-pointer"
          onClick={() => downloadStockSampleFile()}
        >
          Download Sample Excel
        </span>
      </div>
    </AdminModal>
  );
};

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle
        style={{ fill: "#295268" }}
        cx={12}
        cy={12}
        r={12}
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadDiamondStock;
