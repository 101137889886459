import api from "./api";

export const postReadyToShipDesign = (data) => api("/api/AddStock", "post", data)

export const updateReadyToShipDesign = (data) => api("/api/UpdateStock", "put", data)

export const getReadyToShipById = (id) => api(`/api/GetStockAdmin/${id}`, "get")

export const getReadyToShipDesign = (params) => api(`/api/GetStockAdmin`, "get", null, params)

export const deleteReadyToShipDesign = (id) => api(`/api/DeleteStock/${id}`, "delete")

export const updateStockFlgById = (data) => api(`/api/UpdateStockFlgById`, "put", data)