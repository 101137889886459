import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import { Link, useLocation, useParams } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import AddSideStoneDetails from "../Modals/AddSideStoneDetails";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import Pagination from "../Pagination";
import {
  fetchSideStone,
  getSideStoneDataState,
} from "../../../store/Slices/sideStoneSlice";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSideStone } from "../../../Services/sideStoneService";
import { toast } from "react-hot-toast";
import swal from "sweetalert";
import usePagination from "../../../Hooks/use-Pagination";
import NoDataText from "../../shared/NoDataText";
import Loader from "../common/Loader";

const StepTwo = () => {
  const [editId, setEditId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading, error } = useSelector(getSideStoneDataState);
  const location = useLocation();
  const currentStoneData = useMemo(() => {
    if (data?.length) {
      return data.filter((item) => item.unDesignId === id);
    }
    return [];
  }, [data, id]);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: currentStoneData,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchSideStone());
  }, [dispatch]);

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await DeleteSideStone(id);
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(fetchSideStone());
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  return (
    <div className="grid px-6 pt-6">
      <AddSideStoneDetails
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        setEditId={setEditId}
      />
      <div className="flex items-center justify-between gap-3">
        <h2 className="text-[22px] font-Josefin font-semibold">
          {location?.state?.name}
        </h2>
        <div className="flex items-center justify-end gap-3">
          <Link
            to={"/admin/product"}
            className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
          >
            <TiArrowBack className="text-[18px]" /> Back
          </Link>
          <button
            onClick={() => setIsOpen(true)}
            className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
          >
            <BsPlusLg className="text-[12px]" /> Add
          </button>
        </div>
      </div>
      <div className="shadow-md sm:rounded-lg mt-4 overflow-hidden">
        <div
          className={`relative overflow-x-auto bg-white ${
            []?.length ? "min-h-[15rem]" : ""
          } `}
        >
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-3 max-w-[75px]">
                  Sr No.
                </th>
                <th scope="col" className="px-4 py-3">
                  shape
                </th>
                <th scope="col" className="px-4 py-3">
                  Size
                </th>
                <th scope="col" className="px-4 py-3 ">
                  colour/clarity
                </th>
                <th scope="col" className="px-4 py-3">
                  PCS
                </th>
                <th scope="col" className="px-4 py-3">
                  CT
                </th>
                <th scope="col" className="px-4 py-3">
                  WT
                </th>
                <th scope="col" className="px-4 py-3">
                  Rate($)
                </th>
                <th scope="col" className="px-4 py-3">
                  Amount($)
                </th>
                <th scope="col" className="px-4 py-3">
                  Rate On
                </th>
                <th scope="col" className="px-4 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading && currentData?.length
                ? currentData.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-white text-xs border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                      >
                        <th
                          scope="row"
                          className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {i + 1}
                        </th>
                        <td className="px-4 py-3">{item.stShapeName}</td>
                        <td className="px-4 py-3">{item.stSizeName}</td>
                        <td className="px-4 py-3">{item.stColorClarity}</td>
                        <td className="px-4 py-3">{item.inPcs}</td>
                        <td className="px-4 py-3">{item.dcPcsPerWt}</td>
                        <td className="px-4 py-3">{item.dcWt}</td>
                        <td className="px-4 py-3">
                          {item.dcRate ? Number(item.dcRate).toFixed(2) : ""}
                        </td>
                        <td className="px-4 py-3">
                          {item.dcAmount
                            ? Number(item.dcAmount).toFixed(2)
                            : ""}
                        </td>
                        <td className="px-4 py-3">
                          {item.flgRateOn === "1" ? (
                            <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                              Y
                            </span>
                          ) : (
                            <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                              N
                            </span>
                          )}
                        </td>
                        <td className="px-4 py-3 w-[100px]">
                          <div className="flex items-center gap-[10px]">
                            <button
                              type="button"
                              onClick={() =>
                                handleEdit(item.unDesignStoneDetailsIdentifier)
                              }
                            >
                              <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleDelete(
                                  item.unDesignStoneDetailsIdentifier
                                )
                              }
                            >
                              <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : !loading && (
                    <tr>
                      <td colSpan={11}>
                        <NoDataText text={"No Stone found!"} />
                      </td>
                    </tr>
                  )}
              {loading && (
                <tr>
                  <td colSpan={11} className="h-60">
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalItems={currentStoneData.length}
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default StepTwo;
