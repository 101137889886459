import React from "react";

const Button = ({ children, className = "", theme = "light", ...rest }) => {
  return (
    <button
      {...rest}
      className={`w-full font-Josefin h-[44px] flex justify-center items-center text-[16px] leading-[32px] font-400 border-[1px] border-black ${
        theme === "light"
          ? "bg-[#FFFFFF] text-[#334155]"
          : "bg-[#334155] text-[#FFFFFF]"
      } ${className} `}
    >
      {children}
    </button>
  );
};

export default Button;
