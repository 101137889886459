import { useState, useEffect, memo } from "react";
import { ArrowLeftCircleIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";

import { fetchCalibratedData } from "../../../store/Slices/calibratedSlice";
import { getAuthState } from "../../../store/Slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCalibrateStockByMM,
  getCalibrateStockBySieve,
} from "../../../Services/calibratedService";
import { MdShoppingCart } from "react-icons/md";
import { tawkWidgetToggle } from "../../../Utils";
import AddToCartModal from './addToCartModal'

const tableData = [
  {
    sr: 1,
    colour: "DEF",
    clarity: "VS1+",
    origin: "Exp12",
    location: "India",
    pcs: "50",
    carat: "0.076",
    cost: "100",
    amount: "7.60",
  },
  {
    sr: 2,
    colour: "DEF",
    clarity: "VS1+",
    origin: "Exp12",
    location: "India",
    pcs: "50",
    carat: "0.076",
    cost: "100",
    amount: "7.60",
  },
  {
    sr: 3,
    colour: "DEF",
    clarity: "VS1+",
    origin: "Exp12",
    location: "India",
    pcs: "50",
    carat: "0.076",
    cost: "100",
    amount: "7.60",
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function TableList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentSize, currentShape } = props.parentState;
  const { handleCurrentNavKey } = props;

  const { isLoggedIn, user } = useSelector(getAuthState);

  const [state, setState] = useState({
    shapeStats: [
      { id: 1, name: "Lot", value: currentSize?.lot },
      { id: 2, name: "Pcs", value: currentSize?.pcs },
      { id: 3, name: "Carat", value: currentSize?.carat },
    ],
    stockData: [],
    loading: true,
    selectedItem: {},
    openModal: false,
  });
  
  const { shapeStats, stockData, loading, selectedItem, openModal } = state;

  const handleAddToCart = (item) => {
    setState((prevState) => ({ ...prevState, selectedItem: item, openModal: true }));
  };

  const toggleModal = () => {
    setState((prevState) => ({ ...prevState, openModal: !openModal }));
  };

  const getData = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    let resp = "";

    if (currentShape?.shapeSieveMM === "SM" && currentSize?.shapeMM === "SM")
      resp = await getCalibrateStockBySieve({
        shapeCode: currentShape?.shapeCode,
        shapeSize: currentSize?.name,
      });
    else
      resp = await getCalibrateStockByMM({
        shapeCode: currentShape?.shapeCode,
        shapeSize: currentSize?.name,
      });

    setState((prevState) => ({
      ...prevState,
      stockData: resp.data.data.ShapeSize.original,
      loading: false,
    }));
  };

  useEffect(() => {
    getData();
  }, [currentSize]);

  const tawkTo = require("tawkto-react");
  const tawkToPropertyId = "64644842ad80445890ed5f0b";
  const tawkToKey = "1h0jrkhog";

  useEffect(() => {

    if(window.innerWidth > 0)
    {
      new tawkTo(tawkToPropertyId, tawkToKey);
      tawkWidgetToggle(true);
      return () => {
        tawkWidgetToggle(false);
      };
    }
    
  }, [tawkTo]);

  return (
    <>
      <div className="bg-gray-100 h-[calc(100vh-64px)]">
        <main>
          <div className="py-1 sm:py-1 mx-auto px-4 md:px-6 lg:px-4">
            <div class="mt-2 w-full flex justify-between items-center p-2 bg-white shadow">
              <div class="w-[45%] lg:w-[70%] md:w-[60%]">
                <div className="flex items-center">
                  <ArrowLeftIcon
                    className="h-6 w-6 md:h-8 md:w-8 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={(e) => {
                      handleCurrentNavKey(currentShape?.key);
                    }}
                  />

                  <div className="ml-2">
                    <h3 class="text-sm md:text-xl font-semibold text-gray-700 truncate text-ellipsis">
                      {currentSize?.name || "N/A"}
                    </h3>

                    <p class="text-[11px] md:text-xs mt-1 text-gray-600 font-medium">
                      {currentShape?.name}
                    </p>
                  </div>
                </div>
              </div>

              <div class="text-xs lg:text-sm font-medium text-gray-900 text-right w-[55%] lg:w-[30%] md:w-[40%] flex justify-between p-1">
                {shapeStats.map((stat) => (
                  <div
                    key={stat.id}
                    class="flex flex-col-reverse md:flex-row w-full justify-center items-center border-l-[1px] border-gray-400 pl-1 text-center"
                  >
                    <p className="text-[15px] leading-5 font-semibold md:text-xl text-gray-700">
                      {stat.value}
                    </p>
                    <p class="text-[10px] md:text-xs ml-1 font-normal mt-0 md:mt-2">
                      {stat.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <AddToCartModal
            item={{...selectedItem, name: currentSize?.name}}
            source="cardlist"
            open={openModal}
            handleClose={toggleModal}
            parentState={props.parentState}
          />

          <div>
            <div className="overflow-x-auto sm:-mx-0 lg:-mx-0">
              <div className="inline-block min-w-full py-2 align-middle px-4 sm:px-6 lg:px-4">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                  {loading ? (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          {[...Array(7).keys()]?.map((item) => {
                            return (
                              <th
                                scope="col"
                                className="py-3.5  pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                              >
                                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 m-2"></div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {[...Array(5).keys()]?.map((item) => {
                          return (
                            <tr
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {[...Array(7).keys()]?.map((item) => {
                                return (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 m-2"></div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell"
                          >
                            Sr
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Colour
                          </th>
                          <th
                            scope="col"
                            className="block lg:hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Quality
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Clarity
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Pcs
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Carat
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Price/Pcs
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Location
                          </th>
                          {/* <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Origin
                          </th> */}
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Lot
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {stockData?.map((item, itemIdx) => {
                          return (
                            <tr key={itemIdx}>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "hidden py-4 pl-4 pr-3 text-sm sm:pl-6 lg:table-cell"
                                )}
                              >
                                <div className="font-medium text-gray-900">
                                  {itemIdx + 1}
                                </div>
                                {itemIdx !== 0 ? (
                                  <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                                ) : null}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "px-3 py-3.5 text-sm text-gray-500"
                                )}
                              >
                                <p className="hidden lg:block">
                                  {item?.Color || "-"}
                                </p>

                                <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                                  <p className="font-medium text-gray-900">
                                    {item?.Color || "-"} /{" "}
                                    {item?.Clarity || "-"}
                                  </p>
                                  <p className="text-xs">
                                    {item?.Location || "-"}{" "}
                                    {item?.Lot && `/ ${item?.Lot}`}
                                  </p>
                                </div>
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                              >
                                {item?.Clarity || "-"}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "px-3 py-3.5 text-sm text-gray-500"
                                )}
                              >
                                {item?.Pcs || "-"}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "px-3 py-3.5 text-sm text-gray-500"
                                )}
                              >
                                {item?.Carat || "-"}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "px-3 py-3.5 text-sm text-gray-500"
                                )}
                              >
                                {!isLoggedIn ? (
                                  <button
                                    type="button"
                                    className="font-[400] underline"
                                    onClick={() => {
                                      navigate("/login");
                                    }}
                                  >
                                    See Price
                                  </button>
                                ) : item?.PerPcsPrice ? (
                                  `USD $${Number(item?.PerPcsPrice)?.toFixed(
                                    2
                                  )}`
                                ) : (
                                  <button
                                    type="button"
                                    className="font-[400] underline"
                                    onClick={() => {
                                      window.Tawk_API.maximize();
                                      window.Tawk_API.setAttributes(
                                        {
                                          unUserIdentifier:
                                            user.unUserIdentifier,
                                          stUserName: user.stUserName,
                                          Role: user.Role,
                                        },
                                        function (error) {
                                          console.log("Error", error);
                                        }
                                      );
                                    }}
                                  >
                                    On Request
                                  </button>
                                )}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200 ",
                                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                              >
                                {item?.Location || "-"}
                              </td>
                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                              >
                                {item?.Lot || "-"}
                              </td>

                              <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "px-3 py-3.5 text-sm text-gray-500 w-5 sm:w-28 lg:table-cell"
                                )}
                              >
                                <div
                                  className="flex items-center cursor-pointer w-6 sm:w-full gap-[2px] justify-center bg-gray-700 text-gray-200 py-1 text-sm sm:text-xs"
                                  onClick={() => {
                                    handleAddToCart(item);
                                  }}
                                >
                                  <MdShoppingCart />
                                  <p className="hidden sm:block">Add To Cart</p>
                                </div>
                              </td>

                              {/* <td
                                className={classNames(
                                  itemIdx === 0
                                    ? ""
                                    : "border-t border-gray-200",
                                  "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                )}
                              >
                                {item?.Origin || "-"}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default memo(TableList);
