import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyAtyZ-29zpZZtGqNNL4XSEMNa5PMNrxMX4",
//   authDomain: "fir-demo-9de0e.firebaseapp.com",
//   projectId: "fir-demo-9de0e",
//   storageBucket: "fir-demo-9de0e.appspot.com",
//   messagingSenderId: "274038448090",
//   appId: "1:274038448090:web:8411c14d160567798c2ae3",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBCEODpd-Mr5EBeyaMSbeSWwjqueyw-e_Q",
  authDomain: "dtn-diamonds.firebaseapp.com",
  projectId: "dtn-diamonds",
  storageBucket: "dtn-diamonds.appspot.com",
  messagingSenderId: "657695575442",
  appId: "1:657695575442:web:424e05b6f0681a97a9054b",
  measurementId: "G-NZW81NPRDQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };
