import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMenuData } from "../../Services/menuService";

export const fetchMenu = createAsyncThunk("menu/fetchMenu",
  async () => {
    const response = await getMenuData()
    return response.data.data.menu
  })

const initialState = {
  data: [],
  loading: false,
  error: null
}

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMenu.pending, (state, action) => {
      state.loading = true
    })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      })
  }
})

export const getMenuDataState = state => state.menuData

export default menuSlice.reducer