import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">404 Error</h1>
      <p className="text-lg text-gray-600 mb-8">Oops! The page you're looking for does not exist.</p>
      <Link
        to="/jewelry"
        className="px-4 py-2 bg-slate-500 text-white font-semibold rounded hover:bg-slate-600 transition duration-300"
      >
        Go back to homepage
      </Link>
    </div>
  );
};

export default NotFoundPage;
