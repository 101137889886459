import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { ClickAwayListener } from "@mui/material";

export default function DropdownMenu({ children, title, theme = "light" }) {
  const [open, setOpen] = useState(false);
  const classes = classNames({
    "bg-transparent text-gray-600 border border-gray-300 rounded-3xl":
      theme === "light",
    "bg-primaryColor text-white rounded-3xl": theme === "dark",
  });
  return (
    <div className={classes}>
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setOpen((prev) => !prev)}
            className="inline-flex w-full justify-center rounded-md px-2 py-0.5 lg:px-4 lg:py-1 lg:text-sm text-xs font-medium hover:bg-opacity-30 outline-none "
          >
            {title}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </div>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div
              className={`absolute right-0 z-50 mt-2 min-w-full origin-top-right divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ${
                theme === "light"
                  ? "divide-gray-100  bg-white"
                  : "divide-gray-800  bg-primaryColor"
              }`}
            >
              <div className="p-2 w-full">{children}</div>
            </div>
          </Transition>
        </ClickAwayListener>
      </div>
    </div>
  );
}
