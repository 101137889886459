import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAttributeValueData } from "../../Services/attributeValueService";

export const fetchAttributeValueData = createAsyncThunk("attributeValue/fetchData", async () => {
  const response = await getAttributeValueData()
  return response.data.data.attribute_value
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const attributeValueSlice = createSlice({
  name: "attributeValue",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAttributeValueData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchAttributeValueData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchAttributeValueData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getAttributeValueState = state => state.attributeValueData

export default attributeValueSlice.reducer