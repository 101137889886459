import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductDetailsSkeleton = () => {
  return (
    <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-3 mx-auto mt-10">
      <div className="flex items-start gap-[42px] lg:flex-row flex-col mb-[21px]">
        <div className="lg:w-[50%] w-full max-h-[800px] ">
          <div className="w-full sm:h-[500px] h-[400px] mb-4">
            <Skeleton height={"100%"} />
          </div>
          <div className="flex justify-center items-center gap-4">
            <div className="w-20 h-20">
              <Skeleton height={"100%"} />
            </div>
            <div className="w-20 h-20">
              <Skeleton height={"100%"} />
            </div>
            <div className="w-20 h-20">
              <Skeleton height={"100%"} />
            </div>
            <div className="w-20 h-20 hidden md:block">
              <Skeleton height={"100%"} />
            </div>
            <div className="w-20 h-20 hidden md:block">
              <Skeleton height={"100%"} />
            </div>
            <div className="w-20 h-20 hidden md:block">
              <Skeleton height={"100%"} />
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] w-full">
          <div className="flex items-start justify-between mb-[13px]">
            <Skeleton height={40} width={300} />
            <Skeleton circle height={20} width={20} />
          </div>
          <div className="lg:mb-6 mb-3 flex flex-col gap-1">
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} width={"75%"} />
          </div>
          <div className="mb-4">
            <Skeleton height={30} width={250} />
          </div>
          <div className="mb-9">
            <div className="mb-4">
              <div className="flex justify-start items-center gap-2 mb-1">
                <Skeleton height={20} width={80} />
                <Skeleton height={20} width={180} />
              </div>
              <div className="flex flex-wrap gap-2">
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
              </div>
            </div>
            <div>
              <div className="flex justify-start items-center gap-2 mb-1">
                <Skeleton height={20} width={80} />
                <Skeleton height={20} width={180} />
              </div>
              <div className="flex flex-wrap gap-2">
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
                <Skeleton height={50} width={50} />
              </div>
            </div>
            <div className="mt-4">
              <Skeleton height={20} width={150} />
              <div className="flex flex-wrap gap-2">
                <Skeleton height={40} width={40} />
                <Skeleton height={40} width={40} />
                <Skeleton height={40} width={40} />
                <Skeleton height={40} width={40} />
                <Skeleton height={40} width={40} />
              </div>
              <Skeleton height={10} width={60} />
            </div>
          </div>
          <div className="w-full py-[6px]">
            <Skeleton height={40} />
          </div>
        </div>
      </div>
      <div className="flex items-start justify-end mb-[40px] md:flex-row flex-col gap-14">
        <div className="lg:w-[50%] w-full">
          <div className=" flex items-center lg:justify-start justify-center gap-[20px]">
            <Skeleton circle height={30} width={30} />
            <Skeleton height={25} width={280} />
          </div>
          <div className="flex items-center lg:justify-start justify-center gap-[20px]">
            <Skeleton circle height={30} width={30} />
            <Skeleton height={25} width={280} />
          </div>
          <div className="flex items-center lg:justify-start justify-center gap-[20px]">
            <Skeleton circle height={30} width={30} />
            <Skeleton height={25} width={280} />
          </div>
          <div className="flex items-center lg:justify-start justify-center gap-[20px]">
            <Skeleton circle height={30} width={30} />
            <Skeleton height={25} width={280} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSkeleton;
