import React, { Fragment } from 'react'
import AdminModal from '../common/AdminModal'
import TextInputAdmin from '../common/TextInputAdmin'
import { useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { getAttributeValueByAttribute } from '../../../Services/attributeValueService'
import { useEffect } from 'react'

const AddMetalDemand = ({ isOpen, setIsOpen, handleAddDemand }) => {
  const [formData, setFormData] = useState({
    dcGrWt: "",
    dcNtWt: "",
    usd_g: "",
    amount: "",
    localCr: ""
  })
  const [selectedType, setSelectedType] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])

  useEffect(() => {
    if (isOpen) {
      fetchTypeOptions()
    }
  }, [isOpen])

  const handleSubmit = () => {
    const finalData = {
      ...formData,
      metal: selectedType?.typeName
    }
    handleAddDemand(finalData)
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
    setFormData({
      dcGrWt: "",
      dcNtWt: "",
      usd_g: "",
      amount: "",
      localCr: ""
    })
    setSelectedType(null)
  }

  const handleChangeData = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const fetchTypeOptions = async () => {
    try {
      const response = await getAttributeValueByAttribute(1);
      if (response.status === 200) {
        const options = response.data.data.attribute_value.map(item => {
          return {
            id: item.unAttributeValueIdentifier,
            typeName: item.stAttributeValueName
          }
        })
        setTypeOptions(options)
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  }

  const numberRegex = /^(\s*|\d+)$/;

  return (
    <AdminModal
      title={"Add Demand"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={handleClose}
      submitButtonText={"Add"}
    >
      <div className=" grid gap-x-4 gap-y-0 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="type"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Type
          </label>
          <Listbox value={selectedType} onChange={setSelectedType}>
            <div className="relative">
              <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                <span className="block truncate text-[14px]">
                  {selectedType ? selectedType?.typeName : "Select Shape"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-28 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {!!typeOptions?.length ?
                    typeOptions?.map((type, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                          }`
                        }
                        value={type}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? "font-medium" : "font-normal"
                                }`}
                            >
                              {type.typeName}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )) : (
                      <div className="text-center w-full">No data</div>
                    )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcGrWt"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Gross Weight
          </label>
          <TextInputAdmin
            onChange={e => {
              if (numberRegex.test(e.target.value)) {
                handleChangeData(e)
              }
            }}
            id="dcGrWt"
            value={formData.dcGrWt || ""}
            type="text"
            name="dcGrWt"
            placeholder="Enter Gross weight"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="dcNrWt"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Net Weight
          </label>
          <TextInputAdmin
            onChange={e => {
              if (numberRegex.test(e.target.value)) {
                handleChangeData(e)
              }
            }}
            id="dcNrWt"
            value={formData.dcNtWt || ""}
            type="text"
            name="dcNtWt"
            placeholder="Enter Net weight"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="usd_g"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            USD/g
          </label>
          <TextInputAdmin
            onChange={e => {
              if (numberRegex.test(e.target.value)) {
                handleChangeData(e)
              }
            }}
            id="usd_g"
            value={formData.usd_g || ""}
            type="text"
            name="usd_g"
            placeholder="USD per gram"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="usd/t"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            USD Ttl
          </label>
          <TextInputAdmin
            onChange={e => {
              if (numberRegex.test(e.target.value)) {
                handleChangeData(e)
              }
            }}
            id="usd/t"
            type="text"
            name="amount"
            value={formData.amount || ""}
            placeholder="Enter USD Ttl"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="localCr"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Local Currency
          </label>
          <TextInputAdmin
            onChange={e => {
              if (numberRegex.test(e.target.value)) {
                handleChangeData(e)
              }
            }}
            id="localCr"
            type="text"
            name="localCr"
            placeholder="Enter Local Currency"
          />
        </div>
      </div>
    </AdminModal>
  )
}

export default AddMetalDemand