import React, { Fragment, useEffect, useState } from "react";
import wichlist from "../../assets/images/wichlist.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";
import PDFImage from "../../assets/images/icons/pdf_primary.png";
import WhatsappImage from "../../assets/images/icons/whatsapp_primary.png";
import LinkImage from "../../assets/images/icons/link_primary.png";
import diamond from "../../assets/images/diamond.png";
import Calendar from "../../assets/images/Calendar.png";
import truck from "../../assets/images/truck.png";
import warranty from "../../assets/images/warranty.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../store/Slices/cartSlice";
import { toast } from "react-hot-toast";
import {
  fetchAttributeValueData,
  getAttributeValueState,
} from "../../store/Slices/attributeValueSlice";
import { designsVariationSearch } from "../../Services/designService";
import {
  getSelectedDiamondState,
  removeDiamond,
  selectDiamond,
} from "../../store/Slices/diamondSelectSlice";
import { useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy, MdOutlineFileDownload, Md360 } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { RiVideoLine } from "react-icons/ri";
import { CgPlayButtonO } from "react-icons/cg";
import NoDataText from "../shared/NoDataText";
import { tawkWidgetToggle } from "../../Utils";
import jsPDF from "jspdf";
import "jspdf-autotable";

const sizeArray = [4, 4.5, 5, 6, 6.5, 7, 7.5, 8];

export function downloadMedia(url) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getUrlFilename(url);
      link.click();
    });
}

export function getUrlFilename(url) {
  const urlParts = url.split('/');
  const filename = urlParts[urlParts.length - 1];
  return filename;
}

const ReadyToShipDetails = ({ product }) => {
  const [data, setData] = useState(null);
  const [mainImage, setMainImage] = useState(product?.stDefImageURL);
  const [size, setSize] = useState(sizeArray[0]);
  const [showMore, setShowMore] = useState(false);
  const [showEngraving, setShowEngraving] = useState(false);
  const [addEngraving, setAddEngraving] = useState("");
  const [engraving, setEngraving] = useState("");
  const [selectedVariation, setSelectedVariation] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const { data: selectedDiamond } = useSelector(getSelectedDiamondState);
  const { user: userData } = useSelector((state) => state.authData);

  const tawkTo = require("tawkto-react");
  const tawkToPropertyId = "64644842ad80445890ed5f0b";
  const tawkToKey = "1h0jrkhog";

  useEffect(() => {
    setSelectedVariation(null);
  }, [location]);

  useEffect(() => {
    if (product?.attribute) {
      dispatch(fetchAttributeValueData());
    }
  }, [dispatch, product]);
  useEffect(() => {
    if (state) {
      setData(state.attrData);
    }
  }, [state]);

  const images = useMemo(() => {
    if (product) {
      const productImage =
        product?.images?.length > 0
          ? product?.images?.map((item) => {
              return {
                id: item?.SrNumber,
                img: item?.ImageURL,
              };
            })
          : [];

      return productImage;
    }
    return [];
  }, [product]);

  const getData = useCallback(() => {
    if (product?.designAttribute?.length) {
      product?.designAttribute.forEach((attr) => {
        const attrData = product.designAttributeValue.filter(
          (attrVal) => attrVal.unAttributeId === attr.unAttributeId
        );
        if (attrData?.length && !state?.attrData) {
          setData((prev) => ({
            ...prev,
            [attr?.stAttributeName]: {
              name: attrData?.[0]?.stAttributeValueName,
              id: attrData?.[0]?.unAttributeValueId,
            },
          }));
        }
      });
    }
  }, [product, state]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (images?.length) {
      setMainImage(images?.[0]);
    }
  }, [images]);

  useEffect(() => {
    if (data) {
      variationSearch();
    }
  }, [data]);

  const variationSearch = async () => {
    const payload = new FormData();
    const idsArr = Object.values(data).map((item) => item.id);
    payload.append("unDesignId", id);
    payload.append("stVariationKey", idsArr.join());
    const response = await designsVariationSearch(payload);
    if (response.data.success) {
      setSelectedVariation(response.data.data);
    }
  };

  const isSelectedDiamond = useMemo(() => {
    if (selectedDiamond?.length) {
      return (
        !!selectedDiamond.find((item) => item?.productId === id) &&
        selectedDiamond?.length === +product?.inMainStonesPcs
      );
    }
    return false;
  }, [selectedDiamond, id, product]);

  const productImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productMobileImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
  };

  const handleChangeShape = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetal = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleAddToCart = () => {
    const cartData = {
      product: {
        id: id,
        title: product?.StockName,
        price: productFinalPrice,
        image: product?.FocusImageURL,
        engraving: addEngraving,
        stockNo: product?.DesignRemark,
        attribute: product?.attribute,
        details: product?.details,
        metal: product?.metal,
        size,
        attributes: data,
      },
      total: Number(productFinalPrice),
    };
    dispatch(openCart(true));
    dispatch(addToCart(cartData));
    toast.success("Successfully Added to Cart.");
  };

  const handleRemoveDiamond = (stockId) => {
    const filterData =
      selectedDiamond.length > 0 &&
      selectedDiamond.filter((item) => item.StoneNo !== stockId);
    dispatch(removeDiamond(filterData));
  };

  const productPrice = useMemo(() => {
    if (selectedVariation) {
      return (
        parseInt(selectedVariation?.dcSalePrice) +
          parseInt(selectedVariation?.dcVariationPrice) ||
        parseInt(product?.dcSalePrice)
      );
    }
    return parseInt(product?.dcSalePrice) || 0;
  }, [selectedVariation, product]);

  const productDescription = useMemo(() => {
    return product?.DesignLongRemark || "";
  }, [product]);

  const variationDescription = useMemo(() => {
    if (selectedVariation?.stDescription) {
      return selectedVariation?.stDescription;
    }
  }, [selectedVariation]);

  const stonePrice = useMemo(() => {
    if (selectedDiamond?.length && isSelectedDiamond) {
      return selectedDiamond.reduce(
        (acc, cur) => acc + parseInt(cur.Amount),
        0
      );
    }
    return 0;
  }, [selectedDiamond, isSelectedDiamond]);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF("l", "mm", "a5");

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageBackgroundColor = "#FDFFFB"; // Set your desired page background color here

    // Set page background color
    doc.setFillColor(pageBackgroundColor);
    doc.rect(0, 0, pageWidth, pageHeight, "F");

    // Add border to the entire page
    doc.setDrawColor("gray"); // Border color (black in this case)
    doc.setLineWidth(0.3); // Border width
    doc.rect(
      2,
      2,
      doc.internal.pageSize.width - 4,
      doc.internal.pageSize.height - 4,
      "S"
    ); // 'S' option for stroke

    // Set text properties
    doc.setTextColor("#707070");
    doc.setFontSize(10);

    // Set text to be centered in the row
    const categoryTitle = "Ready To Ship";
    const categoryTitleWidth =
      (doc.getStringUnitWidth(categoryTitle) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - categoryTitleWidth) / 2;

    // Add text to the PDF document
    doc.text(textX, 7, categoryTitle);

    doc.setTextColor("#1c1c1c");
    doc.setFontSize(16);

    // Set text to be centered in the row
    const headerLable = "DTN Premium Quality LAB-GROWN DIAMONDS";
    const headerLableWidth =
      (doc.getStringUnitWidth(headerLable) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headerLableWidthX = (pageWidth - headerLableWidth) / 2;

    // Add a header to the PDF
    doc.text(headerLableWidthX, 18, headerLable);

    // Add a link to Google in the PDF
    doc.setFontSize(10);

    let rowIndex = 0;

    images?.map((item, i) => {
      if (!item?.img?.endsWith(".mp4") && i < 4) {
        if (i % 2 === 0) {
          rowIndex++;
        }

        doc.addImage(
          item?.img,
          "PNG",
          i % 2 === 0 ? 5 : 47,
          rowIndex === 1 ? 30 : 72 * (rowIndex - 1),
          40,
          40
        );
      }
    });

    let videoURL = images?.find((item) => item?.img?.endsWith(".mp4"))?.img;

    if (videoURL) {
      doc.textWithLink("Click to view Video", 5, 118.5, {
        url: videoURL,
      });
      doc.text("_______________", 5, 119);
    }

    const columns = ["", ""];

    // Define the data for the auto-table

    const bodydata = [
      [
        { content: "Stock Name", styles: { fontStyle: "bold" } },
        product?.StockName,
      ],
      [
        { content: "Stock No", styles: { fontStyle: "bold" } },
        product?.StockNo,
      ],
      [
        { content: "Shape", styles: { fontStyle: "bold" } },
        product?.details?.[0]?.Shape,
      ],
      [
        { content: "Total Wt & Pcs", styles: { fontStyle: "bold" } },
        `${product?.details?.[0]?.Carat}ct / ${product?.details?.[0]?.Pcs}pcs`,
      ],
    ];

    // Define alternative row colors
    const rowColors = {
      odd: [245, 245, 245], // Light gray color for odd rows
      even: [255, 255, 255], // White color for even rows
    };

    // Define a function to apply row colors based on the row index
    const applyRowColors = (dataList) => {
      dataList.forEach((row, index) => {
        if (index % 2 === 0) {
          // Even rows
          row.styles.fillColor = rowColors.even;
        } else {
          // Odd rows
          row.styles.fillColor = rowColors.odd;
        }
      });
    };

    doc.autoTable({
      startY: 30,
      margin: { left: 90 },
      //  head: [columns],
      body: bodydata,
      // didDrawCell: applyRowColors,
      theme: "plain", // Optional: 'striped', 'plain', 'grid' (default)
      styles: { lineWidth: 0.2 }, // Set border width
      columnStyles: {
        0: { columnWidth: 54, fillColor: "#FAFAFA" },
        1: { columnWidth: 60 },
      },
    });

    doc.save(`Ready To Ship ${product?.StockNo || ""}`);
  };

  const productFinalPrice = useMemo(() => {
    return (
      +product?.SalePrice +
      (product?.attribute?.find((item) => +item?.dcPrice !== 0)?.dcPrice || 0) *
        (+product?.NetWt || 0)
    )?.toFixed(2);
  }, [product]);

  return (
    <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-[20px] mx-auto mt-4">
      <div className="flex items-start lg:flex-row flex-col mb-[21px] grid sm:grid-cols-12 gap-[42px]">
        <div className="lg:col-span-5 col-span-full xl:w-[495px] lg:w-[410px] w-full lg:max-h-[800px] max-h-full lg:h-full h-auto sideImages">
          {mainImage?.img?.endsWith("mp4") ||
          mainImage?.img?.endsWith("mov") ? (
            <video
              src={mainImage?.img}
              alt="Product"
              className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 border-[#e2e8f0]"
              autoPlay
              muted
              loop
            ></video>
          ) : mainImage?.img ? (
            <img
              src={mainImage?.img}
              alt="Product"
              className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 border-[#e2e8f0]"
            />
          ) : (
            <NoDataText text={"Image not found"} height="450px" />
          )}
          {/* <img
            src={mainImage}
            alt="Product"
            className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 rounded-md border-[#e2e8f0]"
          /> */}
          <div className="my-4 w-full bottomImg p-3 pt-0 mt-2">
            {!!images?.length && (
              <Slider
                {...productImageSettings}
                className="details_page max-w-[100%]"
              >
                {images
                  .sort((a, b) => a.id - b.id)
                  .map((item, i) => {
                    return (
                      <div
                        key={`desktop-${i}`}
                        className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                      >
                        {item?.img?.endsWith("mp4") ||
                        item?.img?.endsWith("mov") ? (
                          <div className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50">
                            <CgPlayButtonO
                              className="text-[20px]  w-[30px] h-[30px] object-cover border-1 border-[#e2e8f0]"
                              onClick={() => setMainImage(item)}
                            />
                          </div>
                        ) : (
                          <img
                            onClick={() => setMainImage(item)}
                            src={item?.img}
                            alt="Product"
                            className=" w-full h-full object-cover border-1 border-[#e2e8f0]"
                          />
                        )}
                        {/* <img
                        onClick={() => setMainImage(item)}
                        src={item}
                        alt="Product"
                        className=" w-full h-full object-cover border-0 rounded-md border-[#e2e8f0]"
                      /> */}
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
          <div className="lg:hidden block col-span-full">
            <Slider
              {...productMobileImageSettings}
              className="details_page max-w-[100%] w-[325px] h-[338px] mx-auto flex items-center shadow-lg"
            >
              {images.map((item, i) => {
                return (
                  <div
                    key={`mobile-${i}`}
                    className="details__image max-h-[330px] overflow-hidden"
                  >
                    {item?.img?.endsWith("mp4") ||
                    item?.img?.endsWith("mov") ? (
                      <video
                        src={item?.img}
                        alt="Product"
                        className="m-auto w-full h-full object-cover bannerImg border-0 border-[#e2e8f0]"
                        autoPlay
                        muted
                        loop
                      ></video>
                    ) : item?.img ? (
                      <img
                        src={item?.img}
                        alt="Product"
                        className="h-full w-full object-cover object-center sm:border-0 border-0"
                      />
                    ) : (
                      <NoDataText text={"Image not found"} height="full" />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        <div className="lg:col-span-7 col-span-full w-full">
          <div className="flex items-start justify-between mb-[0px]">
            <h2 className="md:text-[30px] text-[24px] lg:leading-[43px] md:leading-[38px] leading-[32px] font-[400] text-[#000] font-playfair">
              {product?.StockName}{" "}
              <span className="lg:text-[20px] text-[15px] lg:leading-[24px] leading-[20px] text-[#6A6A6A] font-normal">
                {product?.StockNo && `(SKU: ${product?.StockNo})`}
              </span>
            </h2>
          </div>
          {/* <p className="lg:text-[18px] text-[14px] lg:leading-[24px] leading-[20px] py-1 pt-0 text-[#6A6A6A] font-normal">
            {product?.StockNo}
          </p> */}

          {(userData?.Role === "User" || !userData?.Role) ? (
            <p
              class="lg:text-[20px] text-[16px] text-left w-[40%] font-[400] underline text-gray-700 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                new tawkTo(tawkToPropertyId, tawkToKey);
                tawkWidgetToggle(true);
              }}
            >
              On request
            </p>
          ) : (
            <h3 className="lg:text-[25px] text-[18px] lg:leading-[36px] leading-[30px] text-[#295268] font-[700] my-3 flex items-center gap-2">
              US ${productFinalPrice}
              <span className="text-[16px] font-[400] text-[#999999]">
                (Total Price)
              </span>
            </h3>
          )}

          {product?.DesignRemark && (
            <p className="lg:text-[16px] xl:text-[16px] text-[12px] lg:leading-[24px] leading-[20px] py-1 text-[#6A6A6A] font-normal">
              {product?.DesignRemark}
            </p>
          )}

          {/* <div className="py-1 border-b-2 border-gray-700 w-[75%] md:w-[40%] lg:w-[50%] xl:w-[40%] mt-[5px]"></div> */}

          <div className="mt-4 flex flex-col gap-3">
            {product?.details?.length > 0 && (
              <div class="flex items-center">
                <div class="border-l-4 border-gray-700 h-6"></div>
                <h1 class="ml-2 text-xl font-[500]">
                  Lab Grown Diamond Details
                </h1>
              </div>
            )}
            {product?.details?.length > 0 &&
              product?.details.map((item, i) => {
                return (
                  <Fragment key={i}>
                    {item.StockJvType?.toLowerCase() === "main" ? (
                      <>
                        <h5 className="font-bold italic">Center Stone</h5>
                        <ul className="mb-2 list-none ">
                          <li className="text-[16px]">
                            <span className="font-[500] text-[#171717]">
                              Shape & Size:{" "}
                            </span>{" "}
                            <span className="text-[#6A6A6A]">
                              {item?.Shape} {item?.Carat?.toFixed(2)}ct
                            </span>
                          </li>
                          <li className="text-[16px]">
                            <span className="font-[500] text-[#171717]">
                              Grading:
                            </span>{" "}
                            <span className="mr-2 text-[#6A6A6A]">
                              {item?.Colour} | {item?.Clarity} | EX/EX
                            </span>{" "}
                          </li>
                          {Number(item?.Depth) > 0 && (
                            <li className="text-[16px]">
                              <span className="font-[500] text-[#171717]">
                                Measurements:
                              </span>{" "}
                              <span className="text-[#6A6A6A]">
                                {item?.Width} x {item?.Length} x {item?.Depth}
                              </span>
                            </li>
                          )}
                          {item?.LAB && (
                            <li className="text-[16px]">
                              <span className="font-[500]">Certificate:</span>{" "}
                              <span className="text-[#6A6A6A]">
                                {item?.LAB}{" "}
                                {item?.CertiId && ` - ${item?.CertiId}`}
                              </span>
                            </li>
                          )}
                        </ul>
                      </>
                    ) : (
                      <>
                        {item.CertiId?.toLowerCase() === "side" ? (
                          <>
                            <h5 className="font-bold italic">Side Stones</h5>
                            <ul className="mb-2 list-none ">
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Shape:{" "}
                                </span>{" "}
                                <span className="text-[#6A6A6A]">
                                  {item?.Shape}
                                </span>
                              </li>
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Total Wt & Pcs:
                                </span>{" "}
                                <span className="mr-2 text-[#6A6A6A]">
                                  {item?.Carat}ct / {item?.Pcs}pcs
                                </span>{" "}
                              </li>
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Grading:
                                </span>{" "}
                                <span className="mr-2 text-[#6A6A6A]">
                                  {item?.Colour} / {item?.Clarity}
                                </span>{" "}
                              </li>
                            </ul>
                          </>
                        ) : item.CertiId?.toLowerCase() === "accent" ? (
                          <>
                            <h5 className="font-bold italic">Accent Stones</h5>
                            <ul className="mb-2 list-none ">
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Shape:{" "}
                                </span>{" "}
                                <span className="text-[#6A6A6A]">
                                  {item?.Shape}
                                </span>
                              </li>
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Total Wt & Pcs:
                                </span>{" "}
                                <span className="mr-2 text-[#6A6A6A]">
                                  {item?.Carat}ct / {item?.Pcs}pcs
                                </span>{" "}
                              </li>
                              <li className="text-[16px]">
                                <span className="font-[500] text-[#171717]">
                                  Grading:
                                </span>{" "}
                                <span className="mr-2 text-[#6A6A6A]">
                                  {item?.Colour} / {item?.Clarity}
                                </span>{" "}
                              </li>
                            </ul>
                          </>
                        ) : (
                          <ul className="mb-2 list-none flex flex-col gap-y-1">
                            <li className="text-[16px]">
                              <span className="font-[500] text-[#171717]">
                                Shape:{" "}
                              </span>{" "}
                              <span className="text-[#6A6A6A]">
                                {item?.Shape}
                              </span>
                            </li>
                            <li className="text-[16px]">
                              <span className="font-[500] text-[#171717]">
                                Total Wt & Pcs:
                              </span>{" "}
                              <span className="mr-2 text-[#6A6A6A]">
                                {item?.Carat}ct / {item?.Pcs}pcs
                              </span>{" "}
                            </li>
                            <li className="text-[16px]">
                              <span className="font-[500] text-[#171717]">
                                Grading:
                              </span>{" "}
                              <span className="mr-2 text-[#6A6A6A]">
                                {item?.Colour} / {item?.Clarity}
                              </span>{" "}
                            </li>
                          </ul>
                        )}
                      </>
                    )}
                  </Fragment>
                );
              })}
          </div>

          <div className="mt-4 flex flex-col gap-3">
            <div class="flex items-center">
              <div class="border-l-4 border-gray-700 h-6"></div>
              <h1 class="ml-2 text-xl font-[500]">Setting Details</h1>
            </div>
            <div className="sm:mb-6 mb-4 flex flex-col gap-y-1">
              {product?.attribute?.length &&
                product?.attribute.map((item, i) => {
                  return (
                    <ul className="list-none flex flex-col gap-1">
                      <li className="text-[16px] font-[400] text-[#000]">
                        <span className="font-[500] text-[#171717]">
                          {item.Attribute.stAttributeStock}
                        </span>
                        :{" "}
                        <span className="text-[#6A6A6A]">
                          {item.AttributeValue}
                        </span>
                        {Number(product.GrossWt) &&
                        item.Attribute.inSrNumber === 1 ? (
                          <>
                            <br />
                            <span className="font-[500] text-[#171717]">{`Gr.Wt: `}</span>
                            <span className="text-[#6A6A6A]">
                              {product.GrossWt}g
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {/* {item.Attribute.inSrNumber === 1 ? (product.GrossWt !== "0" ? (product.GrossWt) : "") :""} */}
                        {Number(product.NetWt) &&
                        item.Attribute.inSrNumber === 1 ? (
                          <>
                            {!Number(product.GrossWt) && <br />}
                            <span className="font-[500] text-[#171717]">{` Nt.Wt: `}</span>
                            <span className="text-[#6A6A6A]">
                              {product.NetWt}g
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {/* {item.Attribute.inSrNumber === 1 ? (product.NetWt   !== 0 ? (product.NetWt) : "") :""} */}
                        {/* <span className="font-[300]">
                        {data?.[item.stAttributeName]?.name}
                      </span> */}
                      </li>
                    </ul>
                  );
                })}
              {/* <h3 className="lg:text-[25px] text-[18px] lg:leading-[36px] leading-[30px] text-[#000] font-[400] my-3 flex items-center gap-2">
              US$ {productFinalPrice}
              <span className="text-[14px] font-[400]">(Total Price)</span>
            </h3> */}
            </div>
          </div>

          <div className="hidden md:block">
            {(userData?.Role !== "User" && userData?.Role) && <button
              onClick={handleAddToCart}
              className="w-full block text-center font-sans tracking-[1px] bg-[#334155] text-[#fff] text-[14px] leading-[32px] py-[6px] font-[600]"
            >
              ADD TO CART
            </button>}
            <button
              onClick={() => {
                navigate("/make-own-design", {
                  state: {
                    data: {
                      metal: product?.attribute?.find(
                        (obj) =>
                          obj?.Attribute?.stAttributeName ===
                          "Select Precious Metal"
                      )?.AttributeValue,
                      centerStoneShape: product?.AttributeValueShapeId,
                      ringSize: product?.attribute?.find(
                        (obj) => obj?.Attribute?.stAttributeName === "Ring Size"
                      )?.AttributeValue,
                      images: images
                        ?.filter((obj) => !obj?.img?.endsWith(".mp4"))
                        ?.map((obj) => obj?.img),
                      category: product?.CategoryName,
                      backURL: window.location.pathname,
                    },
                  },
                });
              }}
              className="w-full block text-center font-sans tracking-[1px] bg-[#E9E9E9] text-black text-[14px] leading-[32px] py-[6px] font-[600] mt-3"
            >
              CUSTOMIZE FOR ME
            </button>
          </div>

          <div class="md:hidden fixed bottom-0 left-0 right-0 flex">
            <button
              onClick={() => {
                navigate("/make-own-design", {
                  state: {
                    data: {
                      metal: product?.attribute?.find(
                        (obj) =>
                          obj?.Attribute?.stAttributeName ===
                          "Select Precious Metal"
                      )?.AttributeValue,
                      centerStoneShape: product?.AttributeValueShapeId,
                      ringSize: product?.attribute?.find(
                        (obj) => obj?.Attribute?.stAttributeName === "Ring Size"
                      )?.AttributeValue,
                      images: images
                        ?.filter((obj) => !obj?.img?.endsWith(".mp4"))
                        ?.map((obj) => obj?.img),
                      category: product?.CategoryName,
                      backURL: window.location.pathname,
                    },
                  },
                });
              }}
              class={`${(userData?.Role !== "User" && userData?.Role) ? 'w-1/2' : 'w-full'} block text-center font-sans tracking-[1px] bg-[#E9E9E9] text-black text-[14px] leading-[32px] py-[6px] font-[600]`}
            >
              CUSTOMIZE FOR ME
            </button>
            {(userData?.Role !== "User" && userData?.Role) && <button
              onClick={handleAddToCart}
              class="w-1/2 block text-center font-sans tracking-[1px] bg-[#334155] text-[#fff] text-[14px] leading-[32px] py-[6px] font-[600]"
            >
              ADD TO CART
            </button>}
          </div>

          <div className="flex md:flex-nowrap justify-center md:justify-start gap-y-2 mt-3 gap-x-2 mb-11 md:mb-0">
            <button
              onClick={() => {
                downloadPDF();
              }}
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
              >
                <img src={PDFImage} className="w-4 h-4 md:w-5 md:h-5 mr-2" />
                PDF
              </button>
            </button>

            {/* <a
              href={`https://wa.me/?text=${window.location.href}`}
              target="_blank"
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
            >
              <button class="inline-flex w-full hover:bg-gray-200" role="group">
                <button
                  type="button"
                  class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
                >
                  <img
                    src={WhatsappImage}
                    className="w-4 h-4 md:w-5 md:h-5 mr-2"
                  />
                  Whatsapp
                </button>
              </button>
            </a> */}

            <button
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success("Copied..");
              }}
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
              >
                <img src={LinkImage} className="w-4 h-4 md:w-5 md:h-5 mr-2" />
                Share
              </button>
            </button>

            <button
              onClick={() => {
                images.forEach((image, index) => {
                  downloadMedia(image.img);
                });
              }}
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
              >
                <MdOutlineFileDownload className="w-5 h-5 md:w-6 md:h-6 mr-2 text-[#334155]" />
                Download
              </button>
            </button>
          </div>

          <p
            dangerouslySetInnerHTML={{ __html: productDescription }}
            className="hidden lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] py-[5px] px-[12px] shadow-xl text-[#000] font-[300] lg:my-4 my-3"
          ></p>

          {/* <h3 className="pt-3 pb-4 text-[20px] font-playfair">
            Included with every purchase
          </h3> */}
          <div>
            {/* <ul className="list-disc ml-[20px]">
              <li className="text-[14px] font-[400] text-[#000]">
                Lifetime Warranty
              </li>
              <li className="text-[14px] font-[400] text-[#000]">
                Professional Appraisal
              </li>
              <li className="text-[14px] font-[400] text-[#000]">
                Lab Grown Diamond Certificate
              </li>
              <li className="text-[14px] font-[400] text-[#000]">
                Complimentary resizing (Up to 1.5 finger size difference)
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyToShipDetails;
