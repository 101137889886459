import React from "react";
import MainHeader from "../Components/LandingComponent/MainHeader";
import HomeSlider from "../Components/LandingComponent/HomeSlider";
import Aboutus from "../Components/LandingComponent/Aboutus";
import MainFooter from "../Components/LandingComponent/MainFooter";

const Home = () => {
  return (
    <>
      <MainHeader />
      <HomeSlider />
      <Aboutus />
      <MainFooter />
    </>
  );
};

export default Home;
