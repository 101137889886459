import React from "react";
import slider1 from "../../assets/images/slider1.svg";
import mobileSlider2 from "../../assets/images/mobileSlider2.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

const HomeSlider = () => {
  const navigate = useNavigate();

  return (
    <div className="slideHome lg:mb-[150px] mb-[60px]">
      <div className="sm:px-0">
        <div className="relative xl:bg-[#024C79] h-[360px] md:max-h-[523px] md:h-full bannertext max-h-auto">
          <img
            src={slider1}
            className="deskImage absolute top-0 right-0 ml-auto xl:pt-[71px] pt-0 xl:max-w-[755px] max-w-full xl:w-auto w-full h-auto sm:object-cover object-center"
            alt="Slide 1"
          />
          <img
            src={mobileSlider2}
            className="w-full lg:h-[600px] md:h-[500px] h-[367px] absolute top-0 right-0 mobImage sm:object-cover sm:object-center object-cover xl:z-50 -z-10"
            alt="Slide 1"
          />
          <div className="xl:max-w-[1232px] max-w-[90%] w-full mx-auto xl:px-0 px-5 sm:mt-0 -mt-[25px] md:block xl:py-[5px] lg:py-10 py-6 hidden z-40 relative">
            <div className="xl:bg-transparent bg-[#024c79] rounded xl:px-0 py-16 sm:px-0 px-[14px] bannertext w-full xl:max-w-[480px] sm:max-w-[500px] max-w-[90%] z-50 xl:mx-0 mx-auto">
              {/* <div className="sm:absolute static hidden xl:bg-transparent bg-[#024c79] rounded xl:p-0 p-4 pt-[17px] bottom-[50%] sm:block transform sm:translate-y-[50%] sm:mt-0 sm:px-0 px-[14px] 2xl:left-[110px] lg:left-[40px] sm:left-[50%] bannertext lg:translate-x-[0] sm:-translate-x-[50%] w-full xl:max-w-[480px] sm:max-w-[500px] max-w-[90%] mx-auto -mt-[25px] z-50"> */}
              <div className="md:py-4 sm:py-2 py-1 rounded sm:mb-2 mb-0">
                <h1 className="xl:text-[39px] lg:text-[32px] sm:text-[28px] text-[20px] text-[#fff] xl:px-0 px-6 sm:leading-8 leading-5 lg:mb-[31px] sm:mb-[20px] mb-[16px] md:text-[30px] font-montserrat text-center sm:text-start font-[600]">
                  Nothing but Excellence
                </h1>
                <div className="md:text-[20px] text-[16px] font-Josefin xl:px-0 bannerblock sm:px-6 text-sm font-[300] sm:text-justify text-center sm:leading-7 text-[#fff]">
                  <p className="mb-6 sm:mb-[14px] sm:px-0 px-6 break-normal text-justify">
                    Decades of experience in diamond industry and close work
                    with rough growers for years, DTN understands it all when it
                    comes to lab-grown diamonds
                  </p>
                  <div className="sm:hidden flex flex-col items-center gap-3 mb-6 font-Josefin xl:px-0 px-6 text-white">
                    <button
                      type="button"
                      onClick={() => navigate("/diamonds")}
                      className="main-page-btn h-11 w-[50%] border border-white shadow text-base font-[400] relative hover:text-[#024C79]"
                    >
                      <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                      Diamonds
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate("/jewelry")}
                      className="main-page-btn h-11 w-[50%] border border-white shadow text-base font-[400] relative hover:text-[#024C79]"
                    >
                      <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                      Jewelry
                    </button>
                  </div>
                  <p className="mb-6 sm:mb-[13px] sm:px-0 px-6 break-normal text-justify">
                    But that is not enough, when lab-grown diamonds have become
                    more available and affordable, DTN differs itself by
                    committing to excellence. You will find nothing but the best
                    here - the top quality diamonds and most fine jewelries. And
                    even better, at the best price in the market!
                  </p>
                </div>
              </div>
              {/* <div className="hidden sm:flex items-center gap-4 mb-2 font-Josefin xl:px-0 px-6 text-white buttonblock">
                <button
                  type="button"
                  onClick={() => navigate("/diamonds")}
                  className="main-page-btn h-11 w-[50%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                  Diamonds
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/jewelry")}
                  className="main-page-btn h-11 w-[50%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                  Jewelry
                </button>
              </div> */}

              <div class="grid grid-cols-1 gap-4 md:px-6 xl:p-0 md:py-1 xl:py-0 text-white buttonblock place-items-center">
                <button
                  type="button"
                  onClick={() => navigate("/home")}
                  className="main-page-btn h-11 w-[80%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  OUR LAB DIAMONDS COLLECTION
                </button>
              </div>

              <div class="hidden grid grid-cols-2 gap-4 md:px-6 xl:p-0 md:py-1 xl:py-0 text-white buttonblock">
                <button
                  type="button"
                  onClick={() => navigate("/diamonds")}
                  className="main-page-btn h-11 w-[100%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  {/* <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span> */}
                  Diamonds
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/jewelry")}
                  className="main-page-btn h-11 w-[100%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  {/* <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span> */}
                  Jewelry
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/calibrated")}
                  className="main-page-btn h-11 w-[100%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  {/* <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span> */}
                  Calibrated
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/mounting")}
                  className="main-page-btn h-11 w-[100%] border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79] transition-all duration-500"
                >
                  {/* <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span> */}
                  Mounting
                </button>
              </div>
            </div>
          </div>
          {/* Mobile */}
          <div className="xl:bg-transparent bg-[#024c79] rounded w-full xl:max-w-[480px] py-4 md:hidden absolute textContent left-0 right-0 block sm:max-w-[500px] max-w-[90%] mx-auto">
            <div className="md:py-4 sm:py-2 py-1 rounded sm:mb-2 mb-0">
              <h1 className="xl:text-[39px] lg:text-[32px] sm:text-[28px] text-[20px] text-[#fff] xl:px-0 px-6 sm:leading-8 leading-5 lg:mb-[31px] sm:mb-[20px] mb-[16px] md:text-[30px] font-montserrat text-center sm:text-start font-[600]">
                Nothing but Excellence
              </h1>
              <div className="md:text-[20px] text-[16px] font-Josefin xl:px-0 bannerblock sm:px-6 text-sm font-[300] sm:text-justify text-center sm:leading-7 text-[#fff]">
                <div className="hidden mt-6 sm:hidden flex flex-col items-center gap-3 mb-6 font-Josefin xl:px-0 px-6 text-white">
                  <button
                    type="button"
                    onClick={() => navigate("/diamonds")}
                    className="main-page-btn h-11 w-full border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79]"
                  >
                    <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                    Diamonds
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/jewelry")}
                    className="main-page-btn h-11 w-full border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79]"
                  >
                    <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                    Jewelry
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/calibrated")}
                    className="main-page-btn h-11 w-full border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79]"
                  >
                    <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                    Calibrated
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/mounting")}
                    className="main-page-btn h-11 w-full border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79]"
                  >
                    <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                    Mounting
                  </button>
                </div>
                <p className=" sm:mb-[14px] sm:px-0 px-6 break-normal text-justify">
                  Decades of experience in diamond industry and close work with
                  rough growers for years, DTN understands it all when it comes
                  to lab-grown diamonds
                </p>
                <p className="sm:mb-[13px] sm:px-0 px-6 break-normal text-justify">
                  But that is not enough, when lab-grown diamonds have become
                  more available and affordable, DTN differs itself by
                  committing to excellence. You will find nothing but the best
                  here - the top quality diamonds and most fine jewelries. And
                  even better, at the best price in the market!
                </p>
              </div>
            </div>

            <div className="mt-6 sm:hidden flex flex-col items-center gap-3 mb-6 font-Josefin xl:px-0 px-6 text-white">
              <button
                type="button"
                onClick={() => navigate("/home")}
                className="main-page-btn h-11 w-full border border-white shadow text-base font-[400] relative hover:bg-white hover:text-[#024C79]"
              >
                <span className="btn-hover-left-right absolute bg-[#fff] top-0 left-0 w-0 h-full opacity-0"></span>
                OUR LAB DIAMONDS COLLECTION
              </button>
            </div>

            <div className="hidden sm:grid items-center sm:grid-cols-2 gap-4 mb-2 font-Josefin xl:px-0 px-6 text-white buttonblock">
              <button
                type="button"
                onClick={() => navigate("/diamonds")}
                className="h-11 w-full border border-white shadow text-base font-[400]"
              >
                Diamonds
              </button>
              <button
                type="button"
                onClick={() => navigate("/jewelry")}
                className="h-11 w-full border border-white shadow text-base font-[400]"
              >
                Jewelry
              </button>
              <button
                type="button"
                onClick={() => navigate("/calibrated")}
                className="h-11 w-full border border-white shadow text-base font-[400]"
              >
                Calibrated
              </button>
              <button
                type="button"
                onClick={() => navigate("/mounting")}
                className="h-11 w-full border border-white shadow text-base font-[400]"
              >
                Mounting
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;