import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../assets/images/logo.svg";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import Button from "../Components/LandingComponent/Common/Button";
import TextInput from "../Components/LandingComponent/Common/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, selectCartState } from "../store/Slices/cartSlice";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthState } from "../store/Slices/AuthSlice";
import { placeOrder } from "../Services/orderService";
import { toast } from "react-hot-toast";
import { clearCartAction } from "../store/actions";
import Loader from "../Components/Admin/common/Loader";
import { MdOutlineClose } from "react-icons/md";

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [formData, setFormData] = useState({
    Address1: "",
    Address2: "",
    Address3: "",
    PinCode: "",
    City: "",
    State: "",
    Country: "",
    PhoneNo: "",
  });
  const { cart } = useSelector(selectCartState);
  const { user } = useSelector(getAuthState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const totalPrice = useMemo(
    () => cart.reduce((acc, cur) => acc + Number(cur.total), 0),
    [cart]
  );

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!!!cart?.length) {
      navigate("/home");
    }
  }, [cart, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (
      formData.Address1 === "" ||
      formData.Address2 === "" ||
      formData.City === "" ||
      formData.Country === "" ||
      formData.PhoneNo === "" ||
      formData.PinCode === "" ||
      formData.State === ""
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [formData]);

  const handlePlaceOrder = async () => {
    if (
      formData.Address1 !== "" &&
      formData.Address2 !== "" &&
      formData.City !== "" &&
      formData.Country !== "" &&
      formData.PhoneNo !== "" &&
      formData.PinCode !== "" &&
      formData.State !== ""
    ) {
      const settingsData = cart.map((item) => {
        return {
          DesignVariationId: item.product.DesignVariationId
            ? item.product.DesignVariationId
            : null,
          Attributes: item.product.attributes,
          Qty: 1,
          Amount: item.product.price,
          Size: item.product.size,
          EngravingFont: item.product.engraving,
          remark: {
            Name: item.product.title,
            Shape: item.product.attributes?.Shape?.name,
            Band: item.product.attributes?.Band?.name,
            Metal: item.product.attributes?.Metal?.name,
          },
          diamond: item.diamond.map((dia) => {
            return {
              // DesignVariationId: item.product.DesignVariationId ? item.product.DesignVariationId : null,
              StoneId: dia.STOCKNO,
              StoneNo: dia.StoneNo,
              Carat: dia.CARAT,
              Color: dia.COLOUR,
              Clarity: dia.CLARITY,
              Amount: dia.Amount,
              Qty: 1,
            };
          }),
        };
      });

      const diamondsData = [];
      const payload = new FormData();
      payload.append("UserId", user?.unUserIdentifier);
      payload.append("address", JSON.stringify(formData));
      payload.append("setting", JSON.stringify(settingsData));
      payload.append("diamond", JSON.stringify(diamondsData));

      try {
        setIsLoading(true);
        const response = await placeOrder(payload);
        if (response.data.success) {
          toast.success("Order placed.");
          dispatch(clearCartAction());
        }
      } catch (err) {
        console.log("Error: ", err);
        toast.error("Something went wrong.");
      } finally {
        setIsLoading(false);
        setIsDisable(true);
      }
    }
  };

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id));
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center ">
        <Loader />
      </div>
    );
  }

  const breadCrumbData = [
    {
      title: "Home",
      onClick: () => {
        navigate("/home");
      },
    },
    {
      title: "Checkout",
    },
  ];

  return (
    <>
      {/* <Header /> */}
      <div className="relative w-full min-h-[100vh] py-[20px] font-Josefin">
        <div className="absolute left-0 top-0 lg:w-[50%] h-full w-full headerBg z-[-1] border-r"></div>
        <div className="absolute right-0 top-0 lg:w-[50%] w-full h-full bg-[#FFFFFF] z-[-1]"></div>
        <div className="max-w-[1224px] mx-auto h-full">
          <div className="flex justify-center lg:flex-row flex-col h-full items-center relative">
            <div className="w-full lg:max-w-[50%] max-w-auto lg:mb-0 mb-[50px]">
              <div className="flex flex-col items-center justify-center gap-[13px] mb-[15px]">
                <span className="font-playfair uppercase text-[24px] font-bold text-[#1F2937]">
                  DTN jewelry
                </span>
                <Breadcrumb tabs={breadCrumbData} />
              </div>
              <div className="lg:pr-[38px] px-[38px]">
                <div className="mb-[8px]">
                  <h2 className="text-[20px] font-[600] leading-[27px] font-Josefin mb-3">
                    User Email information
                  </h2>
                  <div>
                    <TextInput
                      type="email"
                      placeholder="email"
                      name="email"
                      disabled={true}
                      className="mb-[8px] bg-white"
                      value={user?.stEmail}
                    />
                  </div>
                </div>
                <div className="mb-[20px]">
                  <h2 className="text-[20px] font-[600] leading-[27px] mb-[12px] font-Josefin ">
                    Shipping address
                  </h2>
                  <div className="flex justify-center items-center gap-2 mb-[13px]">
                    <TextInput
                      value={user?.stFirstName}
                      placeholder="First Name"
                      name="fname"
                      disabled={true}
                      className="bg-white"
                    />
                    <TextInput
                      value={user?.stLastName}
                      placeholder="Last Name"
                      name="lname"
                      disabled={true}
                      className="bg-white"
                    />
                  </div>

                  {/* <Dropdown
                    value={dropDownValue ? dropDownValue.label : "Country / region"}
                    onChange={handleChangeDropDown}
                    options={[{ label: "one" }, { label: "two" }, { label: "three" }]}
                    className="mb-[13px]"
                  /> */}
                  <TextInput
                    placeholder="Country"
                    name="Country"
                    className="mb-[13px] bg-white"
                    onChange={handleChange}
                    value={formData.Country}
                  />
                  <TextInput
                    placeholder="Address"
                    name="Address1"
                    className="mb-[13px] bg-white"
                    onChange={handleChange}
                    value={formData.Address1}
                  />
                  <TextInput
                    placeholder="Apartment, suite, etc. (optional)"
                    name="Address2"
                    className="mb-[13px] bg-white"
                    onChange={handleChange}
                    value={formData.Address2}
                  />
                  <div className="flex justify-center items-center gap-2 mb-[13px]">
                    <TextInput
                      placeholder="City"
                      name="City"
                      onChange={handleChange}
                      value={formData.City}
                      className="bg-white"
                    />
                    <TextInput
                      placeholder="State"
                      name="State"
                      onChange={handleChange}
                      value={formData.State}
                      className="bg-white"
                    />
                    {/* <Dropdown
                      value={dropDownValue ? dropDownValue.label : "State"}
                      onChange={handleChangeDropDown}
                      options={[
                        { label: "one" },
                        { label: "two" },
                        { label: "three" },
                      ]}
                    /> */}
                  </div>
                  <div className="flex justify-center items-center gap-2 mb-[13px]">
                    <TextInput
                      placeholder="Zip Code"
                      name="PinCode"
                      onChange={handleChange}
                      value={formData.PinCode}
                      className="bg-white"
                    />
                    <TextInput
                      placeholder="Phone(optional)"
                      name="PhoneNo"
                      onChange={handleChange}
                      value={formData.PhoneNo}
                      className="bg-white"
                    />
                  </div>
                  <input
                    type="checkbox"
                    className="mr-2"
                    name="offer"
                    id="offer"
                  />
                  <label htmlFor="offer">Text me with news and offers</label>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    theme="contained"
                    onClick={() => navigate("/home")}
                    className="w-[260px]"
                  >
                    Continue to shipping
                  </Button>
                  <Button
                    theme="contained"
                    disabled={isDisable ? true : false}
                    onClick={handlePlaceOrder}
                    className="w-[260px] disabled:cursor-not-allowed disabled:opacity-75"
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full lg:max-w-[50%] self-stretch max-w-auto lg:px-[50px] px-[15px] font-Josefin">
              <div
                className="max-h-[460px] xl:h-[440px] overflow-y-auto mt-[30px] mb-[20px]"
                id="modelScroll"
              >
                {!!cart?.length &&
                  cart.map((item, index) => {
                    return (
                      <div key={index} className="pr-4">
                        <div className="border-b-[1px] border-[#000000] flex justify-between items-center px-[15px] mb-2">
                          <div className="flex items-center gap-1">
                            <button
                              onClick={handleRemoveItem.bind(null, index)}
                            >
                              <MdOutlineClose />
                            </button>
                            <h4 className="md:text-base sm:text-sm truncate max-w-[90%] text-xs py-1 font-[500] font-Josefin">
                              {item?.product?.title}
                            </h4>
                          </div>
                          {!!item?.total && (
                            <span className="sm:text-[20px] text-[18px] leading-[33px] font-[400] font-Josefin">
                              ${item?.total}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-center items-center lg:gap-[40px] gap-[10px] mb-[25px]">
                          {item.product.image && (
                            <img
                              src={item.product.image}
                              className="h-[100px] w-[100px]"
                              alt="ring"
                            />
                          )}
                          {!item.product.image && (
                            <div className="h-[100px] w-[100px] bg-gray-200">
                              <img
                                src={item.product.image}
                                className="h-[100px] w-[100px]"
                                alt="ring"
                              />
                            </div>
                          )}
                          <div className="w-full">
                            <h2 className="lg:text-base text-sm font-[600] mb-1 ">
                              {item?.product?.title}{" "}
                              {item?.product?.source === "calibrated"
                                ? `- ${item?.product?.name}`
                                : ``}
                            </h2>
                            <p className="lg:text-sm text-xs font-[400] flex flex-col text-[#626262]">
                              {item?.product?.attributes &&
                                Object.entries(item?.product?.attributes).map(
                                  ([key, value], index) => {
                                    return (
                                      <span
                                        key={index}
                                        title={value?.name}
                                        className="max-w-[250px] truncate"
                                      >
                                        {key} : {value?.name}
                                      </span>
                                    );
                                  }
                                )}
                              {item?.product?.size && (
                                <span>Size : {item?.product?.size}</span>
                              )}
                              {item?.product?.engraving && (
                                <span
                                  title={item?.product?.engraving}
                                  className="max-w-[250px] truncate"
                                >
                                  Engraving : {item?.product?.engraving}
                                </span>
                              )}

                              {item?.product?.source === "calibrated" && (
                                <div className="flex flex-col">
                                  <span>
                                    Quantity : {`${item?.product?.pcs} Pcs`} /{" "}
                                    {`${item?.product?.carat} cts`}
                                  </span>
                                  <span>Color : {item?.product?.color}</span>
                                  <span>
                                    Clarity : {item?.product?.clarity}
                                  </span>
                                </div>
                              )}
                            </p>
                          </div>
                          {/* <div>
                            <span className="lg:text-[20px] text-[16px] leading-[33px] font-[400] ">
                              ${item.product.price}
                            </span>
                          </div> */}
                        </div>
                        {!!item?.diamond?.length &&
                          item?.diamond?.map((diamond, i) => {
                            return (
                              <div
                                key={i}
                                className="flex justify-center items-center lg:gap-[40px] gap-[10px] mb-[25px]"
                              >
                                {diamond?.image && (
                                  <img
                                    src={diamond?.image}
                                    className="h-[100px] w-[100px]"
                                    alt="ring"
                                  />
                                )}
                                {!diamond?.image && (
                                  <div className="h-[100px] w-[100px] bg-gray-200">
                                    <img
                                      src={diamond?.ImageUrl}
                                      className="h-[100px] w-[100px] max-w-fit"
                                      alt="ring"
                                    />
                                  </div>
                                )}
                                <div className="w-full">
                                  <h2 className="lg:text-base text-sm font-[600] mb-1 ">
                                    {diamond?.title}
                                  </h2>
                                  <p className="lg:text-sm text-xs font-[400] flex flex-col text-[#626262]">
                                    <span>Carat : {diamond?.carat}</span>
                                    <span>Colour : {diamond?.COLOUR}</span>
                                    <span>Clarity : {diamond?.CLARITY}</span>
                                    <span>Cut : {diamond?.CUTPOLISHSYM}</span>
                                  </p>
                                </div>
                                {/* <div>
                                  <span className="lg:text-[20px] text-[16px] leading-[33px] font-[400] ">
                                    ${diamond?.price}
                                  </span>
                                </div> */}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>

              <div className="mt-[25px]">
                <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
                <div className="flex justify-between">
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[600] text-[#626262] ">
                    Subtotal (tax excl.)
                  </span>
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[300] ">
                    ${totalPrice}
                  </span>
                </div>
                <div className="flex justify-between mb-[25px]">
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[600] text-[#626262] ">
                    Shipping
                  </span>
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[300] ">
                    Calculated at next step
                  </span>
                </div>
                <div className="w-full h-[1px] bg-[#000000]"></div>
                <div className="flex justify-between mt-[10px]">
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[600] text-[#626262] ">
                    Total
                  </span>
                  <span className="lg:text-[18px] text-[16px] leading-[42px] font-[300] ">
                    USD
                    <span className="lg:text-[26px] text-[22px] leading-[42px] font-[600] ml-2">
                      ${totalPrice}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Checkout;
