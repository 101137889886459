import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export const initialiseAnalytics = () => {
  // TODO: move this to env file
  // const TRACKING_ID = "G-WE4W6GHHN0";
  const TRACKING_ID = "G-9J4NEG78XK";
  //   const TRACKING_ID = process.env.REACT_APP_GA_ID;
  ReactGA.initialize(TRACKING_ID);
};

export const trackPageEvent = (category) => {
  return (action, label) => {
    // Send a custom event
    ReactGA.event({
      category: category,
      action: action,
      label: label, // optional
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  };
};

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initialiseAnalytics();
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "page_view",
        page: location.pathname + location.search,
        title: "Page Visit",
      });
    }
  }, [initialized, location]);
};
