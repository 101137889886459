import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSnap } from "../../Services/snapPostService";

export const fetchSnapPost = createAsyncThunk("category/SnapPost",
  async (params) => {
    const response = await getSnap(params)
    return response.data.data.snap
  })

const initialState = {
  data: [],
  loading: false,
  error: null
}

const snapPostSlice = createSlice({
  name: "SnapPost",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSnapPost.pending, (state, action) => {
      state.loading = true
    })
      .addCase(fetchSnapPost.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchSnapPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      })
  }
})

export const getSnapPostDataState = state => state.snapPostData

export default snapPostSlice.reducer