import React from "react";
import Modal from "../../LandingComponent/Common/Modal";

const VideoModal = ({ isOpen, onClose, data }) => {
  const { videoUrl = "", title = "Stock ID :" } = data;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="screen"
      title={title}
      className="bg-transparent text-white text-left"
      backdropClass={'bg-[#000000c2]'}
    >
      <div className="flex justify-center items-center w-full min-h-[85vh] ">
        <video className="md:max-w-[600px] sm:max-w-[400px] max-w-[250px] mx-auto" autoPlay loop muted controls>
          <source type="video/mp4" src={videoUrl} />
        </video>
      </div>
    </Modal>
  );
};

export default VideoModal;
