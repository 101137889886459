import React from "react";
import img6 from "../../assets/images/img6.png";
import img9 from "../../assets/images/img9.svg";
import Customizejewelry from "../../assets/images/Customizejewelry.svg";
import img11 from "../../assets/images/img11.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Banner.css";
import { getAuthState } from "../../store/Slices/AuthSlice";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

const Banner = ({ className }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState);

  return (
    <>
      <div className={`relative ${className}`}>
        <div className="max-w-[1224px] mx-auto p-4 xl:pt-[20px] xl:p-0">
          <img
            src={img6}
            alt="banner"
            className="w-full object-cover max-h-[200px] sm:h-auto h-[130px]"
          />
          <div className="absolute flex flex-col w-[92%] lg:w-[95%] xl:w-[79%] 2xl:w-[72%] 3xl:w-max 3xl:pl-[8%] h-full justify-center top-1 md:top-2 lg:top-2 xl:top-2">
          <h1 className="2xl:text-[80px] xl:text-[80px] lg:text-[70px] md:text-[50px] text-[30px] xl:leading-[60px] lg:leading-[50px] sm:leading-[40px] leading-[32px] font-[600] text-[#fff]  2xl:mb-0 mb-0 font-playfair lg:text-center block tracking-[2px] mx-auto mt-0 2xl:mt-[8px]">
            Luxury Re<span className="font-Josefin">D</span>efined
          </h1>
          <p className="2xl:text-[26px] xl:text-[22px] lg:text-[16px] md:text-[14px] text-[8px] xl:leading-[52px] lg:leading-[40px] md:leading-[26px] leading-[14px] font-[400] tracking-[2px] text-[#fff] sm:mb-0 mb-0 font-Poppins 2xl:mx-auto 2xl:tracking-[4px] 2xl:mt-[20px] xl:mt-[16px] lg:mt-[20px] md:mt-[20px] mt-[10px] text-center">
            Fine Lab-diamond Jewellery by the Diamond Polisher
          </p>
        </div>
        </div>
        
        {/* <div className="absolute text-center md:top-[50%] sm:top-0 top-[10px] md:left-[30%] right-0 md:-translate-y-1/2 md:-translate-x-1/2 translate-y-0 
        translate-x-0 2xl:max-w-[1000px] xl:max-w-[800px] md:w-full max-w-fit md:max-w-full :max-w-fit w-full md:mx-0 mx-auto lg:mt-[20px] md:mt-0 sm:mt-[20px] mt-[12px] md:px-0 px-4 ">
          <h1 className="2xl:text-[110px] xl:text-[90px] lg:text-[60px] md:text-[50px] text-[24px] xl:leading-[60px] lg:leading-[50px] sm:leading-[40px] leading-[32px] font-[600] text-[#fff]  2xl:mb-4 mb-1 font-playfair lg:text-center block tracking-[2px] mx-auto mt-0 md:text-right 2xl:mt-[8px]">
            Luxury Re<span className="font-Josefin">D</span>efined
          </h1>
          <p className="2xl:text-[26px] xl:text-[22px] lg:text-[16px] md:text-[14px] text-[12px] xl:leading-[52px] lg:leading-[40px] md:leading-[26px] leading-[16px] font-[400] tracking-[2px] text-[#fff] sm:mb-4 mb-0 font-Poppins 2xl:w-[900px] 2xl:mx-auto 2xl:tracking-[4px] 2xl:mt-[40px] xl:w-[800px] lg:w-[550px] mt-[10px] w-auto ">
            Fine Lab-diamond Jewellery by the Diamond Polisher
          </p>
          <div className="flex items-center sm:justify-end justify-center w-full lg:mt-[30px] sm:mt-[20px] mt-[7px] mr-[10px] 2xl:w-[1100px]">
            <div className="flex items-center md:gap-4 gap-2">
              <button
                onClick={() => navigate("/about")}
                className="md:w-auto w-full lg:text-[15px] md:text-[14px] text-[13px] leading-[32px] lg:py-[3px] py-[3px] lg:px-[18px] px-[10px] font-[300] bg-[#aaaeae] text-[#fff]  font-Josefin hover:bg-[#fff] hover:text-[#21395a] border-2 border-[#aaaeae] duration-300 shadow-md"
              >
                Know Lab Diamond
              </button>
              <button
                onClick={() => navigate("/about")}
                className="md:w-auto w-full lg:text-[15px] md:text-[14px] text-[13px] leading-[32px] lg:py-[3px] py-[3px] lg:px-[18px] px-[10px] font-[300] bg-[#aaaeae] text-[#fff]  font-Josefin hover:bg-[#fff] hover:text-[#21395a] border-2 border-[#aaaeae] duration-300 shadow-md"
              >
                About DTN
              </button>
              <button
                onClick={() => navigate("/diamonds-new")}
                className="w-auto lg:text-[15px] md:text-[14px] text-[13px] sm:leading-[32px] leading-[24px] lg:py-[3px] sm:py-[3px] py-0 lg:px-[18px] px-[10px] font-[400] bg-[#1F2937] text-[#fff]  font-Josefin hover:bg-[#fff] hover:text-[#1F2937] border-2 md:border-[#1F2937] border-[#1F2937] duration-300 shadow-md hover:border-transparent hidden"
              >
                Select Your Diamond
              </button>
            </div>
          </div>
        </div> */}
      </div>

      <div className="xl:max-w-[1224px] max-w-full xl:mx-auto mx-[20px] xl:mb-[22px] md:mb-[16px] mb-[18px]">
        {/* <div className='mt-[80px] grid md:grid-cols-3 sm:grid-cols-2 xl:gap-[84px] lg:gap-[50px] gap-[20px]'> */}
        <div className="mt-0 sm:mt-0 md:mt-[0px] lg:mt-[2px] xl:mt-[22px] xl:gap-[82px] lg:gap-[20px] gap-[10px] grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3">
          {/* <Link
            to={"/customized-jewelry"}
            className="border border-[#1F2937] rounded-[10px] text-center hover:bg-[#1F2937] banner-box group transition-all duration-400"
          >
            <div className="md:mt-[14px] md:mb-[37px] mb-0 mt-[20px] img-fix md:h-[70px] pt-[2px] h-[70px] sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <img
                src={Customizejewelry}
                alt="img"
                className="svg-main mx-auto duration-300 md:w-[45px] w-[40px]"
              />
            </div>
            <div className="sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <div className="md:mt-[25px] mt-[18px] border-y border-[#1F2937] banner-lable">
                <h5 className="md:text-[19px] text-[#1F2937] font-Bodoni font-[800] py-2 banner-lable">
                  Customize Jewelry
                </h5>
              </div>
              <div
                className="mx-0-after560 sm:mt-[11px] mt-0 mb-[9px] min-h-[26px] flex flex-col justify-center sm:opacity-0 sm:group-hover:opacity-100 transition-all duration-500
                  "
              >
                <p className="text-sm font-normal text-[#626262] px-[16px] banner-text ">
                  Choose Mounting Choose Diamond
                </p>
              </div>
            </div>
          </Link> */}
          <Link
            to={"/customized-jewelry"}
            className="sm:max-w-[200px] xs:max-w-full md:max-w-full ml-0 border border-[#1F2937] rounded-[10px] text-center hover:bg-[#1F2937] banner-box  group transition-all duration-500"
          >
            <div className="md:mt-[30px] mt-[20px] img-fix md:h-[70px] pt-[2px] h-[70px] sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <img
                src={Customizejewelry}
                alt="img"
                className="svg-main mx-auto duration-300 md:w-[45px] w-[40px]"
              />
            </div>
            <div className="sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <div className="md:mt-[24px] mt-[18px] border-y border-[#1F2937] banner-lable">
                <h5 className="md:text-[19px] text-[#1F2937] font-Bodoni font-[700] py-2 banner-lable">
                Customize Jewelry
                </h5>
              </div>
              <div className="mx-0-after560 sm:mt-[11px] mt-0 mb-[9px] min-h-[26px] flex flex-col justify-center">
                <p className="text-sm font-normal text-[#626262] px-[16px] banner-text sm:opacity-0 sm:group-hover:opacity-100 transition-all duration-500">
                Choose Mounting Choose Diamond
                </p>
              </div>
            </div>
          </Link>
          <Link
            to={"/readytoship"}
            className="sm:max-w-[200px] xs:max-w-full md:max-w-full m-0 border border-[#1F2937] rounded-[10px] text-center hover:bg-[#1F2937] banner-box  group transition-all duration-500"
          >
            <div className="md:mt-[30px] mt-[20px] img-fix md:h-[70px] pt-[2px] h-[70px] sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <img
                src={img9}
                alt="img"
                className="svg-main mx-auto duration-300 md:w-[45px] w-[40px]"
              />
            </div>
            <div className="sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <div className="md:mt-[24px] mt-[18px] border-y border-[#1F2937] banner-lable">
                <h5 className="md:text-[19px] text-[#1F2937] font-Bodoni font-[700] py-2 banner-lable">
                  Ready To Ship{" "}
                </h5>
              </div>
              <div className="mx-0-after560 sm:mt-[11px] mt-0 mb-[9px] min-h-[26px] flex flex-col justify-center">
                <p className="text-sm font-normal text-[#626262] px-[16px] banner-text sm:opacity-0 sm:group-hover:opacity-100 transition-all duration-500">
                  Jewelry In Stock
                </p>
              </div>
            </div>
          </Link>
          <Link
            to={"/snap-own-design"}
            className="sm:max-w-[200px] xs:max-w-full md:max-w-full sm:mt-0 sm:mb-0 md:mt-0 md:mb-0 mr-0 border border-[#1F2937] rounded-[10px] text-center hover:bg-[#1F2937] banner-box  group transition-all duration-500"
          >
            <div className="md:mt-[30px] mt-[20px] img-fix md:h-[70px] pt-[2px] h-[70px] sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <img
                src={img11}
                alt="img"
                className="svg-third mx-auto duration-300 w-[46px]"
              />
            </div>
            <div className="sm:translate-y-[20px] sm:group-hover:translate-y-0 transition-all duration-500">
              <div className="md:mt-[24px] mt-[18px] border-y border-[#1F2937] banner-lable">
                <h5 className="md:text-[19px] text-[#1F2937] font-Bodoni font-[700] py-2 banner-lable">
                  Snap & Post
                </h5>
              </div>
              <div className="mx-0-after560 sm:mt-[11px] mt-0 mb-[9px] min-h-[26px] flex flex-col justify-center">
                <p className="text-sm font-normal text-[#626262] px-[16px] banner-text sm:opacity-0 sm:group-hover:opacity-100 transition-all duration-500">
                  Design Your Own Jewelry
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Banner;
