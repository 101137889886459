import React from "react";
import AdminModal from "../common/AdminModal";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  AddCategoryStyleData,
  UpdateCategoryStyle,
} from "../../../Services/categoryStyleService";
import axios from "axios";
import Loading from "../../../assets/images/loading4.gif";
import { BsCheckCircleFill } from "react-icons/bs";
import ImageUpload from "../../shared/ImageUpload";
import {
  fetchCategoryStyleByCategoryId,
  getCategoryDataState,
} from "../../../store/Slices/categorySlice";
import { useThunk } from "../../../Hooks/use-thunk";

const AddCategoryStyle = ({
  isAtOpen,
  setIsAtOpen,
  editId,
  categoryId,
  setIsEdit,
}) => {
  const [inputValue, setInputValue] = useState({
    stCategoryStyleName: "",
    stCategoryStyleAlias: "",
    stImageUrl: "",
    flgIsActive: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const { categoryStyles } = useSelector(getCategoryDataState);

  const [getCategoryStylebyCategoryId] = useThunk(
    fetchCategoryStyleByCategoryId
  );

  useEffect(() => {
    if (categoryStyles?.length > 0) {
      const editData = categoryStyles.find(
        (item) => item.unCategoryStyleIdentifier === editId
      );
      if (editData) {
        setInputValue({
          stCategoryStyleName: editData.stCategoryStyleName,
          stCategoryStyleAlias: editData.stCategoryStyleAlias,
          stImageUrl: editData.stImageUrl,
          flgIsActive: (editData.flgIsActive === 1 || editData.flgIsActive === "1") ? true : false,
        });
      }
    }
  }, [editId, categoryStyles]);

  const handleChangeImage = async (e) => {
    const { files } = e.target;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "DTNUnsigned");
    await axios
      .post("https://api.cloudinary.com/v1_1/dbdpfhov6/image/upload", formData)
      .then((res) => {
        setInputValue((prev) => ({
          ...prev,
          stImageUrl: res.data.secure_url,
        }));
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const handleChange = async (e) => {
    const { name, value, checked, files } = e.target;
    if (files) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("upload_preset", "DTNUnsigned");
      await axios
        .post(
          "https://api.cloudinary.com/v1_1/dbdpfhov6/image/upload",
          formData
        )
        .then((res) => {
          setInputValue((prev) => ({
            ...prev,
            stImageUrl: res.data.secure_url,
          }));
          setIsLoading(false);
        });
    }
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "isActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setIsAtOpen(false);
    setInputValue({
      stCategoryStyleName: "",
      stCategoryStyleAlias: "",
      stImageUrl: "",
      flgIsActive: true,
    });
    setUploadImage(false);
    if (editId) {
      setIsEdit("");
    }
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        unCategoryStyleIdentifier: editId,
        stCategoryStyleName: inputValue.stCategoryStyleName,
        stCategoryStyleAlias: inputValue.stCategoryStyleAlias,
        stImageUrl: inputValue.stImageUrl,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
      };
      const res = await UpdateCategoryStyle(payload);
      if (res.status === 200) {
        getCategoryStylebyCategoryId(categoryId);
        toast.success(res.data.message);
      }
      setIsEdit("");
    } else {
      const payload = new FormData();
      payload.append("unCategoryId", categoryId);
      payload.append("stCategoryStyleName", inputValue.stCategoryStyleName);
      payload.append("stCategoryStyleAlias", inputValue.stCategoryStyleAlias);
      payload.append("stImageUrl", inputValue.stImageUrl);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await AddCategoryStyleData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        getCategoryStylebyCategoryId(categoryId);
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={editId ? "Update Category Style" : "Add Category Style"}
      onSubmit={handleSubmit}
      isOpen={isAtOpen}
      onClose={handleClose}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="stCategoryStyleName"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Category Style Name
        </label>
        <div>
          <input
            type="text"
            name="stCategoryStyleName"
            value={inputValue.stCategoryStyleName}
            id="stCategoryStyleName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Enter Attribute Value Name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="stCategoryStyleAlias"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Category Style Alias
        </label>
        <div className="col-span-8 sm:col-span-4">
          <input
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            type="text"
            name="stCategoryStyleAlias"
            id="stCategoryStyleAlias"
            value={inputValue.stCategoryStyleAlias}
            onChange={handleChange}
            placeholder="Enter Attribute Value Alias"
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="stImageUrl"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Image Url
        </label>
        <div className="flex items-center gap-[10px]">
          <div>
            <ImageUpload
              name="stImageUrl"
              id="stImageUrl"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChangeImage}
            />
          </div>
          {isLoading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {uploadImage && <BsCheckCircleFill className="text-green-600" />}
        </div>
      </div>
      <div>
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="attributeCheck"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="attributeCheck"
            className=" block text-sm font-medium leading-6 text-gray-900"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddCategoryStyle;
