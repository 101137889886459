import React from "react";
import Skeleton from "react-loading-skeleton";

const AgentSelectionCardSkeleton = () => {
  return (
    <div className="bg-white p-4 rounded-md shadow col-span-1">
      <div className="h-80">
          <Skeleton height={"100%"} />
        </div>
      <div className="pt-2">
        <div className="flex items-center justify-between">
          <h1 className="text-[20px] font-[500]">
            <Skeleton width={50} height={20} />
          </h1>
          <h1 className="text-[16px] font-[400] text-[#4f4f4f]">
            <Skeleton width={50} height={20} />
          </h1>
        </div>
        <div className="flex items-center gap-2 pt-2">
          <Skeleton width={50} height={20} />
          <Skeleton width={50} height={20} />
        </div>
      </div>
    </div>
  );
};

export default AgentSelectionCardSkeleton;
