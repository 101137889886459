import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser, socialLogin, registerUser } from "../../Services/loginService";

export const authLogin = createAsyncThunk("user/login", async (data) => {
  const response = await loginUser(data)
  return response.data
})

export const socialAuthLogin = createAsyncThunk("user/socialLogin", async (data) => {
  const response = await socialLogin(data)
  return response.data
})

export const authRegister = createAsyncThunk(
  "user/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await registerUser(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  token: localStorage.token || "",
  isLoggedIn: !!localStorage.token,
  user: localStorage.name ? JSON.parse(localStorage.name) : null,
  loading: false,
  error: null
}

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.token = ""
      state.isLoggedIn = false
      state.user = null
      localStorage.removeItem("token")
      localStorage.removeItem("name")
    }
  },
  extraReducers: builder => {
    builder.addCase(authLogin.pending, (state) => {
        state.loading = true
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.success) {
          localStorage.setItem("token", action.payload.data.token)
          localStorage.setItem("name", JSON.stringify(action.payload.data))
          state.token = action.payload.data.token
          state.user = action.payload.data
          state.isLoggedIn = true
          state.error = null
        } else {
          state.error = action.payload.message
        }
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(socialAuthLogin.pending, (state) => {
        state.loading = true
      })
      .addCase(socialAuthLogin.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.success) {
          localStorage.setItem("token", action.payload.data.token)
          localStorage.setItem("name", JSON.stringify(action.payload.data))
          state.token = action.payload.data.token
          state.user = action.payload.data
          state.isLoggedIn = true
          state.error = null
        } else {
          state.error = action.payload.message
        }
      })
      .addCase(socialAuthLogin.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(authRegister.pending, (state) => {
        state.loading = true
      })
      .addCase(authRegister.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.success) {
          localStorage.setItem("token", action.payload.data.token)
          localStorage.setItem("name", JSON.stringify(action.payload.data))
          state.token = action.payload.data.token
          state.user = action.payload.data
          state.isLoggedIn = true
          state.error = null
        }else {
          state.error = action.payload.message
        }
      })
      .addCase(authRegister.rejected, (state, action) => {
        state.loading = false;
        state.error =
          Object.values(action.payload?.data)?.[0]?.[0] ||
          action.payload.message ||
          action.error.message;
      });
  }
})

export const getAuthState = state => state.authData

export const { clearAuth } = authSlice.actions

export default authSlice.reducer