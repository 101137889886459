import api from "./api"

const getStoneClarity = () => {
  return api("/api/StoneClarity", "get")
}

const getStoneClarityById = (id) => {
  return api(`/api/StoneClarity/${id}`, "get")
}

const createStoneClarity = (data) => {
  return api("/api/StoneClarity", "post", data)
}

const updateStoneClarity = (data) => {
  return api("/api/StoneClarity", "put", data)
}

const DeleteStoneClarity = (data) => {
  return api(`/api/StoneClarity/${data}`, "delete")
}

export {
  getStoneClarity,
  getStoneClarityById,
  createStoneClarity,
  updateStoneClarity,
  DeleteStoneClarity
}