import { createSlice } from "@reduxjs/toolkit";

const rolePermissions = createSlice({
  name: "rolePermissions",
  initialState: { rolePermissions: {} },
  reducers: {
    setRolePermissions(state, { payload }) {
      state.rolePermissions = payload
    },
  },

});

export const { setRolePermissions } = rolePermissions.actions;
export default rolePermissions.reducer;