import api from "./api";

const getSuppliers = () => api("/api/Accounts", "get")
const getSuppliersById = (id) => api(`/api/Accounts/${id}`, "get")
const createSuppliers = (data) => api("/api/Accounts", "post", data)
const updateSuppliers = (data) => api("/api/Accounts", "put", data)
const deleteSuppliers = (id) => api(`/api/Accounts/${id}`, "delete")

export {
  getSuppliers,
  createSuppliers,
  updateSuppliers,
  deleteSuppliers,
  getSuppliersById
}