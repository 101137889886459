import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Fragment } from 'react'

const SelectMenu = ({ options = [], data, setData = () => { }, className = "", widthClass = "", dropDownHeightClass = "", defaultText = "Select" }) => {
  return (
    <Listbox value={data} onChange={setData}>
      <div className={`relative ${widthClass}`}>
        <Listbox.Button className={`relative cursor-pointer w-full h-[38px] rounded-lg py-2 pl-3 pr-10 text-left shadow-sm border border-[#295268] focus:outline-none focus-visible:border-[#295268] focus-visible:ring-2 focus-visible:ring-[#295268] focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-[#295268] sm:text-sm z-0 ${className}`}>
          <span className="block truncate">
            {data
              ? data?.title
              : defaultText}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${dropDownHeightClass}`}>
            {!!options?.length ?
              options?.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                      ? "bg-[#edf4f8] text-gray-900"
                      : "text-gray-900"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                          }`}
                      >
                        {option.title}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#295268]">
                          <CheckIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )) : (
                <div className='w-full text-center'>No Data</div>
              )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default SelectMenu