import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./Pagination";
import { FiFilter } from "react-icons/fi";
import UploadSideStonePrice from "./Modals/UploadSideStonePrice";
import {
  fetchSideStonePriceList,
  getSideStonePriceState,
} from "../../store/Slices/sideStonePriceSlice";
import { deleteSideStonePriceList } from "../../Services/sideStonePriceService";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { TiArrowBack } from "react-icons/ti";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const tableData = [
  {
    id: 1,
    size: 50,
    shape: "Round",
    color: "White",
    price: 2000,
    perCt: 500,
  },
  {
    id: 2,
    size: 75,
    shape: "Squre",
    color: "White",
    price: 5000,
    perCt: 200,
  },
];

const SideStonePriceList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(getSideStonePriceState);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchSideStonePriceList());
  }, [dispatch]);

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await deleteSideStonePriceList(id);
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(fetchSideStonePriceList());
      } else {
        toast.error(res.data.message);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <UploadSideStonePrice isOpen={isOpen} setIsOpen={setIsOpen} />
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-[10px] mt-[1px]">
            <Link
              to={"/admin/utilityMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>

            <div className="flex gap-1">
              <button
                onClick={() => setIsOpen(true)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                Upload Excel
              </button>
              <button className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-3 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] ">
                <FiFilter />
              </button>
            </div>
          </div>

          <div className="sm:rounded-lg relative mt-4 rounded shadow-md">
            <div
              className={`relative overflow-x-auto bg-white ${
                tableData?.length ? "min-h-[15rem]" : ""
              } `}
            >
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 max-w-[75px]">
                      Sr No.
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Size
                    </th>
                    <th scope="col" className="px-4 py-3">
                      shape
                    </th>
                    <th scope="col" className="px-4 py-3 ">
                      colour/clarity
                    </th>
                    <th scope="col" className="px-4 py-3">
                      price
                    </th>
                    <th scope="col" className="px-4 py-3">
                      p/ct
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white text-xs border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.inSrNumber}
                            </th>
                            <td className="px-6 py-3">{item.stSizeName}</td>
                            <td className="px-6 py-3">
                              {item.unAttributeValueShapeId}
                            </td>
                            <td className="px-6 py-3">
                              {item.stColourClarity}
                            </td>
                            <td className="px-6 py-3">{item.dcPrice}</td>
                            <td className="px-6 py-3">{item.perCt || "-"}</td>
                            <td className="px-6 py-3 w-[100px]">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  type="button"
                                  onClick={() => handleDelete(item.inSrNumber)}
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={7}>
                            <NoDataText text={"No data found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SideStonePriceList;
