import React, { useState } from "react";
import AdminModal from "../common/AdminModal";
import { Dialog, Listbox, Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import AutoComplete from "../common/CustomAutoComplete/AutoComplete";
import { BiCopyAlt } from "react-icons/bi";
import { useEffect } from "react";
import { DesignCatalog } from "../../../Services/designService";
import { useLocation } from "react-router-dom";
const option = [
  {
    id: 1,
    title: "1",
  },
  {
    id: 2,
    title: "2",
  },
  {
    id: 3,
    title: "15",
  },
  {
    id: 4,
    title: "30",
  },
];

const Catalog = ({ onClose, isOpen, data }) => {
  const [days, setDays] = useState(option[2]);
  const [linkGanarete, setLinkGanarete] = useState(false);
  const [link, setLink] = useState(null);
  const hostName = window.location.origin;
  const User = JSON.parse(localStorage.getItem('name'))

  useEffect(() => {
    if (data.length === 0) {
      setLinkGanarete(false);
    }
  }, [data]);

  const handleGenerateLink = async () => {
    const DesignId =
      (await data.length) > 0 && data.map((item) => item.unDesignIdentifier);

    if (DesignId.length > 0 && User) {
      const payload = new FormData();
      for (var i = 0; i < DesignId.length; i++) {
        payload.append("design_ids[]", DesignId[i]);
      }
      payload.append("link_expire_day", days.title);
      payload.append("user_id", User.unUserIdentifier);
      const res = await DesignCatalog(payload);
      if (res.status === 200) {
        setLinkGanarete(true);
        setLink(res.data.data.link);
      }
    }
  };

  const copyToClipboard = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-70"}
                rounded-md py-2 sm:px-4 px-2 sm:text-[14px] text-[12px] bg-white text-black text-opacity-70 font-medium shadow flex items-center cursor-pointer gap-2`}
          >
            <span>E-Catalog</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-50 bg-white mt-3 w-screen max-w-[320px] sm:mx-0 mx-3 -translate-x-1/2 transform px-0 lg:max-w-[450px] overflow-hidden rounded shadow">
              <div className=" rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {!linkGanarete && (
                  <div className="relative  bg-slate-200 sm:p-7 p-3 flex items-end gap-4">
                    <div className="grid gap-8 lg:grid-cols-2 w-[55%]">
                      <div className="col-span-full">
                        <label
                          htmlFor="menufecture"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Link Expiry
                        </label>
                        <Listbox value={days} onChange={setDays}>
                          <div className="relative">
                            <Listbox.Button className="block rounded-md w-full h-[36px] py-[5px] px-[8px] text-gray-900 shadow-sm placeholder:text-gray-400 border border-[#D1D5DB] sm:text-sm sm:leading-6 text-left bg-white">
                              <span className="block truncate">
                                {days?.title} Days
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {option.map((person, personIdx) => (
                                  <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-amber-100 text-amber-900"
                                          : "text-gray-900"
                                      }`
                                    }
                                    value={person}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {person.title} Days
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <button
                        className="rounded-md py-2 px-4 sm:text-[14px] text-[13px] bg-[#f4f4f4] text-black text-opacity-70 font-medium shadow flex items-center cursor-pointer gap-2"
                        onClick={() => handleGenerateLink()}
                      >
                        Ganarete Link
                      </button>
                    </div>
                  </div>
                )}
                {linkGanarete && (
                  <div className="relative  bg-slate-200 sm:p-7 p-2 flex items-end gap-4">
                    <a
                      href={`${hostName}${link}`}
                      className="block w-full outline-0 bg-white rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 py-[5px] px-4 h-[36px] relative pr-[50px] truncate"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${hostName}${link}`}
                    </a>
                    <button
                      className="absolute top-[50%] -translate-y-[50%] right-10"
                      type="button"
                      onClick={() => copyToClipboard(`${hostName}${link}`)}
                    >
                      <BiCopyAlt className="text-[18px]" />
                    </button>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Catalog;
