import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDesigns,
  getDesignDataState,
} from "../../store/Slices/designSlice";
import { usePagination } from "react-table";
import { useSearchParams } from "react-router-dom";
import { FaThList } from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import dummyDiamond from "../../assets/images/dummy-diamond.png";
import DiamondCardSkeleton from "../LandingComponent/Common/skeleton/DiamondCardSkeleton";
import DiamondListDesktopSkeleton from "../LandingComponent/Common/skeleton/DiamondListDesktopSkeleton";
import DiamondListMobileSkeleton from "../LandingComponent/Common/skeleton/DiamondListMobileSkeleton";
import AgentSelectionCardSkeleton from "../LandingComponent/Common/skeleton/AgentSelectionCardSkeleton";
import AgentSelectionListSkeleton from "../LandingComponent/Common/skeleton/AgentSelectionListSkeleton";

const AgentSelection = () => {
  const dispatch = useDispatch();
  const { data, loading, error, totalCount } = useSelector(getDesignDataState);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchDesigns());
  }, [dispatch]);

  const handleViewChange = (type) => {
    if (type === "card") {
      setSearchParams([["view", 1]]);
    } else {
      setSearchParams("");
    }
  };

  const activeView = useMemo(() => searchParams.get("view"), [searchParams]);

  return (
    <div className="grid">
      <div className="w-full py-2">
        <div className="px-4">
          <div className="my-2 flex justify-between items-center border-b-2 border-dashed border-gray-400 mb-4">
            <h2 className="text-lg font-bold text-gray-700 ">
              {/* View All Diamonds */}
            </h2>
            <div className="flex justify-center items-center">
              <button
                onClick={handleViewChange.bind(null, "list")}
                title="List View"
                className="border border-gray-300 p-2"
              >
                <FaThList color="#868e96" className="text-[16px]" />
              </button>
              <button
                onClick={handleViewChange.bind(null, "card")}
                title="Card View"
                className="border border-gray-300 p-2"
              >
                <BsFillGridFill color="#868e96" className="text-[16px]" />
              </button>
            </div>
          </div>
        </div>
        {!activeView && (
          <div className="grid">
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <div className="bg-white lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4 mx-4">
                    <div className="w-full">
                      <div className="w-full grid grid-cols-12 gap-2">
                        <div className="col-span-4">
                          <div className="flex items-center gap-[17px]">
                            <img
                              src={item.stDefImageURL}
                              alt="DefImageURL"
                              className="sm:w-16 sm:h-16 w-14 h-14 rounded"
                            />
                            <div>
                              <h2 className="text-[20px] font-[500]">
                                {item.stDesignName}
                              </h2>
                              <h6 className="text-[16px] text-[#424242]">
                                {item.stDesignAlias}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-8 flex items-center gap-[20px] justify-end">
                          <div className="flex items-center">
                            <div className="h-[22px] w-[1px] bg-[#000] opacity-[0.10] 2xl:block hidden"></div>
                            <div className="col-span-1 text-center px-[30px]">
                              <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                                Category
                              </h6>
                              <h2 className="text-[16px] font-[500]">
                                {item.category}
                              </h2>
                            </div>
                            <div className="h-[22px] w-[1px] bg-[#000] opacity-[0.10] 2xl:block hidden"></div>
                            <div className="col-span-1 text-center px-[30px]">
                              <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                                Category Style
                              </h6>
                              <h2 className="text-[16px] font-[500]">
                                {item.categoryStyle}
                              </h2>
                            </div>
                            <div className="h-[22px] w-[1px] bg-[#000] opacity-[0.10] 2xl:block hidden"></div>
                            <div className="col-span-1 text-center px-[30px]">
                              <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                                Sale Price
                              </h6>
                              <h2 className="text-[16px] font-[500]">
                                $ {item.dcSalePrice}
                              </h2>
                            </div>
                          </div>
                          <div className="flex items-center gap-[10px]">
                            <button className="px-[18px] py-[7px] text-[12px] border border-[#1F2937] bg-[#F5F5F5] hover:bg-[#1F2937] hover:text-[#fff] rounded">
                              On Request
                            </button>
                            <button className="px-[18px] py-[7px] text-[12px] border bg-[#1F2937] text-[#fff] hover:bg-[#f5f5f5] hover:text-[#1F2937] hover:border-[#1F2937] rounded">
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {loading && (
              <>
                <div className="hidden lg:grid lg:gap-4 mb-4 py-2">
                  <AgentSelectionListSkeleton />
                  {!data?.length && (
                    <>
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                    </>
                  )}
                </div>
                <div className="lg:hidden grid gap-4 py-2">
                  <DiamondListMobileSkeleton />
                  {!data?.length && <DiamondListMobileSkeleton />}
                </div>
              </>
            )}
          </div>
        )}
        {activeView && (
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 px-4">
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <div className="bg-white p-4 rounded-md shadow col-span-1">
                    {item?.stDefImageURL?.includes("https") ? (
                      <img
                        src={item?.stDefImageURL}
                        alt="stockData"
                        className="object-cover h-[280px] w-[280px]"
                      />
                    ) : (
                      <div className="w-full h-[293px] bg-gray-200 flex items-center justify-center">
                        <img
                          src={dummyDiamond}
                          alt="stockData"
                          className="object-cover h-[100px] w-[100px]"
                        />
                      </div>
                    )}
                    <div className="pt-2">
                      <div className="flex items-center justify-between">
                        <h1 className="text-[20px] font-[500]">
                          {item?.stDesignName}
                        </h1>
                        <h1 className="text-[16px] font-[400] text-[#4f4f4f]">
                          {item?.stDesignAlias}
                        </h1>
                      </div>
                      <div className="flex items-center gap-2 pt-2">
                        <button className="px-[18px] py-[7px] w-full text-[12px] border border-[#1F2937] bg-[#F5F5F5] hover:bg-[#1F2937] hover:text-[#fff] rounded">
                          On Request
                        </button>
                        <button className="px-[18px] py-[7px] w-full text-[12px] border bg-[#1F2937] text-[#fff] hover:bg-[#f5f5f5] hover:text-[#1F2937] hover:border-[#1F2937] rounded">
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            {loading && (
              <>
                <AgentSelectionCardSkeleton />
                <AgentSelectionCardSkeleton />
                {!data?.length && (
                  <>
                    <AgentSelectionCardSkeleton />
                    <AgentSelectionCardSkeleton />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentSelection;
