import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSideStone } from "../../Services/sideStoneService";

export const fetchSideStone = createAsyncThunk("sideStone/fetchCategory",
  async () => {
    const response = await getSideStone()
    return response.data.data.design_stone
  })

const initialState = {
  data: [],
  loading: false,
  error: null
}

const sideStoneSlice = createSlice({
  name: "sideStone",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSideStone.pending, (state, action) => {
      state.loading = true
    })
      .addCase(fetchSideStone.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchSideStone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      })
  }
})

export const getSideStoneDataState = state => state.sideStoneData

export default sideStoneSlice.reducer