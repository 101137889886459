import { useState, useEffect, memo } from "react";
import { ArrowLeftCircleIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";
import Skeleton from "react-loading-skeleton";
import DiamondListDesktopSkeleton from "../Common/skeleton/DiamondListDesktopSkeleton";
import DiamondListMobileSkeleton from "../Common/skeleton/DiamondListMobileSkeleton";
import { fetchCalibratedData } from "../../../store/Slices/calibratedSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  getCalibrateStockByMM,
  getCalibrateStockBySieve,
} from "../../../Services/calibratedService";
import {
  getSelectedDiamondState,
  removeDiamond,
  selectDiamond,
} from "../../../store/Slices/diamondSelectSlice";
import { getMountingDetails } from "../../../Services/mountingService";
import { MdShoppingCart } from "react-icons/md";
import AddToCartModal from "./addToCartModal";
import { BsXDiamondFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { GrDiamond } from "react-icons/gr";
import { IoDiamond } from "react-icons/io5";
import Details from "./details";
import { toast } from "react-hot-toast";
import { addToCart, openCart } from "../../../store/Slices/cartSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Admin/common/Loader";

// const tableData = [
//   {
//     sr: 1,
//     code: "M001-01",
//     metalName: "18K Yellow Gold",
//     centerStone: "Round",
//     GrWt: 1.2,
//     NtWt: 1.0,
//     stoneWt: "1 Pcs - 1.2 ct",
//     quantity: "5",
//     amount: "700USD",
//     location: "HK",
//   },
//   {
//     sr: 2,
//     code: "M001-02",
//     metalName: "18K White Gold",
//     centerStone: "Round",
//     GrWt: 1.6,
//     NtWt: 1.2,
//     stoneWt: "1 Pcs - 2.1 ct",
//     quantity: "50",
//     amount: "800USD",
//     location: "HK",
//   },
//   {
//     sr: 3,
//     code: "M001-03",
//     metalName: "18K Green Gold",
//     centerStone: "Round",
//     GrWt: 1.20,
//     NtWt: 1.75,
//     stoneWt: "1 Pcs - 1.21 ct",
//     quantity: "8",
//     amount: "500USD",
//     location: "BKK",
//   },
//   {
//     sr: 4,
//     code: "M001-04",
//     metalName: "18K Yellow Gold",
//     centerStone: "Round",
//     GrWt: 1.1,
//     NtWt: 1.24,
//     stoneWt: "1 Pcs - 1.23 ct",
//     quantity: "42",
//     amount: "700USD",
//     location: "HK",
//   },
//   // More people...
// ];

const plans = [
  {
    id: 1,
    name: "Hobby",
    memory: "4 GB RAM",
    cpu: "4 CPUs",
    storage: "128 GB SSD disk",
    price: "$40",
    isCurrent: false,
  },
  {
    id: 2,
    name: "Startup",
    memory: "8 GB RAM",
    cpu: "6 CPUs",
    storage: "256 GB SSD disk",
    price: "$80",
    isCurrent: true,
  },
  // More plans...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function TableList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { currentSize, currentShape } = props.parentState;

  const shapes = useSelector((state) => state?.mounting?.menuData?.Shape);
  const { data: selectedDiamond } = useSelector(getSelectedDiamondState);

  const { handleCurrentNavKey } = props;

  const [state, setState] = useState({
    shapeStats: [
      // { id: 1, name: "Lot", value: currentSize?.lot },
      // { id: 2, name: "Carat", value: currentSize?.carat },
      { id: 3, name: "No of stock", value: currentSize?.detailsCount },
    ],
    itemData: [],
    loading: true,
    selectedItem: {},
    openModal: false,
  });

  const { shapeStats, itemData, loading, selectedItem, openModal } = state;

  const menuData = useSelector((state) => state?.mounting?.menuData);

  // const handleAddToCart = (item) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     selectedItem: item,
  //     openModal: true,
  //   }));
  // };

  const handleAddToCart = (item) => {
    const cartData = {
      // product: {
      //   ...item,
      //   id: parentState?.currentShape?.shapeCode,
      //   title: parentState?.currentShape?.name,
      //   price: 0,
      //   image: parentState?.currentShape?.icon,
      //   carat: carat,
      //   price: pcs,
      //   clarity: clarity,
      // },
      product: {
        id: item?.MouId,
        title: item?.MouDetId,
        price: item?.Pcs,
        image: currentSize?.DefImageURL,
        source: "mounting",
      },
      diamond: selectedDiamond
        ?.filter((i) => i.productId === item?.MouDetId)
        .map((dmnd) => {
          return {
            ...dmnd,
            title: "Diamond",
            id: dmnd?.STOCKNO,
            carat: dmnd?.CARAT,
            price: dmnd?.Amount || 0,
            shape: dmnd?.SHAPE,
            image: dmnd?.stImageUrl,
          };
        }),
      total: 0,
    };

    dispatch(openCart(true));
    dispatch(addToCart(cartData));
    toast.success("Successfully Added to Cart.");
  };

  const toggleModal = () => {
    setState((prevState) => ({ ...prevState, openModal: !openModal }));
  };

  const getData = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    let resp = "";

    resp = await getMountingDetails(id || currentSize?.MouId);

    setState((prevState) => ({
      ...prevState,
      itemData: resp.data.data,
      loading: false,
    }));
  };

  const handleRemoveDiamond = (stockId) => {
    const filterData =
      selectedDiamond.length > 0 &&
      selectedDiamond.filter((item) => item.StoneNo !== stockId);
    dispatch(removeDiamond(filterData));
  };

  useEffect(() => {
    getData();
  }, [currentSize, id]);

  return (
    <>
      <div className="bg-gray-100 h-[calc(100vh-64px)]">
        <main className="bg-gray-100">
          <div className="py-1 sm:py-1 mx-auto px-4 md:px-6 lg:px-4">
            <div class="mt-2 w-full flex justify-between items-center p-2 bg-white">
              <div class="w-[60%] lg:w-[85%] md:w-[60%]">
                <div className="flex items-center">
                  <ArrowLeftIcon
                    className="h-6 w-6 md:h-8 md:w-8 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={(e) => {
                      handleCurrentNavKey(currentShape?.key);
                    }}
                  />

                  <div className="ml-2">
                    <h3 class="text-sm md:text-xl font-semibold text-gray-700 truncate text-ellipsis">
                      {currentSize?.MouNo || itemData?.mounting?.MouNo || "N/A"}
                    </h3>

                    {/* <p class="text-[10px] md:text-xs mt-1 text-gray-500">
                      {currentShape?.name}
                    </p> */}
                  </div>
                </div>
              </div>

              <div class="text-xs lg:text-sm font-medium text-gray-900 text-right w-[40%] lg:w-[15%] md:w-[40%] flex justify-between p-1">
                {shapeStats.map((stat) => (
                  <div
                    key={stat.id}
                    class="flex flex-col-reverse md:flex-row w-full justify-center items-center border-l-[1px] border-gray-400 pl-1 text-center"
                  >
                    <p class="text-[10px] md:text-xs font-normal mt-0 md:mt-2">
                      {stat.name}
                    </p>
                    <p className="text-[15px] leading-5  ml-1 font-semibold md:text-xl text-gray-700">
                      {stat.value || itemData?.mounting?.detailsCount}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-4 pt-0 bg-white">
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <Details product={itemData} />
              )}
            </div>
          </div>

          <AddToCartModal
            item={selectedItem}
            source="cardlist"
            open={openModal}
            handleClose={toggleModal}
          />

          <div className="overflow-x-auto sm:-mx-0 lg:-mx-0">
            <div className="inline-block min-w-full py-2 align-middle px-4 sm:px-6 lg:px-4">
              <p className="text-sm sm:text-base text-gray-700">
                Mounting Details
              </p>
              <div className="overflow-hidden">
                {loading ? (
                  <>
                    <div className="hidden lg:grid lg:gap-2 mb-4">
                      {[...Array(4).keys()]?.map((item) => {
                        return (
                          <div className="bg-white flex items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
                            <div className="w-full">
                              <div className="w-full grid grid-cols-8 gap-2">
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mb-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mb-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-8 gap-2">
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mt-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1 mt-2">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="mt-2 col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="lg:hidden grid gap-4">
                      {[...Array(4).keys()]?.map((item) => {
                        return (
                          <div className="bg-white flex items-center gap-2 sm:flex-row flex-col shadow overflow-hidden py-4 sm:px-6 px-4">
                            <div className="w-full">
                              <div className="grid grid-cols-2 gap-2">
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>

                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  <Skeleton height={"100%"} />
                                </div>
                                <div className="col-span-1">
                                  {/* <Skeleton height={"100%"} /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div>
                    {itemData?.details?.map((item) => {
                      return (
                        <>
                          <div className="hidden bg-white lg:block mt-4 shadow hover:shadow-md py-4 sm:px-6 px-4">
                            <div className="w-full lg:flex items-center lg:gap-4 gap-2 sm:flex-row flex-col overflow-hidden">
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    {item?.MouDetId} Hii
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.MouDetNo}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Center Stone
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {currentSize?.MainStoneShape || "N/A"}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Gr.Wt
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.GrWt}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Nt.Wt
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.NtWt}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Stone Wt
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.DiaWt}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Quantity
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.DiaPcs}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Price
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.SalePrice}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div className="w-full grid gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-1 col-span-1">
                                    Loc
                                  </div>
                                </div>
                                <div className="w-full grid gap-2">
                                  <div className="font-normal xl:text-sm text-[13px] mt-1 sm:col-span-1 col-span-2">
                                    {item.Location}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                {selectedDiamond?.filter(
                                  (i) => i.productId === item?.MouDetId
                                )?.length <= 0 ? (
                                  <div
                                    className="flex items-center cursor-pointer w-6 sm:min-w-[125px] gap-[2px] justify-center bg-gray-700 text-gray-200 py-1 text-sm sm:text-xs"
                                    onClick={() => {
                                      navigate("/diamondlist", {
                                        state: {
                                          product: item?.MouDetId,
                                          attrData: {
                                            "Center Stone Shape": {
                                              name: currentSize?.MainStoneShape,
                                              id: shapes?.find(
                                                (i) =>
                                                  i.stAttributeValueName ===
                                                  currentSize?.MainStoneShape
                                              )?.MainStoneShape,
                                            },
                                          },
                                          productData: {
                                            designAttribute: [
                                              {
                                                unDesignAttributeIdentifier:
                                                  "9ad0af1a-c6a0-40c8-80c8-57a0eb492c44",
                                                unAttributeId: shapes?.find(
                                                  (i) =>
                                                    i.stAttributeValueName ===
                                                    currentSize?.MainStoneShape
                                                )?.MainStoneShape,
                                                stAttributeName:
                                                  "Center Stone Shape",
                                                flgIsSelectDiamond: 0,
                                                inSortOrder: 2,
                                              },
                                            ],
                                            designAttributeValue: [
                                              {
                                                unDesignAttributeIdentifier:
                                                  "9ad0af21-7027-43c3-b99d-b98b2b112dc7",
                                                unAttributeId:
                                                  "98c2306a-03a9-48c8-8c99-118d9905d828",
                                                unAttributeValueId:
                                                  "98d19ce7-bdc9-4cc8-be6b-dba3a1a52a8e",
                                                stAttributeName:
                                                  "Center Stone Shape",
                                                stAttributeValueName: "OVAL",
                                                stAttributeValueAlias: "OV",
                                                FromCarat: "0.00",
                                                ToCarat: "0.00",
                                                stImageURL:
                                                  "https://res.cloudinary.com/dbdpfhov6/image/upload/v1699594224/yoc8kco08a7u3x4gawhv.png",
                                                inSortOrder: 5,
                                              },
                                            ],
                                          },
                                          centerStoneRange: {
                                            name: "1.00-1.99ct",
                                            id: "99f3617b-db74-4b00-be48-67e1f7e5aac2",
                                            dcFromCarat: "1.00",
                                            dcToCarat: "1.99",
                                          },
                                          metalPurity: {
                                            name: "14K",
                                            id: "9acb2191-cab4-4a4a-b72e-3d336d372c43",
                                            dcPrice: "37.60",
                                          },
                                          prevPath: `/mounting/${id}`,
                                        },
                                      });
                                    }}
                                  >
                                    <IoDiamond />
                                    <p className="hidden sm:block">
                                      Select Main Stone
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    className="flex items-center cursor-pointer w-6 sm:min-w-[125px] gap-[2px] justify-center bg-gray-700 text-gray-200 py-1 text-sm sm:text-xs"
                                    onClick={() => {
                                      handleAddToCart(item);
                                    }}
                                  >
                                    <MdShoppingCart />
                                    <p className="hidden sm:block">
                                      Add To Cart
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            {!!selectedDiamond?.filter(
                              (i) => i?.productId === item?.MouDetId
                            )?.length && (
                              <div className="w-full">
                                <div className="my-4 mb-2.5 border-t border-dashed">
                                  <div className="grid sm:grid-cols-3 grid-cols-4 gap-4 mt-2.5">
                                    {selectedDiamond
                                      ?.filter(
                                        (i) => i?.productId === item?.MouDetId
                                      )
                                      .map((dia, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="text-sm text-gray-700 col-span-1"
                                          >
                                            <div className="flex items-center gap-4 border p-2 pr-4 rounded relative">
                                              <div className="overflow-hidden">
                                                {dia.ImageUrl !== null ? (
                                                  <img
                                                    src={dia.ImageUrl}
                                                    alt="ImageUrl"
                                                    className="w-[60px] h-[60px] object-scale-down rounded"
                                                  />
                                                ) : (
                                                  <div className="w-[60px] h-[60px] bg-slate-200 rounded"></div>
                                                )}
                                              </div>
                                              <div>
                                                <div className="text-[13px]">
                                                  <span className="font-[600]">
                                                    Shape:
                                                  </span>{" "}
                                                  {dia.SHAPE}
                                                </div>
                                                <div className="flex items-center">
                                                  <div className="pr-2 text-[13px]">
                                                    <span className="font-[600]">
                                                      Carat:
                                                    </span>{" "}
                                                    {dia.CARAT}
                                                  </div>
                                                  <div className="text-[13px]">
                                                    <span className="font-[600]">
                                                      Colour:
                                                    </span>{" "}
                                                    {dia.COLOUR}
                                                  </div>
                                                </div>
                                                <div className="text-[13px]">
                                                  <span className="font-[600]">
                                                    Clarity:
                                                  </span>{" "}
                                                  {dia.CLARITY}
                                                </div>
                                              </div>
                                              <div className="absolute top-[5px] right-[10px]">
                                                <button
                                                  onClick={() =>
                                                    handleRemoveDiamond(
                                                      dia.StoneNo
                                                    )
                                                  }
                                                >
                                                  <IoMdClose />
                                                </button>
                                              </div>
                                            </div>
                                            {/* <span className="font-[500]"></span> {dia.SHAPE}, {dia.CARAT}, {dia.COLOUR}, {dia.CLARITY} */}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="lg:hidden bg-white flex items-center gap-2 sm:flex-row flex-col shadow overflow-hidden hover:shadow-md py-4 sm:px-6 px-4 mt-2">
                            <div className="w-full">
                              <div className="grid grid-cols-2 gap-1">
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  {item.MouDetId}
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  {item.MouDetNo}
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Center Stone :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {currentSize?.MainStoneShape || "N/A"}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Stone Wt :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.DiaWt}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Gr.Wt :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.GrWt}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Nt.Wt :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.NtWt}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Quantity :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.DiaPcs}
                                  </span>
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                  Price :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.SalePrice}
                                  </span>
                                </div>
                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                  Loc :{" "}
                                  <span className="font-normal xl:text-[14px] text-[13px]">
                                    {item.Location}
                                  </span>
                                </div>
                                <div className="text-[12px] col-span-2">
                                  {item.DiaPcs > 0 ? (
                                    <div
                                      className="flex items-center cursor-pointer w-full gap-[2px] justify-center bg-gray-700 text-gray-200 py-1 text-xs"
                                      onClick={() => {
                                        navigate(`/diamondlist`, {
                                          state: {},
                                        });
                                      }}
                                    >
                                      <IoDiamond />
                                      <p className="">Select Main Stone</p>
                                    </div>
                                  ) : (
                                    <div
                                      className="flex items-center cursor-pointer w-full gap-[2px] justify-center bg-gray-700 text-gray-200 py-1 text-xs"
                                      onClick={() => {
                                        handleAddToCart(item);
                                      }}
                                    >
                                      <MdShoppingCart />
                                      <p className="">Add To Cart</p>
                                    </div>
                                  )}
                                </div>
                                <div className="font-bold col-span-1 sm:text-sm text-[12px]"></div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default memo(TableList);
