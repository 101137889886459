import React, { useEffect } from "react";
import AdminModal from "../common/AdminModal";
import useApi from "../../../Hooks/use-Api";
import { addUserRate, getUserRate } from "../../../Services/userRateService";
import { useState } from "react";
import { everyNumber, numberRegexWithDecimals } from "../../shared/constants";
import { toast } from "react-hot-toast";
import Loader from "../common/Loader";

const defaultVal = [
  {
    unUserID: "",
    inUserRateID: "1",
    dcPercentage: "0.00",
    dcFromAmount: "0.00",
    dcToAmount: "500.00",
  },
  {
    unUserID: "",
    inUserRateID: "2",
    dcPercentage: "0.00",
    dcFromAmount: "501.00",
    dcToAmount: "1000.00",
  },
  {
    unUserID: "",
    inUserRateID: "3",
    dcPercentage: "0.00",
    dcFromAmount: "1001.00",
    dcToAmount: "1500.00",
  },
];

const UserMargin = ({ isOpen, setIsOpen, data, setData }) => {
  const [marginVal, setMarginVal] = useState([]);
  const [fetchMargin, marginData, loading, error] = useApi(getUserRate);

  useEffect(() => {
    if (data) {
      fetchMargin(data.unUserIdentifier);
      const defaultData = defaultVal.map((item) => ({
        ...item,
        unUserID: data.unUserIdentifier,
      }));
      setMarginVal(defaultData);
    }
  }, [fetchMargin, data]);

  useEffect(() => {
    if (marginData && marginData.user_rate.length) {
      setMarginVal(marginData.user_rate);
    }
  }, [marginData]);

  const handleChange = (val, e) => {
    const { name, value } = e.target;
    if (
      everyNumber.test(value) &&
      // parseFloat(value || 0) >= 0 &&
      parseFloat(value || 0) <= 100
    ) {
      setMarginVal((prev) =>
        prev.map((item) =>
          item.inUserRateID === val.inUserRateID
            ? { ...val, [name]: value }
            : item
        )
      );
    }
  };
  const handleSubmit = async () => {
    try {
      const payload = new FormData();
      payload.append("unUserID", data.unUserIdentifier);
      // payload.append("data", JSON.stringify(marginVal));
      marginVal.forEach((item, index) => {
        Object.entries(item).forEach(([key, val]) => {
          payload.append(`data[${index}][${key}]`, val);
        });
      });
      const response = await addUserRate(payload);
      if (response.data.success) {
        toast.success("Margin updated successfully.");
        handleClose();
      }
    } catch (err) {
      toast.error("Something went wrong.");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setMarginVal([]);
    setData(null);
  };

  return (
    <AdminModal
      title={"User Margin"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <div className="mb-3">
        <div className="flex justify-start items-center gap-2">
          <h3 className="text-sm w-1/4">User Name</h3>
          <span>:</span>
          <p className="text-base font-[500] w-3/4">
            {data?.stFirstName} {data?.stLastName}
          </p>
        </div>
        <div className="flex justify-start items-center gap-2">
          <h3 className="text-sm w-1/4">User Email</h3>
          <span>:</span>
          <p className="text-base font-[500] w-3/4">{data?.stEmail}</p>
        </div>
      </div>
      <div>
        <table className="w-full text-sm text-left border border-[#e2e8f0] text-gray-500 dark:text-gray-400">
          <thead className="bg-[#e2e8f0]">
            <tr>
              <th className="px-2 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                From Amount
              </th>
              <th className="px-2 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                To Amount
              </th>
              <th className="px-2 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                Margin
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              !!marginVal?.length &&
              marginVal.map((margin, index) => {
                return (
                  <tr key={index}>
                    <td className="px-2 py-1 text-xs font-bold text-left text-gray-700 ">
                      {margin.dcFromAmount}
                    </td>
                    <td className="px-2 py-1 text-xs font-bold text-left text-gray-700 ">
                      {margin.dcToAmount}
                    </td>
                    <td className="px-2 py-1 text-xs font-bold text-left text-gray-700 ">
                      <div className="">
                        <input
                          type="text"
                          name="dcPercentage"
                          value={margin.dcPercentage}
                          id="dcPercentage"
                          className="block w-20 outline-none rounded text-xs border-0 py-1 focus:px-3 text-gray-700 focus:ring-1 focus:ring-inset focus:ring-gray-300 placeholder:text-gray-400"
                          onChange={handleChange.bind(null, margin)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            {loading && (
              <tr>
                <td colSpan={3}>
                  <div className="flex justify-center items-center h-24">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </AdminModal>
  );
};

export default UserMargin;
