import React from "react";
import Banner from "../../assets/images/Banner.png";

const ProductBanner = ({ className }) => {
  return (
    <div className={`${className}`}>
      <div className="mt-[20px]">
        <img src={Banner} alt="Banner" className="w-full object-cover" />
      </div>
    </div>
  );
};

export default ProductBanner;
