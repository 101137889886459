import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  HeartIcon,
  CubeTransparentIcon,
  WalletIcon,
  HomeIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/20/solid";
import { GrHomeRounded } from "react-icons/gr";
import cartIcon from "../../../assets/images/cart.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { fetchCalibratedData } from "../../../store/Slices/calibratedSlice";
import {
  fetchMountingMenu,
  fetchMountingList,
  getMountingState,
} from "../../../store/Slices/mountingSlice";
import { selectCartState, openCart } from "../../../store/Slices/cartSlice";

import { useDispatch, useSelector } from "react-redux";

import CardList from "./cardList";
import TableList from "./tableList";
import CartDrawer from "./cartDrawer";
import { useCallback } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Mounting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const shapes = useSelector((state) => state?.calibrated?.data?.Shapes);
  const menuData = useSelector((state) => state?.mounting?.menuData);
  const { cart, cartDrawer } = useSelector(selectCartState);

  const [state, setState] = useState({
    currentNavKey: null,
    currentShape: null,
    currentSize: {},
    standardData: [],
    pairsData: [],
    loading: true,
  });

  let {
    currentNavKey,
    currentShape,
    currentSize,
    standardData,
    pairsData,
    loading,
  } = state;

  const getData = async () => {
    await dispatch(fetchMountingMenu());
  };

  const handleOpenCart = () => {
    dispatch(openCart(true));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    getData();
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }, []);

  // useEffect(() => {
  //   if (shapes?.length) {
  //     let standardList = [];
  //     let pairsList = [];

  //     let shapeList = shapes
  //       ?.map((item) => item)
  //       ?.sort((a, b) => ((a?.SortOrder || 2) > (b?.SortOrder || 2) ? 1 : -1))
  //       ?.map((item, i) => {
  //         if (item?.ShapeType?.toLowerCase() === "standard") {
  //           standardList.push({
  //             key: i,
  //             shapeCode: item?.ShapeCode,
  //             name: item?.Shape,
  //             href: "#",
  //             icon: item?.ImageURL,
  //             current: false,
  //             shapeSieveMM: item?.ShapeSieveMM,
  //           });
  //         } else {
  //           pairsList.push({
  //             key: i,
  //             shapeCode: item?.ShapeCode,
  //             name: item?.Shape,
  //             href: "#",
  //             icon: item?.ImageURL,
  //             current: false,
  //             shapeSieveMM: item?.ShapeSieveMM,
  //           });
  //         }

  //         return {
  //           key: i,
  //           shapeCode: item?.ShapeCode,
  //           name: item?.Shape,
  //           href: "#",
  //           icon: item?.ImageURL,
  //           current: false,
  //           shapeSieveMM: item?.ShapeSieveMM,
  //         };
  //       });

  //     setState((prevState) => ({
  //       ...prevState,
  //       standardData: standardList,
  //       pairsData: pairsList,
  //       currentNavKey: shapeList[0]?.key,
  //       currentShape: shapeList[0],
  //     }));
  //   }
  // }, [shapes]);

  useEffect(() => {
    if (menuData && !id) {
      setState((prevState) => ({
        ...prevState,
        currentNavKey: menuData?.Shape?.[0]?.MainStoneShape,
        // currentShape: menuData?.Shape?.[0],
      }));
    }
  }, [menuData,id]);

  useEffect(() => {
    dispatch(fetchMountingList());
  }, [dispatch]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleCurrentNavKey = (key) => {
    setState((prevState) => ({ ...prevState, currentNavKey: key }));
  };

  const handleCurrentSize = (size) => {
    setState((prevState) => ({ ...prevState, currentSize: size }));
  };

  const renderComponentAccordingTab = useCallback(() => {
    switch (currentNavKey) {
      case "home":
        return (
          <div className="p-4">
            <p className="mb-6 sm:mb-[14px] sm:px-0 px-6 break-normal text-justify">
              Decades of experience in diamond industry and close work with
              rough growers for years, DTN understands it all when it comes to
              lab-grown diamonds Decades of experience in diamond industry and
              close work with rough growers for years, DTN understands it all
              when it comes to lab-grown diamonds Decades of experience in
              diamond industry and close work with rough growers for years, DTN
              understands it all when it comes to lab-grown diamonds Decades of
              experience in diamond industry and close work with rough growers
              for years, DTN understands it all when it comes to lab-grown
              diamonds
            </p>
          </div>
        );
      case "table-list":
        return (
          <TableList
            parentState={state}
            handleCurrentNavKey={handleCurrentNavKey}
          />
        );
      default:
        return (
          <CardList
            handleCurrentNavKey={handleCurrentNavKey}
            handleCurrentSize={handleCurrentSize}
            parentState={state}
          />
        );
    }
  }, [currentNavKey]);

  useEffect(()=>{
    if (id) {
      setState((prevState) => ({ ...prevState, currentNavKey: "table-list" }));
    }
  },[id])


  return (
    <>
      <div class="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
        <h1 class="text-5xl text-white font-bold mb-8 animate-pulse">
          Coming Soon
        </h1>
        <Link to={"/"} className="bg-white py-2 px-4 rounded ">
          Go Back
        </Link>
      </div>

      <div className="hidden">
        <CartDrawer />

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 xl:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center justify-center font-dancingScript">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      /> */}
                      <p class=" text-gray-100 font-dancingScript capitalize text-2xl">
                        Mounting
                      </p>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          {/* <li
                            className="-mx-2 space-y-0 pb-2 flex items-center cursor-pointer"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            <HomeIcon className="h-4 w-4 text-gray-400" />
                            <div className="text-gray-400 text-base pl-1">
                              Home
                            </div>
                          </li> */}

                          {!!menuData?.Shape?.length && (
                            <ul role="list" className="-mx-2 space-y-1">
                              {/* <li className="border-b border-gray-800 py-1">
                                <div className="text-gray-200 text-lg">
                                  Stone Shapes
                                </div>
                              </li> */}

                              {/* {standardData?.map((item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      currentShape: item,
                                      currentNavKey: item.key,
                                    }));
                                    setSidebarOpen(false);
                                  }}
                                >
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.key === currentNavKey ||
                                        item.key === currentShape?.key
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  > */}
                              {/* <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  /> */}

                              {/* <img
                                      src={`${
                                        item.icon || "/images/icons/Asscher.png"
                                      }`}
                                      className="h-6 w-6 shrink-0 invert opacity-60"
                                    /> */}

                              {/* {item?.name?.charAt(0)?.toUpperCase() +
                                      item?.name?.slice(1)?.toLowerCase()}
                                  </a>
                                </li>
                              ))} */}

                              {loading ? (
                                [...Array(5).keys()]?.map((i) => {
                                  return (
                                    <div
                                      role="status"
                                      class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                                    >
                                      <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                                      <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                                    </div>
                                  );
                                })
                              ) : (
                                <Disclosure as="div" defaultOpen>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                          "flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-200 border-b border-gray-700"
                                        )}
                                      >
                                        {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
                                        Stone Shapes
                                        <ChevronRightIcon
                                          className={classNames(
                                            open
                                              ? "rotate-90 text-gray-500"
                                              : "text-gray-400",
                                            "ml-auto h-5 w-5 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel
                                        as="ul"
                                        className="mt-1 px-2"
                                      >
                                        {menuData?.Shape?.map((item) => (
                                          <li
                                            key={item.MainStoneShape}
                                            onClick={() => {
                                              setState((prevState) => ({
                                                ...prevState,
                                                currentShape: item,
                                                currentNavKey:
                                                  item.MainStoneShape,
                                              }));
                                              setSidebarOpen(false);
                                            }}
                                            className="py-0.5 cursor-pointer"
                                          >
                                            <a
                                              href={item.href}
                                              className={classNames(
                                                item.MainStoneShape ===
                                                  currentShape?.MainStoneShape
                                                  ? "bg-gray-800 text-white"
                                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize"
                                              )}
                                            >
                                              {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                              {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                              {item?.stAttributeValueName
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                item?.stAttributeValueName
                                                  ?.slice(1)
                                                  ?.toLowerCase()}
                                            </a>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </ul>
                          )}

                          {!!menuData?.Category?.length && (
                            <ul role="list" className="-mx-2 space-y-1">
                              {/* <li className="border-b border-gray-800 py-3">
                                <div className="text-gray-200 text-lg">
                                  Category
                                </div>
                              </li> */}

                              {/* {categoryList?.map((item) => (
                                <li
                                  key={item.name}
                                  onClick={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      currentShape: item,
                                      currentNavKey: item.key,
                                    }));
                                    setSidebarOpen(false);
                                  }}
                                >
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.key === currentNavKey ||
                                        item.key === currentShape?.key
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  > */}
                              {/* <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  /> */}

                              {/* <img
                                      src={`${
                                        item.icon || "/images/icons/Asscher.png"
                                      }`}
                                      className="h-6 w-6 shrink-0 invert opacity-60"
                                    /> */}

                              {/* {item?.name?.charAt(0)?.toUpperCase() +
                                      item?.name?.slice(1)?.toLowerCase()}
                                  </a>
                                </li>
                              ))} */}

                              {loading ? (
                                [...Array(5).keys()]?.map((i) => {
                                  return (
                                    <div
                                      role="status"
                                      class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                                    >
                                      <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                                      <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                                    </div>
                                  );
                                })
                              ) : (
                                <Disclosure as="div">
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                          "mt-2 flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-200 border-b border-gray-700"
                                        )}
                                      >
                                        {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
                                        Category
                                        <ChevronRightIcon
                                          className={classNames(
                                            open
                                              ? "rotate-90 text-gray-500"
                                              : "text-gray-400",
                                            "ml-auto h-5 w-5 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel
                                        as="ul"
                                        className="mt-1 px-2"
                                      >
                                        {menuData?.Category?.map((item) => (
                                          <li
                                            key={item.CategoryId}
                                            onClick={() => {
                                              setState((prevState) => ({
                                                ...prevState,
                                                currentShape: item,
                                                currentNavKey: item.CategoryId,
                                              }));
                                              setSidebarOpen(false);
                                            }}
                                            className="py-0.5 cursor-pointer"
                                          >
                                            <a
                                              href={item.href}
                                              className={classNames(
                                                item.CategoryId ===
                                                  currentShape?.CategoryId
                                                  ? "bg-gray-800 text-white"
                                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize"
                                              )}
                                            >
                                              {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                              {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                              {item?.stCategoryName
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                item?.stCategoryName
                                                  ?.slice(1)
                                                  ?.toLowerCase()}
                                            </a>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </ul>
                          )}
                        </li>
                        {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                        {/* <li className="-mx-6 mt-auto">
                          <a
                            href="#"
                            className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                          >
                            <img
                              className="h-8 w-8 rounded-full bg-gray-800"
                              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            />
                            <span className="sr-only">Your profile</span>
                            <span aria-hidden="true">Tom Cook</span>
                          </a>
                        </li> */}
                        {/* <li className="-mx-6 mt-auto cursor-pointer">
                          <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800">
                            <span aria-hidden="true">Place Order</span>
                          </div>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-gray-900 px-6 ring-1 ring-white/10">
            <div className="flex h-16 shrink-0 items-center justify-center">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
              /> */}

              <p class="text-gray-100 font-dancingScript capitalize text-2xl">
                Mounting
              </p>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  {/* <li
                    className="-mx-2 space-y-0 pb-2 flex items-center cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <HomeIcon className="h-4 w-4 text-gray-400" />
                    <div className="text-gray-400 text-base pl-1">Home</div>
                  </li> */}

                  {!!menuData?.Shape?.length && (
                    <ul role="list" className="-mx-2 space-y-1">
                      {/* <li className="border-b border-gray-800 py-1">
                        <div className="text-gray-200 text-lg">
                          By Stone Shapes
                        </div>
                      </li> */}

                      {/* {loading
                        ? [...Array(5).keys()]?.map((i) => {
                            return (
                              <div
                                role="status"
                                class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                              >
                                <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                                <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                              </div>
                            );
                          })
                        : standardData?.map((item) => (
                            <li
                              key={item.name}
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  currentShape: item,
                                  currentNavKey: item.key,
                                }));
                              }}
                            >
                              <a
                                href={item.href}
                                className={classNames(
                                  item.key === currentNavKey ||
                                    item.key === currentShape?.key
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              > */}
                      {/* <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      /> */}

                      {/* <img
                                  src={`${
                                    item.icon || "/images/icons/Asscher.png"
                                  }`}
                                  className="h-6 w-6 shrink-0 invert opacity-60"
                                /> */}
                      {/* {item?.name?.charAt(0)?.toUpperCase() +
                                  item?.name?.slice(1)?.toLowerCase()}
                              </a>
                            </li>
                          ))} */}

                      {loading ? (
                        [...Array(5).keys()]?.map((i) => {
                          return (
                            <div
                              role="status"
                              class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                            >
                              <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                              <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                            </div>
                          );
                        })
                      ) : (
                        <Disclosure as="div" defaultOpen>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                  "flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-200 border-b border-gray-700"
                                )}
                              >
                                {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
                                Stone Shapes
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-400",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                <li
                                  key={"allstone"}
                                  onClick={() => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      currentShape: null,
                                      currentNavKey: null,
                                    }));
                                  }}
                                  className="py-0.5 cursor-pointer"
                                >
                                  <p
                                    className={classNames(
                                      !currentShape
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                                      " text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize"
                                    )}
                                  >
                                    All
                                  </p>
                                </li>
                                {menuData?.Shape?.map((item) => (
                                  <li
                                    key={item.MainStoneShape}
                                    onClick={() => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        currentShape: item,
                                        currentNavKey: item.MainStoneShape,
                                      }));
                                    }}
                                    className="py-0.5 cursor-pointer"
                                  >
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.MainStoneShape ===
                                          currentShape?.MainStoneShape
                                          ? "bg-gray-800 text-white"
                                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                                        " text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize"
                                      )}
                                    >
                                      {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                      {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                      {item?.stAttributeValueName
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                        item?.stAttributeValueName
                                          ?.slice(1)
                                          ?.toLowerCase()}
                                    </a>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </ul>
                  )}

                  {!!menuData?.Category?.length && (
                    <ul role="list" className="-mx-2 space-y-1">
                      {/* <li className="border-b border-gray-800 py-3">
                        <div className="text-gray-200 text-lg">By Category</div>
                      </li> */}

                      {/* {loading
                        ? [...Array(5).keys()]?.map((i) => {
                            return (
                              <div
                                role="status"
                                class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                              >
                                <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                                <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                              </div>
                            );
                          })
                        : categoryList?.map((item) => (
                            <li
                              key={item.name}
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  currentShape: item,
                                  currentNavKey: item.key,
                                }));
                              }}
                            >
                              <a
                                href={item.href}
                                className={classNames(
                                  item.key === currentNavKey ||
                                    item.key === currentShape?.key
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              > */}
                      {/* <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      /> */}

                      {/* <img
                                  src={`${
                                    item.icon || "/images/icons/Asscher.png"
                                  }`}
                                  className="h-6 w-6 shrink-0 invert opacity-60"
                                /> */}
                      {/* {item?.name?.charAt(0)?.toUpperCase() +
                                  item?.name?.slice(1)?.toLowerCase()}
                              </a>
                            </li>
                          ))} */}

                      {loading ? (
                        [...Array(5).keys()]?.map((i) => {
                          return (
                            <div
                              role="status"
                              class="flex item-center justify-evenly max-w-sm rounded-lg animate-pulse dark:bg-gray-700 p-1"
                            >
                              <div class="w-5 h-4 bg-gray-100 rounded-full dark:bg-gray-700 m-2"></div>
                              <div class="h-2.5 w-full bg-gray-100 rounded-full dark:bg-gray-700 m-3"></div>
                            </div>
                          );
                        })
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  // item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                  "mt-2 flex items-center w-full text-left p-2 pl-0 gap-x-3 text-lg leading-6 text-gray-200 border-b border-gray-700"
                                )}
                              >
                                {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
                                Category
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-500"
                                      : "text-gray-400",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {menuData?.Category?.map((item) => (
                                  <li
                                    key={item.CategoryId}
                                    onClick={() => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        currentShape: item,
                                        currentNavKey: item.CategoryId,
                                      }));
                                    }}
                                    className="py-0.5 cursor-pointer"
                                  >
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.CategoryId ===
                                          currentShape?.CategoryId
                                          ? "bg-gray-800 text-white"
                                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                                        "text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 capitalize"
                                      )}
                                    >
                                      {/* <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        /> */}

                                      {/* <img
                                    src={`${
                                      item.icon || "/images/icons/Asscher.png"
                                    }`}
                                    className="h-6 w-6 shrink-0 invert opacity-60"
                                  /> */}
                                      {item?.stCategoryName
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                        item?.stCategoryName
                                          ?.slice(1)
                                          ?.toLowerCase()}
                                    </a>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </ul>
                  )}
                </li>
                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}
                {/* <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Tom Cook</span>
                  </a>
                </li> */}
                {/* <li className="-mx-6 mt-auto cursor-pointer">
                  <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800">
                    <span aria-hidden="true">Place Order</span>
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div className="xl:pl-72">
          {/* Sticky search header */}
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-white xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 items-center justify-center">
              <header className="sm:text-center  md:text-3xl sm:text-xl text-[14px] md:my-6 text-gray-100">
                {/* DTN
                <span className="font-dancingScript font-bold">
                  {" "}
                  Premium Quality{" "}
                </span> */}
                {/* CALIBRATED DIAMONDS */}

                <span className="font-montserrat font-[700] lg:text-[30px] sm:text-[24px] uppercase block text-[12px] text-[#FFF] sm:text-center text-left pr-16 md:pr-0">
                  Diamond Trading Network
                </span>

                <div className="absolute right-5 top-[22px] flex gap-4">
                  <GrHomeRounded
                    className="h-5 w-5 cursor-pointer invert"
                    onClick={(e) => navigate("/")}
                  />

                  <span
                    onClick={() => handleOpenCart()}
                    className="cursor-pointer relative flex items-center"
                  >
                    <img src={cartIcon} alt="cart" className="invert" />{" "}
                    <span className="flex justify-center items-center absolute -top-[45%] -right-[40%] w-4 h-4 rounded-full bg-[#295268] text-white text-xs">
                      {cart?.length}
                    </span>
                  </span>
                </div>
              </header>
            </div>
          </div>

          <main className="bg-gray-100">
            {/* Card List */}
            <div className="w-full">
              <div className="p-4 pb-3 hidden">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    {/* <li onClick={() => navigate("/")}>
                      <div>
                        <a
                          href="#"
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li> */}
                    {/* {pages.map((page) => (
                    <li key={page.name}>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={page.href}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          aria-current={page.current ? "page" : undefined}
                        >
                          {page.name}
                        </a>
                      </div>
                    </li>
                  ))} */}
                    {/* <li>
                      <div className="flex items-center">
                        <ChevronRightIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <a
                          href={"#"}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                          aria-current={undefined}
                          onClick={()=>handleCurrentNavKey('home')}
                        >
                          Calibrated
                        </a>
                      </div>
                    </li> */}
                    {currentShape?.name && currentNavKey !== "home" && (
                      <li
                        onClick={() => handleCurrentNavKey(currentShape?.key)}
                      >
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <a
                            href={"#"}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={undefined}
                          >
                            {currentShape?.name}
                          </a>
                        </div>
                      </li>
                    )}

                    {currentShape?.name && currentNavKey === "table-list" && (
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <a
                            href={"#"}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={undefined}
                          >
                            List
                          </a>
                        </div>
                      </li>
                    )}
                  </ol>
                </nav>
              </div>

              {renderComponentAccordingTab()}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
