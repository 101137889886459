import api from "./api"

const getAttributeValueData = () => {
  return api("/api/AttributeValues", "get")
}
const getAttributeValueById = (id) => {
  return api(`/api/AttributeValues/${id}`, "get")
}
const getAttributeValueByAttribute = (id) => {
  return api(`/api/AttributeValuesByAttribute/${id}`, "get")
}

const createAttributeValueData = (data) => {
  return api("/api/AttributeValues", "post", data)
}

const updateAttributeValueData = (data) => {
  return api("/api/AttributeValues", "put", data)
}

const DeleteAttributeValueData = (data) => {
  return api(`/api/AttributeValues/${data}`, "delete")
}

export {
  getAttributeValueData,
  createAttributeValueData,
  updateAttributeValueData,
  DeleteAttributeValueData,
  getAttributeValueById,
  getAttributeValueByAttribute
}