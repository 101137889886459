import React, { useMemo } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { GetDesignByVariation } from "../../../Services/designService";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { getOrderwithId } from "../../../Services/orderService";

const ViewOrder = ({
  isOpen,
  setIsOpen,
  orderId,
  setOrderId,
  order,
  setOrder,
}) => {
  const componentRef = useRef();
  const data = {
    unOrderIdentifier: "99ba7dca-99c3-4eb6-a4d6-fd94cc3c5525",
    dtOrderDate: "2023-07-25 00:00:00.000000",
    inOrderNo: "1",
    stOrderNo: "O230725-001",
    dcOrderPcs: "1",
    dcOrderQty: "1.00",
    dcOrderAmount: "10300.00",
    stOrderRemark: "",
    dtCreationDate: "2023-07-25 00:00:00.000000",
    unUserId: "98c2f0b9-e33a-4c47-8f94-1982a747e13c",
    unFollowUpStatusId: null,
    unFollowUpId: null,
    flgIsCancelOrder: "0",
    stCancelRemark: null,
    user: {
      unUserIdentifier: "98c2f0b9-e33a-4c47-8f94-1982a747e13c",
      stUserName: "admin",
      stFirstName: "Super",
      stLastName: "Admin",
      stPhoneNumber: "9876543210",
      stEmail: "admin@gmail.com",
      stCompanyName: "test",
      stAddress: "test",
      unCityId: "1",
      unStateId: "1",
      unCountryId: "1",
      stPinCode: "395006",
      flgIsActive: "1",
      unRoleId: "10891a8e-ed70-11ed-8fa7-52543dc17722",
      dcStoneDisc: "0.00",
      dcJewelleryDisc: "0.00",
      unCreatedDate: "2023-03-24 03:58:17.000000",
      unCreatedBy: null,
      dtModifiedDate: "2023-07-19 04:02:05.000000",
      unModifiedBy: "98c2f0b9-e33a-4c47-8f94-1982a747e13c",
      email_verified_at: null,
    },
    shipping: {
      unOrderId: "99ba7dca-99c3-4eb6-a4d6-fd94cc3c5525",
      stAddress1: "31 JAMANA NAGAR SOCI,.",
      stAddress2: "PUNAGAM ROAD",
      stAddress3: "",
      stPinCode: "395010",
      stCity: "SURAT",
      stState: "GUJARAT",
      stCountry: "India",
      stPhoneNo: "09913715670",
    },
    details: [
      {
        unOrderDetailIdentifier: "99ba7dca-9fbf-4348-8730-c2bd426a62d0",
        unOrderId: "99ba7dca-99c3-4eb6-a4d6-fd94cc3c5525",
        inSrNumber: "1",
        stArticleType: "setting",
        unStockId: "",
        unDesignVariationId: "99b07e69-beaa-4ad3-ae82-92d6a786bdfb",
        stDetRemark1: null,
        stDetRemark2: null,
        stSize: "1",
        stEngravingFont: "",
        stEngraving: null,
        dcDetQty: "1.00",
        dcRate: null,
        dcAmount: "10300.00",
        flgIsCancelDetail: null,
        stCancelRemark: null,
      },
      {
        unOrderDetailIdentifier: "99ba7dca-a061-4dfd-986a-e846e3325344",
        unOrderId: "99ba7dca-99c3-4eb6-a4d6-fd94cc3c5525",
        inSrNumber: "2",
        stArticleType: "diamond",
        unStockId: "99a45e31-9413-48a3-a8b4-343c0122c3a0",
        unDesignVariationId: "",
        stDetRemark1: null,
        stDetRemark2: null,
        stSize: "",
        stEngravingFont: "",
        stEngraving: null,
        dcDetQty: "0.00",
        dcRate: null,
        dcAmount: "0.00",
        flgIsCancelDetail: null,
        stCancelRemark: null,
      },
      {
        unOrderDetailIdentifier: "99ba7dca-a0fd-4b96-822a-f8bfa1df662b",
        unOrderId: "99ba7dca-99c3-4eb6-a4d6-fd94cc3c5525",
        inSrNumber: "3",
        stArticleType: "diamond",
        unStockId: "99a45e31-20fd-425f-a475-56daa54c31a8",
        unDesignVariationId: "",
        stDetRemark1: null,
        stDetRemark2: null,
        stSize: "",
        stEngravingFont: "",
        stEngraving: null,
        dcDetQty: "0.00",
        dcRate: null,
        dcAmount: "0.00",
        flgIsCancelDetail: null,
        stCancelRemark: null,
      },
    ],
  };
  const dispatch = useDispatch();
  const [productdata, setProductData] = useState(null);

  const handleGetData = async (id) => {
    const res = await getOrderwithId(id);
    if (res.status === 200) {
      setProductData(res.data.data);
    }
  };

  useEffect(() => {
    if (orderId) {
      handleGetData(orderId);
    }
  }, [orderId]);

  const subTotal =
    productdata &&
    productdata?.setting.reduce(
      (accumulator, currentValue) =>
        +accumulator +
        +(
          +currentValue.Amount +
          (currentValue.diamond.length &&
            currentValue.diamond.reduce((acc, curr) => +acc + +curr.Amount, 0))
        ),
      0
    );

  const total = useMemo(() => {
    const tax = 0.0;
    return subTotal + tax;
  }, [subTotal]);

  const downloadPdf = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePDF = () => {
    downloadPdf();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative  transform overflow-hidden flex flex-col justify-between rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-6xl max-h-[85vh]`}
              >
                <div className=" ">
                  <div className="text-center sm:mt-0 sm:text-left">
                    <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                      <Dialog.Title
                        as="h3"
                        className="text-[20px] text-[#334155] font-[600] leading-6"
                      >
                        View Order
                      </Dialog.Title>
                      <button
                        type="button"
                        className="text-[20px] text-[#334155] font-[600] leading-6"
                        onClick={() => setIsOpen(false)}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`sm:px-6 sm:pt-2 px-4 pt-5 pb-4 h-full overflow-y-auto`}
                  id="modelScroll"
                >
                  <div className="rounded m-4" ref={componentRef}>
                    {/* <div className="text-center mb-2">
                      <h2 className="text-[24px] font-bold">DTN Diamond</h2>
                    </div> */}
                    <div className="flex items-start justify-between">
                      <div>
                        <h2 className="font-[500]">Order To:</h2>
                        <h2 className="font-[600] flex items-center gap-3">
                          <span className="font-[400]">M/s : </span>{" "}
                          {`${productdata?.user.stFirstName} ${productdata?.user.stLastName}`}
                        </h2>
                      </div>
                      <div className="text-right">
                        <h2 className="font-[600] flex items-center gap-3">
                          <span className="font-[400]">Order No. : </span>{" "}
                          {data?.stOrderNo}
                        </h2>
                        <h2 className="font-[600] flex items-center gap-3">
                          <span className="font-[400]">Order Date : </span>
                          {moment(productdata?.dtOrderDate).format("ll")}
                        </h2>
                      </div>
                    </div>
                    <div className="mt-8">
                      <table border={1} className="w-full border">
                        <tr>
                          <th className="w-[30px] px-4 py-2 border-r text-sm">
                            Sr.
                          </th>
                          <th className="w-[300px] px-4 py-2 border-r text-sm text-left">
                            Name
                          </th>
                          <th className="w-[300px] px-4 py-2 border-r text-sm">
                            Description
                          </th>
                          <th className="w-[50px] px-4 py-2 border-r text-sm text-right">
                            Qt.
                          </th>
                          <th className="w-[80px] px-4 py-2 border-r text-sm text-right">
                            Price
                          </th>
                          <th className="w-[80px] px-4 py-2 text-sm text-right">
                            Amount
                          </th>
                        </tr>
                        {productdata?.setting.length > 0 &&
                          productdata?.setting.map((item, i) => {
                            return (
                              <tr key={i} className="border-t">
                                <td className="w-[30px] px-4 py-2 border-r">
                                  {i + 1}
                                </td>
                                <td className="w-[300px] px-4 py-2 border-r text-sm text-gray-500 sm:table-cell">
                                  {item.remark.Name || ""}
                                </td>
                                <td className="w-[300px] px-4 py-2 border-r text-sm text-gray-500 sm:table-cell">
                                  {item.diamond.length === 0 ? (
                                    <>
                                      <h3 className="text-black font-semibold">
                                        Ring :-
                                      </h3>
                                      <h4>{`${item.remark.Metal}, ${item.remark.Shape}, ${item.remark.Band}`}</h4>
                                    </>
                                  ) : (
                                    <>
                                      <h3 className="text-black font-semibold">
                                        Ring :-
                                      </h3>
                                      <h4>{`${item.remark.Metal}, ${item.remark.Shape}, ${item.remark.Band}`}</h4>
                                      <h3 className="text-black font-semibold">
                                        Diamonds :-
                                      </h3>
                                      <ul className="list-disc px-4">
                                        {item.diamond.map((dia, inx) => {
                                          return (
                                            <li className="">{`${
                                              dia.StoneNo
                                            }, ${
                                              item.remark.Shape
                                                ? item.remark.Shape + ","
                                                : ""
                                            } ${dia.Carat}, ${
                                              dia.remark.Clarity
                                            }, ${dia.remark.Color}`}</li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  )}
                                </td>
                                <td className="w-[50px] px-4 py-2 border-r text-right text-sm text-gray-500 sm:table-cell">
                                  1
                                </td>
                                <td className="w-[80px] px-4 py-2 border-r text-right text-sm text-gray-500 sm:table-cell">
                                  $
                                  {(
                                    +item.Amount +
                                    (item.diamond.length &&
                                      item.diamond.reduce(
                                        (accumulator, currentValue) =>
                                          +accumulator + +currentValue.Amount,
                                        0
                                      ))
                                  ).toFixed(2) || 0}
                                </td>
                                <td className="w-[80px] px-4 py-2 text-right text-sm text-gray-500 sm:table-cell">
                                  $
                                  {(
                                    (+item.Amount +
                                      (item.diamond.length &&
                                        item.diamond.reduce(
                                          (accumulator, currentValue) =>
                                            +accumulator + +currentValue.Amount,
                                          0
                                        ))) *
                                    1
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                        <tfoot className="border-t">
                          {/* <tr>
                            <th
                              scope="row"
                              colSpan={5}
                              className="hidden px-4 py-2 border-l border-b text-right text-sm font-semibold text-gray-500 sm:table-cell sm:pl-0"
                            >
                              Subtotal
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-2 text-left border-l text-sm border-b font-semibold text-gray-500 sm:hidden"
                            >
                              Subtotal
                            </th>
                            <td className="px-4 py-2 text-right border-l border-b text-sm text-gray-500">
                              ${subTotal.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              colSpan={5}
                              className="hidden px-4 py-2 text-right border-l border-b text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                            >
                              Tax
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-2 text-left text-sm border-l border-b font-normal text-gray-500 sm:hidden"
                            >
                              Tax
                            </th>
                            <td className="px-4 py-2 text-right border-l border-b text-sm text-gray-500">
                              $0.00
                            </td>
                          </tr> */}
                          <tr>
                            <th
                              scope="row"
                              colSpan={5}
                              className="hidden px-4 py-2 text-right border-l text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                            >
                              Total
                            </th>
                            <th
                              scope="row"
                              className="px-4 py-2 text-left text-sm border-l font-semibold text-gray-900 sm:hidden"
                            >
                              Total
                            </th>
                            <td className="px-4 py-2 text-right text-sm border-l font-semibold text-gray-900">
                              ${subTotal?.toFixed(2)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="mt-4 text-right mx-4">
                    <button
                      type="button"
                      onClick={handlePDF}
                      className="bg-slate-500 px-4 py-2 rounded text-[#fff]"
                    >
                      Print
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewOrder;
