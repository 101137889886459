import React, { useMemo, useState } from "react";
import Pagination from "./Pagination";
import Drawer from "./common/Drawer";
import TextInputAdmin from "./common/TextInputAdmin";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { getOrderList } from "../../Services/orderService";
import Loader from "./common/Loader";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import usePagination from "../../Hooks/use-Pagination";
import useApi from "../../Hooks/use-Api";
import NoDataText from "../shared/NoDataText";
import moment from "moment";
import ViewOrder from "./Modals/ViewOrder";
import CancelOrder from "./Modals/CancelOrder";

const Order = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState(null);

  const [fetchOrderData, data, isLoading, error] = useApi(getOrderList);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: data ? data.order : [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  const currentWithoutCencelData = useMemo(() => {
    const current = currentData?.length > 0 && currentData.filter(item => item.flgIsCancelOrder !== 1)
    return current
  }, [currentData])

  const calculateTime = (time) => {
    const toDay = moment();
    const diff = toDay.diff(time, "day");
    if (diff === 0) {
      return "Today";
    }
    return `${diff}d`;
  };

  const handleView = (order) => {
    setOrderId(order?.setting[0].unOrderId);
    setOrder(order);
    setIsOpenView(true);
  };

  const handleCancelModel = (order) => {
    setOrderId(order?.setting[0].unOrderId);
    setIsCancel(true);
  };

  const handleAccept = (id) => {};

  
  const totalItems = useMemo(() => {
    const filterdata = data && data?.order?.length > 0 && data.order.filter(item => item.flgIsCancelOrder === 0)
    return filterdata?.length
  }, [data])

  const numberRegex = /^(\s*|\d+)$/;
  return (
    <div className="flex flex-col flex-1 w-full mb-[10px]">
      <ViewOrder
        isOpen={isOpenView}
        setIsOpen={setIsOpenView}
        orderId={orderId}
        setOrderId={setOrderId}
        order={order}
        setOrder={setOrder}
      />
      <CancelOrder
        isOpen={isCancel}
        setIsOpen={setIsCancel}
        orderId={orderId}
        setOrderId={setOrderId}
      />
      <main className="h-full bg-gray-100">
        <div className=" px-6">
          <div className="overflow-x-auto">
            <div className="flex justify-between gap-3 py-3 pl-2">
              <Link
                to={"/admin/transactionMenu"}
                className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
              >
                <TiArrowBack className="text-[18px]" /> Back
              </Link>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <button
                    onClick={() => setIsOpen(true)}
                    className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
                  >
                    <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-3 h-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                          />
                        </svg>
                      </div>
                      <div className="hidden sm:block">Filters</div>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="shadow rounded-lg overflow-hidden">
              <div className="overflow-auto border  bg-white ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#e2e8f0]">
                    <tr>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Order No
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Customer
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Customer Id
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Remark
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-left text-gray-500 uppercase  "
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-center text-gray-500 uppercase"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="p-3 text-xs font-bold text-center text-gray-500 uppercase "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {!isLoading && currentWithoutCencelData?.length > 0
                      ? currentWithoutCencelData.map((order, index) => {
                            return (
                              <tr key={index}>
                                <td className="p-3 text-sm font-medium text-gray-800 ">
                                  {calculateTime(order.dtOrderDate)}
                                </td>
                                <td className="p-3 text-sm text-gray-800 ">
                                  {order.dtOrderDate.split(" ")[0]}
                                </td>
                                <td className="p-3 text-sm text-gray-800 ">
                                  {order.stOrderNo}
                                </td>
                                <td className="p-3 text-sm text-gray-800 ">
                                  {order?.user?.stFirstName}{" "}
                                  {order?.user?.stLastName}
                                </td>
                                <td className="p-3 text-sm text-gray-800 ">
                                  {order?.user?.stEmail}
                                </td>
                                <td className="p-3 text-sm text-gray-800 ">
                                  {order.stOrderRemark || "-"}
                                </td>
                                <td className="p-3 text-sm text-gray-800 w-[100px]">
                                  {order.setting.length}
                                </td>
                                <td className="p-3 text-sm text-gray-800 text-right w-[100px]">
                                  {Number(order.dcOrderAmount)}
                                </td>
                                <td className="p-3 text-sm text-gray-800 text-center">
                                  -
                                </td>
                                <td className="p-3 text-sm flex justify-center items-center gap-2 font-medium text-right ">
                                  {order.unFollowUpStatusId === null ? (
                                    <>
                                      <button
                                        className=""
                                        type="button"
                                        onClick={() =>
                                          handleAccept(
                                            order?.setting[0].unOrderId
                                          )
                                        }
                                      >
                                        Accept
                                      </button>
                                      <span>|</span>
                                      <button
                                        className=""
                                        type="button"
                                        onClick={() => handleCancelModel(order)}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button className="">Edit</button>
                                      <span>|</span>
                                      <button
                                        className=""
                                        onClick={() => handleView(order)}
                                      >
                                        View
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                        })
                      : !isLoading && (
                          <tr>
                            <td colSpan={12}>
                              <NoDataText text={"No data found!"} />
                            </td>
                          </tr>
                        )}
                    {isLoading && (
                      <tr>
                        <td colSpan={12} className="h-60">
                          <Loader />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                totalItems={currentWithoutCencelData !== undefined ? currentWithoutCencelData?.length : 0}
                activePage={activePage}
                itemsPerPage={itemsPerPage}
                setActivePage={setActivePage}
              />
            </div>
          </div>
        </div>
      </main>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="mb-3">
          <div className="flex items-center">
            <input
              id="atozemail"
              name={`atozemail`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label htmlFor={`atozemail`} className="ml-3 text-sm text-gray-500">
              A to Z Email
            </label>
          </div>
          <div className="flex items-center mt-2">
            <input
              id="ztoaemail"
              name={`ztoaemail`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label htmlFor={`ztoaemail`} className="ml-3 text-sm text-gray-500">
              Z to A Email
            </label>
          </div>
          <div className="flex items-center mt-2">
            <input
              id="atozstatus"
              name={`atozstatus`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor={`atozstatus`}
              className="ml-3 text-sm text-gray-500"
            >
              A to Z Status
            </label>
          </div>
          <div className="flex items-center mt-2">
            <input
              id="ztoastatus"
              name={`ztoastatus`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor={`ztoastatus`}
              className="ml-3 text-sm text-gray-500"
            >
              Z to A Status
            </label>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="max-w-[130px]">
            <label htmlFor="from" className="text-[14px] font-Josefin">
              From Date
            </label>
            <TextInputAdmin
              type="text"
              onChange={(e) => {
                const { value } = e.target;
                if (numberRegex.test(value)) {
                  // handleChange(e);
                }
              }}
              className=""
              name="from"
              placeholder="From Date"
            />
          </div>
          <div className="max-w-[130px]">
            <label htmlFor="from" className="text-[14px] font-Josefin">
              To Date
            </label>
            <TextInputAdmin
              type="text"
              onChange={(e) => {
                const { value } = e.target;
                if (numberRegex.test(value)) {
                  // handleChange(e);
                }
              }}
              name="to"
              placeholder="To Date"
            />
          </div>
        </div>
        <div className="w-fit	absolute bottom-3">
          <button className="w-[280px] mt-3 py-2 text-white text-center bg-[#64748b] rounded">
            Apply
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default Order;
