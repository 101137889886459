import { createSlice } from "@reduxjs/toolkit";
import { addToCart } from "./cartSlice";

const diamondSelectSlice = createSlice({
  name: "selectDiamond",
  initialState: {
    data: [],
    selectedShape: [],
  },
  reducers: {
    setSelectedShape(state, action) {
      if (
        state.selectedShape?.find(
          (i) => i.shapeCode === action.payload?.shapeCode
        )
      )
        state.selectedShape = state.selectedShape?.filter(
          (i) => i.shapeCode !== action.payload.shapeCode
        );
      else state.selectedShape.push(action.payload);
    },
    clearSelectedShape(state, action) {
      state.selectedShape = [];
    },
    selectDiamond(state, action) {
      state.data.push(action.payload)
    },
    removeDiamond(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(addToCart, (state) => {
      state.data = []
    })
  }
})

export const { selectDiamond, setSelectedShape, clearSelectedShape, resetDiamond, removeDiamond } = diamondSelectSlice.actions

export const getSelectedDiamondState = state => state.selectedDiamond

export default diamondSelectSlice.reducer