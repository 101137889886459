import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { MdOutlineClose } from "react-icons/md";

const AdminModal = ({
  isOpen,
  onClose = () => { },
  children,
  title,
  onSubmit = () => { },
  submitButtonText = "Save",
  className = "",
  showButton = true,
  disableSubmit = false,
  showActionButtons = true,
  childClass = "overflow-y-auto",
  width = "",
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative  transform overflow-hidden flex flex-col justify-between rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${className} ${width !== "" ? width : "w-full sm:max-w-lg max-h-[80vh]"}`}
              >
                <div className=" ">
                  <div className="text-center sm:mt-0 sm:text-left">
                    <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                      <Dialog.Title
                        as="h3"
                        className="text-[20px] text-[#334155] font-[600] leading-6"
                      >
                        {title}
                      </Dialog.Title>
                      <button
                        type="button"
                        className="text-[20px] text-[#334155] font-[600] leading-6"
                        onClick={onClose}
                        ref={cancelButtonRef}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`mt-2 sm:p-6 sm:pb-0 sm:pt-2 px-4 pt-5 pb-4 h-full ${childClass}`}
                >
                  {children}
                </div>
                {showActionButtons && (
                  <div className="mt-5 sm:mt-4 sm:px-6 px-4 pb-4 sm:flex sm:justify-end sm:gap-2 ">
                    {showButton && (
                      <button
                        type="button"
                        disabled={disableSubmit}
                        className="inline-flex disabled:opacity-70 w-full justify-center rounded-md bg-[#334155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#64748b] sm:ml-3 sm:w-auto disabled:cursor-not-allowed"
                        onClick={onSubmit}
                      >
                        {submitButtonText}
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AdminModal;
