import React from "react";
import { useState } from "react";
import { PhotoIcon } from "@heroicons/react/20/solid";
import { TiArrowBack } from "react-icons/ti";
import Card from "./common/Card";
import TextInputAdmin from "./common/TextInputAdmin";
import { useDispatch, useSelector } from "react-redux";
import { getSidebarStatus } from "../../store/Slices/sidebarStatusSlice";
import { useEffect } from "react";
import { useRef } from "react";
import { AiOutlineCloudDownload, AiOutlineDelete } from "react-icons/ai";
import BrowseDiamondData from "./Modals/BrowseDiamondData";
import AddNewDemand from "./Modals/AddNewDemand";
import BrowseMetalData from "./Modals/BrowseMetalData";
import AddMetalDemand from "./Modals/AddMetalDemand";
import { getAttributeValueByAttribute } from "../../Services/attributeValueService";
import { getStoneColor } from "../../Services/stoneColorService";
import { useMemo } from "react";
import {
  fetchSideStonePriceList,
  getSideStonePriceState,
} from "../../store/Slices/sideStonePriceSlice";
import { useReactToPrint } from "react-to-print";
import VideoModal from "./Modals/VideoModal";
import ModalImage from "react-modal-image";
import { TbCertificate } from "react-icons/tb";
import Gai from "./../../assets/images/GIA.png";
import Igi from "./../../assets/images/IGI.png";
import { useNavigate } from "react-router";

const SnapAndPost = () => {
  const sideStoneObj = {
    sr: "",
    shape: "",
    size: "",
    pcs: "",
    ctpc: "",
    cost: "",
    rate: "",
    amount: "",
    cr: "",
    total: "",
  };

  const [shapeOptions, setShapeOptions] = useState([]);
  const [selectedMetalRow, setSelectedMetalRow] = useState(null);
  const [colorOptions, setColorOptions] = useState([]);
  const [sideStoneRows, setSideStoneRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenMetalModal, setIsOpenMetalModal] = useState(false);
  const [isDemandModal, setIsDemandModal] = useState(false);
  const [isDemandMetalModal, setIsDemandMetalModal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [pr, setPr] = useState();
  const [currency, setCerrency] = useState();
  const [metalRows, setMetalRows] = useState([]);
  const [metalData, setMetalData] = useState({
    metal: "",
    total: "",
  });
  const [videoData, setVideoData] = useState({
    videoUrl: "",
    title: "",
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: sideStone } = useSelector(getSideStonePriceState);
  const componentRef = useRef();

  useEffect(() => {
    dispatch(fetchSideStonePriceList());
  }, [dispatch]);

  useEffect(() => {
    if (
      sideStoneRows.shape &&
      sideStone?.length &&
      sideStoneRows.size &&
      sideStoneRows.color
    ) {
      const data = sideStone.find(
        (item) =>
          item.unAttributeValueShapeId === sideStoneRows.shape &&
          item.stSizeName === sideStoneRows.size &&
          item.stColourClarity === sideStoneRows.color
      );
      setSideStoneRows((prev) => ({ ...prev, rate: data.dcPrice }));
    }
  }, [sideStoneRows, sideStone]);

  const [laborRows, setLaborRows] = useState();
  const [centerStone, setcenterStone] = useState([]);

  useEffect(() => {
    fetchShapeOptions();
    fetchColorOptions();
  }, []);

  const fetchShapeOptions = async () => {
    try {
      const response = await getAttributeValueByAttribute(2);
      if (response.status === 200) {
        const options = response.data.data.attribute_value.map((item) => {
          return {
            id: item.unAttributeValueIdentifier,
            shapeName: item.stAttributeValueName,
          };
        });
        setShapeOptions(options);
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  };

  const fetchColorOptions = async () => {
    try {
      const response = await getStoneColor();
      if (response.status === 200) {
        const options = response.data.data.color.map((item) => {
          return {
            id: item.unStoneColorIdentifier,
            colorName: item.stStoneColorName,
          };
        });
        setColorOptions(options);
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  };

  const downloadPdf = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePDF = () => {
    downloadPdf();
  };

  const increaseList = (type) => {
    if (centerStone.length <= 5) {
      if (type === "browseStock") {
        setIsOpenModal(true);
      } else {
        setIsDemandModal(true);
      }
    }
  };

  const increaseMetalList = (type) => {
    if (metalRows.length < 1) {
      if (type === "browseStock") {
        setIsOpenMetalModal(true);
      } else {
        setIsDemandMetalModal(true);
      }
    }
  };

  const handleDelete = (i) => {
    const NewData = centerStone.filter((item, index) => index !== i);
    setcenterStone(NewData);
  };

  const increaseSideStoneList = () => {
    if (!sideStoneRows?.length) {
      setSideStoneRows([sideStoneObj]);
    } else {
      setSideStoneRows((prev) => [...prev, sideStoneObj]);
    }
  };

  const handleDeleteSideStone = (i) => {
    const NewData = sideStoneRows.filter((item, index) => index !== i);
    setSideStoneRows(NewData);
  };

  const handleDeleteMetal = (i) => {
    setMetalRows([]);
    setMetalData({
      total: "",
      metal: "",
    });
  };

  const handleAddDemand = (data) => {
    setcenterStone((prev) => [
      ...prev,
      { ...data, agentMargin: "", agentTotal: "" },
    ]);
  };

  const handleMetalDemand = (data) => {
    setMetalRows((prev) => [...prev, data]);
  };

  const handleChangeSideStoneData = (index, e, amount) => {
    const { name, value } = e.target;
    if (name === "ctpt") {
      const data = value * sideStoneRows[index].pcs;
      setSideStoneRows((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, totalct: data, [name]: value } : item
        )
      );
      if (data) {
        const total = +amount * +data;
        setSideStoneRows((prev) =>
          prev.map((item, i) =>
            i === index ? { ...item, amount: total } : item
          )
        );
      }
    } else if (name === "pcs") {
      const data = value * sideStoneRows[index].ctpt;
      setSideStoneRows((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, totalct: data, [name]: value } : item
        )
      );
      if (data) {
        const total = +amount * +data;
        setSideStoneRows((prev) =>
          prev.map((item, i) =>
            i === index ? { ...item, amount: total } : item
          )
        );
      }
    } else {
      setSideStoneRows((prev) =>
        prev.map((item, i) => (i === index ? { ...item, [name]: value } : item))
      );
    }
  };

  const handleChangeLaborData = (e) => {
    const { name, value } = e.target;
    setLaborRows(value);
  };

  const disable = true;
  const numberRegex = /^(\s*|\d+)$/;

  const handleChangeTotal = (e, stone) => {
    const { value, name } = e.target;
    setcenterStone((prev) =>
      prev.map((item) =>
        item.STOCKNO === stone.STOCKNO
          ? { ...item, [name]: +value, agentTotal: +value * item.CARAT }
          : item
      )
    );
  };

  const DtnTotal = useMemo(() => {
    if (centerStone?.length) {
      return centerStone.reduce((acc, cur) => +acc + +cur.Amount, 0);
    }
    return 0;
  }, [centerStone]);

  const AgentTotal = useMemo(() => {
    if (centerStone?.length) {
      return centerStone.reduce((acc, cur) => +acc + +cur.agentTotal, 0);
    }
    return 0;
  }, [centerStone]);
  const AmountTotal =
    sideStoneRows?.length &&
    sideStoneRows.reduce((acc, cur) => +acc + +cur.amount, 0);

  const metalDataArrName = useMemo(() => {
    if (metalRows?.length) {
      let arr = [];
      metalRows.map((item) => {
        arr.push(item.stAttributeValueName);
      });
      return arr;
    }
  }, [metalRows]);

  const handleChangeMetal = (e) => {
    const { name, value } = e.target;
    setMetalData((prev) => ({ ...prev, [name]: value }));
  };

  const selectMetal = useMemo(() => {
    if (metalRows.length) {
      return metalRows.find(
        (item) => item.stAttributeValueName === metalData.metal
      );
    }
  }, [metalData, metalRows]);

  const TotalMetal = useMemo(() => {
    if (metalData.total && selectMetal) {
      return +metalData.total * +selectMetal.dcNtWt;
    }
  }, [metalData, selectMetal]);

  const TotalAllDTN = useMemo(() => {
    if (TotalMetal && AmountTotal && laborRows) {
      return +TotalMetal + +AmountTotal + +laborRows;
    }
  }, [TotalMetal, AmountTotal, laborRows]);

  const TotalJewelDTN = useMemo(() => {
    if (DtnTotal && TotalAllDTN) {
      return +DtnTotal + +TotalAllDTN;
    }
  }, [DtnTotal, TotalAllDTN]);

  const Totalmargin = useMemo(() => {
    if (pr && TotalAllDTN) {
      return (+pr * +TotalAllDTN) / 100;
    }
  }, [TotalAllDTN, pr]);

  const TotalAgent = useMemo(() => {
    if (Totalmargin && TotalAllDTN) {
      return +Totalmargin + +TotalAllDTN;
    }
  }, [Totalmargin, TotalAllDTN]);

  const TotalJewelAgent = useMemo(() => {
    if (AgentTotal && TotalAgent) {
      return +AgentTotal + +TotalAgent;
    }
  }, [AgentTotal, TotalAgent]);

  const TotalJewelMargin = useMemo(() => {
    if (TotalJewelAgent && TotalJewelDTN) {
      return +TotalJewelAgent - +TotalJewelDTN;
    }
  }, [TotalJewelDTN, TotalJewelAgent]);

  const TotalJewelMarginPr = useMemo(() => {
    if (TotalJewelAgent && TotalJewelDTN) {
      return (+TotalJewelMargin / +TotalJewelDTN) * 100;
    }
  }, [TotalJewelDTN, TotalJewelMargin, TotalJewelAgent]);

  const TotalJewelGrandDTN = useMemo(() => {
    if (currency && TotalJewelDTN) {
      return +currency * +TotalJewelDTN;
    }
  }, [TotalJewelDTN, currency]);

  const TotalJewelGrandAgent = useMemo(() => {
    if (currency && TotalJewelAgent) {
      return +currency * +TotalJewelAgent;
    }
  }, [TotalJewelAgent, currency]);

  const TotalJewelGrand = useMemo(() => {
    if (TotalJewelGrandAgent && TotalJewelGrandDTN) {
      return +TotalJewelGrandAgent - +TotalJewelGrandDTN;
    }
  }, [TotalJewelGrandAgent, TotalJewelGrandDTN]);

  const TotalJewelGrandPr = useMemo(() => {
    if (TotalJewelGrandDTN) {
      return (TotalJewelGrand * 100) / TotalJewelGrandDTN;
    }
  }, [TotalJewelGrandDTN, TotalJewelGrand]);

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
    setVideoData({
      videoUrl: "",
      title: "",
    });
  };

  return (
    <>
      <div className="flex items-center justify-start px-4 pt-4">
        <button
          onClick={() => navigate("/admin/agentMenu")}
          className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
        >
          <TiArrowBack className="text-[18px]" /> Back
        </button>
      </div>
      <div className="p-4" ref={componentRef}>
        <VideoModal
          data={videoData}
          isOpen={videoModalOpen}
          onClose={handleVideoModalClose}
        />
        <BrowseDiamondData
          setcenterStone={setcenterStone}
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
        />
        <BrowseMetalData
          isOpen={isOpenMetalModal}
          setIsOpen={setIsOpenMetalModal}
          setMetalRows={setMetalRows}
          setSelectedMetalRow={setSelectedMetalRow}
        />
        <AddMetalDemand
          isOpen={isDemandMetalModal}
          setIsOpen={setIsDemandMetalModal}
          handleAddDemand={handleMetalDemand}
        />
        <AddNewDemand
          isOpen={isDemandModal}
          setIsOpen={setIsDemandModal}
          handleAddDemand={handleAddDemand}
        />
        <Card
          title={"Center Stones"}
          add={"Add"}
          centerStone={centerStone}
          isHidden={isHidden}
          onclick={increaseList}
          className={`mb-4`}
        >
          {centerStone?.length ? (
            centerStone.map((item, i) => {
              const certiUrl =
                item.LAB === "GIA"
                  ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                  : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;
              return (
                <div className="border rounded p-4 mt-4">
                  {centerStone.length > 0 && (
                    <div className="text-right">
                      <button type="button" onClick={() => handleDelete(i)}>
                        <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                      </button>
                    </div>
                  )}
                  <div className="border-b pb-4">
                    <div className="grid sm:grid-cols-6 grid-cols-1 text-[12px] gap-y-1 gap-x-4 sm:text-[14px] leading-[20px] font-[500]">
                      <div className="col-span-1 sm:block flex items-center">
                        <div className="col-span-3">
                          {!!["gia", "igi"].includes(item.LAB.toLowerCase()) ? (
                            <div className="md:w-[173px] w-[130px] md:h-[100px] h-[100px] mx-auto bg-gray-200 rounded">
                              <a
                                href={certiUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="w-full h-full"
                              >
                                <img
                                  className="md:w-[100px] w-[130px] md:h-[100px] h-[100px] mx-auto"
                                  src={
                                    item.LAB.toLowerCase() === "gia" ? Gai : Igi
                                  }
                                  alt={item.LAB.toLowerCase()}
                                />
                              </a>
                            </div>
                          ) : (
                            <div className="md:w-[173px] w-[130px] md:h-[100px] h-[100px] mx-auto flex justify-center items-center bg-gray-300 rounded">
                              <TbCertificate fontSize={24} />
                            </div>
                          )}
                        </div>
                        <div className="col-span-3">
                          {item.ImageUrl ? (
                            <ModalImage
                              small={item.ImageUrl}
                              large={item.ImageUrl}
                              className="md:w-[173px] w-[130px] md:h-[100px] h-[100px] object-cover mx-auto bg-gray-200 mt-2 flex justify-center"
                              alt={`Stock ID: ${item.StoneNo}`}
                            />
                          ) : (
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-3">
                              <div className="text-center">
                                <PhotoIcon
                                  className="mx-auto h-8 w-8 text-gray-300"
                                  aria-hidden="true"
                                />
                                <div className="flex text-sm leading-6 text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-[#295268]"
                                  >
                                    <span className="text-[13px]">
                                      Cert Image
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-5 grid gap-x-4 gap-y-0 sm:grid-cols-6 grid-cols-1">
                        <div className="col-span-2">
                          <label
                            htmlFor="lab"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Lab - Cert.No.
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            type="text"
                            placeholder="Enter Lab"
                            value={item.LAB + "-" + item.CERTID}
                            name="LAB"
                            id="lab"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="shape"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Shape
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            type="text"
                            placeholder="Enter Shape"
                            value={item.SHAPE}
                            name="SHAPE"
                            id="shape"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="cut"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Cut/Sym/Polish
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="cut"
                            value={item.CUTPOLISHSYM || ""}
                            type="text"
                            name="CUTPOLISHSYM"
                            placeholder="Cut/Sym/Polish"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="color"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Colour
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="color"
                            value={item.COLOUR || ""}
                            type="text"
                            name="COLOUR"
                            placeholder="Enter Colour"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="purity"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Purity
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="purity"
                            type="text"
                            name="CLARITY"
                            value={item.CLARITY || ""}
                            placeholder="Enter Purity"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="measurement"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Measurement
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="measurement"
                            type="text"
                            name="MEASUREMENT"
                            value={item.MEASUREMENT}
                            placeholder="Enter Measurement"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="CARAT"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Carat
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="CARAT"
                            type="text"
                            name="CARAT"
                            value={item.CARAT}
                            placeholder="Enter Carat"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="USD/ct"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            USD/ct
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="USD/ct"
                            type="text"
                            placeholder="Enter USD/ct"
                            name="USD/ct"
                            value={item?.total}
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="total"
                            className="block text-sm font-medium leading-6 text-gray-600"
                          >
                            Total
                          </label>
                          <TextInputAdmin
                            disable={disable}
                            id="total"
                            type="text"
                            name="AMOUNT"
                            value={item.Amount}
                            placeholder="Enter USD Ttl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 text-[12px] gap-y-1 mt-2 gap-x-4 sm:text-[14px] leading-[20px] font-[500]">
                    <div className="col-span-1 flex items-center">
                      <span className="block text-[15px] font-medium leading-6 text-gray-600">
                        Agent Margin
                      </span>
                    </div>
                    <div className="col-span-5 grid gap-x-4 gap-y-0 grid-cols-6">
                      <div className="col-span-2 opacity-0">
                        <label
                          htmlFor="USD/ct"
                          className="block text-sm font-medium leading-6 text-gray-600"
                        >
                          Total
                        </label>
                        <TextInputAdmin
                          disable={true}
                          id="USD/ct"
                          type="text"
                          placeholder="Enter USD/ct"
                          name="USD/ct"
                        />
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="agentUSD"
                          className="block text-sm font-medium leading-6 text-gray-600"
                        >
                          USD/ct
                        </label>
                        <TextInputAdmin
                          id="agentUSD"
                          type="text"
                          name="agentMargin"
                          value={item.agentMargin}
                          onChange={(e) => {
                            handleChangeTotal(e, item);
                          }}
                          placeholder="Enter USD/ct"
                        />
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="agentTotal"
                          className="block text-sm font-medium leading-6 text-gray-600"
                        >
                          Total
                        </label>
                        <TextInputAdmin
                          id="agentTotal"
                          type="text"
                          disable={true}
                          value={item.agentTotal || 0}
                          placeholder="Enter USD/ct"
                          name="agentTotal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center text-[14px] py-[20px]">
              No Any Select Center Stone
            </div>
          )}
        </Card>

        <div className="font-[500] mb-6 bg-[#e2e8f0] shadow p-4 rounded">
          <table className="w-full border border-[#000] rounded">
            <tr className="border-b border-[#000]">
              <th
                colSpan={4}
                className="text-left border-r px-4 py-1 border-[#000]"
              >
                Center Stone Total (DTN)
              </th>
              <td
                colSpan={5}
                className="flex items-center px-4 py-1 border-[#000] justify-center"
              >
                ${" "}
                <span className="font-[700] ml-1">
                  {DtnTotal.toFixed(2) || 0}
                </span>
              </td>
            </tr>
            <tr className="border-b border-[#000]">
              <th
                colSpan={4}
                className="text-left border-r px-4 py-1 border-[#000]"
              >
                Center Stone Total (Agent)
              </th>
              <td
                colSpan={5}
                className="flex items-center px-4 py-1 border-[#000] justify-center"
              >
                ${" "}
                <span className="font-[700] ml-1">
                  {AgentTotal.toFixed(2) || 0}
                </span>
              </td>
            </tr>
            <tr>
              <th
                colSpan={4}
                className="text-left border-r px-4 py-1 border-[#000]"
              >
                Margin
              </th>
              <td className="text-center border-r px-2 py-1 w-[100px]  border-[#000]">
                ${" "}
                <span className="font-[700] ml-1">
                  {Math.abs(AgentTotal - DtnTotal).toFixed(2) || 0}
                </span>
              </td>
              <td className=" border-r px-4 text-center py-1 w-[100px] border-[#000]">
                <span className="font-[700] ml-1">
                  {DtnTotal
                    ? (
                        Math.abs(
                          (Math.round(AgentTotal) - Math.round(DtnTotal)) * 100
                        ) / Math.round(DtnTotal)
                      ).toFixed(2)
                    : (0).toFixed(2)}
                  %
                </span>
              </td>
            </tr>
          </table>
        </div>

        <Card
          title={"Metal"}
          add={"Add"}
          isHidden={isHidden}
          metalRows={metalRows}
          onclick={increaseMetalList}
          className={`mb-4`}
        >
          <table className="w-[100%] text-black my-3 text-left border">
            <thead className="bg-[#e6e6e6] text-left rounded ">
              <tr>
                <th className="py-1 px-2 text-[14px] text-[#555] w-[20px]">
                  Sr.
                </th>
                <th className="py-1 px-2 text-[14px] text-[#555]">Metal</th>
                <th className="py-1 px-2 text-[14px] text-[#555] max-w-[450px]">
                  Description
                </th>
                <th className="py-1 px-2 text-[14px] text-[#555]">
                  Net Weight
                </th>
                <th className="py-1 px-2 text-[14px] text-[#555]">Rate $</th>
                <th className="py-1 px-2 text-[14px] text-[#555]">Total $</th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]"></th>
              </tr>
            </thead>
            <tbody>
              {!!metalRows.length ? (
                <tr className="text-sm even:bg-gray-100">
                  <td className="py-1 px-2">1</td>
                  <td className=" text-center">
                    <select
                      className="w-full border"
                      name="metal"
                      onChange={handleChangeMetal}
                      id="SideStoneShape"
                    >
                      <option value="">Select</option>
                      {metalDataArrName?.length > 0 &&
                        metalDataArrName.map((shape, index) => {
                          return (
                            <option key={index} value={shape}>
                              {shape}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td className="py-1 px-2">
                    {selectedMetalRow?.stDesignAlias}-
                    {selectedMetalRow?.stDesignName}
                  </td>
                  <td className="py-1 px-2">{selectMetal?.dcNtWt || 0}</td>
                  <td className="py-1 px-2 max-w-[100px]">
                    <input
                      type="text"
                      name="total"
                      onChange={handleChangeMetal}
                      className="w-[100px] outline-none bg-slate-50"
                    />
                  </td>
                  <td className="py-1 px-2">{TotalMetal || 0}</td>
                  <td className="py-1 px-2 text-right">
                    <button
                      className="flex justify-center ml-auto items-center h-full"
                      type="button"
                      onClick={() => handleDeleteMetal()}
                    >
                      <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr className="text-center text-[14px]">
                  <td colSpan={8} className="py-[20px]">
                    No Any Metal Add
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>

        <Card
          title={"Side Stones"}
          add={"side"}
          isHidden={isHidden}
          onclick={increaseSideStoneList}
          className={`mb-4`}
        >
          <table className="w-[100%] text-black mt-2 mb-3 text-left border">
            <thead className="bg-[#e6e6e6] text-left rounded">
              <tr>
                <th className="py-1 max-w-[30px] px-2 text-[14px] text-[#555]">
                  Sr.
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Shape
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Size
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Color/Clarity
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Pcs
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Ct/Pc
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Ttl.Cts
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Rate $
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                  Total $
                </th>
                <th className="py-1 px-2 text-[14px] text-center text-[#555]"></th>
              </tr>
            </thead>
            <tbody>
              {sideStoneRows.length ? (
                sideStoneRows.map((item, i) => {
                  const totalRate =
                    sideStone.length > 0 &&
                    sideStone.find(
                      (itm) =>
                        itm.unAttributeValueShapeId === item.shape &&
                        itm.stSizeName === item.size &&
                        itm.stColourClarity === item.color
                    );
                  const sizeOption =
                    sideStone.length > 0 &&
                    sideStone.filter(
                      (stone) => stone.unAttributeValueShapeId === item.shape
                    );
                  const colorOption =
                    sideStone.length > 0 &&
                    sideStone.filter(
                      (stone) =>
                        stone.unAttributeValueShapeId === item.shape &&
                        stone.stSizeName === item.size
                    );
                  const sizeOpData = [
                    ...new Map(
                      sizeOption.map((m) => [m.stSizeName, m])
                    ).values(),
                  ];
                  const colorOpData = [
                    ...new Map(
                      colorOption.map((m) => [m.stColourClarity, m])
                    ).values(),
                  ];
                  return (
                    <tr key={i} className="even:bg-gray-100 text-sm">
                      <td className="">
                        <input
                          type="text"
                          className="w-[60px] outline-none text-center bg-transparent"
                          name="sr"
                          value={item.sr}
                          onChange={(e) => {
                            if (numberRegex.test(e.target.value)) {
                              handleChangeSideStoneData(i, e);
                            }
                          }}
                        />
                      </td>
                      <td className=" text-center">
                        <select
                          className="w-full border"
                          value={item.shape || ""}
                          onChange={handleChangeSideStoneData.bind(null, i)}
                          name="shape"
                          id="SideStoneShape"
                        >
                          <option value="">Select</option>
                          {shapeOptions?.length > 0 &&
                            shapeOptions.map((shape, index) => {
                              return (
                                <option key={index} value={shape.shapeName}>
                                  {shape.shapeName}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="w-full border"
                          value={item.size || ""}
                          onChange={handleChangeSideStoneData.bind(null, i)}
                          name="size"
                          id="SideStoneSize"
                        >
                          <option value="">Select</option>
                          {sizeOpData?.length > 0 &&
                            sizeOpData.map((stone, index) => {
                              return (
                                <option key={index} value={stone.stSizeName}>
                                  {stone.stSizeName}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="w-full border"
                          value={item.color || ""}
                          onChange={handleChangeSideStoneData.bind(null, i)}
                          name="color"
                          id="SideStoneColor"
                        >
                          <option value="">Select</option>
                          {colorOpData?.length > 0 &&
                            colorOpData.map((color, index) => {
                              return (
                                <option
                                  key={index}
                                  value={color.stColourClarity}
                                >
                                  {color.stColourClarity}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="w-full outline-none text-center bg-transparent"
                          name="pcs"
                          value={item.pcs}
                          onChange={(e) => {
                            if (numberRegex.test(e.target.value)) {
                              handleChangeSideStoneData(
                                i,
                                e,
                                totalRate?.dcPrice
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="w-full outline-none text-center bg-transparent"
                          name="ctpt"
                          value={item.ctpt}
                          onChange={(e) => {
                            handleChangeSideStoneData(i, e, totalRate?.dcPrice);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="w-full outline-none text-center bg-transparent"
                          name="totalct"
                          value={item.totalct}
                          onChange={(e) => {
                            if (numberRegex.test(e.target.value)) {
                              handleChangeSideStoneData(i, e);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="w-full outline-none text-center bg-transparent"
                          name="rate"
                          disabled
                          value={totalRate?.dcPrice}
                          onChange={(e) => {
                            if (numberRegex.test(e.target.value)) {
                              handleChangeSideStoneData(i, e);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="w-full outline-none text-center bg-transparent"
                          name="amount"
                          value={item.amount}
                          onChange={(e) => {
                            if (numberRegex.test(e.target.value)) {
                              handleChangeSideStoneData(i, e);
                            }
                          }}
                        />
                      </td>
                      <td className="text-center px-2">
                        <button
                          type="button"
                          className="flex justify-center items-center h-full"
                          onClick={() => handleDeleteSideStone(i)}
                        >
                          <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center text-[14px]">
                  <td colSpan={10} className="py-[20px]">
                    No Any Row Selected
                  </td>
                </tr>
              )}
              <tr className="border-t">
                <td colSpan={8}></td>
                <td className="text-center border-l bg-slate-50 text-[13px]">
                  ${AmountTotal || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </Card>

        <div>
          <Card title={"Labour"} className={`mb-4`}>
            <table className="w-[100%] text-black my-3 text-left border">
              <thead className="bg-[#e6e6e6] text-left rounded">
                <tr>
                  {/* <th className="py-1 px-2 text-[14px] text-center text-[#555]">
                Sr.No
              </th> */}
                  <th
                    colSpan={11}
                    className="py-1 px-2 text-[14px] text-center text-[#555]"
                  ></th>
                  <th className="py-1 px-2 text-[14px] border-l text-center text-[#555] w-[210px]">
                    Labour
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={11}></td>
                  <td className="border-l">
                    <input
                      type="text"
                      className="outline-none text-center text-[13px] w-[210px]"
                      name="amount"
                      value={laborRows}
                      placeholder="Enter Labour"
                      onChange={(e) => {
                        if (numberRegex.test(e.target.value)) {
                          handleChangeLaborData(e);
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
          <div className="font-[500] my-6 shadow bg-[#e2e8f0] p-4 rounded">
            <table className="w-full border border-[#000] rounded">
              <tr className="border-b border-[#000]">
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Mounting Total (DTN)
                </th>
                <td
                  colSpan={5}
                  className="flex items-center px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalAllDTN) || 0}
                  </span>
                </td>
              </tr>
              <tr className="border-b border-[#000]">
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Mounting Total (Agent)
                </th>
                <td
                  colSpan={5}
                  className="flex items-center px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalAgent) || 0}
                  </span>
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Margin
                </th>
                <td className="text-center border-r px-2 py-1 w-[100px]  border-[#000]">
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(Totalmargin) || 0}
                  </span>
                </td>
                <td className=" border-r px-4 text-center py-1 w-[100px] border-[#000]">
                  <input
                    type="text"
                    className="outline-none text-center text-[13px] w-[100px]"
                    name="amount"
                    placeholder="enter pr"
                    onChange={(e) => setPr(e.target.value)}
                  />
                </td>
              </tr>
            </table>
          </div>
          <div className="font-[500] mb-6 shadow bg-[#e2e8f0] p-4 rounded">
            <table className="w-full border border-[#000] rounded overflow-auto">
              <tr className="border-b border-[#000]">
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Jewelry Total (DTN)
                </th>
                <td
                  colSpan={5}
                  className="flex items-center px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelDTN) || 0}
                  </span>
                </td>
                <td></td>
                <td rowSpan={4} className="border-l border-[#000] text-center">
                  <input
                    type="text"
                    className="outline-none text-center text-[13px] w-[100px]"
                    name="amount"
                    placeholder="Enter Currency"
                    onChange={(e) => setCerrency(e.target.value)}
                  />
                </td>
                <td
                  colSpan={5}
                  className="flex items-center border-l px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelGrandDTN) || 0}
                  </span>
                </td>
              </tr>
              <tr className="border-b border-[#000]">
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Jewelry Total (Agent)
                </th>
                <td
                  colSpan={5}
                  className="flex items-center px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelAgent) || 0}
                  </span>
                </td>
                <td></td>
                <td
                  colSpan={5}
                  className="flex items-center border-l px-4 py-1 border-[#000] justify-center"
                >
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelGrandAgent) || 0}
                  </span>
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  className="text-left border-r px-4 py-1 border-[#000]"
                >
                  Margin
                </th>
                <td className="text-center border-r px-2 py-1 w-[100px]  border-[#000]">
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelMargin) || 0}
                  </span>
                </td>
                <td className=" border-r px-4 text-center py-1 w-[100px] border-[#000]">
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelMarginPr) || 0}%{" "}
                  </span>
                </td>
                <td className="text-center border-l px-2 py-1 w-[100px]  border-[#000]">
                  ${" "}
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelGrand) || 0}
                  </span>
                </td>
                <td className=" border-l px-4 text-center py-1 w-[100px] border-[#000]">
                  <span className="font-[700] ml-1">
                    {Math.round(TotalJewelGrandPr) || 0}%{" "}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="text-right p-4 pt-0">
        <button
          onClick={handlePDF}
          onMouseEnter={() => setIsHidden(true)}
          onMouseLeave={() => setIsHidden(false)}
          className="button flex items-center gap-[10px] ml-auto border rounded border-[#475569] px-2 py-2 bg-[#e2e8f0] hover:bg-[#f1f5f9]"
        >
          <AiOutlineCloudDownload fontSize={"20px"} /> Download
        </button>
      </div>
    </>
  );
};

export default SnapAndPost;
