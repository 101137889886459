import api from "./api"

const getSideStone = () => {
  return api("/api/DesignStone", "get")
}

const getSideStoneById = (id) => {
  return api(`/api/DesignStone/${id}`, "get")
}

const createSideStone = (data) => {
  return api("/api/DesignStone", "post", data)
}

const updateSideStone = (data) => {
  return api("/api/DesignStone", "put", data)
}

const DeleteSideStone = (data) => {
  return api(`/api/DesignStone/${data}`, "delete")
}

export {
  getSideStone,
  getSideStoneById,
  createSideStone,
  updateSideStone,
  DeleteSideStone
}