import axios from "axios";

const api = (url, method, data = null, params) => {
  const instance = axios.create({
    baseURL: "https://api.dtndiamonds.com/",
    headers: {
      Authorization: `Bearer ${localStorage.token || ""}`,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 404) {
        return Promise.reject({
          status: error.response.status,
          message: error.response.data.message,
        });
      }
      return Promise.reject(error);
    }
  );

  const config = {
    url,
    method,
    data,
    params,
  };

  return instance.request(config);
};

export default api;
