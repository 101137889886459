import React from "react";
import AdminModal from "../common/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { getDesignerDataState } from "../../../store/Slices/designerSlice";
import { createDesignersData, updateDesignersData } from "../../../Services/designerService";
import { fetchDesignerData } from './../../../store/Slices/designerSlice';

const AddDesigner = ({
  isOpen,
  setIsOpen,
  editId,
  setIsEdit
}) => {
  const [inputValue, setInputValue] = useState({
    stDesignerName: "",
    stDesignerAlias: "",
    stAddress: "",
    unCityId: "",
    unStateId: "",
    unCountryId: "",
    stPinCode: "",
    stPhoneNo: "",
  });
  const dispatch = useDispatch();
  const { data } = useSelector(getDesignerDataState);

  useEffect(() => {
    const editData = data.find((item) => item.unDesignerIdentifier === editId);
    if (editData) {
      setInputValue({
        stDesignerName: editData.stDesignerName,
        stDesignerAlias: editData.stDesignerAlias,
        stAddress: editData.stAddress,
        unCityId: editData.unCityId,
        unStateId: editData.unStateId,
        unCountryId: editData.unCountryId,
        stPinCode: editData.stPinCode,
        stPhoneNo: editData.stPhoneNo,
      });
    }
  }, [editId, data]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setIsEdit("")
    setInputValue({
      stDesignerName: "",
      stDesignerAlias: "",
      stAddress: "",
      unCityId: "",
      unStateId: "",
      unCountryId: "",
      stPinCode: "",
      stPhoneNo: "",
    })
  };

  const handleSubmit = async () => {
    // const payload = new FormData();
    // payload.append("stDesignerName", inputValue.stDesignerName);
    // payload.append("stDesignerAlias", inputValue.stDesignerAlias);
    // payload.append("stAddress", inputValue.stAddress);
    // payload.append("unCityId", inputValue.unCityId);
    // payload.append("unStateId", inputValue.unStateId);
    // payload.append("unCountryId", inputValue.unCountryId);
    // payload.append("stPinCode", inputValue.stPinCode);
    // payload.append("stPhoneNo", inputValue.stPhoneNo);
    
    if (editId) {
        const payload = {
          unDesignerIdentifier: editId,
          stDesignerName: inputValue.stDesignerName,
          stDesignerAlias: inputValue.stDesignerAlias,
          stAddress: inputValue.stAddress,
          unCityId: inputValue.unCityId,
          unStateId: inputValue.unStateId,
          unCountryId: inputValue.unCountryId,
          stPinCode: inputValue.stPinCode,
          stPhoneNo: inputValue.stPhoneNo,
        };
        try {
          const res = await updateDesignersData(payload);
          if (res.status === 200) {
            toast.success(res.data.message);
            dispatch(fetchDesignerData());
            setIsOpen(false)
            handleClose()
          }
        } catch (error) {
          console.log("ERROR:", error);
          setIsOpen(false)
          handleClose()
        }
    } else {
      const payload = new FormData();
      payload.append("stDesignerName", inputValue.stDesignerName);
      payload.append("stDesignerAlias", inputValue.stDesignerAlias);
      payload.append("stAddress", inputValue.stAddress);
      payload.append("unCityId", inputValue.unCityId);
      payload.append("unStateId", inputValue.unStateId);
      payload.append("unCountryId", inputValue.unCountryId);
      payload.append("stPinCode", inputValue.stPinCode);
      payload.append("stPhoneNo", inputValue.stPhoneNo);
        const res = await createDesignersData(payload);
        if (res.status === 200) {
          toast.success(res.data.message);
          dispatch(fetchDesignerData());
        }
        handleClose();
      // setCategoryId();
    }
  };

  return (
    <AdminModal
      title={editId ? "Update Designer" : "Add Designer"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => {setIsOpen(false); setIsEdit("")}}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <div className="mb-2 col-span-3">
          <label
            htmlFor="stDesignerName"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Designer Name
          </label>
          <div>
            <input
              type="text"
              name="stDesignerName"
              value={inputValue.stDesignerName}
              id="stDesignerName"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute Value Name"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex items-center gap-[10px] col-span-3">
          <div className="mb-2 w-[400px]">
            <label
              htmlFor="stDesignerAlias"
              className="block text-[14px] font-medium leading-6 text-gray-900"
            >
              Designer Alias
            </label>
            <div className="col-span-8 sm:col-span-4">
              <input
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                type="text"
                name="stDesignerAlias"
                id="stDesignerAlias"
                value={inputValue.stDesignerAlias}
                onChange={handleChange}
                placeholder="Enter Attribute Value Alias"
              />
            </div>
          </div>
        </div>
        <div className="mb-2 col-span-full">
          <label
            htmlFor="stAddress"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Address
          </label>
          <div>
            <input
              type="text"
              name="stAddress"
              value={inputValue.stAddress}
              id="stAddress"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unCityId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            City
          </label>
          <div>
            <input
              type="text"
              name="unCityId"
              value={inputValue.unCityId}
              id="unCityId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unStateId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            State
          </label>
          <div>
            <input
              type="text"
              name="unStateId"
              value={inputValue.unStateId}
              id="unStateId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unCountryId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Country
          </label>
          <div>
            <input
              type="text"
              name="unCountryId"
              value={inputValue.unCountryId}
              id="unCountryId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="stPinCode"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Pin Code
          </label>
          <div>
            <input
              type="text"
              name="stPinCode"
              value={inputValue.stPinCode}
              id="stPinCode"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="stPhoneNo"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Phone No.
          </label>
          <div>
            <input
              type="text"
              name="stPhoneNo"
              value={inputValue.stPhoneNo}
              id="stPhoneNo"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddDesigner;
