import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../../store/Slices/cartSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: "0px solid #000",
  outline: 'none',
};

export default function BasicModal(props) {

  const dispatch = useDispatch();

  const { item, source, open, handleClose, parentState } = props;

  const [state, setState] = useState({
    clarity: '',
    pcs: 1,
    carat: 0
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, clarity: item?.Clarity || "" }));
  }, [item]);

  const { clarity, pcs, carat } = state;

  const handleChange = (key,event) => {
    setState((prevState) => ({...prevState, [key]: event.target.value}));
  };

  const handleAddToCart = () => {
    const cartData = {
      product: {
        ...item,
        id: parentState?.currentShape?.shapeCode,
        title: parentState?.currentShape?.name,
        price: 0,
        image: parentState?.currentShape?.icon,
        carat: carat,
        price: pcs,
        clarity: clarity,
      },
      diamond: [],
      total: 0,
    };
    dispatch(openCart(true));
    dispatch(addToCart(cartData));
    toast.success("Successfully Added to Cart.");
    handleClose();
  };

  return (
    <div>
      {/* <div
        className="flex justify-center bg-gray-100 text-gray-700 py-1 text-sm"
        onClick={handleOpen}
      >
        Add To Cart
      </div> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-between">
            <span className="text-lg text-gray-900">Details</span>
            <span>
              <XMarkIcon className="h-5 w-5 text-gray-600 cursor-pointer" onClick={()=>handleClose()} />
            </span>
          </div>
          <Divider />

          <div className="mt-4 flex justify-between text-sm text-gray-800">
            <span>Available Pcs</span>
            <span className="font-[500] text-gray-700">
              {item?.pcs || item?.Pcs || 0}
            </span>
          </div>

          <div className="mt-2 flex justify-between text-sm text-gray-800">
            <span>Carat</span>
            <span className="font-[500] text-gray-700">
              {item?.carat || item?.Carat || 0}
            </span>
          </div>

          <div className="mt-2 flex justify-between text-sm text-gray-800">
            <span>Colour</span>
            <span className="font-[500] text-gray-700">
              {item?.Color || "DEF"}
            </span>
          </div>

          {item?.Clarity && (
            <div className="mt-2 flex justify-between text-sm text-gray-800">
              <span>Clarity</span>
              <span className="font-[500] text-gray-700">{item?.Clarity}</span>
            </div>
          )}

          {!item?.Clarity && (
            <div className="flex mt-3">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Clarity</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clarity}
                  label="Clarity"
                  onChange={(event) => handleChange("clarity", event)}
                >
                  <MenuItem value="VVS">VVS</MenuItem>
                  <MenuItem value="VS">VS</MenuItem>
                  <MenuItem value="SI">SI</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <div className="flex mt-3">
            <TextField
              id="pcs"
              label="Pcs"
              type="number"
              size="small"
              className="w-full"
              value={pcs}
              onChange={(e) => handleChange("pcs", e)}
            />
          </div>

          <div className="flex mt-3">
            <TextField
              id="carat"
              label="Carat"
              type="text"
              size="small"
              className="w-full"
              value={carat}
              onChange={(e) => handleChange("carat", e)}
            />
          </div>

          <div className="flex mt-2">
            {/* <Button variant="contained" size="medium" className="w-full" onClick={handleAddToCart}>
              
            </Button> */}

            <button
              class="w-full block text-center font-playfair tracking-[1px] bg-[#334155] text-[#fff] text-[16px] leading-[32px] py-[6px]"
              onClick={handleAddToCart}
            >
              Add To Cart
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}