import React, { useState } from "react";
import Pagination from "./Pagination";
import { BiEdit, BiShow } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import { GoDiffAdded, GoKebabVertical } from "react-icons/go";
import { Fragment } from "react";
import { Listbox, Menu, Transition } from "@headlessui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import {
  fetchDesigns,
  fetchDesignsInProductPage,
  getDesignDataState,
} from "../../store/Slices/designSlice";
import { deleteDesign, designUpdateFlgById } from "../../Services/designService";
import { TiArrowBack } from "react-icons/ti";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { FiFilter } from "react-icons/fi";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { getCategory } from "../../Services/categoryService";
import { debounce } from "@mui/material";

let recall = false;
const Product = () => {
  const dispatch = useDispatch();
  const { data, loading, error, totalCount } = useSelector(getDesignDataState);
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const [categoryStyleData, setCategoryStyleData] = useState([]);
  const [value, _value] = useState()

  // const { currentData, activePage, setActivePage, itemsPerPage } = usePagination({ data, itemsPerPage: 10});

  const [activePage, setActivePage] = useState(1);
  var itemsPerPage = 10;
  useEffect(() => {
    dispatch(fetchDesignsInProductPage(activePage));
  }, [dispatch, activePage]);

  // useEffect(() => {
  //   dispatch(fetchDesigns());
  // }, [dispatch]);

  useEffect(() => {
    if (data.length === 0 && !recall) {
      dispatch(fetchDesigns());
      recall = true;
    }
  }, [dispatch, data]);

  const handleEdit = (id) => {
    navigate(`/admin/addproduct/${id}`);
  };

  const handleView = (id) => {
    navigate(`/productdetail/${id}`);
  }

  useEffect(() => {
    const categoryStyleData = async () => {
      const res = await getCategory();
      setCategoryStyleData(res.data.data.categories);
    };
    categoryStyleData();
  }, []);

  const handleSetData = (val, name) => {
    const currentCategory =
      categoryStyleData?.length &&
      categoryStyleData?.find((itm) => itm.stCategoryName === val.stCategoryName);
    if (currentCategory) {
      setSelected(currentCategory);
    }
    const params = {
      category_id: currentCategory?.unCategoryIdentifier,
      Name: value ? value : null
    }
    dispatch(fetchDesigns(params))
  }

  const handleSearchBox = (e) => {
    _value(e.target.value)
    let params = {
      category_id: selected?.unCategoryIdentifier,
    }
    if (e.target.value.length > 0) {
      params['Name'] = e.target.value
    }

    dispatch(fetchDesigns(params));
  }

  const debouncedOnChange = debounce(handleSearchBox, 700);

  const handleDelete = async (id, name) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: `That you want to delete this Design ${name}?`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await deleteDesign(id);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchDesigns());
      }
    }
  };

  const handleDesignFlg = async(flag, flagType, unDesignIdentifier) => {
    const res = await designUpdateFlgById({ unDesignIdentifier, [flagType]: flag ? 1 : 0 });
    if (res.status === 200) {
      toast.success(res.data.message);
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full z-0 h-[90vh] bg-gray-100">
      <main className="h-full">
        <div className="px-6 pb-6 mx-auto">
          <div className="w-auto mr-1 lg:mr-0">
            <div className="mt-2 flex flex-wrap sm:flex-nowrap rounded-md shadow-sm">
              <Link
                to={"/admin/transactionMenu"}
                className="rounded-l-md  text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50 "
              >
                <TiArrowBack className="text-[18px]" /> Back
              </Link>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  // name="email"
                  // id="email"
                  onChange={debouncedOnChange}
                  className="block w-full rounded-none border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search Text here..."
                />
              </div>
              <Listbox
                value={selected}
                onChange={(val) => {
                  handleSetData(val, "category");
                }}
              >
                <div className="relative w-[155px]">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default hover:bg-gray-50 py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected ? selected?.stCategoryName : "Category"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {categoryStyleData?.length &&
                        categoryStyleData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                ? "bg-amber-100 text-amber-900"
                                : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? "font-medium" : "font-normal"
                                    }`}
                                >
                                  {person.stCategoryName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FiFilter className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Filter
              </button>
              <Link
                to={"/admin/addproduct"}
                className="rounded-r-md text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </Link>
            </div>
          </div>
          <div className="sm:rounded-lg relative mt-4 rounded shadow-md">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-4 max-w-[100px] text-center"
                    >
                      Image
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px]">
                      Category
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px]">
                      Style
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px]">
                      Code
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px] w-auto">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px]">
                      Nt.Wt
                    </th>
                    <th scope="col" className="px-2 py-4 max-w-[100px]">
                      Setting
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      className="px-2 py-4 max-w-[10px] text-center"
                    >
                      New
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      className="px-2 py-4 max-w-[10px] text-center"
                    >
                      Hot
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      className="px-2 py-4 max-w-[10px] text-center"
                    >
                      Y|N
                    </th>
                    <th scope="col" className="px-2 py-4 w-[80px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && data?.length
                    ? data.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                        >
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <img
                              src={item.stDefImageURL}
                              alt="stDefImageURL"
                              className="w-[40px] h-[40px] mx-auto rounded opacity-80"
                            />
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.category}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.categoryStyle}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stDesignAlias}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium max-w-[150px] truncate text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stDesignName}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.dcNtWt}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.dcNtWt}
                          </th>
                          <td colSpan={2} className="px-2 py-2 text-center">
                            {/* {item.flgIsNew === "1" || item.flgIsNew === 1 ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )} */}

                            <label class="relative inline-flex items-center mb-0 cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                defaultChecked={
                                  item.flgIsNew === "1" || item.flgIsNew === 1
                                }
                                onChange={(e)=>handleDesignFlg(e.target.checked, 'flgIsNew', item.unDesignIdentifier)}
                              />
                              <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-[#8dbeff] dark:peer-focus:ring-[#1F2937] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#1F2937]"></div>
                            </label>
                          </td>
                          <td colSpan={2} className="px-2 py-2 text-center">
                            {/* {item.flgIsHotDesign === "1" ||
                            item.flgIsHotDesign === 1 ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )} */}

                            <label class="relative inline-flex items-center mb-0 cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                defaultChecked={
                                  item.flgIsHotDesign === "1" ||
                                  item.flgIsHotDesign === 1
                                }
                                onChange={(e)=>handleDesignFlg(e.target.checked, 'flgIsHotDesign', item.unDesignIdentifier)}
                              />
                              <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-[#8dbeff] dark:peer-focus:ring-[#1F2937] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#1F2937]"></div>
                            </label>
                          </td>
                          <td colSpan={2} className="px-2 py-2 text-center">
                            {/* {item.flgIsActive === "1" ||
                              item.flgIsActive === 1 ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )} */}

                            <label class="relative inline-flex items-center mb-0 cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                defaultChecked={
                                  item.flgIsActive === "1" ||
                                  item.flgIsActive === 1
                                }
                                onChange={(e)=>handleDesignFlg(e.target.checked, 'flgIsActive', item.unDesignIdentifier)}
                              />
                              <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#8dbeff] dark:peer-focus:ring-[#1F2937] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#1F2937]"></div>
                            </label>
                          </td>
                          <td className="px-2 py-2">
                            <div className="flex items-center gap-[10px]">
                              <button
                                onClick={handleEdit.bind(
                                  null,
                                  item.unDesignIdentifier
                                )}
                                type="button"
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              <button
                                type="button"
                                onClick={handleDelete.bind(
                                  null,
                                  item.unDesignIdentifier,
                                  item.stDesignName
                                )}
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              <button
                                onClick={() =>
                                  handleView(item.unDesignIdentifier)
                                }
                                type="button"
                              >
                                <BiShow className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>

                              <div className="flex items-center justify-end relative">
                                <Menu as="div" className="h-[20px] text-left">
                                  <Menu.Button>
                                    <GoKebabVertical className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[3px] rounded" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute top-3 z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="px-1 py-1 ">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                navigate(
                                                  `/admin/stone/${item.unDesignIdentifier}`,
                                                  {
                                                    state: {
                                                      name: item.stDesignName,
                                                    },
                                                  }
                                                )
                                              }
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-gray-900"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Side Stone
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                navigate(
                                                  `/admin/variation/${item.unDesignIdentifier}`,
                                                  {
                                                    state: {
                                                      name: item.stDesignName,
                                                    },
                                                  }
                                                )
                                              }
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-[#1e293b]"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Variation
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                navigate(
                                                  `/admin/agentcal/${item.unDesignIdentifier}`,
                                                  {
                                                    state: {
                                                      name: item.stDesignName,
                                                      dcGrWt: item.dcGrWt,
                                                      dcNtWt: item.dcNtWt,
                                                      stDesignAlias:
                                                        item.stDesignAlias,
                                                    },
                                                  }
                                                )
                                              }
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-[#1e293b]"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-1.5 text-[13px] font-Poppins`}
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Metal for Agent
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    : !loading && (
                      <tr>
                        <td className="w-full" colSpan={13}>
                          <NoDataText text={"No Product found!"} />
                        </td>
                      </tr>
                    )}
                  {loading && (
                    <tr>
                      <td colSpan={13} className="h-60 w-full">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={totalCount}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Product;
