import { createSlice } from "@reduxjs/toolkit";

const sidebarStatus = createSlice({
  name: "sidebarStatus",
  initialState: { isOpen: true },
  reducers: {
    toggleSidebar(state, action) {
      state.isOpen = action.payload
    }
  }
})

export const { toggleSidebar } = sidebarStatus.actions

export const getSidebarStatus = state => state.sidebar

export default sidebarStatus.reducer