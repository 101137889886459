import React, { Fragment, useMemo } from "react";
import { ClickAwayListener, Drawer } from "@mui/material";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import Cart from "../../../assets/images/cart.png";
import {
  closeCart,
  openCart,
  removeFromCart,
  selectCartState,
} from "../../../store/Slices/cartSlice";

import { Link, useNavigate } from "react-router-dom";

import { clearAuth, getAuthState } from "../../../store/Slices/AuthSlice";

import { useDispatch, useSelector } from "react-redux";

export default function CartDrawer(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart, cartDrawer } = useSelector(selectCartState);
  const { isLoggedIn } = useSelector(getAuthState);
    
  const totalPrice = useMemo(
    () => cart?.reduce((acc, cur) => acc + Number(cur?.total), 0),
    [cart]
  );
  
  const handleCloseCart = () => {
    dispatch(openCart(false));
  };

  const handleCheckout = () => {
    handleCloseCart();
    if (isLoggedIn) {
      navigate("/checkout");
    } else {
      navigate("/login", { state: { path: "/checkout" } });
    }
  };

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <Drawer anchor={"right"} open={cartDrawer} onClose={() => handleCloseCart()}>
      <div className="sm:w-[470px] w-[310px] pt-16 max-h-full h-full relative">
        <div className="absolute top-0 left-0 z-50 flex justify-between w-full h-[54px] bg-[#F7F7F7] px-[15px] items-center">
          <div className="flex gap-2 items-center">
            <FiShoppingCart className="sm:text-[26px] text-[22px] font-[600]" />
            <span className="ml-2 sm:text-[24px] text-[20px] leading-[52px] font-[600] ">
              Cart
            </span>
          </div>
          <span onClick={() => handleCloseCart()} className="cursor-pointer">
            <IoMdCloseCircleOutline className="sm:text-[24px] text-[20px]" />
          </span>
        </div>
        {!!cart?.length && (
          <div className="absolute left-0 bottom-0 z-50 bg-[#334155] w-full text-[#FFFFFF] flex justify-between items-center px-[15px] ">
            <button
              onClick={handleCheckout}
              className="sm:text-[18px] text-[16px] leading-[40px] font-[400] py-1 font-Josefin"
            >
              Checkout
            </button>
            <span className="sm:text-[18px] text-[16px] leading-[33px] font-[400] font-Josefin">
              ${totalPrice}
            </span>
          </div>
        )}
        <div className="max-h-full h-[85vh] overflow-y-auto" id="cartScroll">
          {cart.map((item, index) => {
            return (
              <div key={index}>
                <div className="border-b-[1px] border-[#000000] flex justify-between items-center px-[15px] ">
                  <div className="flex items-center gap-1">
                    <button onClick={handleRemoveItem.bind(null, index)}>
                      <MdOutlineClose />
                    </button>
                    <h4 className="md:text-base sm:text-sm truncate max-w-[90%] text-xs py-1 font-[500] font-Josefin">
                      {item?.product?.title}
                    </h4>
                  </div>
                  <span className="sm:text-[20px] text-[18px] leading-[33px] font-[400] font-Josefin">
                    ${item?.total}
                  </span>
                </div>
                <div className="sm:pl-[10px] px-0 sideCart">
                  <div className="relative flex justify-center gap-[15px] p-[10px] ">
                    {item?.product?.image && (
                      <img
                        src={item?.product?.image}
                        className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                        alt="ring"
                      />
                    )}
                    {!item?.product?.image && (
                      <div className="h-[130px] w-[130px] bg-gray-200">
                        <img
                          src={item?.product?.image}
                          className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                          alt="ring"
                        />
                      </div>
                    )}
                    <div className="w-full">
                      {item.product.DesignVariationId && (
                        <div className="flex justify-between">
                          <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[600] mb-[4px]  font-Josefin">
                            {item?.product?.title}
                          </h2>
                          {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                  ${item?.product?.price}
                                </span> */}
                        </div>
                      )}
                      <p className="sm:text-[15px] text-[13px] leading-[20px] font-[400] flex flex-col text-[#626262] font-Josefin">
                        {item?.product?.attributes &&
                          Object.entries(item?.product?.attributes).map(
                            ([key, value], index) => {
                              return (
                                <span
                                  key={index}
                                  title={value?.name}
                                  className="max-w-[270px] truncate"
                                >
                                  {key} : {value?.name}
                                </span>
                              );
                            }
                          )}
                        {item.product.DesignVariationId && (
                          <span>Size : {item?.product?.size}</span>
                        )}
                        {!item.product.DesignVariationId && (
                          <>
                            <div className="flex justify-between">
                              <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[500] text-black font-Josefin">
                                {item?.product?.title}
                              </h2>
                              {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                        ${item?.product?.price}
                                      </span> */}
                            </div>
                            <span className="text-[14px]">
                              {item?.product?.stockNo}
                            </span>
                            {item?.product?.attribute?.length &&
                              item?.product?.attribute.map((items, i) => {
                                return (
                                  <ul className="list-none" key={i}>
                                    <li className="text-[14px] font-[400] ">
                                      <span className="font-[500]">
                                        {items.Attribute.stAttributeStock}
                                      </span>{" "}
                                      :- {items.AttributeValue}
                                      {/* <span className="font-[300]">
                                                {data?.[item.stAttributeName]?.name}
                                              </span> */}
                                    </li>
                                  </ul>
                                );
                              })}
                          </>
                        )}
                        {item?.product?.engraving && (
                          <span
                            title={item?.product?.engraving}
                            className="max-w-[180px] truncate"
                          >
                            Engraving :{" "}
                            <span className="font-Allura">
                              {item?.product?.engraving}
                            </span>
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
                  {!!item.diamond?.length &&
                    item.diamond.map((diamond, i) => {
                      return (
                        <Fragment key={i}>
                          <div className="relative flex justify-center gap-[15px] p-[20px] ">
                            {diamond?.image && (
                              <img
                                src={diamond?.image}
                                className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]"
                                alt="ring"
                              />
                            )}
                            {!diamond?.image && (
                              <div className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px]">
                                <img
                                  src={diamond?.ImageUrl}
                                  className="sm:h-[90px] sm:w-[90px] w-[80px] h-[80px] object-contain max-w-fit"
                                  alt="ring"
                                />
                              </div>
                            )}
                            <div className="w-full">
                              <div className="flex justify-between">
                                <h2 className="sm:text-[18px] text-[16px] leading-[28px] font-[600] mb-[4px]  font-Josefin">
                                  {diamond?.title}
                                </h2>
                                {/* <span className="sm:text-[17px] text-[16px] leading-[28px] font-[600] font-Josefin">
                                          ${diamond?.price}
                                        </span> */}
                              </div>
                              <p className="sm:text-[15px] text-[13px] leading-[20px] font-[400] flex flex-col text-[#626262] font-Josefin">
                                <span>Carat : {diamond?.carat}</span>
                                <span>Colour : {diamond?.COLOUR || "N/A"}</span>
                                <span>
                                  Clarity : {diamond?.CLARITY || "N/A"}
                                </span>
                                <span>
                                  Cut : {diamond?.CUTPOLISHSYM || "N/A"}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="w-full h-[1px] bg-[#E3E3E3]"></div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {!!!cart?.length && (
            <div className="absolute top-1/2 -translate-y-1/2 text-md font-[600] text-slate-800 w-full flex justify-center items-center">
              <div className="relative">
                <div className="relative w-[90px] h-[90px] mx-auto mb-2">
                  <div className="w-[90px] h-[90px] bg-slate-200 rounded-full"></div>
                  <img
                    src={Cart}
                    alt="Cart"
                    className="w-[90px] rounded-full absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 h-[90px]"
                  />
                </div>
                <span>Your Cart is Empty</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
}