import { useCallback, useState } from "react";

const useApi = (api) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const runApi = useCallback(
    async (...args) => {
      try {
        setError(null);
        setData(null);
        setIsLoading(true);
        const response = await api(...args);
        if (response?.data?.success) {
          setData(response.data.data);
        }
      } catch (err) {
        setError(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  return [runApi, data, isLoading, error];
};

export default useApi;
