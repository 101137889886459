import React, { useEffect, useState, useCallback, useRef } from "react";
import { getDiamondStockWithoutRateMarginJewelry } from "../../../Services/diamondService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { numberRegex } from "../../shared/constants";
import ModalImage from "react-modal-image";
import VideoModal from "../../Admin/Modals/VideoModal";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-hot-toast";
import Gai from "./../../../assets/images/GIA.png";
import Igi from "./../../../assets/images/IGI.png";
import { TbCertificate } from "react-icons/tb";
import { getAuthState } from "../../../store/Slices/AuthSlice";
import { BsWhatsapp } from "react-icons/bs";
import { createDiamonndServiceData } from "../../../Services/stockRequestService";
import { tawkWidgetToggle } from "../../../Utils";
import { CgPlayButtonO } from "react-icons/cg";
import DiamondListDesktopSkeleton from "../Common/skeleton/DiamondListDesktopSkeleton";
import DiamondListMobileSkeleton from "../Common/skeleton/DiamondListMobileSkeleton";
import DiamondFilters from "./DiamondFilters";
import { FaThList } from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import { useMemo } from "react";
import DiamondCardSkeleton from "../Common/skeleton/DiamondCardSkeleton";
import dummyDiamond from "../../../assets/images/dummy-diamond.png";
import IsLoginHeader from "../IsLoginHeader";
import login from "../../../assets/images/login.svg";

const Diamond = () => {
  const [stockData, setStockData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValue, setFilterValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const User = JSON.parse(localStorage.getItem("name"));

  const [videoData, setVideoData] = useState({
    videoUrl: "",
    title: "",
  });
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [data, setData] = useState({
    stEmailId: "",
    stName: "",
    stPhoneNo: "",
    certiid: "",
    stockid: "",
    message: "",
  });
  const { user, isLoggedIn } = useSelector(getAuthState);
  const [searchParams, setSearchParams] = useSearchParams([["view", 1]]);

  const tawkTo = require("tawkto-react");
  const tawkToPropertyId = "64644842ad80445890ed5f0b";
  const tawkToKey = "1h0jrkhog";

  useEffect(() => {
    if(window.innerWidth > 640)
    {
      new tawkTo(tawkToPropertyId, tawkToKey);
      tawkWidgetToggle(true);
      return () => {
        tawkWidgetToggle(false);
      };
    }
  }, []);

  const hideChat = useCallback((hide) => {
    if (hide) {
      tawkWidgetToggle(false);
    } else {
      tawkWidgetToggle(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (data.stEmailId && data.stName && data.stPhoneNo && data.message) {
      const payload = new FormData();
      payload.append("stName", data.stName);
      payload.append("stEmail", data.stEmailId);
      payload.append("stContact", data.stPhoneNo);
      payload.append("stStoneID", data.stockid);
      payload.append("stCertiID", data.certiid);
      payload.append("stMessage", data.message);
      payload.append("flgIsVerifiedEmail", 1);

      const res = await createDiamonndServiceData(payload);
      if (res.status === 200) {
        toast.success("Request Send Successfully.");
        setOpen(false);
        setData({
          stEmailId: "",
          stName: "",
          stPhoneNo: "",
          certiid: "",
          stockid: "",
          message: "",
        });
      }
    } else {
      toast.error("All Field are Required !");
    }
  };

  const DiamondStock = useCallback(
    async (page) => {
      // debugger;
      if (page === 1) {
        setStockData([]);
      }
      const Data = new FormData();
      if (
        filterValue &&
        JSON.stringify(filterValue) !== "{}" &&
        !!Object.values(filterValue).join("")
      ) {
        Data.append("ShapeId", filterValue?.shape || "");
        Data.append("ColorId", filterValue?.color || "");
        Data.append("ClarityId", filterValue?.clarity || "");
        Data.append("Cut", filterValue.cut || "");
        Data.append("FromCarat", filterValue?.fromCarat ?? "");
        Data.append("ToCarat", filterValue?.toCarat ?? "");
        Data.append("fromPrice", filterValue?.fromPrice ?? "");
        Data.append("toPrice", filterValue?.toPrice ?? "");
        Data.append("symmetry", filterValue?.symmetry || "");
        Data.append("polish", filterValue?.polish || "");
        Data.append("type", filterValue?.type || "");
        Data.append("LAB", filterValue?.lab || "");
        Data.append("CERTID", filterValue?.certi || "");
        Data.append("StockID", filterValue?.StockID || "");
      }
      Data.append("UserId", user?.unUserIdentifier || "");
      Data.append("inPageNo", page);
      Data.append("inPageSize", 10);
      setLoading(true);
      try {
        const response = await getDiamondStockWithoutRateMarginJewelry(Data);
        if (response.data.success) {
          setHasMore(!(response.data.data.stock?.length < 10));
          setStockData((prev) => [...prev, ...response.data.data.stock]);
        }
      } catch (err) {
        console.log("Error: ", err.response);
      } finally {
        setLoading(false);
      }
    },
    [filterValue, user?.unUserIdentifier]
  );

  useEffect(() => {
    DiamondStock(pageNumber);
  }, [pageNumber, DiamondStock]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleVideoModalOpen = (data) => {
    setVideoData({
      videoUrl: data.VideoUrl,
      title: `Stock ID: ${data.StoneNo}`,
    });
    setVideoModalOpen(true);
  };

  const handleVideoModalClose = () => {
    setVideoModalOpen(false);
    setVideoData({
      videoUrl: "",
      title: "",
    });
  };
  const handleViewChange = (type) => {
    if (type === "card") {
      setSearchParams([["view", 1]]);
    } else {
      setSearchParams("");
    }
  };

  const activeView = useMemo(() => searchParams.get("view"), [searchParams]);

  return (
    <>
      <div className="flex flex-col gap-3 md:gap-4 fixed z-50 bottom-[25px] md:bottom-[95px] right-[25px] items-end 38px">
        <a
          href="https://wa.me/+85261951229"
          className="shadow-md rounded-full p-2.5 md:p-3.5 bg-[#1f2937]"
        >
          <BsWhatsapp className="text-[22px] md:text-[30px] font-bold text-white" />
        </a>
        <a
          href="mailto:info@dtndiamonds.com"
          className="shadow-md rounded-full p-2.5 md:p-3.5 bg-[#1f2937]"
        >
          <CiMail className="text-[22px] md:text-[30px] font-bold text-white" />
        </a>
      </div>

      <VideoModal
        data={videoData}
        isOpen={videoModalOpen}
        onClose={handleVideoModalClose}
      />
      <div className="bg-gray-100" id="diamondListDTN">
        <div className="flex flex-col justify-center flex-1 xl:max-w-[1324px] w-full mx-auto xl:px-0 px-2">
          <main className="min-h-[100vh] w-full">
            <div className="relative">
              <header className="text-center md:text-3xl sm:text-xl text-base md:my-6 mt-6 mb-3">
                DTN
                <span className="font-dancingScript font-bold ">
                  {" "}
                  Premium Quality{" "}
                </span>
                LAB-GROWN DIAMONDS
              </header>
              <div className="absolute top-[50%] z-50 sm:right-4 right-2 transform -translate-y-[50%] flex items-center justify-end sm:gap-[20px] gap-[12px]">
                {User && isLoggedIn ? (
                  <IsLoginHeader />
                ) : (
                  <Link to={"/admin"} className="">
                    <img src={login} alt="login" />{" "}
                  </Link>
                )}
              </div>
            </div>
            <div className=" grid pt-2 mx-auto">
              <div className="pt-2 pb-4">
                <button
                  type="button"
                  onClick={() => navigate("/jewelry")}
                  className="flex items-center outline-none gap-2 font-Primary"
                >
                  <IoIosArrowBack /> Back
                </button>
              </div>
              <div className="mb-4">
                <DiamondFilters
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  setPageNumber={setPageNumber}
                  loading={loading}
                  hideChat={hideChat}
                  stockData={stockData}
                />
              </div>
              <div className="my-2 flex justify-between items-center border-b-4 border-gray-400">
                <h2 className="text-lg font-bold text-gray-700 ">
                  View All Diamonds
                </h2>
                <div className="flex justify-center items-center">
                  {/* <button
                    onClick={handleViewChange.bind(null, "list")}
                    title="List View"
                    className="border border-gray-300 p-2"
                  >
                    <FaThList color="#868e96" className="text-[16px]" />
                  </button> */}
                  <button
                    // onClick={handleViewChange.bind(null, "card")}
                    title="Card View"
                    className="border border-gray-300 p-2"
                  >
                    <BsFillGridFill color="#868e96" className="text-[16px]" />
                  </button>
                </div>
              </div>
              {activeView && (
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2">
                  {stockData.map((item, index) => {
                    const certiUrl =
                      item.LAB === "GIA"
                        ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                        : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;
                    return (
                      <div
                        ref={
                          index + 1 === stockData?.length
                            ? lastDiamondElementRef
                            : dummyRef
                        }
                        key={index}
                        className="bg-white p-4 rounded-md shadow shapeBox"
                      >
                        <div className="flex flex-col gap-2 h-full">
                          <div className="max-h-80 h-full rounded-xl overflow-hidden w-full relative">
                            <div className="absolute z-10 top-3 right-3 flex justify-end items-center gap-2 w-full">
                              {!!item?.VideoUrl?.includes("https") && (
                                <Tooltip title={"Click to play video"}>
                                  <div className="w-8 h-8 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                    <CgPlayButtonO
                                      className="text-2xl text-gray-600 "
                                      onClick={handleVideoModalOpen.bind(
                                        null,
                                        item
                                      )}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                              {!!["gia", "igi"].includes(
                                item?.LAB.toLowerCase()
                              ) ? (
                                <div className="w-8 h-8 bg-gray-100 rounded">
                                  <a
                                    href={certiUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-full h-full"
                                  >
                                    <img
                                      className="w-full h-full"
                                      src={
                                        item?.LAB.toLowerCase() === "gia"
                                          ? Gai
                                          : Igi
                                      }
                                      alt={item?.LAB.toLowerCase()}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <div className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded">
                                  <TbCertificate fontSize={24} />
                                </div>
                              )}
                            </div>
                            {item?.ImageUrl?.includes("https") ? (
                              <img
                                src={item?.ImageUrl}
                                alt="stockData"
                                className="object-cover h-full w-full"
                              />
                            ) : (
                              <div className="w-full h-[293px] bg-gray-200 flex items-center justify-center">
                                <img
                                  src={dummyDiamond}
                                  alt="stockData"
                                  className="object-cover h-[100px] w-[100px]"
                                />
                              </div>
                            )}
                            <span className="absolute z-10 top-3 left-3 px-2 py-1 text-xs rounded bg-slate-700 text-white font-bold">
                              {item?.Type}
                            </span>
                          </div>
                          <div className="pb-4 w-full border-b border-gray-300">
                            <h1 className="flex justify-start items-center gap-1 font-bold text-gray-600 ">
                              <span>{item?.SHAPE}</span>
                              <span>{item?.CARAT}</span>
                              <span>{item?.COLOUR}</span>
                              <span>{item?.CLARITY}</span>
                              <span>{item?.CUTPOLISHSYM}</span>
                            </h1>
                          </div>
                          <div className="flex justify-between items-start w-full">
                            <div>
                              <h3 className="font-medium text-sm">Rate(USD)</h3>
                              {item?.Price > 0 ? (
                                <span className="font-normal text-sm">
                                  {Math.round(+item?.Price)}
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  className="font-[400] text-sm underline"
                                  onClick={() => {
                                    window.Tawk_API.maximize();
                                    window.Tawk_API.setAttributes(
                                      {
                                        id: "A1234",
                                        store: "Midvalley",
                                      },
                                      function (error) {
                                        console.log("Error", error);
                                      }
                                    );
                                    window.Tawk_API.addEvent(
                                      "product-add-to-cart",
                                      {
                                        StoneId: item?.StoneNo,
                                        CertId: item?.CERTID,
                                      },
                                      function (error) {}
                                    );
                                  }}
                                >
                                  On Request
                                </button>
                              )}
                            </div>
                            <div>
                              <h3 className="font-medium text-sm">
                                Amount(USD)
                              </h3>
                              <span className="font-normal text-sm">
                                {item?.Amount > 0
                                  ? Math.round(+item?.Amount)
                                  : "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {loading && (
                    <>
                      <DiamondCardSkeleton />
                      <DiamondCardSkeleton />
                      {!stockData?.length && (
                        <>
                          <DiamondCardSkeleton />
                          <DiamondCardSkeleton />
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {!activeView && (
                <div className="grid">
                  {!!stockData?.length
                    ? stockData?.map((item, index) => {
                        const certiUrl =
                          item.LAB === "GIA"
                            ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                            : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;
                        return (
                          <div
                            key={index}
                            ref={
                              index + 1 === stockData?.length
                                ? lastDiamondElementRef
                                : dummyRef
                            }
                            className="w-full py-2"
                          >
                            <div className="bg-white lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
                              <div className="w-full">
                                <div className="w-full grid grid-cols-8 gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Shape :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.SHAPE}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 xl:text-sm text-[13px] mb-2">
                                    Carat :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CARAT}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Color :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.COLOUR}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                    Clarity :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CLARITY}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                    Cut / Polish / Sym :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CUTPOLISHSYM}
                                    </span>
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                    <div className="">
                                      Cert :{" "}
                                      <span className="font-normal xl:text-[14px] text-[13px]">{`${item.LAB}-${item.CERTID}`}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full grid grid-cols-8 gap-2">
                                  <div className="font-bold xl:text-sm text-[13px] mt-2 sm:col-span-1 col-span-2">
                                    Stock ID :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.StoneNo}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                    Measurement :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.MEASUREMENT}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 xl:text-sm text-[13px] mt-2">
                                    Rbp :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.RAP}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                    Rate(USD) :{" "}
                                    {item.Price > 0 ? (
                                      <span className="font-normal xl:text-[14px] text-[13px]">
                                        {Math.round(+item.Price)}
                                      </span>
                                    ) : (
                                      <button
                                        type="button"
                                        className="font-[400] underline"
                                        onClick={() => {
                                          window.Tawk_API.maximize();
                                          window.Tawk_API.setAttributes(
                                            {
                                              id: "A1234",
                                              store: "Midvalley",
                                            },
                                            function (error) {
                                              console.log("Error", error);
                                            }
                                          );
                                          window.Tawk_API.addEvent(
                                            "product-add-to-cart",
                                            {
                                              StoneId: item.StoneNo,
                                              CertId: item.CERTID,
                                            },
                                            function (error) {}
                                          );
                                        }}
                                      >
                                        On Request
                                      </button>
                                    )}
                                  </div>
                                  <div className="font-bold xl:text-sm text-[13px] mt-2 col-span-2">
                                    <div>
                                      Amount(USD) :{" "}
                                      <span className="font-normal xl:text-[14px] text-[13px]">
                                        {item.Amount > 0
                                          ? Math.round(+item.Amount)
                                          : "--"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-3 lg:gap-4 gap-2 items-center">
                                {!!item.ImageUrl?.includes("https") ? (
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded">
                                    <ModalImage
                                      small={item.ImageUrl}
                                      large={item.ImageUrl}
                                      className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover"
                                      alt={`Stock ID: ${item.StoneNo}`}
                                    />
                                  </div>
                                ) : (
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded"></div>
                                )}
                                {!!item.VideoUrl?.includes("https") ? (
                                  <Tooltip title={"Click to play video"}>
                                    <div className="sm:w-10 sm:h-10 w-14 h-14 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                      <CgPlayButtonO
                                        className="text-2xl text-gray-600 "
                                        onClick={handleVideoModalOpen.bind(
                                          null,
                                          item
                                        )}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded"></div>
                                )}
                                {!!["gia", "igi"].includes(
                                  item.LAB.toLowerCase()
                                ) ? (
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 rounded">
                                    <a
                                      href={certiUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="w-full h-full"
                                    >
                                      <img
                                        className="w-full h-full"
                                        src={
                                          item.LAB.toLowerCase() === "gia"
                                            ? Gai
                                            : Igi
                                        }
                                        alt={item.LAB.toLowerCase()}
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  <div className="sm:w-10 sm:h-10 w-14 h-14 flex justify-center items-center bg-gray-300 rounded">
                                    <TbCertificate fontSize={24} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="bg-white lg:hidden flex items-center gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
                              <div className="w-full">
                                <div className="grid grid-cols-2 gap-1">
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Shape :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.SHAPE}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                    Carat :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CARAT}
                                    </span>
                                  </div>
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Color :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.COLOUR}
                                    </span>
                                  </div>
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Clarity :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CLARITY}
                                    </span>
                                  </div>
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Cut/Polish/Sym :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CUTPOLISHSYM}
                                    </span>
                                  </div>
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Cert :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.CERTID}
                                    </span>
                                  </div>

                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Stock ID :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.StoneNo}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-2 sm:text-sm text-[12px]">
                                    Measurement :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.MEASUREMENT}
                                    </span>
                                  </div>
                                  <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                    Rate(USD) :{" "}
                                    {item.Price > 0 ? (
                                      <span className="font-normal xl:text-[14px] text-[13px]">
                                        {Math.round(+item.Price)}
                                      </span>
                                    ) : (
                                      <button
                                        type="button"
                                        className="font-[400] underline"
                                        onClick={() => {
                                          window.Tawk_API.toggle();
                                          window.Tawk_API.setAttributes(
                                            {
                                              id: "A1234",
                                              store: "Midvalley",
                                            },
                                            function (error) {
                                              console.log("Error", error);
                                            }
                                          );
                                          window.Tawk_API.addEvent(
                                            "product-add-to-cart",
                                            {
                                              StoneId: item.StoneNo,
                                              CertId: item.CERTID,
                                            },
                                            function (error) {}
                                          );
                                        }}
                                      >
                                        On Request
                                      </button>
                                    )}
                                  </div>
                                  <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                    Amount(USD) :{" "}
                                    <span className="font-normal xl:text-[14px] text-[13px]">
                                      {item.Amount > 0
                                        ? Math.round(+item.Amount)
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 items-center flex-row">
                                {!!item.ImageUrl ? (
                                  <div className="w-10 h-10 bg-gray-300 rounded">
                                    <ModalImage
                                      small={item.ImageUrl}
                                      large={item.ImageUrl}
                                      className="w-10 h-10 rounded object-cover"
                                      alt={`Stock ID: ${item.StoneNo}`}
                                    />
                                  </div>
                                ) : (
                                  <div className="w-10 h-10 bg-gray-300 rounded"></div>
                                )}
                                {!!item.VideoUrl ? (
                                  <Tooltip title="Click to play video">
                                    <div className="w-10 h-10 rounded cursor-pointer flex justify-center items-center bg-gray-100">
                                      <CgPlayButtonO
                                        className="text-2xl text-gray-600 "
                                        onClick={handleVideoModalOpen.bind(
                                          null,
                                          item
                                        )}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div className="w-10 h-10 bg-gray-300 rounded"></div>
                                )}
                                {!!["gia", "igi"].includes(
                                  item.LAB.toLowerCase()
                                ) ? (
                                  <div className="w-10 h-10 rounded">
                                    <a
                                      href={certiUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="w-full h-full"
                                    >
                                      <img
                                        className="w-full h-full"
                                        src={
                                          item.LAB.toLowerCase() === "gia"
                                            ? Gai
                                            : Igi
                                        }
                                        alt={item.LAB.toLowerCase()}
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  <div className="w-10 h-10 flex justify-center items-center bg-gray-300 rounded">
                                    <TbCertificate fontSize={24} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : !loading && (
                        <div className="text-[14px] text-gray-500 font-[600] text-center">
                          No Data
                        </div>
                      )}
                  {loading && (
                    <>
                      <div className="hidden lg:grid lg:gap-4 mb-4 py-2">
                        <DiamondListDesktopSkeleton />
                        {!stockData?.length && (
                          <>
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                            <DiamondListDesktopSkeleton />
                          </>
                        )}
                      </div>
                      <div className="lg:hidden grid gap-4 py-2">
                        <DiamondListMobileSkeleton />
                        {!stockData?.length && <DiamondListMobileSkeleton />}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="sm:flex block min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="">
                    <div className="text-center sm:mt-0 sm:text-left">
                      <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                        >
                          Diamond Request Form
                        </Dialog.Title>
                        <button
                          type="button"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          <MdOutlineClose />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-6 gap-4 sm:p-6 sm:pb-0 sm:pt-2 px-4 pt-5 pb-4">
                    <div className="col-span-6">
                      <label
                        htmlFor="stName"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stName"
                          id="stName"
                          value={data.stName}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter your Name"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stEmailId"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="stEmailId"
                          id="stEmailId"
                          value={data.stEmailId}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Phone
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stPhoneNo"
                          id="stPhoneNo"
                          onChange={(e) => {
                            const { value } = e.target;
                            if (numberRegex.test(value)) {
                              handleChange(e);
                            }
                          }}
                          value={data.stPhoneNo}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Stock Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled
                          value={data.stockid}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Cert Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          disabled
                          value={data.certiid}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Message
                      </label>
                      <div className="mt-2">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="5"
                          maxLength={2000}
                          value={data.message}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:px-6 px-4 pb-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-[#334155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#64748b] sm:ml-3 sm:w-auto"
                      onClick={handleSave}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Diamond;
