import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCategoryData,
  UpdateCategory,
} from "../../../Services/categoryService";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../../store/Slices/categorySlice";
import AdminModal from "../common/AdminModal";
import axios from "axios";
import Loading from "../../../assets/images/loading4.gif";
import { BsCheckCircleFill } from "react-icons/bs";
import ImageUpload from "../../shared/ImageUpload";

export default function AddCategory({ isOpen, setIsOpen, editId, setEditId }) {
  const [inputValue, setInputValue] = useState({
    stCategoryName: "",
    stImageUrl: "",
    flgIsActive: true,
    flgIsSize: false,
    flgIsEngraving: false,
  });
  const { data } = useSelector(getCategoryDataState);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const arr = ["flgIsSize", "flgIsActive", "flgIsEngraving"];
    setInputValue((prev) => ({
      ...prev,
      [name]: arr.includes(name) ? checked : value,
    }));
  };

  const handleClose = () => {
    setIsOpen(false);
    setInputValue({
      stCategoryName: "",
      stImageUrl: "",
      flgIsActive: true,
      flgIsSize: false,
      flgIsEngraving: false,
    });
    setUploadImage(false);
    if (editId) {
      setEditId("");
    }
  };

  useEffect(() => {
    const editData = data?.find((item) => item.unCategoryIdentifier === editId);
    if (editData) {
      setInputValue({
        stCategoryName: editData.stCategoryName,
        stImageUrl: editData.stImageUrl,
        flgIsActive: (editData.flgIsActive === 1 || editData.flgIsActive === "1") ? true : false,
        flgIsSize: (editData.IsSelectSize === 1 || editData.flgIsActive === "1") ? true : false,
        flgIsEngraving: (editData.IsEngraving === 1 || editData.IsEngraving === "1") ? true : false,
      });
    }
  }, [editId, data]);

  const handleChangeImage = async (e) => {
    const { files } = e.target;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "DTNUnsigned");
    await axios
      .post("https://api.cloudinary.com/v1_1/dbdpfhov6/image/upload", formData)
      .then((res) => {
        setInputValue((prev) => ({
          ...prev,
          stImageUrl: res.data.secure_url,
        }));
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stCategoryName: inputValue.stCategoryName,
        stImageUrl: inputValue.stImageUrl,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        IsSelectSize: inputValue.flgIsSize ? "1" : "0",
        IsEngraving: inputValue.flgIsEngraving ? "1" : "0",
        unCategoryIdentifier: editId,
      };
      const res = await UpdateCategory(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchCategory());
      }
    } else {
      const payload = new FormData();
      payload.append("stCategoryName", inputValue.stCategoryName);
      payload.append("stImageUrl", inputValue.stImageUrl);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      payload.append("IsSelectSize", inputValue.flgIsSize ? "1" : "0");
      payload.append("IsEngraving", inputValue.flgIsEngraving ? "1" : "0");
      const res = await AddCategoryData(payload);
      if (res.status === 200) {
        dispatch(fetchCategory());
        toast.success(res.data.message);
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={editId ? "Update Category" : "Add Category"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={handleClose}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4 w-full">
        <label
          htmlFor="stCategoryName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Category Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stCategoryName"
            id="stCategoryName"
            value={inputValue.stCategoryName}
            onChange={(e) => handleChange(e)}
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Enter Category Name"
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="stImageUrl"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Image Url
        </label>
        <div className="flex items-center gap-[10px]">
          <div>
            <ImageUpload
              type="file"
              name="stImageUrl"
              id="stImageUrl"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChangeImage}
            />
          </div>
          {isLoading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {uploadImage && <BsCheckCircleFill className="text-green-600" />}
        </div>
      </div>
      <div className="flex items-center">
        <input
          id="link-checkbox"
          type="checkbox"
          name="flgIsActive"
          onChange={(e) => handleChange(e)}
          checked={inputValue.flgIsActive}
          className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
        />
        <label
          htmlFor="link-checkbox"
          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Is Active.
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="link-checkbox"
          type="checkbox"
          name="flgIsSize"
          onChange={(e) => handleChange(e)}
          checked={inputValue.flgIsSize}
          className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
        />
        <label
          htmlFor="link-checkbox"
          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Is Category Size.
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="link-checkbox"
          type="checkbox"
          name="flgIsEngraving"
          onChange={(e) => handleChange(e)}
          checked={inputValue.flgIsEngraving}
          className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
        />
        <label
          htmlFor="link-checkbox"
          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Is Engraving
        </label>
      </div>
    </AdminModal>
  );
}
