import React, { useEffect } from "react";
import Banner from "../Components/LandingComponent/Banner";
import Category from "../Components/LandingComponent/Category";
import JewelryTraceableSection from "../Components/LandingComponent/JewelryTraceableSection";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import HomeProduct from "../Components/LandingComponent/HomeProduct";
import NewBanner from "../Components/LandingComponent/NewBanner";
import Testimonial from "../Components/LandingComponent/Testimonial";
import VideoBanner from "../Components/LandingComponent/VideoBanner";
import { Link, useNavigate } from "react-router-dom";

const Main = () => {
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      {/* <div class="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
        <h1 class="text-5xl text-white font-bold mb-8 animate-pulse">
          Coming Soon
        </h1>
        <Link to={"/"} className="bg-white py-2 px-4 rounded ">
          Go Back
        </Link>
      </div> */}
      <Header />
      <Banner />
      <HomeProduct />
      {/* <Category /> */}
      <JewelryTraceableSection />
      <NewBanner />
      <Footer />
      {/* <VideoBanner /> */}
      {/* <Testimonial /> */}
    </div>
  );
};

export default Main;
