export const addElementsInArray = (
  data,
  elements = [{ type: 1 }],
  indexes = [4, 13]
) => {
  const lastIndex = indexes.slice().pop() - 1;
  const clonedArr = data.slice();
  let loopCount = 0;
  loopCount = Math.ceil(clonedArr.length / lastIndex);
  const newArr = [];
  const finalArr = [];
  for (let i = 0; i < loopCount; i++) {
    newArr.push(clonedArr.splice(0, lastIndex));
  }
  newArr.forEach((item) => {
    finalArr.push(addEleArr(item, elements, indexes));
  });
  return finalArr.flat();
};

export const addEleArr = (data, element, indexes) => {
  const clonedArr = data.slice();
  indexes.forEach((item, index) => {
    if (item <= clonedArr.length) {
      for (let i = clonedArr.length; i > item; i--) {
        clonedArr[i] = clonedArr[i - 1];
      }
      clonedArr[item] = element[index] || element[index - 1];
    }
  });
  return clonedArr;
};

export const sortByID = (data = [], type, key) => {
  const arr = [...data];
  return arr.sort((a, b) => {
    if (type === "asc") {
      return +a[key] === +b[key] ? 0 : +a[key] < +b[key] ? -1 : 1;
    } else {
      return +a[key] === +b[key] ? 0 : +a[key] > +b[key] ? -1 : 1;
    }
  });
};
export const sortByName = (data, type, key) => {
  const arr = [...data];
  return arr.sort((a, b) => {
    if (type === "asc") {
      return a[key] < b[key] ? -1 : 1;
    } else {
      return a[key] > b[key] ? -1 : 1;
    }
  });
};

export const transformCapitalize = (text) => {
  if (!text) return "";
  return text.replace(text.slice(1), text.slice(1).toLowerCase());
};

export const tawkWidgetToggle = (show) => {
  // Ensure the Tawk object has initalized
  if (window.$_Tawk && window.$_Tawk.init) {
    show ? showWidget() : hideWidget();
  } else {
    // If the Tawk object didn't initilize, use a differnt method of loading
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        show ? showWidget() : hideWidget();
      };
    }
  }
};

function showWidget() {
  window.Tawk_API.showWidget();
}

function hideWidget() {
  window.Tawk_API.hideWidget();
}

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
