import React from "react";
import Experiance from "../../assets/images/experience.png";
import Savemoney from "../../assets/images/save-money.png";
import Customize from "../../assets/images/customize.png";
import Honesty from "../../assets/images/honesty.png";

const Aboutus = () => {
  const benefits = [
    {
      title: "Expertise",
      image: Experiance,
      description:
        "Diamond polishers are skilled craftsmen who work with diamonds every day. They have an expert eye for quality and can ensure that the diamonds used in your jewelry piece are of the highest quality.",
    },
    {
      title: "Cost Savings",
      image: Savemoney,
      description:
        "When you buy directly from a diamond polisher, you can often save money compared to buying from a traditional retail store. Since there are no middlemen involved in the transaction, the prices can be more competitive.",
    },
    {
      title: "Customization",
      image: Customize,
      description:
        "A diamond polisher can work with you to create a customized jewelry piece that is tailored to your preferences. They can help you choose the perfect diamond shape, size, and setting to create a one-of-a-kind piece that suits your personal style.",
    },
    {
      title: "Trustworthiness",
      image: Honesty,
      description:
        "Diamond polishers take great pride in their work and reputation. They are committed to providing high-quality products and excellent customer service. When you buy from a diamond polisher, you can trust that you are getting a genuine and authentic diamond jewelry piece.",
    },
  ];
  return (
    <div className="sm:relative sm:isolate sm:mb-[127px] mb-[90px]">
      <div className="pt-24 md:pt-0 max-w-[1232px] w-full mx-auto xs:mt-[95px] sm:mt-0">
        <div className="text-center lg:mb-[45px] sm:mb-[25px] mb-[10px]">
          <h2 className="lg:text-[40px] font-alata leading-[52px] lg:mb-[6px] mb-[3px] text-[30px] font-[600] tracking-tight text-black">
            We Polish
          </h2>
          <p className="lg:text-[20px] text-[15px] lg:max-w-[70%] max-w-[90%] mx-auto text-[#626262] lg:leading-7 font-[500] font-alata">
            As diamond polisher, DTN can provide you with a unique and
            personalized experience that ensures you get the best possible
            quality at a competitive price.
          </p>
        </div>
        <ul className="mx-auto grid md:grid-cols-2 grid-cols-1 sm:gap-10 gap-[22px] bg-white/5 xl:py-[20px] py-[16px] ring-1 ring-white/10 sm:rounded-3xl lg:mx-0 lg:max-w-none xl:px-0 px-4">
          {benefits.map((benefit, i) => (
            <li
              key={benefit}
              className=" bg-[#F6F7F9] sm:p-[25px] px-[25px] py-[17px] rounded text-justify shadow relative hover:translate-x-[-6px] hover:translate-y-[-6px] hover-blue-shadow border border-[transparent] duration-300 hover:border-[#024C79]"
            >
              <div>
                <span className="cardCount">0{i + 1}</span>
                <img
                  src={benefit.image}
                  className="lg:w-[73px] w-[57px] lg:h-[73px] h-[57px] mb-[30px] object-cover"
                  alt="dataImage"
                />
                <h2 className="lg:text-[26px] text-[20px] leading-7 font-[500] font-Josefin mb-2">
                  {benefit?.title}
                </h2>
                <p className="rounded font-montserrat font-[500] lg:text-[16px] text-[14px] text-[#626262] leading-6">
                  {benefit.description}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Aboutus;
