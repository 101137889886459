import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStoneClarity } from "../../Services/stoneClarityService";

export const fetchStoneClarityData = createAsyncThunk("clarity/fetchData", async () => {
  const response = await getStoneClarity()
  return response.data.data.clarity
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const stoneClaritySlice = createSlice({
  name: "clarity",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStoneClarityData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchStoneClarityData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchStoneClarityData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getStoneClarityState = state => state.stoneClarityData

export default stoneClaritySlice.reducer