import React, { Fragment, useEffect, useMemo, useState } from "react";
import AgentSelectionListSkeleton from "../LandingComponent/Common/skeleton/AgentSelectionListSkeleton";
import DiamondListMobileSkeleton from "../LandingComponent/Common/skeleton/DiamondListMobileSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDesignByCategoryId,
  fetchDesignByCategoryStyleId,
  fetchDesigns,
  getDesignDataState,
} from "../../store/Slices/designSlice";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { GrClose, GrFormClose } from "react-icons/gr";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router";
import NoDataText from "../shared/NoDataText";
import { useRef } from "react";
import {
  Document,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import ECatalog from "./Catalog";
import Catalog from "./Modals/Catalog";
import dummyDiamond from "../../assets/images/dummy-diamond.png";
import ProductFilter from "../LandingComponent/CustomizeJewelry/ProductFilter";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";
import { getCustomizeJewelryData } from "../../store/Slices/customizeJewelrySlice";
import { transformCapitalize } from "../../Utils";
import useApi from "../../Hooks/use-Api";
import { getCategoryStylebyCategoryId } from "../../Services/categoryStyleService";
import { useThunk } from "../../Hooks/use-thunk";

const DesignCatalog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({
    designStoke: [],
  });
  const { data, loading, error } = useSelector(getDesignDataState);
  const componentRef = useRef();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryStyle, setSelectedCategoryStyle] = useState(null);
  const { data: Category } = useSelector(getCategoryDataState);
  const [selctedTab, setSelectedTab] = useState("");

  const [fetchcategoryStyles, categoryStyleData] = useApi(
    getCategoryStylebyCategoryId
  );
  const [fetchDesignData] = useThunk(fetchDesigns);
  const [fetchDesignDataByCategory] = useThunk(fetchDesignByCategoryId);
  const [fetchDesignDataByCategoryStyle] = useThunk(
    fetchDesignByCategoryStyleId
  );

  useEffect(() => {
    if (selectedCategory) {
      fetchcategoryStyles(selectedCategory);
    }
  }, [selectedCategory, fetchcategoryStyles]);

  // useEffect(() => {
  //   dispatch(fetchCategory())
  // }, [dispatch])

  useEffect(() => {
    if (selectedCategoryStyle) {
      fetchDesignDataByCategoryStyle(selectedCategoryStyle);
    } else if (selectedCategory) {
      fetchDesignDataByCategory(selectedCategory);
    } else {
      const params = {
        inPageSize: 1000000,
        inPageNo: 1,
      };
      fetchDesignData(params);
    }
  }, [
    selectedCategory,
    selectedCategoryStyle,
    fetchDesignData,
    fetchDesignDataByCategory,
    fetchDesignDataByCategoryStyle,
  ]);

  useEffect(() => {
    dispatch(fetchDesigns());
  }, [dispatch]);

  const downloadPdf = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePDF = () => {
    downloadPdf();
  };

  const handleChangeMultiData = (val, e) => {
    const { name } = e.target.closest("button");
    setSelected((prev) => ({
      ...prev,
      [name]: prev[name].includes(val)
        ? prev[name].filter((item) => item !== val)
        : prev[name].concat(val),
    }));
  };

  const categoryOptions = useMemo(() => {
    return Category.map((item) => ({
      name: item.stCategoryName,
      id: item.unCategoryIdentifier,
    }));
  }, [Category]);

  const categoryStyleOptions = useMemo(() => {
    if (categoryStyleData) {
      return categoryStyleData.categories_style.map((item) => ({
        name: item.stCategoryStyleName,
        id: item.unCategoryStyleIdentifier,
      }));
    }
    return [];
  }, [categoryStyleData]);

  const handleChangeFilter = (name, value) => {
    if (name === "category") {
      setSelectedCategoryStyle("");
      setSelectedCategory((prev) => (prev === value ? "" : value));
    }
    if (name === "categoryStyle") {
      setSelectedCategoryStyle((prev) => (prev === value ? "" : value));
    }
    if (name === "price") {
    }
    if (name === "metal") {
    }
  };

  const findCategory = (id) => {
    if (categoryOptions.length > 0) {
      const findData = categoryOptions.find((item) => item.id === id);
      return findData.name;
    }
  };
  const findCategoryStyle = (id) => {
    if (categoryStyleOptions.length > 0) {
      const findData = categoryStyleOptions.find((item) => item.id === id);
      return findData.name;
    }
  };

  const handleRemoveSelected = () => {
    setSelected((prev) => ({
      ...prev,
      designStoke: [],
    }));
  };

  return (
    <div className="grid bg-[#f3f4f6]">
      <div className="w-full py-4">
        <div className="px-4">
          <div className="flex items-center gap-2 mt-8">
            <div className="">
              <button
                type="button"
                onClick={() =>
                  setSelectedTab((prev) =>
                    prev === "category" ? "" : "category"
                  )
                }
                className={`font-semibold text-[14px] border px-2 flex items-center gap-2 ${
                  selectedCategory ? "border-slate-600" : "border-slate-300"
                }`}
              >
                {selectedCategory ? findCategory(selectedCategory) : "Category"}
                {selectedCategory && (
                  <GrFormClose
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCategory("");
                      setSelectedCategoryStyle("");
                    }}
                    className="text-[20px] "
                  />
                )}
              </button>
            </div>
            <div className="">
              <button
                type="button"
                onClick={() =>
                  setSelectedTab((prev) => (prev === "style" ? "" : "style"))
                }
                className={`font-semibold text-[14px] border px-2 flex items-center gap-2 ${
                  selectedCategoryStyle
                    ? "border-slate-600"
                    : "border-slate-300"
                }`}
              >
                {selectedCategoryStyle
                  ? findCategoryStyle(selectedCategoryStyle)
                  : "Styles"}
                {selectedCategoryStyle && (
                  <GrFormClose
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCategoryStyle("");
                    }}
                    className="text-[20px] "
                  />
                )}
              </button>
            </div>
          </div>
          {selctedTab === "category" && (
            <div className="flex justify-start self-start gap-2 flex-wrap mt-2">
              {categoryOptions.map((item, index) => {
                return (
                  <button
                    disabled={loading}
                    key={index}
                    className={`flex gap-1 justify-center items-center min-w-[80px] sm:p-2 p-1 font-semibold font-openSans sm:text-[13px] text-xs outline-none border-b-2 ${
                      selectedCategory === item.id
                        ? "border-gray-700"
                        : "border-transparent"
                    } sm:hover:border-gray-700`}
                    onClick={() => {
                      handleChangeFilter("category", item.id);
                    }}
                  >
                    {transformCapitalize(item.name)}
                  </button>
                );
              })}
            </div>
          )}
          {selctedTab === "style" && (
            <div className="flex justify-start self-start gap-2 flex-wrap mt-2">
              {categoryStyleOptions.map((item, index) => {
                return (
                  <button
                    disabled={loading}
                    key={index}
                    className={`flex gap-1 justify-center items-center min-w-[80px] sm:p-2 p-1 font-semibold font-openSans sm:text-sm text-xs outline-none border-b-2 ${
                      selectedCategoryStyle === item.id
                        ? "border-gray-700"
                        : "border-transparent"
                    } sm:hover:border-gray-700`}
                    onClick={() => {
                      handleChangeFilter("categoryStyle", item.id);
                    }}
                  >
                    {transformCapitalize(item.name)}
                  </button>
                );
              })}
            </div>
          )}
          <div className={`mb-4 px-0 flex items-center sm:gap-[10px] gap-[5px] ${selected.designStoke.length > 0 ? 'justify-between' : 'justify-start'} mt-3`}>
            <button
              onClick={() => navigate("/admin/agentMenu")}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </button>
            {selected.designStoke.length > 0 && (
              <div className="flex gap-[5px]">
                <div className="flex items-center justify-end">
                  <button className="rounded-md p-2 sm:text-[14px] text-[12px] bg-white shadow font-normal cursor-auto flex items-center gap-2">
                    <div>
                      {selected.designStoke.length}{" "}
                      <span className="font-medium">Selected</span>
                    </div>
                    <button onClick={handleRemoveSelected}>
                      <GrClose />
                    </button>
                  </button>
                </div>
                <Catalog
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  data={selected.designStoke}
                />
                <button
                  onClick={() => {
                    window.print();
                    return false;
                  }}
                  className="rounded-md py-2 sm:px-4 px-2 sm:text-[14px] text-[12px] bg-white text-black text-opacity-70 font-medium shadow flex items-center cursor-pointer gap-2"
                >
                  Genrate PDF
                </button>
              </div>
            )}
          </div>
          {/* </div> */}
          <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
            {data.length > 0 && !error ? (
              data.map((item, i) => {
                return (
                  <button
                    key={i}
                    type="button"
                    name="designStoke"
                    className={`bg-white shadow rounded-lg overflow-hidden max-h-[340px]  border hover:shadow-lg ${
                      selected?.designStoke?.length > 0 &&
                      selected?.designStoke?.find(
                        (it) =>
                          it.unDesignIdentifier === item.unDesignIdentifier
                      )
                        ? "border-[#1F2937]"
                        : "border-[#fff]"
                    }`}
                    onClick={handleChangeMultiData.bind(null, item)}
                  >
                    <div className="w-full">
                      <div className="flex w-full items-center justify-between space-x-6 p-4">
                        <img
                          className="h-20 w-20 flex-shrink-0 rounded bg-gray-300 border"
                          src={item.stDefImageURL}
                          alt=""
                        />
                        <div className="flex-1 text-left truncate">
                          <div className="flex items-center justify-between space-x-3">
                            <h3 className="truncate text-[16px] font-medium text-gray-900">
                              {item.stDesignName}
                            </h3>
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mt-2">
                              {item.stDesignAlias}
                            </span>
                          </div>
                          <h2 className="text-[16px] font-[500]">
                            $ {item.dcSalePrice}
                          </h2>
                        </div>
                      </div>
                      {/* <div className="border-t border-gray-200">
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="flex w-0 flex-1">
                            <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-3 text-sm font-semibold text-gray-900">
                              <h2 className="text-[16px] font-[500]">
                                {item.category}
                              </h2>
                            </div>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-3 text-sm font-semibold text-gray-900">
                              <h2 className="text-[16px] font-[500]">
                                {item.categoryStyle}
                              </h2>
                            </div>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-3 text-sm font-semibold text-gray-900">
                              <h2 className="text-[16px] font-[500]">
                                $ {item.dcSalePrice}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="flex flex-col gap-2 h-full">
                      <div className="max-h-70 h-full rounded-xl overflow-hidden w-full relative border">
                        {item?.stDefImageURL?.includes("https") ? (
                          <img
                            src={item?.stDefImageURL}
                            alt="stockData"
                            className="object-cover h-full w-full"
                          />
                        ) : (
                          <div className="w-full h-[293px] bg-gray-200 flex items-center justify-center">
                            <img
                              src={dummyDiamond}
                              alt="stockData"
                              className="object-cover h-[100px] w-[100px]"
                            />
                          </div>
                        )}
                      </div>
                      <div className="pb-2 w-full border-b border-gray-300 flex items-center  justify-between">
                        <h1 className="text-left font-bold text-gray-600 titleOwerflow">
                          {item.stDesignName}
                        </h1>
                        <h2 className="font-semibold text-gray-600 text-[14px]">
                          {item.stDesignAlias}
                        </h2>
                      </div>
                      <div className="flex items-start w-full">
                        <div className="border-r px-2 w-[92px]">
                          <span className="font-medium text-sm">
                            {item.category}
                          </span>
                        </div>
                        <div className="border-r px-2 w-[92px]">
                          <span className="font-medium text-sm">
                            {item.categoryStyle}
                          </span>
                        </div>
                        <div className="px-2 w-[92px]">
                          <span className="font-medium text-sm">
                            $ {item.dcSalePrice}
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </button>
                );
              })
            ) : (
              <div className="col-span-2">
                <NoDataText text={"No data"} />
              </div>
            )}
            {loading && (
              <>
                <div className="hidden lg:grid lg:gap-4 mb-4 py-2">
                  <AgentSelectionListSkeleton />
                  {!data?.length && (
                    <>
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                      <AgentSelectionListSkeleton />
                    </>
                  )}
                </div>
                <div className="lg:hidden grid gap-4 py-2">
                  <DiamondListMobileSkeleton />
                  {!data?.length && <DiamondListMobileSkeleton />}
                </div>
              </>
            )}
          </div>
          {/* <div
          className="grid grid-cols-6 gap-4 px-4 py-8 m-4 bg-white w-[595px] h-[1060px] mx-auto"
          ref={componentRef}
        >
          {" "}
          {selected.designStoke.map((item, i) => {
            return (
              <div
                key={i}
                className="col-span-6 odd:border-b border-[#444] odd:pb-20 pt-20"
              >
                <div className="grid grid-cols-6 w-full gap-[41px]">
                  {" "}
                  {item.designImages.slice(0, 3).map((ite, ind) => {
                    return (
                      <img
                        src={ite.stImageURL}
                        alt="ImageURL"
                        className="w-[160px] h-[120px] ded object-contain border col-span-2"
                      />
                    );
                  })}
                </div>
                <div className="mt-10">
                  <table className="border border-[#a4a4a4] text-left">
                    <tr className="border-b border-[#a4a4a4] text-left">
                      <th className="w-[200px]">Name:-</th>
                      <td className="border-l border-[#a4a4a4] w-[200px]">{item.stDesignName}</td>
                    </tr>
                    <tr className="border-b border-[#a4a4a4] text-left">
                      <th className="w-[200px]"> Design Number:-</th>
                      <td className="border-l border-[#a4a4a4] w-[200px]">{}</td>
                    </tr>
                    <tr className="border-b border-[#a4a4a4] text-left">
                      <th className="w-[200px]">Gold/Metal:-</th>
                      <td className="border-l border-[#a4a4a4] w-[200px]">{}</td>
                    </tr>
                    <tr className="border-b border-[#a4a4a4] text-left">
                      <th className="w-[200px]">Main Stone:-</th>
                      <td className="border-l border-[#a4a4a4] w-[200px]">{}</td>
                    </tr>
                    <tr className="border-b border-[#a4a4a4] text-left">
                      <th className="w-[200px]">Side Stone:-</th>
                      <td className="border-l border-[#a4a4a4] w-[200px]">{}</td>
                    </tr>
                  </table>
                </div>
              </div>
            );
          })}
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default DesignCatalog;

const PdfData = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      display: "grid",
    },
    imageWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "41px",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.section}>
          {data.length > 0 &&
            data.designStoke.map((item, i) => {
              return (
                <View
                  key={i}
                  style={styles.innerWrapper}
                >
                  <View style={styles.imageWrapper}>
                    {
                      item.designImages.slice(0, 3).map((ite, ind) => {
                        return(
                          <Image src={ite.stImageURL} style={styles}>
                        )
                      })}
                    }
                  </View>
                </View>
              );
            })}
        </View> */}
      </Page>
    </Document>
  );
};
