import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSideStonePriceList } from "../../Services/sideStonePriceService";

export const fetchSideStonePriceList = createAsyncThunk("sideStonePrice/fetchSideStonePriceList", async () => {
  const response = await getSideStonePriceList()
  return response.data.data.price
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const sideStonePriceSlice = createSlice({
  name: "sideStonePrice",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSideStonePriceList.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchSideStonePriceList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchSideStonePriceList.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getSideStonePriceState = state => state.sideStonePrice

export default sideStonePriceSlice.reducer