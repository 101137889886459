import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDiamondReqData } from "../../Services/stockRequestService";

export const fetchDiasmondRequestData = createAsyncThunk("diamondRequest/fetchData", async () => {
  const response = await getDiamondReqData()
  return response.data.data.stock_request
})

const initialState = {
  data: [],
  loading: false,
  error: null
}

const diamondRequestSlice = createSlice({
  name: "diamondRequest",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDiasmondRequestData.pending, (state) => {
      state.loading = true
    })
      .addCase(fetchDiasmondRequestData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchDiasmondRequestData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const getDiamondRequestState = state => state.diamondRequest

export default diamondRequestSlice.reducer