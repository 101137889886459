import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategoryStyle } from "../../Services/categoryStyleService";

export const fetchCategoryStyle = createAsyncThunk(
  "categoryStyle/fetchCategoryStyle",
  async () => {
    const response = await getCategoryStyle();
    return response.data.data.categories_style;
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const CategoryStyleSlice = createSlice({
  name: "categoryStyle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryStyle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCategoryStyle.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      })
      .addCase(fetchCategoryStyle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getCategoryStyleDataState = (state) => state.categoryStyleData;

export default CategoryStyleSlice.reducer;
