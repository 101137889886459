import api from "./api"

const getStoneColor = () => {
  return api("/api/StoneColor", "get")
}

const getStoneColorById = (id) => {
  return api(`/api/StoneColor/${id}`, "get")
}

const createStoneColor = (data) => {
  return api("/api/StoneColor", "post", data)
}

const updateStoneColor = (data) => {
  return api("/api/StoneColor", "put", data)
}

const DeleteStoneColor = (data) => {
  return api(`/api/StoneColor/${data}`, "delete")
}

export {
  getStoneColor,
  getStoneColorById,
  createStoneColor,
  updateStoneColor,
  DeleteStoneColor
}