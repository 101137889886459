import React, { useState, useEffect } from "react";
import AdminModal from "../common/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { fetchSupplier } from "./../../../store/Slices/supplierSlice";
import {
  createSuppliers,
  updateSuppliers,
} from "../../../Services/supplierService";
import { getSupplierDataState } from "../../../store/Slices/supplierSlice";

const AddSupplierModal = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [inputValue, setInputValue] = useState({
    stAccountName: "",
    stAccountAlias: "",
    stOwnerPerson: "",
    stAddress: "",
    unCityId: "",
    unStateId: "",
    unCountryId: "",
    stPinCode: "",
    stPhoneNo: "",
  });
  const dispatch = useDispatch();
  const { data } = useSelector(getSupplierDataState);

  useEffect(() => {
    const editData = data.find((item) => item.unAccountIdentifier === editId);
    if (editData) {
      setInputValue({
        stAccountName: editData.stAccountName,
        stAccountAlias: editData.stAccountAlias,
        stOwnerPerson: editData.stOwnerPerson,
        stAddress: editData.stAddress,
        unCityId: editData.unCityId,
        unStateId: editData.unStateId,
        unCountryId: editData.unCountryId,
        stPinCode: editData.stPinCode,
        stPhoneNo: editData.stPhoneNo,
      });
    }
  }, [editId, data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setEditId("");
    setInputValue({
      stAccountName: "",
      stAccountAlias: "",
      stOwnerPerson: "",
      stAddress: "",
      unCityId: "",
      unStateId: "",
      unCountryId: "",
      stPinCode: "",
      stPhoneNo: "",
    });
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        unAccountIdentifier: editId,
        stAccountName: inputValue.stAccountName,
        stAccountAlias: inputValue.stAccountAlias,
        stOwnerPerson: inputValue.stOwnerPerson,
        stAddress: inputValue.stAddress,
        unCityId: inputValue.unCityId,
        unStateId: inputValue.unStateId,
        unCountryId: inputValue.unCountryId,
        stPinCode: inputValue.stPinCode,
        stPhoneNo: inputValue.stPhoneNo,
      };
      const res = await updateSuppliers(payload);
      if (res.data.success) {
        toast.success(res?.data?.message);
        dispatch(fetchSupplier());
        setEditId();
      } else {
        toast.error(res?.data?.message);
      }
    } else {
      const payload = new FormData();
      payload.append("stAccountName", inputValue.stAccountName);
      payload.append("stAccountAlias", inputValue.stAccountAlias);
      payload.append("stOwnerPerson", inputValue.stOwnerPerson);
      payload.append("stAddress", inputValue.stAddress);
      payload.append("unCityId", inputValue.unCityId);
      payload.append("unStateId", inputValue.unStateId);
      payload.append("unCountryId", inputValue.unCountryId);
      payload.append("stPinCode", inputValue.stPinCode);
      payload.append("stPhoneNo", inputValue.stPhoneNo);
      const res = await createSuppliers(payload);
      if (res.data.success) {
        toast.success(res?.data?.message);
        dispatch(fetchSupplier());
      } else {
        toast.error(res?.data?.message);
      }
    }
    handleClose();
  };

  return (
    <AdminModal
      title={editId ? "Update Supplier" : "Add Supplier"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="grid w-full grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <div className="mb-2 col-span-6 sm:col-span-3">
          <label
            htmlFor="stAccountName"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Account Name
          </label>
          <div>
            <input
              type="text"
              name="stAccountName"
              value={inputValue.stAccountName}
              id="stAccountName"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Account Name"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex items-center gap-[10px] col-span-6 sm:col-span-3">
          <div className="mb-2 w-[400px]">
            <label
              htmlFor="stAccountAlias"
              className="block text-[14px] font-medium leading-6 text-gray-900"
            >
              Account Alias
            </label>
            <div className="col-span-8 sm:col-span-4">
              <input
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                type="text"
                name="stAccountAlias"
                id="stAccountAlias"
                value={inputValue.stAccountAlias}
                onChange={handleChange}
                placeholder="Enter Account Alias"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[10px] col-span-6 sm:col-span-3">
          <div className="mb-2 w-[400px]">
            <label
              htmlFor="stOwnerPerson"
              className="block text-[14px] font-medium leading-6 text-gray-900"
            >
              Owner Person
            </label>
            <div className="col-span-8 sm:col-span-4">
              <input
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                type="text"
                name="stOwnerPerson"
                id="stOwnerPerson"
                value={inputValue.stOwnerPerson}
                onChange={handleChange}
                placeholder="Enter Account Alias"
              />
            </div>
          </div>
        </div>
        <div className="mb-2 sm:col-span-full col-span-3">
          <label
            htmlFor="stAddress"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Address
          </label>
          <div>
            <input
              type="text"
              name="stAddress"
              value={inputValue.stAddress}
              id="stAddress"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unCityId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            City
          </label>
          <div>
            <input
              type="text"
              name="unCityId"
              value={inputValue.unCityId}
              id="unCityId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unStateId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            State
          </label>
          <div>
            <input
              type="text"
              name="unStateId"
              value={inputValue.unStateId}
              id="unStateId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="unCountryId"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Country
          </label>
          <div>
            <input
              type="text"
              name="unCountryId"
              value={inputValue.unCountryId}
              id="unCountryId"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="stPinCode"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Pin Code
          </label>
          <div>
            <input
              type="text"
              name="stPinCode"
              value={inputValue.stPinCode}
              id="stPinCode"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2 col-span-3">
          <label
            htmlFor="stPhoneNo"
            className="block text-[14px] font-medium leading-6 text-gray-900"
          >
            Phone No.
          </label>
          <div>
            <input
              type="text"
              name="stPhoneNo"
              value={inputValue.stPhoneNo}
              id="stPhoneNo"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddSupplierModal;
