import React, { useState } from "react";
import AdminModal from "./common/AdminModal";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Chip from "@mui/material/Chip";
import "jspdf-autotable";

function SnapPostDetailModal(props) {
  const location = useLocation();

  const { open, handleClose, data } = props;

  return (
    <AdminModal
      title={"Detail"}
      onSubmit={() => {}}
      disableSubmit={false}
      showButton={false}
      isOpen={open}
      onClose={() => handleClose()}
      submitButtonText={"Submit Order"}
      className="min-w-[42%]"
      showActionButtons={false}
    >
      {/* <img
                src={data?.ImageUrl}
                alt="Image"
                className="w-full md:w-44 h-full md:h-44 mt-2"
              /> */}
      <div className="p-2">
        <div className="flex flex-col justify-between xl:flex-row xl:gap-x-6 2xl:gap-x-0">
          <div className="w-full xl:w-[35%] md:w-max">
            <div className="w-full grid grid-cols-2 gap-x-2 gap-y-2 content-center">
              {data?.jsonImages ? (
                <>
                  {JSON.parse(data?.jsonImages)?.map((image) => {
                    return (
                      <img
                        src={image?.url}
                        alt="Image"
                        className="w-36 h-36 md:w-24 md:h-24 mt-2 object-cover"
                      />
                    );
                  })}
                </>
              ) : (
                <MdOutlineImageNotSupported className="h-36 w-36 text-gray-500 ml-0 xl:ml-5" />
              )}
            </div>
          </div>
          <div className="w-full xl:w-[65%] flex flex-col gap-y-7 justify-center self-start p-2 xl:p-0 mb-4">
            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Order No</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stOrderNo || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Order Date</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.dtOrderDate || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">CSTM Code</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stCustomerCode || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Style</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stStyle || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Band Width</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stBandWidth || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Center Stone</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stCenterStone || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">L/W/D Ratio</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stLength || "-"}/{data?.stWidth || "-"}/
                  {data?.stDepth || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Ring Size</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stCategorySize || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Metal</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stMetal || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Stamp</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stStamping || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Engraving</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stEngraving || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Deadline</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.dtDeliveryDate || "-"}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Remark</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.stRemark1 || "-"}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Type</p>
                <p className="text-sm font-medium text-gray-700">
                  <Chip
                    label={data?.stRemark2 ? "Quote" : "Snap"}
                    color={data?.stRemark2 ? "success" : "primary"}
                    variant="outlined"
                    size="small"
                    className="mt-1"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <nav class="flex items-center justify-end flex-wrap bg-gray-700 p-2">
        <div>
          <button
            onClick={downloadPDF}
            class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white duration-200"
          >
            Download PDF
          </button>
        </div>
      </nav> */}
      </div>
    </AdminModal>
  );
}

export default SnapPostDetailModal;
