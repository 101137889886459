import React from "react";
import { useState } from "react";
import AutoComplete from "../common/CustomAutoComplete/AutoComplete";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDesignAttributeValue,
  getDesignById,
} from "../../../Services/designService";
import { getAttributeById } from "../../../Services/attributeService";
import { getAttributeValueByAttribute } from "../../../Services/attributeValueService";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../../assets/images/loading4.gif";
import { useCallback } from "react";
import { fetchDesigns } from "../../../store/Slices/designSlice";

const StepThree = ({ isSubmit, setBasicInfo, basicInfo, setStepCount }) => {
  const [prevPageData, setPrevPageData] = useState({});
  const [loading, setLoading] = useState(true);
  const [attributeValueData, setAttributeValueData] = useState([]);
  const [selectedAttributeValueData, setSelectedAttributeValueData] = useState(
    {}
  );
  const dispatch = useDispatch();
  const id = localStorage.design;
  const navigate = useNavigate();

  useEffect(() => {
    if (prevPageData?.designAttribute?.length) {
      prevPageData?.designAttribute.forEach((item) => {
        return getAttributesData(item);
      });
    }
    if (
      prevPageData?.designAttribute?.length &&
      prevPageData?.designAttributeValue?.length
    ) {
      const attrValueData = prevPageData?.designAttribute?.map((attr) => {
        const values = prevPageData?.designAttributeValue.filter(
          (val) => val.unAttributeId === attr.unAttributeId
        );
        return {
          [attr.unAttributeId]: values.map((val) => ({
            ...val,
            title: val.stAttributeValueName,
            id: val.unAttributeValueId,
          })),
        };
      });

      const finalData = attrValueData.reduce(
        (acc, cur) => ({ ...acc, ...cur }),
        {}
      );
      setSelectedAttributeValueData(finalData);
    }
  }, [prevPageData]);

  const getAttributesData = async (attr) => {
    setAttributeValueData((prev) => [
      ...prev,
      {
        attributeName: attr.stAttributeName,
        attributeId: attr.unAttributeId,
        values: [],
      },
    ]);

    const response = await getAttributeById(attr.unAttributeId);
    if (response.status === 200) {
      getAttributesValueData(response.data.data);
    }
  };

  const getAttributesValueData = async (attr) => {
    const attrId = attr.unAttributeIdentifier;
    const response = await getAttributeValueByAttribute(attrId);
    if (response.status === 200) {
      const values = response.data.data.attribute_value?.map((item) => {
        return {
          id: item.unAttributeValueIdentifier,
          title: item.stAttributeValueName,
        };
      });
      const data = {
        values: values,
        attributeName: attr.stAttributeName,
        attributeId: attrId,
      };
      setAttributeValueData((prev) =>
        prev.map((item) => (item.attributeId === attrId ? data : item))
      );
      setLoading(false);
    }
  };

  const fetchDesignById = useCallback(async (id) => {
    const response = await getDesignById(id);
    setPrevPageData(response.data.data);
  }, []);

  useEffect(() => {
    if (id) {
      fetchDesignById(id);
    }
  }, [id, fetchDesignById]);

  const handleChangeAttributeValues = (data, attrId) => {
    setSelectedAttributeValueData((prev) => ({ ...prev, [attrId]: data }));
  };

  const handleSave = async () => {
    const finalData = {};
    for (let key in selectedAttributeValueData) {
      finalData[key] = selectedAttributeValueData[key].map((item) => item.id);
    }
    const payload = new FormData();
    payload.append("unDesignIdentifier", id);
    payload.append("AttributeValueDetails", JSON.stringify(finalData));
    const res = await addDesignAttributeValue(payload);
    if (res.status === 200) {
      if (basicInfo) {
        setStepCount(1);
        toast.success("Design Successfully Added.");
        localStorage.removeItem("design");
        localStorage.removeItem("attributeKey");
      } else {
        navigate("/admin/product");
        toast.success("Design Successfully Added.");
        localStorage.removeItem("design");
        localStorage.removeItem("attributeKey");
      }
      dispatch(fetchDesigns(1));
    }
  };

  useEffect(() => {
    if (isSubmit) {
      handleSave();
      setBasicInfo(false);
    }
  }, [isSubmit]);

  return (
    <>
      <div className="grid grid-cols-1 gap-y-10 gap-x-8 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Attribute Value
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            We'll always let you know about important changes, but you pick what
            else you want to hear aboutimport {useSelector} from 'react-redux';
            .
          </p>
        </div>

        <div className="grid max-w-2xl grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-6 md:col-span-2">
          {loading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {attributeValueData.map((item, index) => {
            return (
              <div key={index} className="sm:col-span-full col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  {item.attributeName}
                </label>
                <AutoComplete
                  className="w-[75%] md:w-[80%]"
                  defValue={selectedAttributeValueData[item.attributeId] || []}
                  onChange={(data) => {
                    handleChangeAttributeValues(data, item.attributeId);
                  }}
                  options={item.values}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StepThree;
