import React, { useState } from "react";

const usePagination = ({ data, itemsPerPage }) => {
  const [activePage, setActivePage] = useState(1);

  const indexOfLastPost = activePage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentData = data?.slice(indexOfFirstPost, indexOfLastPost);

  return { currentData, activePage, setActivePage, itemsPerPage };
};

export default usePagination;
