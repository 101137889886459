import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDesignersData } from "../../Services/designerService";

export const fetchDesignerData = createAsyncThunk("designer/fetchDesignerData",
  async () => {
    const response = await getDesignersData()
    return response.data.data
  })

const initialState = {
  data: [],
  loading: false,
  error: null
}

const designerSlice = createSlice({
  name: "designer",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDesignerData.pending, (state, action) => {
      state.loading = true
    })
      .addCase(fetchDesignerData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchDesignerData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      })
  }
})

export const getDesignerDataState = state => state.designerData

export default designerSlice.reducer