import React, { useState } from "react";
import Header from "../Components/LandingComponent/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useMemo } from "react";
// import ProductList from "../Components/LandingComponent/CustomizeJewelry/ProductList";
import StylesProductList from "../Components/LandingComponent/CustomizeJewelry/StylesProductList";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { VscSettings } from "react-icons/vsc";
import { IoIosArrowBack } from "react-icons/io";
import {
  fetchCategory,
  fetchDesignCategory,
  getCategoryDataState,
} from "../store/Slices/categorySlice";
import { fetchDesignByCategoryId, setSearchedText } from "../store/Slices/designSlice";
import { clearSelectedCategory } from "../store/Slices/categorySlice";
import { useThunk } from "../Hooks/use-thunk";
import { setCustomizeJewelryAns } from "../store/Slices/customizeJewelrySlice";
import CustomizedJewelryFilter from "../Components/LandingComponent/CustomizeJewelry/ProductFilter";
import DownArrow from "../assets/images/icons/down_arrow_black.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Badge from "@mui/material/Badge";

export default function StylesWeMadeNew() {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get("category");

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, _categoryData] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);
  const { data } = useSelector(getCategoryDataState);
  const selectedCategoryData = useSelector(
    (state) => state.categoryData.selectedCategory
  );
  const categoryList = useSelector((state) => state.categoryData.data);
  const { searchedText } = useSelector((state) => state.designData);

  const stockDesignCategories = useSelector(
    (state) => state.categoryData.stockDesignCategories
  );

  const [fetchDesignDataByCategory] = useThunk(fetchDesignByCategoryId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory()).then((res) => _categoryData(res?.payload));
    dispatch(fetchDesignCategory());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCategory?.id) {
      // fetchDesignDataByCategory(selectedCategory?.id);
    }
  }, [fetchDesignDataByCategory]);

  useEffect(() => {
    const payload = {
      category: selectedCategory,
    };
    dispatch(setCustomizeJewelryAns(payload));
  }, [selectedCategory, dispatch]);

  const linkTab = useMemo(() => {
    return [
      {
        title: (
          <span className="hover:underline inline-flex justify-start items-center gap-1 cursor-pointer">
            {" "}
            Home{" "}
          </span>
        ),
        onClick: () => {
          navigate("/jewelry");
        },
      },
      {
        //title: "",
        title: (
          <span className="hover:underline inline-flex justify-start items-center gap-1 cursor-pointer">
            {" Customized Jewelry "}
          </span>
        ),
        onClick: async () => {
          setSelectedCategory(null);
        },
      },
    ];
  }, [navigate]);

  useEffect(() => {
    if (!categoryParam || categoryParam === "all") {
      setSelectedCategory({ id: undefined, name: undefined });
    } else {
      const findCategory = categoryList?.find(
        (item) => item?.stCategoryName?.toLowerCase() === categoryParam
      );
      setSelectedCategory({
        id: findCategory?.unCategoryIdentifier,
        name: findCategory?.stCategoryName,
      });
    }
  }, [categoryParam, categoryList]);
  
  const breadcrumbs = [
    <div
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        dispatch(clearSelectedCategory());
        navigate(`/home?tab=jewelry`);
      }}
      className="text-xs md:text-sm cursor-pointer"
    >
      Home
    </div>,
    <div
      underline="hover"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearSelectedCategory());
        navigate(
          `/category-selection?type=${selectedCategoryData?.[0]?.selectedType || 'customizedJewelry'}`
        );
      }}
      className="text-xs md:text-sm cursor-pointer"
    >
      Styles We Made
    </div>,
    <div key="3" className="text-xs md:text-sm text-gray-700">
      Product List
    </div>,
  ];

  useEffect(() => {
    if (selectedCategoryData?.length) {
      const { unCategoryIdentifier, stCategoryName } =
        selectedCategoryData?.[0];
      setSelectedCategory({
        id: unCategoryIdentifier,
        name: stCategoryName,
      });
    }
  }, [selectedCategoryData]);

  // const { dataCopy, loading, error } = useSelector(getReadyToShipDataState);
  // const productFilteredData = useMemo(() => {
  //   return dataCopy?.length > 0
  //     ? dataCopy.filter((item, i) => item.IsSale === 1)
  //     : [];
  // }, [dataCopy]);

  const productFilteredData = [];

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const updateURLCategory = (newCategory) => {
    // Parse existing query parameters
    const searchParams = new URLSearchParams(location.search);
    
    // Override the 'category' parameter
    searchParams.set('category', newCategory);
    
    // Construct the new URL with the updated query parameter
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    
    // Navigate to the new URL
    navigate(newUrl);
  };

  return (
    <>
      <Header headerText="In store" />
      <div className="bg-white">
        <div>
          {/* Mobile filter dialog */}
          <Dialog
            className="relative z-40 lg:hidden"
            open={mobileFiltersOpen}
            onClose={setMobileFiltersOpen}
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 z-40 flex">
              <DialogPanel
                transition
                className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
              >
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="relative -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4 border-t border-gray-200">
                  {/* Mobile Categories Dropdown  */}
                  <Disclosure
                    as="div"
                    key="categories"
                    className="border-t px-4 py-6 border-b border-[#e5e7eb]"
                    defaultOpen
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <DisclosureButton className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500 ">
                            <span className="text-[#121212] text-xl font-[500]">
                              Categories
                              <hr class="w-[50px] h-[3px] border-0 rounded bg-[#295268] mt-2"></hr>
                            </span>
                            <span className="ml-6 flex items-center">
                              <img
                                src={DownArrow}
                                className={`h-5 w-5 ${open && "rotate-180"}`}
                              />
                            </span>
                          </DisclosureButton>
                        </h3>
                        <DisclosurePanel className="pt-6">
                          <ul className="space-y-3">
                            {categoryList.map((category) => (
                              <li
                                key={category.stCategoryName}
                                className="border-b border-[#BABABA] border-dashed px-0 pb-3 pt-0"
                              >
                                <div
                                  onClick={() => {
                                    setSelectedCategory({
                                      id: category?.unCategoryIdentifier,
                                      name: category?.stCategoryName,
                                    });
                                    updateURLCategory(
                                      category?.stCategoryName?.toLowerCase()
                                    );
                                  }}
                                  className={`font-[500] text-sm cursor-pointer
                                    ${
                                      selectedCategory?.id ===
                                      category?.unCategoryIdentifier
                                        ? "text-[#295268]"
                                        : "text-[#6A6A6A]"
                                    }
                                    `}
                                >
                                  {category.stCategoryName} (
                                  {
                                    stockDesignCategories?.find(
                                      (i) =>
                                        i?.CategoryId ===
                                        category?.unCategoryIdentifier
                                    )?.totCount
                                  }
                                  )
                                </div>
                              </li>
                            ))}
                          </ul>
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                  <div className="px-4">
                    <CustomizedJewelryFilter
                      selectedCategory={selectedCategory}
                    />
                  </div>
                </form>
              </DialogPanel>
            </div>
          </Dialog>

          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between pb-0 pt-3">
              {/* <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                In Store Jewelry
              </h1> */}

              {/* <button
                type="button"
                onClick={() => {
                  dispatch(clearSelectedCategory());
                  navigate(
                    `/category-selection?type=${selectedCategoryData?.[0]?.selectedType}`
                  );
                }}
                className="flex lg:hidden items-center outline-none gap-1 text-[#212121]"
              >
                <IoIosArrowBack /> Back
              </button> */}

              <div className="py-2 flex lg:hidden">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
              </div>

              <div className="flex items-center">
                {/* <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </div>

                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <MenuItem key={option.name}>
                          {({ focus }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                focus ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </MenuItem>
                      ))}
                    </div>
                  </MenuItems>
                </Menu>

                <button
                  type="button"
                  className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                >
                  <span className="sr-only">View grid</span>
                  <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                </button> */}
                <Badge variant="dot" className="hidden">
                  <button
                    type="button"
                    className="p-2 text-[#1E1E1E] bg-white sm:ml-6 lg:hidden flex items-center rounded border border-[#E9E9E9] gap-x-1 font-[500]"
                    onClick={() => setMobileFiltersOpen(true)}
                  >
                    <VscSettings
                      className="h-4 w-5 rotate-90"
                      aria-hidden="true"
                    />
                    Filters
                    {/* <div class="absolute inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-gray-700 border-2 border-white rounded-full -top-1 -end-1 dark:border-gray-900"></div> */}
                  </button>
                </Badge>
              </div>
            </div>

            <div className="relative py-2 lg:hidden">
              <input
                onChange={(e) => {
                  dispatch(setSearchedText(e.target.value));
                }}
                type="search"
                name="searchText"
                id="searchText"
                placeholder="Search Style Number"
                value={searchedText}
                className="block w-full outline-none border border-neutral-300 bg-transparent py-2 pl-2 pr-2 text-base/6 ring-0 transition sm:placeholder:text-[16px] placeholder:text-[14px] placeholder:text-neutral-400"
              />
            </div>

            <section aria-labelledby="products-heading" className="pb-6 pt-0">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              {/* <button
                type="button"
                onClick={() => {
                  dispatch(clearSelectedCategory());
                  navigate(
                    `/category-selection?type=${selectedCategoryData?.[0]?.selectedType}`
                  );
                }}
                className="hidden lg:flex items-center outline-none gap-1 text-[#212121]"
              >
                <IoIosArrowBack /> Back
              </button> */}

              <div className="border-t border-b py-2 border-gray-100 hidden lg:flex">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
              </div>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 items-start">
                {/* Product grid */}
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 lg:col-span-3 lg:gap-x-8">
                  <StylesProductList />
                </div>

                {/* Filters */}
                <form className="hidden lg:block">
                  <div className="relative w-full mt-3">
                    <input
                      onChange={(e) => {
                        dispatch(setSearchedText(e.target.value));
                      }}
                      type="search"
                      name="searchText"
                      id="searchText"
                      placeholder="Search Style Number"
                      value={searchedText}
                      className="block w-full outline-none border border-neutral-300 bg-transparent py-2 pl-2 pr-2 text-base/6 ring-0 transition sm:placeholder:text-[16px] placeholder:text-[14px] placeholder:text-neutral-400"
                    />
                  </div>

                  {/* Desktop Categories Dropdown */}
                  <Disclosure
                    as="div"
                    key="categories"
                    className="border-b border-gray-200 py-6"
                    defaultOpen
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <DisclosureButton className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="text-[#121212] text-xl font-[500]">
                              Categories
                              <hr class="w-[50px] h-[3px] border-0 rounded bg-[#295268] mt-2"></hr>
                            </span>
                            <span className="ml-6 flex items-center">
                              <img
                                src={DownArrow}
                                className={`h-5 w-5 ${open && "rotate-180"}`}
                              />
                            </span>
                          </DisclosureButton>
                        </h3>
                        <DisclosurePanel className="pt-6">
                          <ul className="space-y-3">
                            {categoryList.map((category) => (
                              <li
                                key={category.stCategoryName}
                                className="border-b border-[#BABABA] border-dashed px-0 pb-3 pt-0"
                              >
                                <div
                                  onClick={() => {
                                    setSelectedCategory({
                                      id: category?.unCategoryIdentifier,
                                      name: category?.stCategoryName,
                                    });
                                    updateURLCategory(
                                      category?.stCategoryName?.toLowerCase()
                                    );
                                  }}
                                  className={`font-[500] text-sm ${
                                    selectedCategory?.id ===
                                    category?.unCategoryIdentifier
                                      ? "text-[#295268]"
                                      : "text-[#6A6A6A]"
                                  } cursor-pointer`}
                                >
                                  {category.stCategoryName} (
                                  {
                                    stockDesignCategories?.find(
                                      (i) =>
                                        i?.CategoryId ===
                                        category?.unCategoryIdentifier
                                    )?.totCount
                                  }
                                  )
                                </div>
                              </li>
                            ))}
                          </ul>
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>

                  {/* {filters.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-b border-gray-200 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <DisclosureButton className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </DisclosureButton>
                          </h3>
                          <DisclosurePanel className="pt-6">
                            <div className="space-y-4">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </DisclosurePanel>
                        </>
                      )}
                    </Disclosure>
                  ))} */}

                  <CustomizedJewelryFilter
                    selectedCategory={selectedCategory}
                  />
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
