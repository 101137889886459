import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSuppliers } from "../../Services/supplierService";

export const fetchSupplier = createAsyncThunk("supplier/fetchSupplier",
  async () => {
    const response = await getSuppliers()
    return response.data.data.accounts
  })

const initialState = {
  data: [],
  loading: false,
  error: null
}

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSupplier.pending, (state, action) => {
      state.loading = true
    })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      })
  }
})

export const getSupplierDataState = state => state.supplierData

export default supplierSlice.reducer