import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";
import "./Category.css";
import SearchIcon from "@mui/icons-material/Search";
import { transformCapitalize } from "../../Utils";

const Category = () => {
  const { data } = useSelector(getCategoryDataState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const handleNavigateCategory = (id, name) => {
    navigate(`/product`, { state: { category: { id, name } } });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    prevArrow: "",
    nextArrow: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="bg-[#1F2937] flex items-center justify-center lg:flex-row flex-col lg:mb-[60px] md:mb-[40px] mb-[30px] p-4 lg:gap-0 gap-[20px] max-w-full 2xl:max-w-[1224px] 2xl:px-4 px-4 mx-auto xl:mx-auto">
        <div className="xl:max-w-[1224px] w-full md:mb-[20px]">
          <h3 className="lg:text-[40px] md:text-[30px] text-[18px] lg:leading-[52px] md:leading-[40px] leading-[30px] font-[600] font-playfair text-[#fff] text-center">
            Traceable | Eco-Friendly | Versatile
          </h3>
          <div className="relative w-full mt-[0px] md:mt-[30px] font-playfair text-white text-center text-[11px] md:text-base leading-6 md:leading-8 tracking-wide">
            <p>
              Thanks to technology, lab-created diamonds has made diamond
              jewelry much more affordable, 1ct used to be a goal. But now it
              can be your starter. DTN produces only top quality lab-created
              diamonds and promises to deliver you the best quality at the best
              price. More choices, more affordable
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
