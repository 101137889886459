import React, { useEffect } from "react";
import Pagination from "./Pagination";
import { useState } from "react";
import Loader from "./common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiasmondRequestData,
  getDiamondRequestState,
} from "../../store/Slices/diamondRequestSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useRef } from "react";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import { Link, useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";

const DiamondRequestList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const { data, loading, error } = useSelector(getDiamondRequestState);
  const [viewData, setViewData] = useState(null);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchDiasmondRequestData());
  }, [dispatch]);

  const handleOpenModel = async (stock) => {
    const findData =
      data.length > 0 &&
      data.find((item) => item.unRequestIdentifier === stock);
    setViewData(findData);
    setOpen(true);
  };

  return (
    <div className="w-full inline-block align-middle px-6">
      <h2 className="sm:mb-4 mb-3 font-bold sm:text-[22px] text-[20px]">
        Diamonds Request
      </h2>

      <div className="flex items-center justify-between gap-[10px]">
        <Link
          to={"/admin/transactionMenu"}
          className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
        >
          <TiArrowBack className="text-[18px]" /> Back
        </Link>
      </div>

      <div className="sm:border rounded-lg bg-white sm:shadow mt-4 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 sm:inline-table hidden">
          <thead className="bg-[#e2e8f0]">
            <tr>
              <th
                scope="col"
                className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
              >
                StoneId
              </th>
              <th
                scope="col"
                className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
              >
                Name
              </th>
              <th
                scope="col"
                className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
              >
                Email
              </th>
              <th
                scope="col"
                className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
              >
                Contact
              </th>
              <th
                scope="col"
                className="p-3 text-xs font-bold text-center text-gray-500 uppercase "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {!loading &&
              !!currentData?.length &&
              currentData.map((order, index) => {
                return (
                  <tr key={index}>
                    <td className="p-3 text-sm font-medium text-gray-800 ">
                      {order.stStoneID}
                    </td>
                    <td className="p-3 text-sm text-gray-800 ">
                      {order.stName}
                    </td>
                    <td className="p-3 text-sm text-gray-800 ">
                      {order.stEmail}
                    </td>
                    <td className="p-3 text-sm text-gray-800 ">
                      {order.stContact || "-"}
                    </td>
                    <td className="p-3 text-sm flex justify-center items-center gap-2 font-medium text-right ">
                      <button
                        className=""
                        onClick={() =>
                          handleOpenModel(order.unRequestIdentifier)
                        }
                      >
                        View
                      </button>
                      <span>|</span>
                      <button className="disabled:text-gray-500" disabled>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="sm:hidden block">
          {!loading &&
            !!currentData?.length &&
            currentData.map((order, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-4 border rounded shadow-md mb-3 gap-2 p-3"
                >
                  <div className="sm:text-sm text-[12px] font-medium text-gray-800 col-span-2">
                    StoneId:{" "}
                    <span className="font-normal">{order.stStoneID}</span>
                  </div>
                  <div className="sm:text-sm text-[12px] font-medium text-gray-800 col-span-2"></div>
                  <div className="sm:text-sm text-[12px] font-medium text-gray-800 col-span-2">
                    Name: <span className="font-normal">{order.stName}</span>
                  </div>
                  <div className="sm:text-sm text-[12px] font-medium text-gray-800 col-span-2">
                    Contact:{" "}
                    <span className="font-normal">
                      {order.stContact || "-"}
                    </span>
                  </div>
                  <div className="sm:text-sm text-[12px] font-medium text-gray-800 col-span-4">
                    Email: <span className="font-normal">{order.stEmail}</span>
                  </div>
                  <div className="sm:text-sm text-[12px] flex justify-center items-center gap-2 font-medium text-right col-span-4">
                    <button
                      className=""
                      onClick={() => handleOpenModel(order.unRequestIdentifier)}
                    >
                      View
                    </button>
                    <span>|</span>
                    <button className="disabled:text-gray-500" disabled>
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        {!loading && !currentData?.length && (
          <div className="w-full h-52 flex justify-center items-center">
            <NoDataText text={"No Data found!"} />
          </div>
        )}
        {loading && (
          <div className="w-full h-52 flex justify-center items-center">
            <Loader />
          </div>
        )}
        <Pagination
          totalItems={data.length}
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          setActivePage={setActivePage}
        />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            setViewData(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="sm:flex block min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="">
                    <div className="text-center sm:mt-0 sm:text-left">
                      <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                        >
                          View Diamond Request
                        </Dialog.Title>
                        <button
                          type="button"
                          className="text-[20px] text-[#334155] font-[600] leading-6"
                          onClick={() => {
                            setOpen(false);
                            setViewData(null);
                          }}
                          ref={cancelButtonRef}
                        >
                          <MdOutlineClose />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-6 gap-4 sm:p-6 sm:pb-0 sm:pt-2 px-4 pt-5 pb-4">
                    <div className="col-span-6">
                      <label
                        htmlFor="stName"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stName"
                          id="stName"
                          disabled
                          value={viewData?.stName}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter your Name"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stEmailId"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="stEmailId"
                          id="stEmailId"
                          disabled
                          value={viewData?.stEmail}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Phone
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="stPhoneNo"
                          id="stPhoneNo"
                          disabled
                          value={viewData?.stContact}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Stock Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled
                          value={viewData?.stStoneID}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Cert Id
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          disabled
                          value={viewData?.stCertiID}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <label
                        htmlFor="stPhoneNo"
                        className="block text-sm font-medium leading-4 text-gray-900"
                      >
                        Message
                      </label>
                      <div className="mt-2">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="5"
                          disabled
                          maxLength={2000}
                          value={viewData?.stMessage}
                          className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          placeholder="Enter Your Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:px-6 px-4 pb-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setOpen(false);
                        setViewData(null);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default DiamondRequestList;
