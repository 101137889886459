import api from "./api";

export const postDesign = (data) => api("/api/Designs", "post", data)

export const postVeriasion = (data) => api("/api/DesignsVariation", "post", data)

export const DesignMetal = (data) => api("/api/DesignMetal", "post", data)

export const updateDesign = (data) => api("/api/Designs", "put", data)

export const addDesignAttributeValue = (data) => api("/api/DesignAttributeValues", "post", data)

export const deleteDesign = (id) => api(`/api/Designs/${id}`, "delete")

export const updateDesignVeriaation = (data) => api("/api/DesignsVariation", "put", data)

export const deleteDesignVeriation = (id) => api(`/api/DesignsVariation/${id}`, "delete")

export const getDesignVariationById = (id) => api(`/api/DesignsVariation/${id}`, "get")

export const getDesignById = (id) => api(`/api/Designs/${id}`, "get")

export const getDesignByCategoryId = (id) => api(`/api/DesignsByCategory/${id}`, "get")

export const getDesignByCategoryStyleId = (id) => api(`/api/DesignsByCategoryStyle/${id}`, "get")

export const getMetalById = (id) => api(`/api/DesignMetalByDesignId/${id}`, "get")

export const designsVariationSearch = (data) => api(`/api/DesignsVariationSearch`, "post", data)

export const getDesign = (params) => api(`/api/Designs`, "get", null, params)

export const DesignCatalog = (data) => api(`/api/Catalog`, "post", data)

export const GetDesignCatalog = (id) => api(`/api/Catalog/${id}`, "get")

export const GetDesignByVariation = (id) => api(`/api/DesignsByVariation/${id}`, "get")

export const getDesignAndStockByStockCode = (id) => api(`/api/DesignAndStock?DesignStockCode=${id}`, "get")

export const getDesignAndStockByStockName = (designName) => api(`/api/DesignAndStock?DesignName=${designName}`, "get")

export const designUpdateFlgById = (data) => api("/api/DesignUpdateFlgById", "put", data)

export const getDesignsListV2 = (data) => api("/api/DesignsListV2", "post", data)

export const getDesignsJStockListV2 = (data) => api("/api/JStockListV2", "post", data)