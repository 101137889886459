import React, { useState } from "react";
import AdminModal from "../common/AdminModal";
import { cancelOrderwithId } from "../../../Services/orderService";
import { toast } from "react-hot-toast";

const CancelOrder = ({ isOpen, setIsOpen, orderId, setOrderId }) => {
  const [marginVal, setMarginVal] = useState("");

  const handleSubmit = async () => {
    const res = await cancelOrderwithId(orderId, marginVal)
    if (res.status === 200) {
      toast.success(res.data.message)
      handleClose()
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setMarginVal("");
    setOrderId();
  };

  return (
    <AdminModal
      title={"Cancel Order"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <div>
        <textarea
          rows={4}
          name="remark"
          onChange={(e) => setMarginVal(e.target.value)}
          id="remark"
          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={""}
          placeholder="Remark..."
        />
      </div>
    </AdminModal>
  );
};

export default CancelOrder;
