import React from "react";
import Skeleton from "react-loading-skeleton";

const AgentSelectionListSkeleton = () => {
  return (
    <div className="bg-white lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4 mx-4">
      <div className="w-full">
        <div className="w-full grid grid-cols-12 gap-2">
          <div className="col-span-4">
            <div className="flex items-center gap-[17px]">
              <div className="sm:w-16 sm:h-16 w-14 h-14 rounded">
                <Skeleton height={"100%"} />
              </div>
              <div>
                <h2 className="text-[20px] font-[500]">
                  <Skeleton width={50} height={20} />
                </h2>
                <h6 className="text-[16px] text-[#424242]">
                  <Skeleton width={50} height={20} />
                </h6>
              </div>
            </div>
          </div>
          <div className="col-span-8 flex items-center gap-[20px] justify-end">
            <div className="flex items-center">
              <div className="col-span-1 text-center px-[30px]">
                <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                  <Skeleton width={50} height={20} />
                </h6>
                <h2 className="text-[16px] font-[500]">
                  <Skeleton width={50} height={20} />
                </h2>
              </div>
              <div className="col-span-1 text-center px-[30px]">
                <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                  <Skeleton width={50} height={20} />
                </h6>
                <h2 className="text-[16px] font-[500]">
                  <Skeleton width={50} height={20} />
                </h2>
              </div>
              <div className="col-span-1 text-center px-[30px]">
                <h6 className="text-[12px] text-[#424242] opacity-[0.6]">
                  <Skeleton width={50} height={20} />
                </h6>
                <h2 className="text-[16px] font-[500]">
                  <Skeleton width={50} height={20} />
                </h2>
              </div>
            </div>
            <div className="flex items-center gap-[10px]">
              <Skeleton width={50} height={20} />
              <Skeleton width={50} height={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="bg-white flex items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
    //   <div className="w-full">
    //     <div className="w-full grid grid-cols-8 gap-2">
    //       <div className="mb-2 col-span-1">
    //         <Skeleton height={"100%"} />
    //       </div>
    //       <div className="col-span-1 mb-2">
    //         <Skeleton height={"100%"} />
    //       </div>
    //       <div className="mb-2 col-span-1">
    //         <Skeleton height={"100%"} />
    //       </div>
    //       <div className="mb-2 col-span-1">
    //         <Skeleton height={"100%"} />
    //       </div>
    //       <div className="mb-2 col-span-2">
    //         <Skeleton height={"100%"} />
    //       </div>
    //       <div className="mb-2 col-span-2">
    //         <Skeleton height={"100%"} />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AgentSelectionListSkeleton;
