import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStoneClarityData,
  getStoneClarityState,
} from "../../../store/Slices/stoneClaritySlice";
import {
  createStoneClarity,
  updateStoneClarity,
} from "../../../Services/stoneClarityService";
import AdminModal from "../common/AdminModal";
import { useEffect } from "react";

const ClarityModal = ({ isOpen, setIsOpen, editId, setEditId = () => { } }) => {
  const [inputValue, setInputValue] = useState({
    stStoneClarityName: "",
    stStoneClarityAlias: "",
    flgIsActive: true,
  });
  const { data } = useSelector(getStoneClarityState);
  const dispatch = useDispatch();

  useEffect(() => {
    const editData = data.find(
      (item) => item.unStoneClarityIdentifier === editId
    );
    if (editData) {
      setInputValue({
        stStoneClarityName: editData.stStoneClarityName,
        stStoneClarityAlias: editData.stStoneClarityAlias,
        flgIsActive: editData.flgIsActive === "1" ? true : false,
      });
    }
  }, [editId, data]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setInputValue({
      stStoneClarityName: "",
      stStoneClarityAlias: "",
      flgIsActive: true,
    });
    setIsOpen(false)
    if (editId) {
      setEditId("")
    }
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stStoneClarityName: inputValue.stStoneClarityName,
        stStoneClarityAlias: inputValue.stStoneClarityAlias,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unStoneClarityIdentifier: editId,
      };
      const res = await updateStoneClarity(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneClarityData());
        handleClose()
      }
    } else {
      const payload = new FormData();
      payload.append("stStoneClarityName", inputValue.stStoneClarityName);
      payload.append("stStoneClarityAlias", inputValue.stStoneClarityAlias);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createStoneClarity(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchStoneClarityData());
      }
    }
  };

  return (
    <AdminModal
      title={editId ? "Update Clarity" : "Add Clarity"}
      onSubmit={() => {
        handleSubmit();
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="stStoneClarityName"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Stone Clarity Name
        </label>
        <div>
          <input
            type="text"
            name="stStoneClarityName"
            value={inputValue.stStoneClarityName}
            id="stStoneClarityName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Stone Clarity Name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="stStoneClarityAlias"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Stone Clarity Alias
        </label>
        <div>
          <input
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            name="stStoneClarityAlias"
            id="stStoneClarityAlias"
            value={inputValue.stStoneClarityAlias}
            onChange={handleChange}
            placeholder="Enter Stone Clarity Alias"
          />
        </div>
      </div>
      <div>
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="flgIsActive"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="flgIsActive"
            className=" block text-sm font-medium leading-6 text-gray-900"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal >
  );
};

export default ClarityModal;
