import React, { useState } from "react";
import Pagination from "./Pagination";
import { BiEdit, BiSearch, BiShow } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import { GoDiffAdded, GoKebabVertical } from "react-icons/go";
import { Fragment } from "react";
import { Listbox, Menu, Transition } from "@headlessui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import {
  fetchDesigns,
  fetchDesignsInProductPage,
  getDesignDataState,
} from "../../store/Slices/designSlice";
import { deleteDesign } from "../../Services/designService";
import { TiArrowBack } from "react-icons/ti";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";
import {
  fetchReadyToShip,
  getReadyToShipDataState,
} from "../../store/Slices/readyToShipSlice";
import {
  fetchPlaceOrderList,
  getPlaceOrderListDataState,
} from "../../store/Slices/calibratedSlice";
import { deleteReadyToShipDesign, updateStockFlgById } from "../../Services/readyToShipService";
import { deletePlaceOrderById } from "../../Services/calibratedService";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { getCategory } from "../../Services/categoryService";
import { BarsArrowUpIcon, UsersIcon } from '@heroicons/react/20/solid'
import { debounce } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

let recall = false;
const ReadyToShipProduct = () => {
  const dispatch = useDispatch();
  const { placeOrderData, placeOrderLoading, placeOrderError, placeOrderTotalCount } = useSelector(
    getPlaceOrderListDataState
  );
  const navigate = useNavigate();

  const { currentData, activePage, setActivePage, itemsPerPage } = usePagination({ data:placeOrderData, itemsPerPage: 10 });

  const [selected, setSelected] = useState();
  const [categoryStyleData, setCategoryStyleData] = useState([]);
  const [value, _value] = useState()

  useEffect(() => {
    dispatch(fetchPlaceOrderList());
  }, [dispatch]);

  const handleSetData = (val, name) => {
    const currentCategory =
      categoryStyleData?.length &&
      categoryStyleData?.find((itm) => itm.stCategoryName === val.stCategoryName);
    if (currentCategory) {
      setSelected(currentCategory);
    }
    const params = {
      CategoryID: currentCategory?.unCategoryIdentifier,
      Name: value ? value : null
    }
    dispatch(fetchPlaceOrderList(params))
  }

  const handleSearchBox = (e) => {
    _value(e.target.value)
    let params = {
      category_id: selected?.unCategoryIdentifier,
    }
    if (e.target.value.length > 0) {
      params['Name'] = e.target.value
    }

    dispatch(fetchPlaceOrderList(params));
  }

  const debouncedOnChange = debounce(handleSearchBox, 500);

  const handleEdit = (id) => {
    navigate(`/admin/addreadytoship/${id}`);
  };

  const handleView = (id) => {
    navigate(`/readytoshipdetail/${id}`);
  }

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: `That you want to delete this Order?`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      // const imgArr = [product.DefImageURL, product.FocusImageURL]
      // product.images.length > 0 && product.images.forEach((item) => imgArr.push(item.ImageURL))
      const res = await deletePlaceOrderById(id);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchPlaceOrderList());
      }
    }
  };

  const handleStockFlg = async(flag, flagType, StockID) => {
    const res = await updateStockFlgById({ StockID, [flagType]: flag ? 1 : 0 });
    if (res.status === 200) {
      toast.success(res.data.message);
    }
  };

  // useEffect(() => {
  //   const categoryStyleData = async () => {
  //     const res = await getCategory();
  //     setCategoryStyleData(res.data.data.categories);
  //   };
  //   categoryStyleData();
  // }, []);

  return (
    <div className="flex flex-col flex-1 w-full z-0 h-[90vh] bg-gray-100">
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="w-auto mr-1 lg:mr-0">
            <div className="mt-2 flex flex-wrap rounded-md shadow-sm">
              <Link
                to={"/admin/transactionMenu"}
                className="rounded-l-md  text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50 "
              >
                <TiArrowBack className="text-[18px]" /> Back
              </Link>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  // name="email"
                  // id="email"
                  onChange={debouncedOnChange}
                  className="block w-full rounded-none border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search Text here..."
                />
              </div>
              {/* <Listbox
                value={selected}
                onChange={(val) => {
                  handleSetData(val, "category");
                }}
              >
                <div className="relative w-[155px]">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default hover:bg-gray-50 py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected ? selected?.stCategoryName : "Category"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {categoryStyleData?.length &&
                        categoryStyleData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                ? "bg-amber-100 text-amber-900"
                                : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? "font-medium" : "font-normal"
                                    }`}
                                >
                                  {person.stCategoryName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FiFilter className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Filter
              </button> */}
              {/* <Link
                to={"/admin/addreadytoship"}
                className=" relative text-[14px] border-[1px] rounded-r-md border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50 "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </Link> */}
            </div>
          </div>
          <div className="sm:rounded-lg relative mt-4 rounded shadow-md">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-2 py-4 w-[50px]">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-4 w-[50px]">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-4 w-[50px]">
                      Email
                    </th>
                    <th scope="col" className="px-2 py-4 w-[100px]">
                      Phone
                    </th>
                    <th scope="col" className="px-2 py-4 w-[90px]">
                      Remark
                    </th>
                    <th scope="col" className="px-2 py-4 w-[50px]">
                      Status
                    </th>
                    <th scope="col" className="px-2 py-4 w-[120px]">
                      Status Remark
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-4 w-[60px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!placeOrderLoading && currentData?.length
                    ? currentData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                        >
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.ODate}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.OName}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.OEmail}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.OPhone}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.ORemark1}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.OStatus}
                          </th>
                          <th
                            scope="row"
                            className="px-2 py-2 w-[50px] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.OStatusRemark}
                          </th>
                         
                          <td className="px-2 py-2">
                            <div className="flex items-center justify-center gap-[10px]">
                              {/* <button
                                onClick={handleEdit.bind(null, item.StockID)}
                                type="button"
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button> */}
                              <button
                                type="button"
                                onClick={handleDelete.bind(
                                  null,
                                  item.OId,
                                )}
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              {/* <button
                                onClick={() => handleView(item.StockID)}
                                type="button"
                              >
                                <BiShow className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    : !placeOrderLoading && (
                      <tr>
                        <td className="w-full" colSpan={13}>
                          <NoDataText text={"No Product found!"} />
                        </td>
                      </tr>
                    )}
                  {placeOrderLoading && (
                    <tr>
                      <td colSpan={13} className="h-60 w-full">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={placeOrderData ? placeOrderData?.length : 0}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReadyToShipProduct;
