import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineWhatsApp,
} from "react-icons/ai";

const MainFooter = () => {
  const navigation = {
    main: [
      { name: "About Us", href: "#" },
      { name: "Contact Us", href: "#" },
      { name: "Policy", href: "#" },
      { name: "Terms & Conditions", href: "#" },
    ],
    social: [
      {
        name: "Mail",
        href: "mailto:info@dtndiamonds.com",
        icon: <AiOutlineMail className=" sm:text-[26px] text-[22px]" />,
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/dtn_createddiamonds/",
        icon: <AiOutlineInstagram className=" sm:text-[26px] text-[22px]" />,
      },
      {
        name: "Whatsapp",
        href: "https://wa.me/+85261951229",
        icon: <AiOutlineWhatsApp className=" sm:text-[26px] text-[22px]" />,
      },
    ],
  };

  return (
    <footer className="landing__footer bg-transparent z-50 relative sm:pt-20 pt-14 pb-4">
      <div className="bg-[#024C79] mx-auto lg:max-w-[848px] md:max-w-[648px] sm:max-w-[500px] max-w-[90%] w-full sm:h-[115px] h-[85px] absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="flex items-center w-full h-full justify-center sm:flex-row flex-col space-x-6">
          <form className="flex sm:max-w-md">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              className="md:w-[296px] sm:w-[196px] w-full appearance-none border border-white bg-transparent px-3 sm:h-11 h-10 text-[16px] font-Josefin text-white  ring-gray-300 placeholder:text-white outline-none sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <div className="sm:flex-shrink-0">
              <button
                type="submit"
                className="flex lg:w-[141px] md:w-[100px] w-[80px] items-center justify-center bg-white px-3 sm:h-11 h-10 text-[16px] font-semibold text-black font-Josefin duration-300 hover:scale-105"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center gap-3">
        {navigation.social.map((item) => (
          <a
            key={item.name}
            rel="noreferrer"
            href={item.href}
            className="mainfooter-media overflow-hidden relative sm:w-11 sm:h-11 w-10 h-10 rounded-full border border-black hover:border-[#024C79] hover:text-[#fff] flex justify-center items-center"
            target="_blank"
          >
            <span className="sr-only">{item.name}</span>
            {item.icon}
          </a>
        ))}
      </div>
    </footer>
  );
};

export default MainFooter;
