import React, { useCallback, useEffect } from "react";
import AdminModal from "../common/AdminModal";
import { Slider as RangeSlider } from "@mui/material";
import { useState } from "react";
import shap1 from "../../../assets/images/shap1.png";
import shap2 from "../../../assets/images/shap2.png";
import shap3 from "../../../assets/images/shap3.png";
import shap4 from "../../../assets/images/shap4.png";
import shap5 from "../../../assets/images/shap5.png";
import shap6 from "../../../assets/images/shap6.png";
import shap7 from "../../../assets/images/shap7.png";
import shap8 from "../../../assets/images/shap8.jpg";
import Slider from "react-slick";
import {
  getDiamondStock,
  getDiamondStockFilterParameter,
} from "../../../Services/diamondService";
import { useRef } from "react";
import Loader from "../common/Loader";
import useApi from "../../../Hooks/use-Api";
import { useMemo } from "react";
import { sortByID } from "../../../Utils";

function valuetext(value) {
  return `${value}°C`;
}

const BrowseDiamondData = ({ isOpen, setIsOpen, setcenterStone }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const [stockData, setStockData] = useState([]);
  const [value, setValue] = React.useState([0, 8]);
  const [price, setPrice] = React.useState([0, 10000]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  useEffect(() => {
    if (filterParameters) {
      setPrice([
        +filterParameters.amount[0].ID,
        +filterParameters.amount[0].Name,
      ]);
      setValue([
        Number(filterParameters.carat[0].ID).toFixed(2),
        Number(filterParameters.carat[0].Name).toFixed(2),
      ]);
    }
  }, [filterParameters]);

  const DiamondStock = useCallback(async () => {
    const Data = new FormData();
    if (filterValue) {
      Data.append("ShapeId", filterValue?.shape?.ID || "");
      Data.append("ColorId", filterValue?.color?.ID || "");
      Data.append("ClarityId", filterValue?.clarity?.ID || "");
      Data.append("Cut", filterValue.cut?.ID || "");
      Data.append("FromCarat", filterValue?.fromCarat || "");
      Data.append(
        "ToCarat",
        filterValue?.fromCarat ? filterValue?.toCarat : ""
      );
      Data.append("fromPrice", filterValue?.fromPrice || "");
      Data.append(
        "toPrice",
        filterValue?.fromPrice ? filterValue?.toPrice : ""
      );
    }
    Data.append("inPageNo", pageNumber);
    Data.append("inPageSize", 10);

    try {
      setLoading(true);
      const response = await getDiamondStock(Data);

      if (response.data.success) {
        setHasMore(!(response.data.data.stock?.length < 10));
        setStockData((prev) => [...prev, ...response.data.data.stock]);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  }, [filterValue, pageNumber]);

  useEffect(() => {
    DiamondStock();
  }, [DiamondStock]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSliderVal = (name, val) => {
    setStockData([]);
    setPageNumber(1);
    if (name === "carat") {
      // setValue(val)
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: val[0],
        toCarat: val[1],
      }));
    } else {
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: val[0],
        toPrice: val[1],
      }));
      // setPrice(val)
    }
  };

  const handleChangePrice = (event, newValue) => {
    setPrice(newValue);
  };

  const handleSelectRow = (data) => {
    setcenterStone((prev) =>
      prev.find((item) => item.STOCKNO === data.STOCKNO)
        ? prev
        : [
          ...prev,
          {
            ...data,
            agentMargin: "",
            agentTotal: "",
            total: (+data.Amount / +data.CARAT).toFixed(2),
          },
        ]
    );
    setIsOpen(false);
  };

  const handleChangeFilter = (name, val) => {
    setStockData([]);
    setPageNumber(1);
    setFilterValue((prev) => ({
      ...prev,
      [name]: prev?.[name]?.ID === val.ID ? {} : val,
    }));
  };

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  const shapeData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.shape, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const cutData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.cut, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const colorData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.color, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const clarityData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.clarity, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  return (
    <AdminModal
      title={"Browse Diamond"}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      showButton={false}
      showActionButtons={false}
      width={" sm:max-w-[95vw] max-h-[90vh]"}
      childClass="overflow-auto"
    >
      <div className="xl:max-w-[90%] max-w-auto xl:px-0 px-4 mx-auto browseDiamond">
        <div className="flex items-start lg:flex-row flex-col lg:gap-10 gap-8">
          <div className="lg:w-[30%] w-full lg:text-left text-center">
            <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px]">
              Shape:{" "}
              <span className="text-[14px] text-gray-500 font-[400]">
                {filterValue?.shape?.Name}
              </span>
            </h3>
            {!!shapeData?.length && (
              <div>
                {shapeData?.length >= 7 ? (
                  <Slider className="mb-[20px]" {...settings}>
                    {shapeData?.map((itm, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={handleChangeFilter.bind(null, "shape", itm)}
                          className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${filterValue?.shape?.Name === itm.Name
                              ? "bg-[#f1f5f9]"
                              : ""
                            }`}
                        >
                          <img
                            src={shapeImages(itm.Name)}
                            alt="shap1"
                            className="m-auto h-[30px]"
                          />
                        </button>
                      );
                    })}
                  </Slider>
                ) : (
                  <div>
                    {shapeData.map((itm, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={handleChangeFilter.bind(
                            null,
                            "shape",
                            itm.Name
                          )}
                          className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${filterValue?.shape?.Name === itm.Name
                              ? "bg-[#f1f5f9]"
                              : ""
                            }`}
                        >
                          <img
                            src={shapeImages(itm.Name)}
                            alt="shap1"
                            className="m-auto h-[30px]"
                          />
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Carat
              </h3>
              <div className="w-3/4">
                <div className="flex items-center justify-between">
                  <span className="text-[14px] font-Josefin">{value[0]}ct</span>
                  <span className="text-[14px] font-Josefin">{value[1]}ct</span>
                </div>
                <RangeSlider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                  onChangeCommitted={(e, val) =>
                    handleChangeSliderVal("carat", val)
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  step={0.01}
                  max={+filterParameters?.carat?.[0]?.Name || 8}
                  min={+filterParameters?.carat?.[0]?.ID || 0}
                  sx={{
                    "& .MuiSlider-thumb": {
                      color: "#475569",
                    },
                    "& .MuiSlider-track": {
                      color: "#475569",
                    },
                    "& .MuiSlider-rail": {
                      color: "#acc4e4",
                    },
                    "& .MuiSlider-active": {
                      color: "green",
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex items-center lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Price
              </h3>
              <div className="w-3/4">
                <div className="flex items-center justify-between">
                  <span className="text-[14px] font-Josefin">${price[0]}</span>
                  <span className="text-[14px] font-Josefin">${price[1]}</span>
                </div>
                <RangeSlider
                  getAriaLabel={() => "Temperature range"}
                  value={price}
                  onChange={handleChangePrice}
                  onChangeCommitted={(e, val) =>
                    handleChangeSliderVal("price", val)
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={+filterParameters?.amount?.[0]?.ID || 0}
                  max={filterParameters?.amount?.[0]?.Name || 10000}
                  sx={{
                    "& .MuiSlider-thumb": {
                      color: "#475569",
                    },
                    "& .MuiSlider-track": {
                      color: "#475569",
                    },
                    "& .MuiSlider-rail": {
                      color: "#acc4e4",
                    },
                    "& .MuiSlider-active": {
                      color: "green",
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex items-center lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Cut
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {cutData.map((cut, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "cut", cut)}
                      className={`font-openSans text-xs outline-none px-3 py-1 rounded border-2 sm:hover:border-gray-500 ${filterValue?.cut?.Name === cut.Name
                          ? "border-gray-500"
                          : "border-gray-300"
                        }`}
                    >
                      {cut.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Clarity
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {clarityData.map((clarity, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(
                        null,
                        "clarity",
                        clarity
                      )}
                      className={` font-openSans text-xs outline-none px-3 py-1 rounded border-2 sm:hover:border-gray-500 hover:border-2 ${filterValue?.clarity?.ID === clarity.ID
                          ? "border-gray-500"
                          : "border-gray-300"
                        }`}
                    >
                      {clarity.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Colour
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {colorData.map((color, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "color", color)}
                      className={` font-openSans text-xs outline-none px-3 py-1 rounded border-2 sm:hover:border-gray-500 hover:border-2 ${filterValue?.color?.ID === color.ID
                          ? "border-gray-500"
                          : "border-gray-300"
                        }`}
                    >
                      {color.Name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="lg:w-[70%] w-full diamondTable">
            <div className="relative h-[90%] overflow-y-auto scrollbar-hide">
              <table className="w-full">
                <thead className="sticky top-0">
                  <tr className="bg-gray-200">
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Shape
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Carat
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Color
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Clarity
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      cut
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      price
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  {!!stockData?.length &&
                    stockData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          ref={
                            index + 1 === stockData?.length
                              ? lastDiamondElementRef
                              : dummyRef
                          }
                          onClick={handleSelectRow.bind(null, item)}
                          className=" cursor-pointer hover:bg-slate-300  "
                        >
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            {item.SHAPE}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            {item.CARAT}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            {item.COLOUR}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            {item.CLARITY}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            {item.CUTPOLISHSYM}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            ${item.Price || 0}
                          </td>
                          <td className="md:py-3 md:px-4 py-2 px-1 ">
                            ${item.Amount || 0}
                          </td>
                        </tr>
                      );
                    })}
                  {loading && (
                    <tr>
                      <td colSpan={7}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {!loading && !!!stockData?.length && (
                <div className="flex justify-center items-center w-full h-40">
                  <span className="text-gray-500 italic font-[600]">
                    No data
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminModal>
  );
};

export default BrowseDiamondData;
