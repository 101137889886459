import React, { useEffect, useState } from "react";
import wichlist from "../../assets/images/wichlist.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";
import diamond from "../../assets/images/diamond.png";
import Calendar from "../../assets/images/Calendar.png";
import truck from "../../assets/images/truck.png";
import warranty from "../../assets/images/warranty.png";
import PDFImage from "../../assets/images/icons/pdf_primary.png";
import WhatsappImage from "../../assets/images/icons/whatsapp_primary.png";
import LinkImage from "../../assets/images/icons/link_primary.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../store/Slices/cartSlice";
import { toast } from "react-hot-toast";
import {
  fetchAttributeValueData,
  getAttributeValueState,
} from "../../store/Slices/attributeValueSlice";
import { designsVariationSearch } from "../../Services/designService";
import {
  getSelectedDiamondState,
  removeDiamond,
  selectDiamond,
} from "../../store/Slices/diamondSelectSlice";
import { useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { RiVideoLine } from "react-icons/ri";
import { CgPlayButtonO } from "react-icons/cg";
import { MdContentCopy, MdOutlineFileDownload, Md360 } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import VideoIcon from "./Common/VideoIcon";
import jsPDF from "jspdf";
import "jspdf-autotable";

const sizeArray = [4, 4.5, 5, 6, 6.5, 7, 7.5, 8];

const ProductDetails = ({ product }) => {
  const [data, setData] = useState(null);
  const [metalPurity, setMetalPurity] = useState(null);
  const [centerStoneRange, setCenterStoneRange] = useState(null);
  const [mainImage, setMainImage] = useState(product?.stDefImageURL);
  const [size, setSize] = useState(sizeArray[0]);
  const [showMore, setShowMore] = useState(false);
  const [showEngraving, setShowEngraving] = useState(false);
  const [addEngraving, setAddEngraving] = useState("");
  const [engraving, setEngraving] = useState("");
  const [selectedVariation, setSelectedVariation] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const { data: selectedDiamond } = useSelector(getSelectedDiamondState);

  const downloadPDF = () => {
    const doc = new jsPDF("l", "mm", "a5");

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageBackgroundColor = "#FDFFFB"; // Set your desired page background color here

    // Set page background color
    doc.setFillColor(pageBackgroundColor);
    doc.rect(0, 0, pageWidth, pageHeight, "F");

    // Add border to the entire page
    doc.setDrawColor("gray"); // Border color (black in this case)
    doc.setLineWidth(0.3); // Border width
    doc.rect(
      2,
      2,
      doc.internal.pageSize.width - 4,
      doc.internal.pageSize.height - 4,
      "S"
    ); // 'S' option for stroke

    // Set text properties
    doc.setTextColor("#707070");
    doc.setFontSize(10);

    // Set text to be centered in the row
    const categoryTitle = "Customized Jewelry";
    const categoryTitleWidth =
      (doc.getStringUnitWidth(categoryTitle) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textX = (pageWidth - categoryTitleWidth) / 2;

    // Add text to the PDF document
    doc.text(textX, 7, categoryTitle);

    doc.setTextColor("#1c1c1c");
    doc.setFontSize(16);

    // Set text to be centered in the row
    const headerLable = "DTN Premium Quality LAB-GROWN DIAMONDS";
    const headerLableWidth =
      (doc.getStringUnitWidth(headerLable) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headerLableWidthX = (pageWidth - headerLableWidth) / 2;

    // Add a header to the PDF
    doc.text(headerLableWidthX, 18, headerLable);

    // Add a link to Google in the PDF
    doc.setFontSize(10);

    let rowIndex = 0;

    product?.designImages?.map((item, i) => {
      if (!item?.stImageURL?.endsWith(".mp4") && i < 4) {
        if (i % 2 === 0) {
          rowIndex++;
        }

        doc.addImage(
          item?.stImageURL,
          "PNG",
          i % 2 === 0 ? 5 : 47,
          rowIndex === 1 ? 30 : 72 * (rowIndex - 1),
          40,
          40
        );
      }
    });

    let videoURL = product?.designImages?.find((item) =>
      item?.stImageURL?.endsWith(".mp4")
    )?.stImageURL;

    if (videoURL) {
      doc.textWithLink("Click to view Video", 5, 118.5, {
        url: videoURL,
      });
      doc.text("_______________", 5, 119);
    }

    const columns = ["", ""];

    // Define the data for the auto-table

    const bodydata = [
      [
        { content: "Design", styles: { fontStyle: "bold" } },
        product?.stDesignName || "N/A",
      ],
      [
        { content: "Design Code", styles: { fontStyle: "bold" } },
        product?.stDesignAlias || "N/A",
      ],
      [
        { content: "Center Stone Shape", styles: { fontStyle: "bold" } },
        product?.designAttributeValue?.find(
          (item) => item?.stAttributeName === "Center Stone Shape"
        )?.stAttributeValueName || "N/A",
      ],
      [
        { content: "Center Stone Range", styles: { fontStyle: "bold" } },
        product?.designMainStone?.[0]?.stAttributeValueName || "N/A",
      ],
      [
        { content: "Metal Purity", styles: { fontStyle: "bold" } },
        product?.designAttributeValue?.find(
          (item) => item?.stAttributeName === "Select Metal Purity"
        )?.stAttributeValueName || "N/A",
      ],
      [
        { content: "Category", styles: { fontStyle: "bold" } },
        product?.category || "N/A",
      ],
    ];

    // Define alternative row colors
    const rowColors = {
      odd: [245, 245, 245], // Light gray color for odd rows
      even: [255, 255, 255], // White color for even rows
    };

    // Define a function to apply row colors based on the row index
    const applyRowColors = (dataList) => {
      dataList.forEach((row, index) => {
        if (index % 2 === 0) {
          // Even rows
          row.styles.fillColor = rowColors.even;
        } else {
          // Odd rows
          row.styles.fillColor = rowColors.odd;
        }
      });
    };

    doc.autoTable({
      startY: 30,
      margin: { left: 90 },
      //  head: [columns],
      body: bodydata,
      // didDrawCell: applyRowColors,
      theme: "plain", // Optional: 'striped', 'plain', 'grid' (default)
      styles: { lineWidth: 0.2 }, // Set border width
      columnStyles: {
        0: { columnWidth: 54, fillColor: "#FAFAFA" },
        1: { columnWidth: 60 },
      },
    });

    doc.save(`Customized Jewelry ${product?.stDesignAlias || ""}`);
  };

  useEffect(() => {
    setSelectedVariation(null);
  }, [location]);
  // 98c2301f-8dad-4b0a-8eac-d15cedf75097

  useEffect(() => {
    if (product?.designAttributeValue) {
      dispatch(fetchAttributeValueData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (state) {
      setData(state.attrData);
    }
  }, [state]);

  const images = useMemo(() => {
    if (product) {
      const productImage =
        product.designImages.length > 0
          ? product.designImages?.map((d) => {
              return {
                id: d.inSortOrder,
                img: d.stImageURL,
              };
            })
          : [];
      const sortData = productImage.sort((a, b) => a.id - b.id);
      if (selectedVariation) {
        return [...selectedVariation.design_variation_images, ...sortData];
      } else {
        return sortData;
      }
    }
    return [];
  }, [selectedVariation, product]);

  const getData = useCallback(() => {
    if (product?.designAttribute?.length) {
      product?.designAttribute?.forEach((attr) => {
        const attrData = product.designAttributeValue.filter(
          (attrVal) => attrVal.unAttributeId === attr.unAttributeId
        );
        if (attrData?.length && !state?.attrData) {
          setData((prev) => ({
            ...prev,
            [attr?.stAttributeName]: {
              name: attrData?.[0]?.stAttributeValueName,
              id: attrData?.[0]?.unAttributeValueId,
            },
          }));
        }
      });
    }

    if (product?.designMetalTone?.length) {
      setMetalPurity({
        name: product?.designMetalTone?.[0]?.stAttributeValueName,
        id: product?.designMetalTone?.[0]?.unAttributeValueIdentifier,
        dcPrice: product?.designMetalTone?.[0]?.dcPrice,
      });
    }

    if (product?.designMainStone?.length) {
      setCenterStoneRange({
        name: product?.designMainStone?.[0]?.stAttributeValueName,
        id: product?.designMainStone?.[0]?.unAttributeValueIdentifier,
        dcFromCarat: product?.designMainStone?.[0]?.dcFromCarat,
        dcToCarat: product?.designMainStone?.[0]?.dcToCarat,
      });
    }

    if (Object.keys(state?.metalPurity || {})?.length)
      setMetalPurity(state?.metalPurity);
  }, [product, state]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (images?.length) {
      setMainImage(images?.[0]);
    }
  }, [images]);

  useEffect(() => {
    if (data) {
      variationSearch();
    }
  }, [data]);

  const variationSearch = async () => {
    try {
      const payload = new FormData();
      const idsArr = Object.values(data).map((item) => item?.id);
      payload.append("unDesignId", id);
      payload.append("stVariationKey", idsArr.join());
      const response = await designsVariationSearch(payload);
      if (response.data.success) {
        setSelectedVariation(response.data.data);
      }
    } catch (e) {}
  };

  const isSelectedDiamond = useMemo(() => {
    if (selectedDiamond?.length) {
      return (
        !!selectedDiamond.find((item) => item?.productId === id) &&
        selectedDiamond?.length === +product?.inMainStonesPcs
      );
    }
    return false;
  }, [selectedDiamond, id, product]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const productImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productMobileImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
  };

  const handleChangeShape = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetal = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetalPurity = (value, valId, dcPrice) => {
    setMetalPurity({ name: value, id: valId, dcPrice });
  };

  const handleCenterStoneRange = (item) => {
    setCenterStoneRange({
      name: item?.stAttributeValueName,
      id: item?.unAttributeValueIdentifier,
      dcFromCarat: item?.dcFromCarat,
      dcToCarat: item.dcToCarat,
    });
  };

  const handleAddToCart = () => {
    if (isSelectedDiamond || !product?.inMainStonesPcs) {
      const cartData = {
        product: {
          id: id,
          title: product?.stDesignName,
          price: productPrice,
          image: product?.stFocusImageURL,
          engraving: addEngraving,
          size,
          attributes: data,
          details: product?.details,
          metal: product?.metal,
          DesignVariationId:
            selectedVariation?.unDesignVariationIdentifier || "",
          centerStoneRange,
        },
        diamond: selectedDiamond.map((dmnd) => {
          return {
            ...dmnd,
            title: "Diamond",
            id: dmnd?.STOCKNO,
            carat: dmnd?.CARAT,
            price: dmnd?.Amount || 0,
            shape: dmnd?.SHAPE,
            image: dmnd?.stImageUrl,
          };
        }),
        total:
          Number(productPrice) +
          Number(
            selectedDiamond.reduce(
              (acc, cur) => acc + parseInt(cur.Amount),
              0
            ) || 0
          ) +
          Number(metalPurity?.dcPrice || 0) * Number(product?.dcNtWt || 1),
      };
      dispatch(openCart(true));
      dispatch(addToCart(cartData));
      toast.success("Successfully Added to Cart.");
    } else {
      navigate("/diamondlist", {
        state: {
          product: id,
          attrData: data,
          productData: product,
          centerStoneRange,
          metalPurity,
          prevPath: state?.prevPath,
        },
      });
    }
  };

  const handleRemoveDiamond = (stockId) => {
    const filterData =
      selectedDiamond.length > 0 &&
      selectedDiamond.filter((item) => item.StoneNo !== stockId);
    dispatch(removeDiamond(filterData));
  };

  const productPrice = useMemo(() => {
    if (selectedVariation) {
      return (
        parseInt(selectedVariation?.dcSalePrice) +
          parseInt(selectedVariation?.dcVariationPrice) ||
        parseInt(product?.dcSalePrice)
      );
    }
    return parseInt(product?.dcSalePrice) || 0;
  }, [selectedVariation, product]);

  const productDescription = useMemo(() => {
    return product?.Description1 || "";
  }, [product]);

  const variationDescription = useMemo(() => {
    if (selectedVariation?.stDescription) {
      return selectedVariation?.stDescription;
    }
  }, [selectedVariation]);

  const stonePrice = useMemo(() => {
    if (selectedDiamond?.length && isSelectedDiamond) {
      return selectedDiamond.reduce(
        (acc, cur) => acc + parseInt(cur.Amount),
        0
      );
    }
    return 0;
  }, [selectedDiamond, isSelectedDiamond]);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  return (
    <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-[20px] mx-auto mt-4">
      <div className="flex items-start lg:flex-row flex-col mb-[21px] grid sm:grid-cols-12 gap-[42px]">
        <div className="lg:col-span-5 col-span-full xl:w-[495px] lg:w-[410px] w-full lg:max-h-[800px] max-h-full lg:h-full h-auto sideImages">
          {/* {product && (
            <GlassMagnifier
              imageSrc={mainImage}
              imageAlt="Example"
              className="image"
              magnifierSize="35%"
            />
          )} */}

          {(
            typeof mainImage === "object"
              ? mainImage?.img?.endsWith("mp4") ||
                mainImage?.img?.endsWith("mov")
              : mainImage?.endsWith("mp4") || mainImage?.endsWith("mov")
          ) ? (
            <video
              src={typeof mainImage === "object" ? mainImage.img : mainImage}
              alt="Product"
              className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 border-[#e2e8f0]"
              autoPlay
              muted
              loop
            ></video>
          ) : (
            <img
              src={typeof mainImage === "object" ? mainImage.img : mainImage}
              alt="Product"
              className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 border-[#e2e8f0]"
            />
          )}
          {/* <img
            src={typeof mainImage === "object" ? mainImage.img : mainImage}
            alt="Product"
            className="m-auto w-full h-full object-cover lg:block hidden bannerImg border-0 border-[#e2e8f0]"
          /> */}
          <div className="my-4 w-full bottomImg p-3 pt-0 mt-2">
            {!!images?.length && (
              <Slider
                {...productImageSettings}
                className="details_page max-w-[100%]"
              >
                {images.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                    >
                      {(
                        typeof item === "object"
                          ? item.img.endsWith("mp4") || item.img.endsWith("mov")
                          : item.endsWith("mp4") || item.endsWith("mov")
                      ) ? (
                        // <CgPlayButtonO className="text-[20px]  w-[30px] h-[30px] object-cover border-1 border-[#e2e8f0]" onClick={() => setMainImage(item)} />
                        // <VideoIcon onClick={() => setMainImage(item)} />
                        <div className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50">
                          <CgPlayButtonO
                            className="text-[20px]  w-[30px] h-[30px] object-cover border-1 border-[#e2e8f0]"
                            onClick={() => setMainImage(item)}
                          />
                        </div>
                      ) : (
                        <img
                          onClick={() => setMainImage(item)}
                          src={typeof item === "object" ? item.img : item}
                          alt="Product"
                          className=" w-full h-full object-cover border-1 border-[#e2e8f0]"
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
          <div className="lg:hidden block col-span-full">
            <Slider
              {...productMobileImageSettings}
              className="details_page max-w-[100%] w-[325px] h-[338px] mx-auto flex items-center shadow-lg"
            >
              {images.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="details__image max-h-[330px] overflow-hidden"
                  >
                    {(
                      typeof item === "object"
                        ? item?.img?.endsWith("mp4") ||
                          item?.img?.endsWith("mov")
                        : item?.endsWith("mp4") || item?.endsWith("mov")
                    ) ? (
                      <video
                        src={typeof item === "object" ? item.img : item}
                        alt="Product"
                        className="m-auto w-full h-full object-cover bannerImg border-0 border-[#e2e8f0]"
                        autoPlay
                        muted
                        loop
                      ></video>
                    ) : (
                      <img
                        src={typeof item === "object" ? item.img : item}
                        alt="Product"
                        className="h-full w-full object-cover object-center sm:border border-0"
                      />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          {/* <Tab.Group as="div" className="sm:flex flex-col-reverse hidden">
            <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none">
              <Tab.List className={`grid sliderGrid gap-6`}>
                <Slider
                  {...productImageSettings}
                  className="details_page max-w-[100%]"
                >
                  {images.map((image) => (
                    <Tab
                      key={image.id}
                      className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                    >
                      {({ selected }) => (
                        <>
                          <span className="sr-only">{image.name}</span>
                          <span className="absolute inset-0 overflow-hidden rounded-md">
                            <img
                              src={image}
                              alt=""
                              className="h-full w-full object-cover object-center"
                            />
                          </span>
                          <span
                            className={classNames(
                              selected ? "ring-[#64748b]" : "ring-[#e2e8f0]",
                              "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2 h-[100px] outline-none"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Slider>
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full ring-1 ring-[#e2e8f0] rounded">
              {images.map((image) => (
                <Tab.Panel key={image}>
                  <img
                    src={image}
                    alt={image}
                    className="sm:h-[470px] h-full w-full sm:w-[580px] object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group> */}

          {/* <div className="sm:hidden block ">
            <Slider
              {...productImageSettings}
              className="details_page max-w-[100%] w-[325px] h-[338px] mx-auto flex items-center shadow-lg"
            >
              {images.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="details__image max-h-[330px] overflow-hidden"
                  >
                    <img
                      src={item}
                      alt="Product"
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </div>
                );
              })}
            </Slider>
          </div> */}
        </div>
        <div className="lg:col-span-7 col-span-full w-full">
          <div className="flex items-start justify-between mb-[6px]">
            <h2 className="md:text-[30px] text-[24px] lg:leading-[43px] md:leading-[38px] leading-[32px] font-[400] text-[#000] font-playfair">
              {product?.stDesignName}
            </h2>
            {/* <button type="button" className="p-3 sm:block hidden">
              <img src={wichlist} alt="wichlist" />
            </button> */}
          </div>

          <p className="lg:text-[18px] text-[14px] lg:leading-[24px] leading-[20px] py-1 pt-0 text-[#6A6A6A] font-normal">
            {product?.stDesignAlias}
          </p>

          <h3 className="lg:text-[25px] text-[18px] lg:leading-[36px] leading-[30px] text-[#295268] font-[700] my-3 flex items-center gap-2">
            US $
            {(
              productPrice +
              stonePrice +
              Number(metalPurity?.dcPrice || 0) * Number(product?.dcNtWt || 1)
            ).toFixed(2)}
            {selectedDiamond.length > 0 ? (
              <span className="text-[16px] font-[400] text-[#999999]">
                (Including Center Stone)
              </span>
            ) : (
              <span className="text-[16px] font-[400] text-[#999999]">
                (Setting Price)
              </span>
            )}
          </h3>

          <p className="lg:text-[16px] xl:text-[16px] text-[12px] lg:leading-[24px] leading-[20px] py-1 text-[#6A6A6A] font-normal">
            {product?.stRemark1}
          </p>
          {/* <p
            dangerouslySetInnerHTML={{ __html: productDescription }}
            className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] py-[5px] px-[12px] shadow-xl  text-[#000] font-[300] lg:mb-4 mb-3"
          ></p> */}

          {product?.designMetalTone?.length > 0 && (
            <div className="my-4 ">
              <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                Select Metal Purity
              </h3>
              <div className="flex items-center gap-2">
                {product?.designMetalTone?.map((item) => {
                  return (
                    <button
                      key={item.unAttributeValueIdentifier}
                      type="button"
                      onClick={handleChangeMetalPurity.bind(
                        null,
                        item.stAttributeValueName,
                        item.unAttributeValueIdentifier,
                        item.dcPrice
                      )}
                      className={`border px-3 ${
                        metalPurity?.name === item?.stAttributeValueName
                          ? "bg-[#f1f5f9]"
                          : ""
                      } rounded p-1`}
                    >
                      {item.stAttributeValueName}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          <div className="sm:mb-6 mb-4">
            {product?.designAttribute?.length &&
              product?.designAttribute?.map((item, i) => {
                if (item.stAttributeName === "Size") {
                  const SizeData = product?.designAttributeValue.filter(
                    (itm) => itm.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="mb-4">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">{data?.Shape?.name}</span> */}
                      </h3>
                      <div className="flex items-center gap-2">
                        {SizeData.length > 0 &&
                          SizeData.map((itm, i) => {
                            return (
                              <button
                                key={i}
                                type="button"
                                onClick={handleChangeShape.bind(
                                  null,
                                  item.stAttributeName,
                                  itm.stAttributeValueName,
                                  itm?.unAttributeValueId
                                )}
                                className={`flex items-center justify-center w-[40px] h-[40px] border-2 rounded-full shadow-lg border-[#cbd5e1] ${
                                  data?.Size?.name === itm.stAttributeValueName
                                    ? "bg-[#f1f5f9]"
                                    : ""
                                }`}
                              >
                                {itm.stAttributeValueAlias}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                }
                if (
                  item.stAttributeName === "Center Stone Shape" ||
                  item.stAttributeName === "Side Stone Shape"
                ) {
                  const ShapeData = product?.designAttributeValue.filter(
                    (itm) => itm.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="mb-4">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">{data?.Shape?.name}</span> */}
                      </h3>
                      {ShapeData.length >= 70 ? (
                        <Slider {...settings}>
                          {ShapeData.length > 0 &&
                            ShapeData.map((itm, i) => {
                              return (
                                <button
                                  key={i}
                                  type="button"
                                  onClick={handleChangeShape.bind(
                                    null,
                                    item.stAttributeName,
                                    itm.stAttributeValueName,
                                    itm?.unAttributeValueId
                                  )}
                                  className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${
                                    data?.[item.stAttributeName]?.name ===
                                    itm.stAttributeValueName
                                      ? "bg-[#f1f5f9]"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={shapeImages(itm.stAttributeValueName)}
                                    alt={`shape-${i + 1}`}
                                    className="m-auto h-[30px]"
                                  />
                                </button>
                              );
                            })}
                        </Slider>
                      ) : (
                        <div className="flex items-center gap-2 flex-wrap">
                          {ShapeData.length > 0 &&
                            ShapeData.map((itm, i) => {
                              return (
                                <button
                                  key={i}
                                  type="button"
                                  onClick={handleChangeShape.bind(
                                    null,
                                    item.stAttributeName,
                                    itm.stAttributeValueName,
                                    itm?.unAttributeValueId
                                  )}
                                  className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${
                                    data?.[item.stAttributeName]?.name ===
                                    itm.stAttributeValueName
                                      ? "bg-[#f1f5f9]"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={itm.stImageURL}
                                    alt={`shape-${i + 1}`}
                                    className="m-auto h-[30px]"
                                  />
                                </button>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  );
                }
                if (item.stAttributeName === "Select Metal Tone") {
                  const MetalData = product?.designAttributeValue.filter(
                    (it) => it.stAttributeName === item.stAttributeName
                  );
                  return (
                    <div className="my-5">
                      <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                        {item.stAttributeName}{" "}
                        {/* <span className="font-[300]">
                          {data?.[item.stAttributeName]?.name}
                        </span> */}
                      </h3>
                      <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
                        {MetalData?.length > 0 &&
                          MetalData.map((it) => {
                            return (
                              <button
                                type="button"
                                onClick={handleChangeMetal.bind(
                                  null,
                                  item.stAttributeName,
                                  it.stAttributeValueName,
                                  it.unAttributeValueId
                                )}
                                className={`border-4 ${
                                  it.stAttributeValueAlias === "PL"
                                    ? "px-2 py-1 w-[37px] h-[37px]"
                                    : "px-1 py-1"
                                } ${
                                  data?.[item.stAttributeName]?.name ===
                                    it.stAttributeValueName &&
                                  it.stAttributeValueName?.includes(
                                    "White Gold"
                                  )
                                    ? "bg-[#ececec]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Rose Gold"
                                      )
                                    ? "bg-[#e6be8a]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Yellow Gold"
                                      )
                                    ? "bg-[#d4af37]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Yellow Gold"
                                      )
                                    ? "bg-[#e5e4e2]"
                                    : data?.[item.stAttributeName]?.name ===
                                        it.stAttributeValueName &&
                                      it.stAttributeValueName?.includes(
                                        "Platinum"
                                      )
                                    ? "bg-[#d6d5d5]"
                                    : "bg-[#fff]"
                                }
                                    rounded-full ${
                                      it.stAttributeValueName?.includes(
                                        "White Gold"
                                      )
                                        ? "border-[#e4e5e2]"
                                        : it.stAttributeValueName?.includes(
                                            "Rose Gold"
                                          )
                                        ? "border-[#e6be8a]"
                                        : it.stAttributeValueName?.includes(
                                            "Yellow Gold"
                                          )
                                        ? "border-[#d4af37]"
                                        : "border-[#e5e4e2]"
                                    } font-semibold text-[14px]`}
                              >
                                {it.stAttributeValueAlias}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                }
                const attrValData = product.designAttributeValue.filter(
                  (attrVal) => attrVal.unAttributeId === item.unAttributeId
                );
                return (
                  <div className="my-4 ">
                    <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                      {item.stAttributeName}{" "}
                      {/* <span className="font-[300]">
                        {data?.[item.stAttributeName]?.name}
                      </span> */}
                    </h3>
                    <div className="flex items-center gap-2">
                      {attrValData?.length > 0 &&
                        attrValData?.map((band) => {
                          return (
                            <button
                              key={band.unAttributeValueId}
                              type="button"
                              onClick={handleChangeMetal.bind(
                                null,
                                item.stAttributeName,
                                band.stAttributeValueName,
                                band.unAttributeValueId
                              )}
                              className={`border px-3 ${
                                data?.[item.stAttributeName]?.name ===
                                band.stAttributeValueName
                                  ? "bg-[#f1f5f9]"
                                  : ""
                              } rounded p-1`}
                            >
                              {band.stAttributeValueName}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                );
              })}

            {product?.designMainStone?.length > 0 &&
              !product?.designMainStone?.every(
                (i) => !i.stAttributeValueName
              ) && (
                <div className="my-4 ">
                  <h3 className="text-[16px] leading-[36px] font-[400] text-[#000] mb-[4px] font-playfair">
                    Center Stone Range
                  </h3>
                  <div className="flex items-center gap-2">
                    {product?.designMainStone?.map((item) => {
                      if (!item.stAttributeValueName) return;

                      return (
                        <button
                          key={item.unAttributeValueIdentifier}
                          type="button"
                          onClick={handleCenterStoneRange.bind(null, item)}
                          className={`border px-3 ${
                            centerStoneRange?.name ===
                            item?.stAttributeValueName
                              ? "bg-[#f1f5f9]"
                              : ""
                          } rounded p-1`}
                        >
                          {item.stAttributeValueName}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}

            {(isSelectedDiamond || !product?.inMainStonesPcs) &&
              product?.isSize === 1 && (
                <>
                  {product?.isSize !== "0" && (
                    <div className="mt-4">
                      <h3 className="text-[16px] leading-[36px] font-[500] text-[#000] mb-[4px] font-playfair">
                        Choose your ring size
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {sizeArray
                          .slice(0, showMore ? sizeArray.length : 5)
                          .map((item, index) => {
                            return (
                              <button
                                key={index}
                                type="button"
                                onClick={() => {
                                  setSize(item);
                                }}
                                className={`flex justify-center items-center w-10 h-10 border rounded ${
                                  size === item ? "bg-[#f1f5f9]" : ""
                                }`}
                              >
                                {item}
                              </button>
                            );
                          })}
                      </div>
                      {!showMore && (
                        <button
                          onClick={() => setShowMore((prev) => !prev)}
                          className="text-xs underline text-slate-400 "
                        >
                          Show More size
                        </button>
                      )}
                    </div>
                  )}
                  {/* {product?.isEngraving !== "0" && (
                  <div className="mt-4">
                    {!!!addEngraving && (
                      <button
                        onClick={() => setShowEngraving(true)}
                        className="text-lg mb-3 font-Josefin font-extrabold text-slate-600"
                      >
                        Add Engraving
                        <span className="text-base font-[500] text-[#000000]">
                          (optional)
                        </span>
                      </button>
                    )}
                    {!!addEngraving && (
                      <div className="w-full flex justify-between items-center mb-3">
                        <div className="text-lg font-bold">
                          Your engraving :{" "}
                          <span className="text-[24px] font-Allura font-[600] tracking-wider text-[#000000]">
                            {" "}
                            {addEngraving}
                          </span> 
                        </div>
                        {!showEngraving && (
                          <button
                            onClick={() => setShowEngraving(true)}
                            className="text-xs underline text-slate-400 "
                          >
                            Modify
                          </button>
                        )}
                      </div>
                    )}
                    {showEngraving && (
                      <>
                        <div>
                          <input
                            type="text"
                            value={engraving}
                            onChange={(e) => {
                              e.target.value?.length <= 20 &&
                                setEngraving(e.target.value);
                            }}
                            className="font-Allura px-3 h-10 w-full outline-none text-[#626262] text-base border-[1px] border-slate-400 "
                          />
                          <span className="text-xs text-slate-600">
                            Character limit: {engraving?.length}/20
                          </span>
                        </div>
                        <button
                          onClick={() => {
                            setAddEngraving(engraving);
                            setShowEngraving(false);
                          }}
                          className="outline-none border-2 border-slate-700 w-full py-2 text-base font-Josefin mt-3"
                        >
                          Add engraving
                        </button>
                      </>
                    )}
                  </div>
                )} */}
                </>
              )}
            {/* <h3 className="lg:text-[25px] text-[18px] lg:leading-[36px] leading-[30px] text-[#000] font-[400] my-3 flex items-center gap-2">
              US${" "}
              {(
                productPrice +
                stonePrice +
                Number(metalPurity?.dcPrice || 0) * Number(product?.dcNtWt || 1)
              ).toFixed(2)}
              {selectedDiamond.length > 0 ? (
                <span className="text-[14px] font-[400]">
                  (Including Center Stone)
                </span>
              ) : (
                <span className="text-[14px] font-[400]">(Setting Price)</span>
              )}
            </h3> */}
            {!!selectedDiamond?.length && (
              <div className="my-4">
                {/* <h3 className="text-lg font-[500] font-Josefin text-[#000] m-0 mb-1">
                Selected Diamonds : &nbsp;
              </h3> */}
                <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
                  {selectedDiamond.map((dia, index) => {
                    return (
                      <div
                        key={index}
                        className="text-sm text-gray-700 col-span-2"
                      >
                        <div className="flex items-center gap-4 border p-2 pr-4 rounded relative">
                          <div className="overflow-hidden">
                            {dia.ImageUrl !== null ? (
                              <img
                                src={dia.ImageUrl}
                                alt="ImageUrl"
                                className="w-[60px] h-[60px] object-cover rounded"
                              />
                            ) : (
                              <div className="w-[60px] h-[60px] bg-slate-200 rounded"></div>
                            )}
                          </div>
                          <div>
                            <div className="text-[13px]">
                              <span className="font-[600]">Shape:</span>{" "}
                              {dia.SHAPE}
                            </div>
                            <div className="flex items-center">
                              <div className="pr-2 text-[13px]">
                                <span className="font-[600]">Carat:</span>{" "}
                                {dia.CARAT}
                              </div>
                              <div className="text-[13px]">
                                <span className="font-[600]">Colour:</span>{" "}
                                {dia.COLOUR}
                              </div>
                            </div>
                            <div className="text-[13px]">
                              <span className="font-[600]">Clarity:</span>{" "}
                              {dia.CLARITY}
                            </div>
                          </div>
                          <div className="absolute top-[5px] right-[10px]">
                            <button
                              onClick={() => handleRemoveDiamond(dia.StoneNo)}
                            >
                              <IoMdClose />
                            </button>
                          </div>
                        </div>
                        {/* <span className="font-[500]"></span> {dia.SHAPE}, {dia.CARAT}, {dia.COLOUR}, {dia.CLARITY} */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <p className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] mb-9 sm:hidden block">
            {product?.stRemark1}
          </p>
          {/* <button
            onClick={handleAddToCart}
            className="w-full block text-center font-playfair tracking-[1px] bg-[#334155] text-[#fff] text-[16px] leading-[32px] py-[6px]"
          >
            {isSelectedDiamond || !product?.inMainStonesPcs
              ? "Add To Cart"
              : "Select Your Diamond"}
          </button> */}

          <div className="hidden md:block">
            <button
              onClick={handleAddToCart}
              className="w-full block text-center font-sans tracking-[1px] bg-[#334155] text-[#fff] text-[14px] leading-[32px] py-[6px] font-[600]"
            >
              {isSelectedDiamond || !product?.inMainStonesPcs
                ? "ADD TO CART"
                : "SELECT YOUR DIAMOND"}
            </button>
            <button
              onClick={() => {
                navigate("/make-own-design", {
                  state: {
                    data: {
                      metal: product?.metal,
                      centerStoneShape: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Center Stone Shape"
                      )?.stAttributeValueName,
                      ringSize: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Ring Size"
                      )?.stAttributeValueName,
                      images: product?.designImages
                        ?.filter((obj) => !obj?.stImageURL?.endsWith(".mp4"))
                        ?.map((obj) => obj?.stImageURL),
                      category: product?.category,
                      backURL: window.location.pathname,
                    },
                  },
                });
              }}
              className="w-full block text-center font-sans tracking-[1px] bg-[#E9E9E9] text-black text-[14px] leading-[32px] py-[6px] font-[600] mt-3"
            >
              CUSTOMIZE FOR ME
            </button>
          </div>

          <div class="md:hidden fixed bottom-0 left-0 right-0 flex">
            <button
              onClick={() => {
                navigate("/make-own-design", {
                  state: {
                    data: {
                      metal: product?.metal,
                      centerStoneShape: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Center Stone Shape"
                      )?.stAttributeValueName,
                      ringSize: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Ring Size"
                      )?.stAttributeValueName,
                      images: product?.designImages
                        ?.filter((obj) => !obj?.stImageURL?.endsWith(".mp4"))
                        ?.map((obj) => obj?.stImageURL),
                      category: product?.category,
                      backURL: window.location.pathname,
                    },
                  },
                });
              }}
              class="w-1/2 block text-center font-sans tracking-[1px] bg-[#E9E9E9] text-black text-[14px] leading-[32px] py-[6px] font-[600]"
            >
              CUSTOMIZE FOR ME
            </button>
            <button
              onClick={handleAddToCart}
              class="w-1/2 block text-center font-sans tracking-[1px] bg-[#334155] text-[#fff] text-[14px] leading-[32px] py-[6px] font-[600]"
            >
              {isSelectedDiamond || !product?.inMainStonesPcs
                ? "ADD TO CART"
                : "SELECT YOUR DIAMOND"}
            </button>
          </div>

          <div className="flex md:flex-nowrap justify-center md:justify-start gap-y-2 mt-3 gap-x-2 mb-0">
            <button
              onClick={() => {
                downloadPDF();
              }}
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
              >
                <img src={PDFImage} className="w-4 h-4 md:w-5 md:h-5 mr-2" />
                PDF
              </button>
            </button>

            <a
              href={`https://wa.me/?text=${window.location.href}`}
              target="_blank"
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
            >
              <button class="inline-flex w-full hover:bg-gray-200" role="group">
                <button
                  type="button"
                  class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
                >
                  <img
                    src={WhatsappImage}
                    className="w-4 h-4 md:w-5 md:h-5 mr-2"
                  />
                  Whatsapp
                </button>
              </button>
            </a>

            <button
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success("Copied..");
              }}
              class="inline-flex w-full md:w-[33%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center items-center px-4 md:px-2 xl:px-4 py-2 text-sm md:text-base font-medium text-gray-600 bg-white border border-gray-200 dark:focus:text-white"
              >
                <img src={LinkImage} className="w-4 h-4 md:w-5 md:h-5 mr-2" />
                Share
              </button>
            </button>
          </div>

          {/* <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start gap-y-2 mt-2 gap-x-2">
            <button
              onClick={() => {
                navigate("/make-own-design", {
                  state: {
                    data: {
                      metal: product?.metal,
                      centerStoneShape: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Center Stone Shape"
                      )?.stAttributeValueName,
                      ringSize: product?.designAttributeValue?.find(
                        (obj) => obj?.stAttributeName === "Ring Size"
                      )?.stAttributeValueName,
                      images: product?.designImages
                        ?.filter((obj) => !obj?.stImageURL?.endsWith(".mp4"))
                        ?.map((obj) => obj?.stImageURL),
                      category: product?.category,
                      backURL: window.location.pathname,
                    },
                  },
                });
              }}
              class="inline-flex rounded w-full md:w-[30%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex items-center px-4 md:px-2 xl:px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
              >
                Customize For Me
              </button>

              <button
                type="button"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
              >
                <IoSettingsOutline className="w-5 h-5" />
              </button>
            </button>

            <button
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success("Copied..");
              }}
              class="inline-flex rounded w-full md:w-[23%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex items-center px-4 md:px-2 xl:px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
              >
                Copy
              </button>

              <button
                type="button"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
              >
                <IoIosLink className="w-5 h-5" />
              </button>
            </button>

            <button
              onClick={() => {
                downloadPDF();
              }}
              class="inline-flex rounded w-full md:w-[23%] hover:bg-gray-200"
              role="group"
            >
              <button
                type="button"
                class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
              >
                PDF
              </button>

              <button
                type="button"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
              >
                <MdOutlineFileDownload className="w-5 h-5" />
              </button>
            </button>

            <a
              href={`https://wa.me/?text=${window.location.href}`}
              target="_blank"
              class="inline-flex rounded  w-full md:w-[23%] hover:bg-gray-200"
            >
              <button
                type="button"
                class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
              >
                Whatsapp
              </button>

              <button
                type="button"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
              >
                <FaWhatsapp className="w-5 h-5 mr-1" />
              </button>
            </a>
          </div> */}

          <div className="flex items-start justify-end mb-[40px] md:flex-row flex-col gap-14 mt-8">
            <div className="w-full lg:text-left sm:text-center text-left">
              {/* <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={diamond} alt="diamond" /> Jewellers created diamond{" "}
          </p>
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={Calendar} alt="diamond" />
            Made to order. Order now, ships by Thu, Mar 2
          </p>
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={truck} alt="diamond" /> Free shipping{" "}
          </p>
          <p className="text-[16px] leading-[26px] flex items-center lg:justify-start justify-center gap-[20px]">
            <img src={warranty} alt="diamond" /> Lifetime warranty
          </p> */}
              {/* <p
                dangerouslySetInnerHTML={{ __html: variationDescription }}
                className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] lg:mb-4 mb-3"
              ></p> */}
              <p
                dangerouslySetInnerHTML={{ __html: productDescription }}
                className="hidden lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] py-[5px] px-[12px] shadow-xl  text-[#000] font-[300] lg:mb-4 mb-3"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
