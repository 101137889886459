import React, { useState } from "react";
import AdminModal from "../Admin/common/AdminModal";
import { MdContentCopy, MdOutlineFileDownload, Md360 } from "react-icons/md";
import { IoPlayCircleOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { Tb360View } from "react-icons/tb";
import { IoIosLink } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import "jspdf-autotable";

function DiamondDetailModel(props) {
  const location = useLocation();

  const { open, handleClose, data, handleOnRequest, handleVideoModalOpen } = props;

  const downloadPDF = () => {
    const doc = new jsPDF("l", "mm", "a5");

    // Add border to the entire page
    doc.setDrawColor("gray"); // Border color (black in this case)
    doc.setLineWidth(0.3); // Border width
    doc.rect(
      2,
      2,
      doc.internal.pageSize.width - 4,
      doc.internal.pageSize.height - 4,
      "S"
    ); // 'S' option for stroke

    // Add a header to the PDF
    doc.setTextColor("black");
    doc.text(`DTN Premium Quality LAB-GROWN DIAMONDS`, 42, 18);

    // Add a link to Google in the PDF
    doc.setTextColor("black");
    doc.setFontSize(10);

    if (data?.VideoUrl) {
      doc.textWithLink("Click to view video", 19, 80, { url: data?.VideoUrl });
      doc.text("_______________", 19, 80.5);
    }

    if (data?.["360Url"] || true) {
      doc.textWithLink(
        "Click to open 360 view",
        16,
        !data?.VideoUrl ? 80 : 86,
        {
          url: data?.["360Url"],
        }
      );
      doc.text("__________________", 16.4, !data?.VideoUrl ? 80 : 86.5);
    }

    doc.addImage(data?.ImageUrl, "PNG", 13, 35, 40, 40); // Adjust position and size as needed

    const columns = ["", ""];

    // Define the data for the auto-table

    const bodydata = [
      [{ content: "Shape", styles: { fontStyle: "bold" } }, data?.SHAPE],
      [{ content: "Carat", styles: { fontStyle: "bold" } }, data?.CARAT],
      [{ content: "Stock ID", styles: { fontStyle: "bold" } }, data?.StoneNo],
      [
        { content: "Cert ID", styles: { fontStyle: "bold" } },
        `${data?.LAB}-${data?.CERTID}`,
      ],
      [{ content: "Colour", styles: { fontStyle: "bold" } }, data?.COLOUR],
      [{ content: "Clarity", styles: { fontStyle: "bold" } }, data?.CLARITY],
      [
        { content: "Cut / Polish / Sym", styles: { fontStyle: "bold" } },
        data?.CUTPOLISHSYM,
      ],
      [
        { content: "Measurement", styles: { fontStyle: "bold" } },
        data?.MEASUREMENT,
      ],
      [{ content: "Location", styles: { fontStyle: "bold" } }, data?.Location],
    ];

    // Define alternative row colors
    const rowColors = {
      odd: [245, 245, 245], // Light gray color for odd rows
      even: [255, 255, 255], // White color for even rows
    };

    // Define a function to apply row colors based on the row index
    const applyRowColors = (dataList) => {
      dataList.forEach((row, index) => {
        if (index % 2 === 0) {
          // Even rows
          row.styles.fillColor = rowColors.even;
        } else {
          // Odd rows
          row.styles.fillColor = rowColors.odd;
        }
      });
    };

    doc.autoTable({
      startY: 35,
      margin: { left: 65 },
      //  head: [columns],
      body: bodydata,
      // didDrawCell: applyRowColors,
      theme: "plain", // Optional: 'striped', 'plain', 'grid' (default)
      styles: { lineWidth: 0.2 }, // Set border width
      columnStyles: {
        0: { fillColor: "#FAFAFA" },
      },
    });

    doc.save(`${data?.LAB}-${data?.StoneNo}`);
  };

  return (
    <AdminModal
      title={"Diamond Detail"}
      onSubmit={() => {}}
      disableSubmit={false}
      showButton={false}
      isOpen={open}
      onClose={() => handleClose()}
      submitButtonText={"Submit Order"}
      className="min-w-[42%]"
      showActionButtons={false}
    >
      <div className="p-2">
        <div className="flex flex-col justify-between xl:flex-row xl:gap-x-6 2xl:gap-x-0">
          <div className="w-full md:w-max flex flex-col md:flex-row md:gap-x-3 xl:gap-x-0 xl:flex-col">
            <img
              src={data?.ImageUrl}
              alt="Image"
              className="w-full md:w-44 h-full md:h-44 mt-2"
            />

            <div className="flex flex-col gap-y-1 gap-x-4 mt-2 justify-evenly">
              <a
                // href={data?.VideoUrl}
                // target="_blank"
                class="inline-flex rounded w-full hover:bg-gray-200"
                role="group"
                onClick={handleVideoModalOpen.bind(null, data)}
              >
                <button
                  type="button"
                  class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
                >
                  Video
                </button>

                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
                >
                  <MdOutlineFileDownload className="w-5 h-5" />
                </button>
              </a>

              {data?.["360Url"] && (
                <a
                  href={data?.["360Url"]}
                  target="_blank"
                  class="inline-flex rounded w-full hover:bg-gray-200"
                  role="group"
                >
                  <button
                    type="button"
                    class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
                  >
                    360 View
                  </button>

                  <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
                  >
                    <Md360 className="w-5 h-5" />
                  </button>
                </a>
              )}

              <a
                href={data?.ImageUrl}
                target="_blank"
                class="inline-flex rounded w-full hover:bg-gray-200"
                role="group"
              >
                <button
                  type="button"
                  class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
                >
                  Image
                </button>

                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
                >
                  <MdOutlineFileDownload className="w-5 h-5" />
                </button>
              </a>

              <a
                href={data?.ImageUrl}
                target="_blank"
                class="inline-flex rounded w-full hover:bg-gray-200"
                role="group"
              >
                <button
                  type="button"
                  class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
                >
                  Location
                </button>

                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 text-xs font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
                >
                  <p className="font-medium">{data?.Location}</p>
                </button>
              </a>
            </div>
          </div>
          <div className="w-full xl:w-[65%] flex flex-col gap-y-7 justify-center self-start p-2 xl:p-0">
            <div className="w-full flex justify-between">
              <div className="w-[40%]">
                <p className="text-base text-gray-400">Shape</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.SHAPE}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-base text-gray-400">Carat</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.CARAT}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-base text-gray-400">Stock ID</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.StoneNo}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-[40%]">
                <p className="text-base text-gray-400">Cert-ID</p>
                <p className="text-sm font-medium text-gray-700 flex gap-x-1 items-center">
                  <p className="text-gray-700">
                    {data?.LAB}-{data?.CERTID}
                  </p>
                  <MdContentCopy
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${data?.LAB}-${data?.CERTID}`
                      );
                      toast.success("Copied..");
                    }}
                  />
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-base text-gray-400">Color</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.COLOUR}
                </p>
              </div>
              <div className="w-[30%]">
                <p className="text-base text-gray-400">Clarity</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.CLARITY}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Cut / Polish / Sym</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.CUTPOLISHSYM}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Measurement</p>
                <p className="text-sm font-medium text-gray-700">
                  {data?.MEASUREMENT}
                </p>
              </div>
            </div>

            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <p className="text-base text-gray-400">Rate (USD)</p>

                <button
                  type="button"
                  className="font-[400] underline text-gray-700"
                  onClick={() => {
                    handleOnRequest(data);
                  }}
                >
                  On Request
                </button>
              </div>
              <div className="w-1/2">
                <p className="text-base text-gray-400">Amount (USD)</p>
                <button
                  type="button"
                  className="font-[400] underline text-gray-700"
                  onClick={() => {
                    handleOnRequest(data);
                  }}
                >
                  On Request
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center gap-x-1 gap-y-2 md:gap-y-0 mt-6 pb-6">
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                window.location.hostname + `/diamonds?StoneNo=${data?.StoneNo}`
              );
              toast.success("Copied..");
            }}
            class="inline-flex rounded w-full md:w-40 hover:bg-gray-200"
            role="group"
          >
            <button
              type="button"
              class="w-full inline-flex items-center px-4 md:px-2 xl:px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
            >
              Copy Link
            </button>

            <button
              type="button"
              class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
            >
              <IoIosLink className="w-5 h-5" />
            </button>
          </button>

          <button
            onClick={() => {
              downloadPDF();
            }}
            class="inline-flex rounded w-full md:w-40 hover:bg-gray-200"
            role="group"
          >
            <button
              type="button"
              class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
            >
              PDF
            </button>

            <button
              type="button"
              class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
            >
              <MdOutlineFileDownload className="w-5 h-5" />
            </button>
          </button>

          <a
            href={`https://wa.me/?text=${
              window.location.hostname + `/diamonds?StoneNo=${data?.StoneNo}`
            }`}
            target="_blank"
            class="inline-flex rounded w-full md:w-40 hover:bg-gray-200"
          >
            <button
              type="button"
              class="w-full inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-s dark:focus:text-white"
            >
              Whatsapp
            </button>

            <button
              type="button"
              class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-e dark:text-white dark:focus:text-white"
            >
              <FaWhatsapp className="w-5 h-5 mr-1" />
            </button>
          </a>
        </div>

        {/* <nav class="flex items-center justify-end flex-wrap bg-gray-700 p-2">
        <div>
          <button
            onClick={downloadPDF}
            class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white duration-200"
          >
            Download PDF
          </button>
        </div>
      </nav> */}
      </div>
    </AdminModal>
  );
}

export default DiamondDetailModel;
