import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import { getDesign, getDesignAndStockByStockCode } from "../../Services/designService";
import { useEffect } from "react";
import ProductCard from "./Common/ProductCard/index";
import NoDataText from "../shared/NoDataText";
import ProductCardSkeleton from "./Common/skeleton/ProductCardSkeleton";
import { ReactComponent as Emoji } from "../../assets/images/sadEmoji.svg";
import { useMemo } from "react";
import {
  fetchReadyToShip,
  getReadyToShipDataState,
} from "../../store/Slices/readyToShipSlice";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { IoArrowForwardCircle } from "react-icons/io5";
import { BsArrowRightSquareFill, BsArrowRightSquare  } from "react-icons/bs";
import { MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HomeProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [designData, setDesignData] = useState([]);
  const [searchProductCode, setSearchProductCode] = useState("");
  const [searchProductError, setSearchProductError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: readyToShipData } = useSelector(
    (state) => state.readyToShipData
  );

  const userId = localStorage.name ? JSON.parse(localStorage.name) : {};
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const fetchDesignData = async () => {
    try {
      const params = {
        inPageNo: 1,
        inPageSize: 10,
        unUserId: userId?.unUserIdentifier,
        isNew: 1,
      };
      setIsLoading(true);
      const response = await getDesign(params);
      if (response.status === 200) {
        setDesignData(response?.data?.data?.designs);
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductCodeSearch = async () => {

    try {
      if(searchProductCode)
      {

        navigate('/searched-result',
          {
            state: {
              searchedText: searchProductCode,
            },
        });
        // const response = await getDesignAndStockByStockCode(searchProductCode);
        // if (response.status === 200) {

        //   if(!response?.data?.data?.length)
        //   {
        //     setSearchProductError("Product not found");
        //   }
        //   setSearchProductError("");
        //   // navigate(response?.data?.data?.[0]?.URLProductId);
        //   navigate('/searched-result',
        //   {
        //     state: {
        //       searchedText: searchProductCode,
        //     },
        //   });
        // }
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  };

  // useEffect(() => {
  //   fetchDesignData();
  // }, []);

  useEffect(() => {
    dispatch(fetchReadyToShip());
  }, [dispatch]);

  const activeData = useMemo(() => {
    if (designData.length > 0) {
      return designData.filter((item) => item.flgIsActive === 1);
    }
    return [];
  }, [designData]);

  return (
    <div className="xl:max-w-[1224px] max-w-full xl:mx-auto mx-[20px] lg:mb-[60px] md:mb-[40px] mb-[30px]">
      <div className="mb-4">
        <label
          for="search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div class="relative">
          {/* <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div> */}
          <button
            type="button"
            class="text-gray-400 absolute left-0 bottom-2 bg-transparent cursor-auto focus:ring-0 focus:outline-none  font-medium rounded-lg text-sm px-4 py-2"
          >
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <input
            type="text"
            id="search"
            onChange={(e) => setSearchProductCode(e.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleProductCodeSearch()
              }
            }}
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-0 focus:ring-opacity-0 focus-visible:outline-[#1F2937]"
            placeholder="Search by Product Code"
            required
          />
          <button
            type="submit"
            onClick={() => {
              handleProductCodeSearch();
            }}
            class="text-gray-400 absolute bottom-2 right-2 bg-transparent focus:ring-0 focus:outline-none  font-medium rounded-lg text-sm px-2 py-2 border border-[#1F2937]"
            // class="flex aspect-square sm:h-full h-[85%] items-center justify-center text-slate-800 bg-white rounded-xl transition"
          >
            <MdOutlineSearch
              className="h-5 w-5 text-[#1F2937] round"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      <div className="mb-[27px]">
        <h2 className="lg:text-[40px] md:text-[30px] text-[26px] lg:leading-[52px] md:leading-[40px] leading-[30px] font-[600] text-center text-[#000] font-playfair">
          New Arrival
        </h2>
        {/* <p className="lg:text-[16px] text-[14px] lg:leading-[52px] md:leading-[40px] leading-[26px] font-[400] text-center text-[#626262] font-Poppins">
          Lorem Ipsum is simply dummy text of the printing and typesetting
        </p> */}
      </div>
      {isLoading && (
        <div className=" grid lg:grid-cols-4 md:grid-cols-3	sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2">
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </div>
      )}
      {!isLoading &&
        (!readyToShipData?.length ? (
          <NoDataText
            text={
              <div className="flex flex-col justify-center items-center">
                {/* <img src={emoji} alt="sad emoji" /> */}
                <Emoji opacity={0.3} width={60} />
                <span>Sorry, we couldn't found any product.</span>
              </div>
            }
          />
        ) : (
          <>
            {/* <div className="grid xl:grid-cols-8 md:grid-cols-6 grid-cols-4 gap-4">
            {!!activeData?.length &&
              activeData
                .splice(0, 4)
                ?.map(
                  (
                    {
                      stDefImageURL: mainImage,
                      unDesignIdentifier: id,
                      stDesignName: name,
                      stFocusImageURL: hoverImage,
                      dcSalePrice: price,
                      ...rest
                    },
                    index
                  ) => {
                    return (
                      <ProductCard
                        key={index}
                        id={id}
                        title={name}
                        mainImage={mainImage}
                        hoverImage={hoverImage}
                        price={price}
                        data={rest}
                      />
                    );
                  }
                )}
          </div> */}
            <div className="grid xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-6 grid-cols-4 gap-3">
              {!!readyToShipData?.length &&
                readyToShipData
                  ?.slice(0, 10)
                  ?.map(
                    (
                      {
                        DefImageURL: mainImage,
                        StockID: id,
                        StockName: name,
                        FocusImageURL: hoverImage,
                        SalePrice: price,
                        StockNo,
                        ...rest
                      },
                      index
                    ) => {
                      return (
                        <ProductCard
                          key={index}
                          id={id}
                          title={name}
                          mainImage={mainImage}
                          hoverImage={hoverImage}
                          price={price}
                          data={rest}
                          StockNo={StockNo}
                          needToRedirectReadyToShip={true}
                        />
                      );
                    }
                  )}
            </div>
          </>
        ))}
    </div>
  );
};

export default HomeProduct;
