import React from "react";
import { useState } from "react";
import AddCategory from "./Modals/AddCategory";
import Pagination from "./Pagination";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsCardChecklist, BsPlusLg } from "react-icons/bs";
import { GoDiffAdded, GoKebabVertical } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { DeleteCategory } from "../../Services/categoryService";
import AddCategoryStyle from "./Modals/AddCategoryStyle";
import { TiArrowBack } from "react-icons/ti";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const CategoryList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAtOpen, setIsAtOpen] = useState(false);
  const dispatch = useDispatch();
  const [editId, setEditId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const { data, loading, error } = useSelector(getCategoryDataState);
  const navigate = useNavigate();

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  const handleOpenCategoryStyle = (id) => {
    setCategoryId(id);
    setIsAtOpen(true);
  };

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await DeleteCategory(id);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchCategory());
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full z-0 h-[90vh] bg-gray-100">
      <AddCategory
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        setEditId={setEditId}
      />
      <AddCategoryStyle
        isAtOpen={isAtOpen}
        setIsAtOpen={setIsAtOpen}
        categoryId={categoryId}
      />
      <main className="h-full">
        <div className="px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-[10px]">
            <Link
              to={"/admin/masterMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
          </div>
          <div className="sm:rounded-lg relative mt-4 rounded overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4 w-[100px]">
                    Image
                  </th>
                  <th scope="col" className="p-4">
                    Category Name
                  </th>
                  <th
                    scope="col"
                    className="p-4 w-[40px] text-center"
                  >
                    Y | N
                  </th>
                  <th scope="col" className="p-4 w-[100px] text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && currentData?.length
                  ? currentData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                        >
                          <th
                            scope="row"
                            className="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <img
                              src={item.stImageUrl}
                              alt=""
                              className="w-[40px] h-[40px] rounded"
                            />
                          </th>
                          <th
                            scope="row"
                            className="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.stCategoryName}
                          </th>
                          <td className="p-4 text-center w-[40px]">
                            {(item.flgIsActive === "1" || item.flgIsActive === 1) ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )}
                          </td>
                          <td className="p-4">
                            <div className="flex items-center gap-[10px]">
                              <button
                                onClick={handleEdit.bind(
                                  null,
                                  item.unCategoryIdentifier
                                )}
                                type="button"
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              <button
                                type="button"
                                onClick={handleDelete.bind(
                                  null,
                                  item.unCategoryIdentifier
                                )}
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              <div className="flex items-center justify-end">
                                <Menu as="div" className="h-[20px] text-left">
                                  <Menu.Button>
                                    <GoKebabVertical className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[3px] rounded" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 mt-2 w-56 z-50 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="px-1 py-1 ">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-gray-900"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-2 text-[13px] font-Poppins`}
                                              onClick={() =>
                                                handleOpenCategoryStyle(
                                                  item.unCategoryIdentifier
                                                )
                                              }
                                            >
                                              <GoDiffAdded className="text-[16px]" />
                                              Add Category Style
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${
                                                active
                                                  ? "bg-[#94a3b8] text-white"
                                                  : "text-[#1e293b]"
                                              } group flex w-full items-center gap-[5px] rounded-md px-2 py-2 text-[13px] font-Poppins`}
                                              onClick={() =>
                                                navigate(
                                                  `/admin/categoryStylelist/${item.unCategoryIdentifier}`
                                                )
                                              }
                                            >
                                              <BsCardChecklist className="text-[16px]" />
                                              Category Style List
                                            </button>
                                          )}
                                        </Menu.Item>
                                        {item.flgIsSize === "1" && (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                className={`${
                                                  active
                                                    ? "bg-[#94a3b8] text-white"
                                                    : "text-[#1e293b]"
                                                } group flex w-full items-center gap-[5px] rounded-md px-2 py-2 text-[13px] font-Poppins`}
                                                onClick={() =>
                                                  navigate(
                                                    `/admin/categoryStylelist/${item.unCategoryIdentifier}`
                                                  )
                                                }
                                              >
                                                <BsCardChecklist className="text-[16px]" />
                                                Add Category Size
                                              </button>
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : !loading && (
                      <tr>
                        <td colSpan={4} className="text-[14px] text-center">
                          <NoDataText text={"No data found!"} />
                        </td>
                      </tr>
                    )}
                {loading && (
                  <tr>
                    <td colSpan={4} className="text-[14px] text-center h-60">
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
        </div>
      </main>
    </div>
  );
};

export default CategoryList;
