import React from "react";
import { useState } from "react";
import AddUserRole from "./AddUserRole";
import Pagination from "./Pagination";
import useApi from "../../Hooks/use-Api";
import { deleteUserRole, getUsers } from "../../Services/userService";
import { useEffect } from "react";
import Loader from "./common/Loader";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import UpdateUser from "./UpdateUser";
import UpdateUserPermission from "./UpdateUserPermission";
import { TiArrowBack } from "react-icons/ti";
import { BsPlusLg } from "react-icons/bs";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { Link } from "react-router-dom";
import UserMargin from "./Modals/UserMargin";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import { toast } from "react-hot-toast";
import { Listbox } from "@headlessui/react";
import { Transition } from "react-transition-group";
import SearchIcon from "@mui/icons-material/Search";
import { FiFilter } from "react-icons/fi";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { debounce } from "@mui/material";

const UserRole = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMargin, setIsOpenMargin] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenUpdatePermission, setIsOpenUpdatePermission] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selected, _selected] = useState();
  const [value, _value] = useState("");

  const [fetchUsers, data, loading, error] = useApi(getUsers);
  const role = useSelector((s) => s.rolePermissions.rolePermissions.role);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: data ? data.user : [],
      itemsPerPage: 10,
    });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSetData = (val, name) => {
    const currentCategory = role?.length && role?.find((itm) => itm === val);
    if (currentCategory?.stRoleName) {
      _selected(currentCategory);
    }
    const params = {
      RoleId: currentCategory?.unRoleIdentifier,
      Name: value ? value : null,
    };
    fetchUsers(params);
  };

  const handleSearchBox = (e) => {
    _value(e.target.value);
    let params = {
      RoleId: selected?.unRoleIdentifier,
    };
    if (e.target.value.length > 0) {
      params["Name"] = e.target.value;
    }
    fetchUsers(params);
  };

  const debouncedOnChange = debounce(handleSearchBox, 700);

  const handleEdit = (data) => {
    setEditData(data);
    setIsOpenUpdate(true);
  };

  const handleEditPermission = (data) => {
    setEditData(data);
    setIsOpenUpdatePermission(true);
  };

  const openMargin = (data) => {
    setEditData(data);
    setIsOpenMargin(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteUserRole(id);
      if (res.status === 200) {
        fetchUsers();
        toast.success(res.data.message);
      } else {
        toast.error(res.data?.message);
      }
    } catch (error) {
      toast.error("Something Wrong!");
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <AddUserRole isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpenUpdate && (
        <UpdateUser
          isOpen={isOpenUpdate}
          setIsOpen={setIsOpenUpdate}
          editData={editData}
          setEditData={setEditData}
          fetchUpdatedData={fetchUsers}
        />
      )}

      {isOpenUpdatePermission && (
        <UpdateUserPermission
          isOpen={isOpenUpdatePermission}
          setIsOpen={setIsOpenUpdatePermission}
          editData={editData}
          setEditData={setEditData}
          fetchUpdatedData={fetchUsers}
        />
      )}

      <UserMargin
        isOpen={isOpenMargin}
        setIsOpen={setIsOpenMargin}
        data={editData}
        setData={setEditData}
      />
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="w-auto mr-1 lg:mr-0">
            <div className="mt-2 flex flex-wrap rounded-md shadow-sm">
              <Link
                to={"/admin/agentMenu"}
                className="rounded-l-md  text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50 "
              >
                <TiArrowBack className="text-[18px]" /> Back
              </Link>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={debouncedOnChange}
                  className="block w-full rounded-none border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search Text here..."
                />
              </div>
              <Listbox
                value={selected}
                onChange={(val) => {
                  handleSetData(val, "role");
                }}
              >
                <div className="relative w-[155px]">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default hover:bg-gray-50 py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected ? selected?.stRoleName : "Role"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {role?.length &&
                          role?.map((person, personIdx) => (
                            <Listbox.Option
                              key={personIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-amber-100 text-amber-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={person}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {person.stRoleName}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </>
                  </Transition>
                </div>
              </Listbox>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FiFilter
                  className="-ml-0.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Filter
              </button>
              <Link
                onClick={() => setIsOpen(true)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </Link>
            </div>
          </div>
          {/* <div className="flex items-center justify-end gap-[10px] mt-4">
            <Link
              to={"/admin/utilityMenu"}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
          </div> */}
          <div className="relative overflow-x-auto sm:rounded-lg mt-4">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="bg-[#e2e8f0]">
                <tr>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    User Name
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Email id
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    City
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Stone%
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Jewelry%
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Verify
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Access
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && currentData.length
                  ? currentData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                        >
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.unCreatedDate.split(" ")[0]}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.stFirstName} {item.stLastName}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.stEmail}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.unCityId === 0 ? "" : item.unCityId}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.unCountryId}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.Role}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.dcStoneDisc || "0.00"}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.dcJewelleryDisc || "0.00"}
                          </td>
                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            -
                          </td>
                          {/* <td className="p-3 text-sm font-medium text-gray-800 ">
                            {item.flgIsActive === "1" ? (
                              <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                Y
                              </span>
                            ) : (
                              <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                N
                              </span>
                            )}
                          </td> */}

                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            <span
                              onClick={handleEditPermission.bind(null, item)}
                              className="cursor-pointer bg-[#c7d4ff] text-[#5265d0] px-3 py-[3px] font-Poppins leading-[10px] rounded"
                            >
                              A
                            </span>
                          </td>

                          <td className="p-3 text-sm font-medium text-gray-800 ">
                            <div className="flex items-center gap-[10px]">
                              <button
                                type="button"
                                onClick={handleEdit.bind(null, item)}
                              >
                                <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  handleDelete(item.unUserIdentifier)
                                }
                              >
                                <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                              </button>
                              <button type="button" onClick={() => {}}>
                                <AiOutlineEye className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                              </button>
                              {item.Role === "Agent" && (
                                <button
                                  type="button"
                                  onClick={openMargin.bind(null, item)}
                                >
                                  <HiOutlineCurrencyDollar className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />{" "}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : !loading && (
                      <tr>
                        <td colSpan={11}>
                          <NoDataText text={"No users found!"} />
                        </td>
                      </tr>
                    )}
                {loading && (
                  <tr>
                    <td colSpan={11} className="h-60">
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            totalItems={data ? data.user.length : 0}
            activePage={activePage}
            itemsPerPage={itemsPerPage}
            setActivePage={setActivePage}
          />
        </div>
      </main>
    </div>
  );
};

export default UserRole;
