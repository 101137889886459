import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import {
  fetchStoneSizeData,
  getStoneSizeState,
} from "../../store/Slices/stoneSizeSlice";
import SizeModal from "./Modals/SizeModal";
import { DeleteStoneSize } from "../../Services/stoneSizeService";
import swal from "sweetalert";
import { TiArrowBack } from "react-icons/ti";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { getAttributeValueByAttribute } from "../../Services/attributeValueService";
import usePagination from "../../Hooks/use-Pagination";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const StockSizeList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(getStoneSizeState);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data: sizeData,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchStoneSizeData());
  }, [dispatch]);

  useEffect(() => {
    const data = async () => {
      const res = await getAttributeValueByAttribute(2);
      if (res.status === 200) {
        setSelectedData(res.data.data.attribute_value);
        setSelected(res.data.data.attribute_value[0]);
      }
    };
    data();
  }, []);

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  useEffect(() => {
    if (data.length && selected) {
      const sizedata = data?.filter(
        (item) => item.unAttributeValueShapeId === selected.stAttributeValueName
      );
      setSizeData(sizedata);
    }
  }, [data, selected]);

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await DeleteStoneSize(id);
      if (res.status === 200) {
        if (res.status === 200) {
          toast.success(res.data.message);
          dispatch(fetchStoneSizeData());
        }
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <SizeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editId={editId}
        shapes={selected?.unAttributeValueIdentifier}
        setEditId={setEditId}
      />
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="flex flex-wrap items-center justify-between gap-[10px]">
            <Link
              to={"/admin/masterMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <div className="flex items-center gap-[10px]">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                  <Listbox.Button className="relative w-[200px] h-[38px] cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected
                        ? selected?.stAttributeValueName
                        : "Select Shape"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {selectedData?.length &&
                        selectedData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stAttributeValueName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              <button
                onClick={() => setIsOpen(true)}
                className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
              >
                <BsPlusLg className="text-[10px]" /> Add
              </button>
            </div>
          </div>
          <div className="sm:rounded-lg overflow-hidden">
            <div className="relative mt-4 overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-4 w-[20px]">
                      Sr.
                    </th>
                    <th scope="col" className="px-4 py-4 w-[100px]">
                      Shape
                    </th>
                    <th scope="col" className="px-4 py-4 min-w-[200px]">
                      Size
                    </th>
                    {selected?.stAttributeValueName === "Round" && (
                      <>
                        <th scope="col" className="px-4 py-4">
                          From MM
                        </th>
                        <th scope="col" className="px-4 py-4">
                          To MM
                        </th>
                        <th scope="col" className="px-4 py-4">
                          From Sieve
                        </th>
                        <th scope="col" className="px-4 py-4">
                          To Sieve
                        </th>
                      </>
                    )}
                    <th scope="col" className="px-4 py-4 w-[100px]">
                      Avg.Pcs/Cts
                    </th>
                    <th scope="col" className="px-4 py-4 w-[100px]">
                      Avg.Cts/Pcs
                    </th>
                    <th scope="col" className="px-4 py-4 text-center w-[60px]">
                      Y | N
                    </th>
                    <th scope="col" className="px-4 py-4 w-[100px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.inSortOrder}
                            </th>
                            <td className="px-4 py-3">
                              {item.unAttributeValueShapeId}
                            </td>
                            <td className="px-4 py-3">
                              {item.stStoneSizeName}
                            </td>
                            {selected?.stAttributeValueName === "Round" && (
                              <>
                                <td className="px-4 py-3">{item.dcFromMM}</td>
                                <td className="px-4 py-3">{item.dcToMM}</td>
                                <td className="px-4 py-3">
                                  {item.stFromSieve}
                                </td>
                                <td className="px-4 py-3">{item.stToSieve}</td>
                              </>
                            )}
                            <td className="px-4 py-3">{item.dcAvgPcs}</td>
                            <td className="px-4 py-3">{item.dcAvgCts}</td>
                            <td className="px-4 py-3 text-center">
                              {item.flgIsActive === "1" ? (
                                <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  Y
                                </span>
                              ) : (
                                <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  N
                                </span>
                              )}
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  onClick={handleEdit.bind(
                                    null,
                                    item.unStoneSizeIdentifier
                                  )}
                                  type="button"
                                >
                                  <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={handleDelete.bind(
                                    null,
                                    item.unStoneSizeIdentifier
                                  )}
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={11}>
                            <NoDataText text={"No Size found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={11} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={sizeData.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default StockSizeList;
