import React, { useState, useEffect, useMemo, Fragment } from "react";
import AdminModal from "./common/AdminModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BsCheckCircleFill } from "react-icons/bs";
import { FiLoader } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, openCart } from "../../store/Slices/cartSlice";
import {
  fetchStoneSizeData,
  getStoneSizeState,
} from "../../store/Slices/stoneSizeSlice";
import {
  fetchStoneColorData,
  getStoneColorState,
} from "../../store/Slices/stoneColorSlice";
import {
  fetchStoneClarityData,
  getStoneClarityState,
} from "../../store/Slices/stoneClaritySlice";
import TextInputAdmin from "./common/TextInputAdmin";
import SelectMenuTableView from "./common/SelectMenuTableView";
import {
  diamondStockUpdate,
  getDiamondStockById,
} from "../../Services/diamondService";
import { getAttributeValueByAttribute } from "../../Services/attributeValueService";
import { MdOutlineDeleteOutline } from "react-icons/md";
import ImageUpload from "../shared/ImageUpload";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pb: 2,
  border: "0px solid #000",
  outline: "none",
};

const labData = [
  {
    id: "IGI",
    title: "IGI",
  },
  {
    id: "GIA",
    title: "GIA",
  },
  {
    id: "Non Cert",
    title: "Non Cert",
  },
];

const cutData = [
  {
    id: "EX",
    title: "EX",
  },
  {
    id: "ID",
    title: "ID",
  },
  {
    id: "VG",
    title: "VG",
  },
  {
    id: "GD",
    title: "GD",
  },
];

const polishData = [
  {
    id: "EX",
    title: "EX",
  },
  {
    id: "ID",
    title: "ID",
  },
  {
    id: "VG",
    title: "VG",
  },
  {
    id: "GD",
    title: "GD",
  },
];

const symData = [
  {
    id: "EX",
    title: "EX",
  },
  {
    id: "ID",
    title: "ID",
  },
  {
    id: "VG",
    title: "VG",
  },
  {
    id: "GD",
    title: "GD",
  },
];

const flData = [
  {
    id: "None",
    title: "None",
  },
  {
    id: "Faint",
    title: "Faint",
  },
  {
    id: "Very Strong",
    title: "Very Strong",
  },
];

export default function BasicModal(props) {
  const dispatch = useDispatch();

  const { data: size, error } = useSelector(getStoneSizeState);
  const { data: color } = useSelector(getStoneColorState);
  const { data: clarity } = useSelector(getStoneClarityState);

  const { item, formMode, open, handleClose, parentState, DiamondStock, pageNumber } = props;

  // Image
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [filesImage, setFileImage] = useState();
  const [filesVideo, setFileVideo] = useState();
  const [uploadImage, setUploadImage] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  const [shapeData, setShapeData] = useState([]);

  const fetchMetalData = async () => {
    const resShape = await getAttributeValueByAttribute(2);
    setShapeData(resShape.data.data.attribute_value);
  };

  const ShapeOption = useMemo(() => {
    return (
      shapeData?.length > 0 &&
      shapeData?.map((item, i) => ({
        id: item.unAttributeValueIdentifier,
        title: item.stAttributeValueName,
      }))
    );
  }, [shapeData]);

  useEffect(() => {
    dispatch(fetchStoneSizeData());
    dispatch(fetchStoneColorData());
    dispatch(fetchStoneClarityData());
    fetchMetalData();
  }, [dispatch]);

  const [formData, setFormData] = useState({
    stockId: "",
    stockNumber: "",
    lab: "",
    certiNumner: "",
    shape: "",
    mm: "",
    colorId: "",
    clarityId: "",
    cut: "",
    polish: "",
    sym: "",
    fl: "",
    lratio: "",
    wratio: "",
    dratio: "",
    tablePer: "",
    depthPer: "",
    backPer: "",
    carat: "",
    costRate: "",
    costAmt: "",
    rap: "",
    saleRate: "",
    saleAmt: "",
    location: "",
    remark: "",
    isActive: "",
    showRate: "",
    imageUrl: "",
    videoUrl: "",
  });

  const {
    stockId,
    stockNumber,
    lab,
    certiNumner,
    shape,
    mm,
    colorId,
    clarityId,
    cut,
    polish,
    sym,
    fl,
    lratio,
    wratio,
    dratio,
    tablePer,
    depthPer,
    backPer,
    carat,
    costRate,
    costAmt,
    rap,
    saleRate,
    saleAmt,
    location,
    remark,
    isActive,
    showRate,
    imageUrl,
    videoUrl,
  } = formData;

  const getDiamondStockDetails = async (id) => {
    const resp = await getDiamondStockById(id);

    const itemData = resp?.data?.data?.StockDetail?.original?.[0];

    setFormData({
      stockId: itemData?.unStoneIdentifier || "",
      stockNumber: itemData?.inStoneNo || "",
      lab: itemData?.stLAB || "",
      certiNumner: itemData?.stCertiNo || "",
      shape: itemData?.unAttributeValueShapeId || "",
      mm: "",
      colorId: itemData?.unStoneColorId || "",
      clarityId: itemData?.unStoneClarityId || "",
      cut: itemData?.stCut || "",
      polish: itemData?.stPolish || "",
      sym: itemData?.stSym || "",
      fl: itemData?.stFL || "",
      lratio: itemData?.dcLength,
      wratio: itemData?.dcWidth,
      dratio: itemData?.dcDepth,
      tablePer: itemData?.dcTablePer,
      depthPer: itemData?.dcDepthPer,
      backPer: itemData?.dcBack || 0,
      carat: itemData?.dcCarat || 0,
      costRate:
        (itemData?.dcCostPrice
          ? itemData?.dcCostPrice
          : itemData?.dcCostAmount / itemData?.dcCarat) || 0,
      costAmt:
        (itemData?.dcCostAmount
          ? itemData?.dcCostAmount
          : itemData?.dcCarat * itemData?.dcCostPrice) || 0,
      rap: itemData?.dcRapPrice || "",
      saleRate: itemData?.dcPrice
        ? itemData?.dcPrice
        : ((itemData?.dcRapPrice * (100 - itemData?.dcBack)) / 100)?.toFixed(2),
      saleAmt: itemData?.dcAmount
        ? itemData?.dcAmount
        : (
            ((itemData?.dcRapPrice * (100 - itemData?.dcBack)) / 100) *
            itemData?.dcCarat
          )?.toFixed(2),
      location: itemData?.stLocation || "",
      remark: itemData?.stRemark,
      isActive: itemData?.flgIsAvailable ? true : false,
      showRate: itemData?.flgIsShowRate ? true : false,
      imageUrl: itemData?.stImageUrl || "",
      videoUrl: itemData?.stVideoUrl || "",
    });

    setFileImage(itemData?.stImageUrl);
    setFileVideo(itemData?.stVideoUrl);
  };

  useEffect(() => {
    if (formMode === "add") {
      setFormData({
        stockNumber: "",
        lab: "",
        certiNumner: "",
        shape: "",
        mm: "",
        colorId: "",
        clarityId: "",
        cut: "",
        polish: "",
        sym: "",
        fl: "",
        lratio: "",
        wratio: "",
        dratio: "",
        tablePer: "",
        depthPer: "",
        backPer: "",
        carat: "",
        costRate: "",
        costAmt: "",
        rap: "",
        saleRate: "",
        saleAmt: "",
        location: "",
        remark: "",
        isActive: "",
        showRate: "",
        imageUrl: "",
        videoUrl: "",
      });

      setAllImages([]);
      setAllVideos([]);
      setLoading(false);
      setFileImage();
      setFileVideo();
      setUploadImage(false);
      setUploadVideo(false);
      setIsLoading(false);
      setIsLoadingVideo(false);
    }
  }, [item]);

  useEffect(() => {
    if (item?.STOCKNO) getDiamondStockDetails(item?.STOCKNO);
  }, [item]);

  const handleChange = (key, event, directVal = false) => {
    if (key === "carat") {
      setFormData((prevState) => ({
        ...prevState,
        [key]: directVal ? event : event.target.value,
        saleAmt: saleRate * (directVal ? event : event.target.value),
      }));
    }
    if (key === "rap") {
      const calSaleRate = (
        (directVal ? event : event.target.value * (100 - (backPer || 0))) / 100
      ).toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        [key]: directVal ? event : event.target.value,
        saleRate: calSaleRate,
        saleAmt: calSaleRate * carat,
      }));
    }
    if (key === "backPer") {
      const calSaleRate = (
        (rap * (100 - (directVal ? event : event.target.value || 0))) /
        100
      ).toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        [key]: directVal ? event : event.target.value,
        saleRate: calSaleRate,
        saleAmt: calSaleRate * carat,
      }));
    }
    if (key === "saleRate") {
      const calBackPer = (
        (directVal ? event : event.target.value * 100) / (rap || 1)
      ).toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        [key]: directVal ? event : event.target.value,
        backPer: (100 - calBackPer) < 0 ? 0 : (100 - calBackPer),
        saleAmt: (directVal ? event : event.target.value) * carat,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [key]: directVal ? event : event.target.value,
      }));
    }
  };

  const sizeOption = useMemo(() => {
    const arr = [];
    size.length > 0 &&
      size.map((item, i) => {
        const data = {
          id: item.unStoneSizeIdentifier,
          shapeId: item.unAttributeValueShapeId,
          title: item.stStoneSizeName,
        };
        arr.push(data);
      });
    return arr;
  }, [size]);

  const sizeSelect = () => {
    const filterData =
      sizeOption.length > 0 &&
      sizeOption.filter((item) => item.shapeId === shape);
    return filterData || [];
  };

  const colorOption = useMemo(() => {
    const arr = [];
    color.length > 0 &&
      color.map((item, i) => {
        const data = {
          id: item.unStoneColorIdentifier,
          title: item.stStoneColorName,
        };
        arr.push(data);
      });
    return arr;
  }, [color]);

  const clarityOption = useMemo(() => {
    const arr = [];
    clarity.length > 0 &&
      clarity.map((item, i) => {
        const data = {
          id: item.unStoneClarityIdentifier,
          title: item.stStoneClarityName,
        };
        arr.push(data);
      });
    return arr;
  }, [clarity]);

  const handleSubmit = async () => {
    const payload = new FormData();

    setLoading(true);

    if (formMode == "add") {
      payload.append("unStoneIdentifier", "");
      payload.append("inStoneNo", stockNumber);
      payload.append("stLAB", lab);
      payload.append("stCertiNo", certiNumner);
      payload.append("unAttributeValueShapeId", shape);
      payload.append("unStoneColorId", colorId);
      payload.append("unStoneClarityId", clarityId);
      payload.append("stCut", cut);
      payload.append("stPolish", polish);
      payload.append("stSym", sym);
      payload.append("stFL", fl);
      payload.append("dcLength", lratio || 0);
      payload.append("dcWidth", wratio || 0);
      payload.append("dcDepth", dratio || 0);

      payload.append("dcTablePer", tablePer || 0);
      payload.append("dcDepthPer", depthPer || 0);
      payload.append("dcCarat", carat || 0);

      payload.append("dcCostPrice", costRate || 0);
      payload.append("dcCostAmount", costAmt || 0);

      payload.append("dcPrice", saleRate || 0);
      payload.append("dcAmount", saleAmt || 0);

      payload.append("dcRapPrice", rap || 0);
      payload.append("dcBack", backPer || 0);

      payload.append("stLocation", location);
      payload.append("stRemark", remark);

      payload.append("stImageUrl", imageUrl);
      payload.append("stVideoUrl", videoUrl);

      payload.append("flgIsShowRate", showRate ? 1 : 0);
      payload.append("flgIsAvailable", isActive ? 1 : 0);
    } else {
      payload.append("unStoneIdentifier", stockId || "");

      payload.append("stLAB", lab);
      payload.append("inStoneNo", stockNumber);
      payload.append("stCertiNo", certiNumner);
      payload.append("unAttributeValueShapeId", shape);
      payload.append("unStoneColorId", colorId);
      payload.append("unStoneClarityId", clarityId);

      payload.append("stCut", cut);
      payload.append("stPolish", polish);
      payload.append("stSym", sym);

      payload.append("dcCarat", carat || 0);

      payload.append("dcPrice", saleRate || 0);
      payload.append("dcAmount", saleAmt || 0);

      payload.append("dcRapPrice", rap || 0);
      payload.append("dcBack", backPer || 0);

      payload.append("stLocation", location);

      payload.append("stImageUrl", imageUrl);
      payload.append("stVideoUrl", videoUrl);

      payload.append("flgIsShowRate", showRate ? 1 : 0);
      payload.append("flgIsAvailable", isActive ? 1 : 0);
    }

    const resp = await diamondStockUpdate(payload);

    if (resp.data) {
      toast.success(
        formMode === "add" ? "Added Successfully." : "Updated Successfully."
      );
    }

    DiamondStock(pageNumber);

    handleClose();
    setLoading(false);
  };

  const checkValidation = useMemo(() => {
    let isSubmit = true;

    // Name validation
    if (
      !stockNumber ||
      !lab ||
      !certiNumner ||
      !shape ||
      !colorId ||
      !clarityId
    ) {
      isSubmit = false;
    }

    return isSubmit;
  }, [formData]);

  // Image
  const handleFile = (e) => {
    let file = e.target.files;
    const urlImage = URL.createObjectURL(file[0]);
    setFileImage(urlImage);
  };

  const handleImage = async () => {
    if (!allImages.length) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", allImages[0]);
    formData.append("upload_preset", "DTNUnsigned");
    await axios
      .post("https://api.cloudinary.com/v1_1/dbdpfhov6/image/upload", formData)
      .then((res) => {
        setFormData((prev) => ({ ...prev, imageUrl: res.data.secure_url }));
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const removeImage = (i) => {
    setFileImage();
    setAllImages([]);

    setFormData({ ...formData, imageUrl: "" });
  };

  const handleVideoFile = (e) => {
    let file = e.target.files;
    const urlImage = URL.createObjectURL(file[0]);

    if (file[0].type !== "video/mp4") {
      toast.error("Video type must be .mp4");
      return;
    } else if (file[0].size > 3072000) {
      toast.error("Video size must be less than 3.00 MB");
      return;
    }

    setFileVideo(urlImage);
  };

  const handleVideo = async () => {
    if (!allVideos.length) return;
    setIsLoadingVideo(true);
    const formData = new FormData();
    formData.append("file", allVideos[0]);
    formData.append("upload_preset", "DTNUnsigned");
    await axios
      .post("https://api.cloudinary.com/v1_1/dbdpfhov6/video/upload", formData)
      .then((res) => {
        setFormData((prev) => ({ ...prev, videoUrl: res.data.secure_url }));
        setIsLoadingVideo(false);
        setUploadVideo(true);
      });
  };

  const removeVideo = (i) => {
    setFileVideo();
    setAllVideos([]);

    setFormData({ ...formData, videoUrl: "" });
  };

  return (
    <div>
      <AdminModal
        title={formMode === "add" ? "Add Stock" : "Edit Stock"}
        onSubmit={handleSubmit}
        disableSubmit={!checkValidation}
        isOpen={open}
        onClose={() => handleClose()}
        submitButtonText={"Submit"}
        className="min-w-[50%]"
      >
        <div className="w-full">
          <div class="border p-4 mb-4 rounded-md">
            <div class="flex gap-2 mb-4 rounded-md">
              <div className="w-1/3">
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Stock Id.
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("stockNumber", e)}
                  className="rounded-none shadow-none text-xs"
                  name="stockno"
                  value={stockNumber}
                />
              </div>

              <div className="w-2/3">
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Certi No.
                  <span className="text-red-500 ml-1">*</span>
                </label>

                <div className="flex">
                  <select
                    id="small"
                    class="w-[150px] text-sm border bg-white outline-none"
                    value={lab}
                    onChange={(e) => handleChange("lab", e.target.value, true)}
                  >
                    <option selected value="">
                      Select LAB
                    </option>
                    {labData?.map((i) => {
                      return <option value={i?.id}>{i?.title}</option>;
                    })}
                  </select>

                  <TextInputAdmin
                    type="text"
                    onChange={(e) => handleChange("certiNumner", e)}
                    className="rounded-none shadow-none text-xs w-2/3"
                    name="certiNumber"
                    value={certiNumner}
                  />
                </div>
              </div>
            </div>

            <div class="grid gap-2 mb-4 md:grid-cols-3 rounded-md">
              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Shape
                  <span className="text-red-500 ml-1">*</span>
                </label>

                <select
                  id="small"
                  class="w-full h-[36px] text-sm border bg-white outline-none"
                  value={shape}
                  onChange={(e) => handleChange("shape", e.target.value, true)}
                >
                  <option selected value="">
                    Select Shape
                  </option>
                  {ShapeOption?.length &&
                    ShapeOption?.map((i) => {
                      return <option value={i?.id}>{i?.title}</option>;
                    })}
                </select>
              </div>

              {/* <div>
              <label
                for="last_name"
                class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
              >
                MM
                <span className="text-red-500 ml-1">*</span>
              </label>

              <Listbox
                value={mm}
                onChange={(val) => handleChange("mm", val, true)}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[36px] cursor-pointer bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-xs z-0">
                    <span className="block truncate text-[14px]">
                      {mm ? mm : "Select MM"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {sizeSelect()?.length &&
                        sizeSelect()?.map((item, index) => (
                          <Listbox.Option
                            key={item}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={
                              sizeSelect()?.find((i) => i?.id === item?.id)
                                ?.title
                            }
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {item?.title}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div> */}

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Color
                  <span className="text-red-500 ml-1">*</span>
                </label>

                <select
                  id="small"
                  class="w-full h-[36px] text-sm border bg-white outline-none"
                  value={colorId}
                  onChange={(e) =>
                    handleChange("colorId", e.target.value, true)
                  }
                >
                  <option selected value="">
                    Select Color
                  </option>
                  {colorOption?.map((i) => {
                    return <option value={i?.id}>{i?.title}</option>;
                  })}
                </select>
              </div>

              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Clarity
                  <span className="text-red-500 ml-1">*</span>
                </label>

                <select
                  id="small"
                  class="w-full h-[36px] text-sm border bg-white outline-none"
                  value={clarityId}
                  onChange={(e) =>
                    handleChange("clarityId", e.target.value, true)
                  }
                >
                  <option selected value="">
                    Select Clarity
                  </option>
                  {clarityOption?.map((i) => {
                    return <option value={i?.id}>{i?.title}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>

          <div class="grid gap-2 mb-4 md:grid-cols-4 border p-4 rounded-md">
            <div>
              <label
                for="stock_no"
                class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
              >
                Cut
              </label>

              <select
                id="small"
                class="w-full h-[36px] text-sm border bg-white outline-none"
                value={cut}
                onChange={(e) => handleChange("cut", e.target.value, true)}
              >
                <option selected value="">
                  Select Cut
                </option>
                {cutData?.map((i) => {
                  return <option value={i?.id}>{i?.title}</option>;
                })}
              </select>
            </div>

            <div>
              <label
                for="last_name"
                class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
              >
                Polish
              </label>
              <select
                id="small"
                class="w-full h-[36px] text-sm border bg-white outline-none"
                value={polish}
                onChange={(e) => handleChange("polish", e.target.value, true)}
              >
                <option selected value="">
                  Select Polish
                </option>
                {polishData?.map((i) => {
                  return <option value={i?.id}>{i?.title}</option>;
                })}
              </select>
            </div>

            <div>
              <label
                for="stock_no"
                class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
              >
                SYM
              </label>
              <select
                id="small"
                class="w-full h-[36px] text-sm border bg-white outline-none"
                value={sym}
                onChange={(e) => handleChange("sym", e.target.value, true)}
              >
                <option selected value="">
                  Select SYM
                </option>
                {symData?.map((i) => {
                  return <option value={i?.id}>{i?.title}</option>;
                })}
              </select>
            </div>

            <div>
              <label
                for="last_name"
                class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
              >
                FL
              </label>

              <select
                id="small"
                class="w-full h-[36px] text-sm border bg-white outline-none"
                value={fl}
                onChange={(e) => handleChange("fl", e.target.value, true)}
              >
                <option selected value="">
                  Select FL
                </option>
                {flData?.map((i) => {
                  return <option value={i?.id}>{i?.title}</option>;
                })}
              </select>
            </div>
          </div>

          <div class="border p-4 rounded-md">
            <div class="grid gap-2 mb-4 md:grid-cols-3">
              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  L/W/D Ratio
                </label>

                <div className="flex">
                  <TextInputAdmin
                    type="text"
                    onChange={(e) => handleChange("lratio", e)}
                    className="rounded-none shadow-none w-1/3 text-xs text-right"
                    name="lratio"
                    value={lratio}
                  />
                  <TextInputAdmin
                    type="text"
                    onChange={(e) => handleChange("wratio", e)}
                    className="rounded-none shadow-none w-1/3 text-xs text-right"
                    name="wratio"
                    value={wratio}
                  />
                  <TextInputAdmin
                    type="text"
                    onChange={(e) => handleChange("dratio", e)}
                    className="rounded-none shadow-none w-1/3 text-xs text-right"
                    name="dratio"
                    value={dratio}
                  />
                </div>
              </div>

              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Table %
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("tablePer", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="tablePer"
                  value={tablePer}
                />
              </div>

              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Depth %
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("depthPer", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="depthPer"
                  value={depthPer}
                />
              </div>
            </div>

            <div class="grid gap-2 mb-4 md:grid-cols-3">
              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Carat
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("carat", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="carat"
                  value={carat}
                />
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Cost Rate
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("costRate", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="costRate"
                  value={costRate}
                />
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Cost Amt.
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("costAmt", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="costAmt"
                  value={costAmt}
                />
              </div>
            </div>

            <div class="grid gap-2 mb-4 md:grid-cols-3">
              <div>
                <label
                  for="last_name"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Rap
                </label>

                <div className="flex">
                  <TextInputAdmin
                    type="number"
                    onChange={(e) => handleChange("rap", e)}
                    className="rounded-none shadow-none w-1/2 text-xs text-right"
                    name="rap"
                    value={rap}
                  />

                  <TextInputAdmin
                    type="number"
                    onChange={(e) => handleChange("backPer", e)}
                    className="rounded-none shadow-none w-1/2 text-xs text-right"
                    name="backPer"
                    value={backPer}
                  />
                </div>
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Sale Rate
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("saleRate", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="saleRate"
                  value={saleRate}
                />
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Sale Amt.
                </label>
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("saleAmt", e)}
                  className="rounded-none shadow-none text-xs text-right"
                  name="saleAmt"
                  value={saleAmt}
                />
              </div>
            </div>

            <div class="grid gap-2 mb-4 md:grid-cols-3">
              <div className="flex items-center">
                <div class="flex items-center">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value={isActive}
                    checked={isActive}
                    onChange={(e) => {
                      handleChange("isActive", e.target.checked, true);
                    }}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 text-xs"
                  />
                  <label
                    for="default-checkbox"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    ({`${isActive ? "Stock" : "Memo"}`})
                  </label>
                </div>
                <div class="flex items-center justify-around">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value={showRate}
                    checked={showRate}
                    onChange={(e) => {
                      handleChange("showRate", e.target.checked, true);
                    }}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 text-xs ml-6"
                  />
                  <label
                    for="default-checkbox"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Show Rate
                  </label>
                </div>
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Location
                </label>
                <TextInputAdmin
                  type="text"
                  onChange={(e) => handleChange("location", e)}
                  className="rounded-none shadow-none text-xs"
                  name="location"
                  value={location}
                />
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Remark
                </label>
                <TextInputAdmin
                  type="text"
                  onChange={(e) => handleChange("remark", e)}
                  className="rounded-none shadow-none text-xs"
                  name="remark"
                  value={remark}
                />
              </div>
            </div>

            <div class="grid gap-2 mb-4 md:grid-cols-2">
              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Image Url
                </label>
                <div className="flex items-center gap-[10px]">
                  <div className="flex">
                    <label
                      htmlFor="formFile"
                      className="border rounded-l-lg px-3 py-[9px] text-[13px] w-1/2"
                    >
                      Choose image
                    </label>
                    <input
                      type="text"
                      disabled
                      className="border rounded-r-lg py-2 px-2 text-[13px] w-1/2"
                      value={!!filesImage ? 1 + " " + "Files" : "Choose Image"}
                    />
                  </div>
                  <button
                    className="border rounded px-3 py-2 text-[13px]"
                    onClick={handleImage}
                  >
                    Upload
                  </button>
                  {isLoading && <FiLoader className="text-gray-600" />}
                  {uploadImage && (
                    <BsCheckCircleFill className="text-green-600" />
                  )}
                </div>
                <ImageUpload
                  className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                  id="formFile"
                  onChange={(e) => {
                    handleFile(e);
                    setAllImages(e.target.files);
                  }}
                />
                <div className="flex flex-wrap gap-2 mt-2">
                  {!!filesImage && (
                    <div className="overflow-hidden relative">
                      <GrFormClose
                        onClick={() => {
                          removeImage();
                        }}
                        className="absolute right-1 text-white cursor-pointer"
                      />
                      <img
                        className="h-20 w-20 rounded-md"
                        alt="filesImage"
                        src={filesImage}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label
                  for="stock_no"
                  class="block mb-1 text-xs font-medium text-gray-900 dark:text-white"
                >
                  Video Url
                </label>
                <div className="flex items-center gap-[10px]">
                  <div className="flex">
                    <label
                      htmlFor="formVideo"
                      className="border rounded-l-lg px-3 py-[9px] text-[13px] w-1/2"
                    >
                      Choose video
                    </label>
                    <input
                      type="text"
                      disabled
                      className="border rounded-r-lg py-2 px-2 text-[13px] w-1/2"
                      value={!!filesVideo ? 1 + " " + "Files" : "Choose Video"}
                    />
                  </div>
                  <button
                    className="border rounded px-3 py-2 text-[13px]"
                    onClick={handleVideo}
                  >
                    Upload
                  </button>
                  {isLoadingVideo && <FiLoader className="text-gray-600" />}
                  {uploadVideo && (
                    <BsCheckCircleFill className="text-green-600" />
                  )}
                </div>
                <ImageUpload
                  className="hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                  id="formVideo"
                  onChange={(e) => {
                    handleVideoFile(e);
                    setAllVideos(e.target.files);
                  }}
                  video={true}
                />
                <div className="flex flex-wrap gap-2 mt-2">
                  {!!filesVideo && (
                    <div className="overflow-hidden relative">
                      <GrFormClose
                        onClick={() => {
                          removeVideo();
                        }}
                        className="absolute right-1 text-white cursor-pointer z-50"
                      />
                      <video className="h-20 w-20 rounded-md" controls>
                        <source src={filesVideo} />
                      </video>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminModal>
    </div>
  );
}
