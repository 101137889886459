import React from "react";
import { getDesignDataState } from "../../../store/Slices/designSlice";
import { useSelector } from "react-redux";
// import ProductCard from "../Common/ProductCard";
import StyleProductCard from "../Common/StyleProductCard";
import ProductCardSkeleton from "../Common/skeleton/ProductCardSkeleton";
import { useMemo } from "react";
import { getReadyToShipDataState } from "../../../store/Slices/readyToShipSlice";

const StylesProductList = () => {
  const { data, loading, error } = useSelector(getDesignDataState);
  const { searchedText } = useSelector((state) => state.designData);

  const filterData = useMemo(() => {
    return data?.length > 0 && data.filter((item, i) => item.flgIsActive === 1);
  }, [data]);

  return (
    <>
      {loading ? (
        <div className=" grid lg:grid-cols-4 md:grid-cols-3	sm:grid-cols-2 grid-cols-2 sm:gap-4 gap-2 mt-4">
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center text-slate-400 text-base italic mt-5">
          No Data
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="product_list font-Josefin grid lg:grid-cols-8 md:grid-cols-6	sm:grid-cols-4 grid-cols-4 sm:gap-4 gap-2 mt-4">
              {filterData.map(
                (
                  {
                    stDefImageURL: mainImage,
                    unDesignIdentifier: id,
                    stDesignName: name,
                    stFocusImageURL: hoverImage,
                    dcSalePrice: price,
                    stDesignAlias: alias,
                    ...rest
                  },
                  index
                ) => {

                  if (
                    searchedText &&
                    !alias?.toLowerCase()?.includes(searchedText?.toLowerCase())
                  )
                    return;

                  return (
                    <StyleProductCard
                      key={index}
                      id={id}
                      title={name}
                      mainImage={mainImage}
                      hoverImage={hoverImage}
                      price={price}
                      alias={alias}
                      data={rest}
                    />
                  );
                }
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center text-slate-400 text-base italic ">
              coming soon
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StylesProductList;
