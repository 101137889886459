import React from "react";
import Skeleton from "react-loading-skeleton";

const DiamondCardSkeleton = () => {
  return (
    <div className="bg-white p-4 pt-2 rounded-md">
      <div className="flex flex-col gap-2 h-full">
        <div className="flex justify-end items-center gap-2 ">
          <div className="w-8 h-8 ">
            <Skeleton height={"100%"} />
          </div>
          <div className="w-8 h-8 ">
            <Skeleton height={"100%"} />
          </div>
        </div>
        <div className="h-80">
          <Skeleton height={"100%"} />
        </div>
        <div className="pb-4 border-b border-gray-300">
          <div className="flex justify-start items-center gap-1 ">
            <Skeleton width={50} height={20} />
            <Skeleton width={50} height={20} />
            <Skeleton width={50} height={20} />
            <Skeleton width={50} height={20} />
          </div>
        </div>
        <div className="flex justify-between items-start">
          <div>
            <div className="">
              <Skeleton width={100} height={15} />
            </div>
            <div>
              <Skeleton width={75} height={15} />
            </div>
          </div>
          <div>
            <div className="">
              <Skeleton width={100} height={15} />
            </div>
            <div>
              <Skeleton width={75} height={15} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiamondCardSkeleton;
