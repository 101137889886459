import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "./Pagination";
import {
  fetchSupplier,
  getSupplierDataState,
} from "../../store/Slices/supplierSlice";
import AddSupplierModal from "./Modals/AddSupplierModal";
import { deleteSuppliers } from "../../Services/supplierService";
import { TiArrowBack } from "react-icons/ti";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import usePagination from "../../Hooks/use-Pagination";
import Loader from "./common/Loader";
import NoDataText from "../shared/NoDataText";

const SupplierList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(getSupplierDataState);

  const { currentData, activePage, setActivePage, itemsPerPage } =
    usePagination({
      data,
      itemsPerPage: 10,
    });

  useEffect(() => {
    dispatch(fetchSupplier());
  }, [dispatch]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsOpen(true);
  };

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });
    if (willDelete) {
      const res = await deleteSuppliers(id);
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(fetchSupplier());
      } else {
        toast.error(res.data.message);
      }
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-[10px]">
            <Link
              to={"/admin/masterMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>
            <button
              onClick={() => setIsOpen(true)}
              className="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[9px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] "
            >
              <BsPlusLg className="text-[10px]" /> Add
            </button>
            <AddSupplierModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              editId={editId}
              setEditId={setEditId}
            />
          </div>
          <div className="shadow-md sm:rounded-lg mt-4 overflow-hidden">
            <div
              className={`relative overflow-x-auto bg-white ${
                data?.length ? "min-h-[15rem]" : ""
              } `}
            >
              <table
                className={`w-full text-sm text-left text-gray-500 dark:text-gray-400 `}
              >
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 max-w-[75px]">
                      Sort No.
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Supplier Name
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Supplier Alias
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Owner Name
                    </th>
                    <th scope="col" className="px-4 py-3 max-w-[200px]">
                      Address
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Phone
                    </th>
                    <th scope="col" className="px-4 py-3 text-center">
                      Y|N
                    </th>
                    <th scope="col" className="px-4 py-3 w-[100px]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && currentData.length
                    ? currentData.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.inSortOrder}
                            </th>
                            <td className="px-6 py-3">{item.stAccountName}</td>
                            <td className="px-6 py-3">{item.stAccountAlias}</td>
                            <td className="px-6 py-3">{item.stOwnerPerson}</td>
                            <td className="px-6 py-3">{item.stAddress}</td>
                            <td className="px-6 py-3">{item.stPhoneNo}</td>
                            <td className="px-6 py-3 w-[100px] text-center">
                              {item.flgIsActive === "1" ? (
                                <span className="bg-[#ecfccb] text-[#365314] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  Y
                                </span>
                              ) : (
                                <span className="bg-[#fecaca] text-[#7f1d1d] px-3 py-[3px] font-Poppins leading-[10px] rounded">
                                  N
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-3 ">
                              <div className="flex items-center gap-[10px]">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleEdit(item.unAccountIdentifier)
                                  }
                                >
                                  <BiEdit className="text-[20px] text-[#8D8D8D] bg-[#E5E7EB] p-[2px] rounded" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDelete(item.unAccountIdentifier)
                                  }
                                >
                                  <AiOutlineDelete className="text-[20px] text-[#E90B0B] bg-[#fee2e2] p-[2px] rounded" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={8}>
                            <NoDataText text={"No Supplier found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={8} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {!currentData?.length && (
              <div className=" min-h-[15rem] bg-[#FFFFFF] flex justify-center items-center ">
                <span>No data</span>
              </div>
            )}
            <Pagination
              totalItems={data.length}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SupplierList;
