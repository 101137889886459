import React from "react";
import Banner from "../Components/LandingComponent/Banner";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import ProductList from "../Components/LandingComponent/ProductList";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryStyle from "../Components/LandingComponent/CategoryStyle";
import { useEffect } from "react";
import ProductListByCategoryStyle from "./ProductListByCategoryStyle";
import Breadcrumb from "../Components/LandingComponent/Common/Breadcrumb";
import { useMemo } from "react";
import { FaHome } from "react-icons/fa";
import { transformCapitalize } from "../Utils";

const Product = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  const linkTab = useMemo(() => {
    if (state?.subCategory) {
      return [
        {
          title: (
            <span className="hover:underline inline-flex justify-start items-center gap-1">
              {" "} Home{" "}
            </span>
          ),
          onClick: () => {
            navigate("/jewelry");
          },
        },
        {
          title: "Customize Jewelry",
          onClick: () => {
            navigate("/jewelry");
          },
        },
        {
          title: transformCapitalize(state.subCategory.category.name),
          onClick: () => {
            navigate("/product", {
              state: { category: state.subCategory.category },
            });
          },
        },
        {
          title: transformCapitalize(state.subCategory.name),
        },
      ];
    }
    return [
      {
        title: (
          <span className="hover:underline inline-flex justify-start items-center gap-1">
            {" "} Home{" "}
          </span>
        ),
        onClick: () => {
          navigate("/jewelry");
        },
      },
      {
        title: transformCapitalize(state.category.name),
      },
    ];
  }, [state, navigate]);
  return (
    <div>
      <Header />
      <div className="products max-w-[1224px] mx-auto sm:p-2 p-4 pt-0 xl:p-0 xl:mb-[15px] lg:mb-[15px] md:mb-[15px] sm:mb-[15px]">
      <div className="my-4 px-6">
        <Breadcrumb tabs={linkTab} />
      </div>
      {state?.category ? (
        <>
          <CategoryStyle
            category={state?.category?.name}
            ctId={state?.category?.id}
          />
        </>
      ) : state?.subCategory ? null : (
        <Banner className="xl:mb-[28px] lg:mb-[20px] mb-[15px]" />
      )}
      <div>
        {state?.subCategory ? (
          <ProductListByCategoryStyle />
        ) : (
          <div className="products max-w-[1224px] mx-auto sm:p-2 lg:p-4 xl:p-0 xl:mb-[15px] lg:mb-[15px] md:mb-[15px] sm:mb-[15px]">
            {/* <div className="filters items-center justify-start gap-5 mb-[36px]">
              <span>Filter: </span>
              <span>Style</span>
              <span>Shape</span>
              <span>Metal</span>
            </div> */}
            <ProductList />
          </div>
        )}
      </div>

      {/* <ViewedProduct title={"Previously Viewed"} /> */}
      {/* <NewBanner />+ */}
      {/* <PopulareProduct /> */}
      </div>
      <Footer />
    </div>
  );
};

export default Product;
