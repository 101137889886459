import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import UploadSideStonePrice from "./Modals/UploadSideStonePrice";
import TextInputAdmin from "../Admin/common/TextInputAdmin";
import { fetchMetalData } from "../../store/Slices/metalPriceSlice";
import { updateMetals } from "../../Services/metalPriceService";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import NoDataText from "../shared/NoDataText";
import Loader from "./common/Loader";

const MetalPriceList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.metalPriceData);
  const [globalPrice, setGlobalPrice] = useState();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    dispatch(fetchMetalData());
  }, [dispatch]);

  useEffect(() => {
    if (data?.length) {
      setDataList(data);
    }
  }, [data]);

  const handleUpdate = async () => {
    setUpdateLoading(true);

    let updatedData = dataList?.map((item) => ({
      ...item,
      dcPrice: (+item?.dcTouch * globalPrice) / 100,
    }));

    const payload = new FormData();
    payload.append("json", JSON.stringify(updatedData));
    const resp = await updateMetals(payload);

    if (resp?.data) {
      toast.success(resp?.data?.message);
    }

    setUpdateLoading(false);
  };

  const handlePriceChange = () => {
    let updatedList = dataList?.map((item) => ({
      ...item,
      dcPrice: ((+item?.dcTouch * globalPrice) / 100).toFixed(2),
    }));

    setDataList(updatedList);
  };

  useEffect(() => {
    handlePriceChange();
  }, [globalPrice]);

  const handlePrice = (key, value, index) => {
    if (key === "dcTouch") {
      setDataList(
        dataList?.map((obj, i) =>
          i === index
            ? {
                ...obj,
                [key]: value,
                dcPrice: ((value * globalPrice) / 100).toFixed(2),
              }
            : obj
        )
      );
    } else {
      setDataList(
        dataList?.map((obj, i) =>
          i === index ? { ...obj, [key]: value } : obj
        )
      );
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full h-[90vh] bg-gray-100">
      <UploadSideStonePrice isOpen={isOpen} setIsOpen={setIsOpen} />
      <main className="h-full overflow-y-auto">
        <div className="px-6 pb-6 mx-auto">
          <div className="flex items-center justify-between gap-[10px] mt-[1px]">
            <Link
              to={"/admin/utilityMenu"}
              className="rounded-md text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-gray-50"
            >
              <TiArrowBack className="text-[18px]" /> Back
            </Link>

            <div className="flex gap-1">
              <TextInputAdmin
                type="number"
                onChange={(e) => setGlobalPrice(e.target.value)}
                className="ring-1 border"
                name="price"
                value={globalPrice}
                placeholder="Enter 24K Price USD($)"
              />
              <button
                onClick={() => handleUpdate()}
                className={`bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] ${
                  updateLoading && "opacity-50"
                }`}
              >
                Update
              </button>
            </div>
          </div>

          <div className="sm:rounded-lg relative mt-4 rounded shadow-md">
            <div className={`relative overflow-x-auto bg-white`}>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3 max-w-[75px]">
                      Sr No.
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Metal (Gold / Silver / Platinum)
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Purity
                    </th>
                    <th scope="col" className="px-4 py-3 ">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && dataList?.length
                    ? dataList?.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className="bg-white text-xs border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                          >
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {i + 1}
                            </th>
                            <td className="px-6 py-3">
                              {item.stAttributeValueName}
                            </td>
                            <td className="px-6 py-3">
                              <TextInputAdmin
                                type="number"
                                onChange={(e) =>
                                  handlePrice("dcTouch", e.target.value, i)
                                }
                                className="ring-1 border"
                                name="dcTouch"
                                value={dataList?.[i]?.dcTouch}
                                placeholder="Clarity"
                              />
                            </td>
                            <td className="px-6 py-3">
                              <TextInputAdmin
                                type="number"
                                onChange={(e) =>
                                  handlePrice("dcPrice", e.target.value, i)
                                }
                                className="ring-1 border"
                                name="dcPrice"
                                value={dataList?.[i]?.dcPrice}
                                placeholder="Price"
                              />
                            </td>
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={7}>
                            <NoDataText text={"No data found!"} />
                          </td>
                        </tr>
                      )}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="h-60">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MetalPriceList;
