import { Slider as RangeSlider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import shap1 from "../../../assets/images/shap1.png";
import shap2 from "../../../assets/images/shap2.png";
import shap3 from "../../../assets/images/shap3.png";
import shap4 from "../../../assets/images/shap4.png";
import shap5 from "../../../assets/images/shap5.png";
import shap6 from "../../../assets/images/shap6.png";
import shap7 from "../../../assets/images/shap7.png";
import shap8 from "../../../assets/images/shap8.jpg";
import TableRow from "../TableRow";
import Slider from "react-slick";
import {
  getDiamondStockWithoutRateMarginJewelry,
  getDiamondStockFilterParameter,
} from "../../../Services/diamondService";
import Loader from "../../Admin/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import {
  getSelectedDiamondState,
  selectDiamond,
} from "../../../store/Slices/diamondSelectSlice";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import useApi from "../../../Hooks/use-Api";
import { sortByID, tawkWidgetToggle } from "../../../Utils";
import { numberRegexWithDecimals } from "../../shared/constants";
import { BiArrowBack } from "react-icons/bi";
import DiamondFilter from "./DiamondFilter";
import { getAuthState } from "../../../store/Slices/AuthSlice";
function valuetext(value) {
  return `${value}°C`;
}

const DiamondLists = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [value, setValue] = useState([0, 8]);
  const [price, setPrice] = useState([0, 10000]);
  const [caratValue, setCaratValue] = useState({
    fromCarat: "",
    toCarat: "",
  });
  const [priceValue, setPriceValue] = useState({
    fromPrice: "",
    toPrice: "",
  });
  const [filterValue, setFilterValue] = useState(null);
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timer = useRef(true);
  const { user } = useSelector(getAuthState);

  const [fetchFilterParameters, filterParameters] = useApi(
    getDiamondStockFilterParameter
  );

  useEffect(() => {
    fetchFilterParameters();
  }, [fetchFilterParameters]);

  useEffect(() => {
    if (filterParameters) {
      // setPrice([
      //   +filterParameters.amount[0].ID,
      //   +filterParameters.amount[0].Name,
      // ]);
      // setValue([filterParameters.carat[0].ID, filterParameters.carat[0].Name]);
      // setPageNumber(1);
      // setPriceValue({
      //   fromPrice: filterParameters.amount[0].ID,
      //   toPrice: filterParameters.amount[0].Name,
      // });
    }
  }, [filterParameters]);

  const caratData = useMemo(() => {
    const selectDiamond = state?.productData?.designAttribute?.find(
      (item) => item?.flgIsSelectDiamond === 1 || item?.flgIsSelectDiamond === "1"
    );
    const selectDiamondValue = state?.productData?.designAttributeValue?.filter(
      (item) => item?.stAttributeName === selectDiamond?.stAttributeName
    );
    const filter = selectDiamondValue?.find(
      (item) =>
        item?.stAttributeValueName ===
        state?.attrData[selectDiamond?.stAttributeName]?.name
    );
    return filter;
  }, [state]);

  // useEffect(() => {
  //   if (caratData) {
  //     setCaratValue({
  //       fromCarat: caratData?.FromCarat,
  //       toCarat: caratData?.ToCarat,
  //     });
  //   }
  // }, [caratData]);

  useEffect(() => {
    const attribute = state?.productData?.designAttribute?.find(
      (item) => item?.stAttributeName === "Center Stone Shape"
      );
      if (attribute) {
      setFilterValue((prev) => ({
        ...prev,
        shape: state?.attrData[attribute?.stAttributeName],
        fromCarat: caratData?.FromCarat || state.centerStoneRange.dcFromCarat || "",
        toCarat: caratData?.ToCarat || state.centerStoneRange.dcToCarat || "",
      }));
    }
  }, [state, caratData]);

  const observer = useRef();
  const dummyRef = useRef();
  const lastDiamondElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries?.[0]?.isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const DiamondStock = useCallback(
    async (page) => {
      if (page === 1) {
        setStockData([]);
      }
      if (filterValue) {
        const Data = new FormData();
        Data.append("ShapeId", filterValue?.shape?.id || "");
        Data.append("ColorId", filterValue?.color?.ID || "");
        Data.append("ClarityId", filterValue?.clarity?.ID || "");
        Data.append("Cut", filterValue?.cut?.ID || "");
        Data.append("symmetry", filterValue?.sym?.ID || "");
        Data.append("polish", filterValue?.polish?.ID || "");
        Data.append(
          "FromCarat",
          Number(filterValue?.fromCarat) || ""
        );
        Data.append(
          "ToCarat",
          Number(filterValue?.toCarat) || ""
        );
        Data.append("fromPrice", filterValue?.fromPrice || "");
        Data.append("toPrice", filterValue?.toPrice || "");
        Data.append("UserId", user?.unUserIdentifier || "");
        Data.append("inPageNo", page);
        Data.append("inPageSize", 10);
        setLoading(true);
        try {
          const response = await getDiamondStockWithoutRateMarginJewelry(Data);
          if (response.data.success) {
            setHasMore(!(response.data.data.stock?.length < 10));
            setStockData((prev) => [...prev, ...response.data.data.stock]);
          }
        } catch (err) {
          toast.error("Something went wrong.");
        } finally {
          setLoading(false);
        }
      }
    },
    [filterValue, caratValue]
  );

  // useEffect(() => {
  //   fetchFilterParameters();
  // }, [fetchFilterParameters]);

  useEffect(() => {
    DiamondStock(pageNumber);
  }, [DiamondStock]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePrice = (event, newValue) => {
    setPrice(newValue);
  };

  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

  const toggleAccordion = (index) => {
    if (openAccordionIndex === index) {
      setOpenAccordionIndex(-1);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  const handleChangeShape = (name, value, valId) => {
    setPageNumber(1);
    setFilterValue((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeFilter = (name, val) => {
    setPageNumber(1);
    setFilterValue((prev) => ({
      ...prev,
      [name]: prev[name]?.ID === val.ID ? {} : val,
    }));
  };

  const handleBack = () => {
    navigate(state.prevPath?.includes('mounting') ? state.prevPath : `/productdetail/${state?.product}`, {
      state: {
        attrData: {
          ...state.attrData,
          // "Center Stone Shape": filterValue?.shape,
        },
        prevPath: state?.prevPath,
      },
    });
  };

  const handleChangeClearFilter = () => {
    setFilterValue((prev) => ({
      ...prev,
      ColorId: "",
      ClarityId: "",
      Cut: null,
      cut: null,
      clarity: null,
      color: null,
      toPrice: "",
      fromPrice: "",
      fromCarat: "",
      toCarat: "",
      polish: null,
      sym: null,
    }));
    setPageNumber(1);
    setCaratValue({
      fromCarat: "",
      toCarat: "",
    });
    setPriceValue({
      fromPrice: "",
      toPrice: "",
    });
  };

  const handleChangeFilterCaratPrice = (type, e) => {
    const { name, value } = e.target;

    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (numberRegexWithDecimals.test(value)) {
      if (type === "carat") {
        setCaratValue((prev) => ({ ...prev, [name]: value }));
      } else {
        setPriceValue((prev) => ({ ...prev, [name]: value }));
      }
      timer.current = setTimeout(() => {
        setPageNumber(1);
        setFilterValue((prev) => ({ ...prev, [name]: value }));
      }, 700);
    }
  };

  const handleChangeSliderVal = (name, val) => {
    setPageNumber(1);
    if (name === "carat") {
      // setValue(val)
      setFilterValue((prev) => ({
        ...prev,
        fromCarat: val[0],
        toCarat: val[1],
      }));
    } else {
      setFilterValue((prev) => ({
        ...prev,
        fromPrice: val[0],
        toPrice: val[1],
      }));
      // setPrice(val)
    }
  };

  const handleSelectDiamond = (diamond) => {
    const cartData = {
      ...diamond,
      productId: state?.product,
    };
    dispatch(selectDiamond(cartData));
    navigate((state.prevPath?.includes('mounting') ? state.prevPath : `/productdetail/${state?.product}`), {
      state: {
        attrData: {
          ...state.attrData,
          "Center Stone Shape": filterValue?.shape,
        },
        metalPurity: state.metalPurity,
        prevPath: state.prevPath,
      },
    });
  };

  const attrData = state?.productData?.designAttribute?.length
    ? state?.productData?.designAttribute?.find(
      (item) => item.stAttributeName === "Center Stone Shape"
    )
    : null;

  const shapeValData = attrData
    ? state?.productData?.designAttributeValue.filter(
      (attrVal) => attrVal.unAttributeId === attrData.unAttributeId
    )
    : [];

  const hideChat = useCallback((hide) => {
    if (hide) {
      tawkWidgetToggle(false);
    } else {
      tawkWidgetToggle(true);
    }
  }, []);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  const cutData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.cut, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const polishData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.polish, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const symData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.sym, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  const colorData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.color, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);
  const clarityData = useMemo(() => {
    if (filterParameters) {
      return sortByID(filterParameters.clarity, "asc", "SrNo");
    }
    return [];
  }, [filterParameters]);

  return (
    <div className="mt-5">
      <div className="xl:max-w-[1224px] max-w-auto xl:px-0 px-4 mx-auto">
        <button
          class="bg-white rounded text-[14px] border-[1px] border-[#cbd5e1] py-[8px] px-4 font-[600] text-[#1e293b] leading-4 flex justify-center items-center gap-1 hover:bg-[#f8fafc] mb-3"
          onClick={handleBack}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            version="1.2"
            baseProfile="tiny"
            viewBox="0 0 24 24"
            class="text-[18px]"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 9.059v-2.559c0-.256-.098-.512-.293-.708-.195-.195-.451-.292-.707-.292s-.512.097-.707.292l-6.293 6.208 6.293 6.207c.195.195.451.293.707.293s.512-.098.707-.293.293-.452.293-.707v-2.489c2.75.068 5.755.566 8 3.989v-1c0-4.633-3.5-8.443-8-8.941z"></path>
          </svg>{" "}
          Back
        </button>
        <div className="flex items-start md:flex-row flex-col gap-[30px]">
          <div className="lg:w-[30%] md:w-[40%] w-full sm:block hidden">
            <h3 className="text-[15px] uppercase leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px]">
              Shape:{" "}
              <span className="text-[14px] text-gray-500 font-[400]">
                {filterValue?.shape?.name}
              </span>
            </h3>
            {/* <div className="flex items-center lg:justify-start justify-center md:gap-[10px] gap-[0px] mb-[20px]"> */}
            {shapeValData?.length > 0 && (
              <div className="mb-4">
                {shapeValData.length >= 5 ? (
                  <Slider {...settings}>
                    {shapeValData.map((itm, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={handleChangeShape.bind(
                            null,
                            "shape",
                            itm.stAttributeValueName,
                            itm?.unAttributeValueId
                          )}
                          className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${filterValue?.shape?.name ===
                            itm.stAttributeValueName
                            ? "bg-[#f1f5f9]"
                            : ""
                            }`}
                        >
                          <img
                            src={itm.stImageURL}
                            alt="shap1"
                            className="m-auto h-[30px]"
                          />
                        </button>
                      );
                    })}
                  </Slider>
                ) : (
                  <div className="flex items-center gap-1">
                    {shapeValData.map((itm, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={handleChangeShape.bind(
                            null,
                            "shape",
                            itm.stAttributeValueName,
                            itm?.unAttributeValueId
                          )}
                          className={`py-2 px-2 w-[50px] h-[50px] mx-[2px] border-2 rounded shadow-lg border-[#cbd5e1] ${filterValue?.shape?.name ===
                            itm.stAttributeValueName
                            ? "bg-[#f1f5f9]"
                            : ""
                            }`}
                        >
                          <img
                            src={itm.stImageURL}
                            alt="shap1"
                            className="m-auto h-[30px]"
                          />
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {/* <Slider className="mb-[20px]" {...settings}>
            </Slider> */}
            {/* </div> */}
            <div className="flex items-center lg:justify-start mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] w-1/4">
                Carat
              </h3>
              <div className="w-3/4 max-w-[300px]">
                {/* <div className="flex items-center justify-between">
                <span className="text-[14px] font-Josefin">{value[0]}ct</span>
                <span className="text-[14px] font-Josefin">{value[1]}ct</span>
              </div> */}
                <div className="flex gap-4 items-center">
                  <div className="flex gap-2 items-center">
                    <h4 className="text-sm font-semibold">From:</h4>
                    <div>
                      <input
                        type="text"
                        name="fromCarat"
                        className="w-12 px-2 py-1 text-xs outline-none border-2 border-gray-300 rounded"
                        onChange={handleChangeFilterCaratPrice.bind(
                          null,
                          "carat"
                        )}
                        disabled
                        value={Number(caratData?.FromCarat || state.centerStoneRange.dcFromCarat)}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <h4 className="text-sm font-semibold">to:</h4>
                    <div>
                      <input
                        type="text"
                        name="toCarat"
                        className="w-12 px-2 py-1 text-xs outline-none border-2 border-gray-300 rounded"
                        onChange={handleChangeFilterCaratPrice.bind(
                          null,
                          "carat"
                        )}
                        disabled
                        value={Number(caratData?.ToCarat || state.centerStoneRange.dcToCarat)}
                      />
                    </div>
                  </div>
                </div>
                {/* <RangeSlider
                getAriaLabel={() => "Temperature range"}
                value={value}
                // color="#475569"
                onChange={handleChange}
                onChangeCommitted={(e, val) =>
                  handleChangeSliderVal("carat", val)
                }
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={+filterParameters?.carat?.[0]?.Name || 8}
                min={+filterParameters?.carat?.[0]?.ID || 0}
                step={0.01}
                sx={{
                  "& .MuiSlider-thumb": {
                    color: "#475569",
                  },
                  "& .MuiSlider-track": {
                    color: "#475569",
                  },
                  "& .MuiSlider-rail": {
                    color: "#acc4e4",
                  },
                  "& .MuiSlider-active": {
                    color: "green",
                  },
                }}
              /> */}
              </div>
            </div>
            <div className="flex items-start lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Colour
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {colorData.map((color, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "color", color)}
                      className={classNames(
                        " relative font-openSans text-xs outline-none px-3 py-1 rounded border-gray-300 border-2 sm:hover:border-gray-500 hover:border-2",
                        {
                          "border-gray-500 border-2":
                            filterValue?.color?.ID === color.ID,
                        }
                      )}
                    >
                      {color.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-start lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Clarity
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {clarityData.map((clarity, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(
                        null,
                        "clarity",
                        clarity
                      )}
                      className={classNames(
                        " relative font-openSans text-xs outline-none px-3 py-1 rounded border-gray-300 border-2 sm:hover:border-gray-500 hover:border-2",
                        {
                          "border-gray-500 border-2":
                            filterValue?.clarity?.ID === clarity.ID,
                        }
                      )}
                    >
                      {clarity.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-start lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Cut
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {cutData.map((cut, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "cut", cut)}
                      className={classNames(
                        " relative font-openSans text-xs outline-none px-3 py-1 rounded border-gray-300 border-2 sm:hover:border-gray-500 hover:border-2",
                        {
                          "border-gray-500 border-2":
                            filterValue?.cut?.Name === cut.Name,
                        }
                      )}
                    >
                      {cut.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-start lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Polish
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {polishData.map((polish, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "polish", polish)}
                      className={classNames(
                        " relative font-openSans text-xs outline-none px-3 py-1 rounded border-gray-300 border-2 sm:hover:border-gray-500 hover:border-2",
                        {
                          "border-gray-500 border-2":
                            filterValue?.polish?.Name === polish.Name,
                        }
                      )}
                    >
                      {polish.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-start lg:justify-start justify-center mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                SYM
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-2 w-3/4">
                {symData.map((sym, index) => {
                  return (
                    <button
                      key={index}
                      onClick={handleChangeFilter.bind(null, "sym", sym)}
                      className={classNames(
                        " relative font-openSans text-xs outline-none px-3 py-1 rounded border-gray-300 border-2 sm:hover:border-gray-500 hover:border-2",
                        {
                          "border-gray-500 border-2":
                            filterValue?.sym?.Name === sym.Name,
                        }
                      )}
                    >
                      {sym.Name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center lg:justify-start mb-[20px] gap-[30px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4">
                Price
              </h3>
              <div className="w-3/4 max-w-[300px]">
                {/* <div className="flex items-center justify-between">
                <span className="text-[14px] font-Josefin">${price[0]}</span>
                <span className="text-[14px] font-Josefin">${price[1]}</span>
              </div> */}
                {/* <ThemeProvider  muiTheme={muiTheme}> */}
                <div className="flex gap-4 items-center">
                  <div className="flex gap-2 items-center">
                    <h4 className="text-sm font-semibold">From:</h4>
                    <div>
                      <input
                        type="text"
                        name="fromPrice"
                        className="w-12 px-2 py-1 text-xs outline-none border-2 border-gray-300 rounded"
                        onChange={handleChangeFilterCaratPrice.bind(
                          null,
                          "price"
                        )}
                        value={priceValue?.fromPrice}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <h4 className="text-sm font-semibold">to:</h4>
                    <div>
                      <input
                        type="text"
                        name="toPrice"
                        className="w-12 px-2 py-1 text-xs outline-none border-2 border-gray-300 rounded"
                        onChange={handleChangeFilterCaratPrice.bind(
                          null,
                          "price"
                        )}
                        value={priceValue?.toPrice}
                      />
                    </div>
                  </div>
                </div>
                {/* <RangeSlider
                getAriaLabel={() => "Temperature range"}
                value={price}
                onChange={handleChangePrice}
                onChangeCommitted={(e, val) =>
                  handleChangeSliderVal("price", val)
                }
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={+filterParameters?.amount?.[0]?.ID || 0}
                max={filterParameters?.amount?.[0]?.Name || 10000}
                sx={{
                  "& .MuiSlider-thumb": {
                    color: "#475569",
                  },
                  "& .MuiSlider-track": {
                    color: "#475569",
                  },
                  "& .MuiSlider-rail": {
                    color: "#acc4e4",
                  },
                  "& .MuiSlider-active": {
                    color: "green",
                  },
                }}
              /> */}
                {/* </ThemeProvider> */}
              </div>
            </div>
            <div className="flex items-center gap-[30px] mt-0.5 mb-[10px]">
              <h3 className="text-base uppercase font-[500] font-Josefin text-[#000] mb-[4px] w-1/4 opacity-0">
                Price
              </h3>
              <div className="w-3/4 ml-auto">
                <button
                  type="button"
                  onClick={handleChangeClearFilter}
                  className="font-Josefin font-[600] border px-6 py-2 border-gray-300 rounder w-full"
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
          <div className="sm:hidden block">
            <DiamondFilter
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setPageNumber={setPageNumber}
              loading={loading}
              hideChat={hideChat}
              carat={[caratValue?.fromCarat, caratValue?.toCarat]}
              shape={shapeValData}
              filterParameters={filterParameters}
            />
          </div>
          <div className="lg:w-[70%] w-full diamondTable">
            <div className="text-center mb-4">
              <h2 className="text-[20px] font-[600] font-Josefin">
                Select Your Diamond
              </h2>
              {/* <h2 className="font-Josefin">
                Need help?{" "}
                <button className="underline text-[#516868]">
                  Take our quiz
                </button>
              </h2> */}
            </div>
            <div>
              <table className="w-full relative">
                <thead className="sticky top-0">
                  <tr className="bg-gray-200">
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] text-left uppercase">
                      Shape
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      Carat
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      Color
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      Clarity
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      cut
                    </th>
                    {/* <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      price
                    </th> */}
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center">
                      Amount
                    </th>
                    <th className="md:py-3 md:px-4 py-2 px-1 md:text-[14px] text-[12px] uppercase text-center"></th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  {stockData.map((item, index) => (
                    <TableRow
                      key={index}
                      data={item}
                      handleSelectDiamond={handleSelectDiamond.bind(null, item)}
                      ref={
                        stockData?.length === index + 1
                          ? lastDiamondElementRef
                          : dummyRef
                      }
                      isOpen={openAccordionIndex === index}
                      toggleAccordion={() => toggleAccordion(index)}
                    />
                  ))}
                  {stockData?.length === 0 && !loading && (
                    <tr>
                      <td colSpan="7">
                        <div className="w-full text-center text-slate-400">
                          No data
                        </div>
                      </td>
                    </tr>
                  )}
                  {loading && (
                    <tr>
                      <td colSpan="7">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiamondLists;
