import React from 'react'
import MenuCard from '../common/MenuCard';
import Menu from '../../../assets/images/Menu.png'


const ToolsMenu = () => {
  const commonMasterList = [
      {
        title: "Menu",
        icon: Menu,
        link: "/admin/menu"
      },
    ];
    
  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-4">
          {commonMasterList.map((item, index) => {
            return (
              <MenuCard title={item.title} key={index} link={item.link} icon={item.icon} />
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default ToolsMenu