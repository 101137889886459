import React, { Fragment } from 'react'
import AdminModal from '../common/AdminModal'
import TextInputAdmin from '../common/TextInputAdmin'
import { useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { getAttributeValueByAttribute } from '../../../Services/attributeValueService'
import { getStoneColor } from '../../../Services/stoneColorService'
import { getStoneClarity } from '../../../Services/stoneClarityService'
import { useEffect } from 'react'

const AddNewDemand = ({ isOpen, setIsOpen, handleAddDemand }) => {
  const [formData, setFormData] = useState({
    CUTPOLISHSYM: "",
    AMOUNT: "",
    MEASUREMENT: "",
    CERTID: "",
    LAB: ""
  })
  const [selectedShape, setSelectedShape] = useState(null)
  const [shapeOptions, setShapeOptions] = useState([])
  const [selectedColor, setSelectedColor] = useState(null)
  const [colorOptions, setColorOptions] = useState([])
  const [selectedClarity, setSelectedClarity] = useState(null)
  const [clarityOptions, setClarityOptions] = useState([])

  useEffect(() => {
    if (isOpen) {
      fetchShapeOptions()
      fetchColorOptions()
      fetchClarityOptions()
    }
  }, [isOpen])

  const handleSubmit = () => {
    const finalData = {
      ...formData,
      SHAPE: selectedShape?.shapeName || "",
      COLOUR: selectedColor?.colorName || "",
      CLARITY: selectedClarity?.clarityName || "",
    }
    handleAddDemand(finalData)
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
    setFormData({
      CUTPOLISHSYM: "",
      AMOUNT: "",
      MEASUREMENT: "",
      CERTID: "",
      LAB: ""
    })
    setSelectedClarity(null)
    setSelectedColor(null)
    setSelectedShape(null)
  }

  const handleChangeData = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const fetchShapeOptions = async () => {
    try {
      const response = await getAttributeValueByAttribute(2)
      if (response.status === 200) {
        const options = response.data.data.attribute_value.map(item => {
          return {
            id: item.unAttributeValueIdentifier,
            shapeName: item.stAttributeValueName
          }
        })
        setShapeOptions(options)
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  }
  const fetchColorOptions = async () => {
    try {
      const response = await getStoneColor()
      if (response.status === 200) {
        const options = response.data.data.color.map(item => {
          return {
            id: item.unStoneColorIdentifier,
            colorName: item.stStoneColorName
          }
        })
        setColorOptions(options)
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  }
  const fetchClarityOptions = async () => {
    try {
      const response = await getStoneClarity()
      if (response.status === 200) {
        const options = response.data.data.clarity.map(item => {
          return {
            id: item.unStoneClarityIdentifier,
            clarityName: item.stStoneClarityName
          }
        })
        setClarityOptions(options)
      }
    } catch (error) {
      console.log("Error: ", error?.response?.data?.message);
    }
  }

  return (
    <AdminModal
      title={"Add Demand"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={handleClose}
      submitButtonText={"Add"}
    >
      <div className=" grid gap-x-4 gap-y-0 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="shape"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Shape
          </label>
          <Listbox value={selectedShape} onChange={setSelectedShape}>
            <div className="relative">
              <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                <span className="block truncate text-[14px]">
                  {selectedShape ? selectedShape?.shapeName : "Select Shape"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {!!shapeOptions?.length ?
                    shapeOptions?.map((shape, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                          }`
                        }
                        value={shape}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? "font-medium" : "font-normal"
                                }`}
                            >
                              {shape.shapeName}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )) : (
                      <div className="text-center w-full">No data</div>
                    )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="color"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Colour
          </label>
          <Listbox value={selectedColor} onChange={setSelectedColor}>
            <div className="relative">
              <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                <span className="block truncate text-[14px]">
                  {selectedColor ? selectedColor?.colorName : "Select Category"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {!!colorOptions?.length ?
                    colorOptions?.map((color, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                          }`
                        }
                        value={color}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? "font-medium" : "font-normal"
                                }`}
                            >
                              {color.colorName}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )) : (
                      <div className="text-center w-full">No data</div>
                    )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="lab"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Lab
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="lab"
            value={formData.LAB || ""}
            type="text"
            name="LAB"
            placeholder="Enter Lab"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="purity"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Purity
          </label>
          <Listbox value={selectedClarity} onChange={setSelectedClarity}>
            <div className="relative">
              <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                <span className="block truncate text-[14px]">
                  {selectedClarity ? selectedClarity?.clarityName : "Select Category"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {!!clarityOptions?.length ?
                    clarityOptions?.map((clarity, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                          }`
                        }
                        value={clarity}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? "font-medium" : "font-normal"
                                }`}
                            >
                              {clarity.clarityName}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )) : (
                      <div className="text-center w-full">No data</div>
                    )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="cut"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Cut/Sym/Polish
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="cut"
            value={formData.CUTPOLISHSYM || ""}
            type="text"
            name="CUTPOLISHSYM"
            placeholder="Cut/Sym/Polish"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="total"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            USD Ttl
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="total"
            type="text"
            name="AMOUNT"
            value={formData.AMOUNT || ""}
            placeholder="Enter USD Ttl"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="localCr"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Local Currency
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="localCr"
            type="text"
            name="localCr"
            placeholder="Enter Local Currency"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="measurement"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Measurement
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="measurement"
            type="text"
            name="MEASUREMENT"
            value={formData.MEASUREMENT}
            placeholder="Enter Measurement"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="cert"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            Cert.No.
          </label>
          <TextInputAdmin
            onChange={handleChangeData}
            id="cert"
            type="text"
            name="CERTID"
            value={formData.CERTID}
            placeholder="Cert. No."
          />
        </div>
      </div>
    </AdminModal>
  )
}

export default AddNewDemand