import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authLogin, getAuthState } from "../../store/Slices/AuthSlice";
import { emailRegex } from "../shared/constants";
import FormError from "../shared/FormError";
import { useThunk } from "../../Hooks/use-thunk";
import Loader from "./common/Loader";
import { HiHome, HiKey  } from "react-icons/hi";

const ForgotPassword = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [passType, setPassType] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { isLoggedIn, user } = useSelector(getAuthState);

  const [loginUser, isLoading, loginError] = useThunk(authLogin);

  useEffect(() => {
    if (isLoggedIn) {
      if (state?.path) {
        navigate(state?.path);
      } else {
        if (user && user.Role === "Admin") {
          navigate("/admin");
        } else {
          navigate("/");
        }
      }
    }
  }, [isLoggedIn, navigate, state?.path, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      if (value) {
        if (emailRegex.test(value)) {
          setError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setError((prev) => ({
            ...prev,
            [name]: "Please Enter valid email.",
          }));
        }
      } else {
        setError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    } else {
      if (value) {
        setError((prev) => ({ ...prev, [name]: "" }));
      } else {
        setError((prev) => ({
          ...prev,
          [name]: "This field is required.",
        }));
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let isValidate = false;
    if (Object.values(error).join("")) {
      return;
    }

    if (!loginData.email) {
      isValidate = false;
      setError((prev) => ({ ...prev, email: "This field is required." }));
    } else {
      isValidate = true;
    }

    if (!loginData.password) {
      isValidate = false;
      setError((prev) => ({ ...prev, password: "This field is required." }));
    } else {
      isValidate = true;
    }

    if (!isValidate) {
      return;
    }

    const payload = new FormData();
    payload.append("stEmail", loginData.email);
    payload.append("stPassword", loginData.password);
    loginUser(payload);
  };

  const handleNavigateRegister = () => {
    if (state?.path) {
      navigate("/register", { state: { path: state?.path } });
    } else {
      navigate("/register");
    }
  };

  return (
    <div className="h-[100vh] flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-[#f9fafb]">
      {isLoading && (
        <div className="fixed z-50 inset-0 bg-[#ffffffa9] flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div className="w-full max-w-md ">
        <div className="text-center">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="text-center bg-slate-200 p-3.5 rounded-full inline-block"
          >
            <HiKey className="text-[#295268] text-[20px]" />
          </button>
          <h2 className="mt-1 text-center text-[22px] font-bold tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        <form
          className="mt-6 bg-white p-6 rounded-[10px] shadow-lg"
          onSubmit={handleLogin}
        >
          {loginError && (
            <div className="rounded mb-3 border border-red-400 bg-red-100 text-red-600 italic text-sm p-2">
              Incorrect email or password.
            </div>
          )}
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="mb-4">
              <label htmlFor="email-address" className="text-[14px] font-bold">
                Enter your account's verified email address and we will send you
                a password. <span className="text-red-600">*</span>
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                className="block w-full outline-none mt-2 border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 sm:text-sm"
                placeholder="Email address"
                onChange={handleChange}
              />
              {error.email && <FormError>{error.email}</FormError>}
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div className="text-sm">
              <span
                className="font-medium cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Back to login
              </span>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center border border-transparent bg-[#295268] py-2 px-4 text-sm font-medium text-white focus:outline-none mt-[14px]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
