import React, { useState, useEffect, Fragment, useMemo } from "react";
import { getCategoryDataState } from "../../../store/Slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryStylebyCategoryId,
  getCategoryStylesOfStockById,
} from "../../../Services/categoryStyleService";
import { numberRegexWithDecimals } from "../../shared/constants";
import { Slider } from "@mui/material";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Tab,
  Transition,
  DialogBackdrop,
  DialogPanel,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useApi from "../../../Hooks/use-Api";
import { useThunk } from "../../../Hooks/use-thunk";
import {
  fetchDesignByCategoryId,
  fetchDesignByCategoryStyleId,
  fetchDesigns,
  getDesignDataState,
} from "../../../store/Slices/designSlice";
import {
  fetchReadyToShipJStockListV2,
} from "../../../store/Slices/readyToShipSlice";
import { getReadyToShipFilterList } from "../../../Services/categoryService";
import { transformCapitalize } from "../../../Utils";
import {
  getCustomizeJewelryData,
  setCustomizeJewelryAns,
} from "../../../store/Slices/customizeJewelrySlice";
import { GrFormClose } from "react-icons/gr";
import { fetchReadyToShip } from "../../../store/Slices/readyToShipSlice";
import DownArrow from "../../../assets/images/icons/down_arrow_black.png";

function valuetext(value) {
  return `${value}°C`;
}

const ReadyToShipProductFilter = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryStyle, setSelectedCategoryStyle] = useState(null);
  const [productFilterList, setProductFilterList] = useState([]);
  const [filteredParameter, setFilteredParameter] = useState([]);

  const dispatch = useDispatch();
  const { data } = useSelector(getCategoryDataState);

  const { answers } = useSelector(getCustomizeJewelryData);
  const [selctedTab, setSelectedTab] = useState("");
  const { loading: designLoading, error: designError } =
    useSelector(getDesignDataState);

  const [fetchDesignData] = useThunk(fetchDesigns);
  const [fetchReadyToShipData] = useThunk(fetchReadyToShip);
  const [fetchDesignDataByCategory] = useThunk(fetchDesignByCategoryId);
  const [fetchDesignsJStockListV2Filter] = useThunk(fetchReadyToShipJStockListV2);
  const [fetchDesignDataByCategoryStyle] = useThunk(
    fetchDesignByCategoryStyleId
  );
  const [fetchcategoryStyles, categoryStyleData, loading, error] = useApi(
    getCategoryStylebyCategoryId
  );

  const [
    fetchcategoryStylesOfStock,
    categoryStyleOfStockData,
    categoryStyleOfStockLoading,
    categoryStyleOfStockError,
  ] = useApi(getCategoryStylesOfStockById);

  useEffect(() => {
    if (answers.categoryStyle || answers.category) {
      setSelectedCategoryStyle(answers.categoryStyle);
      setSelectedCategory(answers.category);
    }
  }, [answers]);

  // Product Filter List
  const getProductFilterListData = async () => {
    const res = await getReadyToShipFilterList();
    setProductFilterList(res?.data?.data);
  };

  useEffect(() => {
    getProductFilterListData();
  }, []);

  const handleFilteredParameter = (section, data) => {
    let updatedOptionsList;

    if (section?.id === "price") {
      updatedOptionsList = filteredParameter
        .find((item) => item.id === section.id)
        .options.map((d) =>
          d.value === data.value
            ? { ...d, checked: !d.checked }
            : { ...d, checked: false }
        );
    } else {
      updatedOptionsList = filteredParameter
        .find((item) => item.id === section.id)
        .options.map((d) =>
          d.value === data.value ? { ...d, checked: !d.checked } : d
        );
    }

    let updatedList = filteredParameter.map((item) =>
      item.id === section.id ? { ...item, options: updatedOptionsList } : item
    );
    setFilteredParameter(updatedList);
  };

  useEffect(() => {
    if (productFilterList?.length) {
      let filterList = [];

      let filterDetails = productFilterList?.find(
        (item) => item?.MenuID === selectedCategory?.id
      );

      if (filterDetails?.Styles?.length) {
        filterList.push({
          id: "styles",
          name: "Styles",
          options:
            filterDetails?.Styles?.map((item) => ({
              value: item?.unCategoryStyleIdentifier,
              label: item.stCategoryStyleName,
              checked: false,
            })) || [],
        });
      }

      if (filterDetails?.Ocussion?.length) {
        filterList.push({
          id: "occasions",
          name: "Occasions",
          options:
            filterDetails?.Ocussion?.map((item) => ({
              value: item?.unTagIdentifier,
              label: item.stTagName,
              checked: false,
            })) || [],
        });
      }

      if (filterDetails?.Shape?.length) {
        filterList.push({
          id: "metal",
          name: "Metal",
          options:
            filterDetails?.Shape?.map((item) => ({
              value: item?.unAttributeValueIdentifier,
              label: item.stAttributeValueName,
              checked: false,
            })) || [],
        });
      }

      if (filterDetails?.Metal?.length) {
        filterList.push({
          id: "shape",
          name: "Shape",
          options:
            filterDetails?.Metal?.map((item) => ({
              value: item?.unAttributeValueIdentifier,
              label: item.stAttributeValueName,
              checked: false,
            })) || [],
        });
      }

      if (filterDetails?.Prices?.length) {
        filterList.push({
          id: "price",
          name: "Price",
          options:
            filterDetails?.Prices?.map((item) => ({
              value: `${item?.FromPrice}-${item?.ToPrice}`,
              label: `${item?.FromPrice}-${item?.ToPrice}`,
              checked: false,
            })) || [],
        });
      }

      setFilteredParameter(filterList);
    }
  }, [productFilterList, selectedCategory]);

  useEffect(() => {
    if (filteredParameter?.length) {
      let priceFilter = filteredParameter
        ?.find((i) => i.id === "price")
        ?.options?.filter((i) => i.checked)
        ?.map((d) => d.value)?.[0];

      fetchDesignsJStockListV2Filter({
        categoryId: selectedCategory?.id,
        categoryStyleId: filteredParameter
          ?.find((i) => i.id === "styles")
          ?.options?.filter((i) => i.checked)
          ?.map((d) => d.value),
        occasionIds: filteredParameter
          ?.find((i) => i.id === "occasions")
          ?.options?.filter((i) => i.checked)
          ?.map((d) => d.value),
        stoneId: filteredParameter
          ?.find((i) => i.id === "shape")
          ?.options?.filter((i) => i.checked)
          ?.map((d) => d.value),
        metalToneId: filteredParameter
          ?.find((i) => i.id === "metal")
          ?.options?.filter((i) => i.checked)
          ?.map((d) => d.value),
        fromPrice: +priceFilter?.split("-")?.[0] || "",
        toPrice: +priceFilter?.split("-")?.[1] || "",
      });
    }

    // Clear filter
    if (
      filteredParameter?.flatMap((i) => i.options)?.every((i) => !i.checked) &&
      selectedCategory?.id
    ) {
      // fetchDesignDataByCategory(selectedCategory?.id);
      fetchReadyToShipData({ CategoryID: selectedCategory.id });
    }
  }, [filteredParameter]);

  // useEffect(() => {
  //   if (selectedCategory) {
  //     fetchcategoryStyles(selectedCategory.id);
  //   }
  // }, [selectedCategory, fetchcategoryStyles]);

  useEffect(() => {
    if (selectedCategory) {
      fetchcategoryStylesOfStock(selectedCategory.id);
    }
  }, [selectedCategory, fetchcategoryStylesOfStock]);

  useEffect(() => {
    if (selectedCategoryStyle) {
      fetchReadyToShipData({ CategoryStyleID: selectedCategoryStyle.id });
      // fetchDesignDataByCategoryStyle(selectedCategoryStyle.id);
    } else if (selectedCategory) {
      if (selectedCategory?.id) {
        fetchReadyToShipData({ CategoryID: selectedCategory?.id });
      } else {
        fetchReadyToShipData();
      }
    } else {
      // fetchReadyToShipData();
    }
  }, [
    fetchReadyToShipData,
    selectedCategory,
    selectedCategoryStyle,
    fetchDesignDataByCategory,
    fetchDesignDataByCategoryStyle,
  ]);

  const categoryOptions = useMemo(() => {
    return data.map((item) => ({
      name: item.stCategoryName,
      id: item.unCategoryIdentifier,
    }));
  }, [data]);

  const categoryStyleOptions = useMemo(() => {
    if (categoryStyleData && selectedCategory) {
      return categoryStyleData.categories_style.map((item) => ({
        name: item.stCategoryStyleName,
        id: item.unCategoryStyleIdentifier,
      }));
    }
    return [];
  }, [categoryStyleData, selectedCategory]);

  const categoryStyleOfStockOptions = useMemo(() => {
    if (categoryStyleOfStockData && selectedCategory) {
      return categoryStyleOfStockData.map((item) => ({
        name: item.stCategoryStyleName,
        id: item.unCategoryStyleIdentifier,
      }));
    }
    return [];
  }, [categoryStyleOfStockData, selectedCategory]);

  const setCategoryAns = (value) => {
    if (answers.category) {
      dispatch(
        setCustomizeJewelryAns({
          category: value,
        })
      );
    }
  };

  const setCategoryStyleAns = (value) => {
    if (answers.categoryStyle) {
      dispatch(
        setCustomizeJewelryAns({
          categoryStyle: value,
        })
      );
    }
  };

  const handleChangeFilter = (name, value) => {
    if (name === "category") {
      setSelectedCategoryStyle(null);
      setSelectedCategory((prev) => (prev?.id === value?.id ? null : value));
      setCategoryAns(selectedCategory?.id === value.id ? null : value);
    }
    if (name === "categoryStyle") {
      setSelectedCategoryStyle((prev) =>
        prev?.id === value?.id ? null : value
      );
      setCategoryStyleAns(
        selectedCategoryStyle?.id === value.id ? null : value
      );
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <>
      {/* <h1 className="font-bold md:text-2xl text-lg my-8">Filter</h1> */}
      {/* <div className="flex items-center gap-2 mt-8"> */}
      {/* <div className="">
          <button
            type="button"
            onClick={() =>
              setSelectedTab((prev) => (prev === "category" ? "" : "category"))
            }
            className={`font-semibold text-[14px] border px-2 flex items-center gap-2 ${
              selectedCategory ? "border-slate-600" : "border-slate-300"
            }`}
          >
            {selectedCategory ? selectedCategory.name : "Category"}
            {selectedCategory && (
              <GrFormClose
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategory(null);
                  setSelectedCategoryStyle(null);
                  setCategoryAns(null);
                  setCategoryStyleAns(null);
                }}
                className="text-[20px] "
              />
            )}
          </button>
        </div> */}
      {/* <div className="">
          <button
            type="button"
            onClick={() =>
              setSelectedTab((prev) => (prev === "style" ? "" : "style"))
            }
            className={`font-semibold text-[14px] border px-2 flex items-center gap-2 ${
              selectedCategoryStyle ? "border-slate-600" : "border-slate-300"
            }`}
          >
            {selectedCategoryStyle ? selectedCategoryStyle.name : "Styles"}
            {selectedCategoryStyle && (
              <GrFormClose
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategoryStyle(null);
                  setCategoryStyleAns(null);
                }}
                className="text-[20px] "
              />
            )}
          </button>
        </div> */}
      {/* <div className="flex justify-start gap-2">
          <h3 className="font-semibold md:text-base text-sm">Price:</h3>
          <div className="w-full mx-8">
            <Slider
              value={price}
              onChange={(e, val) => {
                setPrice(val);
              }}
              onChangeCommitted={(e, val) => {
                setFinalPrice(val);
              }}
              getAriaValueText={valuetext}
              min={0}
              max={100000}
              step={0.01}
              sx={{
                "& .MuiSlider-thumb": {
                  color: "#475569",
                },
                "& .MuiSlider-track": {
                  color: "#475569",
                },
                "& .MuiSlider-rail": {
                  color: "#acc4e4",
                },
                "& .MuiSlider-active": {
                  color: "green",
                },
              }}
            />
            <div className="flex justify-between items-center">
              <input
                type="text"
                name="fromCarat"
                id="fromCarat"
                value={price[0]}
                onChange={(e) => {
                  const { value: val } = e.target;
                  if (numberRegexWithDecimals.test(val)) {
                    if (parseInt(val.split(".").join(""))) {
                      setFinalPrice(val);
                    }
                    setPrice((prev) => [val, prev[1]]);
                  }
                }}
                className="min-w-[48px] max-w-[100px] w-auto rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
              />
              <input
                type="text"
                name="toCarat"
                id="toCarat"
                value={price[1]}
                onChange={(e) => {
                  const { value: val } = e.target;
                  if (numberRegexWithDecimals.test(val)) {
                    if (parseInt(val.split(".").join(""))) {
                      setFinalPrice(val);
                    }
                    setPrice((prev) => [prev[0], val]);
                  }
                }}
                className="min-w-[48px] max-w-[100px] w-auto rounded text-sm outline-none border-2 border-gray-400 px-2 py-0.5"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start gap-2">
          <h3 className="font-semibold md:text-base text-sm">Metal:</h3>
        </div> */}
      {/* </div> */}
      {selctedTab === "category" && (
        <div className="flex justify-start self-start gap-2 flex-wrap mt-5">
          {categoryOptions.map((item, index) => {
            return (
              <button
                disabled={designLoading}
                key={index}
                className={`flex gap-1 justify-center capitalize items-center min-w-[80px] sm:p-2 p-1 font-semibold font-openSans sm:text-[13px] text-xs outline-none border-b-2 ${
                  selectedCategory?.id === item?.id
                    ? "border-gray-700"
                    : "border-transparent"
                } sm:hover:border-gray-700`}
                onClick={() => {
                  handleChangeFilter("category", item);
                }}
              >
                {transformCapitalize(item.name)}
              </button>
            );
          })}
        </div>
      )}

      {/* {!!answers.category?.id && !!categoryStyleOptions.length && (
        <div className="flex justify-start self-start gap-2 flex-wrap mt-2">
          {categoryStyleOptions.map((item, index) => {
            return (
              <button
                disabled={designLoading}
                key={index}
                className={`flex gap-1 justify-center capitalize items-center min-w-[80px] sm:p-2 p-1 font-semibold font-openSans sm:text-sm text-xs outline-none border-b-2 ${
                  selectedCategoryStyle?.id === item?.id
                    ? "border-gray-700"
                    : "border-transparent"
                } sm:hover:border-gray-700`}
                onClick={() => {
                  handleChangeFilter("categoryStyle", item);
                }}
              >
                {transformCapitalize(item.name)}
              </button>
            );
          })}
        </div>
      )} */}

      {/* Product Filter */}

      {selectedCategory && filteredParameter?.length ? (
        <div>
          <div>
            <div>
              {/* Mobile filter dialog */}
              <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-40 sm:hidden"
                  onClose={setMobileFiltersOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-40 flex">
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                        <div className="flex items-center justify-between px-4">
                          <h2 className="text-lg font-medium text-gray-900">
                            Filters
                          </h2>
                          <button
                            type="button"
                            className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                            onClick={() => setMobileFiltersOpen(false)}
                          >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>

                        {/* Filters */}
                        <form className="mt-4">
                          {filteredParameter.map((section) => (
                            <Disclosure
                              as="div"
                              key={section.name}
                              className="border-t border-gray-200 px-4 py-6"
                            >
                              {({ open }) => (
                                <>
                                  <h3 className="-mx-2 -my-3 flow-root">
                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                      <span className="font-medium text-gray-900">
                                        {section.name}
                                      </span>
                                      <span className="ml-6 flex items-center">
                                        <ChevronDownIcon
                                          className={classNames(
                                            open ? "-rotate-180" : "rotate-0",
                                            "h-5 w-5 transform"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Disclosure.Button>
                                  </h3>
                                  <Disclosure.Panel className="pt-6">
                                    <div className="space-y-6">
                                      {section.options.map(
                                        (option, optionIdx) => (
                                          <div
                                            key={option.value}
                                            className="flex items-center"
                                          >
                                            {/* <input
                                              id={`filter-mobile-${section.id}-${optionIdx}`}
                                              name={`${section.id}[]`}
                                              defaultValue={option.value}
                                              type="checkbox"
                                              defaultChecked={option.checked}
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                              className="ml-3 text-sm text-gray-500"
                                            >
                                              {option.label}
                                            </label> */}

                                            <label
                                              onClick={() =>
                                                handleFilteredParameter(
                                                  section,
                                                  option
                                                )
                                              }
                                              class={`text-sm outline-none border-b border-b-transparent xs:hover:border-b-gray-400 cursor-pointer ${
                                                option.checked && "font-medium"
                                              }`}
                                            >
                                              {option.label}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>

              <main>
                {/* Filters */}
                <section aria-labelledby="filter-heading">
                  <h2 id="filter-heading" className="sr-only">
                    Filters
                  </h2>

                  <div>
                    <div className="flex flex-col items-center">
                      {/* <button
                        type="button"
                        className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                        onClick={() => setMobileFiltersOpen(true)}
                      >
                        Filters
                      </button> */}

                      <div className="hidden">
                        <div className="flow-root">
                          <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                            {filteredParameter.map((section, sectionIdx) => (
                              <Popover
                                key={section.name}
                                className="relative inline-block px-4 text-left"
                              >
                                <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 outline-none">
                                  <span>{section.name}</span>
                                  {/* {sectionIdx === 0 ? (
                                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                      1
                                    </span>
                                  ) : null} */}
                                  <ChevronDownIcon
                                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="absolute z-20 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <form className="space-y-4">
                                      {section.options.map(
                                        (option, optionIdx) => {
                                          return (
                                            <div
                                              key={option.value}
                                              className="w-[135px] flex items-center"
                                            >
                                              {/* <input
                                              id={`filter-${section.id}-${optionIdx}`}
                                              name={`${section.id}[]`}
                                              defaultValue={option.value}
                                              type="checkbox"
                                              defaultChecked={option.checked}
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            /> */}
                                              {/* <label
                                              htmlFor={`filter-${section.id}-${optionIdx}`}
                                              className={`ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900`}
                                            >
                                              {option.label}
                                            </label> */}

                                              <label
                                                class={`text-sm outline-none border-b border-b-transparent sm:hover:border-b-gray-400 cursor-pointer ${
                                                  option.checked &&
                                                  "font-medium"
                                                }`}
                                                onClick={() =>
                                                  handleFilteredParameter(
                                                    section,
                                                    option
                                                  )
                                                }
                                              >
                                                {option.label}
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </form>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            ))}
                          </Popover.Group>
                        </div>
                      </div>

                      {filteredParameter.map((section, sectionIdx) => (
                        <Disclosure
                          as="div"
                          key={section.id}
                          className="w-full border-b border-gray-200 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-my-3 flow-root">
                                <DisclosureButton className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                  <span className="text-[#121212] text-xl font-[500]">
                                    {section.name}
                                    <hr class="w-[50px] h-[3px] border-0 rounded bg-[#295268] mt-2"></hr>
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    <img
                                      src={DownArrow}
                                      className={`h-5 w-5 ${
                                        open && "rotate-180"
                                      }`}
                                    />
                                  </span>
                                </DisclosureButton>
                              </h3>
                              <DisclosurePanel className="pt-6">
                                <ul className="space-y-3">
                                  {section.options.map((option) => (
                                    <li
                                      key={option.value}
                                      className="border-b border-[#BABABA] border-dashed px-0 pb-3 pt-0"
                                    >
                                      <div
                                        class="flex items-center gap-x-2"
                                        onClick={() =>
                                          handleFilteredParameter(
                                            section,
                                            option
                                          )
                                        }
                                      >
                                        <input
                                          checked={option.checked}
                                          id="checked-checkbox"
                                          type="checkbox"
                                          value=""
                                          class="w-4 h-4 mt-[2px] text-[#295268] bg-gray-100 border-gray-300 rounded cursor-pointer accent-[#334155]"
                                        />

                                        <div
                                          className={`font-[500] text-sm ${
                                            option.checked
                                              ? "text-[#295268]"
                                              : "text-[#6A6A6A]"
                                          } cursor-pointer`}
                                        >
                                          {option.label}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </DisclosurePanel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </div>

                  {/* Active filters */}
                  {/* <div className="bg-gray-100">
                    <div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
                      
                    </div>
                  </div> */}
                </section>
              </main>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ReadyToShipProductFilter;
