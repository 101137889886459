import React from "react";
import AdminModal from "./common/AdminModal";
import SelectMenu from "./common/SelectMenu";
import useApi from "../../Hooks/use-Api";
import { getRolesData } from "../../Services/roleService";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { updateUserRole } from "../../Services/userService";
import Loader from "./common/Loader";
import { toast } from "react-hot-toast";
import { everyNumber, numberRegexWithDecimals } from "../shared/constants";
import { addUserRate, getUserRate } from "../../Services/userRateService";
import { updateUseAccess } from "../../Services/userService";

const UpdateUser = ({
  isOpen,
  setIsOpen,
  editData,
  setEditData,
  fetchUpdatedData,
}) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState('all');
  const [formData, setFormData] = useState({
    dcStoneDisc: "",
    dcJewelleryDisc: "",
  });
  const [fetchRole, data] = useApi(getRolesData);
  const [updateUser, updatedData, loading] = useApi(updateUserRole);

  const [marginVal, setMarginVal] = useState([]);
  const [fetchMargin, marginData] = useApi(getUserRate);

  useEffect(() => {
    if (editData) {
      fetchMargin(editData.unUserIdentifier);
    }
  }, [fetchMargin, editData]);

  useEffect(() => {
    if (marginData && marginData.user_rate.length) {
      setMarginVal(marginData.user_rate);
    }
  }, [marginData]);

  useEffect(() => {
    if (updatedData) {
      toast.success("User updated successfully.");
      fetchUpdatedData();
      handleClose();
    }
  }, [updatedData]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  useEffect(() => {
    if (editData && data) {
      const role = data.role.find(
        (rl) => rl.unRoleIdentifier === editData.unRoleId
      );
      if (role) {
        setSelectedRole({ title: role.stRoleName, id: role.unRoleIdentifier });
      }
    }
  }, [editData, data]);

  useEffect(() => {
    if (editData) {
      setFormData({
        dcStoneDisc: editData?.dcStoneDisc || "0.00",
        dcJewelleryDisc: editData?.dcJewelleryDisc || "0.00",
      });
      setSelectedPermission(editData?.stAccessType || "All")
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'dcStoneDisc') {
      setMarginVal(prev => {
        return prev?.map((item) => {
          return {...item, dcPercentage: value}
        })
      })
    }
  };

  useEffect(() => {
    if (selectedRole?.id === "36420c30-ed70-11ed-8fa7-52543dc17722") {
      setFormData((prev) => ({ ...prev, dcStoneDisc: 100 }));
      setMarginVal(prev => {
        return prev?.map((item) => {
          return {...item, dcPercentage: 100}
        })
      })
    }else{
      setFormData((prev) => ({ ...prev, dcStoneDisc: "0.00"}));
      setMarginVal(prev => {
        return prev?.map((item) => {
          return {...item, dcPercentage: "0.00"}
        })
      })
    }
  }, [selectedRole])
  
  const roleOptions = useMemo(() => {
    if (data) {
      return data.role.map((item) => {
        return {
          title: item.stRoleName,
          id: item.unRoleIdentifier,
        };
      });
    }
    return [];
  }, [data]);

  const permissionOptions = useMemo(() => {
    if (data) {
      return [
        {
          title: "All",
          id: "All",
        },
        {
          title: "Diamonds Only",
          id: "Diamonds Only",
        },
        {
          title: "Jewelry Only",
          id: "Jewelry Only",
        },
      ];
    }
    return [];
  }, [data]);

  const handleClose = () => {
    setIsOpen(false);
    setEditData(null);
  };

  const handleSubmit = async () => {
    if (formData.dcStoneDisc > "0.00") {
      const payload = new FormData();
      payload.append("unUserID", editData.unUserIdentifier);
      // payload.append("data", JSON.stringify(marginVal));
      marginVal.forEach((item, index) => {
        Object.entries(item).forEach(([key, val]) => {
          payload.append(`data[${index}][${key}]`, val);
        });
      });
      const response = await addUserRate(payload);
    }
    const payload = {
      ...formData,
      unRoleId: selectedRole.id,
      unUserIdentifier: editData?.unUserIdentifier,
    };
    // const accessResponse = await updateUseAccess({
    //   unUserIdentifier: editData.unUserIdentifier,
    //   stAccessType: selectedPermission,
    // });
    updateUser(payload);
  };

  return (
    <AdminModal
      title={"Update Commition and Role"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <div className="mb-4 relative">
        {loading && (
          <div className="absolute inset-0 bg-[#fdfeffb3] z-50 flex justify-center items-center">
            <Loader />
          </div>
        )}
        <div className="mb-3">
          <div className="flex justify-start items-center gap-2">
            <h3 className="text-sm w-1/4">User Name</h3>
            <span>:</span>
            <p className="text-base font-[500] w-3/4">
              {editData?.stFirstName} {editData?.stLastName}
            </p>
          </div>
          <div className="flex justify-start items-center gap-2">
            <h3 className="text-sm w-1/4">User Email</h3>
            <span>:</span>
            <p className="text-base font-[500] w-3/4">{editData?.stEmail}</p>
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-center items-center w-full gap-2 mb-2">
            <span className="block w-1/4 text-sm font-medium leading-4 text-gray-900">
              Role
            </span>
            <span>:</span>
            <SelectMenu
              data={selectedRole}
              setData={setSelectedRole}
              options={roleOptions}
              widthClass={"w-3/4"}
              dropDownHeightClass="h-[75px]"
            />
          </div>
          <div className="flex justify-center items-center w-full mb-2 gap-2">
            <label
              htmlFor="dcStoneDisc"
              className="block w-1/4 text-sm font-medium leading-4 text-gray-900"
            >
              Stone%
            </label>
            <span>:</span>
            <div className="w-3/4">
              <input
                type="text"
                name="dcStoneDisc"
                id="dcStoneDisc"
                value={formData.dcStoneDisc}
                onChange={(e) => {
                  if (everyNumber.test(+e.target.value)) {
                    handleChange(e);
                  }
                }}
                className="block w-full outline-none rounded-md border border-gray-400 py-1 px-2 text-gray-900 shadow-sm sm:text-sm"
              />
            </div>
          </div>
          <div className="flex justify-center items-center w-full mb-2 gap-2">
            <label
              htmlFor="dcJewelleryDisc"
              className="block w-1/4 text-sm font-medium leading-4 text-gray-900"
            >
              Jewelry%
            </label>
            <span>:</span>
            <div className="w-3/4">
              <input
                type="text"
                name="dcJewelleryDisc"
                id="dcJewelleryDisc"
                value={formData.dcJewelleryDisc}
                onChange={(e) => {
                  if (everyNumber.test(+e.target.value)) {
                    handleChange(e);
                  }
                }}
                className="block w-full outline-none rounded-md border border-gray-400 py-1 px-2 text-gray-900 shadow-sm sm:text-sm"
              />
            </div>
          </div>
          {/* <div className="flex justify-center items-center w-full gap-2 mb-2">
            <label
              htmlFor="dcJewelleryDisc"
              className="block w-1/4 text-sm font-medium leading-4 text-gray-900"
            >
              Permission
            </label>
            <span>:</span>
            <div className="w-3/4">
              <select
                id="small"
                class="w-full h-[36px] text-sm border bg-white outline-none rounded-md"
                value={selectedPermission}
                onChange={(e) => setSelectedPermission(e.target.value)}
              >
                {permissionOptions?.length &&
                  permissionOptions?.map((i) => {
                    return <option value={i?.id}>{i?.title}</option>;
                  })}
              </select>
            </div>
          </div> */}
        </div>
      </div>
    </AdminModal>
  );
};

export default UpdateUser;
