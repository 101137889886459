import api from "./api"

// Mounting Front

export const getMountingMenu = () => {
  return api("/api/MenuMounting", "get")
}

// Mounting Admin Panel APIs

const insertMounting = (payload) => {
  return api("/api/insertMounting", "post", payload)
}

const updateMounting = (id, payload) => {
  return api(`/api/updateMounting/${id}`, "put", payload)
}

const deleteMounting = (id) =>
  api(`/api/deleteMounting/${id}`, "delete");

const getMountingList = () => {
  return api("/api/getMounting", "get")
}

const getMountingDetails = (id) => {
  return api(`/api/getMountingDetails/${id}`, "get")
}

export {
  insertMounting,
  updateMounting,
  deleteMounting,
  getMountingList,
  getMountingDetails
}