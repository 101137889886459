import api from "./api";

const loginUser = (data) => api("/api/login", "post", data)

const socialLogin = (data) => api("/api/socialLogin", "post", data)

const registerUser = (data) => api("/api/Users", "post", data)

export {
  loginUser,
  socialLogin,
  registerUser
}